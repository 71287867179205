import React from 'react'
import translate from '../Providers/i18n/Translate'
import history from "../History";

class PageNotFoundComponent extends React.Component {
    handleClick = () => {
        history.push('/')
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper pageNotFound">
                                <h3>404</h3>
                                <span>{translate('oops')}</span>
                                <p>{translate('pagenotfound')}</p>
                                <button onClick={this.handleClick}>{translate('gobacktodashboard')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageNotFoundComponent