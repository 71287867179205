import AppDispatcher from '../../Dispatcher'
import EarlySettlementConstants from './earlysettlement-constants'

class EarlySettlementActions {
    earlySettlementRequest(response) {
        AppDispatcher.dispatch({ actionType: EarlySettlementConstants.EARLYSETTLEMENT, response: response })
    }
    financialStatusRequest(response) {
        AppDispatcher.dispatch({ actionType: EarlySettlementConstants.FINANCIALSTATUS, response: response })
    }
}
export default new EarlySettlementActions()