import React from "react"
import DatePicker from 'react-datepicker'
import { LOCALES } from '../../Providers/i18n'
import { ScrollToTop } from '../../utils/Scroll'
import { AppContext } from '../../Providers/context'
import InputComponent from '../utils/inputComponent'
import ErrorComponent from '../utils/errorComponent'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'

class StepOne extends React.Component {
    _isMounted = false
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            areas: [],
            fundTypes: [],
            landAreas: [],
            contractDate: '',
            delegationDate: '',
            demarcationDate: ''
        }
        this.getEmail = this.getEmail.bind(this)
        this.setAreas = this.setAreas.bind(this)
        this.setDates = this.setDates.bind(this)
        this.postData = this.postData.bind(this)
        this.showError = this.showError.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
        this.setFundTypes = this.setFundTypes.bind(this)
        this.getFundTypes = this.getFundTypes.bind(this)
        this.postCallback = this.postCallback.bind(this)
        this.changeListener = this.changeListener.bind(this)
        this.dropDownHandler = this.dropDownHandler.bind(this)
        this.getMobileNumber = this.getMobileNumber.bind(this)
        this.demarcationDateDateChangeListener = this.demarcationDateDateChangeListener.bind(this)
        this.contractDateDateDateChangeListener = this.contractDateDateDateChangeListener.bind(this)
        this.delegationDateDateDateChangeListener = this.delegationDateDateDateChangeListener.bind(this)
    }

    componentDidMount() {
        ScrollToTop()
        this._isMounted = true
        this.getFundTypes()
        this.getAreas()
        this.setDates()
        this.getEmail()
        this.getMobileNumber()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    setDates() {
        var payload = this.props.reference.state.ticketPayload
        this.setState({
            demarcationDate: payload.demarcationDate ? this.stringToDate(payload.demarcationDate, 'dd/mm/yyyy', '/') : '',
            contractDate: payload.contractDate ? this.stringToDate(payload.contractDate, 'dd/mm/yyyy', '/') : '',
            delegationDate: payload.delegationDate ? this.stringToDate(payload.delegationDate, 'dd/mm/yyyy', '/') : ''
        })
    }

    getFundTypes() {
        fetch(CONSTANTS.getFundTypesEndpoint + "?local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.setFundTypes).catch();
    }

    setFundTypes(response) {
        if (response.success && response.payload.length > 0) {
            var fundTypesArray = []
            response.payload.map((fund, index) => {
                fundTypesArray.push(<option key={index} value={fund.id}>{fund.name}</option>)
            })
            this.setState({ fundTypes: fundTypesArray }, () => {
                this.props.reference.state.ticketPayload.fundType === undefined && this.props.reference.updateTicketPayload("fundType", parseInt(response.payload[0].id))
            })
        }
    }

    getAreas() {
        fetch(CONSTANTS.getRegionsEndpoint + "?local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.setAreas).catch();
    }

    setAreas(response) {
        if (response.success && response.payload.length > 0) {
            var areaArray = []
            response.payload.map((area, index) => {
                areaArray.push(<option key={index} value={area.id}>{area.regionName}</option>)
            })
            this.setState({ areas: areaArray }, () => {
                this.props.reference.state.ticketPayload.area === undefined && this.props.reference.updateTicketPayload("area", parseInt(response.payload[0].id))
            })
        }
    }

    changeListener(e, isValid) {
        this.props.reference.reset()
        var name = e.target.name
        var value = e.target.value
        if (isValid) {
            this.props.reference.updateTicketPayload(name, value)
        } else {
            this.props.reference.updateTicketPayload(name, value)
            this.props.reference.addError(name)
        }
    }

    dropDownHandler(e) {
        var { name, value } = e.target
        this.props.reference.updateTicketPayload(name, parseInt(value))
    }

    ddmmyy(date) {
        var d = new Date(date);
        var dd = d.getDate();
        var mm = d.getMonth() + 1;
        var yy = d.getFullYear();
        return dd + "/" + mm + "/" + yy;
    }

    demarcationDateDateChangeListener(date) {
        if (date != null) {
            this.setState({ 'demarcationDate': date }, () => {
                this.props.reference.updateTicketPayload('demarcationDate', this.ddmmyy(date))
            })
        } else {
            this.props.reference.addError('demarcationDate')
        }
    }

    postData() {
        this.props.reference.reset()
        localStorage.getItem('disbursementTypeID') === '16' ? this.props.reference.updateTicketPayload("loanDisbursementType", "DISBURSEMENT_MAINTENANCE_LOAN") : this.props.reference.updateTicketPayload("loanDisbursementType", "DISBURSEMENT_CONSTRUCTION_LOAN")
        setTimeout(() => { this.props.reference.postData(this.postCallback) }, 300)
    }

    loadFailed(response) {
        this.setState({ showLoading: false }, () => {
            this.props.reference.showError(response.message)
        })
    }

    postCallback() {
        localStorage.setItem('startAtStep', 1)
        this.props.jumpToStep(1)
        if (localStorage.getItem("dTicketNo")) {
            fetch(CONSTANTS.changeStatusLoanDisbursementEndpoint + "/" + localStorage.getItem("dTicketNo") + "?local=" + this.context.state.siteLang + "&ticketStatusId=" + this.props.reference.state.ticketPayload.requestStatusId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': CONSTANTS.authToken
                },
            }).then(response => response.json()).then((response) => { console.log(response.message) }).catch(this.loadFailed)
        }
    }

    contractDateDateDateChangeListener(date) {
        if (date != null) {
            this.setState({ 'contractDate': date }, () => {
                this.props.reference.updateTicketPayload('contractDate', this.ddmmyy(date))
            })
        } else {
            this.props.reference.addError('contractDate')
        }
    }

    delegationDateDateDateChangeListener(date) {
        if (date != null) {
            this.setState({ 'delegationDate': date }, () => {
                this.props.reference.updateTicketPayload('delegationDate', this.ddmmyy(date))
            })
        } else {
            this.props.reference.addError('delegationDate')
        }
    }

    stringToDate(_date, _format, _delimiter) {
        var formatLowerCase = _format.toLowerCase();
        var formatItems = formatLowerCase.split(_delimiter);
        var dateItems = _date.split(_delimiter);
        var monthIndex = formatItems.indexOf("mm");
        var dayIndex = formatItems.indexOf("dd");
        var yearIndex = formatItems.indexOf("yyyy");
        var month = parseInt(dateItems[monthIndex]);
        month -= 1;
        var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
        return formatedDate;
    }

    changeListenerNoValidation(e) {
        this.reset()
        const { name, value } = e.target
        this.setState({ [name]: value })
        this.updateTicketPayload(name, value)
    }

    showError(message) {
        if (this._isMounted) {
            this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
        }
    }

    getEmail() {
        var email = ''
        if (this.props.reference.state.ticketPayload.email && this.props.reference.state.ticketPayload.email.length > 0) {
            email = this.props.reference.state.ticketPayload.email
        } else {
            try {
                email = JSON.parse(localStorage.getItem('dashboard')).payload.email
            } catch (error) {
                console.log(error)
            }
        }
        setTimeout(() => { this.props.reference.updateTicketPayload("email", email) }, 100);
        return email
    }

    getMobileNumber() {
        var mobileNumber = ''
        if (this.props.reference.state.ticketPayload.mobileNo && this.props.reference.state.ticketPayload.mobileNo.length > 0) {
            mobileNumber = this.props.reference.state.ticketPayload.mobileNo
        } else {
            try {
                mobileNumber = JSON.parse(localStorage.getItem('dashboard')).payload.primaryMobileNo
            } catch (error) {
                console.log(error)
            }
        }
        setTimeout(() => { this.props.reference.updateTicketPayload("mobileNo", mobileNumber) }, 200);
        return mobileNumber
    }

    postSuccess(response) {
        this.props.reference.stopLoading()
        if (response.success) {
            this.setState({ successMessage: response.message, submitSuccess: true }, () => {
                localStorage.setItem('startAtStep', 2)
                this.props.jumpToStep(2)
            })
        } else {
            this.props.reference.showError(response.message)
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, optionsArray: this.state.fundTypes, lable: translate('fundType'), name: 'fundType', isRequired: true, value: this.props.reference.state.ticketPayload.fundType, placeHolder: '', type: 'select', changeListener: this.dropDownHandler, maxLength: 10 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('mobileNo'), name: 'mobileNo', isRequired: true, value: this.props.reference.state.ticketPayload.mobileNo, type: 'phone', changeListener: this.changeListener, maxLength: 20 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('email'), name: 'email', isRequired: true, value: this.props.reference.state.ticketPayload.email, type: 'email', changeListener: this.changeListener, maxLength: 50 }} />
                    <hr></hr>
                    <div className="col-12">
                        <h3>{translate('landDetails')}</h3>
                    </div>
                    <InputComponent inputProps={{ disabled: true, lable: translate('city'), name: 'city', isRequired: true, type: 'text', changeListener: this.changeListener, placeHolder: this.context.state.siteLang === LOCALES.ENGLISH ? "Dubai" : "دبي", maxLength: 10 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, optionsArray: this.state.areas, lable: translate('area'), name: 'area', isRequired: true, value: this.props.reference.state.ticketPayload.area, placeHolder: '', type: 'select', changeListener: this.dropDownHandler, maxLength: 10 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('Landarea'), name: 'landArea', isRequired: true, value: this.props.reference.state.ticketPayload.landArea, type: 'number', changeListener: this.changeListener, maxLength: 5 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('LandNo'), name: 'manucipalityNumber', isRequired: true, value: this.props.reference.state.ticketPayload.manucipalityNumber, type: 'number', changeListener: this.changeListener, maxLength: 10 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, display: 'none', lable: '', name: '', isRequired: true, value: this.props.reference.state.ticketPayload.landArea, type: 'hidden', changeListener: null, maxLength: 10 }} />
                    <div className="alert alert-info loandisbursement" role="alert">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="information" /></span>
                        <span>{translate("landNumberMessage")}</span>
                    </div>
                    <hr></hr>
                    <div className="col-12">
                        <h3>{translate('tripleContractDetails')}</h3>
                    </div>
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('contractor_licence_number'), name: 'contractor', isRequired: true, value: this.props.reference.state.ticketPayload.contractor, type: 'number', changeListener: this.changeListener, maxLength: 8 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('contractor_name'), name: 'contractorName', isRequired: false, value: this.props.reference.state.ticketPayload.contractorName, type: 'text', changeListener: this.changeListener, maxLength: 30 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('consultant_licence_number'), name: 'consultant', isRequired: true, value: this.props.reference.state.ticketPayload.consultant, type: 'number', changeListener: this.changeListener, maxLength: 8 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('consultant_name'), name: 'consultantName', isRequired: false, value: this.props.reference.state.ticketPayload.consultantName, type: 'text', changeListener: this.changeListener, maxLength: 30 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('contractAmount'), name: 'contractAmount', isRequired: true, value: this.props.reference.state.ticketPayload.contractAmount, type: 'number', changeListener: this.changeListener, maxLength: 8 }} />
                    <div className="col-md-6">
                        <label>{translate('contractDate')}<span className="redStar">*</span></label>
                        <DatePicker readOnly={this.props.reference.state.ticketPayload.requestStatusId === 10} endDate={new Date()} dateFormat="dd/MM/yyyy" selected={this.state.contractDate} maxDate={new Date()} onChange={date => this.contractDateDateDateChangeListener(date)} />
                    </div>
                    <div className="col-md-6">
                        <label>{translate('demarcationDate')}<span className="redStar">*</span></label>
                        <DatePicker readOnly={this.props.reference.state.ticketPayload.requestStatusId === 10} endDate={new Date()} dateFormat="dd/MM/yyyy" selected={this.state.demarcationDate} maxDate={new Date()} onChange={date => this.demarcationDateDateChangeListener(date)} />
                    </div>
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('contractDurationMonths'), name: 'contractDurationInMonths', isRequired: true, value: this.props.reference.state.ticketPayload.contractDurationInMonths, type: 'number', changeListener: this.changeListener, maxLength: 4 }} />
                    <InputComponent inputProps={{ disabled: this.props.reference.state.ticketPayload.requestStatusId === 10, lable: translate('preparationMobilizationDuration'), name: 'preparationMobilizationDuration', isRequired: false, value: this.props.reference.state.ticketPayload.preparationMobilizationDuration, type: 'number', changeListener: this.changeListener, maxLength: 4 }} />
                    <hr></hr>
                </div>
                <div className="row">
                    <div className="col-md-12 constructionLoanDisbursementStepBtn">
                        <button className="stepSubmitBtn" onClick={this.postData}>{translate('next')}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default StepOne