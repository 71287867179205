import React from "react";
import './underMaintenance.css';
class UnderMaintenanceComponent extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="notFoundPageSection">
                                <div className="row notFoundPageWrapper">
                                    <div className="col-md-12"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/UnderMaintenance.svg"></img></div>
                                    <div className="col-md-6 centerDots">
                                        <strong>Under Maintenance</strong>
                                        <span>Will be Back Soon!</span>
                                    </div>
                                    <div className="col-md-6">
                                        <strong>الموقع قيد الصيانة</strong>
                                        <span>سنعود قريباً</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default UnderMaintenanceComponent;