import React from 'react'
import { AppContext } from '../../Providers/context'
import { LOCALES } from '../../Providers/i18n'
import LeavingWeb from '../../utils/LeavingWeb'
class Header extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      showLeavePopup: false,
      urlAddress: ""
    }
    this.showLeavePopup = this.showLeavePopup.bind(this);
    this.hideLeavePopup = this.hideLeavePopup.bind(this);
  }

  isLogedIn() {
    return localStorage.getItem('accessToken') || localStorage.getItem("isPendingPayment")
  }

  showLeavePopup(address) {
    this.setState({ showLeavePopup: true, urlAddress: address })
  }

  hideLeavePopup() {
    this.setState({ showLeavePopup: false, urlAddress: "" })
  }
  

  render() {
    
    return (
      <div className="container-fluid logo_wrapper"  id='logo_wrapper'>
        {this.state.showLeavePopup ? <LeavingWeb url={this.state.urlAddress} hide={this.hideLeavePopup} /> : null}
        <h1>
          <div className="container">
            {/* <div className="row">
              <div className="col gov">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="#!" onClick={() => this.showLeavePopup("https://www.smartdubai.ae/")} ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></a> : <a href="#!" onClick={() => this.showLeavePopup("https://www.smartdubai.ae/ar/home-page")} ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></a>}</div>
              <div className="col mrhe">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.mbrhe.gov.ae/en/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.png'} alt='MBRHE' /></a> : <a href="https://www.mbrhe.gov.ae/ar/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.png'} alt='MBRHE' /></a>}</div>
            </div> */}
            {this.isLogedIn() ? <div className="row">
              <div className="col gov">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="#!" onClick={() => this.showLeavePopup("https://www.smartdubai.ae/")} ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></a> : <a href="#!" onClick={() => this.showLeavePopup("https://www.smartdubai.ae/ar/home-page")} ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></a>}</div>
              <div className="col mrhe">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.mbrhe.gov.ae/en/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.png'} alt='MBRHE' /></a> : <a href="https://www.mbrhe.gov.ae/ar/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.png'} alt='MBRHE' /></a>}</div>
            </div> :
              <div className="row">
                <div className="col gov">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="#!" onClick={() => this.showLeavePopup("https://www.smartdubai.ae/")} ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai_white.svg'} alt='Government of dubai' /></a> : <a href="https://www.smartdubai.ae/ar/home-page" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai_white.svg'} alt='Government of dubai' /></a>}</div>
                <div className="col mrhe">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.mbrhe.gov.ae/en/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe_white.svg'} alt='MBRHE' /></a> : <a href="https://www.mbrhe.gov.ae/ar/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe_white.svg'} alt='MBRHE' /></a>}</div>
              </div>}
          </div>
        </h1>
      </div>
    )
  }
}
export default Header