import ContractorDashboardConstant from './ContractorDashboardConstant';
import BaseStore from '../../BaseStore';

class ContractorDashboardStore extends BaseStore {

  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._success = false;
    this._response = null;
    this._summarySuccess = false;
    this._summaryResponse = null;
    this._projectSummarySuccess = false;
    this._projectSummaryResponse = null;
    this._financialSuccess = false;
    this._financialResponse = null;
    this._dashboardServicesSuccess = false;
    this._dashboardServicesResponse = null;
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case ContractorDashboardConstant.CONTRACTOR_DASHBOARD:
        this._success = action.response.success;
        this._response = action.response.payload;
        this.emitChange();
        break;
      case ContractorDashboardConstant.CONTRACTOR_DASHBOARD_SERVICES:
        this._dashboardServicesSuccess = action.response.success;
        this._dashboardServicesResponse = action.response.payload;
        this.emitChange();
        break;
      case ContractorDashboardConstant.CONTRACTOR_PROJECT_SUMMARY:
        this._summarySuccess = action.response.success;
        this._summaryResponse = action.response.payload;
        this.emitChange();
        break;
      case ContractorDashboardConstant.PROJECT_SUMMARY:
        this._projectSummarySuccess = action.response.success;
        this._projectSummaryResponse = action.response.payload;
        this.emitChange();
        break;
      case ContractorDashboardConstant.FINANCIAL_STATUS:
        this._financialSuccess = action.response.success;
        this._financialResponse = action.response.payload;
        this.emitChange();
        break;
      default:
        break;
    };
  }
  isSuccess() {
    return this._success;
  }

  get response() {
    return this._response;
  }

  isSummarySuccess() {
    return this._summarySuccess;
  }

  get summaryResponse() {
    return this._summaryResponse;
  }

  isProjectSummarySuccess() {
    return this._projectSummarySuccess;
  }

  get projectSummaryResponse() {
    return this._projectSummaryResponse;
  }

  isFinancialStatusSuccess() {
    return this._financialSuccess;
  }

  get financialStatusResponse() {
    return this._financialResponse;
  }

  isDashboardServicesSuccess() {
    return this._dashboardServicesSuccess;
  }

  get dashboardServicesResponse() {
    return this._dashboardServicesResponse;
  }
}

export default new ContractorDashboardStore();