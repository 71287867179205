import ContractorProjectPaymentsConstant from './ContractorProjectPaymentsConstant';
import BaseStore from '../../BaseStore';

class ContractorProjectPaymentsStore extends BaseStore {

  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._response = '';
    this._success = false;
    this._message = '';

    this._ownerResponse = '';
    this._ownerSuccess = false;
    this._ownerMessage = '';

    this._detailSuccess = '';
    this._detailResponse = '';
    this._detailMessage = '';

    this._getAllDocumentsSuccess = '';
    this._getAllDocumentsResponse = '';
    this._getAllDocumentsMessage = '';

    this._uploadDocumentSuccess = '';
    this._uploadDocumentResponse = '';
    this._uploadDocumentMessage = '';

    this._viewDocumentSuccess = '';
    this._viewDocumentResponse = '';
    this._viewDocumentMessage = '';

    this._deleteDocumentSuccess = '';
    this._deleteDocumentMessage = '';

    this._submitSuccess = '';
    this._submitMessage = '';

    this._successPaymentHeader = '';
    this._responsePaymentHeader = '';
    this._messagePaymentHeader = '';

    this._contractorEntrySuccess = '';
    this._contractorEntryResponse = '';
    this._contractorEntryMessage = '';
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case ContractorProjectPaymentsConstant.CONTRACTOR_PROJECT_PAYMENTS:
        this._success = action.response.success;
        this._response = action.response.payload;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_PROJECT_PAYMENTS_BREAK_DOWN:
        this._successPaymentHeader = action.response.success;
        this._responsePaymentHeader = action.response.payload;
        this._messagePaymentHeader = action.response.message;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_ADD_PAYMENT_HEADER_OWNER_A:
        this._ownerSuccess = action.response.success;
        this._ownerResponse = action.response.payload;
        this._ownerMessage = action.response.message;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_ADD_PAYMENT_HEADER_DETAILS:
        this._detailSuccess = action.response.success;
        this._detailResponse = action.response.instancePaymentCalculation;
        this._detailMessage = action.response.message;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_GET_ALL_DOCUMENTS:
        this._getAllDocumentsSuccess = action.response.success;
        this._getAllDocumentsResponse = action.response.payload;
        this._getAllDocumentsMessage = action.response.message;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_UPLOAD_DOCUMENTS:
        this._uploadDocumentSuccess = action.response.success;
        this._uploadDocumentMessage = action.response.message;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_VIEW_DOCUMENTS:
        this._viewDocumentSuccess = action.response.success;
        this._viewDocumentResponse = action.response;
        this._viewDocumentMessage = action.response.message;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_DELETE_DOCUMENTS:
        this._deleteDocumentSuccess = action.response.success;
        this._deleteDocumentMessage = action.response.message;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_SUBMIT_STATUS:
        this._submitSuccess = action.response.success;
        this._submitMessage = action.response.message;
        this.emitChange();
        break;

      case ContractorProjectPaymentsConstant.CONTRACTOR_ENTRY_STATUS:
        this._contractorEntrySuccess = action.response.success;
        this._contractorEntryResponse = action.response.payload;
        this._contractorEntryMessage = action.response.message;
        this.emitChange();
        break;

      default:
        break;
    };
  }

  isSuccess() {
    return this._success;
  }

  get response() {
    return this._response;
  }

  get message() {
    return this._message;
  }

  isContractorEntrySuccess() {
    return this._contractorEntrySuccess;
  }

  get contractorEntrySuccessResponse() {
    return this._contractorEntryResponse;
  }

  get contractorEntrySuccessMessage() {
    return this._contractorEntryMessage;
  }

  isSuccessPaymentHeader() {
    return this._successPaymentHeader;
  }

  get responsePaymentHeader() {
    return this._responsePaymentHeader;
  }

  get messagePaymentHeader() {
    return this._messagePaymentHeader;
  }

  isOwnerSuccess() {
    return this._ownerSuccess;
  }

  get ownerResponse() {
    return this._ownerResponse;
  }

  get ownerMessage() {
    return this._ownerMessage;
  }

  isDetailSuccess() {
    return this._detailSuccess;
  }

  get detailResponse() {
    return this._detailResponse;
  }

  get detailMessage() {
    return this._detailMessage;
  }

  isGetAllDocumentsSuccess() {
    return this._getAllDocumentsSuccess;
  }

  get getAllDocumentsResponse() {
    return this._getAllDocumentsResponse;
  }

  get getAllDocumentsMessage() {
    return this._getAllDocumentsMessage;
  }

  isUploadDocumentSuccess() {
    return this._uploadDocumentSuccess;
  }

  get uploadDocumentMessage() {
    return this._uploadDocumentMessage;
  }

  isViewDocumentSuccess() {
    return this._viewDocumentSuccess;
  }

  get getViewDocumentResponse() {
    return this._viewDocumentResponse;
  }

  get getViewDocumentMessage() {
    return this._viewDocumentMessage;
  }


  isDeleteDocumentSuccess() {
    return this._deleteDocumentSuccess;
  }

  get getDeleteDocumentMessage() {
    return this._deleteDocumentMessage;
  }

  isSubmitSuccess() {
    return this._submitSuccess;
  }

  get getSubmitMessage() {
    return this._submitMessage;
  }
}

export default new ContractorProjectPaymentsStore();