import React from 'react'
import translate from '../../../Providers/i18n/Translate'

function MaintenanceRequestsItem(props) {
    return (
        <div className="col-md-12 maintenanceRequestsWidget">
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        <div className="col-4"><span>{translate('requestNo')}:</span></div>
                        <div className="col-8"><span><strong>{props.payload.id}</strong></span></div>
                    </div>
                </div>
                <div className="col-lg-3 buttons"><button name={props.index} onClick={props.showDetails}>{translate('more')}</button></div>
            </div>
        </div>
    )
}
export default MaintenanceRequestsItem