import React from "react";
import './login.css';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import translate from '../../Providers/i18n/Translate'
import { AppContext, AppContextProvider } from '../../Providers/context'
import CommonUtils from "../../utils/CommonUtils";


class LoginHomeComponent extends React.Component {
    static contextType = AppContext

    isLogedIn() {
        return localStorage.getItem('accessToken')
    }

    getUserType() {
        return localStorage.getItem('userType')
    }

    componentDidMount() {
        CommonUtils.getLocalParameter("/eservices", this.context.state.siteLang, this.props.location.search);
    }

    render() {
        if (this.isLogedIn() && this.getUserType() == 1) {
            return CommonUtils.getRedirection(this.props.location.search, true)
        } else if (this.isLogedIn()) {
            return <Redirect to='/contractorDashboard' />
        } else if (!this.isLogedIn() && this.props.location.search) {
            return queryString.parse(this.props.location.search).isContractorService === 'true' ? <Redirect to={'/contractorLogin' + this.props.location.search} /> : <Redirect to={'/customerLogin' + this.props.location.search} />
        }
        else {
            return (
                <div className="container-fluid appWrapper">
                    <div className="container">
                        <div className="row">
                            <div className="loginSelectorWraper">
                                <div className="loginChoose">
                                    <div className="row">
                                        <div className="col">
                                            <a href={"/customerLogin" + this.props.location.search} className="customerLogBtn">
                                                <div className="loginSelectorBox">
                                                    <div className="headerImg">
                                                        <div className="icon">
                                                            <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/customerIcon.png" alt="customer" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="loginChooseContant">
                                                    <h2>{translate('customerLogin')}</h2>
                                                    <p>{translate('toApplyLoanGrant')}</p>
                                                    <span className="customerLogBtn">{translate('customerLogin')}</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <a href="/contractorLogin" className="contractorLogBtn" >
                                                <div className="loginSelectorBox">
                                                    <div className="headerImg">
                                                        <div className="icon">
                                                            <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractorIcon.png" alt="contractor" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="loginChooseContant">
                                                    <h2>{translate('contractorLogin')}</h2>
                                                    <p>{translate('toOpenNewFile')}</p>
                                                    <span className="contractorLogBtn" >{translate('contractorLogin')}</span>

                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* QUICK SERVICEC */}
                        <div className="row">
                            <div className="col-md-12 loginQuickServicesWrapper customerServicesWidget">
                                <div className="widgetWrapper">
                                    <div className="widgetHead">
                                        <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Services_icon.svg" alt="this Quick Services" /></span>
                                        <span>{translate('QuickServices')}</span>
                                    </div>
                                    <div className="widgetContant">
                                        <div className="widgetServiceBox">
                                            <a href="/housingApplicationStatus">
                                                <div className="widgetService_icon">
                                                    <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ApplicationStatus.svg" alt="Click to Application Status" />
                                                </div>
                                                <div className="widgetServiceName">
                                                    {translate('GetHousingApplicationStatus')}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="widgetServiceBox">
                                            <a href="/maintenanceRequestStatusForReadyHouse">
                                                <div className="widgetService_icon">
                                                    <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/maintenance_grant.svg" alt="Click to Maintenance Grant" />
                                                </div>
                                                <div className="widgetServiceName">
                                                    {translate('GetMaintenanceRequestStatusForReadyHouse')}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="widgetServiceBox">
                                            <a href="/paymentsStatus">
                                                <div className="widgetService_icon">
                                                    <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/FinancialStatus.svg" alt="Click to Financial Status" />
                                                </div>
                                                <div className="widgetServiceName">
                                                    {translate('GetPaymentsStatus')}
                                                </div>
                                            </a>
                                        </div>
                                        <div className="widgetServiceBox">
                                            <a href="/toWhomItMayConcernCertificate">
                                                <div className="widgetService_icon">
                                                    <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg" alt="Click to To Who It May Concern" />
                                                </div>
                                                <div className="widgetServiceName">
                                                    {translate('to-whom-lbl')}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        };
    }
}
export default LoginHomeComponent;