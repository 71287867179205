import React from 'react'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import queryString from 'query-string'
import history from '../../History'
import CONSTANTS from '../constants/dashboard-constants'
import { LOCALES } from '../../Providers/i18n'
import LeavingWeb from '../../utils/LeavingWeb'

class SearchComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            page: 0,
            pageSize: 10,
            showLeavePopup: false,
            query: queryString.parse(this.props.location.search).query,
            searchJsonArray: []
        }
        this.showLeavePopup = this.showLeavePopup.bind(this);
        this.hideLeavePopup = this.hideLeavePopup.bind(this);
        this.searchItems = this.searchItems.bind(this)
        this.setCustomersArray = this.setCustomersArray.bind(this)
        this.setContractorsArray = this.setContractorsArray.bind(this)
    }

    componentDidMount() {
        if (localStorage.getItem("userType") === "1") {
            this.setCustomersArray()
        } else {
            this.setContractorsArray()
        }
    }

    setCustomersArray() {
        fetch(CONSTANTS.searchApiEndpoint + '?userType=1', {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then((jsonData) => {
            this.setState({ searchJsonArray: this.context.state.siteLang === LOCALES.ENGLISH ? jsonData.payload.searchArrayEN : jsonData.payload.searchArrayAR }, () => {
                this.searchItems(null)
            })
        }).catch((error) => {
            console.log(error)
        })
        // this.setState({ searchJsonArray: this.context.state.siteLang === LOCALES.ENGLISH ? SearchJsonCustomers.searchArrayEN : SearchJsonCustomers.searchArrayAR }, () => {
        //     this.searchItems(null)
        // })
    }

    setContractorsArray() {
        fetch(CONSTANTS.searchApiEndpoint + '?userType=2', {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then((jsonData) => {
            console.log(jsonData)
            this.setState({ searchJsonArray: this.context.state.siteLang === LOCALES.ENGLISH ? jsonData.payload.searchArrayEN : jsonData.payload.searchArrayAR }, () => {
                this.searchItems(null)
            })
        }).catch((error) => {
            console.log(error)
        })
        // https://mbrhecdnstorageaccount.blob.core.windows.net/eservices-portal/searchJsonContractor.json
        // this.setState({ searchJsonArray: this.context.state.siteLang === LOCALES.ENGLISH ? SearchJsonContractors.searchArrayEN : SearchJsonContractors.searchArrayAR }, () => {
        //     this.searchItems(null)
        // })
    }

    showLeavePopup(address) {
        this.setState({ showLeavePopup: true, urlAddress: address })
    }

    hideLeavePopup() {
        this.setState({ showLeavePopup: false, urlAddress: "" })
    }

    searchItems(e) {
        var query = ""
        if (e && e.target && e.target.value) {
            query = e.target.value
        }
        this.setState({ query: query, itemsArray: "" })
        var searchItems = []
        this.state.searchJsonArray.map((item) => {
            if (item.title.toLocaleLowerCase().includes(this.state.query.toLocaleLowerCase()) || item.description.toLocaleLowerCase().includes(this.state.query.toLocaleLowerCase())) {
                if (item.isExternal) {
                    searchItems.push(<div key={item.title} className="resultWrapperBox">
                        <a href="#!" data-tip data-for='facebook' onClick={() => this.showLeavePopup(item.url)}>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </a>
                    </div>)
                } else if (item.isComplementaryService) {
                    searchItems.push(<div key={item.title} className="resultWrapperBox">
                        <a href="#!" onClick={() => {
                            var applicationStatus = 0
                            if (item.id === "82d04156-878c-461b-ba6e-3796a3642ecb") {
                                history.push("/changeServiceTicketsListing")
                            } else {
                                if (item.id === "0bcc7958-3e82-444b-ba7f-643bec2ba3e5") {
                                    applicationStatus = 15
                                } else if (item.id === "22d5e68a-2a03-4fca-82c8-52dab2e0a8a6") {
                                    applicationStatus = 5
                                }
                                history.push({ pathname: '/allowedToCancelApplicationsListing', state: { applicationStatus: applicationStatus } })
                            }
                        }}>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </a>
                    </div >)
                } else if (item.isContractor) {
                    searchItems.push(<div key={item.title} className="resultWrapperBox">
                        <a href='#!' onClick={() => {
                            history.push(item.url)
                            localStorage.setItem('statusId', item.statusID)
                        }}>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </a>
                    </div>)
                } else {
                    searchItems.push(<div key={item.title} className="resultWrapperBox">
                        <a href={item.url}>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </a>
                    </div>)
                }
            }
        })
        this.setState({ itemsArray: searchItems })
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.showLeavePopup ? <LeavingWeb url={this.state.urlAddress} hide={this.hideLeavePopup} /> : null}
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">{localStorage.getItem("userType") === "1" ? <a href="/customerDashboard">{translate('dashboard')}</a> : <a href="/contractorDashboard">{translate('dashboard')}</a>}</li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('search')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/search_blue.svg'} alt="" /></i><span>{translate('search')}</span></div>
                        </div>
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    <div className="col-md-12 searchResultTextWrapper">
                                        <h3>{translate("search_title")}</h3>
                                        <h3>{queryString.parse(this.props.location.search).query}</h3>
                                    </div>
                                    <div className="col-md-12">
                                        {this.state.itemsArray.length > 0 ? this.state.itemsArray : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SearchComponent