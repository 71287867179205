import React from 'react'
import '../../reactToastify.css'
import history from '../../History'
import NoData from '../utils/nodata'
import Loader from '../utils/LoaderBars'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../constants/dashboard-constants'
import CostumerModuleUtilities from '../utils/customerModuleUtilities'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class TypicalVillasApplicationsListComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            isLoading: false
        }
        this.modifyTicket = this.modifyTicket.bind(this)
        this.setApplications = this.setApplications.bind(this)
        this.getTypicalVillasApplications = this.getTypicalVillasApplications.bind(this)
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.getTypicalVillasApplications()
        // this.setApplications(JSON.parse(localStorage.getItem('applicationsPayload')))
    }

    getTypicalVillasApplications() {
        localStorage.removeItem("applicationPayload")
        if (CONSTANTS.authToken) {
            fetch(CONSTANTS.typicalVillasApplicationsEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplications).catch(() => { this.setState({ isLoading: false }) });
        }
    }

    setApplications(response) {
        var tickets = []
        var items = response.payload
        items.map((application, index) => {
            let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
            let cls = CostumerModuleUtilities.getStatusLableColor(application.status)
            tickets.push(<UnifiedVillaApplicationItem modifyTicket={this.modifyTicket} ticket={application.request[0]} getButton={this.getButton} itemsCount={application.request.length} key={index} index={index} payload={application} image={img} className={cls} />)
        })
        this.setState({ isLoading: false, applicationsResponse: response.payload, itemsArray: tickets })
        return (tickets)
    }

    modifyTicket(e) {
        const index = e.currentTarget.id
        var application = this.state.applicationsResponse[index]
        localStorage.setItem("applicationPayload", JSON.stringify(application))
        history.push({ pathname: '/typicalVillas' })
    }

    getButton(props) {
        if (props.itemsCount > 0) {
            return <button id={props.index} onClick={props.modifyTicket}>{translate('view')}</button>
        } else {
            return <button id={props.index} onClick={props.modifyTicket}>{translate('Apply')}</button>
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.isLoading ? <Loader /> : null}
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('availableApplications')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/construction_disbursement.svg'} alt="HousingRequest" /></i>
                                <span>{translate('availableApplications')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    {this.state.itemsArray.length > 0 ? this.state.itemsArray : <NoData message={translate('nodata')} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function UnifiedVillaApplicationItem(props) {
    return (
        <div className="col-md-6 housingRequestWrapperAll">
            <div className="houseingRequestInn housingRequestWrapper">
                <div className="row no-gutters">
                    <div className="col-md-2">
                        <i><img src={props.image} alt="MBRHE" /></i>
                    </div>
                    <div className="col-md-7">
                        <div className="selectedcolor-90">
                            <span>{props.payload.serviceName} </span>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <strong>{props.payload.service}</strong>
                                {props.itemsCount === 1 ? <span> {translate('ticket-number')} </span> : null}
                                {props.itemsCount === 1 ? <span> {props.ticket.id} </span> : ''}
                                <div >
                                    <span> {translate('applicationNo')}</span>
                                    <span>: {props.payload.applicationNo} </span>
                                </div>
                            </div>
                            <div className="col-12">
                                <span>{translate('status')} </span>
                                <span className={props.class}>{props.payload.status}</span>
                            </div>
                            <div className="col-12">
                                <p className="housingRequestsince">{props.payload.createdDate != null ? props.payload.createdDate : props.payload.createdDate}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 LoanDisbursementBtn">
                        {props.getButton(props)}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default AuthenticatedComponent(TypicalVillasApplicationsListComponent)