var BASE_URL = process.env.API_BASE_URL
export default {
  BASE_URL: BASE_URL,
  CONTRACTOR_DASHBOARD_URL: BASE_URL + 'contractor/v1/dashboard/projects/list?page=',
  CONTRACTOR_DASHBOARD: 'CONTRACTOR_DASHBOARD',
  CONTRACTOR_DASHBOARD_SERVICES_URL: BASE_URL + 'public/v1/services?loca=',
  CONTRACTOR_DASHBOARD_SERVICES: 'CONTRACTOR_DASHBOARD_SERVICES',
  CONTRACTOR_PROJECT_SUMMARY_URL: BASE_URL + 'contractor/v1/dashboard/projectsbi/status?local=',
  CONTRACTOR_PROJECT_SUMMARY: 'CONTRACTOR_PROJECT_SUMMARY',
  PROJECT_SUMMARY_URL: BASE_URL + 'contractor/v1/dashboard/projectsbi/completion?local=',
  PROJECT_SUMMARY: 'PROJECT_SUMMARY',
  FINANCIAL_STATUS_URL: BASE_URL + 'contractor/v1/dashboard/projectsbi/financial?local=',
  FINANCIAL_STATUS: 'FINANCIAL_STATUS',
}