import React from 'react'
import NoData from '../../utils/nodata'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import ApplicationStatusItem from './applicationstatus-widget-item'

class ApplicationStatusWidget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],

        }
        this.onArrowClick = this.onArrowClick.bind(this)
        this.getComplementaryServices = this.getComplementaryServices.bind(this)
        this.fetchApplicationStatusData = this.fetchApplicationStatusData.bind(this)
        this.setApplicationStatusWidget = this.setApplicationStatusWidget.bind(this)
    }

    componentDidMount() {
        this.fetchApplicationStatusData(this.props.url)
    }

    fetchApplicationStatusData(url) {
        if (CONSTANTS.authToken.length > 15) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplicationStatusWidget).catch(this.loadFailed);
        }
    }

    loadFailed(response) {
        console.log(response)
    }

    setApplicationStatusWidget(response, clickIndex, isShown) {
        if (response.success) {
            var applicationStatusItems = []
            var items = response.payload
            var showComplementaryServices = false
            if (items.length > 2) {
                if (clickIndex) {
                    if (clickIndex === '0') {
                        if (isShown === 'false') {
                            showComplementaryServices = true
                        } else {
                            showComplementaryServices = false
                        }
                        applicationStatusItems.push(<ApplicationStatusItem key={0} index={0} onArrowClick={this.onArrowClick} showComplementaryServices={showComplementaryServices} payload={items[0]} complementaryServicesArray={this.getComplementaryServices(items[0].supportingApplication)} />)
                        applicationStatusItems.push(<ApplicationStatusItem key={1} index={1} onArrowClick={this.onArrowClick} showComplementaryServices={false} payload={items[1]} complementaryServicesArray={this.getComplementaryServices(items[1].supportingApplication)} />)
                    } else {
                        if (isShown === 'false') {
                            showComplementaryServices = true
                        } else {
                            showComplementaryServices = false
                        }
                        applicationStatusItems.push(<ApplicationStatusItem key={0} index={0} onArrowClick={this.onArrowClick} showComplementaryServices={false} payload={items[0]} complementaryServicesArray={this.getComplementaryServices(items[0].supportingApplication)} />)
                        applicationStatusItems.push(<ApplicationStatusItem key={1} index={1} onArrowClick={this.onArrowClick} showComplementaryServices={showComplementaryServices} payload={items[1]} complementaryServicesArray={this.getComplementaryServices(items[1].supportingApplication)} />)
                    }
                } else {
                    applicationStatusItems.push(<ApplicationStatusItem key={0} index={0} onArrowClick={this.onArrowClick} showComplementaryServices={showComplementaryServices} payload={items[0]} complementaryServicesArray={this.getComplementaryServices(items[0].supportingApplication)} />)
                    applicationStatusItems.push(<ApplicationStatusItem key={1} index={1} onArrowClick={this.onArrowClick} showComplementaryServices={showComplementaryServices} payload={items[1]} complementaryServicesArray={this.getComplementaryServices(items[1].supportingApplication)} />)
                }
            } else {
                items.map((item, index) => {
                    if (clickIndex != null && clickIndex === index.toString()) {
                        if (isShown === 'false') {
                            showComplementaryServices = true
                        }
                    } else {
                        showComplementaryServices = false
                    }
                    return (applicationStatusItems.push(<ApplicationStatusItem key={index} index={index} onArrowClick={this.onArrowClick} showComplementaryServices={showComplementaryServices} payload={items[index]} complementaryServicesArray={this.getComplementaryServices(items[index].supportingApplication)} />))
                })
            }
            this.setState({ response: response, itemsArray: applicationStatusItems })
        }
    }

    onArrowClick(e) {
        var index = e.target.id
        var isShown = e.target.name
        this.setState({ showComplementaryServices: !this.state.showComplementaryServices }, () => {
            this.setApplicationStatusWidget(this.state.response, index, isShown)
        })
    }

    getComplementaryServices(servicesArray) {
        var itemsArray = []
        servicesArray.map((item, index) => {
            itemsArray.push(
                <div className="housingAccordionContent">
                    <strong>{item.typeName}</strong>
                    <div>
                        <span class="housingRequestsince">{translate('applicationNo')}:</span>
                        <t></t><strong className="selectedcolor-20">&nbsp;{item.supportingAppNo}</strong>
                    </div>
                    <div>
                        <span class="housingRequestsince">{translate('status')}</span>
                        <t></t><strong className="selectedcolor-20">&nbsp;{item.status}</strong>
                    </div>
                    <p class="housingRequestsince"> {item.since} | {item.createdDate} </p>
                </div>
            )
        })
        return (itemsArray)
    }

    render() {
        return (
            <div className="col-md-6">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ApplicationStatus.svg'} alt="Application Status" /></span>
                        <span>{translate('application-status-widget-heading')}</span>
                    </div>
                    <div className="widgetContant">
                        {this.state.itemsArray.length > 0 ? < div className="widgetContentIn">{this.state.itemsArray}</div> : <NoData message={translate('nodata')} />}
                        {this.state.itemsArray.length > 0 ? <div className="widgetsMoreBtn">
                            <hr></hr>
                            <a href={this.state.itemsArray.length > 0 ? "/applicationStatus" : null}>{translate('show-more')}<img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/arrow.svg'} alt="Click" /></a>
                        </div> : null}
                    </div>
                </div>
            </div >
        )
    }
}

export default ApplicationStatusWidget
