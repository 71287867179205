import React from 'react'
import { AppContext } from '../../Providers/context'
import { LOCALES } from '../../Providers/i18n'

class Header extends React.Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {


    return (
      <div className="container-fluid logo_wrapper">
        <h1>
          <div className="container">
            <div className="row">
              <div className="col gov">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.smartdubai.ae/" target='blank'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></a> : <a href="https://www.smartdubai.ae/ar/home-page" target='blank'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></a>}</div>
              <div className="col mrhe">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.mbrhe.gov.ae/en/" target='blank'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.png'} alt='MBRHE' /></a> : <a href="https://www.mbrhe.gov.ae/ar/" target='blank'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.png'} alt='MBRHE' /></a>}</div>
            </div>
          </div>
        </h1>
      </div>
    )
  }
}

export default Header