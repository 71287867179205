// import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';
import React from "react";
import { AppContext } from '../Providers/context'
import { LOCALES } from '../Providers/i18n'

class SpeechComponent extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        // this.handleSpeechRecognition = this.handleSpeechRecognition.bind(this);
        // this.started = false;
        // this.createPonyfill = createPonyfill({
        //     credentials: {
        //         region: 'uaenorth',
        //         subscriptionKey: 'ef4bd311f81b4976a3e5b77661e8ec47'
        //     }
        // });
    }

    handleSpeechRecognition(locale) {
//         const {SpeechGrammarList, SpeechRecognition} = this.createPonyfill;
//         const recognition = new SpeechRecognition();
//         recognition.grammars = new SpeechGrammarList();
//         recognition.lang = locale;
//         recognition.interimResults = true;
// //        recognition.grammars.phrases = ['Tuen Mun', 'Yuen Long'];
//         const SpeechMic = document.getElementById("SpeechMic");
//         const textBox = document.getElementById("newReqText");
//         recognition.onresult = ({ results }) => {
//             if (results[0][0]) {
//                 var speechTextObj = results[0][0];
//                 var txt = speechTextObj.transcript.replace(".", "");
//                 textBox.value = txt;
//                 if (results[0].isFinal) {
//                     this.props.validateUserRequest(txt);
//                 }
//         }
//         };
//         recognition.onend = () => {
//             SpeechMic.src = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mic_icon.svg";
//         };
//         if (this.started) {
//             SpeechMic.src = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mic_icon.svg";
//             this.started = false;
//         } else {
//             SpeechMic.src = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mic_icon_hover.svg";
//             this.started = true;
//             recognition.start();
//         }
    }

    render() {
        const locale = this.context.state.siteLang === LOCALES.ENGLISH ? LOCALES.CHATBOT_EN : LOCALES.CHATBOT_AR;
        return (
                <button onClick={() => this.handleSpeechRecognition(locale)} className="mic">
                    <img id="SpeechMic" src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mic_icon.svg" alt="" /></button>
                );
    }
}

export default SpeechComponent;