import CONSTANTS from '../constants/dashboard-constants'
import EarlySettlementActions from "./earlysettlement-actions";

class EarlySettlementService {
    
    earlySettlementRequest(applicationNumber, local, files) {
        var url = CONSTANTS.earlySettlementRequestEndpoint + applicationNumber + local
        const formData = new FormData()
        formData.append('certificate', files)
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(EarlySettlementActions.earlySettlementRequest).catch()
        }
    }

    getFinancialStatusRequest(local) {
        var url = CONSTANTS.financialStatusEndpoint + local
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(EarlySettlementActions.financialStatusRequest).catch()
        }
    }
}

export default new EarlySettlementService()