import React from 'react'
import translate from '../../Providers/i18n/Translate'
class InputComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null }
        this.typeValidation = this.typeValidation.bind(this)
        this.input = this.input.bind(this)
    }

    render() {
        return (
            this.props.inputProps ? this.input(this.props.inputProps) : <span className="redStar">Properties required as {sampleObject}</span>
        )
    }

    validate(e) {
        if (this.props.inputProps.isRequired) {
            if (e.target.value.trim().length === 0) {
                this.errorMessage(e, translate('emptyvalidation'))
                this.sendResponse(e, false)
            }
            else {
                if (this.props.inputProps.type === 'textArea') {
                    if (e.target.value.trim().length < this.props.inputProps.minLength || e.target.value.trim().length > this.props.inputProps.maxLength) {
                        this.errorMessage(e, translate('DescrMessage'))
                        this.sendResponse(e, false)
                    } else {
                        this.typeValidation(e)
                    }
                } else {
                    this.typeValidation(e)
                }
            }
        } else {
            this.typeValidation(e)
        }
    }

    typeValidation(e) {
        this.setState({ error: null })
        if (this.props.inputProps.type === "text") {
            this.sendResponse(e, true)
        }
        else if (this.props.inputProps.type === 'number') {
            !regexp.test(e.target.value) ? this.errorMessage(e, translate('numberValidation')) : this.sendResponse(e, true)
        } else if (this.props.inputProps.type === 'email') {
            !regexpEmail.test(e.target.value) ? this.errorMessage(e, translate('emailValidation')) : this.sendResponse(e, true)
        } else if (this.props.inputProps.type === 'phone') {
            !regexpPhone.test(e.target.value) ? this.errorMessage(e, translate('mobileValidation')) : this.sendResponse(e, true)
        } else if (this.props.inputProps.type === 'eid') {
            !regexpEID.test(e.target.value) ? this.errorMessage(e, translate('eidValidation')) : this.sendResponse(e, true)
        } else {
            this.sendResponse(e, true)
        }
    }

    sendResponse(e, isValid) {
        this.props.inputProps.changeListener(e, isValid)
    }

    errorMessage(e, message) {
        this.setState({ error: <div className="alertWarning">{message}</div> })
        this.sendResponse(e, false)
    }

    input() {
        if (this.props.inputProps.type === "select") {
            return (< div className={this.props.inputProps.class ? this.props.inputProps.class : "inputFormRow col-md-6"} >
                <label className={this.props.isError ? "errorlabel" : ""} style={{ display: this.props.inputProps.display }}>{this.props.inputProps.lable}{this.props.inputProps.isRequired && <span className="redStar">*</span>}</label>
                <select style={{ display: this.props.inputProps.display }} name={this.props.inputProps.name} value={this.props.inputProps.value} defaultValue={this.props.inputProps.defaultValue} onChange={this.validate.bind(this)} disabled={this.props.inputProps.disabled}>
                    {this.props.inputProps.optionsArray}
                </select>
                {this.state.error}
            </div>)
        } else if (this.props.inputProps.type === "textArea") {
            return (<div>
                <label className={this.props.isError ? "errorlabel" : ""}>{this.props.inputProps.lable} {this.props.inputProps.isRequired && <span className="redStar">*</span>}</label>
                <textarea minLength={this.props.inputProps.minLength} maxLength={this.props.inputProps.maxLength} onBlur={this.props.inputProps.update ? this.props.inputProps.update : null} onKeyUp={this.validate.bind(this)} placeholder={this.props.inputProps.placeHolder} disabled={this.props.inputProps.disabled} required={this.props.inputProps.isRequired} type="text" name={this.props.inputProps.name} defaultValue={this.props.inputProps.defaultValue === 'null' ? '' : this.props.inputProps.defaultValue} value={this.props.inputProps.value} onChange={this.validate.bind(this)} />
                {this.state.error}
            </div>)
        } else {
            return (< div className={this.props.inputProps.class ? this.props.inputProps.class : "inputFormRow col-md-6"} >
                <label style={{ display: this.props.inputProps.display }} className={this.props.isError ? "errorlabel" : ""}>{this.props.inputProps.lable} {this.props.inputProps.isRequired && <span className="redStar">*</span>}</label>
                <input ref={myinput => (this.inputRef = myinput)} style={{ display: this.props.inputProps.display }} placeholder={this.props.inputProps.placeHolder} maxLength={this.props.inputProps.maxLength} onBlur={this.props.inputProps.update ? this.props.inputProps.update : this.focusOut.bind(this)} onKeyUp={this.validate.bind(this)} disabled={this.props.inputProps.disabled} required={this.props.inputProps.isRequired} type="text" name={this.props.inputProps.name} value={this.props.inputProps.value ? this.props.inputProps.value : this.props.inputProps.defaultValue} onChange={this.validate.bind(this)} />
                {this.state.error}
            </div >)
        }
    }

    focusOut(e) {
        this.inputRef.value = this.convertArabicToEnglishNumber(this.inputRef.value)
        this.validate(e)
    }

    convertArabicToEnglishNumber(arabicNumber) {
        var returnVal = ''
        Array.prototype.map.call(arabicNumber.toString(), eachLetter => {
            var index = arabicNumbers.indexOf(eachLetter)
            var value = index !== -1 ? englishNumbers[index] : eachLetter
            returnVal = returnVal + value
        })
        return returnVal
    }
}

const regexp = new RegExp(`^[0-9٠-٩]*$`);
const regexpEmail = new RegExp(/^[a-zA-Z0-9٠-٩._-]+@[a-zA-Z0-9٠-٩.-]+\.[a-zA-Z]{2,4}$/);
const regexpEID = new RegExp(/^784[0-9٠-٩]{4}[0-9٠-٩]{7}[0-9٠-٩]{1}$/);
const regexpPhone = new RegExp(/^(?:\+971|\+٩٧١|00971|٠٠٩٧١|0|٠|971|٩٧١)(?:2|٢|3|٣|4|٤|6|٦|7|٧|9|٩|50|٥٠|51|٥١|52|٥٢|53|٥٣|54|٥٤|55|٥٥|56|٥٦|67|٦٧|58|٥٨|59|٥٩)[0-9٠-٩]{7}$/);
const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']
const englishNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
const sampleObject = 'inputProps { lable: ,isRequired: ,name: ,defaultValue: ,changeListener:,placeHolde:,type:{number,email,password,phone} } '
export default InputComponent