import MaintenanceInsuranceConstants from './maintenance-insurance-constants'
import BaseStore from '../../BaseStore'

class MaintenanceInsuranceStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this.registerToActions.bind(this))
        this.maintenanceInsuranceResponse = null
    }

    registerToActions(action) {
        switch (action.actionType) {
            case MaintenanceInsuranceConstants.MAINTENANCEINSURANCE:
                this.maintenanceInsuranceResponse = action.response
                this.emitChange()
                break
            default:
                break
        }
    }

    get response() {
        return this.maintenanceInsuranceResponse
    }
}

export default new MaintenanceInsuranceStore()