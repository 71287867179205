import React from 'react'
import translate from '../../Providers/i18n/Translate'

function HousingRequestItem(props) {
    return (
        <div className="col-md-6 housingRequestWrapperAll">
            <div className="houseingRequestInn housingRequestWrapper">
                <div className="row no-gutters">
                    <div className="col-md-2">
                        <i><img src={props.image} alt="MBRHE" /></i>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-12">
                                <strong>{props.payload.requestType > 0 ? props.payload.requestTypeDesc : props.payload.service}</strong>
                                <span>{translate('ticket-number')}</span>
                                <span>{props.payload.ticket}</span>
                            </div>
                            <div className="col-12">
                                <span>{translate('status')}: </span>
                                <span className={props.class}>{props.payload.statusName}</span>
                            </div>
                            <div className="col-12">
                                <p className="housingRequestsince">{props.payload.createdAt} | {props.payload.since}</p>
                                {props.payload.comments && props.payload.comments.length > 0 ?
                                    <div className="remarkWrapper">
                                        <span>{translate('housing-remarks')} </span>
                                        <span className="selectedcolor-60">{props.payload.comments}</span>
                                    </div> :
                                    null}
                            </div>
                        </div>
                    </div>
                    {props.payload.enableEdit ? <div className="col-md-3 housingRequestBtns">
                        <button value={props.payload.requestType === 1 ? 5 : 15} name={props.payload.ticket} id={props.payload.requestType} title={props.payload.applicationNo} onClick={props.modifyTicket}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg'} alt="Edit" /> {translate('modify')} </button>
                        {props.payload.requestType ? null : <button name={props.payload.ticket} onClick={props.deleteClick}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete.svg'} alt="Delete" /> {translate('delete')} </button>}
                    </div> : null}
                </div>
            </div>
        </div>
    )
}
export default HousingRequestItem