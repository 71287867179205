import AppDispatcher from '../Dispatcher';
import DocumentVerificationConstant from './DocumentVerificationConstant';


class DocumentVerificationAction {

  verifyDocument(response) {
    AppDispatcher.dispatch({
      actionType: DocumentVerificationConstant.VERIFY_DOCUMENT,
      response: response
    })
  }
}

export default new DocumentVerificationAction();