import React from "react"
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'

class ExtensionAdditionComponents extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.next = this.next.bind(this)
        this.onChange = this.onChange.bind(this)
        this.validate = this.validate.bind(this)
    }

    onChange(e) {
        this.props.reference.updateSocialStudyPayload(e.target.name, e.target.value.toString().toLowerCase().trim() === 'true' ? 1 : 0,4)
    }

    validate() {
        this.props.reference.reset()
        return this.props.reference.state.socialStudyPayload.requireExternalKitchen !== null &&
            this.props.reference.state.socialStudyPayload.requireStore !== null &&
            this.props.reference.state.socialStudyPayload.requireWashingRoom !== null &&
            this.props.reference.state.socialStudyPayload.requireServiceRoomBath !== null &&
            this.props.reference.state.socialStudyPayload.requireNurseRoomBath !== null &&
            this.props.reference.state.socialStudyPayload.requireDriverRoomBath !== null &&
            this.props.reference.state.socialStudyPayload.requireParking !== null ? true : false
    }

    next() {
        if (this.validate()) {
            this.props.reference.postSocialStudy(3)
        } else {
            this.props.reference.showError(translate('requiredErrorMessage'))
        }
    }

    render() {
        return (
            <div className="col">
                <div className="formSection row">
                    <div className="col">
                        <div className="row">
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('ExternalKitchen')}<span className="redStar">*</span></p>
                                <input type="radio" id="ExternalKitchenyes" name="requireExternalKitchen" value={true} checked={this.props.reference.state.socialStudyPayload.requireExternalKitchen === 1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="ExternalKitchenyes">{translate("yes")}</label>
                                <input type="radio" id="ExternalKitchenno" name="requireExternalKitchen" value={false} checked={this.props.reference.state.socialStudyPayload.requireExternalKitchen === 0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="ExternalKitchenno">{translate("no")}</label>
                            </div>
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('StorageRoom')}<span className="redStar">*</span></p>
                                <input type="radio" id="StorageRoomyes" name="requireStore" value={true} checked={this.props.reference.state.socialStudyPayload.requireStore === 1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="StorageRoomyes">{translate("yes")}</label>
                                <input type="radio" id="StorageRoomno" name="requireStore" value={false} checked={this.props.reference.state.socialStudyPayload.requireStore === 0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="StorageRoomno">{translate("no")}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('LaundryRoom')}<span className="redStar">*</span></p>
                                <input type="radio" id="LaundryRoomyes" name="requireWashingRoom" value={true} checked={this.props.reference.state.socialStudyPayload.requireWashingRoom === 1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="LaundryRoomyes">{translate("yes")}</label>
                                <input type="radio" id="LaundryRoomno" name="requireWashingRoom" value={false} checked={this.props.reference.state.socialStudyPayload.requireWashingRoom === 0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="LaundryRoomno">{translate("no")}</label>
                            </div>
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('HelpingCrewsMasterBedroom')}<span className="redStar">*</span></p>
                                <input type="radio" id="HelpingCrewsMasterBedroomyes" name="requireServiceRoomBath" value={true} checked={this.props.reference.state.socialStudyPayload.requireServiceRoomBath === 1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="HelpingCrewsMasterBedroomyes">{translate("yes")}</label>
                                <input type="radio" id="HelpingCrewsMasterBedroomno" name="requireServiceRoomBath" value={false} checked={this.props.reference.state.socialStudyPayload.requireServiceRoomBath === 0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="HelpingCrewsMasterBedroomno">{translate("no")}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('DriversMasterBedroom')}<span className="redStar">*</span></p>
                                <input type="radio" id="DriversMasterBedroomyes" name="requireDriverRoomBath" value={true} checked={this.props.reference.state.socialStudyPayload.requireDriverRoomBath === 1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="DriversMasterBedroomyes">{translate("yes")}</label>
                                <input type="radio" id="DriversMasterBedroomno" name="requireDriverRoomBath" value={false} checked={this.props.reference.state.socialStudyPayload.requireDriverRoomBath === 0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="DriversMasterBedroomno">{translate("no")}</label>
                            </div>
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('NursesMasterBedroom')}<span className="redStar">*</span></p>
                                <input type="radio" id="NursesMasterBedroomyes" name="requireNurseRoomBath" value={true} checked={this.props.reference.state.socialStudyPayload.requireNurseRoomBath === 1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="NursesMasterBedroomyes">{translate("yes")}</label>
                                <input type="radio" id="NursesMasterBedroomno" name="requireNurseRoomBath" value={false} checked={this.props.reference.state.socialStudyPayload.requireNurseRoomBath === 0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="NursesMasterBedroomno">{translate("no")}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('Parking')}<span className="redStar">*</span></p>
                                <input type="radio" id="Parkingyes" name="requireParking" value={true} checked={this.props.reference.state.socialStudyPayload.requireParking === 1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="Parkingyes">{translate("yes")}</label>
                                <input type="radio" id="Parkingno" name="requireParking" value={false} checked={this.props.reference.state.socialStudyPayload.requireParking === 0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="Parkingno">{translate("no")}</label>
                            </div>
                        </div>
                        <hr></hr>
                    </div>
                    <div className="col-md-12 formSectionBtn ">
                        <button className="stepNextBtn" onClick={this.next}>{translate('next')}</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExtensionAdditionComponents