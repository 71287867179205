import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import CONSTANTS from '../constants/dashboard-constants'

class TypicalVillasUtils {

    getVillaTitle(lookupsPayload, local) {
        return local === 'en' ? lookupsPayload.villa_title : lookupsPayload.villa_title_ar;
    }

    getVilla(villaId) {
        var villaObject = {}
        var villasArray = localStorage.getItem('villasArray')
        if (villasArray) {
            JSON.parse(villasArray).some((villa) => {
                villaObject = villa
                return villa.id === villaId
            })
        }
        return villaObject
    }

    getStyleLabel(lookupsPayload, payload, selectedStyleId, local) {
        var title = ''
        if (lookupsPayload) {
            if (Number(selectedStyleId) > 0) {
                lookupsPayload.styles.some((style, index) => {
                    title = local === 'en' ? lookupsPayload.styles[index].title : lookupsPayload.styles[index].title_ar
                    return style.id === Number(selectedStyleId)
                })
            } else {
                var defaultStyleID = payload.styles[0].id
                lookupsPayload.styles.some((style) => {
                    title = local === 'en' ? style.title : style.title_ar
                    return style.id === defaultStyleID
                })
            }
        }
        return title
    }

    getImageLabel(imgId, lookupsPayload, local) {
        var title = ''
        if (lookupsPayload) {
            lookupsPayload.images.some((image, index) => {
                title = local === 'en' ? lookupsPayload.images[index].title : lookupsPayload.images[index].title_ar
                return image.id === Number(imgId)
            })
        }
        return title
    }

    getStyleArea(payload, styleID) {
        var area = 0
        if (payload.styles) {
            area = payload.styles[0].totalArea
            if (Number(styleID) > 0) {
                payload.styles.some((style) => {
                    area = style.totalArea
                    return style.id === Number(styleID)
                })
            }
        }

        return area
    }

    getStyleVideo(payload, styleID) {
        var video = 'https://www.youtube.com/playlist?list=PLPzcjzS6JSTx08RTJF0HZ94CI9KrejdRG'
        if (payload.styles) {
            if (Number(styleID) > 0) {
                payload.styles.some((style) => {
                    video = style.video
                    return style.id === Number(styleID)
                })
            }
        }
        return video
    }

    getStyleImageName(payload, styleID) {
        var name = payload.styles[0].totalArea
        if (Number(styleID) > 0) {
            payload.styles.some((style) => {
                name = style.image
                return style.id === Number(styleID)
            })
        }
        return name
    }

    getImageURLArray(villaPayload, lookupsPayload, local) {
        return (<OwlCarousel className="owl-theme owl-theme" dots={true} items={1} autoplay={false} loop={false} margin={0} nav info={true} >
            {
                villaPayload.images.map((obj, index) => {
                    return (
                        <div className='item' key={index}>
                            <div className="houseBookinType">
                                <span>{this.getImageLabel(obj.id, lookupsPayload, local)}</span>
                            </div>
                            <img src={CONSTANTS.unifiedVillasImageURL + villaPayload.id + "/" + obj.image} alt="villaimage" />
                        </div>
                    )
                })
            }
        </OwlCarousel>)
    }

    getStyleImageURLArray(selectedStyleId, villaPayload) {
        return (<OwlCarousel className="owl-theme owl-theme" dots={true} items={1} autoplay={false} loop={false} margin={0} nav info={true} >
            <div className='item' key={selectedStyleId}>
                <img src={CONSTANTS.unifiedVillasImageURL + villaPayload.id + "/" + this.getStyleImageName(villaPayload, selectedStyleId)} alt="villaimage" />
            </div>
        </OwlCarousel>)
    }

    getImage(villaId, imageName, index) {
        return (
            <OwlCarousel className="owl-theme owl-theme" dots={true} items={1} autoplay={false} loop={false} margin={0} nav info={true} >
                <div className='item' key={index}>
                    <img src={CONSTANTS.unifiedVillasImageURL + villaId + "/" + imageName} alt="villaimage" />
                </div>
            </OwlCarousel>
        )
    }

    getConsultant(consultantID) {
        var consultantObject = {}
        if (localStorage.getItem("consultants")) {
            var consultants = JSON.parse(localStorage.getItem("consultants")).consultants
            if (consultants != null) {
                var isConsultantInfo = consultants.some((consultant) => {
                    consultantObject = consultant
                    return consultant.id === consultantID
                })
            }
        }
        return isConsultantInfo ? consultantObject : ''
    }
}

export default new TypicalVillasUtils();