import CONSTANTS from '../constants/dashboard-constants'
import MaintenanceInsuranceActions from "./maintenance-insurance-actions";

class MaintenanceInsuranceService {
    fetchMaintenanceInsuranceRequest(pageSize, local) {
        var url = CONSTANTS.maintenanceInsuranceEndpoint + local + '&page=0&pageSize=' + pageSize
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(MaintenanceInsuranceActions.fetchMaintenanceInsuranceRequest).catch()
        }
    }
}

export default new MaintenanceInsuranceService()