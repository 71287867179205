import React from "react"
import translate from '../../Providers/i18n/Translate'
import { PieChart, Pie, Cell } from 'recharts'

const RenderProgressbar = (props) => {
  const { cx, cy } = props
  return (
    <g>
      <text fontSize={15} fontFamily="RegularFont" x={cx} y={cy} dy={-10} textAnchor="middle" >{(props.value)}%</text>
      <text fontSize={8} fontFamily="RegularFont" x={cx} y={cy} dy={6} text-wrap="wrap" text-anchor="middle" textAnchor="middle">{translate('BatchCompletionRate')}</text>
    </g>
  )
}
const COLORS = ['#326295', '#00C49F', '#FFBB28', '#FF8042', '#F6303F', '#0296CE', '#C224B9', '#C1CF32', '#0068AD', '#F29400'];
const min = 1;
const max = 10;
const getrandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const getColor = (data) => {
  if (data[0] > 0 && data[0] <= 30) {
    return 4
  } else if (data[0] > 30 && data[0] <= 50) {
    return 2
  } else if (data[0] > 50 && data[0] <= 70) {
    return 0
  } else if (data[0] > 70) {
    return 1
  } else {
    return 0
  }
}

class CircularProgressChart extends React.Component {

  render() {
    return (
      <PieChart width={100} height={100} >
        <Pie isAnimationActive={false} dataKey={"value"} activeIndex={null} activeShape={RenderProgressbar} data={this.props.data} cx={45} cy={45} innerRadius={40} outerRadius={48} fill={'#F3F3F3'} />

        {this.props.data[0].value > 0 ? <Pie textFill={'#326295'} dataKey={"value"} activeIndex={0} cx={45} cy={45} innerRadius={40} outerRadius={48} activeShape={RenderProgressbar} data={this.props.data} />
          : <Pie textFill={'#326295'} dataKey={"value"} activeIndex={0} cx={45} cy={45} innerRadius={0} outerRadius={0} activeShape={RenderProgressbar} data={this.props.data} />}

        {this.props.data[0].value > 0 ? <Pie dataKey={"value"} activeIndex={1} data={this.props.data} cx={45} cy={45} innerRadius={40} outerRadius={48}  >
          {
            this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={index == 0 ? COLORS[getColor(this.props.data)] : '#F3F3F3'} />)
          }
        </Pie>
          : null}
      </PieChart>
    );
  }
}
export default CircularProgressChart