import React from 'react'
import History from '../../History'
import Loader from '../utils/LoaderBars'
import Captcha from '../utils/captcha'
import InputComponent from '../utils/inputComponent'
import ErrorComponent from '../utils/errorComponent'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../constants/dashboard-constants'
import LoadingProgress from 'react-js-loading-progress-bar'
import LoginStore from '../../ApplicationBase/Login/LoginStore'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'
import AuthService from '../../ApplicationBase/Login/AuthService'
import CommonUtils from '../../utils/CommonUtils'
import TowhomServiceComponent from './towhom-service-component'

class TowhomServiceComponentNew extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            errors: [],
            error: null,
            history: null,
            idbarahnumber: '',
            showResults: false,
            primaryMobileNo: '',
            showOtpPopup: false,
            showLoading: false,
            showMessage: false,
            message: '',
            total: 135,
            current: 0,
            showFileLoader: false,
            isCaptchaSuccess: false,
            refreshCaptcha: false
        }
        this.goOTP = this.goOTP.bind(this)
        this.showPDF = this.showPDF.bind(this)
        this.stepBack = this.stepBack.bind(this)
        this.addError = this.addError.bind(this)
        this.goManual = this.goManual.bind(this)
        this.resendOtp = this.resendOtp.bind(this)
        this._onChange = this._onChange.bind(this)
        this.verifyOTP = this.verifyOTP.bind(this)
        this.showError = this.showError.bind(this)
        this.goBackPage = this.goBackPage.bind(this)
        this.otpSuccess = this.otpSuccess.bind(this)
        this.setProfile = this.setProfile.bind(this)
        this.newRequest = this.newRequest.bind(this)
        this.acceptClick = this.acceptClick.bind(this)
        this.removeError = this.removeError.bind(this)
        this._getResponse = this._getResponse.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.isContractor = this.isContractor.bind(this)
        this.failCallback = this.failCallback.bind(this)
        this.toggleRefresh = this.toggleRefresh.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.publicUserRequest = this.publicUserRequest.bind(this)
        this.publicUserSuccess = this.publicUserSuccess.bind(this)
        this.changeProgressBar = this.changeProgressBar.bind(this)
        this.generateCertificate = this.generateCertificate.bind(this)
        this.goPDFGeneratedMessage = this.goPDFGeneratedMessage.bind(this)
        this.captchaValidateSuccess = this.captchaValidateSuccess.bind(this)
        this.generateSuccessCallback = this.generateSuccessCallback.bind(this)
        this.captchaValidationCallback = this.captchaValidationCallback.bind(this)
        this.publicUserSuccessResponse = this.publicUserSuccessResponse.bind(this)
    }

    _onChange() {
        this.setState({ showLoading: false })
        this.setState(this._getResponse())
    }

    _getResponse() {
        if (LoginStore.isLoggedIn() && LoginStore.isSuccess()) {
            if (LoginStore.userTypeResponse && LoginStore.userTypeResponse.hasOwnProperty('user_type')) {
                localStorage.setItem('userType', LoginStore.userTypeResponse.user_type);
            }
            this.setState({ showOtpPopup: false })
            if (LoginStore.jwt) {
                localStorage.setItem('accessToken', LoginStore.jwt)
                this.loggedInUserRequestNew(LoginStore.jwt)
            }
            return {
                otpResponse: LoginStore._userType,
                userLoggedIn: LoginStore.isLoggedIn()
            }
        } else {
            toast.success(LoginStore._response)
            return {
                apiError: LoginStore._userType,
            }
        }
    }

    componentDidMount() {
        LoginStore.addChangeListener(this._onChange);
        if (this.props.location.state) {
            this.setState({ response: this.props.location.state.response })
        }
        this.addError("emiratesId")
        this.addError("name")
        this.addError("idbarahnumber")
        this.addError("email")
        this.addError("primaryMobileNo")
        if (this.isLogedIn() && localStorage.dashboard) {
            this.setState({ emiratesId: JSON.parse(localStorage.dashboard).payload.emiratesId })
            this.setProfile(JSON.parse(localStorage.dashboard).payload, this.props.history)
        }
        if (localStorage.emiratesId) {
            this.removeError("emiratesId")
            this.setState({ "emiratesId": localStorage.emiratesId })
        }
        if (!this.isLogedIn()) {
            require('../../ApplicationBase/Login/style_24.css')
        }
    }

    isLogedIn() {
        return localStorage.getItem('accessToken') && !this.isContractor()
    }

    isContractor() {
        if (localStorage.getItem('accessToken') && localStorage.dashboard) {
            return localStorage.getItem('accessToken') && JSON.parse(localStorage.dashboard).payload.userType === 2
        } else {
            return false
        }
    }

    async setProfile(props, history) {
        if (props) {
            if (props.emiratesId && props.emiratesId.length !== 0) {
                await this.removeError('emiratesId')
            }
            if (props.name && props.name.length !== 0) {
                await this.removeError('name')
            }
            if (props.idbarahnumber && props.idbarahnumber.length !== 0) {
                await this.removeError('idbarahnumber')
            }
            if (props.email && props.email.length !== 0) {
                await this.removeError('email')
            }
            // if (props.unifiednumber && props.unifiednumber.length !== 0) {
            //     await this.removeError('unifiednumber')
            // }
            if (props.primaryMobileNo && props.primaryMobileNo.length !== 0) {
                await this.removeError('primaryMobileNo')
            }
            this.setState({
                emiratesId: props.emiratesId ? props.emiratesId : "",
                name: props.name ? props.name : "",
                idbarahnumber: props.idbarahnumber ? props.idbarahnumber : '',
                email: props.email ? props.email : "",
                // unifiednumber: props.unifiednumber ? props.unifiednumber : '',
                primaryMobileNo: props.primaryMobileNo ? props.primaryMobileNo : "",
                history: history,
                payload: {},
                showResults: false,
                error: null
            })
        }
    }

    async addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            await this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    handleChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (name === "emiratesId" && isValid) {
            localStorage.setItem("emiratesId", value)
        }
        if (name !== "otpCode") {
            if (isValid) {
                this.removeError(name)
            } else {
                this.addError(name)
            }
        }
    }

    verifyOTP() {
        if (this.state.otpCode) {
            this.setState({ showLoading: true })
            AuthService.customerLoginUsingOtpNo(this.state.emiratesId, this.state.otpCode, this.context.state.siteLang).catch(function (error) { this.failCallback(error) }.bind(this));
        } else {
            this.showError(translate('requiredErrorMessage'))
        }
    }

    resendOtp() {
        this.setState({ showLoading: true })
        AuthService.customerLoginUsingEmiratedId(this.state.emiratesId, this.context.state.siteLang).catch(function (error) { this.failCallback(error) }.bind(this));
    }

    otpSuccess(response) {
        if (response.success) {

        }
    }

    generateCertificate(e) {
        e.preventDefault();
        this.reset()
        if (this.state.temsAccept) {
            if (this.state.isCaptchaSuccess) {
                this.captchaValidateSuccess(true, this.state.captchaMessage)
            } else {
                const captchaMsg = this.state.captchaMessage ? this.state.captchaMessage : translate("invalid_picture")
                this.showError(captchaMsg)
            }
        } else {
            this.setState({ error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
        }
    }

    loggedInUserRequestNew(jwt) {
        this.changeProgressBar()
        var token = 'bearer ' + (jwt != null ? jwt : localStorage.getItem('accessToken'))
        fetch(CONSTANTS.generateToWhomNewCertificateEndPoint + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': token
            },
        }).then(response => response.json()).then(this.generateSuccessCallback).catch(this.failCallback);
    }

    generateSuccessCallback(response) {
        this.setState({ showFileLoader: false, current: 0 })
        if (response.success) {
            if (response.alreadyCreatedManual !== undefined && response.alreadyCreatedManual) {
                this.goPDFGeneratedMessage(response.message)
            } else {
                this.showPDF(response)
            }
        } else {
            if (response.shouldApplyManual !== undefined && response.shouldApplyManual) {
                // this.goManual(response)
                this.goPDFGeneratedMessage(response.message)
            } else {
                this.goPDFGeneratedMessage(response.message)
            }
        }
    }

    publicUserRequest() {
        this.setState({ showLoading: true }, () => {
            this.toggleRefresh()
        })
        fetch(CONSTANTS.generateTowhomCertificateEndPointNew + "?local=" + this.context.state.siteLang + "&idn=" + this.state.emiratesId, {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => response.json()).then(this.publicUserSuccess).catch(this.failCallback);
    }

    publicUserSuccess(response) {
        this.setState({ refreshCaptcha: false, showLoading: false, showFileLoader: false, current: 0, response: response })
        if (response.success) {
            this.publicUserSuccessResponse(response)
        } else {
            if (response.shouldApplyManual !== undefined && response.shouldApplyManual) {
                if (this.isLogedIn()) {
                    this.setState({ showManual: true })
                } else {
                    this.showError(translate('visitoffice'))
                }
                // this.goManual(response)
            } else {
                this.showError(response.message)
            }
        }
    }

    goOTP() {
        this.setState({ showOtpPopup: true })
    }

    goManual(response) {
        localStorage.setItem('eid', this.state.emiratesId)
        History.push('/toWhoItMayConcernManual')
    }

    goPDFGeneratedMessage(message) {
        this.setState({ showFileLoader: false, current: 0, showMessage: true, message: message })
    }

    showPDF(response) {
        const pdfURL = CommonUtils.getBlobURL(response.payload.file)
        this.setState({ showMessage: true, showOtpPopup: false, response: response, showPDF: true, pdfView: <object data={pdfURL} type="application/pdf" width="300" height="200"><a href={pdfURL}>towhom.pdf</a></object> })
    }

    publicUserSuccessResponse(response) {
        if (response.isCustomerExist) {
            if (response.alreadyCreatedManual) {
                this.goPDFGeneratedMessage(response.message)
            } else {
                this.goOTP(response)
            }
        } else {
            if (response.alreadyCreatedManual) {
                this.goPDFGeneratedMessage(response.message)
            } else {
                this.showPDF(response)
            }
        }
    }

    captchaValidateSuccess() {
        if (this.isLogedIn()) {
            if (this.state.errors.indexOf("emiratesId") === -1) {
                this.loggedInUserRequestNew()
            } else {
                this.showError(translate('requiredErrorMessage'))
            }
        } else {
            if (this.state.errors.indexOf("emiratesId") === -1) {
                this.publicUserRequest()
            } else {
                this.showError(translate('requiredErrorMessage'))
            }
        }
    }

    failCallback(response) {
        this.setState({ showLoading: false })
        if (response.status === 400) {
            var responseJson = JSON.parse(response.response)
            toast.error(responseJson.error_description)
        } else {
            toast.error(response.message)
        }
    }

    closeCallback() {
        this.setState({ error: null, response: null })
    }

    loadFailed(response) {
        this.setState({ error: <ErrorComponent message={response.message} closeCalback={this.closeCallback} /> })
    }

    stepBack() {
        localStorage.removeItem("emiratesId")
        this.setState({ current: 0, showPDF: false, response: null, isCaptchaSuccess: false })
    }

    newRequest() {
        localStorage.removeItem("emiratesId")
        this.setState({ showMessage: false, message: "", showResults: false, response: '', temsAccept: false })
    }

    reset() {
        this.setState({ error: null })
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    toggleVisualCaptcha() {
        this.setState({ error: null, isVisualCaptcha: !this.state.isVisualCaptcha })
    }

    captchaInput(e) {
        const value = e.target.value
        this.setState({ captchaInput: value })
    }

    goBackPage() {
        localStorage.removeItem("emiratesId")
        if (this.isContractor()) {
            History.replace("/contractorDashboard")
        } else {
            History.replace("/customerDashboard")
        }
    }

    acceptClick(e) {
        if (e.target.id === "accept") {
            this.setState({ error: false, errorMessage: "", acceptClick: !this.state.acceptClick, temsAccept: true })
        }
        else {
            this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false, error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
        }
    }

    captchaValidationCallback(success, message) {
        this.setState({ error: null, isCaptchaSuccess: success, captchaMessage: message })
    }

    async changeProgressBar() {
        this.setState({ showFileLoader: true })
        for (let i = 0; i < this.state.total; i++) {
            await this.timeout(100);
            await this.setState({ current: i + 1 });
        }
        await this.timeout(100);
        this.setState({ showFileLoader: false, current: 0 })
    }

    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    toggleRefresh() {
        this.setState({ isCaptchaSuccess: false, refreshCaptcha: !this.state.refreshCaptcha })
    }

    render() {
        const state = this.state
        const showPDF = state.showPDF
        const referenceNo = state.response && state.response.payload && state.response.payload.referenceNo
        // const showPDF = state.response && state.response.payload ? state.response.payload.pdfUrl : ''
        // const pdfURL = state.response && state.response.payload ? state.response.payload.pdfUrl : ''
        const pdfURL = (state.response && state.response.payload && state.response.payload.file) ? CommonUtils.getBlobURL(state.response.payload.file) : ''
        const showForm = state.response && state.response.success ? false : true
        return (
            <div>
                {state.showManual ? <TowhomServiceComponent /> :
                    <div className="container-fluid toWhomWrapper">
                        {state.showFileLoader ? <div className="PopupBk loaderBG">
                            <div className='toWhomloadingBar'>
                                <LoadingProgress active={true} total={state.total} current={state.current} visualOnly />
                            </div>
                        </div> : null}
                        <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                        {state.showLoading ? <Loader /> : null}
                        {/* OTP PopupUp */}
                        {state.showOtpPopup ? <div className='PopupBk'></div> : null}
                        {state.showOtpPopup ? <div className='viewPopup toWhomOtpPopup'>
                            <span>{state.response.message}</span>
                            <div className='otpSection'>
                                <div className='otpWrapper'>
                                    <div className='otpOuter'>
                                        <div className='otpInner'>
                                            <input className='otpInput' name="otpCode" type='text' maxlength='6' onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <button onClick={this.resendOtp}>
                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/refresh.svg'} alt="Resend" /></i>
                                    <span>{translate('ResendPinCode')}</span>
                                </button>
                            </div>
                            <div className="alert alert-info" role="alert">
                                <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="information" /></span>
                                <span>{translate('tohomeOtpInfo')}  <a onClick={() => window.open("https://www.mbrhe.gov.ae/" + this.context.state.siteLang + "/aboutus/contact%20us/", "_blank")} href="">{translate('contactus')}</a></span>
                            </div>
                            <hr></hr>
                            <div className='formSectionBtn'>
                                <div className='buttonLR'>
                                    <button onClick={() => { this.setState({ showOtpPopup: false, response: null, isCaptchaSuccess: false, error: null }) }}>{translate('cancle')}</button>
                                    <button onClick={this.verifyOTP}>{translate('submit')}</button>
                                </div>
                            </div>
                        </div> : null}
                        {/* END OTP PopupUp */}

                        {/* TermsOfUse POPUP */}
                        {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                        {this.state.acceptClick ? <div className="acceptPop pdfView">
                            <button className="popupClose" onClick={this.acceptClick}>X</button>
                            <h2>{translate('TermsOfUse')}</h2>
                            <p>{translate('eligibleterms')}</p>
                            <div className="col-md-12 acceptPopBtn">
                                <div className="row">
                                    <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
                                </div>
                            </div>
                        </div> : null}

                        <div className="container mainSection">
                            <div className="row">
                                {/* breadcrumb */}
                                <div className="col-md-12">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a onClick={this.goBackPage} href="#!">{LoginStore.isLoggedIn() ? translate('dashboard') : translate('back')}</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">{translate('to-whom-lbl')}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg'} alt="to whom it may concern" /></i><span>{translate('to-whom-lbl')}</span></div>
                                </div>
                                <div className="col-md-12">
                                    {/* WITHOUT LOGIN */}
                                    {!state.showMessage && showForm ? <div className="service_contant_wrapper formSection">
                                        {/* <form> */}
                                        <div className='row'>
                                            <InputComponent inputProps={{ disabled: this.isLogedIn() && this.state.emiratesId !== undefined, lable: translate('emiratesId'), name: 'emiratesId', defaultValue: this.state.emiratesId, value: this.state.emiratesId, type: 'eid', changeListener: this.handleChange, isRequired: true, placeHolder: "784-2000-1234567-1" }} />
                                            <div className='col-md-6'>
                                                <div className="alertTopSpace alert alert-info" role="alert">
                                                    <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="information" /></span>
                                                    <span>{translate('towhominfomsg')}</span>
                                                </div>
                                            </div>
                                            <Captcha toggleRefresh={this.toggleRefresh} refresh={this.state.refreshCaptcha} captchaValidationCallback={this.captchaValidationCallback} />
                                            <div className='col-md-12 formSectionBtn'>
                                                <hr></hr>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className="customCheckBox ">
                                                            <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
                                                            {this.context.state.siteLang === 'en' ?
                                                                <label htmlFor="acceptCB">{translate('IAgreeToTheWebsite')} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/Terms-of-Use", "_blank")} href="">{translate('TermsOfUse')}</a> and <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/Privacy-Policy", "_blank")} href="">{translate('privacypolicy')}</a>.</span></label> :
                                                                <label htmlFor="acceptCB">{"أوافق على"} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/Terms-of-Use", "_blank")} href="">{"شروط الاستخدام"}</a> كما وأوافق على <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/Privacy-Policy", "_blank")} href="">{"سياسة الخصوصية"}</a> للموقع الإلكتروني الخاص بمؤسسة محمد بن راشد للإسكان.</span></label>}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 formSectionBtn'>
                                                        <button onClick={this.goBackPage}>{translate('back')}</button>
                                                        <button onClick={this.generateCertificate}>{translate('Continue')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </form> */}
                                    </div> : null}
                                </div>

                                {/* PDF VIEW */}
                                {/* <LoadingProgress active={true} total={this.state.total} current={this.state.current} showCompact /> */}
                                {showPDF ? <div className="col-md-12">
                                    <div className="service_contant_wrapper formSection">
                                        <div className='row'>
                                            <div className='col-lg-6 service_subTitle_wrapper'>
                                                <span>{translate('whomItMayConcernCertificate')}</span>
                                            </div>
                                            <div className='col-lg-6 toWhomItMayConcernPdfViewBtn'>

                                                <a href={pdfURL} download type="application/octet-stream" className="download" target='_blank'>
                                                    <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/save_white_icon.svg" alt="Download" /></i>
                                                    <span>{translate('DownloadTheCertificate')}</span>
                                                </a>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='whomItMayConcernPdfView'>
                                                    {state.pdfView}
                                                    {/* <object data={pdfURL} type="application/pdf" width="300" height="200"><a href={this.state.response.payload ? pdfURL : ''}>towhom.pdf</a></object> */}
                                                </div>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='row'>
                                            <div className='col-md-12 formSectionBtn'>
                                                <div className='buttonLR'>
                                                    <button onClick={this.stepBack}>{translate('back')}</button>
                                                    <button onClick={this.goBackPage}>{translate('exit')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                            {state.error}
                            {state.errorMessage}
                            {state.showMessage ? <div className="messages_wrapper success_message">
                                <HappinessMeter serviceCode={CONSTANTS.serviceCodes.ToWhomItMayConcern} transactionID={referenceNo} />
                                <div className="row">
                                    <div className="col-md-12">
                                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/success.svg'} alt="success" /></span>
                                        <h3>{this.state.message}</h3>
                                    </div>
                                    <div className="col-md-12 messages_wrapperBtn">
                                        <button onClick={this.newRequest}>{translate('make-new-request')}</button>
                                    </div>
                                </div>
                            </div> : null}
                        </div>
                    </div>}
            </div>
            // <div className="container-fluid toWhomWrapper">
            //     {state.showFileLoader ? <div className="PopupBk loaderBG">
            //         <div className='toWhomloadingBar'>
            //             <LoadingProgress active={true} total={state.total} current={state.current} visualOnly />
            //         </div>
            //     </div> : null}
            //     <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
            //     {state.showLoading ? <Loader /> : null}
            //     {/* OTP PopupUp */}
            //     {state.showOtpPopup ? <div className='PopupBk'></div> : null}
            //     {state.showOtpPopup ? <div className='viewPopup toWhomOtpPopup'>
            //         <span>{state.response.message}</span>
            //         <div className='otpSection'>
            //             <div className='otpWrapper'>
            //                 <div className='otpOuter'>
            //                     <div className='otpInner'>
            //                         <input className='otpInput' name="otpCode" type='text' maxlength='6' onChange={this.handleChange} />
            //                     </div>
            //                 </div>
            //             </div>
            //             <button onClick={this.resendOtp}>
            //                 <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/refresh.svg'} alt="Resend" /></i>
            //                 <span>{translate('ResendPinCode')}</span>
            //             </button>
            //         </div>
            //         <div className="alert alert-info" role="alert">
            //             <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="information" /></span>
            //             <span>{translate('tohomeOtpInfo')}  <a onClick={() => window.open("https://www.mbrhe.gov.ae/" + this.context.state.siteLang + "/aboutus/contact%20us/", "_blank")} href="">{translate('contactus')}</a></span>
            //         </div>
            //         <hr></hr>
            //         <div className='formSectionBtn'>
            //             <div className='buttonLR'>
            //                 <button onClick={() => { this.setState({ showOtpPopup: false, response: null, isCaptchaSuccess: false, error: null }) }}>{translate('cancle')}</button>
            //                 <button onClick={this.verifyOTP}>{translate('submit')}</button>
            //             </div>
            //         </div>
            //     </div> : null}
            //     {/* END OTP PopupUp */}

            //     {/* TermsOfUse POPUP */}
            //     {this.state.acceptClick ? <div className="PopupBk"></div> : null}
            //     {this.state.acceptClick ? <div className="acceptPop pdfView">
            //         <button className="popupClose" onClick={this.acceptClick}>X</button>
            //         <h2>{translate('TermsOfUse')}</h2>
            //         <p>{translate('eligibleterms')}</p>
            //         <div className="col-md-12 acceptPopBtn">
            //             <div className="row">
            //                 <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
            //             </div>
            //         </div>
            //     </div> : null}

            //     <div className="container mainSection">
            //         <div className="row">
            //             {/* breadcrumb */}
            //             <div className="col-md-12">
            //                 <nav aria-label="breadcrumb">
            //                     <ol className="breadcrumb">
            //                         <li className="breadcrumb-item"><a href={this.isContractor() ? "/contractorDashboard" : "/customerDashboard"}>{LoginStore.isLoggedIn() ? translate('dashboard') : translate('back')}</a></li>
            //                         <li className="breadcrumb-item active" aria-current="page">{translate('to-whom-lbl')}</li>
            //                     </ol>
            //                 </nav>
            //             </div>
            //         </div>

            //         <div className="row">
            //             <div className="col-md-12">
            //                 <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg'} alt="to whom it may concern" /></i><span>{translate('to-whom-lbl')}</span></div>
            //             </div>
            //             <div className="col-md-12">
            //                 {/* WITHOUT LOGIN */}
            //                 {!state.showMessage && showForm ? <div className="service_contant_wrapper formSection">
            //                     {/* <form> */}
            //                     <div className='row'>
            //                         <InputComponent inputProps={{ disabled: this.isLogedIn() && this.state.emiratesId !== undefined, lable: translate('emiratesId'), name: 'emiratesId', defaultValue: this.state.emiratesId, value: this.state.emiratesId, type: 'eid', changeListener: this.handleChange, isRequired: true, placeHolder: "784-2000-1234567-1" }} />
            //                         <div className='col-md-6'>
            //                             <div className="alertTopSpace alert alert-info" role="alert">
            //                                 <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="information" /></span>
            //                                 <span>{translate('towhominfomsg')}</span>
            //                             </div>
            //                         </div>
            //                         <Captcha toggleRefresh={this.toggleRefresh} refresh={this.state.refreshCaptcha} captchaValidationCallback={this.captchaValidationCallback} />
            //                         <div className='col-md-12 formSectionBtn'>
            //                             <hr></hr>
            //                             <div className='row'>
            //                                 <div className='col-md-6'>
            //                                     <div className="customCheckBox ">
            //                                         <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
            //                                         {this.context.state.siteLang === 'en' ?
            //                                             <label htmlFor="acceptCB">{translate('IAgreeToTheWebsite')} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/Terms-of-Use", "_blank")} href="">{translate('TermsOfUse')}</a> and <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/Privacy-Policy", "_blank")} href="">{translate('privacypolicy')}</a>.</span></label> :
            //                                             <label htmlFor="acceptCB">{"أوافق على"} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/Terms-of-Use", "_blank")} href="">{"شروط الاستخدام"}</a> كما وأوافق على <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/Privacy-Policy", "_blank")} href="">{"سياسة الخصوصية"}</a> للموقع الإلكتروني الخاص بمؤسسة محمد بن راشد للإسكان.</span></label>}
            //                                     </div>
            //                                 </div>
            //                                 <div className='col-md-6 formSectionBtn'>
            //                                     <button onClick={this.goBackPage}>{translate('back')}</button>
            //                                     <button onClick={this.generateCertificate}>{translate('Continue')}</button>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                     {/* </form> */}
            //                 </div> : null}
            //             </div>

            //             {/* PDF VIEW */}
            //             {/* <LoadingProgress active={true} total={this.state.total} current={this.state.current} showCompact /> */}
            //             {showPDF ? <div className="col-md-12">
            //                 <div className="service_contant_wrapper formSection">
            //                     <div className='row'>
            //                         <div className='col-lg-6 service_subTitle_wrapper'>
            //                             <span>{translate('whomItMayConcernCertificate')}</span>
            //                         </div>
            //                         <div className='col-lg-6 toWhomItMayConcernPdfViewBtn'>

            //                             <a href={pdfURL} download type="application/octet-stream" className="download" target='_blank'>
            //                                 <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/save_white_icon.svg" alt="Download" /></i>
            //                                 <span>{translate('DownloadTheCertificate')}</span>
            //                             </a>
            //                         </div>
            //                     </div>
            //                     <hr></hr>
            //                     <div className='row'>
            //                         <div className='col-md-12'>
            //                             <div className='whomItMayConcernPdfView'>
            //                                 {state.pdfView}
            //                                 {/* <object data={pdfURL} type="application/pdf" width="300" height="200"><a href={this.state.response.payload ? pdfURL : ''}>towhom.pdf</a></object> */}
            //                             </div>
            //                         </div>
            //                     </div>
            //                     <hr></hr>
            //                     <div className='row'>
            //                         <div className='col-md-12 formSectionBtn'>
            //                             <div className='buttonLR'>
            //                                 <button onClick={this.stepBack}>{translate('back')}</button>
            //                                 <button onClick={this.goBackPage}>{translate('exit')}</button>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div> : null}
            //         </div>
            //         {state.error}
            //         {state.errorMessage}
            //         {state.showMessage ? <div className="messages_wrapper success_message">
            //             <HappinessMeter serviceCode="6674" transactionID={referenceNo} />
            //             <div className="row">
            //                 <div className="col-md-12">
            //                     <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/success.svg'} alt="success" /></span>
            //                     <h3>{this.state.message}</h3>
            //                 </div>
            //                 <div className="col-md-12 messages_wrapperBtn">
            //                     <button onClick={this.newRequest}>{translate('make-new-request')}</button>
            //                 </div>
            //             </div>
            //         </div> : null}
            //     </div>
            // </div>
        )
    }
}

export default TowhomServiceComponentNew