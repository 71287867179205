import React from 'react'
import '../../reactToastify.css'
import history from '../../History'
import NoData from '../utils/nodata'
import Loader from '../utils/LoaderBars'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../constants/dashboard-constants'
import CostumerModuleUtilities from '../utils/customerModuleUtilities'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class ConstructionLoanDisbursmentApplicationTicketsListComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            redirect: false,
            props: '',
            applicationsResponse: [],
            applicationStatus: '',
            isLoading: false
        }
        this.modifyTicket = this.modifyTicket.bind(this)
        this.setApplications = this.setApplications.bind(this)
        this.getAllowedToCancelApplications = this.getAllowedToCancelApplications.bind(this)
    }

    componentDidMount() {
        localStorage.removeItem('ticketPayload')
        this.getAllowedToCancelApplications()
        this.setState({ props: this.props, isLoading: true })
    }

    getAllowedToCancelApplications() {
        if (CONSTANTS.authToken) {
            fetch(CONSTANTS.getApplicationEndpoint + "/" + localStorage.getItem('disbursementTypeID') + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplications).catch();
        }
    }

    setApplications(response) {
        this.setState({ isLoading: false })
        var tickets = []
        var items = response.payload
        if (items.length > 0) {
            items.map((application, index) => {
                if (application.loanDistbursementTickets.length === 0) {
                    let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
                    let cls = CostumerModuleUtilities.getStatusLableColor(application.status)
                    tickets.push(<LoanDisbursementApplicationTicketItem modifyTicket={this.modifyTicket} ticket={''} getButton={this.getButton} itemsCount={application.loanDistbursementTickets.length} key={index} index={index} payload={application} image={img} className={cls} />)
                } else {
                    application.loanDistbursementTickets.map((ticket, index) => {
                        let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
                        let cls = CostumerModuleUtilities.getStatusLableColor(ticket.status)
                        tickets.push(<LoanDisbursementApplicationTicketItem modifyTicket={this.modifyTicket} ticket={ticket} getButton={this.getButton} itemsCount={application.loanDistbursementTickets.length} key={index} index={index} payload={application} image={img} className={cls} />)
                    })
                }
                return (tickets)
            })
            this.setState({ applicationsResponse: response.payload, redirect: false, itemsArray: tickets })
        }
    }

    getNotRejectedTickets(tickets) {
        var notRejectedTickets = []
        tickets.map((ticket) => {
            if (ticket.status !== 30)
                notRejectedTickets.push(ticket)
        })
        return notRejectedTickets
    }

    modifyTicket(e) {
        const index = e.currentTarget.id
        localStorage.removeItem("dTicketNo")
        localStorage.removeItem("startAtStep")
        if (this.state.applicationsResponse[parseInt(index)].loanDistbursementTickets.length > 0) {
            //here we can have multiple applications and one application can have multiple tickets need to discus this scenario
            localStorage.setItem('ticketPayload', JSON.stringify(this.state.applicationsResponse[parseInt(index)].loanDistbursementTickets[0]))
            this.state.props.history.push({ pathname: '/stepConstructionLoanDisbursement', state: { tickerPayload: this.state.applicationsResponse[parseInt(index)].loanDistbursementTickets[0] } })
        } else {
            // localStorage.setItem('ticketPayload', JSON.stringify(this.state.applicationsResponse[0]))
            this.state.props.history.push({ pathname: '/stepConstructionLoanDisbursement', state: { tickerPayload: this.state.applicationsResponse[parseInt(index)] } })
        }
    }

    getButton(props) {
        if (props.itemsCount > 0) {
            //New Or Incomplete Can Modify
            if (props.payload.loanDistbursementTickets[props.index].status === 0) {
                return <button id={props.index} name={props.ticket.ticketNo} onClick={props.modifyTicket}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg'} alt="Edit" /> {translate('modify')} </button>
            } else if (props.payload.loanDistbursementTickets[props.index].status === 10) {
                return <button id={props.index} name={props.ticket.ticketNo} onClick={props.modifyTicket}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/eye_icon.svg'} alt="View" /> {translate('view')} </button>
            } else {
                return <button id={props.index} name={props.ticket.ticketNo} onClick={props.modifyTicket}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg'} alt="Edit" /> {translate('modify')} </button>
            }
            //Submitted Ready To Sign Documents
            // else if (props.payload.loanDistbursementTickets[props.index].status === 1) {
            //     return <button id={props.index} name={props.ticket.ticketNo}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign_blue_ico.svg'} onClick={() => {
            //         localStorage.removeItem("dTicketNo")
            //         history.push({ pathname: '/stepConstructionLoanDisbursement', state: { ticketNo: props.ticket.ticketNo } })
            //     }} alt="Sign" /> <span>{translate('sign')}</span></button>
            // }
            //Documents Signed Ready For Payment
            // else if (props.payload.loanDistbursementTickets[props.index].status <= 2) {
            //     return <button id={props.index} name={props.ticket.ticketNo} onClick={props.modifyTicket}>{props.itemsCount === 0 ? null : <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/eye_icon.svg'} alt="View" />} {translate('view')} </button>
            // }
        }
        else {
            return <button id={props.index} onClick={props.modifyTicket}>{translate('Apply')}</button>
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.isLoading ? <Loader /> : null}
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    {localStorage.getItem('disbursementTypeID') === '16' ? <li className="breadcrumb-item active" aria-current="page">{translate('maintenanceDisbursementContractRequests')}</li> : <li className="breadcrumb-item active" aria-current="page">{translate('constructionDisbursementContractRequests')}</li>}
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/construction_disbursement.svg'} alt="HousingRequest" /></i>
                                <span>{localStorage.getItem('disbursementTypeID') === '16' ? translate('maintenanceDisbursementContractRequests') : translate('constructionDisbursementContractRequests')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    {this.state.itemsArray.length > 0 ? this.state.itemsArray : <NoData message={translate('nodata')} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function LoanDisbursementApplicationTicketItem(props) {
    return (
        <div className="col-md-6 housingRequestWrapperAll">
            <div className="houseingRequestInn housingRequestWrapper">
                <div className="row no-gutters">
                    {/* <span>Farrukh</span> */}
                    <div className="col-md-2">
                        <i><img src={props.image} alt="MBRHE" /></i>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-12">
                                <strong>{props.payload.service}</strong>
                                {props.itemsCount === 1 ? <span> {translate('ticket-number')} </span> : null}
                                {props.itemsCount === 1 ? <span> {props.ticket.ticketNo} </span> : ''}
                                <div >
                                    <span> {translate('applicationNo')}</span>
                                    <span>: {props.payload.applicationNo} </span>
                                </div>
                            </div>
                            <div className="col-12">
                                <span>{translate('status')} </span>
                                <span className={props.class}>{props.payload.statusName}</span>
                            </div>
                            <div className="col-12">
                                <p className="housingRequestsince">{props.payload.createdAt != null ? props.payload.createdAt : props.payload.lastUpdatedAt}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 LoanDisbursementBtn">
                        {props.getButton(props)}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default AuthenticatedComponent(ConstructionLoanDisbursmentApplicationTicketsListComponent)