import React from 'react'
import '../../../reactToastify.css'
import { toast } from 'react-toastify'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
class LinkAccountWidget extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            showLinkPopup: false,
            showGetingBenifitPopup: false,
            showGestPopup: false,
            showCustomerPopup: false,
            showServicesListPopup: false,
            showOtpPopup: false,
            showbg: false,
            showSuccessPopup: false,
            account_linking_token: '',
            applicationNo: '',
            serviceType: '',
            pinCode: '',
            accept: false,
            responseErrorMessage: '',
            accountlinksuccessmessage: '',
            props: ''
        }
        this.getLinkAccountRequestToken = this.getLinkAccountRequestToken.bind(this)
        this.linkAccountClick = this.linkAccountClick.bind(this)
        this.linkAccountStart = this.linkAccountStart.bind(this)
        this.gettingBenifitYesClick = this.gettingBenifitYesClick.bind(this)
        this.gettingBenifitNoClick = this.gettingBenifitNoClick.bind(this)
        this.servicesListNextClick = this.servicesListNextClick.bind(this)
        this.servicesListCancelClick = this.servicesListCancelClick.bind(this)
        this.pinCodeNextClick = this.pinCodeNextClick.bind(this)
        this.pinCodeCancelClick = this.pinCodeCancelClick.bind(this)
        this.finishClick = this.finishClick.bind(this)
        this.guestFinishClick = this.guestFinishClick.bind(this)
        this.guestCancelClick = this.guestCancelClick.bind(this)
        this.handleChangeListener = this.handleChangeListener.bind(this)
        this.linkGuestAccount = this.linkGuestAccount.bind(this)
        this.saveRequestToken = this.saveRequestToken.bind(this)
        this.getEligibleServices = this.getEligibleServices.bind(this)
        this.setServicesResults = this.setServicesResults.bind(this)
        this.setEligibleServices = this.setEligibleServices.bind(this)
        this.linkGuestAccountSuccess = this.linkGuestAccountSuccess.bind(this)
        this.linkCustomerAccountWithApplicationNoSuccess = this.linkCustomerAccountWithApplicationNoSuccess.bind(this)
        this.linkCustomerAccountWithOTPSuccess = this.linkCustomerAccountWithOTPSuccess.bind(this)
    }

    componentDidMount() {
        if (!localStorage.getItem("showComponent")) {
            localStorage.setItem("showComponent", true)
            this.linkAccountClick()
        }
        this.getEligibleServices()
        this.getLinkAccountRequestToken(this.props)
    }

    handleChangeListener(e) {
        const { name, value } = e.target
        if (name === "accept") {
            this.setState({ [name]: e.target.checked })
        } else {
            this.setState({ [name]: value })
        }
    }

    linkAccountClick() {
        this.setState({
            showbg: !this.state.showbg,
            showLinkPopup: !this.state.showLinkPopup
        }, () => { this.getLinkAccountRequestToken() })
    }

    linkAccountStart() {
        this.setState({
            showLinkPopup: !this.state.showLinkPopup,
            showGetingBenifitPopup: !this.state.showGetingBenifitPopup
        })
    }

    gettingBenifitYesClick() {
        this.setState({
            showCustomerPopup: !this.state.showCustomerPopup,
            showGetingBenifitPopup: !this.state.showGetingBenifitPopup,
            showServicesListPopup: !this.state.showServicesListPopup
        })
    }

    gettingBenifitNoClick() {
        this.setState({
            showGetingBenifitPopup: !this.state.showGetingBenifitPopup,
            showGestPopup: !this.state.showGestPopup
        })
    }

    servicesListNextClick() {
        this.linkCustomerAccountWithApplicationNo()
    }

    servicesListCancelClick() {
        this.removeError()
        this.setState({
            showbg: !this.state.showbg,
            showCustomerPopup: !this.state.showCustomerPopup,
            showServicesListPopup: !this.state.showServicesListPopup,
        })
    }

    pinCodeNextClick() {
        if (this.state.accept === false)
            this.setError(translate('acceptterms'))
        else if (this.state.pinCode.length === 0)
            this.setError(translate('EnterPinCode'))
        else
            this.linkCustomerAccountWithOTP()
    }

    pinCodeCancelClick() {
        this.setState({ responseErrorMessage: '', showServicesListPopup: true, showPinCodePopup: false })
    }

    guestFinishClick() {
        this.linkGuestAccount()
    }

    guestCancelClick() {
        this.removeError()
        this.setState({
            showbg: !this.state.showbg,
            showGestPopup: !this.state.showGestPopup,
        })
    }

    getLinkAccountRequestToken(props) {
        if (props) {
            this.setState({ props: props })
        }
        this.removeError()
        if (CONSTANTS.authToken) {
            fetch(CONSTANTS.getLinkAccountRequestTokenEndPoint + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.saveRequestToken).catch(this.loadFailed);
        }
    }

    saveRequestToken(response) {
        this.removeError()
        if (response.success) {
            this.setState({ account_linking_token: response.payload.token })
        } else {
            console.log(response.message)
        }
    }

    linkGuestAccount() {
        if (this.state.accept) {
            this.removeError()
            var data = new URLSearchParams();
            data.append('linkingToken', this.state.account_linking_token);
            fetch(CONSTANTS.linkGuestUserAccountEndPoint + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                headers: {
                    'Authorization': CONSTANTS.authToken
                },
                body: data
            }).then(response => response.json()).then(this.linkGuestAccountSuccess).catch(this.loadFailed);
        } else { this.setError(translate('acceptterms')) }
    }

    linkGuestAccountSuccess(response) {
        if (response.success) {
            toast.success(translate('linksuccess'), { autoClose: 1000 })
            this.setState({ showbg: !this.state.showbg, showGestPopup: !this.state.showGestPopup, })
            setTimeout(function () { this.state.props.refresh() }.bind(this), 1000)
        } else {
            toast.error(response.message, { autoClose: 5000 })
            this.setError(response.message)
            console.log(response.message)
        }
    }

    linkCustomerAccountWithApplicationNo() {
        if (this.state.account_linking_token.length > 0) {
            this.removeError()
            var data = new URLSearchParams();
            data.append('linkingToken', this.state.account_linking_token);
            data.append('applicationNo', this.state.applicationNo);
            data.append('serviceType', this.state.serviceType);
            fetch(CONSTANTS.linkCustomerAccountEndPoint + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                headers: {
                    'Authorization': CONSTANTS.authToken
                },
                body: data
            }).then(response => response.json()).then(this.linkCustomerAccountWithApplicationNoSuccess).catch(this.loadFailed);
        } else {
            // toast.error(translate('nolinkingtoken')) 
        }
    }

    linkCustomerAccountWithApplicationNoSuccess(response) {
        if (response.success) {
            toast.success(response.payload.message, { autoClose: 5000 })
            this.setState({ showServicesListPopup: false, showPinCodePopup: true })
        } else {
            toast.error(response.message, { autoClose: 5000 })
            this.setError(response.message)
            console.log(response.message)
        }
    }

    linkCustomerAccountWithOTP() {
        this.removeError()
        var data = new URLSearchParams();
        data.append('Account_Linking_Token', this.state.account_linking_token);
        data.append('LinkingToken', this.state.account_linking_token);
        data.append('ApplicationNo', this.state.applicationNo);
        data.append('ServiceType', this.state.serviceType);
        data.append('Pin', this.state.pinCode);
        fetch(CONSTANTS.linkCustomerAccountOTPEndPoint + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'Authorization': CONSTANTS.authToken
            },
            body: data
        }).then(response => response.json()).then(this.linkCustomerAccountWithOTPSuccess).catch(this.loadFailed);
    }

    linkCustomerAccountWithOTPSuccess(response) {
        if (response.success) {
            toast.success(response.message, { autoClose: 5000 })
            this.setState({
                accountlinksuccessmessage: response.message,
                showPinCodePopup: !this.state.showPinCodePopup,
                showSuccessPopup: !this.state.showSuccessPopup,
            })
        } else {
            toast.error(response.message, { autoClose: 5000 })
            this.setError(response.message)
        }
    }

    loadFailed(response) {
        toast.error(response.message, { autoClose: 5000 })
        this.setError(response.message)
    }

    setError(message) {
        this.setState({ responseErrorMessage: <div className="linkaccount_error"><span >{message}</span></div> })
    }

    removeError() {
        this.setState({ responseErrorMessage: '' })
    }

    finishClick() {
        this.setState({
            showbg: !this.state.showbg,
            showCustomerPopup: !this.state.showCustomerPopup,
            showSuccessPopup: !this.state.showSuccessPopup,
        }, () => {
            this.state.props.refresh()
        })
    }

    getEligibleServices() {
        var maritalStatus = 2
        if (localStorage.getItem('dashboard')) {
            maritalStatus = JSON.parse(localStorage.dashboard).payload.maritalStatus
        }
        fetch(CONSTANTS.getEligibleServicesEndPoint + "?local=" + this.context.state.siteLang + '&status=' + maritalStatus, {
            method: 'GET',
            headers: { 'Authorization': CONSTANTS.authToken }
        }).then(response => response.json()).then(this.setServicesResults).catch(this.loadFailed);
    }

    setServicesResults(response) {
        if (response.success) {
            this.setEligibleServices(response.payload)
        } else {
            this.showError(response.message)
        }
    }

    setEligibleServices(services) {
        const radiobtns = []
        services.map((service) => {
            radiobtns.push(<option value={service.id}>{service.name}</option>)
        })
        this.setState({ eligibleServices: radiobtns })
    }

    render() {
        let step1DotsClass = ["accLinkYesStepDotSt1", "actAccLinkYesStepDot"];
        let step2DotsClass = ["accLinkYesStepDotSt2"];
        let step3DotsClass = ["accLinkYesStepDotSt3"];

        if (this.state.showPinCodePopup) {
            step2DotsClass.push("actAccLinkYesStepDot")
        }
        if (this.state.showSuccessPopup) {
            step2DotsClass.push("actAccLinkYesStepDot")
            step3DotsClass.push("actAccLinkYesStepDot")
        }
        return (
            <div className="col-md-12">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/link_account.svg'} alt={translate('MyIDLinkAccount')} /></span>
                        <span>{translate('LinkYourAccountWithMBRHE')}</span>
                    </div>
                    <div className="widgetContant">
                        <div className="col-md-12 widgetLinkAccountCenterContainer">
                            <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/link_account.svg'} alt="" /></span>
                            <p>{translate('MyIDLinkAccountMessage')}</p>
                            <button className="linkWidgetBtn" onClick={this.linkAccountClick}>{translate('LinkYourAccountNow')}</button>
                        </div>
                    </div>
                    {this.state.showbg ? <div className="link_to_accountWrapper"></div> : null}
                    {this.state.showLinkPopup ?
                        <div className=" linkAccountOpen linkAccount linkAccPopupBox linkaccountLink0">
                            <div className="com-sm-12">
                                <div className="linkaccFirstPop">
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bell3.svg'} alt="Link Account" />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="linkedIncenterSection">
                                    {translate('MyIDLinkAccountMessage')}
                                </div>
                            </div>
                            <div className="col-sm-12 linkedAccBtnCenter">
                                <button onClick={this.linkAccountStart}>{translate('MyIDLinkAccount')}</button>
                                <button onClick={this.linkAccountClick}>{translate('cancle')}</button>
                            </div>
                        </div> : null}
                    {this.state.showGetingBenifitPopup ?
                        <div className="linkAccountOpen linkAccount linkAccPopupBox linkaccountLink1">
                            <div className="col-md-12 linkaccountTitle">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/link_account.svg'} alt={translate('MyIDLinkAccount')} /></i>
                                <span>{translate('cancle')}</span>
                            </div>
                            <div className="col-md-12 linkedIncenterSection">
                                <p>{translate('IsBenefitingMRHE')}</p>
                            </div>
                            <div className="col-md-12 linkedAccBtnCenter">
                                <button onClick={this.gettingBenifitYesClick}>{translate('yes')}</button>
                                <button onClick={this.gettingBenifitNoClick}>{translate('no')}</button>
                            </div>
                        </div>
                        : null}
                    {this.state.showGestPopup ?
                        <div className="linkAccountOpen linkAccount linkAccPopupBox linkaccountLink2">
                            <div className="col-sm-12 linkAccountTopIcon">
                                <ul>
                                    <li><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/logo2.png'} style={{ width: "30px" }} alt="Link Account" /></li>
                                    <li><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/link_account.svg'} alt="Link Account" /></li>
                                    <li><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/user_linking.svg'} alt="Link Account" /></li>
                                </ul>
                            </div>
                            <div className="col-sm-12 accept customCheckBox">
                                <input type="checkbox" id="Iaccept" name="accept" onChange={this.handleChangeListener} />
                                <label htmlFor="Iaccept">{translate('IAcceptingThe')}</label>
                            </div>
                            <div className="col-sm-12 linkedAccBtnCenter">
                                <button onClick={this.guestCancelClick}>{translate('back')}</button>
                                <button onClick={this.guestFinishClick}>{translate('finish')}</button>
                            </div>
                            {this.state.responseErrorMessage}
                        </div>
                        : null}
                    {this.state.showCustomerPopup ?
                        <div className="linkAccountOpen linkAccount linkAccPopupBox linkaccountLink3">
                            <div className="col-md-12 linkaccountTitle">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/link_account.svg'} alt={translate('MyIDLinkAccount')} /></i>
                                <span>{translate('MyIDLinkAccount')}</span>

                            </div>
                            <div className="col-md-12">

                                <div className="accLinkYesStepDotWrap">
                                    <div className={step1DotsClass.join(' ')}>
                                        <span>{translate('applicationNo')}</span>
                                    </div>
                                    <div className={step2DotsClass.join(' ')}>
                                        <span>{translate('EnterPinCode')}</span>
                                    </div>
                                    <div className={step3DotsClass.join(' ')}>
                                        <span>{translate('finish')}</span>
                                    </div>
                                </div>
                                {this.state.showServicesListPopup ?
                                    <div className="accLinkStepWrapper">
                                        <p>{translate('EnterApplicationNoAndServiceType')}</p>
                                        <div className="col-md-12">
                                            <div className="row accLinkStepFormWrapper">
                                                <div className="col-md-4">
                                                    <label>{translate('applicationNo')}</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input id="applicationno" type="text" name="applicationNo" onChange={this.handleChangeListener}></input>
                                                </div>
                                            </div>
                                            <div className="row accLinkStepFormWrapper">
                                                <div className="col-md-4">
                                                    <label>{translate('serviceType')}</label>
                                                </div>
                                                <div className="col-md-8">
                                                    {/* this.context.state.siteLang==='en'? */}
                                                    <select name="serviceType" id="linkingServiceType" onChange={this.handleChangeListener}>
                                                        {this.context.state.siteLang === 'en' ? <option value="">Please select</option> : <option value="">الرجاء الاختيار</option>}
                                                        {this.state.eligibleServices}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row accLinkStepFormWrapper">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-8">
                                                    <button onClick={this.servicesListNextClick}>{translate('next')}</button>
                                                    <button onClick={this.servicesListCancelClick}>{translate('cancle')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.responseErrorMessage}
                                    </div> : null}
                                {this.state.showPinCodePopup ?
                                    <div className="accLinkStepWrapper actAccLinkYesStepDot">
                                        <div className="col-md-12">
                                            <div className="row accLinkStepFormWrapper">
                                                <div className="col-md-4">
                                                    <label>{translate('EnterPinCode')}</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="row">
                                                        <div className="col-md-6 col-xs-10"><input type="text" name="pinCode" onChange={this.handleChangeListener}></input></div>
                                                        <div className="col-md-6 col-xs-2"><img onClick={this.servicesListNextClick} src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/otpRefresh.svg'} className="refreshBtn" alt="refresh" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row accLinkStepFormWrapper">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-8 customCheckBox">
                                                    <input type="checkbox" id="Iaccept1" name="accept" onChange={this.handleChangeListener} value="" />
                                                    <label htmlFor="Iaccept1">{translate('IAcceptingThe')}</label>
                                                </div>
                                            </div>
                                            <div className="row accLinkStepFormWrapper">
                                                <div className="col-md-4"></div>
                                                <div className="col-md-8">
                                                    <button onClick={this.pinCodeCancelClick}>{translate('back')}</button>
                                                    <button onClick={this.pinCodeNextClick}>{translate('next')}</button>
                                                </div>
                                            </div>
                                            {this.state.responseErrorMessage}
                                        </div>
                                    </div> : null}
                                {this.state.showSuccessPopup ?
                                    <div className="accLinkStepWrapper">
                                        <div className="col-md-12">
                                            <div className="accLinkStepFormWrapper">
                                                <div className="accclinkIconCenter">
                                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/link.svg'} alt="link" />
                                                </div>
                                            </div>
                                            <div className="linkedIncenterText">
                                                {this.state.accountlinksuccessmessage}
                                            </div>
                                            <div className="col-sm-12 linkedAccBtnCenter">
                                                <button onClick={this.finishClick}>{translate('finish')}</button>
                                            </div>
                                        </div>
                                    </div> : null}
                            </div>
                        </div> : null}
                    {/**<div/> */}
                </div>
            </div>
        )
    }
}
export default LinkAccountWidget