import AppDispatcher from '../../Dispatcher';
import AuthConstants from './AuthConstants';

class ContractorLoginAction {

		loginUserUsingPasswordOrOTP(response) {
		AppDispatcher.dispatch({
			actionType: AuthConstants.LOGIN_USER_PASSWORD_OR_OTP,
			response: response
		});
	}

	loginUserUsingMobileNoOrEmiratesId(response) {
		AppDispatcher.dispatch({
			actionType: AuthConstants.LOGIN_USER_OTP,
			response: response
		});
	}
}

export default new ContractorLoginAction();