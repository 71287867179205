import React from 'react'
import History from '../../../History'
import './percentageOfCompletion.css'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import InputComponent from '../../utils/inputComponent'
import CONSTANTS from '../../constants/dashboard-constants'
import CommonUtils from '../../../utils/CommonUtils'
import ErrorComponent from '../../utils/errorComponent'
import { ScrollToTop } from '../../../utils/Scroll'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'

class PercentageOfCompletion extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            acceptClick: false,
        }
        this.acceptClick = this.acceptClick.bind(this)
    }


    closeCallback() {
        this.setState({ error: null })
    }

    acceptClick(e) {
        this.closeCallback()
        if (e.target.id === "accept") {
            this.setState({ errorMessage: "", acceptClick: !this.state.acceptClick, temsAccept: true }, () => { })
        }
        else {
            if (e.target.id === "acceptCB") {
                if (e.target.checked) {
                    this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false }, () => { })
                } else {
                    this.setState({ temsAccept: false })
                }
            } else {
                this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false }, () => { })
            }
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                
                {/* Terms and conditions POPUP*/}
                {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                    {this.state.acceptClick ? <div className="acceptPop">
                        <button className="popupClose" onClick={this.acceptClick}>X</button>
                        <h2>{translate('TermsAndConditions')}</h2>
                        <p>{translate('eligibleterms')}</p>
                        <div className="col-md-12 acceptPopBtn">
                            <div className="row">
                                <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
                            </div>
                        </div>
                    </div> : null}
                    {/* END Terms and conditions POPUP*/}

                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('toWhomItMayConcernsPercentageofcompletion')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg" alt="HousingRequest"/></i>
                                <span>{translate('toWhomItMayConcernsPercentageofcompletion')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className='complaintFormSection'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <label>{translate('applicationType')} <span className='redStar'>*</span></label>
                                            <select>
                                            <option>as</option>
                                            <option>as</option>
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <label>{translate('ApplicationNumber')} <span className='redStar'>*</span></label>
                                            <select>
                                            <option>as</option>
                                            <option>as</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            Captcha
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 formSectionBtn">
                                            <hr></hr>
                                            <div className="customCheckBox">
                                                <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
                                                <label htmlFor="acceptCB">{translate('IAcceptingThe')}</label>
                                            </div>
                                            <button>{translate('back')}</button>
                                            <button onClick={this.submit}>{translate('submit')}</button>
                                        </div>
                                    </div>
                                </div>


                                <div className='complainantError'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='complainantErrorBox'>
                                                <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stop_red.svg" alt="Error"/></i>
                                                <h2>{translate('error-occured')}</h2>
                                                <div>
                                                    <button>{translate('tryagain')}</button>
                                                    <button>{translate('cancle')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='complainantSuccessfully'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='complainantSuccessfullyBox'>
                                                <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/success-green-ico.svg" alt="Success"/></i>
                                                <h2>{translate('ComplaintSuccessfullyNumber')} <span>XXXXX</span></h2>
                                                <div>
                                                    <button>{translate('backToHome')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div >
        );
    }
}

export default AuthenticatedComponent(PercentageOfCompletion)