import React from 'react'
import translate from '../../../Providers/i18n/Translate'

function HousingRequestItem(props) {
    return (
        <div className="housingRequestWrapper">
            <div className="row">
                <div className="col-md-1 col-sm-2">
                    <i><img src={props.image} alt="Status" /></i>
                </div>
                <div className="col-md-11 col-sm-10">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div>
                                <strong>{props.payload.requestType > 0 ? props.payload.requestTypeDesc : props.payload.service}</strong>
                                <span>{translate('ticket-number')} </span>
                                <span>{props.payload.ticket}</span>
                            </div>
                            {props.payload.comments && props.payload.comments.length > 0 ?
                                <div className="remarkWrapper">
                                    <span>{translate('housing-remarks')} </span>
                                    <span className="selectedcolor-60">{props.payload.comments}</span>
                                </div> :
                                null}
                        </div>
                        <div className="col-lg-6 col-md-5">
                            <span>{translate('status')}: </span>
                            <span className={props.class}>{props.payload.statusName}</span>
                            <p className="housingRequestsince">{props.payload.since} | {props.payload.createdAt}</p>
                        </div>
                        {props.payload.enableEdit ? <div className="col-lg-2 col-md-3 housingRequestBtns">
                            <button value={props.payload.requestType === 1 ? 5 : 15} name={props.payload.ticket} id={props.payload.requestType} title={props.payload.applicationNo} onClick={props.modifyTicket}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg'} alt="Edit" /> {translate('modify')} </button>
                            {props.payload.requestType ? null : <button name={props.payload.ticket} onClick={props.deleteClick}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete.svg'} alt="delete" /> {translate('delete')} </button>}
                        </div> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HousingRequestItem