import React from 'react'
class HousingProjectsItem extends React.Component {
    constructor(props) {
        super()
        this.state = { showPopUp: false }
        this.onPinClick = this.onPinClick.bind(this)
    }

    render() {
        return (
            <div className="popupMapWrapper">
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/pointer.svg" onClick={this.onPinClick} alt="Click" />
                {this.state.showPopUp ?
                    <div className="popupMapContent">
                        <button onClick={this.onPinClick}>X</button>
                        <p>{this.props.housingPrtoject.name}</p>
                    </div> :
                    null}
            </div>
        )
    }
    onPinClick() {
        this.setState({ showPopUp: !this.state.showPopUp })
        this.props.callback()
    }
}
export default HousingProjectsItem

// <div id="map">MAP</div>
//<h3>{props.housingPrtoject.id}':'{props.housingPrtoject.name}</h3>
//<h3
// lat={59.955413}
// lng={30.337844}
// text="My Marker" />
// </Marker>
//             <InfoWindow visible={true} />



// <Marker key={props.index} id={props.housingPrtoject.id} position={{
//     lat: props.housingPrtoject.latitude,
//     lng: props.housingPrtoject.longitude
// }}
//     onClick={() => console.log("You clicked me!")} />