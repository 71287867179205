import EarlySettlementConstants from './earlysettlement-constants'
import BaseStore from '../../BaseStore'

class EarlySettlementStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this.registerToActions.bind(this))
        this.earlySettlementResponse = null
        this.financialStatus = null
    }

    registerToActions(action) {
        switch (action.actionType) {
            case EarlySettlementConstants.EARLYSETTLEMENT:
                this.earlySettlementResponse = action.response
                this.emitChange()
                break
            case EarlySettlementConstants.FINANCIALSTATUS:
                this.financialStatus = action.response
                this.emitChange()
                break
            default:
                break
        }
    }

    get response() {
        return this.earlySettlementResponse
    }

    get financialStatusResponse() {
        return this.financialStatus
    }
}

export default new EarlySettlementStore()