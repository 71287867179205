import React from 'react';
import './login.css';
import history from "../../History";
import AuthService from './AuthService'
import FloatingLabelInput from 'react-floating-label-input';
import LoginStore from './LoginStore';
import { Redirect } from 'react-router-dom';
import BaseRedirect from './BaseRedirect';
import CommonUtils from '../../utils/CommonUtils'
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import DOMPurify from '../../utils/DOMPurify';

class ContractorLoginComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            submitted: false,
            isLoginHelp: false,
            iscontractorOtpWrapper: false,
            iscontractorSigninWrapper: true,
            username: '',
            pwd: '',
            apiError: '',
            loading: false,
            isChecked: false,
            mobilenumber: '',
            otpMessage: '',
            loginbyotp: '',
            redirect: '',
            isSuccess: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.backPress = this.backPress.bind(this);
        this.loginHelpClick = this.loginHelpClick.bind(this);
        this.loginUsingPasswordOrOTPClick = this.loginUsingPasswordOrOTPClick.bind(this);
        this.loginUsingMobileNoClick = this.loginUsingMobileNoClick.bind(this);
        this.contractorOtpWrapperClick = this.contractorOtpWrapperClick.bind(this);
        this.otpPasswordBkClick = this.otpPasswordBkClick.bind(this);
    }

    loginUsingPasswordOrOTPClick(e) {
        e.preventDefault();
        this.setState({ submitted: true })
        const { username, pwd, loginbyotp } = this.state;
        // stop here if form is invalid
        if (!(username && pwd || loginbyotp)) {
            return;
        }
        this.setState({ loading: true });
        this.setState({ apiError: '' })
        if (this.state.pwd) {
            AuthService.contractorLoginUsingPassword(this.state.username.trim(), this.state.pwd.trim(), this.context.state.siteLang)
                .catch(function (error) {
                    this.catchBody(error);
                }.bind(this));
        } else {
            AuthService.contractorLoginUsingOtpNo(this.state.username.trim(), this.state.loginbyotp.trim(), this.context.state.siteLang)
                .catch(function (error) {
                    this.catchBody(error);
                }.bind(this));
        }
        this.setState({ loading: false });
    }

    isLogedIn() {
        return localStorage.getItem('accessToken')
    }

    loginUsingMobileNoClick(e) {
        e.preventDefault();
        this.setState({ submitted: true })
        const { username, mobilenumber } = this.state;
        // stop here if form is invalid
        if (!(username && mobilenumber)) {
            return;
        }
        this.setState({ loading: true });
        this.setState({ apiError: '' })
        AuthService.contractorLoginUsingMobileNo(this.state.username.trim(), this.state.mobilenumber.trim(), this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
        this.setState({ loading: false });
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
        this.setState({ apiError: errorDescription });
        this.setState({ otpMessage: '' });
    }

    componentWillUnmount() {
        LoginStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this._getResponse())
    }

    _getResponse() {
        if (LoginStore.isLoggedIn()) {
            return {
                otpMessage: LoginStore.response,
                userLoggedIn: LoginStore.isLoggedIn()
            };
        } else if (LoginStore.isSuccess()) {
            return {
                otpMessage: LoginStore.response,
            };
        } else {
            return {
                apiError: LoginStore.response,
            };
        }
    }

    contractorOtpWrapperClick() {
        this.setState({
            submitted: false,
            apiError: '',
            iscontractorOtpWrapper: !this.state.iscontractorOtpWrapper,
            iscontractorSigninWrapper: !this.state.iscontractorSigninWrapper,
            pwd: '',
        });
    }

    otpPasswordBkClick() {
        this.setState({
            iscontractorOtpWrapper: !this.state.iscontractorOtpWrapper,
            iscontractorSigninWrapper: !this.state.iscontractorSigninWrapper,
            apiError: ''
        });
    }

    loginHelpClick() {
        this.setState({
            isLoginHelp: !this.state.isLoginHelp,
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    backPress() {
        history.push('/eservices')
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onBlur = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onFocus = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked
        });
        const { username } = this.state;
        if (!username) {
            return;
        }
        if (!this.state.isChecked) {
            localStorage.setItem('USERNAME', this.state.username);
            localStorage.setItem('TOGGLEME', !this.state.isChecked);
        } else {
            localStorage.removeItem('USERNAME');
            localStorage.removeItem('TOGGLEME');
        }
    }

    componentDidMount() {
        if (localStorage.getItem("USERNAME")) {
            const username = localStorage.getItem("USERNAME");
            const rememberMe = localStorage.getItem("TOGGLEME");
            this.textInput.current && this.textInput.current.focus();
            this.setState({
                username: username.trim(),
                isChecked: !!rememberMe
            });
        }
        this.changeListener = this._onChange.bind(this);
        LoginStore.addChangeListener(this.changeListener);
        if (!this.isLogedIn()) {
            require('./style_24.css')
        }
    }

    isLoggedIn() {
        return localStorage.getItem('accessToken')
    }

    getUserType() {
        return localStorage.getItem('userType')
    }

    render() {
        if (this.isLoggedIn() && this.getUserType() == 1) {
            return CommonUtils.getRedirection(this.props.location.search)
        } else if (this.isLoggedIn()) {
            return <Redirect to='/contractorDashboard' />
        } else {
            const { apiError, isChecked, otpMessage, userLoggedIn } = this.state;
            return (
                <div className="container-fluid appWrapper">
                    <div className="login_wrapper">
                        <div className="contractor_section finalcontractorlogin">
                            <div className="loginHeaderText"> {translate('contractorLogin')} <a href="" className="contractorLogBtnBack">
                                <span>
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/loginBack.svg'} alt="Click to back" />
                                </span>
                                <span onClick={this.backPress}>{translate('back')}</span>
                            </a>
                            </div>

                            <div className="login_headerImg">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="headerImg contractor_bg">
                                            <div className="icon">
                                                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractorIcon.png'} alt="contractorIcon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row login_formArea">
                                {this.state.iscontractorSigninWrapper ? <div className="col-sm-12 LoginSection ContractorSigninWrapper">
                                    <div className="logincolSection">
                                        <div className="formSection">
                                            <div className="row">
                                                <div className="col-md-12" >
                                                    <div className="formInput">
                                                        <FloatingLabelInput
                                                            id="username"
                                                            name="username"
                                                            className="formInputWrapper loginLabel"
                                                            type="text"
                                                            label={translate('userName')}
                                                            onFocus={this.onFocus}
                                                            onChange={this.onChange}
                                                            onBlur={this.onBlur}
                                                            value={this.state.username}
                                                            refs={this.textInput} />
                                                    </div>
                                                    {this.state.submitted && !this.state.username ? <span className="jqueryStepErrorMsgSpan" id="msg_UserName">{translate('requiredField')}</span> : null}
                                                    <div className="formInput">
                                                        <FloatingLabelInput
                                                            id="password"
                                                            name="pwd"
                                                            className="formInputWrapper loginLabel"
                                                            type="password"
                                                            label={translate('pwd')}
                                                            onFocus={this.onFocus}
                                                            onChange={this.onChange}
                                                            onBlur={this.onBlur}
                                                            value={this.state.pwd} />
                                                    </div>
                                                    {this.state.submitted && !this.state.pwd ? <span className="jqueryStepErrorMsgSpan" id="msg_Password">{translate('requiredField')}</span> : null}
                                                    {apiError !== "" ? <span className="jqueryStepErrorMsgSpan" id="api_error"> {<div dangerouslySetInnerHTML={{ __html: DOMPurify(apiError) }} />}</span> : null}
                                                    <div className="loginRemember" >
                                                        <input type="checkbox" checked={isChecked} id="contractorRemember" name="isChecked"
                                                            onChange={this.toggleChange} />
                                                        <label htmlFor="contractorRemember"> {translate('rememberMe')} </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formSection signincus" >
                                            <a href="" className="signincusBtn" id="btnsubmitContractor" formAction="Login" onClick={this.loginUsingPasswordOrOTPClick}>{translate('signIn')}</a>
                                            <BaseRedirect userLoggedIn={DOMPurify(userLoggedIn)} redirect='/contractorDashboard' />
                                        </div>
                                        <div className="loginNotsWrapper">
                                            <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/loginHelp.svg'} alt="info for login OTP (One Time Password) " />
                                            <span> {translate("contractoraccountlogininfo")} </span>
                                        </div>
                                    </div>
                                    <div className="formSection loginOr"><span>{translate('or')}</span></div>
                                    <div className="formSection OTP logincolSection">
                                        <a href="javascript:;" className="ContractorOtpPassword" onClick={this.contractorOtpWrapperClick}>{translate('loginByOtp')}</a>
                                        <div className="loginNotsWrapper">
                                            <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/loginHelp.svg'} alt="This information for login OTP " />
                                            <span> {translate('loginOtpText')} </span>
                                        </div>
                                    </div>
                                </div> : null}

                                {this.state.iscontractorOtpWrapper ? <div className="col-sm-12 LoginSection ContractorOtpWrapper">
                                    <div className="logincolSection">
                                        <div className="formSection">
                                            <div className="formInput">
                                                <FloatingLabelInput
                                                    id="username"
                                                    name="username"
                                                    className="formInputWrapper loginLabel"
                                                    type="text"
                                                    label={translate('userName')}
                                                    onFocus={this.onFocus}
                                                    onChange={this.onChange}
                                                    onBlur={this.onBlur}
                                                    value={this.state.username} />
                                            </div>
                                            {this.state.submitted && !this.state.username ? <span className="jqueryStepErrorMsgSpan" id="msg_UserNameOtp">{translate('requiredField')}</span> : null}
                                            {otpMessage == "" ? <div className="formInput ContractorloginMobileNo">
                                                <FloatingLabelInput
                                                    id="mobilenumber"
                                                    name="mobilenumber"
                                                    className="formInputWrapper loginLabel"
                                                    type="number"
                                                    label={translate('mobileNo')}
                                                    onFocus={this.onFocus}
                                                    onChange={this.onChange}
                                                    onBlur={this.onBlur}
                                                    value={this.state.mobilenumber} />
                                            </div> : null}
                                            {this.state.submitted && !this.state.mobilenumber ? <span className="jqueryStepErrorMsgSpan" id="msg_Mobile">{translate('requiredField')}</span> : null}
                                            {apiError == "" && otpMessage !== "" ? <div className="formInput ContractorloginOtpNoWr">
                                                <FloatingLabelInput
                                                    id="loginbyotp"
                                                    name="loginbyotp"
                                                    className="formInputWrapper loginLabel"
                                                    type="number"
                                                    label={translate('loginByOtp')}
                                                    onFocus={this.onFocus}
                                                    onChange={this.onChange}
                                                    onBlur={this.onBlur}
                                                    value={this.state.loginbyotp} />
                                                <a href="javascript:;" className="reOtp" onClick={this.loginUsingMobileNoClick}>
                                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/otpRefresh.svg'} alt="Resend pin code img" />
                                                </a>
                                            </div> : null}
                                            {apiError !== "" ? <span className="jqueryStepErrorMsgSpan" id="invalidLoginMessage"><div dangerouslySetInnerHTML={{ __html: DOMPurify(apiError) }} /></span> : null}
                                            {otpMessage !== "" ? <span className="OtpSuccessMessage" id="OtpSuccessMessage"> <div dangerouslySetInnerHTML={{ __html: DOMPurify(otpMessage) }} /></span> : null}
                                        </div>
                                        {otpMessage == "" ? <div className="formSection submitBtnMob">
                                            <a href="javascript:;" id="ContractorOtp" onClick={this.loginUsingMobileNoClick}>{translate('submit')}</a>
                                        </div> : null}
                                        {otpMessage !== "" ? <div className="formSection submitCustomerBtn">
                                            <a href="" id="btnsubmitContractorByotp" formAction="Login" onClick={this.loginUsingPasswordOrOTPClick}>{translate('signIn')}</a>
                                        </div> : null}
                                        <BaseRedirect userLoggedIn={DOMPurify(userLoggedIn)} redirect='/contractorDashboard' />

                                        <div className="formSection MobileNoBk loginBack">
                                            <span className="ContractorotpPasswordBk" onClick={this.otpPasswordBkClick}>{translate('back')}</span>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default ContractorLoginComponent;