import React from "react";
import { Doughnut } from 'react-chartjs-2';
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import { LOCALES } from "../../Providers/i18n";

let financialStatusCopy = [];
class FinancialStatusDoughnutChartComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
        this.state = {
            financialStatusResponse: this.props.doughnutChart,
            selectValue: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }
    getData(key) {
        let dataObject = 0; 
        if (key === 'totalRetentions') {
            dataObject = financialStatusCopy.reduce((s, p) => s += p.totalRetentions, 0);
        } else if (key === 'totalPayment') {
            dataObject = financialStatusCopy.reduce((s, p) => s += p.totalPayment, 0);
        } else if (key === 'remainingPayment') {
            dataObject = financialStatusCopy.reduce((s, p) => s += p.remainingPayment, 0);
        } else if (key === 'ownerShare') {
            dataObject = financialStatusCopy.reduce((s, p) => s += p.ownerShare, 0);
        } else if (key === 'loanAmount') {
            dataObject = financialStatusCopy.reduce((s, p) => s += p.loanAmount, 0);
        } else if (key === 'contractAmount') {
            dataObject = financialStatusCopy.reduce((s, p) => s += p.contractAmount, 0);
        }
        return dataObject;
    }

    // financial Status
    setFinancialStatusResponse() {
        // Doughnut chart
        const financialStatus = {
            labels: [financialStatusCopy[0].totalRetentionsLabel,
            financialStatusCopy[0].totalPaymentLabel,
            financialStatusCopy[0].remainingPaymentLabel,
            financialStatusCopy[0].ownerShareLabel,
            financialStatusCopy[0].loanAmountLabel,
            financialStatusCopy[0].contractAmountLabel],
            datasets: [
                {
                    label: 'Financial status',
                    fill: true,
                    lineTension: 0.1,
                    backgroundColor: ['#55D8FE','#FF8373','#FFDA83','#338AE6','#A3A0FB','#003454','#F6303F', '#0296CE', '#C224B9', '#C1CF32', '#0068AD', '#F29400'],
                    data: [this.getData('totalRetentions'),
                    this.getData('totalPayment'),
                    this.getData('remainingPayment'),
                    this.getData('ownerShare'),
                    this.getData('loanAmount'),
                    this.getData('contractAmount')]
                }
            ]
        };
        return financialStatus;
    }

    handleChange(event) {
        var data = event.target.value;
        if (data !== 'Please Select' || data !== 'الرجاء الاختيار') {
            this.setState({
                selectValue: JSON.parse(data)
            });
        }else{
            this.setState({
                selectValue: ''
            });
        }
    }

    render() {
        const { financialStatusResponse, selectValue } = this.state;
        financialStatusCopy = [];
        if (selectValue) {
            financialStatusCopy.push(selectValue);
        } else {
            financialStatusCopy = [...this.state.financialStatusResponse];
        }
        return (
            <div>
                <div className="col-md-12 chartTitle">
                    <span>
                        <label htmlFor="financialDropdown">{translate('financial-status-widget-heading')}</label>
                        <select id="financialDropdown"
                            onChange={this.handleChange} >
                            {LOCALES.ARABIC ?  <option>الرجاء الاختيار</option> : <option>Please Select</option>}
                            {financialStatusResponse.map((value, index) =>
                                <option key={index} value={JSON.stringify(value)}>
                                    {value.owner + ' - ' + value.applicationNo}
                                </option>
                            )
                            }
                        </select>
                    </span>
                </div>
                <Doughnut ref="doughnut" legend={{ "position": "right", }} data={this.setFinancialStatusResponse()} width={380} />
            </div>
        );
    }

}

export default FinancialStatusDoughnutChartComponent;