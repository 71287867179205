import React from 'react'
import translate from '../../../Providers/i18n/Translate'

function ContractorPaymentnewWidgetItem(props) {
    return (
        <div className="col-md-12 contractorProjects">
            {showColumsReadOnlyData()}
            <div className="AccordionWrapper">
                {props.item ? props.item.map((value, index) => {
                    return (
                        <div key={index} className="AccordionItem">
                            <input type="checkbox" id={index + 200} />
                            <label className="tab-label" htmlFor={index + 200}>
                                {showColumsApiData(value)}
                            </label>
                            <div className="AccordionContent customerContractorPayment">
                                {/* inner nested tables */}
                                {value.payments && value.payments.length > 0 ?
                                    <div className="tableWrapper">
                                        <table >
                                            <tbody >
                                                {showInnerNestedTableReadOnly()}
                                                {/* inner nested loop data */}
                                                {value.payments.length > 0 ? value.payments.map((valueItem, valueIndex) => {
                                                    return (showInnerNestedTableApiData(props.showFiles, value, valueItem, valueIndex)
                                                    );
                                                }) : null}
                                            </tbody>
                                        </table>
                                    </div> : setNodata()}
                            </div>
                        </div>
                    )
                }) : null}
            </div>
        </div>
    )
}

function setNodata() {
    return (
        <div>
            <div className="noDataLabel">
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/No_data_icon.svg" alt="No Data" />
                <span>{translate('nodata')}</span>
            </div>
        </div>
    )
}

function showInnerNestedTableReadOnly() {
    return (
        <tr>
            <th>{translate('fileNumber')}</th>
            <th>{translate('paymentDate')}</th>
            <th>{translate('currentPercentCompleted')}</th>
            <th>{translate('totalBillAmount')}</th>
            <th>{translate('paymentToOwner')}</th>
            <th>{translate('paymentToContractor')}</th>
            <th>{translate('billRetention')}</th>
            <th>{translate('status')}</th>
            <th>{translate('photoStage')}</th>
        </tr>
    );
}

function showInnerNestedTableApiData(propsItem, value, valueItem, index) {
    return (
        <tr key={index}>
            <td>{value.applicationNo}</td>
            <td>{valueItem.paymentDate}</td>
            <td>
                <div className="progress" >
                    <span>{parseFloat(valueItem.percentageCompleted).toFixed(1) + '%'}</span>
                    <div className="progress-bar progress-bar-striped active" style={{ width: valueItem.percentageCompleted + '%' }}></div>
                </div>
            </td>
            <td >{valueItem.totalBillPaymentDisplay}</td>
            <td>{valueItem.paymentOwner}</td>
            <td>{valueItem.paymentContractor}</td>
            <td>{valueItem.billRetention}</td>
            <td>{valueItem.status}</td>
            <td><button className="findImgBtn" id={valueItem.paymentId} name={value.id} onClick={propsItem}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/image_search_icon.svg'} alt={"search"} /></button></td>
        </tr>
    );
}

function showColumsApiData(value) {
    return (
        <div className="row">
            <table>
                <tbody>
                    <tr>
                        <td>{value.applicationNo}</td>
                        <td>{value.owner}</td>
                        <td>{value.contractAmount}</td>
                        <td>
                            <div className="progress" >
                                <span>{parseFloat(value.percentageCompleted).toFixed(1) + '%'}</span>
                                <div className="progress-bar progress-bar-striped active" style={{ width: value.percentageCompleted + '%' }}></div>
                            </div>
                        </td>
                        <td>{value.contractStatus}</td>
                        <td>{value.serviceType}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function showColumsReadOnlyData() {
    return (
        <div className="projectAccordionDashboardHead">
            <table>
                <tbody>
                    <tr>
                        <th>{translate('fileNumber')}</th>
                        <th>{translate('ownerName')}</th>
                        <th>{translate('contractAmount')} </th>
                        <th>{translate('currentPercentCompleted')}</th>
                        <th>{translate('status')}</th>
                        <th>{translate('serviceType')}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
export default ContractorPaymentnewWidgetItem