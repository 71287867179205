import React from "react";
import { Bar } from 'react-chartjs-2';


class ProjectSummaryBarChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
        this.state = {
            projectSummaryResponse: this.props.barChart
        }
    }

    getStatus(key) {
        var dataList = [];
        dataList = this.state.projectSummaryResponse.map(value => {
            if (key === 'owner') {
                return value.applicationNo;
            } else if (key === 'color') {
                return value.color;
            } else if (key === 'percentageCompleted') {
                return value.percentageCompleted;
            }
        });
        return dataList;
    }

    // Project Summary
    setProjectSummaryResponse() {
        // Bar chart
        var projectSummary = {
            labels: this.getStatus('owner'),
            datasets: [
                {
                    label: 'Project Summary',
                    fill: true,
                    lineTension: 20,
                    backgroundColor: ['#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB',
                        '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB', '#A3A1FB'],
                    data: this.getStatus('percentageCompleted')
                }
            ]
        };
        return projectSummary;
    }

    render() {
        return (

            <div className="widgetContant">
                <Bar ref="chart" data={this.setProjectSummaryResponse()} options={{ legend: false }} width={300} />
            </div>
        );
    }
}

export default ProjectSummaryBarChartComponent;