import React from 'react'
import HISTORY from '../History'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { LOCALES } from '../Providers/i18n'

class CommonUtils {

  ownerShareDummyList() {
    var ownerShareList = [
      {
        "workId": 501,
        "workDesc": "Air Conditioning System"
      },
      {
        "workId": 502,
        "workDesc": "Light Fittings"
      },
      {
        "workId": 503,
        "workDesc": "Kitchen Cabinets"
      },
      {
        "workId": 504,
        "workDesc": "Wardrobes"
      },
      {
        "workId": 505,
        "workDesc": "Internal Wooden Doors ( Leaves )"
      },
      {
        "workId": 506,
        "workDesc": "Antenna"
      },
      {
        "workId": 507,
        "workDesc": "Intercom System"
      },
      {
        "workId": 508,
        "workDesc": "False Ceiling"
      },
      {
        "workId": 509,
        "workDesc": "Car Shed"
      },
      {
        "workId": 510,
        "workDesc": "Interlock"
      },
      {
        "workId": 511,
        "workDesc": "Sweet Soil"
      },
      {
        "workId": 512,
        "workDesc": "Leveling"
      },
      {
        "workId": 513,
        "workDesc": "Water & Electricity connection"
      },
      {
        "workId": 514,
        "workDesc": ""
      },
      {
        "workId": 515,
        "workDesc": ""
      },
      {
        "workId": 516,
        "workDesc": ""
      },
      {
        "workId": 517,
        "workDesc": ""
      }
    ]
    return ownerShareList;
  }

  getLocalParameter(path, lang, location) {
    var isLanguageClicked = JSON.parse(localStorage.getItem('isLanguageClicked'));
    if (isLanguageClicked) {
      HISTORY.push({
        pathname: path,
        search: '?local=' + lang
      })
      localStorage.setItem('isLanguageClicked', false);
    }
    if (location !== "") {
      let value = queryString.parse(location);
      let allowed_locale = [LOCALES.ARABIC, LOCALES.ENGLISH];
      if (value.local && value.local !== '' && allowed_locale.includes(value.local)) {
        if (localStorage.getItem('siteLang') !== value.local) {
          localStorage.setItem('siteLang', value.local);
          window.location.reload();
        }
      }
    }
  }

  // download file from blob
  downloadBlob(url, filename) {
    //const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    a.click();
    return a;
  }

  getLastIndexFromUrl(httpUrl) {
    var str = httpUrl;
    var n = str.lastIndexOf('/');
    var result = str.substring(n + 1);
    var noSpacing_fileName;
    if (result) {
      var strName = result.replace(/%20/g, " ");
      noSpacing_fileName = strName.replace(/ /g, '');
    }
    return noSpacing_fileName;
  }

  getBase64 = file => {
    return new Promise(resolve => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        var resultBase64 = reader.result.replace(/^data:.*?;base64,/, "");
        resolve(resultBase64);
      }
    });
  };

  createProcess(x, y, signNumber, redirectURL) {
    var processObject =
    {
      "process_type": "urn:safelayer:eidas:processes:document:sign:esigp",
      "labels": [
        [
          "digitalid",
          "server",
          "advanced"
        ]
      ],
      "signer": {
        "signature_policy_id": "urn:safelayer:eidas:policies:sign:document:pdf",
        "parameters": {
          "type": "pades-baseline",
          "signature_field": {
            "name": "Sign" + signNumber,
            "location": {
              "page": {
                "number": String(localStorage.getItem('setPageNo'))
              },
              "rectangle": {
                "x": x,
                "y": y,
                "height": 38,
                "width": 124
              }
            },
            "appearance": {
              "signature_details": {
                "details": [
                  {
                    "type": "subject",
                    "title": "Signer Name: "
                  },
                  {
                    "type": "date",
                    "title": "Signature Date: "
                  }
                ]
              }
            }
          }
        }
      },
      "ui_locales": [
        "en_US"
      ],
      "finish_callback_url": redirectURL,
      "views": {
        "document_agreement": {
          "skip_server_id": "true"
        }
      },
      "timestamp": {
        "provider_id": "urn:uae:tws:generation:policy:digitalid"
      }
    }

    return processObject;
  }

  USERAGENT() {
    return navigator.userAgent;
  }

  get4DigitsRandomNum() {
    var a = Math.floor(100000 + Math.random() * 900000)
    a = a.toString().substring(0, 4);
    a = parseInt(a);
    return a;
  }

  getIndex(arr, value) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].applicationNo === value.applicationNo) {
        return arr[i];
      }
    }
    return -1;
  }

  getBOQIndex(arr, workId) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].workId === workId) {
        return arr[i];
      }
    }
    return -1;
  }

  isOwnerScopeIDExists(workId) {
    for (var i = 0; i < this.ownerShareDummyList().length; i++) {
      if (this.ownerShareDummyList()[i].workId === workId) {
        return true;
      }
    }
    return false;
  }

  getOwnerScopeWorkDesc(arr, workId) {
    var arrayList = [];
    if (arr.length > 0) {
      arrayList = arr
    } else {
      arrayList = this.ownerShareDummyList();
    }
    for (var i = 0; i < arrayList.length; i++) {
      if (arrayList[i].workId === workId) {
        return arrayList[i].workDesc;
      }
    }
    return '';
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  isValidFileSize(file) {
    return file.size <= 4e6
  }

  isValidFileType(file) {
    var ext = this.getFileExtension(file.name)
    return ext.toUpperCase() === "PNG" || ext.toUpperCase() === "JPG" || ext.toUpperCase() === "JPEG" || ext.toUpperCase() === "PDF"
  }

  isValidPDFFileType(file) {
    var ext = this.getFileExtension(file.name)
    return ext.toUpperCase() === "PDF"
  }

  getFileExtension(fileName) {
    return fileName ? fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2) : '';
  }

  logoutUnAuthorized401() {
    var local = localStorage.getItem('siteLang');
    localStorage.clear();
    if (local !== null) {
      localStorage.setItem('siteLang', local);
    }
    HISTORY.push('/eservices');
  }

  getPath(id) {
    var path
    switch (id) {
      case 'fbfcd36b-60d0-4152-b955-50ed865fc014':
        path = '/loancalculator'
        break
      //Construction Cost Calculator actionURL=Calculators/Construction
      case 'dd058258-d1fa-4686-8506-1ad00a37498d':
        path = '/constructionCostCalculator'
        break
      case 'ceea8a43-f5d9-47fb-bc92-871ecdcf7a80':
        path = '/houseBooking'
        break
      //Towhom
      case 'eef575c0-32e3-42ba-a6e5-e3a9a9b40235':
        path = '/toWhoItMayConcern'
        break
      //Maintanance Request
      case 'a7274fb4-4999-441a-96cf-75026a3cbf12':
        path = '/maintenanceservice'
        break
      case '6f5781ec-385e-423d-a4d8-1438facbbae0':
        path = '/earlysettlement'
        break
      default:
    }
    return path
  }

  getRedirection(params, changeLang) {
    if (params) {
      var serviceID = queryString.parse(params).serviceID
      if (serviceID && serviceID.length >= 3) {
        localStorage.setItem('serviceID', serviceID)
        localStorage.removeItem('ticket')
        return <Redirect to={serviceID.length > 5 ? this.getPath(serviceID) : '/newhousingRequest'} />
      } else {
        return <Redirect to='/customerDashboard' />
      }
    } else {
      return <Redirect to='/customerDashboard' />
    }
  }

  base64toBlob = (data) => {
    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
  }

  getFileComponent(response) {
    var file = response.file ? response.file : response.payload.file
    var mimetype = this.detectMimeType(file);
    return <div className="popupShowFile">
      {mimetype && mimetype.slice(0, 5).toUpperCase() === "IMAGE"
        ? <img src={"data:" + mimetype + ";base64," + (file)} alt="View doc" />
        : <iframe src={this.getBlobURL(file)} alt="" title="View doc" />}
    </div>
  }

  getBlobURL = (data) => {
    var blob = this.base64toBlob(data)
    return URL.createObjectURL(blob)
  }

  signatures = {
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg"
  };

  detectMimeType(b64) {
    for (var s in this.signatures) {
      if (b64.indexOf(s) === 0) {
        return this.signatures[s];
      }
    }
  }
}

export default new CommonUtils();
