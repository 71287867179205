import React from "react";
import DocumentVerificationStore from './DocumentVerificationStore';
import DocumentVerificationService from './DocumentVerificationService';
import LoadingBar from 'react-top-loading-bar'

class DocumentVerificationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            response: "",
            isSuccess: false,
            referenceNo: ''
        }
         this._onChange = this._onChange.bind(this);
    }

    componentDidMount() {
        DocumentVerificationStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        DocumentVerificationStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getVerifyResponse());
        this.LoadingBar && this.LoadingBar.complete()
    }

    getVerifyResponse() {
        return {
            response: DocumentVerificationStore.response,
            isSuccess: DocumentVerificationStore.isSuccess(),
            message: DocumentVerificationStore.message,
        };
    }

    submitClick() {
        const refNo = this.state.referenceNo;
        // console.log('refNo =' + refNo)
        if(!refNo){
           return;
        }

        this.LoadingBar && this.LoadingBar.continuousStart()
        DocumentVerificationService.verifyDocumentById(refNo)
        .catch(function (error) {
            this.catchBody(error);
        }.bind(this));
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        // console.log("Error logging in : ", errorDescription);
    }

    resetClick() {
        this.setState({ 
            referenceNo: ""
         });
    }

    onChangerReferenceNo = (event) => {
        event.preventDefault()
        this.setState({ 
            referenceNo: event.target.value
         });
    }

    render() {
        const {response, referenceNo, message} = this.state
        return (
            <div className="container-fluid">
            <LoadingBar className='loagingbar' height={0} color='#0091ff' onRef={ref => (this.LoadingBar = ref)} /> 
                <div className="container-fluid">
                    <div className="container mainSection">
                        <div className="col-md-12">
                            <div className="row no-gutters docVerifiWrapper formSection">
                                <h3><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/verifi_icon.svg'} alt="verification"/></i> Documents Verification</h3>
                                <div className="col-md-12 documentVerNumber">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label htmlFor="ReferenceNumber">Reference Number<span>*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                        <input type='text' name="referenceNo" id="ReferenceNumber" placeholder="Please Enter The Reference Number …"
                                            value={referenceNo}
                                            onChange={this.onChangerReferenceNo}/>
                                        </div>
                                        <div className="col-md-4"></div>
                                        <div className="col-md-8 docVerifiBtns">
                                            <input type="reset" onClick={this.resetClick.bind(this)}/>
                                            <input type="submit" id="formSubmit" value="Submit" onClick={this.submitClick.bind(this)}/>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                          <div className="docVerifiResultWrapper">
                          {!response ? <div className="noDatadocVerifiResult" style={{display:''}}>
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/No_data_icon.svg'} alt="No data"/>
                                   {message ? <span>{message}</span>: <span>No Available Documents…</span>} 
                                </div> :   
                                     
                                <object data={response.pdfUrl} type="application/pdf" scrolling="no" allowfullscreen=""><p></p>
                                <iframe src={response.pdfUrl} scrolling="no" allowfullscreen="">
                                    <p>Your browser does not support PDFs.
                                    <a href={response.pdfUrl}>Download the PDF</a>.</p>
                                </iframe>
                                </object>
    }
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocumentVerificationComponent;