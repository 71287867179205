import HouseBookingActions from "./housebooking-actions"
import RequestBuilder from "../utils/requestBuilder"

class HouseBookingService {
    houseBookingFiltersRequest(url) {
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(HouseBookingActions.houseBookingFiltersPayload).catch()
        }
    }

    houseBookingVillasGroupsRequest(url, page, pageSize, Floor, Bedroom, VillaStyle) {
        var params = { "page": page, "pageSize": pageSize, "Floor": Floor, "Bedroom": Bedroom, "VillaStyle": VillaStyle, VillaType: -1 }
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(RequestBuilder.buildUrl(url, params), {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(HouseBookingActions.houseBookingVillasGroupsPayload).catch()
        }
    }

    houseBookingVillasRequest(url, page, pageSize, propertyId, MinPrice, MaxPrice, MinArea, MaxArea, OrderBy) {
        var params = { "page": page, "pageSize": pageSize, "propertyId": propertyId, "MinPrice": MinPrice, "MaxPrice": MaxPrice, "MinArea": MinArea, "MaxArea": MaxArea, "OrderBy": OrderBy }
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(RequestBuilder.buildUrl(url, params), {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(HouseBookingActions.houseBookingVillasPayload).catch()
        }
    }

    houseBookingMyBookingsRequest(url) {
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(HouseBookingActions.houseBookingMyBookingsPayload).catch()
        }
    }

    houseBookingVillaDetailRequest(url, propertyId) {
        var token = localStorage.getItem('accessToken')
        var params = { "propertyId": propertyId }
        if (token) {
            fetch(RequestBuilder.buildUrl(url, params), {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(HouseBookingActions.houseBookingVillaDetailsPayload).catch()
        }
    }

    houseBookingPrintMyBookingRequest(url) {
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(HouseBookingActions.houseBookingPrintMyBookingPayload).catch()
        }
    }

    houseBookingBookHouseRequest(url, propertyId, paymentMethod) {
        var token = localStorage.getItem('accessToken')
        var params = { "propertyId": propertyId, "paymentMethod": paymentMethod }
        if (token) {
            fetch(RequestBuilder.buildUrl(url, params), {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(HouseBookingActions.houseBookingBookHousePayload).catch()
        }
    }

    houseBookingePayRequest(url, propertyId, email, mobile, amount) {
        var token = localStorage.getItem('accessToken')
        var data = new URLSearchParams()
        data.append('PropertyId', propertyId)
        data.append('EmailAddress', email)
        data.append('MobileNo', mobile)
        data.append('Amount', amount)

        if (token) {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + token
                },
                body: data
            }).then(response => response.json()).then(HouseBookingActions.houseBookingPayPayload).catch()
        }
    }
}

export default new HouseBookingService()