import AppDispatcher from '../../Dispatcher'
import HouseBookingConstants from './housebooking-constants'

class HouseBookingActions {
    houseBookingFiltersPayload(response) {
        AppDispatcher.dispatch({ actionType: HouseBookingConstants.HOUSE_BOOKING_FILTERS, response: response })
    }

    houseBookingVillasGroupsPayload(response) {
        AppDispatcher.dispatch({ actionType: HouseBookingConstants.VILLAS_GROUPS, response: response })
    }

    houseBookingVillasPayload(response) {
        AppDispatcher.dispatch({ actionType: HouseBookingConstants.VILLAS, response: response })
    }

    houseBookingMyBookingsPayload(response) {
        AppDispatcher.dispatch({ actionType: HouseBookingConstants.MY_BOOKINGS, response: response })
    }

    houseBookingVillaDetailsPayload(response) {
        AppDispatcher.dispatch({ actionType: HouseBookingConstants.VILLA_DETAILS, response: response })
    }

    houseBookingPrintMyBookingPayload(response) {
        AppDispatcher.dispatch({ actionType: HouseBookingConstants.PRINT_BOOKING, response: response })
    }

    houseBookingBookHousePayload(response) {
        AppDispatcher.dispatch({ actionType: HouseBookingConstants.BOOK_HOUSE, response: response })
    }
    houseBookingPayPayload(response) {
        AppDispatcher.dispatch({ actionType: HouseBookingConstants.EPAY, response: response })
    }
}
export default new HouseBookingActions()