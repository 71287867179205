import React from 'react'
import Loader from '../utils/LoaderBars'
import History from '../../History'
import Captcha from '../utils/captcha'
import InputComponent from '../utils/inputComponent'
import ErrorComponent from '../utils/errorComponent'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../constants/dashboard-constants'
import LoadingProgress from 'react-js-loading-progress-bar'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'

class TowhomServiceComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            errors: [],
            error: null,
            response: {},
            history: null,
            emiratesId: '',
            enableEID: true,
            enableName: true,
            idbarahnumber: '',
            temsAccept: false,
            enableEmail: true,
            temsAccept: false,
            showLoading: false,
            audioPayload: null,
            showResults: false,
            enableMobile: true,
            enableEdbarah: true,
            primaryMobileNo: '',
            total: 135,
            current: 0,
            showFileLoader: false,
            isCaptchaSuccess: false
        }
        this.goBack = this.goBack.bind(this)
        this.addError = this.addError.bind(this)
        this.showError = this.showError.bind(this)
        this.setProfile = this.setProfile.bind(this)
        this.newRequest = this.newRequest.bind(this)
        this.prePopulate = this.prePopulate.bind(this)
        this.removeError = this.removeError.bind(this)
        this.acceptClick = this.acceptClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.openDocument = this.openDocument.bind(this)
        this.isContractor = this.isContractor.bind(this)
        this.failCallback = this.failCallback.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.successCalback = this.successCalback.bind(this)
        this.changeProgressBar = this.changeProgressBar.bind(this)
        this.publicUserRequest = this.publicUserRequest.bind(this)
        this.generateCertificate = this.generateCertificate.bind(this)
        this.loggedInUserRequest = this.loggedInUserRequest.bind(this)
        this.captchaValidateSuccess = this.captchaValidateSuccess.bind(this)
        this.captchaValidationCallback = this.captchaValidationCallback.bind(this)
    }

    componentDidMount() {
        this.addError("emiratesId")
        this.addError("name")
        this.addError("idbarahnumber")
        this.addError("email")
        this.addError("primaryMobileNo")
        // if (localStorage.getItem('ica_response')) {
            // var icaPayload = JSON.parse(localStorage.getItem('ica_response'))
            var eid = localStorage.getItem('eid')
            this.prePopulate(eid)
            // toast.success(icaPayload.message)
        // }
        if (this.isLogedIn() && localStorage.dashboard) {
            this.setProfile(JSON.parse(localStorage.dashboard).payload, this.props.history)
        }
    }

    async prePopulate(eid) {
        if (eid && eid.length !== 0) {
            this.setState({ enableEID: false })
            await this.removeError('emiratesId')
        }
        // if (icaPayload.name && icaPayload.name.length !== 0) {
        //     this.setState({ enableName: false })
        //     await this.removeError('name')
        // }
        // if (icaPayload.edbarahNo && icaPayload.edbarahNo.length !== 0) {
        //     this.setState({ enableEdbarah: false })
        //     await this.removeError('idbarahnumber')
        // }
        // if (icaPayload.email && icaPayload.email.length !== 0) {
        //     // this.setState({ enableEmail: false })
        //     await this.removeError('email')
        // }
        // if (icaPayload.mobileNo && icaPayload.mobileNo.length !== 0) {
        //     // this.setState({ enableMobile: false })
        //     await this.removeError('primaryMobileNo')
        // }
        this.setState({
            emiratesId: eid ? eid : "",
            // name: icaPayload.name ? icaPayload.name : "",
            // idbarahnumber: icaPayload.edbarahNo ? icaPayload.edbarahNo : '',
            // email: icaPayload.email ? icaPayload.email : "",
            // primaryMobileNo: icaPayload.mobileNo ? icaPayload.mobileNo : "",
        })
    }

    componentWillUnmount() {
        localStorage.removeItem('ica_response')
    }

    isLogedIn() {
        return localStorage.getItem('accessToken') && !this.isContractor()
    }

    isContractor() {
        if (localStorage.getItem('accessToken') && localStorage.dashboard) {
            return localStorage.getItem('accessToken') && JSON.parse(localStorage.dashboard).payload.userType === 2
        } else {
            return false
        }
    }

    async setProfile(props, history) {
        if (props) {
            if (props.emiratesId && props.emiratesId.length !== 0) {
                await this.removeError('emiratesId')
            }
            if (props.name && props.name.length !== 0) {
                await this.removeError('name')
            }
            if (props.idbarahnumber && props.idbarahnumber.length !== 0) {
                await this.removeError('idbarahnumber')
            }
            if (props.email && props.email.length !== 0) {
                await this.removeError('email')
            }
            if (props.primaryMobileNo && props.primaryMobileNo.length !== 0) {
                await this.removeError('primaryMobileNo')
            }
            this.setState({
                emiratesId: props.emiratesId ? props.emiratesId : "",
                name: props.name ? props.name : "",
                idbarahnumber: props.idbarahnumber ? props.idbarahnumber : '',
                email: props.email ? props.email : "",
                primaryMobileNo: props.primaryMobileNo ? props.primaryMobileNo : "",
                history: history,
                showResults: false,
                error: null
            })
        }
    }

    async addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            await this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    handleChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (isValid) {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    generateCertificate(e) {
        e.preventDefault();
        this.reset()
        if (this.state.errors.length <= 0) {
            if (this.state.temsAccept) {
                if (this.state.isCaptchaSuccess) {
                    this.captchaValidateSuccess(true, this.state.captchaMessage)
                } else {
                    const captchaMsg = this.state.captchaMessage ? this.state.captchaMessage : translate("invalid_picture")
                    this.showError(captchaMsg)
                }
            } else {
                this.setState({ error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
            }
        } else {
            this.showError(translate('requiredErrorMessage'))
        }
    }

    loggedInUserRequest() {
        this.setState({ showLoading: true })
        var data = new URLSearchParams();
        data.append('EID', this.state.emiratesId);
        data.append('Name', this.state.name);
        data.append('IdBarahNo', this.state.idbarahnumber);
        data.append('EmailAddress', this.state.email);
        // data.append('UnifiedNumber', this.state.unifiednumber);
        data.append('MobileNo', this.state.primaryMobileNo);
        fetch(CONSTANTS.generateTowhomCertificateEndPoint + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': CONSTANTS.authToken
            },
            body: data
        }).then(response => response.json()).then(this.successCalback).catch(this.failCallback);
    }

    publicUserRequest() {
        this.setState({ showLoading: true })
        var data = new URLSearchParams();
        data.append('EID', this.state.emiratesId);
        data.append('Name', this.state.name);
        data.append('IdBarahNo', this.state.idbarahnumber);
        data.append('EmailAddress', this.state.email);
        data.append('MobileNo', this.state.primaryMobileNo);
        fetch(CONSTANTS.generateTowhomCertificateEndPoint + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data
        }).then(response => response.json()).then(this.successCalback).catch(this.failCallback);
    }

    captchaValidateSuccess(response) {
        if (this.isLogedIn()) {
            this.loggedInUserRequest()
        } else {
            this.publicUserRequest()
        }
    }

    successCalback(response) {
        this.setState({ showLoading: false })
        if (response.success) {
            this.setState({ showResults: true, response: response })
        } else {
            if (response.shouldApplyManual) {
                this.goManual(response)
            } else {
                this.showError(response.message)
            }
        }
    }

    failCallback(response) {
        this.showError(response.message)
    }

    closeCallback() {
        this.setState({ error: null, response: null, isCaptchaSuccess: false })
    }

    loadFailed(response) {
        this.setState({ error: <ErrorComponent message={response.message} closeCalback={this.closeCallback} /> })
    }

    openDocument() {
        const url = CONSTANTS.documentEndPoint + this.state.response.payload.referenceNo;
        window.open(url);
    }

    newRequest() {
        localStorage.removeItem("emiratesId")
        History.push('/toWhomItMayConcernCertificate')
    }

    reset() {
        this.setState({ error: null })
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    toggleVisualCaptcha() {
        this.setState({ error: null, isVisualCaptcha: !this.state.isVisualCaptcha })
    }

    captchaInput(e) {
        const value = e.target.value
        this.setState({ captchaInput: value })
    }

    goBack() {
        localStorage.removeItem("emiratesId")
        this.isContractor() ? window.location.href = "/contractorDashboard" : window.location.href = "/customerDashboard"
    }

    acceptClick(e) {
        if (e.target.id === "accept") {
            this.setState({ error: false, errorMessage: "", acceptClick: !this.state.acceptClick, temsAccept: true })
        }
        else {
            this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false, error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
        }
    }

    captchaValidationCallback(success, message) {
        this.setState({ error: null, isCaptchaSuccess: success, captchaMessage: message })
    }

    async changeProgressBar() {
        this.setState({ showFileLoader: true })
        for (let i = 0; i < this.state.total; i++) {
            await this.timeout(100);
            await this.setState({ current: i + 1 });
        }
        await this.timeout(100);
        this.setState({ showFileLoader: false, current: 0 })
    }

    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.showFileLoader ? <div className="PopupBk loaderBG">
                    <div className='toWhomloadingBar'>
                        <LoadingProgress active={true} total={this.state.total} current={this.state.current} visualOnly />
                    </div>
                </div> : null}
                {this.state.showLoading ? <Loader /> : null}
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href={this.isContractor() ? "/contractorDashboard" : "/customerDashboard"}>{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('to-whom-lbl')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* TermsOfUse POPUP */}
                    {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                    {this.state.acceptClick ? <div className="acceptPop pdfView">
                        <button className="popupClose" onClick={this.acceptClick}>X</button>
                        <h2>{translate('TermsOfUse')}</h2>
                        <p>{translate('eligibleterms')}</p>
                        <div className="col-md-12 acceptPopBtn">
                            <div className="row">
                                <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
                            </div>
                        </div>
                    </div> : null}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg'} alt="to whom it may concern" /></i><span>{translate('to-whom-lbl')}</span></div>
                        </div>
                        <div className="col-md-12">
                            {!this.state.showResults ? <div className="service_contant_wrapper formSection">
                                <form>
                                    <div className="row">
                                        <InputComponent inputProps={{ placeHolder: '784-2000-1234567-1', disabled: !this.state.enableEID, lable: translate('emiratesId'), name: 'emiratesId', defaultValue: this.state.emiratesId, value: this.state.emiratesId, type: 'eid', changeListener: this.handleChange, isRequired: true }} />
                                        <InputComponent inputProps={{ placeHolder: this.context.state.siteLang === 'en' ? 'Enter your full name' : 'ادخل الاسم بالكامل', disabled: !this.state.enableName, lable: translate('name'), name: 'name', defaultValue: this.state.name, value: this.state.name, type: 'text', changeListener: this.handleChange, isRequired: true }} />
                                    </div>
                                    <div className="row">
                                        <InputComponent inputProps={{ placeHolder: this.context.state.siteLang === 'en' ? 'Enter your edbaraha number' : 'ادخل رقم إضبارتك', disabled: !this.state.enableEdbarah, lable: translate('idbarahno'), name: 'idbarahnumber', defaultValue: this.state.idbarahnumber, value: this.state.idbarahnumber, type: 'text', changeListener: this.handleChange, isRequired: true }} />
                                        <InputComponent inputProps={{ placeHolder: this.context.state.siteLang === 'en' ? 'Enter your email address' : 'ادخل بريدك الإلكتروني', disabled: !this.state.enableEmail, lable: translate('email'), name: 'email', defaultValue: this.state.email, value: this.state.email, type: 'email', changeListener: this.handleChange, isRequired: true }} />
                                    </div>
                                    <div className="row">
                                        <InputComponent inputProps={{ placeHolder: this.context.state.siteLang === 'en' ? 'Enter your mobile number' : 'ادخل رقم هاتفك المتحرك', disabled: !this.state.enableMobile, lable: translate('mobileNo'), name: 'primaryMobileNo', defaultValue: this.state.primaryMobileNo, value: this.state.primaryMobileNo, type: 'phone', changeListener: this.handleChange, isRequired: true }} />
                                    </div>
                                    <Captcha captchaValidationCallback={this.captchaValidationCallback} />
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <div className="customCheckBox ">
                                                <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
                                                {this.context.state.siteLang === 'en' ?
                                                    <label htmlFor="acceptCB">{translate('IAgreeToTheWebsite')} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/Terms-of-Use", "_blank")} href="">{translate('TermsOfUse')}</a> and <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/Privacy-Policy", "_blank")} href="">{translate('privacypolicy')}</a>.</span></label> :
                                                    <label htmlFor="acceptCB">{"أوافق على"} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/Terms-of-Use", "_blank")} href="">{"شروط الاستخدام"}</a> كما وأوافق على <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/Privacy-Policy", "_blank")} href="">{"سياسة الخصوصية"}</a> للموقع الإلكتروني الخاص بمؤسسة محمد بن راشد للإسكان.</span></label>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 formSectionBtn">
                                            <button type="reset" onClick={this.goBack}>{translate('cancle')}</button>
                                            <button type="submit" onClick={this.generateCertificate}>{translate('submit')}</button>
                                        </div>
                                    </div>
                                </form>
                            </div> :
                                <div className="messages_wrapper success_message">
                                    <HappinessMeter serviceCode="6674" transactionID={this.state.response.message.substring(this.state.response.message.lastIndexOf(":") + 2, this.state.response.message.length - 1)} />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/success.svg'} alt="success" /></span>
                                            <h3>{this.state.response.message}</h3>
                                        </div>
                                        <div className="col-md-12 messages_wrapperBtn">
                                            <button onClick={this.newRequest}>{translate('make-new-request')}</button>
                                        </div>
                                    </div>
                                </div>}
                            {this.state.error}
                            {this.state.errorMessage}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TowhomServiceComponent