import React from 'react'
import '../../../reactToastify.css'
import NoData from '../../utils/nodata'
import Loader from '../../utils/LoaderBars'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import CostumerModuleUtilities from '../../utils/customerModuleUtilities'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
class AllowedToCancelApplicationsListingComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            redirect: false,
            props: '',
            applicationsResponse: [],
            applicationStatus: '',
            isLoading: false
        }
        this.modifyTicket = this.modifyTicket.bind(this)
        this.setApplications = this.setApplications.bind(this)
        this.getAllowedToCancelApplications = this.getAllowedToCancelApplications.bind(this)
    }

    componentDidMount() {
        this.setState({ applicationStatus: this.props.location.state.applicationStatus })
        this.getAllowedToCancelApplications()
        this.setState({ props: this.props, isLoading: true })
    }

    getAllowedToCancelApplications() {
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.listApplicationsAllowedToCancelEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplications).catch();
        }
    }

    setApplications(response) {
        var cancelApplicationItems = []
        var items = response.payload
        var applicationStatus = this.state.applicationStatus.toString()
        if (items.length > 0) {
            items.map((cancelApplication, index) => {
                let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
                let cls = CostumerModuleUtilities.getStatusLableColor(cancelApplication.status)
                let ticket = ''
                var count = 0
                if (applicationStatus === cancelApplication.status.toString()) {
                    if (cancelApplication.cancelServiceTickets.length === 1) {
                        if (cancelApplication.cancelServiceTickets[0].status !== 30) {
                            count = cancelApplication.cancelServiceTickets.length
                            ticket = cancelApplication.cancelServiceTickets[0].ticket
                        }
                    } else {
                        var nonRejectedTickets = this.getNotRejectedTickets(cancelApplication.cancelServiceTickets)
                        if (nonRejectedTickets.length === 1) {
                            count = nonRejectedTickets.length
                            ticket = cancelApplication.cancelServiceTickets[0].ticket
                        } else {
                            nonRejectedTickets.map((ticketObject, index) => {
                                count = nonRejectedTickets.length
                                ticket = ticketObject.ticket
                                return ticketObject
                            })
                        }
                        // count = cancelApplication.cancelServiceTickets.length
                    }
                    cancelApplicationItems.push(<CancelServiceApplicationListingItem modifyTicket={this.modifyTicket} ticket={ticket} itemsCount={count} key={index} index={index} payload={cancelApplication} image={img} className={cls} />)
                }
                return (cancelApplicationItems)
            })
            this.setState({ applicationsResponse: response.payload, redirect: false, itemsArray: cancelApplicationItems, isLoading: false })
        } else {
            this.setState({ redirect: true, isLoading: false })
        }
    }

    getNotRejectedTickets(tickets) {
        var notRejectedTickets = []
        tickets.map((ticket) => {
            if (ticket.status !== 30)
                notRejectedTickets.push(ticket)
        })
        return notRejectedTickets
    }

    modifyTicket(e) {
        const index = e.currentTarget.id
        var applicationNo = ''
        var ticketNo = ''
        /*condition for having at-least one ticket for one application*/
        if (this.state.applicationsResponse[parseInt(index)].cancelServiceTickets.length > 0) {
            /*condition for having more than one ticket for one application*/
            if (this.state.applicationsResponse[parseInt(index)].cancelServiceTickets.length > 1) {
                var nonRejectedTickets = this.getNotRejectedTickets(this.state.applicationsResponse[parseInt(index)].cancelServiceTickets)
                if (nonRejectedTickets.length === 0) {
                    applicationNo = this.state.applicationsResponse[parseInt(index)].applicationNo
                    this.state.props.history.push({ pathname: '/cancelProgressServiceRequest', state: { applicationIndex: index, ticketNo: ticketNo, applicationNo: applicationNo, applicationStatus: this.state.applicationsResponse[parseInt(index)].status } })
                }
                else if (nonRejectedTickets.length > 1) {
                    applicationNo = this.state.applicationsResponse[parseInt(index)].applicationNo
                    this.state.props.history.push({ pathname: "/cancelServiceTicketsListing", state: { ticketNo: ticketNo, applicationNo: applicationNo, applicationIndex: index, applicationStatus: this.state.applicationStatus } })
                } else {
                    if (nonRejectedTickets[0].status === 10) {
                        applicationNo = this.state.applicationsResponse[parseInt(index)].applicationNo
                        this.state.props.history.push({ pathname: '/cancelServiceTicketsListing', state: { applicationIndex: index, ticketNo: ticketNo, applicationNo: applicationNo, applicationStatus: this.state.applicationsResponse[parseInt(index)].status } })
                    } else {
                        ticketNo = nonRejectedTickets[0].ticket
                        applicationNo = this.state.applicationsResponse[parseInt(index)].applicationNo
                        this.state.props.history.push({ pathname: '/cancelProgressServiceRequest', state: { ticketNo: ticketNo, applicationNo: applicationNo, applicationStatus: this.state.applicationsResponse[parseInt(index)].status } })
                    }
                }
            } else {
                if (this.state.applicationsResponse[parseInt(index)].cancelServiceTickets[0].status === 30) {
                    applicationNo = this.state.applicationsResponse[parseInt(index)].applicationNo
                    this.state.props.history.push({ pathname: '/cancelProgressServiceRequest', state: { ticketNo: ticketNo, applicationNo: applicationNo, applicationIndex: index, applicationStatus: this.state.applicationStatus } })
                } else if (this.state.applicationsResponse[parseInt(index)].cancelServiceTickets[0].status === 10) {
                    applicationNo = this.state.applicationsResponse[parseInt(index)].applicationNo
                    this.state.props.history.push({ pathname: '/cancelServiceTicketsListing', state: { applicationIndex: index, ticketNo: ticketNo, applicationNo: applicationNo, applicationStatus: this.state.applicationsResponse[parseInt(index)].status } })
                } else {
                    ticketNo = this.state.applicationsResponse[parseInt(index)].cancelServiceTickets[0].ticket
                    applicationNo = this.state.applicationsResponse[parseInt(index)].applicationNo
                    this.state.props.history.push({ pathname: '/cancelProgressServiceRequest', state: { ticketNo: ticketNo, applicationNo: applicationNo, applicationStatus: this.state.applicationsResponse[parseInt(index)].status } })
                }
            }
        } else {
            applicationNo = this.state.applicationsResponse[parseInt(index)].applicationNo
            this.state.props.history.push({ pathname: '/cancelProgressServiceRequest', state: { ticketNo: ticketNo, applicationNo: applicationNo, applicationIndex: index, applicationStatus: this.state.applicationStatus } })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.isLoading ? <Loader /> : null}
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    {/* delete confirmation popup */}
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('allowedToCancleTitle')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" /></i>
                                <span>{translate('allowedToCancleTitle')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    {this.state.redirect ? <NoData message={translate('nodata')} /> : this.state.itemsArray}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function CancelServiceApplicationListingItem(props) {
    return (
        <div className="col-md-6 housingRequestWrapperAll">
            <div className="houseingRequestInn housingRequestWrapper">
                <div className="row no-gutters">
                    <div className="col-md-2">
                        <i><img src={props.image} alt="MBRHE" /></i>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-12">
                                <strong>{props.payload.service}</strong>
                                {props.itemsCount === 1 ? <span> {translate('ticket-number')} </span> : null}
                                {props.itemsCount === 1 ? <span> {props.ticket} </span> : ''}
                                <div >
                                    <span> {translate('applicationNo')}</span>
                                    <span>: {props.payload.applicationNo} </span>
                                </div>
                            </div>
                            <div className="col-12">
                                <span>{translate('status')} </span>
                                <span className={props.class}>{props.payload.statusName}</span>
                            </div>
                            <div className="col-12">
                                <p className="housingRequestsince">{props.payload.createdAt != null ? props.payload.createdAt : props.payload.lastUpdatedAt}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 housingRequestBtns">
                        {props.itemsCount === 1 && props.payload.cancelServiceTickets[0].status === 10 ? <button id={props.index} onClick={props.modifyTicket}>{props.itemsCount === 0 ? null : <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/eye_icon.svg'} alt="View" />} {translate('view')} </button> :
                            <button id={props.index} onClick={props.modifyTicket}>{props.itemsCount === 0 ? null : <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg'} alt="Edit" />} {props.itemsCount === 0 ? translate('Apply') : translate('modify')} </button>}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default AuthenticatedComponent(AllowedToCancelApplicationsListingComponent)