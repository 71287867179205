import React from 'react'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import NoData from '../utils/nodata'
import Loader from '../utils/LoaderBars'
import MaintenanceInsuranceStore from './maintenance-insurance-store'
import MaintenanceInsuranceService from './maintenance-insurance-service'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class MaintenanceInsuranceDetailsComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super()
        this.state = {
            itemsArray: [],
            showLoading: true
        }
        this.onChange = this.onChange.bind(this)
        this.setMaintenanceInsuranceRequests = this.setMaintenanceInsuranceRequests.bind(this)
    }

    componentDidMount() {
        MaintenanceInsuranceStore.addChangeListener(this.onChange)
        MaintenanceInsuranceService.fetchMaintenanceInsuranceRequest(100, "?local=" + this.context.state.siteLang)
    }

    onChange() {
        this.setMaintenanceInsuranceRequests(MaintenanceInsuranceStore.response)
    }

    setMaintenanceInsuranceRequests(response) {
        if (response.success && response.payload.length > 0) {
            var items = []
            response.payload.map((item, index) => {
                items.push(<MaintenanceInsuranceItem key={index} index={index} payload={item} />)
            })
            this.setState({ itemsArray: items })
        } else {
            // console.log(response)
        }
        this.setState({ showLoading: false })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('maintenance-insurance-widget-heading')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ContractorPayment.svg'} alt="Maintenance Insurance" /></i><span>{translate('maintenance-insurance-widget-heading')}</span></div>
                        </div>{this.state.showLoading ? <Loader /> :
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    {this.state.itemsArray.length > 0 ? this.state.itemsArray : <NoData message={translate('nodata')} />}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function MaintenanceInsuranceItem(props) {
    return (
        <div className='col-md-6'>
            <div className="service_contant_wrapper maintenanceInsuranceWrapper maintenanceRequestsWrapperBox" >
                <h4><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/maintenanc.svg'} alt="no data" /></i>{translate('requestNo')}: {props.payload.id}</h4>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="maintenanceInsuranceItem">{translate('ContactPersonName')}: <span>{props.payload.contactPerson}</span></div>
                        <div className="maintenanceInsuranceItem">{translate('mobileNo')}: <span>{props.payload.mobileNo}</span></div>
                        <div className="maintenanceInsuranceItem">{translate('granteePeriod')}: <span>{props.payload.granteePeriod}</span></div>
                    </div>
                    <div className="col-sm-6">
                        <div className="maintenanceInsuranceItem">{translate('OfficePhoneNo')}: <span>{props.payload.officeNo}</span></div>
                        <div className="maintenanceInsuranceItem">{translate('supplier')}: <span>{props.payload.supplier}</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthenticatedComponent(MaintenanceInsuranceDetailsComponent)