import React from 'react'
import '../../../reactToastify.css'
import { LOCALES } from '../../../Providers/i18n'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
class Settings extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            enableEmailNotifications: false,
            enableMobilePushNotifications: false,
            enableSmsVerificationsOnLogin: false,
            languageCulture: 'DEFAULT',
            enableGrayScale: false,
        }
        this.initSettings = this.initSettings.bind(this)
        this.success = this.success.bind(this)
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    initSettings(settings) {
        if (settings)
            this.setState({
                enableEmailNotifications: settings.enableEmailNotifications,
                enableMobilePushNotifications: settings.enableMobilePushNotifications,
                enableSmsVerificationsOnLogin: settings.enableSmsVerificationsOnLogin,
                languageCulture: settings.languageCulture,
                enableGrayScale: settings.enableGrayScale
            })
    }

    handleSwitch(e) {
        const { name, checked } = e.target
        this.setState({ [name]: checked }, () => {
            this.updateSettings()
        });
    }

    handleCommunicationLanguageSwitchChange(e) {
        this.setState({ languageCulture: e.target.value }, () => {
            this.updateSettings()
        });
    }

    updateSettings() {
        var data = new URLSearchParams();
        data.append('enableEmailNotifications', this.state.enableEmailNotifications);
        data.append('enableMobilePushNotifications', this.state.enableMobilePushNotifications);
        data.append('enableSmsVerificationsOnLogin', this.state.enableSmsVerificationsOnLogin);
        data.append('languageCulture', this.state.languageCulture);
        data.append('enableGrayScale', this.state.enableGrayScale);
        fetch(CONSTANTS.updateSettingsEndPoint + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': CONSTANTS.authToken
            },
            body: data
        })
            .then(response => response.json())
            .then(this.success)
            .catch(this.loadFailed);
    }

    loadFailed(response) {
        toast.error(response.message)
    }

    success(response) {
        toast.success(response.message)
    }

    render() {
        return (
            <ul>
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <li>
                    <label htmlFor="language">{translate('communication-language')}</label>
                    {this.context.state.siteLang === LOCALES.ENGLISH ?
                        <select id="language" value={this.state.languageCulture} name='handleCommunicationLanguageSwitchChange' onChange={this.handleCommunicationLanguageSwitchChange.bind(this)} className="form-control">
                            <option value="DEFAULT">Please Select</option>
                            <option value="en-US">English(US)</option>
                            <option value="ar-AE">العربية</option>
                        </select> :
                        <select value={this.state.languageCulture} name='handleCommunicationLanguageSwitchChange' onChange={this.handleCommunicationLanguageSwitchChange.bind(this)} className="form-control">
                            <option value="DEFAULT">الرجاء الاختيار</option>
                            <option value="en-US">English(US)</option>
                            <option value="ar-AE">العربية</option>
                        </select>}
                </li>
                <li>
                    <label>{translate('email-notifications')}
                        <div className="switch">
                            <input type="checkbox" name='enableEmailNotifications' checked={this.state.enableEmailNotifications} onChange={this.handleSwitch.bind(this)} />
                            <span className="slider round"></span>
                        </div>
                    </label>
                </li>
                <li>
                    <label>{translate('mobile-notifications')}
                        <div className="switch">
                            <input type="checkbox" name='enableMobilePushNotifications' checked={this.state.enableMobilePushNotifications} onChange={this.handleSwitch.bind(this)} />
                            <span className="slider round"></span>
                        </div>
                    </label>
                </li>
                <li>
                    <label>{translate('sms')}
                        <div className="switch">
                            <input type="checkbox" name='enableSmsVerificationsOnLogin' checked={this.state.enableSmsVerificationsOnLogin} onChange={this.handleSwitch.bind(this)} />
                            <span className="slider round"></span>
                        </div>
                    </label>
                </li>
            </ul>
        )
    }
}
export default AuthenticatedComponent(Settings)