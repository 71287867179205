import AppDispatcher from '../../Dispatcher';
import ContractorDashboardConstant from './ContractorDashboardConstant';


class ContractorDashboardAction {

  contractorProjects(response) {
    AppDispatcher.dispatch({
      actionType: ContractorDashboardConstant.CONTRACTOR_DASHBOARD,
      response: response
    })
  }

  contractorProjectsSummary(response) {
    AppDispatcher.dispatch({
      actionType: ContractorDashboardConstant.CONTRACTOR_PROJECT_SUMMARY,
      response: response
    })
  }

  projectSummary(response) {
    AppDispatcher.dispatch({
      actionType: ContractorDashboardConstant.PROJECT_SUMMARY,
      response: response
    })
  }

  financialStatus(response) {
    AppDispatcher.dispatch({
      actionType: ContractorDashboardConstant.FINANCIAL_STATUS,
      response: response
    })
  }
  
  dashboardServices(response) {
    AppDispatcher.dispatch({
      actionType: ContractorDashboardConstant.CONTRACTOR_DASHBOARD_SERVICES,
      response: response
    })
  }
}
export default new ContractorDashboardAction();