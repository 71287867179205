import InformationServiceAction from './InformationServiceAction';
import InformationServiceConstant from './InformationServiceConstant';
import request from 'reqwest';

class GetHousingApplicationStatusComponentService {
  /** housing application status */
  housingAppStatus(fileNumber, local) {
    return this.housingAppStatusResponse(request({
      url: InformationServiceConstant.HOUSING_APPLICATION_STATUS_URL + fileNumber + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'consumer-key': process.env.CLIENT_ID,
        'consumer-secret': process.env.CLIENT_SECRET
      }
    }));
  }

  housingAppStatusResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        InformationServiceAction.housingApplicationSatus(response);
        return true;
      });
  }


  /** maintenence status */
  maintenenceAppStatus(fileNumber, local) {
    return this.maintenenceStatusResponse(request({
      url: InformationServiceConstant.MAINTENANCE_STATUS_URL + fileNumber + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'consumer-key': process.env.CLIENT_ID,
        'consumer-secret': process.env.CLIENT_SECRET
      }
    }));
  }

  maintenenceStatusResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        InformationServiceAction.maintenanceApplicationSatus(response);
        return true;
      });
  }

  /** payment status */
  paymentStatus(applicationNo, local) {
    return this.paymentStatusResponse(request({
      url: InformationServiceConstant.PAYMENT_STATUS_URL + applicationNo + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'consumer-key': process.env.CLIENT_ID,
        'consumer-secret': process.env.CLIENT_SECRET
      }
    }));
  }

  paymentStatusResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        InformationServiceAction.paymentSatus(response);
        return true;
      });
  }
}
export default new GetHousingApplicationStatusComponentService();