import React from "react"
import history from '../../History'
import GoogleMapReact from 'google-map-react'
import { ScrollToTop } from '../../utils/Scroll'
import InputComponent from '../utils/inputComponent'
import { AppContext } from '../../Providers/context'
import HouseBookingStore from './housebooking-store'
import { ToastContainer, toast } from 'react-toastify'
import translate from '../../Providers/i18n/Translate'
import HouseBookingMapPin from './housebooking-map-pin'
import HouseBookingService from './housebooking-service'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class HouseDetails extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            payload: '',
            response: '',
            acceptClick: false,
            temsAccept: false,
            name: '',
            emailAddress: '',
            MobileNo: '',
            groupId: props.location.state.groupId,
            propertyId: props.location.state ? props.location.state.propertyId : '',
            user: JSON.parse(localStorage.getItem('dashboard')).payload,
            errors: []
        }
        this.onChange = this.onChange.bind(this)
        this.getPins = this.getPins.bind(this)
        this.goBack = this.goBack.bind(this)
        this.myBooking = this.myBooking.bind(this)
        this.acceptClick = this.acceptClick.bind(this)
        this.bookHouse = this.bookHouse.bind(this)
        this.epay = this.epay.bind(this)
        this.addError = this.addError.bind(this)
        this.removeError = this.removeError.bind(this)
        this.inputFieldsChangeHandler = this.inputFieldsChangeHandler.bind(this)
    }

    journeyClick() {
        this.setState({
            journeyBg: !this.state.journeyBg
        })
    }

    acceptClick(e) {
        if (e.target.id === "accept") {
            this.setState({ acceptClick: !this.state.acceptClick, temsAccept: true })
        }
        else if (e.target.name === "temsAccept") {
            if (!e.target.checked) {
                this.setState({ temsAccept: false })
            } else {
                this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false })
            }
        } else {
            this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false })
        }
    }

    componentDidMount() {
        ScrollToTop()
        setTimeout(() => { this.state.user.name && this.state.user.name.length > 0 ? this.removeError("name") : this.addError("name") }, 100)
        setTimeout(() => { this.state.user.primaryMobileNo && this.state.user.primaryMobileNo.length > 0 ? this.removeError("MobileNo") : this.addError("MobileNo") }, 200)
        setTimeout(() => { this.state.user.email && this.state.user.email.length > 0 ? this.removeError("emailAddress") : this.addError("emailAddress") }, 300)
        this.setState({ name: this.state.user.name, emailAddress: this.state.user.email, MobileNo: this.state.user.primaryMobileNo })
        this.villaDetailsRequest()
        HouseBookingStore.addChangeListener(this.onChange)
    }

    componentWillUnmount() {
        HouseBookingStore.removeChangeListener(this.onChange)
    }

    onChange() {
        if (HouseBookingStore.houseBookingVillaDetails && HouseBookingStore.houseBookingVillaDetails.payload) {
            var houseBookingVillaDetails = HouseBookingStore.houseBookingVillaDetails
            var payload = houseBookingVillaDetails.payload
            var bookedHouse = HouseBookingStore.houseBookingBookHousePayload
            if (bookedHouse != null) {
                if (bookedHouse.success) {
                    history.replace({
                        pathname: '/myBooking',
                        state: { printVisible: false }
                    })
                    // toast.success(bookedHouse.message)
                } else {
                    toast.error(bookedHouse.message)
                }
            }
            var epay = HouseBookingStore.houseBookingePayPayload
            if (epay != null) {
                if (epay.success) {
                    window.location.replace(epay.payload.redirectionUrl)
                } else {
                    toast.error(epay.message)
                }
            }
            this.setState({ response: HouseBookingStore.houseBookingVillaDetails, payload: payload })
        }
    }

    villaDetailsRequest() {
        var url = CONSTANTS.houseBookingPropertyDetailsEndPoint + "?local=" + this.context.state.siteLang
        HouseBookingService.houseBookingVillaDetailRequest(url, this.props.location.state.propertyId)
    }

    getPins(payload) {
        var pins = []
        if (payload.relatedProperties.length > 0) {
            payload.relatedProperties.map((villa, index) => {
                return (pins.push(<HouseBookingMapPin key={index} lat={villa.latitude} lng={villa.longitude} callback={this.refreshPins} showPopUp={false} />))
            })
        }
        return pins
    }

    goBack(e) {
        e.preventDefault()
        this.props.history.push({
            pathname: '/groupDetails',
            state: { propertyId: parseInt(e.target.name, 10) }
        })
    }

    myBooking() {
        this.props.history.push({ pathname: '/myBooking' })
    }

    inputFieldsChangeHandler(e, isValid) {
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (isValid) {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    bookHouse(e) {
        if (this.state.temsAccept) {
            // toast.success("Done")
            var url = CONSTANTS.houseBookingBookHouseEndpoint + "?local=" + this.context.state.siteLang
            HouseBookingService.houseBookingBookHouseRequest(url, this.props.location.state.propertyId, 1)
        } else {
            toast.error(translate('acceptterms'))
        }
    }

    epay(e) {
        if (this.state.errors.length <= 0) {
            if (this.state.temsAccept) {
                // toast.success("Done")
                var url = CONSTANTS.houseBookingePayEndpoint + "?local=" + this.context.state.siteLang
                HouseBookingService.houseBookingePayRequest(url, this.props.location.state.propertyId, this.state.emailAddress, this.state.MobileNo, this.getAmount(this.state.payload.bookingAmount))
            } else {
                toast.error(translate('acceptterms'))
            }
        } else {
            toast.error(translate('requiredErrorMessage'))
        }
    }

    getAmount(amount) {
        return parseFloat(amount.substring(0, amount.length - 5).replace(/,/g, ''))
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                {this.state.acceptClick ? <div className="acceptPop">
                    <button className="popupClose" onClick={this.acceptClick}>X</button>
                    <div className="termsConditionsWrapper">
                        <h2>{translate('TermsConditions')}</h2>
                        <strong>شروط (حجز مبدئي لمسكن جاهز)</strong>
                        <ul>
                            <li> صلاحية الحجز لمدة شهر من تاريخه.</li>
                            <li> يعتبر حجز المسكن ملغي في حال عدم استكمال المستندات المطلوبة ، أو عدم مطابقته للشروط.</li>
                            <li> يتم دفع مبلغ على حسب رسالة الموافقة الصادرة من المؤسسة كحجز مبدئي للمسكن </li>
                        </ul>
                        <strong>شروط (حجز مبدئي لمسكن قيد الانشاء)</strong>
                        <ul>
                            <li> يعتبر حجز المسكن ملغي في حال عدم استكمال المستندات المطلوبة أو عدم مطابقته للشروط.</li>
                            <li> المؤسسة غير مسؤولة عن تعويض المتعامل في حال التأخير في تسليم المسكن..</li>
                            <li> لا يحق للمتعامل معاينة المسكن قيد الانشاء لحين الانتهاء من المشروع.</li>
                            <li> لا يحق للمتعامل المطالبة بأية تعديلات على المسكن قيد الإنشاء.</li>
                            <li> يتم دفع مبلغ على حسب رسالة الموافقة الصادرة من المؤسسة كحجز مبدئي للمسكن </li>
                        </ul>
                        <strong>شروط عامة</strong>
                        <ul>
                            <li> لا يتم تسليم المسكن إلا بعد تحصيل قيمته بالكامل.</li>
                            <li> يتم سداد المبلغ المتبقي  أو فرقية المبلغ من حصة المتعامل خلال شهر من ابلاغ المتعامل بذلك، وبخلافه يتم إلغاء الحجز مباشرة و إحالة طلبكم للمشروع القادم.</li>
                            <li> يسمح للمتعامل بتمديد حجز المسكن مرتين فقط على ان لا تتعدى مدة الحجز شهرين من تاريخه مع توضيح سبب التأجيل, وفي حال عدم رغبتكم في المساكن المتاحة بالمشروع يتم إحالة طلبكم للمشروع القادم.</li>
                            <li> لا يسمح بتغيير المسكن المحجوز إلا مره واحدة فقط خلال فترة تخصيصه المبدئي</li>
                            <li> في حال وجود أحد أفراد الأسرة من أصحاب الهمم يرجى توفير (بطاقة سند/ بطاقة أصحاب الهمم)</li>
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <div className="formSectionBtn">
                            <button id="accept" onClick={this.acceptClick} type="submit">{translate('Agree')}</button>
                        </div>
                    </div>
                </div> : null}

                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item"><a href="/houseBooking">{translate('HouseBooking')}</a></li>
                                    <li className="breadcrumb-item"><a name={this.props.location.state.propertyId} onClick={this.goBack} href="#">{translate('RelatedHouses')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('RelatedHousesPayment')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* my booking */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} /></i><span>{translate('housedetails')}</span>
                                {this.state.response.payload && this.state.response.hasHouseBooked ? <button onClick={this.myBooking}>
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" />
                                    <span>{translate('mybookings')}</span>
                                </button> : null}
                            </div>
                        </div>
                    </div>
                    {/* steps */}
                    {this.state.response.payload && (!this.state.response.hasHouseBooked && this.state.response.payload.canbookingHouse) ?
                        <div className="row">
                            <div className="col-md-12 bankLoneProgressBarWrapper">
                                <div className="bankLoneProgressBar">
                                    <div className="bankLoneProgressBarStart">
                                        <div className="bankLoneProgressBarStart1">{translate("confirm_booking")}</div>
                                        <span>1</span>
                                    </div>
                                    <div className="bankLoneProgressBarEnd">
                                        <div className="bankLoneProgressBarStart2">{translate("booking_details")}</div>
                                        <span>2</span>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                    {/* exceed message */}
                    {this.state.response.payload && this.state.response.payload.houseBookingMessage && (this.state.response.hasHouseBooked || !this.state.response.payload.canbookingHouse) ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="warningMsgBox">
                                    <span>
                                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/warrningmsg_icon.svg'} alt="warrning" />
                                    </span>
                                    <span className="Message userJurny">
                                        {this.state.payload.houseBookingMessage}
                                    </span>
                                </div>
                            </div>
                        </div> : null}
                    {/* house details */}
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.payload ? <OldBooking payload={this.state.payload} /> : null}
                        </div>
                    </div>

                    {this.state.response.payload && !this.state.response.hasHouseBooked && this.state.response.payload.canbookingHouse ? <div className="row">
                        <div className="col-md-12">
                            <div className="bookingLocationWrapper">
                                <input defaultChecked="checked" id="tab22" type="radio" name="pay" />
                                <section>
                                    <div className="tab22">
                                        <div className="col-md-12 cashDepoWrapper">
                                            <label htmlFor="tab22">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/creditcard.svg'} /></i>
                                                <span> {translate("pay_by_E_Payment")} </span>
                                            </label>
                                            <div className="row text-center">
                                                <div className="col-md-12">
                                                    <h2 className="blueText">{translate("booking_fee_heading")} {this.state.payload.bookingAmount} {translate("dirham")}</h2>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="bookingCenterForm formSection">
                                                        <div className="row">
                                                            <InputComponent inputProps={{ isRequired: true, class: "col-md-12", lable: translate('name'), name: "name", defaultValue: this.state.name, value: this.state.name, type: 'text', changeListener: this.inputFieldsChangeHandler }} />
                                                        </div>
                                                        <div className="row">
                                                            <InputComponent inputProps={{ isRequired: true, class: "col-md-12", lable: translate('mobileNo'), name: "MobileNo", defaultValue: this.state.MobileNo, value: this.state.MobileNo, type: 'phone', changeListener: this.inputFieldsChangeHandler }} />
                                                        </div>
                                                        <div className="row">
                                                            <InputComponent inputProps={{ isRequired: true, class: "col-md-12", lable: translate('email'), name: "emailAddress", defaultValue: this.state.emailAddress, value: this.state.emailAddress, type: 'email', changeListener: this.inputFieldsChangeHandler }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="warningMsgBox">
                                                <span>
                                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/warrningmsg_icon.svg'} alt="warrning" />
                                                </span>
                                                <span className="Message userJurny">{translate("booking_note_two")}</span>
                                            </div>
                                            <div className="col-md-12 hBookTabsBottom">
                                                <div className="row">
                                                    <div className="col-sm-6 customCheckBox">
                                                        <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="accept2" />
                                                        <label htmlFor="accept2">{translate("IAcceptingThe")}</label>
                                                    </div>
                                                    <div className="col-md-6 formSectionBtn">
                                                        <button onClick={this.epay} type="submit">{translate('PayNow')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div> : null}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="bookingLocationWrapper">
                                <input defaultChecked="checked" id="tab1" type="radio" name="pct" />
                                <input id="tab2" type="radio" name="pct" />
                                <nav>
                                    <ul>
                                        <li className="tab1">
                                            <label htmlFor="tab1">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/3dview.svg'} /></i>
                                                <span>{translate('3DView')}</span>
                                            </label>
                                        </li>
                                        <li className="tab2">
                                            <label htmlFor="tab2">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/locationMap.svg'} /></i>
                                                <span>{translate('Locationonthemap')}</span>
                                            </label>
                                        </li>
                                    </ul>
                                </nav>
                                <section>
                                    <div className="tab1">
                                        <div className="villa3dimage"> <iframe src={this.state.payload.threeDimensionalHouseURL} /></div>
                                    </div>
                                    <div className="tab2">
                                        <div className="villaLocationMap">
                                            <div id="map" style={{ height: '680px', width: '100%', position: 'relative' }}>
                                                {this.state.payload ? <GoogleMapReact
                                                    bootstrapURLKeys={{ key: CONSTANTS.googleMapKey }}
                                                    defaultCenter={{ lat: this.state.payload.latitude ? parseFloat(this.state.payload.latitude) : 0, lng: this.state.payload.longitude ? parseFloat(this.state.payload.longitude) : 0 }}
                                                    defaultZoom={16}>
                                                    <HouseBookingMapPin key={0} payload={this.state.payload} lat={this.state.payload.latitude} lng={this.state.payload.longitude} callback={this.refreshPins} showPopUp={false} />
                                                </GoogleMapReact> : null}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

function OldBooking(props) {
    return (
        <div className="service_contant_wrapper">
            <div className="row no-gutters">
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Housing_Projects.svg'} alt="Zone" /></i>
                    <span><strong> {translate('Zone')} : </strong> {props.payload.landLocation} </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Villa number" /></i>
                    <span><strong> {translate('VillaNo')} : </strong> {props.payload.villaNo} </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Floor" /></i>
                    <span><strong> {translate('NumberofFloors')} : </strong> {props.payload.floor} </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Land number" /></i>
                    <span><strong> {translate('LandNo')} : </strong> {props.payload.plotNumber} </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Building area" /></i>
                    <span><strong> {translate('BuildingArea')} : </strong> {props.payload.basedArea} </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} alt="Number of bedroom" /></i>
                    <span><strong> {translate('NumberofBedrooms')} : </strong> {props.payload.bedroom} </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Land area" /></i>
                    <span><strong> {translate('Landarea')} : </strong> {props.payload.plotArea} </span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="houseBookingCustomTable">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <strong> {translate("price_allocation")}</strong>
                                <span> {props.payload.sellingPrice} </span>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <strong> {translate("booking_price")}</strong>
                                <span> {props.payload.bookingAmount} </span>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <strong> {translate("loan_amount")}</strong>
                                <span> {props.payload.loanAmount} </span>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <strong> {translate("remaining_amount")}</strong>
                                <span> {props.payload.remainingAmount} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthenticatedComponent(HouseDetails)
//add errors at component did mount step when pre filling form if any required field is empty
