import React from "react"
import DateFormater from 'dateformat'
import queryString from 'query-string'
import GoogleMapReact from 'google-map-react'
import LoadingBar from 'react-top-loading-bar'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import HouseBookingMapPin from './housebooking-map-pin'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class HousePayment extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            values: '',
            isSuccess: true,
            confirmResponse: '',
        }
        this.back = this.back.bind(this)
        this.myBooking = this.myBooking.bind(this)
        this.completeRequestError = this.completeRequestError.bind(this)
        this.confirmPaymentRequest = this.confirmPaymentRequest.bind(this)
        this.completeRequestSuccess = this.completeRequestSuccess.bind(this)
    }

    myBooking() {
        this.props.history.push({ pathname: '/myBooking' })
    }

    componentDidMount() {
        this.getEpayConfirmation();
    }

    getEpayConfirmation() {
        let url = this.props.location.search;
        if (url !== "") {
            let value = queryString.parse(url);
            this.confirmPaymentRequest(value)
            this.setState({ values: value });
        }
    }

    confirmPaymentRequest(value) {
        var token = localStorage.getItem('accessToken')
        if (token) {
            var url = CONSTANTS.houseBookingCompleteePayEndpoint + "?local=" + this.context.state.siteLang + "&spTrn=" + value.sptrn
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(this.completeRequestSuccess).catch(this.completeRequestError)
        }
    }

    completeRequestSuccess(response) {
        this.setState({ response: response })
        if (response.success) {
            this.setState({ confirmResponse: response, isSuccess: true })
        } else {
            this.setState({ isSuccess: false })
        }
    }

    back(e) {
        e.preventDefault()
        this.props.history.push({
            pathname: '/houseBooking'
        })
    }

    completeRequestError(response) {
        this.setState({ isSuccess: false })
    }

    render() {
        return (
            <div className="container-fluid">
                <LoadingBar className='loagingbar' height={0} color='#0091ff' onRef={ref => (this.LoadingBar = ref)} />
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item"><a href="/houseBooking">{translate('HouseBooking')}</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" /></i>
                                <span>{translate('housedetails')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 bankLoneProgressBarWrapper">
                            <div className="bankLoneProgressBar">
                                <div className="bankLoneProgressBarStart">
                                    <div className="bankLoneProgressBarStart1">{translate("booking_details")}</div>
                                    <span>1</span>
                                </div>
                                <div className="bankLoneProgressBarEnd">
                                    <div className="bankLoneProgressBarStart2">{translate("confirm_booking")}</div>
                                    {/*<span>2</span>*/}
                                    {this.state.isSuccess ? <span className="donePayment">2</span> : <span className="paymentError">X</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!this.state.isSuccess ? <div className="row">
                        <div className="col-md-12">
                            <div className="paymentFailMsg">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4">
                                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/paymentFailMsg.svg'} alt="ErrMsgPayment" />
                                    </div>
                                    <div className="col-lg-9 col-md-8">
                                        <h2>{translate("Message")}</h2>
                                        <p>{this.state.response.message}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3 col-md-4"></div>
                                    <div className="col-lg-9 col-md-8">
                                        <button onClick={this.back}>{translate('back')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> :
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="service_title_wrapper">
                                        <button onClick={this.myBooking}>
                                            <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" />
                                            <span>{translate('mybookings')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="bookingRefrWrapper">
                                        <h2>{translate("BookingReferenceNumber")} :{this.state.confirmResponse.payload && this.state.confirmResponse.payload.referenceNo}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="service_contant_wrapper">
                                        <div className="yourBookingHead">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row hBookrefNo">
                                                        <div className="col-lg-12"><strong>{translate("yourBookingDetails")}</strong></div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="row  hBookresDt">
                                                        <div className="col-lg-9 col-sm-6"><strong>{translate("BookedDate")}</strong> : </div>
                                                        <div className="col-lg-3 col-sm-6">{this.state.confirmResponse.payload && DateFormater(this.state.confirmResponse.payload.bookingDate, 'dd/mm/yyyy')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row no-gutters yourBookingDetails">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-sm-6"><strong>{translate("projectName")}</strong> : </div>
                                                    <div className="col-sm-6">{this.state.confirmResponse.payload && this.state.confirmResponse.payload.projectDescription}</div>
                                                    <div className="col-sm-6"><strong>{translate("Style")}</strong> : </div>
                                                    <div className="col-sm-6">{this.state.confirmResponse.payload && this.state.confirmResponse.payload.villaStyle}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row no-gutters yourBookingDetails">
                                            <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Housing_Projects.svg'} alt="Zone" /></i>
                                                <span><strong>{translate("Zone")} : </strong>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.landLocation}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Villa number" /></i>
                                                <span><strong>{translate("VillaNo")} : </strong>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.villaNo}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Floor" /></i>
                                                <span><strong>{translate("NumberofFloors")} : </strong>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.floor}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Land number" /></i>
                                                <span><strong>{translate("LandNo")} : </strong>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.id}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Building area" /></i>
                                                <span><strong>{translate("BuildingArea")} : </strong>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.basedArea}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} alt="Number of bedroom" /></i>
                                                <span><strong>{translate("NumberofBedrooms")} : </strong>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.bedroom}</span>
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Land area" /></i>
                                                <span><strong>{translate("Landarea")} : </strong>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.plotArea}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="subHeading">{translate("CalculateTheValueOfTheHouse")}</h3>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="houseBookingCustomTable">
                                                    <div className="row">
                                                        <div className="col-md-3 col-sm-6">
                                                            <strong>{translate("BookingPrice")}</strong>
                                                            <span>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.bookingAmount}</span>
                                                        </div>
                                                        <div className="col-md-3 col-sm-6">
                                                            <strong>{translate("loanAmount")}</strong>
                                                            <span>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.loanAmount}</span>
                                                        </div>
                                                        <div className="col-md-3 col-sm-6">
                                                            <strong>{translate("RemainingAmount")}</strong>
                                                            <span>{this.state.confirmResponse.payload && this.state.confirmResponse.payload.remainingAmount}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service_contant_wrapper">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3>{translate("TermsConditions")}</h3>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="service_contant_wrapper listPoint">
                                                    <h4>شروط (حجز مبدئي لمسكن جاهز)</h4>
                                                    <ul>
                                                        <li>صلاحية الحجز لمدة شهر من تاريخه.</li>
                                                        <li>يعتبر حجز المسكن ملغي في حال عدم استكمال المستندات المطلوبة ، أو عدم مطابقته للشروط.</li>
                                                        <li>يتم دفع مبلغ على حسب رسالة الموافقة الصادرة من المؤسسة كحجز مبدئي للمسكن </li>
                                                    </ul>
                                                    <h4>شروط (حجز مبدئي لمسكن قيد الانشاء)</h4>
                                                    <ul>
                                                        <li>يعتبر حجز المسكن ملغي في حال عدم استكمال المستندات المطلوبة أو عدم مطابقته للشروط.</li>
                                                        <li>المؤسسة غير مسؤولة عن تعويض المتعامل في حال التأخير في تسليم المسكن..</li>
                                                        <li>لا يحق للمتعامل معاينة المسكن قيد الانشاء لحين الانتهاء من المشروع.</li>
                                                        <li>لا يحق للمتعامل المطالبة بأية تعديلات على المسكن قيد الإنشاء.</li>
                                                        <li>يتم دفع مبلغ على حسب رسالة الموافقة الصادرة من المؤسسة كحجز مبدئي للمسكن </li>
                                                    </ul>
                                                    <h4>شروط عامة</h4>
                                                    <ul>
                                                        <li>لا يتم تسليم المسكن إلا بعد تحصيل قيمته بالكامل.</li>
                                                        <li>يتم سداد المبلغ المتبقي  أو فرقية المبلغ من حصة المتعامل خلال شهر من ابلاغ المتعامل بذلك، وبخلافه يتم إلغاء الحجز مباشرة و إحالة طلبكم للمشروع القادم.</li>
                                                        <li>يسمح للمتعامل بتمديد حجز المسكن مرتين فقط على ان لا تتعدى مدة الحجز شهرين من تاريخه مع توضيح سبب التأجيل, وفي حال عدم رغبتكم في المساكن المتاحة بالمشروع يتم إحالة طلبكم للمشروع القادم.</li>
                                                        <li>لا يسمح بتغيير المسكن المحجوز إلا مره واحدة فقط خلال فترة تخصيصه المبدئي</li>
                                                        <li>في حال وجود أحد أفراد الأسرة من أصحاب الهمم يرجى توفير (بطاقة سند/ بطاقة أصحاب الهمم)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service_contant_wrapper">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="bookingLocationWrapper">
                                                    <input defaultChecked="checked" id="tab1" type="radio" name="pct" />
                                                    <input id="tab2" type="radio" name="pct" />
                                                    <nav>
                                                        <ul>
                                                            <li className="tab1">
                                                                <label htmlFor="tab1">
                                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/3dview.svg'} alt="3dview" /></i>
                                                                    <span>{translate('3DView')}</span>
                                                                </label>
                                                            </li>
                                                            <li className="tab2">
                                                                <label htmlFor="tab2">
                                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/locationMap.svg'} alt="Location" /></i>
                                                                    <span>{translate('Locationonthemap')}</span>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                    <section>
                                                        <div className="tab1">
                                                            <div className="villa3dimage">
                                                                <iframe title="3Dimage" src={this.state.confirmResponse.payload && this.state.confirmResponse.payload.threeDimensionalHouseURL} />
                                                            </div>
                                                        </div>
                                                        <div className="tab2">
                                                            <div className="villaLocationMap">
                                                                <div id="map" style={{ height: '100%', width: '100%', position: 'relative' }}>
                                                                    {this.state.confirmResponse.payload ? <GoogleMapReact
                                                                        bootstrapURLKeys={{ key: CONSTANTS.googleMapKey }}
                                                                        defaultCenter={{ lat: this.state.confirmResponse.payload.longitude ? parseFloat(this.state.confirmResponse.payload.longitude) : 0, lng: this.state.confirmResponse.payload.longitude? parseFloat(this.state.confirmResponse.payload.longitude) : 0 }}
                                                                        defaultZoom={16}>
                                                                        <HouseBookingMapPin key={0} payload={this.state.confirmResponse.payload} callback={this.refreshPins} showPopUp={false} />
                                                                    </GoogleMapReact> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        );
    }
}

export default AuthenticatedComponent(HousePayment)