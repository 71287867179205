import React from "react";
// import ImagesUtils from '../../utils/ImagesUtils';
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent';
import ContractorDashboardStore from './ContractorDashboardStore';
import ContractorDashboardService from './ContractorDashboardService';
import ContractorProjectEnum from '../../constants/ContractorProjectEnum'
import { Redirect } from 'react-router-dom';
import ProjectsSummaryPieChartComponent from "./ProjectsSummaryPieChartComponent";
import ProjectSummaryBarChartComponent from "./ProjectSummaryBarChartComponent";
import FinancialStatusDoughnutChartComponent from "./FinancialStatusDoughnutChartComponent";
import ContractorContentLoader from "../Common/ContractorContentLoader";
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import CommonUtils from "../../utils/CommonUtils";


class ContractorDashboardComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
        this.state = {
            serviceEditClick: false,
            widgetClick: false,
            widgetContant: false,
            statusId: '',
            componentType: '',
            page: '0',
            pageSize: '250',
            local: 'en',
            response: '',
            isSuccess: false,
            redirect: false,
            redirectComponet: '',
            propsitem: '',
            summaryResponse: '',
            isSummarySuccess: '',
            services: '',
            servicesSuccess: '',
            projectSummaryResponse: '',
            isProjectSummarySuccess: '',
            financialStatusResponse: '',
            isFinancialStatusSuccess: '',
            isLoading: true,
            showInfo1: false,
            showInfo2: false,
            showInfo3: false,
            oldID: ''
        }
        this._onChange = this._onChange.bind(this);
    }

    // disableBody = target => disableBodyScroll(target);
    // enableBody = target => enableBodyScroll(target);

    toggleShowMore = () => {
        this.setState(prevState => ({
            isShowingMore: !prevState.isShowingMore
        }));
    };

    closeTour = () => {
        this.setState({ isTourOpen: false });
    };

    openTour = () => {
        this.setState({ isTourOpen: true });
    };

    componentDidMount() {
        if (!this.state.response && localStorage.getItem('accessToken') && localStorage.getItem('accessToken').length > 15) {
            this.getProjects();
            this.getProjectsSummaryPieChart();
            this.getProjectSummaryBarChart();
            this.getFinancialStatusDoughnutChart();
            this.getDashboardServices();
        }
        ContractorDashboardStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        ContractorDashboardStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState({ isLoading: false })
        this.setState(this.setProjectsState());
        this.setDashboardServices()
    }

    getDashboardServices() {
        ContractorDashboardService.contractorDashboardServices(this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    setDashboardServices(serviceID) {
        var servicesArray = []
        this.state.services &&
            this.state.services.map((service) => {
                if (service.isContractorService && service.isDashboard) {
                    servicesArray.push(this.prepareService(service, serviceID !== undefined && service.id === serviceID && serviceID !== this.state.oldID))
                }
            })
        this.setState({ dashboardServices: servicesArray })
    }

    prepareService(service, showInfo) {
        return (
            <div key={service.id} className="widgetServiceBox">
                <div className="contractorSerInfoWrapper">
                    <i><img id={service.id} onClick={this.clickInfo.bind(this)} src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg" alt="serInfo" /></i>
                    {showInfo ? <div className="serInfo">{translate('eligibleInfoIconMsg')} <a href={service.serviceInfoLink} target="_blank">{translate('clickhere')}</a>
                    </div> : null}
                </div>
                <a href="/projectsList" onClick={this.redirectClick.bind(this, this.getServicePath(service.id), service)}>
                    <div className="widgetService_icon">
                        <img src={this.getServiceIcon(service.id)} alt={service.description} />
                    </div>
                    <div className="widgetServiceName">
                        <span>{service.name}</span>
                    </div>
                </a>
            </div>
        )
    }

    clickInfo(e) {
        this.setState({ oldID: e.target.id })
        this.setDashboardServices(e.target.id)
    }

    getServicePath(serviceID) {
        //project payments
        if (serviceID === '3a992a92-24b8-447f-a65d-b050b9f1448e') {
            return ContractorProjectEnum.ContractorProjectPayments
            //Open new project file
        } else if (serviceID === '3a222a92-24b8-447f-a65d-b050b9f11d8e') {
            return ContractorProjectEnum.OpenNewProjectFile
            //Retention payments
        } else if (serviceID === '3a1a2a92-24b8-447f-7899-b050b9f1888e') {
            return ContractorProjectEnum.RetentionPayment
        }
    }

    getServiceIcon(serviceID) {
        //project payments
        if (serviceID === '3a992a92-24b8-447f-a65d-b050b9f1448e') {
            return "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractorProjectPayment.svg"
            //Open new project file
        } else if (serviceID === '3a222a92-24b8-447f-a65d-b050b9f11d8e') {
            return "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/opennewfile_icon.svg"
            //Retention payments
        } else if (serviceID === '3a1a2a92-24b8-447f-7899-b050b9f1888e') {
            return "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/RetentionPayment.svg"
        }
    }

    getProjects() {
        ContractorDashboardService.contractorDashboardProjects(this.state.page, this.state.pageSize, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getProjectsSummaryPieChart() {
        ContractorDashboardService.contractorProjectSummary(this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getProjectSummaryBarChart() {
        ContractorDashboardService.projectSummary(this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getFinancialStatusDoughnutChart() {
        ContractorDashboardService.financialStatus(this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    catchBody(error) {
        if (error.status == 401) {
            CommonUtils.logoutUnAuthorized401()
        } else {
            var parseError = JSON.parse(error.response);
            var errorDescription = parseError.error_description;
            //console.log("Error logging in : ", errorDescription);
        }
    }

    setProjectsState() {
        return {
            response: ContractorDashboardStore.response,
            isSuccess: ContractorDashboardStore.isSuccess(),
            services: ContractorDashboardStore.dashboardServicesResponse,
            servicesSuccess: ContractorDashboardStore.isDashboardServicesSuccess(),
            summaryResponse: ContractorDashboardStore.summaryResponse,
            isSummarySuccess: ContractorDashboardStore.isSummarySuccess(),
            projectSummaryResponse: ContractorDashboardStore.projectSummaryResponse,
            isProjectSummarySuccess: ContractorDashboardStore.isProjectSummarySuccess(),
            financialStatusResponse: ContractorDashboardStore.financialStatusResponse,
            isFinancialStatusSuccess: ContractorDashboardStore.isFinancialStatusSuccess()
        };
    }

    handleAddClick() {
        this.setState({
            sidepanelBg: !this.state.sidepanelBg,
            serviceEditClick: !this.state.serviceEditClick,
        })
    }

    linkAccountClick() {
        this.setState({
            linkAccountClick: !this.state.linkAccountClick,
            linkAccountBg: !this.state.linkAccountBg
        })
    }

    redirectClick = (statusId, service) => {
        this.setState({ isClicked: true })
        localStorage.setItem('statusId', statusId)
        localStorage.setItem('serviceCard', service.serviceInfoLink)
    }

    projectsCount(type) {
        if (this.state.response) {
            const totalCount = this.state.response.filter(object => object.source === type);
            return totalCount.length;
        }
        return 0;
    }

    // infoClick1 = () => {
    //     this.setState({ showInfo1: !this.state.showInfo1, showInfo2: false, showInfo3: false });
    // };

    // infoClick2 = () => {
    //     this.setState({ showInfo1: false, showInfo2: !this.state.showInfo2, showInfo3: false });
    // };

    // infoClick3 = () => {
    //     this.setState({ showInfo1: false, showInfo2: false, showInfo3: !this.state.showInfo3 });
    // };

    render() {
        if (localStorage.getItem("userType") === null) {
            const { dashboardServices, response, isSuccess, isSummarySuccess, summaryResponse,
                isProjectSummarySuccess, projectSummaryResponse, isFinancialStatusSuccess, financialStatusResponse } = this.state

            let serviceaddClass = ["sidepanel"];
            if (this.state.serviceEditClick) {
                serviceaddClass.push('sidepanelOpen');
            }

            let linkAccountClass = ["linkAccount"];
            if (this.state.linkAccountClick) {
                linkAccountClass.push('linkAccountOpen');
            }
            // var baseUrl = "https://www.mbrhe.gov.ae/";
            // var url = baseUrl + this.context.state.siteLang + "/Services/Engineering-section-complementary-services/Business-Services/Request-Opening-File-for-New-Project";
            // var url2 = baseUrl + this.context.state.siteLang + "/Services/Engineering-section-complementary-services/Business-Services/Request-For-Running-Payment";
            // var url3 = baseUrl + this.context.state.siteLang + "/Services/Engineering-section-complementary-services/Business-Services/Request-For-Retention-Payment";
            return (
                <div className="container-fluid">
                    {this.state.isLoading ? <ContractorContentLoader /> :
                        <div className="container mainSection">
                            {/* Section1 */}
                            <div className="row">
                                <div className="col-lg-7 contractorServicesWidget">
                                    <div className="widgetWrapper">
                                        <div className="widgetHead">
                                            <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Services_icon.svg" alt="Services" /></span>
                                            <span>{translate('services')}</span>
                                        </div>
                                        <div className="widgetContant">
                                            {dashboardServices}
                                            {/* <div className="widgetServiceBox">
                                                <div className="contractorSerInfoWrapper">
                                                    <i><img onClick={this.infoClick1} src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg" alt="serInfo" /></i>
                                                    {this.state.showInfo1 ? <div className="serInfo">{translate('eligibleInfoIconMsg')} <a href={url} target="_blank">{translate('clickhere')}</a>
                                                    </div> : null}
                                                </div>
                                                <a href="/projectsList" onClick={this.redirectClick.bind(this, ContractorProjectEnum.OpenNewProjectFile)}>
                                                    <div className="widgetService_icon">
                                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/opennewfile_icon.svg" alt="open new file" />
                                                    </div>
                                                    <div className="widgetServiceName">
                                                        <span>{translate('openNewProjectFile')}</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="widgetServiceBox">
                                                <div className="contractorSerInfoWrapper">
                                                    <i><img onClick={this.infoClick2} src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg" alt="serInfo" /></i>
                                                    {this.state.showInfo2 ? <div className="serInfo">{translate('eligibleInfoIconMsg')} <a href={url2} target="_blank">{translate('clickhere')}</a>
                                                    </div> : null}
                                                </div>
                                                <a href="/projectsList" onClick={this.redirectClick.bind(this, ContractorProjectEnum.ContractorProjectPayments)}>
                                                    <div className="widgetService_icon">
                                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractorProjectPayment.svg" alt="Contractor Payment" />
                                                    </div>
                                                    <div className="widgetServiceName">
                                                        <span>{translate('contractorProjectPayment')}</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="widgetServiceBox">
                                                <div className="contractorSerInfoWrapper">
                                                    <i><img onClick={this.infoClick3} src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg" alt="serInfo" /></i>
                                                    {this.state.showInfo3 ? <div className="serInfo">{translate('eligibleInfoIconMsg')} <a href={url3} target="_blank">{translate('clickhere')}</a>
                                                    </div> : null}
                                                </div>
                                                <a href="/projectsList" onClick={this.redirectClick.bind(this, ContractorProjectEnum.RetentionPayment)}>
                                                    <div className="widgetService_icon">
                                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/RetentionPayment.svg" alt="Contractor Payment Request" />
                                                    </div>
                                                    <div className="widgetServiceName">
                                                        <span>{translate('retentionPayment')}</span>
                                                    </div>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 contractorProjectsWidgetWrapper">
                                    <div className="widgetWrapper">
                                        <div className="widgetHead">
                                            <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/projects_summary_icon.svg" alt="projects summary" /></span>
                                            <span>{translate('contractorProjects')}</span>
                                        </div>
                                        {isSummarySuccess ? <ProjectsSummaryPieChartComponent pieChart={summaryResponse} /> : null}
                                    </div>
                                </div>
                            </div>

                            {/* Section2 */}
                            <div className="row">
                                <div className="col-md-12 constructionLoanProjectsWidgetWrapper">
                                    <div className="widgetWrapper">
                                        <div className="widgetHead">
                                            <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Construction_loan_projects.svg" alt="Construction" /></span>
                                            <span>{translate('constructionLoanProjects')}: {this.projectsCount('ENG')}</span>
                                        </div>
                                        <div className="widgetContant">
                                            <div className="col-md-12 contractorProjects">
                                                {this.showColumsReadOnlyData()}
                                                <div className="AccordionWrapper">
                                                    {isSuccess && response ? response.map((value, index) => {
                                                        if (value.source == 'ENG') {
                                                            return (
                                                                <div key={index} className="AccordionItem">
                                                                    <input type="checkbox" id={index} />
                                                                    <label className="tab-label" htmlFor={index}>
                                                                        {this.showColumsApiData(value)}
                                                                    </label>
                                                                    {/* here projects list and button enable check */}
                                                                    <div className="AccordionContent" >
                                                                        {!value.payments.length > 0 ? this.setNodata(value, value.contractStatusId) : null}
                                                                        {this.renderRedirect()}
                                                                        {this.showProjectButton(value)}
                                                                        {/* inner nested tables */}
                                                                        {value.payments && value.payments.length > 0 ?
                                                                            <div className="tableWrapper">
                                                                                <table >
                                                                                    <tbody >
                                                                                        {this.showInnerNestedTableReadOnly()}
                                                                                        {/* inner nested loop data */}
                                                                                        {value.payments.length > 0 ? value.payments.map((valueItem, valueIndex) => {
                                                                                            return (this.showInnerNestedTableApiData(value, valueItem, valueIndex)
                                                                                            );
                                                                                        }) : null}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div> : null}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    }) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 maintenanceGrantProjectWidgetWrapper">
                                    <div className="widgetWrapper">
                                        <div className="widgetHead">
                                            <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Maintenance_grant_projects.svg" alt="Construction" /></span>
                                            <span>{translate('maintenanceGrantProject')} : {this.projectsCount('TQL')}</span>
                                        </div>
                                        <div className="widgetContant">
                                            <div className="col-md-12 contractorProjects">
                                                {this.showColumsReadOnlyData()}
                                                <div className="AccordionWrapper">
                                                    {isSuccess && response ? response.map((value, index) => {
                                                        if (value.source == 'TQL') {
                                                            return (
                                                                <div key={index} className="AccordionItem">
                                                                    <input type="checkbox" id={index} />
                                                                    <label className="tab-label" htmlFor={index}>
                                                                        {this.showColumsApiData(value)}
                                                                    </label>
                                                                    {/* here projects list and button enable check */}
                                                                    <div className="AccordionContent" >
                                                                        {!value.payments.length > 0 ? this.setNodata(value, value.contractStatusId) : null}
                                                                        {this.renderRedirect()}
                                                                        {this.showProjectButton(value)}

                                                                        {/* inner nested tables */}
                                                                        {value.payments && value.payments.length > 0 ?
                                                                            <div className="tableWrapper">
                                                                                <table >
                                                                                    <tbody >
                                                                                        {this.showInnerNestedTableReadOnly()}
                                                                                        {/* inner nested loop data */}
                                                                                        {value.payments.length > 0 ? value.payments.map((valueItem, valueIndex) => {
                                                                                            return (this.showInnerNestedTableApiData(value, valueItem, valueIndex)
                                                                                            );
                                                                                        }) : null}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div> : null}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    }) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Section3 */}
                            <div className="row">
                                <div className="col-lg-6 financialStatusWidgetWrapper">
                                    <div className="widgetWrapper">
                                        <div className="widgetHead">
                                            <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/FinancialStatus.svg" alt="Financial Status" /></span>
                                            <span>{translate('financialStatus')}</span>
                                        </div>
                                        <div className="widgetContant">
                                            <div className="widgetContentIn">
                                                {isFinancialStatusSuccess ? <FinancialStatusDoughnutChartComponent doughnutChart={financialStatusResponse} />
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 projectSummaryWidgetWrapper">
                                    <div className="widgetWrapper">
                                        <div className="widgetHead">
                                            <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/projects_summary_icon.svg" alt="Contractor Payment Request" /></span>
                                            <span>{translate('projectSummary')}</span>
                                        </div>
                                        <div className="widgetContant">
                                            <div className="widgetContentIn">
                                                {isProjectSummarySuccess ? <ProjectSummaryBarChartComponent barChart={projectSummaryResponse} /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* <div className="helpBut"></div> */}
                </div>);
        } else {
            return <Redirect to='/customerDashboard' />
        }
    }

    setNodata(value, statusId) {
        return (
            <div>
                {!value.enableEdit || statusId == ContractorProjectEnum.Closed || statusId == ContractorProjectEnum.FileOpened ?
                    <div className="noDataLabel">
                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/No_data_icon.svg" alt="No Data" />
                        <span>{translate('nodata')}</span>
                    </div> : null}
            </div>
        )
    }

    showColumsReadOnlyData() {
        return (
            <div className="projectAccordionDashboardHead">
                <table>
                    <tbody>
                        <tr>
                            <th>{translate('fileNumber')}</th>
                            <th>{translate('ownerName')}</th>
                            <th>{translate('contractAmount')} </th>
                            <th>{translate('currentPercentCompleted')}</th>
                            <th>{translate('status')}</th>
                            <th>{translate('serviceType')}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    showInnerNestedTableReadOnly() {
        return (
            <tr>
                <th>{translate('fileNumber')}</th>
                <th>{translate('paymentDate')}</th>
                <th>{translate('currentPercentCompleted')}</th>
                <th>{translate('totalBillAmount')}</th>
                <th>{translate('paymentToOwner')}</th>
                <th>{translate('paymentToContractor')}</th>
                <th>{translate('billRetention')}</th>
                <th>{translate('status')}</th>
            </tr>
        );
    }

    showInnerNestedTableApiData(value, valueItem, index) {
        return (
            <tr key={index}>
                <td>{value.applicationNo}</td>
                <td>{valueItem.paymentDate}</td>
                <td>
                    <div className="progress" >
                        <span>{parseFloat(valueItem.percentageCompleted).toFixed(1) + '%'}</span>
                        <div className="progress-bar progress-bar-striped active" style={{ width: valueItem.percentageCompleted + '%' }}></div>
                    </div>
                </td>
                <td >{valueItem.totalBillPaymentDisplay}</td>
                <td>{valueItem.paymentOwner}</td>
                <td>{valueItem.paymentContractor}</td>
                <td>{valueItem.billRetention}</td>
                <td>{valueItem.status}</td>
            </tr>
        );
    }

    showColumsApiData(value) {
        return (
            <div className="row">
                <table>
                    <tbody>
                        <tr>
                            <td>{value.applicationNo}</td>
                            <td>{value.owner}</td>
                            <td>{value.contractAmount}</td>
                            <td>
                                <div className="progress" >
                                    <span>{parseFloat(value.percentageCompleted).toFixed(1) + '%'}</span>
                                    <div className="progress-bar progress-bar-striped active" style={{ width: value.percentageCompleted + '%' }}></div>
                                </div>
                            </td>
                            <td>{value.contractStatus}</td>
                            <td>{value.serviceType}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    setRedirect = (value, page, statusId) => {
        if (value) {
            localStorage.setItem('projectItem', JSON.stringify(value))
        }
        this.setState({
            redirect: true,
            redirectComponet: page
            // item: value
        })
        localStorage.setItem('statusId', statusId)
    }

    renderRedirect = () => {
        var item = this.state.item;
        if (this.state.redirect) {
            return (
                <div>
                    <Redirect
                        to={{
                            pathname: this.state.redirectComponet,
                            state: { item }
                        }}
                    />
                </div>
            )
        }
    }

    showProjectButton(value) {
        if (value.enableEdit) {
            switch ('' + value.contractStatusId) {
                case ContractorProjectEnum.GL:
                    return (
                        <button onClick={() => { this.setRedirect(value, '/openNewProjectFileDetail', ContractorProjectEnum.OpenNewProjectFile) }}>{translate('openNewProjectFile')}</button>
                    );
                case ContractorProjectEnum.GL2:
                    return (
                        <button onClick={() => { this.setRedirect(value, '/openNewProjectFileDetail', ContractorProjectEnum.OpenNewProjectFile) }}>{translate('openNewProjectFile')}</button>
                    );
                case ContractorProjectEnum.IP:
                    return (
                        <button onClick={() => { this.setRedirect(value, '/projectPaymentsDetail', ContractorProjectEnum.ContractorProjectPayments) }}>{translate('contractorProjectPayment')}</button>
                    );
                case ContractorProjectEnum.TVDone:
                    return (
                        <button onClick={() => { this.setRedirect(value, '/projectPaymentsDetail', ContractorProjectEnum.ContractorProjectPayments) }}>{translate('contractorProjectPayment')}</button>
                    );
                case ContractorProjectEnum.Completed:
                    return (
                        <button onClick={() => { this.setRedirect(value, '/retentionPaymentDetail', ContractorProjectEnum.RetentionPayment) }}>{translate('retentionPayment')}</button>
                    );
                default:
                    return "";

            }
        }
    }
}
export default AuthenticatedComponent(ContractorDashboardComponent);
