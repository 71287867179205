import CONSTANTS from '../../constants/dashboard-constants'
import AwarenessActions from "./awareness-actions";

class AwarenessService {
    awarenessRequest(local) {
        var url = CONSTANTS.awarnessesEndpoint + local
        var token = localStorage.getItem('accessToken')
        if (token.length > 15) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(AwarenessActions.awarenessPayload).catch()
        }
    }
}

export default new AwarenessService()