import React from 'react'
import History from '../History'
import queryString from 'query-string'
import { AppContext } from '../Providers/context'
import CONSTANTS from '../Customer/constants/dashboard-constants'

class HappinessMeter extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            showHappiness: false,
            isPublic: false
        }
        this.isLogedIn = this.isLogedIn.bind(this)
        this.happinessPopClick = this.happinessPopClick.bind(this)
        this.getHappinessMeterSettings = this.getHappinessMeterSettings.bind(this)
        this.happinessMeterSettingsSuccess = this.happinessMeterSettingsSuccess.bind(this)
    }

    componentDidMount() {
        if (!this.isLogedIn()) {
            require('../ApplicationBase/Login/style_24.css')
        }
        this.getHappinessMeterSettings()
    }

    isLogedIn() {
        return localStorage.getItem('accessToken')
    }

    happinessPopClick() {
        this.setState({ showHappiness: !this.state.showHappiness })
        !this.isLogedIn() && !this.props.serviceCode ? History.replace("/customerDashboard") : null
    }

    getHappinessMeterSettings() {
        // https://mbrhedoor.azurefd.net/happinessMeterQR?serviceCode=6674&transactionID=0
        var queryStringValue = ''
        // let url = this.props.location.search;
        // if (url !== "") {
        //     let value = queryString.parse(url);
        //     this.setState({ isPublic: true })
        //     queryStringValue = "?local=" + this.context.state.siteLang + "&themeColor=#ff0000&serviceCode=" + value.serviceCode + "&transactionId=" + value.transactionID
        // } else {
        //     this.setState({ isPublic: false })
        queryStringValue = "?local=" + this.context.state.siteLang + "&themeColor=&serviceCode=" + this.props.serviceCode + "&transactionId=" + this.props.transactionID
        // }
        var headers = this.isLogedIn() ? {
            "Authorization": 'bearer ' + localStorage.getItem('accessToken'),
        } : {
            "consumer-key": process.env.HAPPINESS_METER_CONSUMER_KEY,
            "consumer-secret": process.env.HAPPINESS_METER_CONSUMER_SECRET,
        }
        fetch(CONSTANTS.happinessMeterSettingsEndpoint + queryStringValue, {
            method: 'GET',
            headers: headers
        }).then(response => response.json()).then(this.happinessMeterSettingsSuccess).catch(this.loadFailed);
    }

    happinessMeterSettingsSuccess(response) {
        var payload = response.payload
        var urlString = "/happinessMeter?path=" + payload.uaepassUrl + "&json_payload=" + payload.jsonRequest + "&client_id=" + payload.clientId + "&signature=" + payload.signature + "&lang=" + payload.lang + "&timestamp=" + payload.timestamp + "&random=" + payload.random + "&nonce=" + payload.nonce
        setTimeout(function () {
            this.setState({ showHappiness: true, url: urlString })
        }.bind(this), 2000)
    }

    loadFailed(response) {
        console.log("Fail=" + response)
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.showHappiness ? <div className="PopupBk" ></div> : null}
                {this.state.showHappiness && this.state.url ? <div className="viewPopup happinessMeterWrapper">
                    <button className="popupClose" onClick={this.happinessPopClick}>X</button>
                    <iframe title="happiness meter" frameBorder="0" src={this.state.url} />
                </div> : null}
            </div>)
    }
}

export default HappinessMeter