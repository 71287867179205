import React from "react";
import StepZilla from "react-stepzilla";
import BreakDownInfoComponent from './BreakDownInfoComponent';
import RequiredDocumentsComponent from '../Common/RequiredDocumentsComponent';
import ContractorProjectPaymentsStore from '../ContractorProjectPayments/ContractorProjectPaymentsStore';
import ContractorProjectPaymentsService from '../ContractorProjectPayments/ContractorProjectPaymentsService';
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent';
import { ScrollToTop } from '../../utils/Scroll';
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import { LOCALES } from "../../Providers/i18n";
import ContractorProjectEnum from '../../constants/ContractorProjectEnum'
import CommonUtils from '../../utils/CommonUtils';

var handleValue = "";
var source;
var valueObject = '';
class ProjectPaymentsDetailComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            // object: this.props.location.state.item,
            object: JSON.parse(localStorage.getItem('projectItem')),
            statusId: Number(localStorage.getItem('statusId')),
            isChecked: true,
            message: "",
            response: "",
            page: '0',
            pageSize: '250',
            handleLableData: "",
            inputValue: 0,
            typingTimeout: 0,
            isAttachmentView: false,
            loading: false,
            value: '',
            isSuccess: false
        }
        this.handleData = this.handleData.bind(this);
        this.setCheckBoxStateOnSecondStep = this.setCheckBoxStateOnSecondStep.bind(this);
        this.handleLableData = this.handleLableData.bind(this);
        this.selectInput = this.selectInput.bind(this);
        this.getProjectPaymentsState();
        this._onChange = this._onChange.bind(this);
    }


    componentDidMount() {
        ScrollToTop();
        if (!this.state.value && localStorage.getItem('accessToken') && localStorage.getItem('accessToken').length > 15) {
            this.getAllProjectPayments();
        }
        ContractorProjectPaymentsStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        ContractorProjectPaymentsStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getProjectPaymentsState());
    }

    getProjectPaymentsState() {
        return {
            value: ContractorProjectPaymentsStore.response,
            isSuccess: ContractorProjectPaymentsStore.isSuccess()
        };
    }

    getAllProjectPayments() {
        ContractorProjectPaymentsService.projectPayments(this.state.statusId, this.state.page, this.state.pageSize, this.context.state.siteLang);
    }

    toggleChange = () => {
        handleValue = !this.state.isChecked;
        this.setState({
            isChecked: handleValue
        });
        if (this.state.isChecked) {
            this.state.inputValue = 0;
            this.state.response.paymentOwner = 0;
            this.sendApiCall();
        }
    }

    handleData(errorMessage, successResponse) {
        this.setState({
            message: errorMessage,
            response: successResponse,
            isAttachmentView: false
        });
        if (successResponse && this.state.response.paymentOwner > 0) {
            this.setState({
                isChecked: true
            });
        }
        return;
    }

    handleLableData(successResponse) {
        this.setState({
            successResponse: successResponse
        });
    }

    setCheckBoxState(successResponse) {
        var statusId = Number(localStorage.getItem('statusId'));
        if (statusId == ContractorProjectEnum.OpenNewProjectFile || statusId == ContractorProjectEnum.ContractorProjectPayments || statusId == ContractorProjectEnum.RetentionPayment) {
            if (source == 'TQL') {
                return false;
            }
        }
        if (handleValue === "" || handleValue === true) {
            if (successResponse.paymentOwner === "" || successResponse.paymentOwner === 0 || successResponse.paymentOwner === "0") {
                return true;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    selectInput() {
        ScrollToTop();
        setTimeout(function () {
            this.textInput.current.focus();
        }.bind(this), 500)
    }

    onChangePaymentOwner = (event) => {
        var self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            inputValue: event.target.value,
            typingTimeout: setTimeout(function () {
                self.sendApiCall(self.state.inputValue);
                self.state.response.paymentOwner = self.state.inputValue;
            }, 1000)
        });
    }

    sendApiCall = () => {
        ContractorProjectPaymentsService.contractorAddHeaderOwnerAmount(this.state.object.paymentId, this.state.inputValue, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }
    setCheckBoxStateOnSecondStep() {
        this.setState({
            isAttachmentView: true
        })
    }


    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    render() {
        //if source is TQL then its grant payment
        const { isAttachmentView, value, isChecked, message, response, successResponse, isSuccess } = this.state
        if (successResponse) {
            response.totalBillPaymentDisplay = successResponse.totalBillPaymentDisplay;
            response.totalBillPayment = successResponse.totalBillPayment;
            response.percentageCompleted = successResponse.percentageCompleted;
        }
        if (isSuccess && !value.hasOwnProperty('details')) {
            valueObject = CommonUtils.getIndex(value, this.state.object);
            source = valueObject.source;
        }
        if (!valueObject) {
            return null;
        }

        const steps =
            [
                {
                    name: translate('breakDownInformation'), component:
                        <BreakDownInfoComponent
                            object={valueObject}
                            handlerFromParant={this.handleData}
                            handlerLableFromParant={this.handleLableData}
                            checkCheckBoxState={this.setCheckBoxState}
                            selectInput={this.selectInput} />
                },
                {
                    name: translate('requiredDoc'), component: <RequiredDocumentsComponent dashboardResponse={response}
                        setCheckBoxOnSecondStep={this.setCheckBoxStateOnSecondStep}
                    />
                }
            ]
        return (
            <div className="container-fluid">
                {response ? <div className="totalBillPaymentCal">
                    <span>{translate('totalBillAmount')}</span>
                    <span>{response.totalBillPaymentDisplay}</span>
                </div> : null}
                {response ? <div className="totalBillPaymentPer">
                    <span>{translate('totalPercent')}</span>
                    <span>{parseFloat(response.percentageCompleted).toFixed(2) + " %"}</span>
                </div> : null}

                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/contractorDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item"><a href="/projectsList">{translate('projectPaymentRequests')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('contractorProjectPayment')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    {/* Loan */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractorProjectPayment.svg" alt="Contractor Payment" /></i>
                                {valueObject.source == 'ENG' ? <span>{translate('contractorProjectPaymentConstructionloan')}</span> :
                                    <span>{translate('contractorProjectPaymentMaintenanceGrant')}</span>}</div>
                            {message ? <div className="alert alert-danger" role="alert">
                                {message}
                            </div> : null}
                        </div>
                        <div className="col-md-12">
                            <div className="service_contant_wrapper">
                                <div className="row">
                                    <div className="col-md-12 subTitle">
                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/information.svg" alt="project Information" /><h3>{translate('projectInformation')}</h3>
                                    </div>
                                </div>

                                <div className="row formSection">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="">{translate('ownerName')}</label>
                                                <input id="" type="text" disabled defaultValue={valueObject.owner}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="fileNumber">{translate('fileNumber')}</label>
                                                <input id="fileNumber" type="text" disabled defaultValue={valueObject.applicationNo}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="contractAmount">{translate('contractAmount')}</label>
                                                <input id="contractAmount" type="text" disabled defaultValue={valueObject.contractAmount}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="approvedPercent">{translate('approvedPercent')}</label>
                                                <input id="approvedPercent" type="text" disabled defaultValue={valueObject.percentageCompleted}></input>
                                            </div>
                                            <div className="col-md-4">
                                                {valueObject.source == 'ENG' ? <label htmlFor="loanAmount">{translate('loanAmount')}</label> : <label>{translate('grantAmount')}</label>}
                                                <input id="loanAmount" type="text" disabled defaultValue={valueObject.loanAmountDisplay}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="currentPercentCompleted">{translate('currentPercentCompleted')}</label>
                                                {response ? <input id="currentPercentCompleted" type="text" disabled defaultValue={response.percentageCompleted} /> :
                                                    <input id="currentPercentCompleted" type="text" disabled defaultValue="" />}
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="contractStatus">{translate('contractStatus')}</label>
                                                <input id="contractStatus" type="text" disabled defaultValue={valueObject.contractStatus}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="totalBillAmount">{translate('totalBillAmount')}</label>
                                                {response ? <input id="totalBillAmount" type="text" disabled defaultValue={response.totalBillPaymentDisplay} /> :
                                                    <input id="totalBillAmount" type="text" disabled defaultValue="" />}
                                            </div>
                                        </div>
                                    </div>
                                    {valueObject.source == "ENG" && !isAttachmentView ? <div className="col-md-12">
                                        <div className="col-md-12 customCheckBox">
                                            <input type="checkbox" checked={isChecked} id="Iaccept" name="isChecked" defaultValue="" onChange={this.toggleChange} />
                                            <label htmlFor="Iaccept">{translate('sendAmountOwner')}</label>
                                            <span className="note">{translate('kindlySpecify')}</span>
                                        </div>
                                        {response && isChecked ? <div className="col-md-4">
                                            <label htmlFor="paymentToOwner">{translate('paymentToOwner')}</label>
                                            <input id="paymentToOwner" type="text" ref={this.textInput} defaultValue={response.paymentOwner} onChange={this.onChangePaymentOwner}></input>
                                        </div> : null}
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        {!message ? <div className="col-md-12">
                            <div className="service_contant_wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='step-progress'>
                                            {this.context.state.siteLang === LOCALES.ENGLISH ?
                                                <StepZilla preventEnterSubmission={true} stepsNavigation={false} steps={steps} nextButtonText='Next'
                                                    backButtonText='Previous'
                                                /> :
                                                <StepZilla preventEnterSubmission={true} stepsNavigation={false} steps={steps} nextButtonText='التالي'
                                                    backButtonText='السابق'
                                                />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default AuthenticatedComponent(ProjectPaymentsDetailComponent);