import React from "react";
import StepZilla from "react-stepzilla";
import BreakDownInformationComponent from './BreakDownInformationComponent';
import OwnerScopeBreakdownComponent from './OwnerScopeBreakdownComponent';
import RequiredDocumentsComponent from '../Common/RequiredDocumentsComponent';
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent';
import { ScrollToTop } from '../../utils/Scroll';
import ContractorProjectPaymentsStore from '../ContractorProjectPayments/ContractorProjectPaymentsStore';
import ContractorProjectPaymentsService from '../ContractorProjectPayments/ContractorProjectPaymentsService';
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import { LOCALES } from "../../Providers/i18n";
import CommonUtils from '../../utils/CommonUtils';
import ContractorProjectEnum from "../../constants/ContractorProjectEnum";


class OpenNewProjectFileDetailComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        //object: this.props.location.state.item,
        this.state = {
            object: JSON.parse(localStorage.getItem('projectItem')),
            statusId: Number(localStorage.getItem('statusId')),
            message: "",
            value: "",
            page: '0',
            pageSize: '250',
            inputValue: 0,
            typingTimeout: 0,
            isSuccess: false,
            isContractorEntrySuccess: false,
            isHideShowLable: false
        }
        this.getProjectPaymentsState();
        this._onChange = this._onChange.bind(this);
    }

    componentDidMount() {
        ScrollToTop();
        if (!this.state.value) {
            this.getAllProjectPayments();
        }
        ContractorProjectPaymentsStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        ContractorProjectPaymentsStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getProjectPaymentsState());
        this.setState(this.getContractorEntryState());
        if (!this.state.isContractorEntrySuccess) {
            this.getContractorEntry();
        }
    }

    getContractorEntry() {
        ContractorProjectPaymentsService.contractorEntrySatus(CommonUtils.getIndex(this.state.value, this.state.object).id, ContractorProjectEnum.EntryStatusTransactionType, this.context.state.siteLang);
    }

    getContractorEntryState() {
        return {
            contractorEntrySuccessResponse: ContractorProjectPaymentsStore.contractorEntrySuccessResponse,
            isContractorEntrySuccess: ContractorProjectPaymentsStore.isContractorEntrySuccess()
        };
    }

    getProjectPaymentsState() {
        return {
            value: ContractorProjectPaymentsStore.response,
            isSuccess: ContractorProjectPaymentsStore.isSuccess()
        };
    }

    getAllProjectPayments() {
        ContractorProjectPaymentsService.projectPayments(this.state.statusId, this.state.page, this.state.pageSize, this.context.state.siteLang);
    }

    handleData = (successResponse) => {
        let totalContractPercentage = 0;
        // totalContractPercentage = successResponse.reduce((s, p) => s += p.contractPercentage, 0);
        totalContractPercentage = successResponse;
        this.setState({
            totalContractPercentage: totalContractPercentage
        });
    }

    showHidePercentageLable = (boolean) => {
        this.setState({
            isHideShowLable: boolean
        });
    }

    render() {
        const { value, totalContractPercentage, isSuccess } = this.state
        var valueObject = '';
        if (isSuccess) {
            valueObject = CommonUtils.getIndex(value, this.state.object);
        }
        // console.log(this.state.object);
        if (!valueObject) {
            return null;
        }
        const steps =
            [
                {
                    name: translate('breakDownInformation'), component:
                        <BreakDownInformationComponent
                            object={valueObject}
                            handlerLableFromParant={this.handleData}
                            handlerPercentageLable={this.showHidePercentageLable}
                            totalContractPercentage={this.state.totalContractPercentage} />
                },
                {
                    name: translate('requiredDoc'), component: <RequiredDocumentsComponent
                        dashboardResponse={valueObject} requestID={this.state.contractorEntrySuccessResponse && this.state.contractorEntrySuccessResponse.requestId} />
                }
            ]

        // rmove  ownerScope for Grants
        if (this.state.object.serviceType != 'Grants') {
            const ownerScope = {
                name: translate('ownerScope'), component:
                    <OwnerScopeBreakdownComponent object={valueObject}
                        handlerPercentageLable={this.showHidePercentageLable}
                    />
            }
            steps.splice(1, 0, ownerScope);
        }
        // console.log(steps);
        return (
            <div className="container-fluid">
                {this.state.isHideShowLable ?
                    <div className="totalBillPaymentPer">
                        <span>{translate('totalPercent')}</span>
                        {totalContractPercentage ? <span>{totalContractPercentage + " %"}</span> : <span>0 %</span>}
                    </div> : null}
                <div className="container mainSection">
                    <div className="row">

                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/contractorDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item"><a href="/projectsList">{translate('projectFileRequests')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('openNewProjectFile')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    {/* Loan */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/opennewfile_icon.svg" alt="open new file" /></i>
                                {valueObject.source == 'ENG' ? <span>{translate('openNewProjectFileConstructionloan')}</span> :
                                    <span>{translate('openNewProjectFileMaintenanceGrant')}</span>}</div>
                        </div>
                        <div className="col-md-12">
                            <div className="service_contant_wrapper">
                                <div className="row">
                                    <div className="col-md-12 subTitle">
                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/information.svg" alt="project Information" /><h3>{translate('projectInformation')}</h3>
                                    </div>
                                </div>
                                <div className="row formSection">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>{translate('ownerName')}</label>
                                                <input type="text" disabled defaultValue={valueObject.owner}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label>{translate('contractNo')}</label>
                                                <input type="text" disabled defaultValue={valueObject.id}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label>{translate('contractPercent')}</label>
                                                <input type="text" disabled defaultValue={valueObject.percentageCompleted}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label>{translate('fileNumber')}</label>
                                                <input type="text" disabled defaultValue={valueObject.applicationNo}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label>{translate('contractAmount')}</label>
                                                <input type="text" disabled defaultValue={valueObject.contractAmount}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label>{translate('contractStatus')}</label>
                                                <input type="text" disabled defaultValue={valueObject.contractStatus}></input>
                                            </div>
                                            <div className="col-md-4">
                                                {valueObject.source == 'ENG' ? <label>{translate('loanAmount')}</label> : <label>{translate('grantAmount')}</label>}
                                                <input type="text" disabled defaultValue={valueObject.loanAmount}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label>{translate('ownerShare')}</label>
                                                <input type="text" disabled defaultValue={valueObject.ownerShare}></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="service_contant_wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='step-progress'>
                                            {this.context.state.siteLang === LOCALES.ENGLISH ?
                                                <StepZilla preventEnterSubmission={true} stepsNavigation={false} steps={steps} nextButtonText='Next'
                                                    backButtonText='Previous'
                                                /> :
                                                <StepZilla preventEnterSubmission={true} stepsNavigation={false} steps={steps} nextButtonText='التالي'
                                                    backButtonText='السابق'
                                                />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AuthenticatedComponent(OpenNewProjectFileDetailComponent);
