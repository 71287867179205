import React from 'react'
import Loader from '../utils/LoaderBars'
import DateFormater from 'dateformat'
import NumericInput from 'react-numeric-input'
import { ScrollToTop } from '../../utils/Scroll'
import { AppContext } from '../../Providers/context'
import { ToastContainer, toast } from 'react-toastify'
import translate from '../../Providers/i18n/Translate'
import TypicalVillasUtils from './typical_villas_utils'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

const villaStyle = "Style"
const floorCount = "NumberofFloors"
const roomsCount = "NumberofBedrooms"
class TypicalVillasServiceComponent extends React.Component {
    static contextType = AppContext
    constructor() {
        super()
        this.state = {
            villasGroupsArray: [],
            styleValue: 0,
            floorCount: '',
            maxFloor: 0,
            showLoading: false,
            roomsCount: '',
            maxBedRooms: 0,
            pagesNumbersArray: [],
            filterBubblesArray: [],
            villaStyleOptions: [],
            lookupsPayload: '',
            payload: '',
            response: '',
            villaStylesClick: false
            // oldRequest: ''
        }
        this.villaStyleListener = this.villaStyleListener.bind(this)
        this.removeBubbleClick = this.removeBubbleClick.bind(this)
        this.setFilterState = this.setFilterState.bind(this)
        this.prepareBubble = this.prepareBubble.bind(this)
        this.numberOfBedRoomsListener = this.numberOfBedRoomsListener.bind(this)
        this.numberOfFloorsListener = this.numberOfFloorsListener.bind(this)
        this.removeBubble = this.removeBubble.bind(this)
        this.villasGroupsRequest = this.villasGroupsRequest.bind(this)
        this.goToDetailsView = this.goToDetailsView.bind(this)
        this.getImageURL = this.getImageURL.bind(this)
        this.getVillaStyles = this.getVillaStyles.bind(this)
        // this.getOldRequest = this.getOldRequest.bind(this)
        this.villaStylesClick = this.villaStylesClick.bind(this)
        // this.villasConsultantsRequest = this.villasConsultantsRequest.bind(this)
        this.villasFilterRequest = this.villasFilterRequest.bind(this)
        this.setOldRequest = this.setOldRequest.bind(this)
        this.getTypicalVillasApplications = this.getTypicalVillasApplications.bind(this)
        this.setApplications = this.setApplications.bind(this)
    }

    componentDidMount() {
        ScrollToTop()
        this.setState({ showLoading: true })
        localStorage.removeItem("villaResponse")
        localStorage.removeItem("requestStatus")
        localStorage.removeItem("requestId")
        this.villasFilterRequest()
        this.getTypicalVillasApplications()
    }

    getTypicalVillasApplications() {
        if (CONSTANTS.authToken) {
            fetch(CONSTANTS.typicalVillasApplicationsEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplications).catch(() => { this.setState({ isLoading: false }) });
        }
    }

    setApplications(response) {
        if (response.success) {
            var application = JSON.parse(localStorage.getItem('applicationPayload'))
            response.payload.map((app) => {
                if (application.applicationNo === app.applicationNo) {
                    localStorage.setItem('applicationPayload', JSON.stringify(app))
                }
            })
            this.setOldRequest()
        }
    }

    villasFilterRequest() {
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(CONSTANTS.typicalVillasFiltersApiEndpoint + "?Local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then((jsonData) => {
                if (jsonData.success) {
                    this.villasGroupsRequest()
                    this.setState({ lookupsPayload: jsonData.payload, villaStyleOptions: this.getStyleOptions(jsonData.payload.styles), maxFloor: jsonData.payload.floors.length, maxBedRooms: jsonData.payload.bedrooms[jsonData.payload.bedrooms.length - 1] }, () => { localStorage.setItem("filters", JSON.stringify(jsonData.payload)) })
                } else {
                    this.setState({ showLoading: false })
                    toast.error(jsonData.message)
                }
            }).catch((error) => {
                this.setState({ showLoading: false })
                console.log(error)
            })
        }
    }

    villasGroupsRequest() {
        var token = localStorage.getItem('accessToken')
        if (token) {
            fetch(CONSTANTS.typicalVillasApiEndpoint + "?Local=" + this.context.state.siteLang + "&Floor=" + this.state.floorCount + "&Bedroom=" + this.state.roomsCount + "&Style=" + this.state.styleValue, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then((jsonData) => {
                if (jsonData.success) {
                    localStorage.setItem('villasArray', JSON.stringify(jsonData.payload.villas))
                    localStorage.setItem('canApplayTypicalVilla', jsonData.canApplayTypicalVilla)
                    // this.villasConsultantsRequest()
                    // this.getOldRequest()
                    this.setOldRequest()
                    this.setState({ response: jsonData, villasGroupsArray: this.getVillasGroups(jsonData.payload.villas), payload: jsonData.payload.villas })
                } else {
                    this.setState({ showLoading: false })
                    toast.error(jsonData.message)
                }
            }).catch((error) => {
                this.setState({ showLoading: false })
                console.log(error)
            })
        }
    }

    getFilteredVillas(villasArray, floorCount, BedCount, styleId) {
        var villas = []
        if (villasArray.length > 0) {
            villasArray.map((villa) => {
                var hasStyle = styleId === 0 ? true : this.hasStyle(villa.styles, Number(styleId))
                if ((floorCount === villa.floor || floorCount === '') && (BedCount === villa.bedroom || BedCount === '') && (hasStyle || styleId === '')) {
                    villas.push(villa)
                }
            })
        }
        this.setState({ villasGroupsArray: this.getVillasGroups(villas) })
    }

    hasStyle(styles, styleId) {
        var hasStyle = false
        styles.some((style) => {
            return hasStyle = style.id === styleId
        })
        return hasStyle
    }

    // villasConsultantsRequest() {
    //     var token = localStorage.getItem('accessToken')
    //     if (token) {
    //         fetch(CONSTANTS.typicalVillasConsultantsApiEndpoint + "?Local=" + this.context.state.siteLang, {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': 'bearer ' + token
    //             }
    //         }).then(response => response.json()).then((jsonData) => {
    //             this.setState({ consultantsPayload: jsonData.payload }, () => { localStorage.setItem("consultants", JSON.stringify(jsonData.payload)) })
    //         }).catch((error) => {
    //             console.log(error)
    //         })
    //     }
    // }

    getVillasGroups(villasGroups) {
        var array = []
        villasGroups.map((villa) => {
            return (villa.isActive && array.push(<Villa key={villa.id} getVillaStyles={this.getVillaStyles} selectedStyleID={this.state.styleValue} isPopupVilla={false} siteLang={this.context.state.siteLang} payload={villa} goToDetailsView={this.goToDetailsView} lookupsPayload={this.state.lookupsPayload} getImageURL={this.getImageURL} />))
        })
        return array
    }

    getStyleOptions(stylesArray) {
        var styles = []
        this.context.state.siteLang === 'en' ? styles.push(<option key={0} value={0}>Please select</option>) : styles.push(<option key={0} value={0}>الرجاء الاختيار</option>)
        stylesArray.map((styleObject) => {
            return (styles.push(<option key={styleObject.id} value={styleObject.id}>{this.context.state.siteLang === 'en' ? styleObject.title : styleObject.title_ar}</option>))
        })
        return styles
    }

    removeBubbleClick(e) {
        const { name } = e.target
        this.removeBubble(name)
    }

    removeBubble(name) {
        const index = this.state.filterBubblesArray.findIndex(bubble => bubble.key === name)
        if (index >= 0) {
            var array = [...this.state.filterBubblesArray]
            array.splice(index, 1);
            this.setFilterState(name, '', array)
        }
    }

    villaStyleListener(e) {
        const { name, value } = e.target
        if (value !== "0") {
            var index = e.nativeEvent.target.selectedIndex;
            var label = e.nativeEvent.target[index].text;
            this.prepareBubble(name, value, label)
        } else {
            this.removeBubble(name)
        }
    }

    setFilterState(name, value, array) {
        // name === villaStyle && this.setState({ filterBubblesArray: array, styleValue: value }, () => { this.villasGroupsRequest() })
        // name === roomsCount && this.setState({ filterBubblesArray: array, roomsCount: value }, () => { this.villasGroupsRequest() })
        // name === floorCount && this.setState({ filterBubblesArray: array, floorCount: value }, () => { this.villasGroupsRequest() })
        name === villaStyle && this.setState({ filterBubblesArray: array, styleValue: value }, () => { this.getFilteredVillas(this.state.payload, this.state.floorCount, this.state.roomsCount, this.state.styleValue) })
        name === roomsCount && this.setState({ filterBubblesArray: array, roomsCount: value }, () => { this.getFilteredVillas(this.state.payload, this.state.floorCount, this.state.roomsCount, this.state.styleValue) })
        name === floorCount && this.setState({ filterBubblesArray: array, floorCount: value }, () => { this.getFilteredVillas(this.state.payload, this.state.floorCount, this.state.roomsCount, this.state.styleValue) })
    }

    numberOfFloorsListener(value) {
        if (value) {
            this.prepareBubble(floorCount, value)
        } else {
            this.removeBubble(floorCount)
        }
    }

    numberOfBedRoomsListener(value) {
        if (value) {
            this.prepareBubble(roomsCount, value)
        } else {
            this.removeBubble(roomsCount)
        }
    }

    prepareBubble(name, value, label) {
        var array = [...this.state.filterBubblesArray]
        var bubbleObject = { key: '', value: '' }
        bubbleObject.key = name
        bubbleObject.value = <Bubble key={name} label={name} value={label ? label : value} onRemoveClick={this.removeBubbleClick} />
        const index = this.state.filterBubblesArray.findIndex(bubble => bubble.key === name)
        if (index >= 0) {
            array[index] = bubbleObject
        } else {
            array.push(bubbleObject)
        }
        this.setFilterState(name, value, array)
    }

    goToDetailsView(e) {
        e.preventDefault()
        localStorage.setItem("lookupsPayload", JSON.stringify(this.state.lookupsPayload))
        localStorage.setItem("styleID", e.target.id)
        localStorage.setItem("villaID", e.target.name)
        this.props.history.push({ pathname: '/typicalVillasDetail' })
    }

    getImageURL(payload) {
        var url = ''
        var styleID = this.state.styleValue
        if (styleID > 0) {
            var img
            payload.styles.map((style, index) => {
                if (style.id.toString() === styleID) {
                    img = payload.styles[index].image
                }
            })
            if (img) {
                url = CONSTANTS.unifiedVillasImageURL + payload.id + "/" + img
            } else {
                url = CONSTANTS.unifiedVillasImageURL + payload.id + "/" + payload.styles[0].image
            }

        } else {
            url = CONSTANTS.unifiedVillasImageURL + payload.id + "/" + payload.styles[0].image
        }
        return url
    }

    setOldRequest() {
        var oldRequest = JSON.parse(localStorage.getItem('applicationPayload')).request[0]
        if (oldRequest) {
            localStorage.setItem("requestStatus", oldRequest.requestStatus)
            localStorage.setItem("requestId", oldRequest.id)
            this.setState({ showLoading: false, oldRequest: <OldRequest history={this.props.history} villa={TypicalVillasUtils.getVilla(oldRequest.villaId)} payload={oldRequest} lookupsPayload={this.state.lookupsPayload} local={this.context.state.siteLang} /> }, () => { localStorage.setItem("oldTypicalVillaBooking", JSON.stringify(oldRequest)) })
        } else {
            this.setState({ showLoading: false })
        }
    }

    // getOldRequest() {
    //     var token = localStorage.getItem('accessToken')
    //     if (token) {
    //         fetch(CONSTANTS.typicalVillasRequestsListApiEndpoint + "?Local=" + this.context.state.siteLang, {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': 'bearer ' + token
    //             }
    //         }).then(response => response.json()).then((jsonData) => {
    //             if (jsonData.success) {
    //                 this.setState({ showLoading: false }, () => {
    //                     if (jsonData.payload[0]) {
    //                         localStorage.setItem("requestStatus", jsonData.payload[0].requestStatus)
    //                         localStorage.setItem("requestId", jsonData.payload[0].requestId)
    //                         this.setState({ oldRequest: <OldRequest history={this.props.history} villa={TypicalVillasUtils.getVilla(jsonData.payload[0].villaId)} payload={jsonData.payload[0]} lookupsPayload={this.state.lookupsPayload} local={this.context.state.siteLang} /> }, () => { localStorage.setItem("oldTypicalVillaBooking", JSON.stringify(jsonData.payload[0])) })
    //                     }
    //                 })
    //             } else {
    //                 this.setState({ showLoading: false })
    //                 toast.error(jsonData.message)
    //             }
    //         }).catch((error) => {
    //             this.setState({ showLoading: false })
    //             console.log(error)
    //         })
    //     }
    // }

    getTitle(villaID){
        return TypicalVillasUtils.getVilla(villaID).title
    }

    getVillaStyles(e) {
        var villaID = Number(e.target.name)
        var villa = TypicalVillasUtils.getVilla(villaID)
        var array = []
        villa.styles.map((style) => {
            return (villa.isActive && array.push(<Villa key={style.id} selectedStyleID={style.id} isPopupVilla={true} siteLang={this.context.state.siteLang} payload={villa} goToDetailsView={this.goToDetailsView} lookupsPayload={this.state.lookupsPayload} getImageURL={this.getImageURL} image={style.image} />))
        })
        this.villaStylesClick()
        this.setState({ villaStyles: array })
    }

    villaStylesClick() {
        this.setState({ villaStylesClick: !this.state.villaStylesClick })
    }

    render() {
        return (
            <div className="container-fluid newStyleWrapper">
                {this.state.showLoading ? <Loader /> : null}
                {this.state.villaStylesClick ? <div className="PopupBk"></div> : null}
                {this.state.villaStylesClick ? <div className="uploadPopup houseBookingPopupBox">
                    <button className="popupClose" onClick={this.villaStylesClick}>X</button>
                    <div className="houseBookingPopupWrapper">
                        {this.state.villaStyles}
                    </div>
                </div> : null}
                <ToastContainer autoClose={2000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('typicalvillas')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <span>{translate('typicalvillas')}</span>
                            </div>
                        </div>
                    </div>
                    {this.state.oldRequest}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="booking_filterWrapper">
                                <div className="bookingfilterCenter">
                                    <div className="row">
                                        <div className="col-sm-4 booking_filterCol">
                                            <label><strong>{translate('floors')}</strong></label>
                                            <NumericInput placeholder={this.context.state.siteLang === 'en' ? 'All' : 'الكل'} strict mobile name={floorCount} min={1} max={this.state.maxFloor} value={this.state.floorCount} onChange={this.numberOfFloorsListener} />
                                        </div>
                                        <div className="col-sm-4 booking_filterCol">
                                            <label><strong>{translate('bedrooms')}</strong></label>

                                            <NumericInput placeholder={this.context.state.siteLang === 'en' ? 'All' : 'الكل'} strict mobile name={roomsCount} min={2} max={this.state.maxBedRooms} value={this.state.roomsCount} onChange={this.numberOfBedRoomsListener} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="filterChipWrapper">
                                {this.state.filterBubblesArray.map((bubble) => { return (bubble.value) })}
                            </div>
                        </div>
                    </div>

                    {this.state.villasGroupsArray ? <div className="row">
                        <div className="col-md-12">
                            <div className="houseBooking_wrapper">
                                <div className="row">
                                    {this.state.villasGroupsArray}
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>
            </div>
        )
    }
}

function Bubble(props) {
    return (
        <div className="chip">
            <button name={props.label} onClick={props.onRemoveClick}>X</button>
            <span>{translate(props.label)} : </span>
            <span>{props.value}</span>
        </div>
    )
}

function Villa(props) {
    return (
        <div className="col-lg-3 col-lg-3 col-sm-6 houseBookingBoxWrapper">
            <div className="houseBookingBox">
                <div className="houseImage">
                    {props.isPopupVilla ? <div className="houseBookinType">
                        <span>{TypicalVillasUtils.getStyleLabel(props.lookupsPayload, props.payload, props.selectedStyleID, props.siteLang)}</span>
                    </div> : null}
                    {props.isPopupVilla ? <img src={CONSTANTS.unifiedVillasImageURL + props.payload.id + "/" + props.image} alt="villa" /> : <img src={props.getImageURL(props.payload)} alt="villa" />}
                </div>
                <div className="houseDetailsWrapper">
                    <span> {TypicalVillasUtils.getVillaTitle(props.lookupsPayload, props.siteLang)} {props.payload.title}</span>
                    <div className="houseDetails">
                        <ul>
                            <li> <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/house_new.svg'} alt="floors" /></i>
                                <span>{translate('floors')} : {props.payload.floor}</span>
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} alt="bedrooms" /></i>
                                <span>{translate('bedrooms')} : {props.payload.bedroom}</span>
                            </li>
                        </ul>
                    </div>
                    {props.isPopupVilla ? <span>{translate("startingprice")} : {props.payload.startingPrice} {translate("dirham")}</span> : null}
                    <div className="houseBtns">
                        <a className="primary_button" href="#!" id={props.selectedStyleID} name={props.payload.id} onClick={props.isPopupVilla ? props.goToDetailsView : props.getVillaStyles}>{props.isPopupVilla ? translate('Viewdetails') : translate('Viewdesigns')}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OldRequest(props) {
    return (
        <div className="row">
            <div className="col-md-12">

                <div className="service_contant_wrapper">
                    <div className='service_contant_Box'>
                        <div className="yourBookingHead">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row hBookrefNo">
                                        <div className="col-lg-4"><strong>{translate('typicalvillasrequestnumber')}</strong> : {props.payload ? (props.payload.id ? props.payload.id : props.payload.requestId) : ''}</div>
                                        <div className="col-lg-4"><strong>{translate('typicalvillasrequeststatus')}</strong> : {props.payload ? props.payload.requestStatusDesc : ''}</div>
                                        <div className="col-lg-4"><strong>{translate('typicalvillasrequestdate')}</strong> : {props.payload ? DateFormater(props.payload.createdAt, 'dd/mm/yyyy') : ''}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='service_contant_BoxContent'>
                            <div className='row'>
                                <div className='col-lg-9'>
                                    <div className='row no-gutters'>
                                        <div className='col-md-4'><strong>{translate('villatype')}</strong> : {props.payload ? TypicalVillasUtils.getVilla(props.payload.villaId).title : ''}</div>
                                        <div className='col-md-4'><strong>{translate('Style')}</strong> : {props.payload ? TypicalVillasUtils.getStyleLabel(props.lookupsPayload, '', props.payload.styleId, props.local) : ''}</div>
                                        <div className='col-md-4'><strong>{translate('area')}</strong> :  {props.villa ? TypicalVillasUtils.getStyleArea(props.villa, props.styleId) : ''} {translate('sqft')}</div>
                                    </div>
                                    <div className='row no-gutters'>
                                        <div className='col-md-4'><strong>{translate('bedrooms')}</strong> :  {props.villa ? props.villa.bedroom : ''} </div>
                                        <div className='col-md-4'><strong>{translate('floors')}</strong> :  {props.villa ? props.villa.floor : ''}</div>
                                        <div className='col-md-4'><strong>{translate('startingprice')}</strong> :  {props.payload ? props.payload.startingPrice : ''}  {translate("dirham")}</div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='service_contant_BoxContentBtn'>
                                        <button onClick={() => {
                                            localStorage.setItem("lookupsPayload", JSON.stringify(props.lookupsPayload))
                                            localStorage.setItem("styleID", props.payload.styleId)
                                            localStorage.setItem("villaID", props.payload.villaId)
                                            props.history.push({ pathname: '/typicalVillasReview' })
                                        }}>
                                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info_white.svg'} alt="HousingRequest" /></i>
                                            <span>{translate('viewRequest')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="yourBookingHead">
                            <div className="service_contant_BoxContentBtn">
                                <div className="col-lg-4"><strong>{translate('remarks')}</strong> : {props.payload ? props.payload.remarks : ''}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AuthenticatedComponent(TypicalVillasServiceComponent);