import OpenNewProjectFileAction from './OpenNewProjectFileAction';
import OpenNewProjectFileConstant from './OpenNewProjectFileConstant';
import request from 'reqwest';
import when from 'when';

var apiCallType = '';
class OpenNewProjectFileService {

  /** open new project file */
  openNewProjectFileBOQ(contractId, local) {
    apiCallType = 1;
    return this.handleResponse(request({
      url: OpenNewProjectFileConstant.OPEN_NEW_PROJECT_FILE_BOQ_URL + contractId + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }));
  }

  /** open new file add file header details */
  openNewAddHeaderDetail(contractId, workId, contractPercentage, note, local) {
    apiCallType = 2;
    return this.handleResponse(when(request({
      url: OpenNewProjectFileConstant.OPEN_NEW_PROJECT_FILE_ADD_HEADER_URL + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      },
      data: {
        contractId: contractId,
        workId: workId,
        contractPercentage: contractPercentage,
        note: note
      }
    })));
  }

  /** list owner scope */
  getOwnerScope(contractId, local) {
    return this.ownerScopeHandleResponse(request({
      url: OpenNewProjectFileConstant.LIST_OWNER_SCOPE_URL + contractId + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }));
  }

  /** modify and save owner scope */
  modifyAndSaveOwnerScope(id, contractId, workId, ownerWorkAmount, workDesc, local) {
    apiCallType = 2;
    return this.handleModifyAndSaveResponse(when(request({
      url: OpenNewProjectFileConstant.SAVE_MODIFY_OWNER_SCOPE_URL + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      },
      data: {
        id: id,
        contractId: contractId,
        workId: workId,
        ownerWorkAmount: ownerWorkAmount,
        workDesc: workDesc
      }
    })));
  }

  /** clear Or Delete owner scope */
  clearOrDeleteOwnerScope(id, contractId, local) {
    return this.handleClearOrDeleteResponse(when(request({
      url: OpenNewProjectFileConstant.CLEAR_OR_DELETE_OWNER_SCOPE_URL + local + '&id=' + id + '&contractId=' + contractId,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    })));
  }

  handleClearOrDeleteResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        OpenNewProjectFileAction.clearOrDeleteOwnerScope(response);
        return true;
      });
  }

  handleModifyAndSaveResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        OpenNewProjectFileAction.saveAndModifyOwnerScope(response);
        return true;
      });
  }


  ownerScopeHandleResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        OpenNewProjectFileAction.listOwnerScope(response);
        return true;
      });
  }

  handleResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        if (apiCallType == 1) {
          OpenNewProjectFileAction.projectFileBOQ(response);
        } else if (apiCallType == 2) {
          OpenNewProjectFileAction.projectFileAddHeader(response);
        }
        return true;
      });
  }
}

export default new OpenNewProjectFileService();