import CONSTANTS from '../../Customer/constants/dashboard-constants'
import HeaderActions from "./HeaderActions";
import CommonUtils from '../../utils/CommonUtils';

class HeaderService {
    dashboardRequest(local) {
        var url = CONSTANTS.dashboardEndpoint + local;
        var token = 'bearer ' + localStorage.getItem('accessToken');
        if (token.length > 15) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            }).then(function (response) {
                if (response.status == 401) {
                    CommonUtils.logoutUnAuthorized401();
                } else {
                    return response.json()
                }
            }).then(HeaderActions.headerPayload).catch();
        }
    }
}

export default new HeaderService()