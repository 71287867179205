import AppDispatcher from '../../Dispatcher'
import MaintenanceRequestsConstants from './maintenance-requests-constants'

class MaintenanceRequestsActions {
    fetchMaintenanceRequest(response) {
        AppDispatcher.dispatch({ actionType: MaintenanceRequestsConstants.MAINTENANCEREQUESTS, response: response })
    }
    deleteMaintenanceRequest(response) {
        AppDispatcher.dispatch({ actionType: MaintenanceRequestsConstants.DELETEMAINTENANCEREQUEST, response: response })
    }
}
export default new MaintenanceRequestsActions()