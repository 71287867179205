import OpenNewProjectFileConstant from './OpenNewProjectFileConstant';
import BaseStore from '../../BaseStore';

class OpenNewProjectFileStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this._registerToActions.bind(this))
        this._BOQsuccess = '';
        this._BOQresponse = '';
        this._BOQTotalPercentage = '';
        this._addHeaderSuccess = false;
        this._addHeaderResponse = '';
        this._addHeaderMessage = '';
        this.__addHeaderTotalPercentage = '';


        this._ownerScopeSuccess = false;
        this._ownerScopeResponse = '';
        this._ownerScopeMessage = '';

        this._saveModifyOwnerScopeSuccess = false;
        this._saveModifyOwnerScopeResponse = '';
        this._saveModifyOwnerScopeMessage = '';

        this._clearOwnerScopeSuccess = false;
        this._clearOwnerScopeResponse = '';
        this._clearOwnerScopeMessage = '';
    }

    _registerToActions(action) {
        switch (action.actionType) {
            case OpenNewProjectFileConstant.OPEN_NEW_PROJECT_FILE_BOQ:
                this._BOQsuccess = action.response.success;
                this._BOQresponse = action.response.payload;
                this._BOQTotalPercentage = action.response.totalPercentage;
                this.emitChange();
                break;
            case OpenNewProjectFileConstant.OPEN_NEW_PROJECT_FILE_ADD_HEADER:
                this._addHeaderSuccess = action.response.success;
                this._addHeaderResponse = action.response.payload;
                this.__addHeaderTotalPercentage = action.response.totalPercentage;
                this._addHeaderMessage = action.response.message;
                this.emitChange();
                break;
            case OpenNewProjectFileConstant.LIST_OWNER_SCOPE:
                this._ownerScopeSuccess = action.response.success;
                this._ownerScopeResponse = action.response.payload;
                this._ownerScopeMessage = action.response.message;
                this.emitChange();
                break;
            case OpenNewProjectFileConstant.SAVE_MODIFY_OWNER_SCOPE:
                this._saveModifyOwnerScopeSuccess = action.response.success;
                this._saveModifyOwnerScopeResponse = action.response.payload;
                this._saveModifyOwnerScopeMessage = action.response.message;
                this.emitChange();
                break;
            case OpenNewProjectFileConstant.CLEAR_OR_DELETE_OWNER_SCOPE:
                this._clearOwnerScopeSuccess = action.response.success;
                this._clearOwnerScopeResponse = action.response.payload;
                this._clearOwnerScopeMessage = action.response.message;
                this.emitChange();
                break;
            default:
                break;
        }
    }

    isBOQSuccess() {
        return this._BOQsuccess;
    }

    get BOQresponse() {
        return this._BOQresponse;
    }

    get BOQresponseTotalPercentage() {
        return this._BOQTotalPercentage;
    }

    isAddHeaderSuccess() {
        return this._addHeaderSuccess;
    }

    get addHeaderResponse() {
        return this._addHeaderResponse;
    }

    get addHeaderMessage() {
        return this._addHeaderMessage;
    }

    get addHeaderTotalPercentage() {
        return this.__addHeaderTotalPercentage;
    }

    isOwnerScopeSuccess() {
        return this._ownerScopeSuccess;
    }

    get ownerScopeResponse() {
        return this._ownerScopeResponse;
    }

    get ownerScopeMessage() {
        return this._ownerScopeMessage;
    }

    isSaveModifyOwnerScopeSuccess() {
        return this._saveModifyOwnerScopeSuccess;
    }

    get saveModifyOwnerScopeResponse() {
        return this._saveModifyOwnerScopeResponse;
    }

    get saveModifyOwnerScopeMessage() {
        return this._saveModifyOwnerScopeMessage;
    }

    isClearOrDeleteOwnerScopeSuccess() {
        return this._clearOwnerScopeSuccess;
    }

    get clearOrDeleteOwnerScopeResponse() {
        return this._clearOwnerScopeResponse;
    }

    get clearOrDeleteOwnerScopeMessage() {
        return this._clearOwnerScopeMessage;
    }
}

export default new OpenNewProjectFileStore();