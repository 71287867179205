import React from "react";
import translate from '../../Providers/i18n/Translate'
import LoadingBar from 'react-top-loading-bar'
import { ScrollToTop } from '../../utils/Scroll';
import { AppContext } from '../../Providers/context';
import CommonUtils from '../../utils/CommonUtils';
import ContractorProjectEnum from "../../constants/ContractorProjectEnum";
import OpenNewProjectFileStore from '../OpenNewProjectFile/OpenNewProjectFileStore';
import OpenNewProjectFileService from '../OpenNewProjectFile/OpenNewProjectFileService';
import ContractorProjectPaymentsStore from '../ContractorProjectPayments/ContractorProjectPaymentsStore';
import ContractorProjectPaymentsService from '../ContractorProjectPayments/ContractorProjectPaymentsService';
import NumberFormat from 'react-number-format';

class PrintOpenNewProjectFile extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            page: '0',
            pageSize: '250',
            object: this.props.projectListItem,
            statusId: Number(localStorage.getItem('statusId')),
            ownerScope: JSON.parse(localStorage.getItem('ownerScope')),
            isLoaded: false,
            transactionType: ContractorProjectEnum.TransactionType,
        };
        this._onChange = this._onChange.bind(this);
        ScrollToTop();
        this.LoadingBar && this.LoadingBar.continuousStart()
    }

    componentDidMount() {
        ContractorProjectPaymentsStore.addChangeListener(this._onChange);
        OpenNewProjectFileStore.addChangeListener(this._onChange);
        this.setState({
            loading: true,
        })
        this.getAllProjectPayments();
    }

    componentWillUnmount() {
        OpenNewProjectFileStore.removeChangeListener(this._onChange);
        ContractorProjectPaymentsStore.removeChangeListener(this._onChange);
    }


    _onChange() {
        this.setState(this.getProjectPaymentsState());
        this.setState(this.setStateBreakDown());
        this.setState(this.setContractProjectBreakDown());
        this.setState(this.getownerScopeResponseState());
        if (!this.state.isLoaded) {
            if (!this.state.ownerScope && this.state.statusId == ContractorProjectEnum.OpenNewProjectFile) {
                this.getBreakDown();
                this.setState({ isBOQSuccess: false })
            }
            if (!this.state.ownerScope && this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                this.getPPBreakDown();
                this.setState({ isSuccessPaymentHeader: false })
            }
            if (this.state.ownerScope && this.state.statusId == ContractorProjectEnum.OpenNewProjectFile) {
                this.getownerScopeResponse();
            }
        } else {
            this.LoadingBar && this.LoadingBar.complete()
        }
        this.setState({ isLoaded: true })
    }

    getAllProjectPayments() {
        ContractorProjectPaymentsService.projectPayments(this.state.statusId, this.state.page, this.state.pageSize, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getProjectPaymentsState() {
        return {
            paymentsResponse: ContractorProjectPaymentsStore.response,
            isPaymentsSuccess: ContractorProjectPaymentsStore.isSuccess()
        };
    }

    setStateBreakDown() {
        return {
            BoqResponse: OpenNewProjectFileStore.BOQresponse,
            isBOQSuccess: OpenNewProjectFileStore.isBOQSuccess()
        };
    }

    setContractProjectBreakDown() {
        return {
            responsePaymentHeader: ContractorProjectPaymentsStore.responsePaymentHeader,
            isSuccessPaymentHeader: ContractorProjectPaymentsStore.isSuccessPaymentHeader(),
            messagePaymentHeader: ContractorProjectPaymentsStore.messagePaymentHeader
        };
    }

    getBreakDown() {
        OpenNewProjectFileService.openNewProjectFileBOQ(this.state.object.id, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getPPBreakDown() {
        ContractorProjectPaymentsService.projectPaymentsBreakDownInfo(this.state.object.id, this.state.transactionType, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }


    getownerScopeResponse() {
        OpenNewProjectFileService.getOwnerScope(this.state.object.id, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getownerScopeResponseState() {
        return {
            isOwnerScopeSuccess: OpenNewProjectFileStore.isOwnerScopeSuccess(),
            ownerScopeResponse: OpenNewProjectFileStore.ownerScopeResponse,
            ownerScopeMessage: OpenNewProjectFileStore.ownerScopeMessage,

        };
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorMessage = parseError.message;
        console.log("Error logging in : ", errorMessage);
    }

    render() {
        const { isOwnerScopeSuccess, ownerScopeResponse, isLoaded, paymentsResponse, isPaymentsSuccess, isBOQSuccess, BoqResponse, isSuccessPaymentHeader, responsePaymentHeader } = this.state
        var boqResponse = '';
        var totalEstimatedValue = 0;
        var results = 0;
        if (isLoaded && isBOQSuccess && BoqResponse) {
            boqResponse = BoqResponse.sort((a, b) => a.workId - b.workId);
        }
        if (isLoaded && isSuccessPaymentHeader && responsePaymentHeader) {
            boqResponse = responsePaymentHeader.details.sort((a, b) => a.workId - b.workId);
        }
        if (isLoaded && isOwnerScopeSuccess) {
            ownerScopeResponse.sort((a, b) => a.workId - b.workId);
            totalEstimatedValue = parseFloat(ownerScopeResponse.reduce((s, p) => s += p.ownerWorkAmount, 0).toFixed(2));
            //compare two lists and get unmatched items in list 
            results = ownerScopeResponse.filter((id1) => !CommonUtils.ownerShareDummyList().some((id2) => id2.workId === id1.workId));

        }
        //ENG
        var sumMob = 0;
        var sumExca = 0;
        var sumSubStruture = 0;
        var sumSuperSubStruture = 0;
        var sumBlockWorks = 0;
        var sumPlasterWorks = 0;
        var sumElectricWorks = 0;
        var sumPlumbingWorks_int = 0;
        var sumPlumbingWorks_ext = 0;
        var sumInternalFinish = 0;
        var sumExternalFinish = 0;
        var sumWaterProofing = 0;
        var sumAluminiumWork = 0;
        var sumGatesAndMetalWorks = 0;
        var sumJoineryWorks = 0;
        var sumAirCondWorks = 0;
        var sumCleaning = 0;
        //TQL
        var sumGrantBOQ = 0;
        var totalSumOfValue = 0;
        if (boqResponse) {
            boqResponse.map(task => {
                // if this task has the same ID as the edited task
                if (11 === task.workId) {
                    sumMob += task.contractPercentage;
                } else if (21 === task.workId || 22 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumExca += task.paymentPercentage;
                    } else {
                        sumExca += task.contractPercentage;
                    }
                } else if (31 === task.workId || 32 === task.workId || 33 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumSubStruture += task.paymentPercentage;
                    } else {
                        sumSubStruture += task.contractPercentage;
                    }
                } else if (41 === task.workId || 42 === task.workId || 43 === task.workId || 44 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumSuperSubStruture += task.paymentPercentage;
                    } else {
                        sumSuperSubStruture += task.contractPercentage;
                    }
                } else if (51 === task.workId || 52 === task.workId || 53 === task.workId || 54 === task.workId || 55 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumBlockWorks += task.paymentPercentage;
                    } else {
                        sumBlockWorks += task.contractPercentage;
                    }
                } else if (61 === task.workId || 62 === task.workId || 63 === task.workId || 64 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumPlasterWorks += task.paymentPercentage;
                    } else {
                        sumPlasterWorks += task.contractPercentage;
                    }
                } else if (71 === task.workId || 72 === task.workId || 73 === task.workId || 74 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumElectricWorks += task.paymentPercentage;
                    } else {
                        sumElectricWorks += task.contractPercentage;
                    }
                } else if (81 === task.workId || 82 === task.workId || 83 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumPlumbingWorks_int += task.paymentPercentage;
                    } else {
                        sumPlumbingWorks_int += task.contractPercentage;
                    }
                } else if (91 === task.workId || 92 === task.workId || 93 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumPlumbingWorks_ext += task.paymentPercentage;
                    } else {
                        sumPlumbingWorks_ext += task.contractPercentage;
                    }
                } else if (101 === task.workId || 102 === task.workId || 103 === task.workId || 104 === task.workId || 105 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumInternalFinish += task.paymentPercentage;
                    } else {
                        sumInternalFinish += task.contractPercentage;
                    }
                } else if (111 === task.workId || 112 === task.workId || 113 === task.workId || 114 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumExternalFinish += task.paymentPercentage;
                    } else {
                        sumExternalFinish += task.contractPercentage;
                    }
                } else if (121 === task.workId || 122 === task.workId || 123 === task.workId || 124 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumWaterProofing += task.paymentPercentage;
                    } else {
                        sumWaterProofing += task.contractPercentage;
                    }
                } else if (131 === task.workId || 132 === task.workId || 133 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumAluminiumWork += task.paymentPercentage;
                    } else {
                        sumAluminiumWork += task.contractPercentage;
                    }
                } else if (141 === task.workId || 142 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumGatesAndMetalWorks += task.paymentPercentage;
                    } else {
                        sumGatesAndMetalWorks += task.contractPercentage;
                    }
                } else if (151 === task.workId || 152 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumJoineryWorks += task.paymentPercentage;
                    } else {
                        sumJoineryWorks += task.contractPercentage;
                    }
                } else if (161 === task.workId || 162 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumAirCondWorks += task.paymentPercentage;
                    } else {
                        sumAirCondWorks += task.contractPercentage;
                    }
                } else if (171 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumCleaning += task.paymentPercentage;
                    } else {
                        sumCleaning += task.contractPercentage;
                    }
                } else if (300 === task.workId) {
                    if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                        sumGrantBOQ += task.paymentPercentage;
                    } else {
                        sumGrantBOQ += task.contractPercentage;
                    }
                }
            });
            if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
                totalSumOfValue = parseFloat(boqResponse.reduce((s, p) => s += p.paymentPercentage, 0).toFixed(2));
            } else {
                totalSumOfValue = parseFloat(boqResponse.reduce((s, p) => s += p.contractPercentage, 0).toFixed(2));
            }
        }
        var projectListResponse = '';
        if (isPaymentsSuccess) {
            projectListResponse = CommonUtils.getIndex(paymentsResponse, this.state.object);
        }

        return (
            <div className="col-md-12">
                <LoadingBar className='loagingbar' height={0} color='#0091ff' onRef={ref => (this.LoadingBar = ref)} />
                <div className="col-md-12 service_contant_wrapper" style={{ overflow: 'auto' }}>
                    <div className={this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ? 'printWrapper printProjectPaymentTable' : 'printWrapper'}>
                        {projectListResponse ? <div className="header">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td width="50%" className="gov_logo"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></td>
                                        <td width="50%" className="web_logo"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.png'} alt='MBRHE' /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table width="100%" className="printingHeaderdetails">
                                <tbody>
                                    <tr>
                                        <td colSpan="3" width="100%">
                                            <table style={{ width: '100%', marginBottom: '20px', fontSize: "20px", textAlign: 'center', textDecoration: 'underline' }}>
                                                <tbody>
                                                    <tr>
                                                        <td><strong>جدول الكميات</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Bill of Quantity</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%" style={{ padding: '0px' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr className="headerLeftDetails">
                                                        <td width="20%">Owner Name:</td>
                                                        <td width="60%">{projectListResponse.owner}</td>
                                                        <td width="20%">: اسم المالك</td>
                                                    </tr>
                                                    <tr className="headerLeftDetails">
                                                        <td width="20%">Contractor:</td>
                                                        <td width="60%">{projectListResponse.contractorName}</td>
                                                        <td width="20%">: المقاول</td>
                                                    </tr>
                                                    <tr className="headerLeftDetails">
                                                        <td width="20%">Consultant:</td>
                                                        <td width="60%">{projectListResponse.consultantName}</td>
                                                        <td width="20%">: الاستشاري</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td width="10%" style={{ padding: '0px' }}></td>
                                        <td width="40%" style={{ padding: '0px' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    <tr className="headerRightDetails">
                                                        <td width="20%">Date :</td>
                                                        <td width="60%">{projectListResponse.contractStartDate}</td>
                                                        <td width="20%">: التاريخ</td>
                                                    </tr>
                                                    <tr className="headerRightDetails">
                                                        <td width="20%">File No. :</td>
                                                        <td width="60%">{projectListResponse.applicationNo}</td>
                                                        <td width="20%">: رقم الملف</td>
                                                    </tr>
                                                    <tr className="headerRightDetails">
                                                        <td width="20%">Contract Amount :</td>
                                                        <td width="60%">{projectListResponse.contractAmount}</td>
                                                        <td width="20%">: قيمة العقد</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> : null}
                        {/* end header */}
                        <div className="tableContant">
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <td>
                                            <div className="page-header-space"></div>
                                        </td>
                                    </tr>
                                </thead>
                                {this.state.ownerScope ?
                                    <tbody className="ownerScopeTableHead">
                                        <tr>
                                            <th>Description of Work</th>
                                            <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>القيمة بالدرهم</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Estimated Value</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                            <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>ملاحظات هندسية</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Engineering Remarks</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                            <th>وصف العمل</th>
                                            <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>رقم التسلسل</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Sr.No.</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody> :
                                    <tbody>
                                        <tr>
                                            <th>Description of Work</th>
                                            <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>نسبة العقد %</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Contract Percent</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                            {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ? <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>العمل المنجز %</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Actual Done</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th> : null}
                                            <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>الملاحظات</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                            {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ? <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>الدفع %</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Payment %</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th> : null}
                                            {this.state.statusId == ContractorProjectEnum.OpenNewProjectFile ? <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>ملاحظات هندسية</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Engineering Remarks</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th> : null}
                                            <th>وصف العمل</th>
                                            <th>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>رقم التسلسل</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Sr.No.</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </th>
                                        </tr>
                                    </tbody>}

                                {!this.state.ownerScope && projectListResponse && projectListResponse.source == 'ENG' ?
                                    <tbody>
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Mobilisation</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumMob}</span>
                                                </td>
                                                <td><strong>أعمال التحضيرات</strong></td>
                                                <td><strong>1.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Mobilisation</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumMob}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال التحضيرات</strong></td>
                                                <td><strong>1.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 11) ? boqResponse.filter(object => object.workId == 11).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Mob.,Site Office & Services', 'تجهيز الموقع/ مكتب /توصيل الخدمات', value)
                                        )) : this.tableColumns(11, 11, 'Mob.,Site Office & Services', 'تجهيز الموقع/ مكتب /توصيل الخدمات', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Excavation & Back Filling</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumExca}</span>
                                                </td>
                                                <td><strong>أعمال الحفر والدفان</strong></td>
                                                <td><strong>2.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Excavation & Back Filling</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumExca}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال الحفر والدفان</strong></td>
                                                <td><strong>2.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 21) ? boqResponse.filter(object => object.workId == 21).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Excavation', 'الحفر', value)
                                        )) : this.tableColumns(21, 21, 'Excavation', 'الحفر', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 22) ? boqResponse.filter(object => object.workId == 22).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Back Filling', 'الدفان', value)
                                        )) : this.tableColumns(22, 22, 'Back Filling', 'الدفان', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Sub Structure</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumSubStruture}</span>
                                                </td>
                                                <td><strong>أعمال الأساسات</strong></td>
                                                <td><strong>3.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Sub Structure</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumSubStruture}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال الأساسات</strong></td>
                                                <td><strong>3.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 31) ? boqResponse.filter(object => object.workId == 31).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa', 'الفيلا', value)
                                        )) : this.tableColumns(31, 31, 'Villa', 'الفيلا', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 32) ? boqResponse.filter(object => object.workId == 32).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa Compound Wall', 'السور', value)
                                        )) : this.tableColumns(32, 32, 'Villa Compound Wall', 'السور', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 33) ? boqResponse.filter(object => object.workId == 33).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa Servant Block', 'الملاحق', value)
                                        )) : this.tableColumns(33, 33, 'Villa Servant Block', 'الملاحق', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Super Structure</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumSuperSubStruture}</span>
                                                </td>
                                                <td><strong>أعمال الخرسانة</strong></td>
                                                <td><strong>4.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Super Structure</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumSuperSubStruture}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال الخرسانة</strong></td>
                                                <td><strong>4.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 41) ? boqResponse.filter(object => object.workId == 41).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa GF Slab', 'أعمدة وسقف الطابق الأرضي', value)
                                        )) : this.tableColumns(41, 41, 'Villa GF Slab', 'أعمدة وسقف الطابق الأرضي', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 42) ? boqResponse.filter(object => object.workId == 42).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa FF Slab', 'أعمدة وسقف الطابق الأول', value)
                                        )) : this.tableColumns(42, 42, 'Villa FF Slab', 'أعمدة وسقف الطابق الأول', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 43) ? boqResponse.filter(object => object.workId == 43).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Compound Wall', 'السور', value)
                                        )) : this.tableColumns(43, 43, 'Compound Wall', 'السور', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 44) ? boqResponse.filter(object => object.workId == 44).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Servant Block', 'أعمدة وسقف الملاحق', value)
                                        )) : this.tableColumns(44, 44, 'Servant Block', 'أعمدة وسقف الملاحق', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Block Works</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumBlockWorks}</span>
                                                </td>
                                                <td><strong>أعمال الطابوق</strong></td>
                                                <td><strong>5.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Block Works</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumBlockWorks}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال الطابوق</strong></td>
                                                <td><strong>5.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 51) ? boqResponse.filter(object => object.workId == 51).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Solid Block (Villa, C.W., S.B.)', 'الطابوق المصمت للفيلا والسور والملاحق', value)
                                        )) : this.tableColumns(51, 51, 'Solid Block (Villa, C.W., S.B.)', 'الطابوق المصمت للفيلا والسور والملاحق', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 52) ? boqResponse.filter(object => object.workId == 52).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa GF Walls', 'الطابق الأرضي للفيل', value)
                                        )) : this.tableColumns(52, 52, 'Villa GF Walls', 'الطابق الأرضي للفيل', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 53) ? boqResponse.filter(object => object.workId == 53).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa FF Walls', 'الطابق الأول للفيل', value)
                                        )) : this.tableColumns(53, 53, 'Villa FF Walls', 'الطابق الأول للفيل', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 54) ? boqResponse.filter(object => object.workId == 54).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Compound Wall', 'السور', value)
                                        )) : this.tableColumns(54, 54, 'Compound Wall', 'السور', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 55) ? boqResponse.filter(object => object.workId == 55).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Servant Block', 'أعمدة وسقف الملاحق', value)
                                        )) : this.tableColumns(55, 55, 'Servant Block', 'أعمدة وسقف الملاحق', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Plaster Works</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumPlasterWorks}</span>
                                                </td>
                                                <td><strong>أعمال البلاستر</strong></td>
                                                <td><strong>6.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Plaster Works</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumPlasterWorks}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال البلاستر</strong></td>
                                                <td><strong>6.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 61) ? boqResponse.filter(object => object.workId == 61).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa Internal Plaster', 'البلاستر الداخلي للفيلا', value)
                                        )) : this.tableColumns(61, 61, 'Villa Internal Plaster', 'البلاستر الداخلي للفيلا', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 62) ? boqResponse.filter(object => object.workId == 62).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Villa External Plaster', 'البلاستر الخارجي للفيلا', value)
                                        )) : this.tableColumns(62, 62, 'Villa External Plaster', 'البلاستر الخارجي للفيلا', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 63) ? boqResponse.filter(object => object.workId == 63).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Compound Wall', 'السور', value)
                                        )) : this.tableColumns(63, 63, 'Compound Wall', 'السور', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 64) ? boqResponse.filter(object => object.workId == 64).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Servant Block', 'أعمدة وسقف الملاحق', value)
                                        )) : this.tableColumns(64, 64, 'Servant Block', 'أعمدة وسقف الملاحق', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Electric Works</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumElectricWorks}</span>
                                                </td>
                                                <td><strong>الأعمال الكهربائية</strong></td>
                                                <td><strong>7.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Electric Works</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumElectricWorks}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>الأعمال الكهربائية</strong></td>
                                                <td><strong>7.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 71) ? boqResponse.filter(object => object.workId == 71).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Conduits', 'المواسير الكهربائية', value)
                                        )) : this.tableColumns(71, 71, 'Conduits', 'المواسير الكهربائية', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 72) ? boqResponse.filter(object => object.workId == 72).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Wiring', 'الأسلاك والكابلات', value)
                                        )) : this.tableColumns(72, 72, 'Wiring', 'الأسلاك والكابلات', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 73) ? boqResponse.filter(object => object.workId == 73).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Accessories', 'لوحات الكهرباء والمفاتيح و...الخ', value)
                                        )) : this.tableColumns(73, 73, 'Accessories', 'لوحات الكهرباء والمفاتيح و...الخ', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 74) ? boqResponse.filter(object => object.workId == 74).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Light Fittings & Fans', 'المعلقات الكهربائية والمراوح', value)
                                        )) : this.tableColumns(74, 73, 'Light Fittings & Fans', 'المعلقات الكهربائية والمراوح', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Plumbing Works (int.)</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumPlumbingWorks_int}</span>
                                                </td>
                                                <td><strong>أعمال التمديدات الصحية الداخلية</strong></td>
                                                <td><strong>8.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Plumbing Works (int.)</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumPlumbingWorks_int}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال التمديدات الصحية الداخلية</strong></td>
                                                <td><strong>8.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 81) ? boqResponse.filter(object => object.workId == 81).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Water Supply Pipes', 'تمديدات تغذية المياه الداخلية', value)
                                        )) : this.tableColumns(81, 81, 'Water Supply Pipes', 'تمديدات تغذية المياه الداخلية', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 82) ? boqResponse.filter(object => object.workId == 82).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Drainage Pipes', 'تمديدات الصرف الصحي', value)
                                        )) : this.tableColumns(82, 82, 'Drainage Pipes', 'تمديدات الصرف الصحي', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 83) ? boqResponse.filter(object => object.workId == 83).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Sanitary Wares & Water Heaters', 'الأطقم الصحية وسخانات المياه', value)
                                        )) : this.tableColumns(83, 83, 'Sanitary Wares & Water Heaters', 'الأطقم الصحية وسخانات المياه', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Plumbing Works (ext.)</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumPlumbingWorks_ext}</span>
                                                </td>
                                                <td><strong>أعمال التمديدات الصحية الخارجية</strong></td>
                                                <td><strong>9.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Plumbing Works (ext.)</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumPlumbingWorks_ext}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال التمديدات الصحية الخارجية</strong></td>
                                                <td><strong>9.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 91) ? boqResponse.filter(object => object.workId == 91).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Drainage Pipes & Manholes', 'أعمال الصرف الخارجي وغرف التفتيش', value)
                                        )) : this.tableColumns(91, 91, 'Drainage Pipes & Manholes', 'أعمال الصرف الخارجي وغرف التفتيش', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 92) ? boqResponse.filter(object => object.workId == 92).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Water Tanks & Pumps', 'خزانات المياه والمضخات والتمديدات', value)
                                        )) : this.tableColumns(92, 92, 'Water Tanks & Pumps', 'خزانات المياه والمضخات والتمديدات', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 93) ? boqResponse.filter(object => object.workId == 93).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Septic Tank & Soak Away', 'المرشح وخزان التحليل', value)
                                        )) : this.tableColumns(93, 93, 'Septic Tank & Soak Away', 'المرشح وخزان التحليل', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Internal Finishes</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumInternalFinish}</span>
                                                </td>
                                                <td><strong>أعمال التشطيبات الداخلية</strong></td>
                                                <td><strong>10.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Internal Finishes</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumInternalFinish}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال التشطيبات الداخلية</strong></td>
                                                <td><strong>10.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 101) ? boqResponse.filter(object => object.workId == 101).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Floor Tiles', 'الأرضيات (جميع أنواعها)', value)
                                        )) : this.tableColumns(101, 101, 'Floor Tiles', 'الأرضيات (جميع أنواعها)', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 102) ? boqResponse.filter(object => object.workId == 102).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Wall Tiles', 'جدران الحمامات والمطابخ', value)
                                        )) : this.tableColumns(102, 102, 'Wall Tiles', 'جدران الحمامات والمطابخ', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 103) ? boqResponse.filter(object => object.workId == 103).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Paints', 'الدهانات الداخلية', value)
                                        )) : this.tableColumns(103, 103, 'Paints', 'الدهانات الداخلية', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 104) ? boqResponse.filter(object => object.workId == 104).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Marble', 'الرخام الخارجي', value)
                                        )) : this.tableColumns(104, 104, 'Marble', 'الرخام الخارجي', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 105) ? boqResponse.filter(object => object.workId == 105).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'False Ceiling (wet area)', '(الأسقف المستعارة (الحمامات + المطابخ', value)
                                        )) : this.tableColumns(105, 105, 'False Ceiling (wet area)', 'الأسقف المستعارة (الحمامات + المطابخ)', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>External Finishes</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumExternalFinish}</span>
                                                </td>
                                                <td><strong>أعمال التشطيبات الخارجية</strong></td>
                                                <td><strong>11.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>External Finishes</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumExternalFinish}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال التشطيبات الخارجية</strong></td>
                                                <td><strong>11.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 111) ? boqResponse.filter(object => object.workId == 111).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Paints', 'الدهانات الداخلية', value)
                                        )) : this.tableColumns(111, 111, 'Paints', 'الدهانات الداخلية', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 112) ? boqResponse.filter(object => object.workId == 112).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Compound Wall Paints', 'الدهانات الخارجية للسور', value)
                                        )) : this.tableColumns(112, 112, 'Compound Wall Paints', 'الدهانات الخارجية للسور', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 113) ? boqResponse.filter(object => object.workId == 113).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'interlock', 'الأنترلوك', value)
                                        )) : this.tableColumns(113, 113, 'interlock', 'الأنترلوك', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 114) ? boqResponse.filter(object => object.workId == 114).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Marble', 'الرخام الخارجي', value)
                                        )) : this.tableColumns(114, 114, 'Marble', 'الرخام الخارجي', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Water Proofing</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumWaterProofing}</span>
                                                </td>
                                                <td><strong>أعمال الطبقات العازلة</strong></td>
                                                <td><strong>12.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Water Proofing</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumWaterProofing}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال الطبقات العازلة</strong></td>
                                                <td><strong>12.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 121) ? boqResponse.filter(object => object.workId == 121).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Footings (Villa, C.W., S.B.)', 'عزل أساسات الفيلا والسور والملاحق', value)
                                        )) : this.tableColumns(121, 121, 'Footings (Villa, C.W., S.B.)', 'عزل أساسات الفيلا والسور والملاحق', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 122) ? boqResponse.filter(object => object.workId == 122).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Roof', 'الطبقات العازلة للفيلا والملاحق', value)
                                        )) : this.tableColumns(122, 122, 'Roof', 'الطبقات العازلة للفيلا والملاحق', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 123) ? boqResponse.filter(object => object.workId == 123).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Bathrooms', 'عزل الحمامات', value)
                                        )) : this.tableColumns(123, 123, 'Bathrooms', 'عزل الحمامات', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 124) ? boqResponse.filter(object => object.workId == 124).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Marble', 'الرخام الخارجي', value)
                                        )) : this.tableColumns(124, 124, 'Marble', 'الرخام الخارجي', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Aluminium Works</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumAluminiumWork}</span>
                                                </td>
                                                <td><strong>أعمال الالمنيوم</strong></td>
                                                <td><strong>13.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Aluminium Works</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumAluminiumWork}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال الالمنيوم</strong></td>
                                                <td><strong>13.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 131) ? boqResponse.filter(object => object.workId == 131).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Doors', 'الأبواب', value)
                                        )) : this.tableColumns(131, 131, 'Doors', 'الأبواب', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 132) ? boqResponse.filter(object => object.workId == 132).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Windows', 'الشبابيك', value)
                                        )) : this.tableColumns(132, 132, 'Windows', 'الشبابيك', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 133) ? boqResponse.filter(object => object.workId == 133).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Handrail', 'الدرابزين', value)
                                        )) : this.tableColumns(133, 133, 'Handrail', 'الدرابزين', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Gates & Metal Works</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumGatesAndMetalWorks}</span>
                                                </td>
                                                <td><strong>البوابات والأعمال المعدنية</strong></td>
                                                <td><strong>14.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Gates & Metal Works</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumGatesAndMetalWorks}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>البوابات والأعمال المعدنية</strong></td>
                                                <td><strong>14.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 141) ? boqResponse.filter(object => object.workId == 141).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Gates', 'بوابات السور', value)
                                        )) : this.tableColumns(141, 141, 'Gates', 'بوابات السور', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 142) ? boqResponse.filter(object => object.workId == 142).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Spiral Stair', 'السلم الحلزوني', value)
                                        )) : this.tableColumns(142, 142, 'Spiral Stair', 'السلم الحلزوني', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Joinery Works</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumJoineryWorks}</span>
                                                </td>
                                                <td><strong>الأعمال الخشبية</strong></td>
                                                <td><strong>15.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Joinery Works</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumJoineryWorks}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>الأعمال الخشبية</strong></td>
                                                <td><strong>15.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 151) ? boqResponse.filter(object => object.workId == 151).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Doors', 'الأبواب', value)
                                        )) : this.tableColumns(151, 151, 'Doors', 'الأبواب', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 152) ? boqResponse.filter(object => object.workId == 152).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Kitchen Cabinets & Wardrobes', 'خزائن المطبخ وخزائن الملابس', value)
                                        )) : this.tableColumns(152, 152, 'Kitchen Cabinets & Wardrobes', 'خزائن المطبخ وخزائن الملابس', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Air Conditioning Works</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumAirCondWorks}</span>
                                                </td>
                                                <td><strong>أعمال التكييف</strong></td>
                                                <td><strong>16.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Air Conditioning Works</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumAirCondWorks}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال التكييف</strong></td>
                                                <td><strong>16.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 161) ? boqResponse.filter(object => object.workId == 161).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Machines/Unit', 'ماكينات التكييف', value)
                                        )) : this.tableColumns(161, 161, 'Machines/Unit', 'ماكينات التكييف', '')}
                                        {boqResponse && boqResponse.some(object => object.workId == 162) ? boqResponse.filter(object => object.workId == 162).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Ducts/Diffusers', 'دكتات التكييف ومخارج الهواء', value)
                                        )) : this.tableColumns(162, 162, 'Ducts/Diffusers', 'دكتات التكييف ومخارج الهواء', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Cleaning</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumCleaning}</span>
                                                </td>
                                                <td><strong>تنظيف</strong></td>
                                                <td><strong>17.0</strong></td>
                                            </tr> : <tr className="subTitleBoq">
                                                <td><strong>Cleaning</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong> <span>{sumCleaning}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>تنظيف</strong></td>
                                                <td><strong>17.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 171) ? boqResponse.filter(object => object.workId == 171).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Cleaning, Leveling & Handing Over', 'أعمال النظافة والتسليم الابتدائي', value)
                                        )) : this.tableColumns(171, 171, 'Cleaning, Leveling & Handing Over', 'أعمال النظافة والتسليم الابتدائي', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTotalBoq">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>{translate('totalValue')}</strong>: {totalSumOfValue}%</td>
                                                <td></td>
                                                <td></td>
                                            </tr> :
                                            <tr className="subTotalBoq">
                                                <td></td>
                                                <td><strong>{translate('totalValue')}</strong>: {totalSumOfValue}%</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}
                                    </tbody>
                                    : null}
                                {!this.state.ownerScope && projectListResponse && projectListResponse.source == 'TQL' ?
                                    <tbody>
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTitleBoq">
                                                <td><strong>Plumbing Works (ext.)</strong></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <strong> {translate('total')} </strong>: <span>{sumGrantBOQ}</span>
                                                </td>
                                                <td><strong>أعمال التمديدات الصحية الخارجية</strong></td>
                                                <td><strong>1.0</strong></td>
                                            </tr> :
                                            <tr className="subTitleBoq">
                                                <td><strong>Plumbing Works (ext.)</strong></td>
                                                <td>
                                                    <strong> {translate('total')} </strong>: <span>{sumGrantBOQ}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>أعمال التمديدات الصحية الخارجية</strong></td>
                                                <td><strong>1.0</strong></td>
                                            </tr>}
                                        {boqResponse && boqResponse.some(object => object.workId == 300) ? boqResponse.filter(object => object.workId == 300).map((value, index) => (
                                            this.tableColumns(index, value.workId, 'Grant BOQ', 'جدول كميات المنح', value)
                                        )) : this.tableColumns(300, 300, 'Grant BOQ', ';جدول كميات المنح', '')}
                                        {this.state.statusId == ContractorProjectEnum.ContractorProjectPayments ?
                                            <tr className="subTotalBoq">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><strong>{translate('totalValue')}</strong>: {totalSumOfValue}%</td>
                                                <td></td>
                                                <td></td>
                                            </tr> :
                                            <tr className="subTotalBoq">
                                                <td></td>
                                                <td><strong>{translate('totalValue')}</strong>: {totalSumOfValue}%</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}
                                    </tbody> : null}

                                {/* owner scope */}
                             {ownerScopeResponse ? <tbody className="ownerScopeTableBody">
                                    {/* {ownerScopeResponse ? ownerScopeResponse.map((value, index) => (
                                            this.tableColumnsOwnerScope(index, value)
                                        )) : null} */}
                                        
                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 501) ? ownerScopeResponse.filter(object => object.workId == 501).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Air Conditioning Works', 'أعمال التكييف', value)
                                    )) : this.tableColumnsOwnerScope(501, 501, 'Air Conditioning Works', 'أعمال التكييف', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 502) ? ownerScopeResponse.filter(object => object.workId == 502).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Light Fitiing', 'تجهيزات الإضاءة', value)
                                    )) : this.tableColumnsOwnerScope(502, 502, 'Light Fitiing', 'تجهيزات الإضاءة', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 503) ? ownerScopeResponse.filter(object => object.workId == 503).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Kitchen Cabinets', '', value)
                                    )) : this.tableColumnsOwnerScope(503, 503, 'Kitchen Cabinets', 'خزائن المطبخ', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 504) ? ownerScopeResponse.filter(object => object.workId == 504).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Wardrobes', 'خزائن', value)
                                    )) : this.tableColumnsOwnerScope(504, 504, 'Wardrobes', 'خزائن', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 505) ? ownerScopeResponse.filter(object => object.workId == 505).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Internal Wooden Doors ( Leaves )', 'أبواب خشبية داخلية (أوراق)', value)
                                    )) : this.tableColumnsOwnerScope(505, 505, 'Internal Wooden Doors ( Leaves )', 'أبواب خشبية داخلية (أوراق)', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 506) ? ownerScopeResponse.filter(object => object.workId == 506).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Antenna', 'هوائي', value)
                                    )) : this.tableColumnsOwnerScope(506, 506, 'Antenna', 'هوائي', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 507) ? ownerScopeResponse.filter(object => object.workId == 507).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Intercom System', 'نظام الاتصال الداخلي', value)
                                    )) : this.tableColumnsOwnerScope(507, 507, 'Intercom System', 'نظام الاتصال الداخلي', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 508) ? ownerScopeResponse.filter(object => object.workId == 508).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'False Celling', 'سقف معلق', value)
                                    )) : this.tableColumnsOwnerScope(508, 508, 'False Celling', 'سقف معلق', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 509) ? ownerScopeResponse.filter(object => object.workId == 509).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Car Shed', 'سقيفة السيارات', value)
                                    )) : this.tableColumnsOwnerScope(509, 509, 'Car Shed', 'سقيفة السيارات', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 510) ? ownerScopeResponse.filter(object => object.workId == 510).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Interlock', 'الأنترلوك', value)
                                    )) : this.tableColumnsOwnerScope(510, 510, 'Interlock', 'الأنترلوك', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 511) ? ownerScopeResponse.filter(object => object.workId == 511).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Sweet Soil', 'التربة القلوية', value)
                                    )) : this.tableColumnsOwnerScope(511, 511, 'Sweet Soil', 'التربة القلوية', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 512) ? ownerScopeResponse.filter(object => object.workId == 512).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Leveling', 'التسوية', value)
                                    )) : this.tableColumnsOwnerScope(512, 512, 'Leveling', 'التسوية', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 513) ? ownerScopeResponse.filter(object => object.workId == 513).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, 'Water & Electricity Connection', 'توصيلات المياه والكهرباء', value)
                                    )) : this.tableColumnsOwnerScope(513, 513, 'Water & Electricity Connection', 'توصيلات المياه والكهرباء', '')}

                                    {/* static rows without descriptions */}
                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 514) ? ownerScopeResponse.filter(object => object.workId == 514).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, value.workDesc, '', value)
                                    )) : this.tableColumnsOwnerScope(514, 514, '', '', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 515) ? ownerScopeResponse.filter(object => object.workId == 515).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, value.workDesc, '', value)
                                    )) : this.tableColumnsOwnerScope(515, 515, '', '', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 516) ? ownerScopeResponse.filter(object => object.workId == 516).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, value.workDesc, '', value)
                                    )) : this.tableColumnsOwnerScope(516, 516, '', '', '')}

                                    {ownerScopeResponse && ownerScopeResponse.some(object => object.workId == 517) ? ownerScopeResponse.filter(object => object.workId == 517).map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, value.workDesc, '', value)
                                    )) : this.tableColumnsOwnerScope(517, 517, '', '', '')}

                                    {/* dynamic rows */}
                                    {results.length > 0 ? results.map((value, index) => (
                                        this.tableColumnsOwnerScope(index, value.workId, value.workDesc, value.workDesc, value)
                                    )) : null}
                                    <tr className="subTotalBoq">
                                        <td></td>
                                        <td><strong>{translate('totalValue')}</strong>: <NumberFormat value={totalEstimatedValue} displayType={'text'} thousandSeparator={true} /> {translate('dhs')}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" style={{border:"0px"}}>
                                            <p style={{textAlign:"right", marginBottom: "0px"}}>ملاحظة: يجبانتكونالبنودالمذكورةأعلاهخارجعقدالمقاولومتوافقةمعقائمةشهادةالانجازالخاصةببلديةدبي</p>
                                            <p style={{textAlign:"left", marginBottom: "0px"}}>Note: All above items excluded from the contractor's scope of work have to be incompliance with Dubai Municipality's Completion Certificate exclusion list.</p>
                                        </td>
                                    </tr>
                                </tbody> : null}
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div className="page-footer-space"></div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        {/* end body */}
                        <div className="tableFooter">
                            <table width="100%" style={{ textAlign: 'center' }}>
                                <tbody>
                                    <tr>
                                        <td width="33.33%"><strong>{translate('ownerSign')}</strong></td>
                                        <td width="33.33%"></td>
                                        <td width="33.33%"><strong>{translate('consultantSign')}</strong></td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td width="33.33%" style={{ paddingTop: '20px' }}><hr /></td>
                                        <td width="33.33%"></td>
                                        <td width="33.33%" style={{ paddingTop: '20px' }}><hr /></td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td width="33.33%"><strong>{translate('contractorSign')}</strong></td>
                                        <td width="33.33%"></td>
                                        <td width="33.33%"></td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td width="33.33%" style={{ paddingTop: '20px' }}><hr /></td>
                                        <td width="33.33%"></td>
                                        <td width="33.33%" style={{ textAlign: 'right' }}>MRHE/QP/155/QF/03</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td colSpan="3" width="100%" id="date">{new Date().toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    tableColumns(index, workId, name_eng, name_arabic, value) {
        if (this.state.statusId == ContractorProjectEnum.OpenNewProjectFile) {
            return (
                <tr key={index}>
                    <td>{name_eng}</td>
                    {value ? <td>{value.contractPercentage}</td> : <td>0</td>}
                    {value ? <td>{value.note}</td> : <td></td>}
                    {value ? <td>{value.engRemark}</td> : <td></td>}
                    <td>{name_arabic}</td>
                    <td>{workId}</td>
                </tr>
            );
        } else if (this.state.statusId == ContractorProjectEnum.ContractorProjectPayments) {
            return (
                <tr key={index}>
                    <td>{name_eng}</td>
                    {value ? <td>{value.contractPercentage}</td> : <td>0</td>}
                    {value ? <td>{value.actualDone}</td> : <td>0</td>}
                    {value ? <td>{value.note}</td> : <td></td>}
                    {value ? <td>{value.paymentPercentage}</td> : <td>0</td>}
                    <td>{name_arabic}</td>
                    <td>{workId}</td>
                </tr>
            );
        }
    }

    tableColumnsOwnerScope(index, work_id, name_eng, name_arabic, value) {
        return (
            <tr key={index}>
                <td>{name_eng}</td>
                {value ? <td><NumberFormat value={value.ownerWorkAmount} displayType={'text'} thousandSeparator={true} /></td> : <td>0</td>}
                {value ? <td>{value.remark}</td> : <td></td>}
                <td>{name_arabic}</td>
                <td>{work_id}</td>
            </tr>
        );
    }
}



export default PrintOpenNewProjectFile;