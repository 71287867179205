import React from "react"
import '../../reactToastify.css'
import Loader from '../utils/LoaderBars'
import { toast } from 'react-toastify'
import CommonUtils from '../../utils/CommonUtils'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'

class StepTwo extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            fileName: '',
            response: '',
            documents: [],
            viewfileURL: '',
            fileFrame: null,
            modifyPopup: null,
            showLoading: false,
            addFilePopup: null,
            othersDocuments: [],
            submitSuccess: false,
            showFileFrame: false,
            contractDocuments: [],
            showBackground: false,
            applicantDocuments: [],
            showModifyPopup: false,
            showUploadPopup: false,
            contractorDocuments: [],
            consultantDocuments: [],
            ticketNo: localStorage.getItem("dTicketNo"),
            ticketPayload: localStorage.ticketPayload ? JSON.parse(localStorage.getItem("ticketPayload")) : ""
        }
        this.next = this.next.bind(this)
        this.goBack = this.goBack.bind(this)
        this.getFile = this.getFile.bind(this)
        this.addClick = this.addClick.bind(this)
        this.setResults = this.setResults.bind(this)
        this.modifyClick = this.modifyClick.bind(this)
        this.deleteClick = this.deleteClick.bind(this)
        this.getDocuments = this.getDocuments.bind(this)
        this.setDocuments = this.setDocuments.bind(this)
        this.viewFileClick = this.viewFileClick.bind(this)
        this.deleteDocument = this.deleteDocument.bind(this)
        this.viewImageSuccess = this.viewImageSuccess.bind(this)
        this.validateDocuments = this.validateDocuments.bind(this)
        this.closeShowFilePopup = this.closeShowFilePopup.bind(this)
        this.fetchRequiredDocuments = this.fetchRequiredDocuments.bind(this)
    }

    componentDidMount() {
        this.fetchRequiredDocuments()
    }

    fetchRequiredDocuments() {
        this.setState({ showLoading: true })
        fetch(CONSTANTS.getDisbursementRequiredDocumentsEndpoint + "/" + localStorage.getItem("dTicketNo") + "?local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            },
        }).then(response => response.json()).then(this.setResults).catch(this.loadFailed);
    }

    setResults(response) {
        if (response.success) {
            var documents = []
            this.setState({ response: response.payload, showLoading: false }, () => {
                documents = response.payload.map((object, index) => {
                    var documentsArray = []
                    documentsArray.push(<FileComponent ticketPayload={this.state.ticketPayload} index={index} key={index} payload={object} addClick={this.addClick} modifyClick={this.modifyClick} />)
                    return documentsArray
                })
                this.setState({ documents: documents })
            })
        }
        else {
            this.props.reference.showError(response.message)
        }
    }

    loadFailed(response) {
        this.setState({ showLoading: false }, () => {
            this.props.reference.showError(response.message)
            this.setState({ response: {}, documents: [] })
        })
    }

    getFile(e) {
        let file = e.currentTarget.files[0];
        if (CommonUtils.isValidFileType(file) && CommonUtils.isValidFileSize(file)) {
            this.uploadDocument(e.currentTarget.id, file)
        } else {
            toast.error(translate('fileSize'))
        }
        e.currentTarget.value = null
    }

    uploadDocument(typeId, file) {
        this.setState({ showLoading: true }, () => {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('type', typeId)
            formData.append('local', this.context.state.siteLang)
            fetch(CONSTANTS.uploadDisbursementDocumentEndpoint + "/" + localStorage.getItem("dTicketNo") + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': CONSTANTS.authToken
                },
            }).then(response => response.json()).then((response) => {
                if (response.success) {
                    this.addClick()
                    toast.success(response.message)
                    this.fetchRequiredDocuments()
                } else {
                    this.setState({ showLoading: false }, () => {
                        this.addClick()
                        toast.error(response.message)
                    })
                }
            }).catch(this.loadFailed);
        })
    }

    addClick(e) {
        this.props.reference.reset()
        if (e) {
            this.setState({ fileName: e.currentTarget.name, fileTypeId: e.currentTarget.id })
            e.persist()
        }
        this.setState({ showBackground: !this.state.showBackground, showUploadPopup: !this.state.showUploadPopup }, () => {
            this.setState({
                addFile:
                    this.state.showUploadPopup ?
                        <div>
                            <div className="uploadPopup">
                                <button className="popupClose" onClick={this.addClick}>X</button>
                                <h2>{this.state.fileName}</h2>
                                <input type="file" id={this.state.fileTypeId} onChange={this.getFile} ></input>
                                <label htmlFor={this.state.fileTypeId}>{translate('dragAndDrop')}</label>
                                <p>{translate('fileSize')}</p>
                            </div>
                        </div> : null
            })
        })
    }

    modifyClick(e) {
        if (e) {
            this.setState({ fileName: e.currentTarget.name, fileTypeId: e.currentTarget.id })
            e.persist()
        }
        this.setState({ showBackground: !this.state.showBackground, showModifyPopup: !this.state.showModifyPopup }, () => {
            this.setState({
                modifyPopup:
                    this.state.showModifyPopup && this.state.response ?
                        <div>
                            <div className="viewPopup">
                                <button id={this.state.fileTypeId} className="popupClose" onClick={this.modifyClick}>X</button>
                                <h2>{this.state.fileName}</h2>
                                {this.state.response[this.state.fileTypeId].attachments.map((attachment, index) => {
                                    return (<div key={index} className="row viewPopupContant">
                                        <div className="col-md-7">{attachment.name}</div>
                                        <div className="col-md-5 project_step_upload_btns">
                                            <button id={attachment.id} name={attachment.name} onClick={this.viewFileClick}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/icon_eye.svg'} alt="view" /> {translate('view')} </button>
                                            {this.state.ticketPayload.requestStatusId !== 10 ? <button id={attachment.id} onClick={this.deleteClick}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete.svg'} alt="delete" /> {translate('delete')} </button> : null}
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                        </div> : null
            })
        })
    }

    viewFileClick(e) {
        if (e) {
            this.setState({ fileName: e.currentTarget.name, fileTypeId: e.currentTarget.id })
            e.persist()
        }
        if (!this.state.showFileFrame && this.state.fileTypeId.length > 0) {
            fetch(CONSTANTS.viewDisbursementDocumentEndpoint + "/" + localStorage.getItem("dTicketNo") + "?id=" + e.currentTarget.id + "&local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                },
            }).then(response => response.json()).then(this.viewImageSuccess).catch(this.viewImageError);
        } else {
            this.setState({ showFileFrame: !this.state.showFileFrame })
        }
    }

    viewImageSuccess(response) {
        if (response.success) {
            this.setState({ showFileFrame: !this.state.showFileFrame }, () => {

                this.setState({
                    fileFrame:
                        this.state.showFileFrame ? <div className="uploadPopup">
                            <button className="popupClose" onClick={this.closeShowFilePopup}>X</button>
                            <div className="popupShowFile">
                                {CommonUtils.getFileExtension(response.payload).toUpperCase() === "PNG"
                                    || CommonUtils.getFileExtension(response.payload).toUpperCase() === "JPG"
                                    || CommonUtils.getFileExtension(response.payload).toUpperCase() === "JPEG"
                                    ? <img src={response.payload} alt="View doc" />
                                    : <iframe src={response.payload} alt="" title="View doc" />}
                            </div>
                        </div> : null
                })
            })
        } else {
            toast.error(response.message)
        }
    }

    goBack() {
        localStorage.setItem('startAtStep', 0)
        this.props.jumpToStep(0)
    }

    closeShowFilePopup() {
        this.setState({ showFileFrame: false, fileFrame: null })
    }

    deleteClick(e) {
        this.deleteDocument(e)
    }

    deleteDocument(e) {
        e.persist()
        this.setState({ documentId: e.currentTarget.id, fileName: e.currentTarget.name, fileTypeId: e.currentTarget.id }, () => {
            this.setState({ showLoading: true }, () => {
                fetch(CONSTANTS.deleteDisbursementDocumentEndpoint + "/" + localStorage.getItem("dTicketNo") + "?attachmentId=" + this.state.documentId + "&local=" + this.context.state.siteLang, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': CONSTANTS.authToken
                    },
                }).then(response => response.json()).then((response) => {
                    this.modifyClick()
                    if (response.success) {
                        this.fetchRequiredDocuments()
                        toast.success(translate('docdeletesuccess'))
                    } else {
                        this.setState({ showLoading: false }, () => {
                            toast.error(response.message)
                        })
                    }
                }).catch(this.loadFailed);

            })
        })
    }

    validateDocuments() {
        var isValid = true
        this.state.response.map((document) => {
            if (!document.attachments.length > 0) {
                isValid = false
            }
        })
        return isValid
    }

    getDocuments() {
        setTimeout(this.props.reference.startLoading(), 300)
        fetch(CONSTANTS.getDocumentsToSignListEndpoint + "/" + this.state.ticketNo + "?local=" + this.context.state.siteLang  + "&ticketStatusId=" + this.props.reference.state.ticketPayload.requestStatusId, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.setDocuments).catch();
    }

    setDocuments(response) {
        // setTimeout(() => {
        this.props.reference.stopLoading()
        this.props.reference.reset()
        if (response.success) {
            localStorage.setItem('startAtStep', 2)
            this.props.jumpToStep(2)
        } else {
            this.props.reference.showError(response.message)
        }
        // }, 8000)
    }

    next() {
        this.getDocuments()
    }

    render() {
        return (
            <div className="container-fluid">
                {/* POPUP ADD FILE */}
                {this.state.showBackground ? <div className="PopupBk"></div> : null}
                {this.state.addFile ? this.state.addFile : null}
                {/* POPUP VIEW FILE */}
                {this.state.viewFile ? <div className="PopupBk"></div> : null}
                {this.state.viewFile ? <div className="viewPopup">
                    <button className="popupClose" onClick={this.viewFilePopClick}>X</button>
                    <div className="popupShowFile">
                        <img src="https://api.qa.mrhe.gov.ae/mrhecloud/v1.4/api/dumps//service-requests/024d371f-b161-4f2d-89c6-e2a7806b400f/15301210/flower.jpg" alt="View doc" />
                    </div>
                </div> : null}
                {this.state.modifyPopup}
                {this.state.fileFrame}
                {!this.state.submitSuccess ?
                    <div className="constructionLoanDisbursementStep2Wrapper" style={{ display: '' }}>
                        <div className="row constructionUploadFileSectionWrapper">
                            <div className="col-12">
                                <h3>{translate('Documents')}</h3>
                            </div>
                            <hr></hr>
                            {this.state.documents && this.state.documents}
                        </div>
                        <br></br>
                        <div className="row">
                            <div className="col-md-12 constructionLoanDisbursementStepBtn">
                                <button className="stepBacktBtn" onClick={this.goBack}>{translate('back')}</button>
                                <button className="stepBacktBtn" onClick={this.next}>{translate('next')}</button>
                            </div>
                        </div>
                    </div>
                    : <div className="constructionLoanDisbursementStepSuccussWrapper">
                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/submittedSuccessfully.svg'} alt="Submitted Successfully" />
                        <p>{this.state.successMessage}</p>
                        <button><a href="/customerDashboard">{translate('backDashboard')}</a></button>
                    </div>}
                {this.state.showLoading ? <Loader /> : null}
            </div>
        )
    }
}

function FileComponent(props) {
    return (
        <div className="col-12">
            <div className="constructionUploadFileSection">
                <div className="row">
                    <div className="col-lg-5 col-md-8">
                        <div className="iconDiv">
                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/pdf_ico.svg" alt="pdf" /></i>
                        </div>
                        <div className="textDiv">
                            <span>{props.payload.name}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <span>{translate('numberOfFiles')}: {props.payload.attachments.length}</span>
                    </div>
                    <div className="col-lg-4 col-md-12 constructionUploadFileSectionBtns">
                        {props.payload.attachments.length > 0 ? <button className="viewBtn" name={props.payload.name} id={props.index} onClick={props.modifyClick}>
                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/eye_icon_white.svg" alt="pdf" /></i>
                            <span>{translate('view')}</span>
                        </button> : null}
                        {props.ticketPayload.requestStatusId !== 10 ? <button className="addBtn" name={props.payload.name} id={props.payload.id} onClick={props.addClick}>
                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/add_c.svg" alt="pdf" /></i>
                            <span>{translate('addFile')}</span>
                        </button> : null}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepTwo