import React from "react";
import { Redirect } from 'react-router-dom';


class BaseRedirect extends React.Component {
    render(){
      return (
        <div>
         {this.props.userLoggedIn ? <Redirect to= {this.props.redirect} /> : null}
        </div>
      )
    }
  }

  export default BaseRedirect;
