import React from "react";

class ContractorContentLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div className="container-fluid Loadingcontent mainSection">
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="LoadingWidgetWrapper">
                                    <div className="loadingHead">
                                        <span className="animate_bg"></span>
                                        <p className="animate_bg"></p>
                                    </div>
                                    <div className="loadingServiceWrapper">
                                        <span className="animate_bg"></span>
                                        <span className="animate_bg"></span>
                                        <span className="animate_bg"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="LoadingWidgetWrapper">
                                    <div className="loadingHead">
                                        <span className="animate_bg"></span>
                                        <p className="animate_bg"></p>
                                    </div>
                                    <div className="loadingGraph1Wrapper">
                                        <span className="animate_bg"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="LoadingWidgetWrapper">
                                    <div className="loadingHead">
                                        <span className="animate_bg"></span>
                                        <p className="animate_bg"></p>
                                    </div>
                                    <div className="loadingWidget">
                                        <p className="animate_bg"></p>
                                        <p className="animate_bg"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="LoadingWidgetWrapper">
                                    <div className="loadingHead">
                                        <span className="animate_bg"></span>
                                        <p className="animate_bg"></p>
                                    </div>
                                    <div className="loadingWidget">
                                        <p className="animate_bg"></p>
                                        <p className="animate_bg"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="LoadingWidgetWrapper">
                                    <div className="loadingHead">
                                        <span className="animate_bg"></span>
                                        <p className="animate_bg"></p>
                                    </div>
                                    <div className="loadingWidget">
                                        <p className="animate_bg"></p>
                                        <p className="animate_bg"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="LoadingWidgetWrapper">
                                    <div className="loadingHead">
                                        <span className="animate_bg"></span>
                                        <p className="animate_bg"></p>
                                    </div>
                                    <div className="loadingWidget">
                                        <p className="animate_bg"></p>
                                        <p className="animate_bg"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6">
                                <div className="LoadingWidgetWrapper">
                                    <div className="loadingHead">
                                        <span className="animate_bg"></span>
                                        <p className="animate_bg"></p>
                                    </div>
                                    <div className="loadingWidget">
                                        <p className="animate_bg"></p>
                                        <p className="animate_bg"></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="LoadingWidgetWrapper">
                                    <div className="loadingHead">
                                        <span className="animate_bg"></span>
                                        <p className="animate_bg"></p>
                                    </div>
                                    <div className="loadingWidget">
                                        <p className="animate_bg"></p>
                                        <p className="animate_bg"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default ContractorContentLoader;