import CONSTANTS from '../../constants/dashboard-constants'
import HousingRequestsActions from "./housing-requests-actions";

var locale = '?local=en'
class HousingRequestsService {
    fetchHousingRequest(pageSize, local) {
        locale = local
        // var url = CONSTANTS.housingrequestsEndpoint + local + '&page=0&pageSize=' + pageSize
        var url = CONSTANTS.ticketsListingEndpoint + local + '&page=0&pageSize=' + pageSize
        var token = localStorage.getItem('accessToken')
        if (token.length > 15) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(HousingRequestsActions.fetchHousingRequest).catch()
        }
    }

    deleteTicket(ticketNo) {
        fetch(CONSTANTS.deleteHousingRequestTicketEndPoint + "/" + ticketNo + locale, {
            method: 'POST',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(HousingRequestsActions.deleteHousingRequest).catch(this.loadFailed);
    }
}

export default new HousingRequestsService()