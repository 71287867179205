import MaintenanceRequestsConstants from './maintenance-requests-constants'
import BaseStore from '../../BaseStore'

class MaintenanceRequestsStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this.registerToActions.bind(this))
        this.maintenanceRequestsResponse = null
        this.deleteMaintenanceRequestsResponse = null
    }

    registerToActions(action) {
        switch (action.actionType) {
            case MaintenanceRequestsConstants.MAINTENANCEREQUESTS:
                this.maintenanceRequestsResponse = action.response
                this.emitChange()
                break
            case MaintenanceRequestsConstants.DELETEMAINTENANCEREQUEST:
                this.deleteMaintenanceRequestsResponse = action.response
                this.emitChange()
                break
            default:
                break
        }
    }

    get response() {
        return this.maintenanceRequestsResponse
    }

    get deleteResponse() {
        return this.deleteMaintenanceRequestsResponse
    }

    clearDeleteResponse() {
        this.deleteMaintenanceRequestsResponse = null
    }
}

export default new MaintenanceRequestsStore()