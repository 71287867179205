// import React from "react";
// import './login.css';
// import { Redirect } from 'react-router-dom';
// import queryString from 'query-string';
// import translate from '../../Providers/i18n/Translate'
// import { AppContext, AppContextProvider } from '../../Providers/context'
// import CommonUtils from "../../utils/CommonUtils";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig, loginRequest } from "./Azure/authConfig";
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
// import { SignInButton } from "./Azure/SignInButton";
// import { SignOutButton } from "./Azure/SignOutButton";
// import { ProfileContent } from "./Azure/ProfileContent";

// class AdminLoginComponent extends React.Component {
//     static contextType = AppContext

//     handleLogin(instance) {
//         instance.loginPopup(loginRequest).catch(e => {
//             console.error(e);
//         });
//     }

//     render() {
//         const msalInstance = new PublicClientApplication(msalConfig);
//         return (
//                 <MsalProvider instance={msalInstance}>
//                     <AuthenticatedTemplate>
//                     <SignOutButton />
//                         <ProfileContent />
//                     </AuthenticatedTemplate>
//                     <UnauthenticatedTemplate>
//                         <SignInButton />
//                     </UnauthenticatedTemplate>
//                 </MsalProvider>
//                 )

//     }
// }
// export default AdminLoginComponent;