import React from 'react'
import NoData from '../../utils/nodata'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import ManageBookingsItem from './managebookings-widget-item'

class ManageBookingsWidget extends React.Component {
    constructor(props) {
        super()
        this.state = {
            oldBooking: null,
            payload: ""
        }
        this.fetchBookingsData = this.fetchBookingsData.bind(this)
        this.myBookingDetails = this.myBookingDetails.bind(this)
        this.setHousingRequestWidget = this.setHousingRequestWidget.bind(this)
    }

    componentDidMount() {
        this.fetchBookingsData(this.props.url)
    }

    fetchBookingsData(url) {
        if (CONSTANTS.authToken.length > 15){
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setHousingRequestWidget).catch(this.loadFailed);
        }
    }

    loadFailed(response) {
        console.log(response)
    }

    setHousingRequestWidget(response) {
        var housingRequestItems = null
        var payload = response.payload
        if (payload) {
            housingRequestItems = <ManageBookingsItem key={0} payload={payload} myBookingDetails={this.myBookingDetails} />
        }
        this.setState({ payload: payload, oldBooking: housingRequestItems })
    }

    myBookingDetails() {
        this.props.history.push({
            pathname: '/myBooking',
            state: { payload: this.state.payload }
        })
    }

    render() {
        return (
            <div className="col-md-6 manageBookingWidgetWrapper">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ManageYourBooking.svg'} alt="Manage Your Booking" /></span>
                        <span>{translate('manage-booking-widget-heading')}</span>
                    </div>
                    <div className="widgetContant">
                        {this.state.oldBooking ? this.state.oldBooking :
                            <div className="noData" style={{ display: "" }}>
                                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/nobooking.svg'} alt="No Data" />
                                <span>{translate('nodata')}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default ManageBookingsWidget