import React from 'react';
import LoginStore from './Login/LoginStore';
import { Redirect } from 'react-router-dom';


export default (ComposedComponent) => {
    return class AuthenticatedComponent extends React.Component {
        constructor() {
            super()
            this.state = this._getLoginState();
        }

        _getLoginState() {
            if (LoginStore.isLoggedIn()) {
                localStorage.setItem('accessToken', LoginStore.jwt);
                if (LoginStore.userTypeResponse && LoginStore.userTypeResponse.hasOwnProperty('user_type')) {
                    localStorage.setItem('userType', LoginStore.userTypeResponse.user_type);
                }
                window.location.reload();
            }
            return {
                userLoggedIn: localStorage.getItem('accessToken')
            }

        }

        componentDidMount() {
            this.changeListener = this._onChange.bind(this);
            LoginStore.addChangeListener(this.changeListener);
        }

        _onChange() {
            this.setState(this._getLoginState());
        }

        componentWillUnmount() {
            LoginStore.removeChangeListener(this.changeListener);
        }

        render() {
            return this.state.userLoggedIn ? <ComposedComponent {...this.props} userLoggedIn={this.state.userLoggedIn} /> : <Redirect to='/eservices' />
        }
    }
};