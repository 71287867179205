import HouseBookingConstants from './housebooking-constants'
import BaseStore from '../../BaseStore'

class HouseBookingStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this.registerToActions.bind(this))
        this.houseBookingFiltersResponse = null
        this.houseBookingVillasGroupsResponse = null
        this.houseBookingMyBookingsResponse = null
        this.houseBookingVillasPayload = null
        this.houseBookingVillaDetailsPayload = null
        this.houseBookingPrintMyBookingPayload = null
        this.houseBookingBookHousePayload = null
        this.houseBookingePayPayload = null
    }


    registerToActions(action) {
        switch (action.actionType) {
            case HouseBookingConstants.HOUSE_BOOKING_FILTERS:
                this.houseBookingFiltersResponse = action.response
                this.emitChange()
                break
            case HouseBookingConstants.VILLAS_GROUPS:
                this.houseBookingVillasGroupsResponse = action.response
                this.emitChange()
                break
            case HouseBookingConstants.VILLAS:
                this.houseBookingVillasPayload = action.response
                this.emitChange()
                break
            case HouseBookingConstants.MY_BOOKINGS:
                this.houseBookingMyBookingsResponse = action.response
                this.emitChange()
                break
            case HouseBookingConstants.VILLA_DETAILS:
                this.houseBookingVillaDetailsPayload = action.response
                this.emitChange()
                break
            case HouseBookingConstants.PRINT_BOOKING:
                this.houseBookingPrintMyBookingPayload = action.response
                this.emitChange()
                break
            case HouseBookingConstants.BOOK_HOUSE:
                this.houseBookingBookHousePayload = action.response
                this.emitChange()
                break
            case HouseBookingConstants.EPAY:
                this.houseBookingePayPayload = action.response
                this.emitChange()
                break
            default:
                break
        }
    }

    get houseBookingFilters() {
        return this.houseBookingFiltersResponse
    }

    get houseBookingVillasGroups() {
        return this.houseBookingVillasGroupsResponse
    }

    get houseBookingVillas() {
        return this.houseBookingVillasPayload
    }

    get houseBookingMyBookings() {
        return this.houseBookingMyBookingsResponse
    }
    
    get houseBookingVillaDetails() {
        return this.houseBookingVillaDetailsPayload
    }
    
    get houseBookingPrintMyBooking() {
        return this.houseBookingPrintMyBookingPayload
    }
}

export default new HouseBookingStore()