import React from "react";
import '../../reactToastify.css';
import { Redirect } from 'react-router-dom';
import { LOCALES } from "../../Providers/i18n";
import CommonUtils from "../../utils/CommonUtils";
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify';
import OpenNewProjectFileStore from './OpenNewProjectFileStore';
import OpenNewProjectFileService from './OpenNewProjectFileService';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';

class BreakDownInformationComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            note: '',
            apiNote: '',
            alert: false,
            showBg: false,
            apiWorkId: '',
            popUpNote: '',
            popUpWorkId: 0,
            BOQresponse: '',
            typingTimeout: 0,
            totalPercent: '',
            isRedirect: false,
            show11CmdBtn: true,
            show21CmdBtn: true,
            show22CmdBtn: true,
            show31CmdBtn: true,
            show32CmdBtn: true,
            show33CmdBtn: true,
            show41CmdBtn: true,
            show42CmdBtn: true,
            show43CmdBtn: true,
            show44CmdBtn: true,
            show51CmdBtn: true,
            show52CmdBtn: true,
            show53CmdBtn: true,
            show54CmdBtn: true,
            show55CmdBtn: true,
            show61CmdBtn: true,
            show62CmdBtn: true,
            show63CmdBtn: true,
            show64CmdBtn: true,
            show71CmdBtn: true,
            show72CmdBtn: true,
            show73CmdBtn: true,
            show74CmdBtn: true,
            show81CmdBtn: true,
            show82CmdBtn: true,
            show83CmdBtn: true,
            show91CmdBtn: true,
            show92CmdBtn: true,
            show93CmdBtn: true,
            isBOQSuccess: false,
            show101CmdBtn: true,
            show102CmdBtn: true,
            show103CmdBtn: true,
            show104CmdBtn: true,
            show105CmdBtn: true,
            show111CmdBtn: true,
            show112CmdBtn: true,
            show113CmdBtn: true,
            show114CmdBtn: true,
            show115CmdBtn: true,
            show121CmdBtn: true,
            show122CmdBtn: true,
            show123CmdBtn: true,
            show124CmdBtn: true,
            show131CmdBtn: true,
            show132CmdBtn: true,
            show133CmdBtn: true,
            show141CmdBtn: true,
            show142CmdBtn: true,
            show143CmdBtn: true,
            show151CmdBtn: true,
            show152CmdBtn: true,
            show153CmdBtn: true,
            show161CmdBtn: true,
            show162CmdBtn: true,
            show163CmdBtn: true,
            show171CmdBtn: true,
            show300CmdBtn: true,
            isSendApiCall: false,
            addHeaderMessage: '',
            addHeaderResponse: '',
            contractPercentage: 0,
            object: this.props.object,
            isAddHeaderSuccess: false,
            popUpContractPercentage: 0,
            totalContractPercentage: '',
            addHeaderTotalPercentage: '',
            BOQresponseTotalPercentage: '',
        };
        this.props.handlerPercentageLable(true);
        this._onChange = this._onChange.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }


    isValidated() {
        let isDataValid = false;
        if (this.state.totalContractPercentage == 100) {
            isDataValid = true
        } else {
            this.setState({
                alert: true
            })
        }

        return isDataValid;
    }

    alertClose() {
        this.setState({
            alert: !this.state.alert
        })
    }

    handleOnChange = e => {
        const { target: { value, name } } = e;
        this.setState({
            [name]: Boolean(value)
        });
    }

    componentDidMount() {
        if (!this.state.BOQresponse) {
            this.getBreakDown();
        }
        OpenNewProjectFileStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        OpenNewProjectFileStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.setStateBreakDown());
        this.setTotalPercentOnParent();
        this.setState(this.setStateAddHeader());
        if (!this.state.isAddHeaderSuccess && this.state.addHeaderMessage) {
            this.context.state.siteLang === LOCALES.ENGLISH ?
                toast.error(this.state.addHeaderMessage) :
                toast.error(this.state.addHeaderMessage)
        }
        if (this.state.isAddHeaderSuccess && this.state.addHeaderTotalPercentage) {
            this.props.handlerLableFromParant(this.state.addHeaderTotalPercentage);
            if (this.state.isSendApiCall) {
                toast.success(translate('savedSucc'), { autoClose: 2000 });
                this.setState({
                    isSendApiCall: false
                })
            }
            this.setState({
                BOQresponseTotalPercentage: this.state.addHeaderTotalPercentage
            })
        }
        this.setState({
            totalContractPercentage: this.props.totalContractPercentage
        })

        this.setState({ test: true })
    }

    setTotalPercentOnParent = () => {
        if (this.state.BOQresponseTotalPercentage) {
            this.props.handlerLableFromParant(this.state.BOQresponseTotalPercentage);
        }
    }

    setStateBreakDown() {
        return {
            BOQresponse: OpenNewProjectFileStore.BOQresponse,
            BOQresponseTotalPercentage: OpenNewProjectFileStore.BOQresponseTotalPercentage,
            isBOQSuccess: OpenNewProjectFileStore.isBOQSuccess()
        };
    }

    setStateAddHeader() {
        return {
            addHeaderResponse: OpenNewProjectFileStore.addHeaderResponse,
            addHeaderTotalPercentage: OpenNewProjectFileStore.addHeaderTotalPercentage,
            isAddHeaderSuccess: OpenNewProjectFileStore.isAddHeaderSuccess(),
            addHeaderMessage: OpenNewProjectFileStore.addHeaderMessage
        };
    }

    getBreakDown() {
        OpenNewProjectFileService.openNewProjectFileBOQ(this.state.object.id, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    onChangeValue = (note, workId, event) => {
        this.setState({
            contractPercentage: Number(event.target.value),
        });
    }

    sendApiCall = (contractPercentage, note, workId) => {
        this.setState({
            isSendApiCall: true
        })
        OpenNewProjectFileService.openNewAddHeaderDetail(this.state.object.id, workId, contractPercentage, note, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    onInputChangeValue = (contractPercentage, workId, event) => {
        this.setState({
            note: event.target.value,
        });
    }

    _onNoteBlur = (contractPercentage, workId, event) => {
        this.sendApiCallViaInput(this.state.note, contractPercentage, workId);
    }

    sendApiCallViaInput = (note, contractPercentage, workId) => {
        OpenNewProjectFileService.openNewAddHeaderDetail(this.state.object.id, workId, contractPercentage, note, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    modifyClick(workId) {
        if (this.state.isBOQSuccess) {
            var boqObject
            if (this.state.addHeaderResponse) {
                boqObject = CommonUtils.getBOQIndex(this.state.addHeaderResponse, workId);
            } else {
                boqObject = CommonUtils.getBOQIndex(this.state.BOQresponse, workId);
            }
            if (boqObject !== -1) {
                this.setState({
                    popUpContractPercentage: boqObject.contractPercentage,
                    popUpNote: boqObject.note,
                    popUpWorkId: workId
                })
            } else {
                this.setState({
                    popUpContractPercentage: 0,
                    popUpNote: '',
                    popUpWorkId: workId
                })
            }
        }
        this.setState({
            showBg: !this.state.showBg,
        })
    }

    handleChange = (event) => {
        this.setState({
            popUpContractPercentage: event.target.value
        });
    }

    handleNoteChange = (event) => {
        this.setState({
            popUpNote: event.target.value
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.onClick();
        }
    }

    onClick = () => {
        this.sendApiCall(this.state.popUpContractPercentage, this.state.popUpNote, this.state.popUpWorkId);
        this.setState({
            showBg: !this.state.showBg,
        })
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorMessage = parseError.message;
        console.log("Error logging in : ", errorMessage);
    }

    handlePDF = () => {
        localStorage.setItem('ownerScope', false)
        this.setState({
            isRedirect: true
        })
    }

    redirect() {
        return (
            <div>
                {this.state.isRedirect ? <Redirect
                    to={{
                        pathname: '/viewBOQSummary',
                        state: { item: this.state.object }
                    }}
                /> : null}
            </div>
        )
    }

    render() {
        var isBOQSuccess, BOQresponse, object
        object = this.state.object;
        if (this.state.addHeaderResponse) {
            isBOQSuccess = this.state.isAddHeaderSuccess;
            BOQresponse = this.state.addHeaderResponse;
        } else {
            isBOQSuccess = this.state.isBOQSuccess;
            BOQresponse = this.state.BOQresponse;
        }
        if (isBOQSuccess && BOQresponse) {
            BOQresponse.sort((a, b) => a.workId - b.workId);
        }
        var contractPer = ''
        if (this.state.popUpContractPercentage === '') {
            contractPer = ''
        } else {
            contractPer = this.state.popUpContractPercentage
        }
        return (
            <div className="col-md-12">
                <ToastContainer autoClose={5000} position={toast.POSITION.TOP_CENTER} />

                {/* EditPopup File */}
                {this.state.showBg ? <div className="PopupBk"></div> : null}
                {this.state.showBg ? <div className="uploadPopup modifyNewProjectPopup">
                    <div className="row formSection">
                        <div className="col-12"><label>{translate('contractPercent')} : </label><input onKeyDown={this.handleKeyPress} value={contractPer} type="number" onChange={this.handleChange.bind(this)} /></div>
                        <div className="col-12"><label>{translate('addComment')} : </label><input onKeyDown={this.handleKeyPress} defaultValue={this.state.popUpNote} type="text" onChange={this.handleNoteChange.bind(this)} /></div>
                        <div className="col modifyFormSectionBtn">
                            <div className="modifyFormSectionBtnSpace"></div>
                            <div className="modifyFormSectionBtnWrapper">
                                <button onClick={this.onClick} >{translate('saveAndClose')}</button>
                                <button onClick={this.modifyClick.bind(this, '')}>{translate('cancle')}</button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {/* End EditPopup File */}

                <div className="row">
                    {/* {this.state.totalContractPercentage == 100 ?
                        <div className="col-md-12 project_step_top_Btns">
                            <div>
                                <button className="printBtn" onClick={this.handlePDF}>{translate('viewBOQAndPrint')}</button>
                                {this.redirect()}
                            </div>
                        </div>
                        : null} */}

                    <div className="openNewProjectTable project_step_table_header">
                        <table>
                            <tbody>
                                <tr>
                                    <th>{translate('srNo')}</th>
                                    <th>{translate('descriptionOfwork')}</th>
                                    <th>{translate('contractPercent')}</th>
                                    <th>{translate('viewComment')}</th>
                                    <th>{translate('remarks')}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="project_step_wrapper openNewProjectTable">
                        <div className="project_step_table_contantSection">
                            {object.source == 'ENG' ? <Accordion allowZeroExpanded={true} allowMultipleExpanded={true} preExpanded={['1', '2', '3', '4', '5', '6', '7', '8',
                                '9', '10', '11', '12', '13', '14', '15', '16', '17']}>
                                {/* set1 */}
                                <AccordionItem className="widgetWrapper" uuid="1">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('mobilisation')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 11) ? BOQresponse.filter(object => object.workId == 11).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('mobSiteOfficeServices'), value, this.state.show11CmdBtn, "show11CmdBtn")
                                                    )) : this.tableColumns(11, 11, translate('mobSiteOfficeServices'), '', this.state.show11CmdBtn, "show11CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>

                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set2 */}
                                <AccordionItem className="widgetWrapper" uuid="2">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('excavationBFilling')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 21) ? BOQresponse.filter(object => object.workId == 21).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('excavation'), value, this.state.show21CmdBtn, "show21CmdBtn")
                                                    )) : this.tableColumns(1, 21, translate('excavation'), '', this.state.show21CmdBtn, "show21CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 22) ? BOQresponse.filter(object => object.workId == 22).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('BackFilling'), value, this.state.show22CmdBtn, "show22CmdBtn")
                                                    )) : this.tableColumns(2, 22, translate('BackFilling'), '', this.state.show22CmdBtn, "show22CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set3 */}
                                <AccordionItem className="widgetWrapper" uuid="3">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('subStructure')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 31) ? BOQresponse.filter(object => object.workId == 31).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('villa'), value, this.state.show31CmdBtn, "show31CmdBtn")
                                                    )) : this.tableColumns(31, 31, translate('villa'), '', this.state.show31CmdBtn, "show31CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 32) ? BOQresponse.filter(object => object.workId == 32).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('villaCompundWall'), value, this.state.show32CmdBtn, "show32CmdBtn")
                                                    )) : this.tableColumns(32, 32, translate('villaCompundWall'), '', this.state.show32CmdBtn, "show32CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 33) ? BOQresponse.filter(object => object.workId == 33).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('VillaServantBlock'), value, this.state.show33CmdBtn, "show33CmdBtn")
                                                    )) : this.tableColumns(33, 33, translate('VillaServantBlock'), '', this.state.show33CmdBtn, "show33CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set4 */}
                                <AccordionItem className="widgetWrapper" uuid="4">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('superStructure')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 41) ? BOQresponse.filter(object => object.workId == 41).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('villaGfSlab'), value, this.state.show41CmdBtn, "show41CmdBtn")
                                                    )) : this.tableColumns(41, 41, translate('villaGfSlab'), '', this.state.show41CmdBtn, "show41CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 42) ? BOQresponse.filter(object => object.workId == 42).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('villaFfSlab'), value, this.state.show42CmdBtn, "show42CmdBtn")
                                                    )) : this.tableColumns(42, 42, translate('villaFfSlab'), '', this.state.show42CmdBtn, "show42CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 43) ? BOQresponse.filter(object => object.workId == 43).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('compoundWall'), value, this.state.show43CmdBtn, "show43CmdBtn")
                                                    )) : this.tableColumns(43, 43, translate('compoundWall'), '', this.state.show43CmdBtn, "show43CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 44) ? BOQresponse.filter(object => object.workId == 44).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('servantBlock'), value, this.state.show44CmdBtn, "show44CmdBtn")
                                                    )) : this.tableColumns(44, 44, translate('servantBlock'), '', this.state.show44CmdBtn, "show44CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set5 */}
                                <AccordionItem className="widgetWrapper" uuid="5">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('blockWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 51) ? BOQresponse.filter(object => object.workId == 51).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('solidBlockVillaCw'), value, this.state.show51CmdBtn, "show51CmdBtn")
                                                    )) : this.tableColumns(51, 51, translate('solidBlockVillaCw'), '', this.state.show51CmdBtn, "show51CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 52) ? BOQresponse.filter(object => object.workId == 52).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('villaGfWalls'), value, this.state.show52CmdBtn, "show52CmdBtn")
                                                    )) : this.tableColumns(52, 52, translate('villaGfWalls'), '', this.state.show52CmdBtn, "show52CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 53) ? BOQresponse.filter(object => object.workId == 53).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('villaFfWalls'), value, this.state.show53CmdBtn, "show53CmdBtn")
                                                    )) : this.tableColumns(53, 53, translate('villaFfWalls'), '', this.state.show53CmdBtn, "show53CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 54) ? BOQresponse.filter(object => object.workId == 54).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('compoundWall'), value, this.state.show54CmdBtn, "show54CmdBtn")
                                                    )) : this.tableColumns(54, 54, translate('compoundWall'), '', this.state.show54CmdBtn, "show54CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 55) ? BOQresponse.filter(object => object.workId == 55).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('servantBlock1'), value, this.state.show55CmdBtn, "show55CmdBtn")
                                                    )) : this.tableColumns(55, 55, translate('servantBlock1'), '', this.state.show55CmdBtn, "show55CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set6 */}
                                <AccordionItem className="widgetWrapper" uuid="6">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('plasterWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 61) ? BOQresponse.filter(object => object.workId == 61).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('villaInteralPlaster'), value, this.state.show61CmdBtn, "show61CmdBtn")
                                                    )) : this.tableColumns(61, 61, translate('villaInteralPlaster'), '', this.state.show61CmdBtn, "show61CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 62) ? BOQresponse.filter(object => object.workId == 62).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('villaExternalPlaster'), value, this.state.show62CmdBtn, "show62CmdBtn")
                                                    )) : this.tableColumns(62, 62, translate('villaExternalPlaster'), '', this.state.show62CmdBtn, "show62CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 63) ? BOQresponse.filter(object => object.workId == 63).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('compoundWall'), value, this.state.show63CmdBtn, "show63CmdBtn")
                                                    )) : this.tableColumns(63, 63, translate('compoundWall'), '', this.state.show63CmdBtn, "show63CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 64) ? BOQresponse.filter(object => object.workId == 64).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('servantBlock'), value, this.state.show64CmdBtn, "show64CmdBtn")
                                                    )) : this.tableColumns(64, 64, translate('servantBlock'), '', this.state.show64CmdBtn, "show64CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set7 */}
                                <AccordionItem className="widgetWrapper" uuid="7">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('electricWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 71) ? BOQresponse.filter(object => object.workId == 71).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('conduits'), value, this.state.show71CmdBtn, "show71CmdBtn")
                                                    )) : this.tableColumns(71, 71, translate('conduits'), '', this.state.show71CmdBtn, "show71CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 72) ? BOQresponse.filter(object => object.workId == 72).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('wiring'), value, this.state.show72CmdBtn, "show72CmdBtn")
                                                    )) : this.tableColumns(72, 72, translate('wiring'), '', this.state.show72CmdBtn, "show72CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 73) ? BOQresponse.filter(object => object.workId == 73).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('accessories'), value, this.state.show73CmdBtn, "show73CmdBtn")
                                                    )) : this.tableColumns(73, 73, translate('accessories'), '', this.state.show73CmdBtn, "show73CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 74) ? BOQresponse.filter(object => object.workId == 74).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('lightFittingsFans'), value, this.state.show74CmdBtn, "show74CmdBtn")
                                                    )) : this.tableColumns(74, 74, translate('lightFittingsFans'), '', this.state.show74CmdBtn, "show74CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set8 */}
                                <AccordionItem className="widgetWrapper" uuid="8">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('plumbingWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 81) ? BOQresponse.filter(object => object.workId == 81).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('waterSupplyPipes'), value, this.state.show81CmdBtn, "show81CmdBtn")
                                                    )) : this.tableColumns(81, 81, translate('waterSupplyPipes'), '', this.state.show81CmdBtn, "show81CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 82) ? BOQresponse.filter(object => object.workId == 82).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('drainagePipes'), value, this.state.show82CmdBtn, "show82CmdBtn")
                                                    )) : this.tableColumns(82, 82, translate('drainagePipes'), '', this.state.show82CmdBtn, "show82CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 83) ? BOQresponse.filter(object => object.workId == 83).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('sanitaryWaresWaterHeaters'), value, this.state.show83CmdBtn, "show83CmdBtn")
                                                    )) : this.tableColumns(83, 83, translate('sanitaryWaresWaterHeaters'), '', this.state.show83CmdBtn, "show83CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set9 */}
                                <AccordionItem className="widgetWrapper" uuid="9">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('plumbingWorkExt')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 91) ? BOQresponse.filter(object => object.workId == 91).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('drainagePipesManholes'), value, this.state.show91CmdBtn, "show91CmdBtn")
                                                    )) : this.tableColumns(91, 91, translate('drainagePipesManholes'), '', this.state.show91CmdBtn, "show91CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 92) ? BOQresponse.filter(object => object.workId == 92).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('waterTanksPumps'), value, this.state.show92CmdBtn, "show92CmdBtn")
                                                    )) : this.tableColumns(92, 92, translate('waterTanksPumps'), '', this.state.show92CmdBtn, "show92CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 93) ? BOQresponse.filter(object => object.workId == 93).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('septicTankAndSoakaway'), value, this.state.show93CmdBtn, "show93CmdBtn")
                                                    )) : this.tableColumns(93, 93, translate('septicTankAndSoakaway'), '', this.state.show93CmdBtn, "show93CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set10 */}
                                <AccordionItem className="widgetWrapper" uuid="10">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('internalFinishes')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 101) ? BOQresponse.filter(object => object.workId == 101).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('floorTiles'), value, this.state.show101CmdBtn, "show101CmdBtn")
                                                    )) : this.tableColumns(101, 101, translate('floorTiles'), '', this.state.show101CmdBtn, "show101CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 102) ? BOQresponse.filter(object => object.workId == 102).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('wallTiles'), value, this.state.show102CmdBtn, "show102CmdBtn")
                                                    )) : this.tableColumns(102, 102, translate('wallTiles'), '', this.state.show102CmdBtn, "show102CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 103) ? BOQresponse.filter(object => object.workId == 103).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('paints'), value, this.state.show103CmdBtn, "show103CmdBtn")
                                                    )) : this.tableColumns(103, 103, translate('paints'), '', this.state.show103CmdBtn, "show103CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 104) ? BOQresponse.filter(object => object.workId == 104).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('marble'), value, this.state.show104CmdBtn, "show104CmdBtn")
                                                    )) : this.tableColumns(104, 104, translate('marble'), '', this.state.show104CmdBtn, "show104CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 105) ? BOQresponse.filter(object => object.workId == 105).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('falseCeiling'), value, this.state.show105CmdBtn, "show105CmdBtn")
                                                    )) : this.tableColumns(105, 105, translate('falseCeiling'), '', this.state.show105CmdBtn, "show105CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set11 */}
                                <AccordionItem className="widgetWrapper" uuid="11">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('externalFinishes')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 111) ? BOQresponse.filter(object => object.workId == 111).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('paints'), value, this.state.show111CmdBtn, "show111CmdBtn")
                                                    )) : this.tableColumns(111, 111, translate('paints'), '', this.state.show111CmdBtn, "show111CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 112) ? BOQresponse.filter(object => object.workId == 112).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('compoundWallPaints'), value, this.state.show112CmdBtn, "show112CmdBtn")
                                                    )) : this.tableColumns(112, 112, translate('compoundWallPaints'), '', this.state.show112CmdBtn, "show112CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 113) ? BOQresponse.filter(object => object.workId == 113).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('interlock'), value, this.state.show113CmdBtn, "show113CmdBtn")
                                                    )) : this.tableColumns(113, 113, translate('interlock'), '', this.state.show113CmdBtn, "show113CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 114) ? BOQresponse.filter(object => object.workId == 114).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('marble'), value, this.state.show114CmdBtn, "show114CmdBtn")
                                                    )) : this.tableColumns(114, 114, translate('marble'), '', this.state.show114CmdBtn, "show114CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 115) ? BOQresponse.filter(object => object.workId == 115).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('RoofClayTiles'), value, this.state.show115CmdBtn, "show115CmdBtn")
                                                    )) : this.tableColumns(115, 115, translate('RoofClayTiles'), '', this.state.show115CmdBtn, "show115CmdBtn")}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set12 */}
                                <AccordionItem className="widgetWrapper" uuid="12">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('waterProofing')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 121) ? BOQresponse.filter(object => object.workId == 121).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('footingsVilla'), value, this.state.show121CmdBtn, "show121CmdBtn")
                                                    )) : this.tableColumns(121, 121, translate('footingsVilla'), '', this.state.show121CmdBtn, "show121CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 122) ? BOQresponse.filter(object => object.workId == 122).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('roof'), value, this.state.show122CmdBtn, "show122CmdBtn")
                                                    )) : this.tableColumns(122, 122, translate('roof'), '', this.state.show122CmdBtn, "show122CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 123) ? BOQresponse.filter(object => object.workId == 123).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('bathrooms'), value, this.state.show123CmdBtn, "show123CmdBtn")
                                                    )) : this.tableColumns(123, 123, translate('bathrooms'), '', this.state.show123CmdBtn, "show123CmdBtn")}

                                                    {/* {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 124) ? BOQresponse.filter(object => object.workId == 124).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('marble'), value, this.state.show124CmdBtn, "show124CmdBtn")
                                                    )) : this.tableColumns(124, 124, translate('marble'), '', this.state.show124CmdBtn, "show124CmdBtn")} */}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set13 */}
                                <AccordionItem className="widgetWrapper" uuid="13">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('aluminiumWorks')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 131) ? BOQresponse.filter(object => object.workId == 131).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('doors'), value, this.state.show131CmdBtn, "show131CmdBtn")
                                                    )) : this.tableColumns(131, 131, translate('doors'), '', this.state.show131CmdBtn, "show131CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 132) ? BOQresponse.filter(object => object.workId == 132).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('windows'), value, this.state.show132CmdBtn, "show132CmdBtn")
                                                    )) : this.tableColumns(132, 132, translate('windows'), '', this.state.show132CmdBtn, "show132CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 133) ? BOQresponse.filter(object => object.workId == 133).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('handrail'), value, this.state.show133CmdBtn, "show133CmdBtn")
                                                    )) : this.tableColumns(133, 133, translate('handrail'), '', this.state.show133CmdBtn, "show133CmdBtn")}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set14 */}
                                <AccordionItem className="widgetWrapper" uuid="14">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('gatesMetalWorks')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 141) ? BOQresponse.filter(object => object.workId == 141).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('gates'), value, this.state.show141CmdBtn, "show141CmdBtn")
                                                    )) : this.tableColumns(141, 141, translate('gates'), '', this.state.show141CmdBtn, "show141CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 142) ? BOQresponse.filter(object => object.workId == 142).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('spiralStair'), value, this.state.show142CmdBtn, "show142CmdBtn")
                                                    )) : this.tableColumns(142, 142, translate('spiralStair'), '', this.state.show142CmdBtn, "show142CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 143) ? BOQresponse.filter(object => object.workId == 143).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('CarShed'), value, this.state.show143CmdBtn, "show143CmdBtn")
                                                    )) : this.tableColumns(143, 143, translate('CarShed'), '', this.state.show143CmdBtn, "show143CmdBtn")}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set15 */}
                                <AccordionItem className="widgetWrapper" uuid="15">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('joineryWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 151) ? BOQresponse.filter(object => object.workId == 151).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('doors'), value, this.state.show151CmdBtn, "show151CmdBtn")
                                                    )) : this.tableColumns(151, 151, translate('doors'), '', this.state.show151CmdBtn, "show151CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 152) ? BOQresponse.filter(object => object.workId == 152).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('kitchenCabinetsWardrobes'), value, this.state.show152CmdBtn, "show152CmdBtn")
                                                    )) : this.tableColumns(152, 152, translate('kitchenCabinetsWardrobes'), '', this.state.show152CmdBtn, "show152CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 153) ? BOQresponse.filter(object => object.workId == 153).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('Pergola'), value, this.state.show153CmdBtn, "show153CmdBtn")
                                                    )) : this.tableColumns(153, 153, translate('Pergola'), '', this.state.show153CmdBtn, "show153CmdBtn")}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set16 */}
                                <AccordionItem className="widgetWrapper" uuid="16">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('airConditioningWork')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 161) ? BOQresponse.filter(object => object.workId == 161).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('machinesUnit'), value, this.state.show161CmdBtn, "show161CmdBtn")
                                                    )) : this.tableColumns(161, 161, translate('machinesUnit'), '', this.state.show161CmdBtn, "show161CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 162) ? BOQresponse.filter(object => object.workId == 162).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('ductsDiffusers'), value, this.state.show162CmdBtn, "show162CmdBtn")
                                                    )) : this.tableColumns(162, 162, translate('ductsDiffusers'), '', this.state.show162CmdBtn, "show162CmdBtn")}

                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 163) ? BOQresponse.filter(object => object.workId == 163).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('CivilWork'), value, this.state.show163CmdBtn, "show163CmdBtn")
                                                    )) : this.tableColumns(163, 163, translate('CivilWork'), '', this.state.show163CmdBtn, "show163CmdBtn")}

                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                {/* set17 */}
                                <AccordionItem className="widgetWrapper" uuid="17">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('cleaning')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 171) ? BOQresponse.filter(object => object.workId == 171).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('cleaningLevelingHandingOver'), value, this.state.show171CmdBtn, "show171CmdBtn")
                                                    )) : this.tableColumns(171, 171, translate('cleaningLevelingHandingOver'), '', this.state.show171CmdBtn, "show171CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion> : null}

                            {/* grant */}
                            {object.source == 'TQL' ? <Accordion allowZeroExpanded={true} allowMultipleExpanded={true} preExpanded={['1']}>
                                <AccordionItem className="widgetWrapper" uuid="1">
                                    <AccordionItemHeading className="widgetHead">
                                        <AccordionItemButton>
                                            <span>{translate('grantBoq')}</span>
                                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/toggleico.svg" alt="MBRHE" /></i>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {BOQresponse.length > 0 && BOQresponse.some(object => object.workId == 300) ? BOQresponse.map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('grantBoq'), value, this.state.show300CmdBtn, "show300CmdBtn")
                                                    )) : this.tableColumns(300, 300, translate('grantBoq'), '', this.state.show300CmdBtn, "show300CmdBtn")}
                                                </tbody>
                                            </table>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion> : null}

                            {this.state.alert ? <div className="alert alert alert-danger" role="alert">
                                <button onClick={this.alertClose.bind(this)} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                {translate('totalPaymentPercentage')}
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    tableColumns(index, workId, name, boq, state, stateType) {
        var note = '';
        var engRemark = '';
        var contractPercentage = 0;
        if (boq && boq.workId == workId) {
            contractPercentage = boq.contractPercentage;
            note = boq.note;
            engRemark = boq.engRemark;
            if (this.state.addHeaderResponse) {
                let obj = this.state.addHeaderResponse.find(obj => obj.workId === workId);
                contractPercentage = obj.contractPercentage;
                note = obj.note;
                engRemark = boq.engRemark;
            }
        }
        return (
            <tr key={index}>
                <td>{workId}</td>
                <td>{name}</td>
                <td>
                    <input disabled type="Number" value={contractPercentage} maxLength="6" />
                </td>
                <td className="stepCommentsWrapper">
                    {note == null ? <input disabled type="text" value='' /> : <input disabled type="text" value={note} />}
                    <button>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        {note ? <span className="tooltiptext">{note}</span> : null}
                    </button>
                </td>
                <td className="stepRemarksWrapper">
                    <input type="text" disabled defaultValue={engRemark} />
                    <button>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        {engRemark ? <span className="tooltiptext">{engRemark}</span> : null}
                    </button>
                </td>
                <td className="text-center"><button className="modifiBtn modify" onClick={this.modifyClick.bind(this, workId)}><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/modify_boq.svg" alt="edit" /></i>{translate('modify')}</button></td>
            </tr>
        );
    }
}

export default BreakDownInformationComponent;
