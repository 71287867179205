import React from "react"
import history from '../History'
import qs from 'query-string'

class LiveChatComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            locale: props.locale,
            siteLang: props.siteLang,
            divClick: true,
            chatClicked: false,
            queryParams: null
        }
        this.divClick = this.divClick.bind(this)
        this.chatClicked = this.chatClicked.bind(this)
    }

    componentDidMount() {
        this.setState({ queryParams: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) })
        setTimeout(function () { this.chatClicked() }.bind(this), 1000)
    }

    chatClicked() {
        window.$zopim.livechat.setName(this.state.queryParams.name ? this.state.queryParams.name : '')
        window.$zopim.livechat.setEmail(this.state.queryParams.email ? this.state.queryParams.email : '')
        window.$zopim.livechat.setPhone(this.state.queryParams.mobile ? this.state.queryParams.mobile : '')
        window.$zopim.livechat.setLanguage(this.state.queryParams.local ? this.state.queryParams.local : 'en')
        window.$zopim.livechat.window.show()
    }

    divClick() {
        this.setState({ divClick: !this.state.divClick })
        history.push('/customerDashboard')
    }

    handleFabCheckbox() {
        return true;
    }

    render() {
        return (
            <div className="fab-wrapper">
                <input id="fabCheckbox" type="checkbox" onClick={this.divClick} checked={this.state.divClick} onChange={() => this.handleFabCheckbox()} className="fab-checkbox" />
                <label className="fab" htmlFor="fabCheckbox">
                    <img data-tip data-for='Chat' className="msgIco" src={this.state.divClick ? "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/chatbotO.svg" : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/chatbot.svg"} alt="Open" />
                </label>
                <div className="fab-wheel">
                    <div className="fab-action closeFloatIco" onClick={this.divClick}><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/chat_close.svg" alt="close" /></div>
                </div>
                <div className="fab-wheelBack" onClick={this.divClick}></div>
            </div>
        );
    }
}

export default LiveChatComponent;