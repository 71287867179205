import React from "react";
import DateFormater from 'dateformat'
import queryString from 'query-string'
import GoogleMapReact from 'google-map-react'
import LoadingBar from 'react-top-loading-bar'
import { ScrollToTop } from '../../utils/Scroll'
import { AppContext } from '../../Providers/context'
import HouseBookingStore from './housebooking-store'
import { ToastContainer, toast } from 'react-toastify'
import translate from '../../Providers/i18n/Translate'
import HouseBookingMapPin from './housebooking-map-pin'
import HouseBookingService from './housebooking-service'
import CONSTANTS from '../constants/dashboard-constants'
import InputComponent from '../utils/inputComponent'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
//put this date as expiry date  "bookingDueDate": "2020-11-11T12:58:17",
class MyBooking extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            payload: '',
            printButtonDisabled: false,
            showPrint: false,
            name: '',
            emailAddress: '',
            MobileNo: '',
            termsAccept: false,
            errors: [],
            user: JSON.parse(localStorage.getItem('dashboard')).payload,
        }
        this.printPdf = this.printPdf.bind(this)
        this.onChange = this.onChange.bind(this)
        this.epay = this.epay.bind(this)
        this.addError = this.addError.bind(this)
        this.removeError = this.removeError.bind(this)
        this.termsClick = this.termsClick.bind(this)
        this.documentSuccess = this.documentSuccess.bind(this)
        this.confirmPaymentRequest = this.confirmPaymentRequest.bind(this)
        this.inputFieldsChangeHandler = this.inputFieldsChangeHandler.bind(this)
    }

    componentDidMount() {
        ScrollToTop()
        setTimeout(() => { this.state.user.name && this.state.user.name.length > 0 ? this.removeError("name") : this.addError("name") }, 100)
        setTimeout(() => { this.state.user.primaryMobileNo && this.state.user.primaryMobileNo.length > 0 ? this.removeError("MobileNo") : this.addError("MobileNo") }, 200)
        setTimeout(() => { this.state.user.email && this.state.user.email.length > 0 ? this.removeError("emailAddress") : this.addError("emailAddress") }, 300)
        this.setState({ name: this.state.user.name, emailAddress: this.state.user.email, MobileNo: this.state.user.primaryMobileNo })
        this.getEpayConfirmation()
        HouseBookingService.houseBookingMyBookingsRequest(CONSTANTS.houseBookingMyBookingsEndPoint + "?local=" + this.context.state.siteLang)
        HouseBookingStore.addChangeListener(this.onChange)
    }

    getEpayConfirmation() {
        let url = this.props.location.search;
        if (url !== "") {
            let value = queryString.parse(url);
            this.confirmPaymentRequest(value)
            this.setState({ values: value });
        }
    }

    confirmPaymentRequest(value) {
        var token = localStorage.getItem('accessToken')
        if (token) {
            var url = CONSTANTS.houseBookingCompleteePayEndpoint + "?local=" + this.context.state.siteLang + "&spTrn=" + value.sptrn
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'bearer ' + token
                }
            }).then(response => response.json()).then(this.completeRequestSuccess).catch(this.completeRequestError)
        }
    }

    componentWillUnmount() {
        HouseBookingStore.removeChangeListener(this.onChange)
    }

    onChange() {
        if (HouseBookingStore.houseBookingMyBookings && HouseBookingStore.houseBookingMyBookings.payload) {
            this.setState({ payload: HouseBookingStore.houseBookingMyBookings.payload })
            if (HouseBookingStore.houseBookingMyBookings.payload.isBookingAmountPaid) {
                this.setState({ showPrint: true })
            } else {
                this.setState({ showPrint: false })
            }
        }

        if (HouseBookingStore.houseBookingPrintMyBooking && HouseBookingStore.houseBookingPrintMyBooking.success) {
            this.documentSuccess(HouseBookingStore.houseBookingPrintMyBooking.url)
        } else {
            if (HouseBookingStore.houseBookingPrintMyBooking) {
                toast.error(HouseBookingStore.houseBookingPrintMyBooking.message)
            }
            this.LoadingBar && this.LoadingBar.complete()
            this.setState({ printButtonDisabled: false })
        }
        if (HouseBookingStore.houseBookingePayPayload) {
            var epay = HouseBookingStore.houseBookingePayPayload
            if (epay != null) {
                if (epay.success) {
                    window.location.replace(epay.payload.redirectionUrl)
                } else {
                    toast.error(epay.message)
                }
            }
        }
    }

    documentSuccess(url) {
        this.LoadingBar && this.LoadingBar.complete()
        window.open().location.href = url
        this.setState({ printButtonDisabled: false })
    }

    printPdf() {
        this.setState({ printButtonDisabled: true }, () => {
            this.LoadingBar && this.LoadingBar.continuousStart()
            var url = CONSTANTS.houseBookingPrintMyBookingsEndPoint + "?local=" + this.context.state.siteLang
            HouseBookingService.houseBookingPrintMyBookingRequest(url)
        })
    }

    epay(e) {
        if (this.state.errors.length <= 0) {
            if (this.state.termsAccept) {
                var url = CONSTANTS.houseBookingePayEndpoint + "?local=" + this.context.state.siteLang
                HouseBookingService.houseBookingePayRequest(url, this.state.payload.id, this.state.emailAddress, this.state.MobileNo, this.getAmount(this.state.payload.bookingAmount))
            } else {
                toast.error(translate('acceptterms'))
            }
        } else {
            toast.error(translate('requiredErrorMessage'))
        }

    }

    getAmount(amount) {
        return parseFloat(amount.substring(0, amount.length - 5).replace(/,/g, ''))
    }

    inputFieldsChangeHandler(e, isValid) {
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (isValid) {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    termsClick(e) {
        this.setState({ termsAccept: e.target.checked })
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <LoadingBar className='loagingbar' height={0} color='#0091ff' onRef={ref => (this.LoadingBar = ref)} />
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item"><a href="/houseBooking">{translate('HouseBooking')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('mybookings')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" /></i>
                                <span>{translate('mybookings')}</span>
                                {this.state.showPrint ? <button onClick={this.printPdf} disabled={this.state.printButtonDisabled}>
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/print.svg'} alt="print" />
                                    <span>{translate('Print')}</span>
                                </button> : null}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper">
                                <div className="yourBookingHead">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row hBookrefNo">
                                                <div className="col-lg-6 col-sm-6"><strong>{translate("BookingReferenceNumber")}</strong> : </div>
                                                <div className="col-lg-6 col-sm-6">{this.state.payload.referenceNo}</div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="row  hBookresDt">
                                                <div className="col-lg-3 col-sm-6"><strong> {translate("BookedDate")} </strong> : </div>
                                                <div className="col-lg-3 col-sm-6"> {DateFormater(this.state.payload.bookingDate, 'dd/mm/yyyy')} </div>
                                                <div className="col-lg-3 col-sm-6"><strong> {translate("BookingExpiryDate")} </strong> : </div>
                                                <div className="col-lg-3 col-sm-6"> {DateFormater(this.state.payload.bookingDueDate, 'dd/mm/yyyy')} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters yourBookingDetails">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-sm-6"><strong> {translate("projectName")} </strong> : </div>
                                            <div className="col-sm-6"> {this.state.payload.projectDescription} </div>
                                            <div className="col-sm-6"><strong> {translate("Style")} </strong> : </div>
                                            <div className="col-sm-6"> {this.state.payload.villaStyle} </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters yourBookingDetails">
                                    <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Housing_Projects.svg'} alt="Zone" /></i>
                                        <span><strong> {translate("Zone")} : </strong> {this.state.payload.landLocation} </span>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Villa number" /></i>
                                        <span><strong> {translate("VillaNo")} : </strong> {this.state.payload.villaNo} </span>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Floor" /></i>
                                        <span><strong> {translate("NumberofFloors")} : </strong> {this.state.payload.floor} </span>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Land number" /></i>
                                        <span><strong> {translate("LandNo")} : </strong> {this.state.payload.plotNumber} </span>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Building area" /></i>
                                        <span><strong> {translate("BuildingArea")} : </strong> {this.state.payload.basedArea} </span>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} alt="Number of bedroom" /></i>
                                        <span><strong> {translate("NumberofBedrooms")} : </strong> {this.state.payload.bedroom} </span>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6 housebookDetailsbox">
                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Land area" /></i>
                                        <span><strong> {translate("Landarea")} : </strong> {this.state.payload.plotArea} </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="subHeading">{translate("CalculateTheValueOfTheHouse")}</h3>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="houseBookingCustomTable">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6">
                                                    <strong> {translate("PriceAllocation")} </strong>
                                                    <span> {this.state.payload.sellingPrice} </span>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <strong> {translate("BookingPrice")} </strong>
                                                    <span> {this.state.payload.bookingAmount} </span>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <strong> {translate("loanAmount")} </strong>
                                                    <span> {this.state.payload.loanAmount} </span>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <strong> {translate("RemainingAmount")} </strong>
                                                    <span> {this.state.payload.remainingAmount} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.payload && !this.state.payload.isBookingAmountPaid ? <div className="col-md-12">
                                        <div className="bookingLocationWrapper">
                                            <input defaultChecked="checked" id="tab22" type="radio" name="pay" />
                                            <section>
                                                <div className="tab22">
                                                    <div className="col-md-12 cashDepoWrapper">
                                                        <label htmlFor="tab22">
                                                            <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/creditcard.svg'} /></i>
                                                            <span> {translate("pay_by_E_Payment")} </span>
                                                        </label>
                                                        <div className="row text-center">
                                                            <div className="col-md-12">
                                                                <h2 className="blueText">{translate("booking_fee_heading")} {this.state.payload.bookingAmount} {translate("dirham")}</h2>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="bookingCenterForm formSection">
                                                                    <div className="row">
                                                                        <InputComponent inputProps={{ isRequired: true, class: "col-md-12", lable: translate('name'), name: "name", defaultValue: this.state.name, value: this.state.name, type: 'text', changeListener: this.inputFieldsChangeHandler }} />
                                                                    </div>
                                                                    <div className="row">
                                                                        <InputComponent inputProps={{ isRequired: true, class: "col-md-12", lable: translate('mobileNo'), name: "MobileNo", defaultValue: this.state.MobileNo, value: this.state.MobileNo, type: 'phone', changeListener: this.inputFieldsChangeHandler }} />
                                                                    </div>
                                                                    <div className="row">
                                                                        <InputComponent inputProps={{ isRequired: true, class: "col-md-12", lable: translate('email'), name: "emailAddress", defaultValue: this.state.emailAddress, value: this.state.emailAddress, type: 'email', changeListener: this.inputFieldsChangeHandler }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="warningMsgBox">
                                                            <span>
                                                                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/warrningmsg_icon.svg'} alt="warrning" />
                                                            </span>
                                                            <span className="Message userJurny">{translate("booking_note_two")}</span>
                                                        </div>
                                                        <div className="col-md-12 hBookTabsBottom">
                                                            <div className="row">
                                                                <div className="col-sm-6 customCheckBox">
                                                                    <input name='temsAccept' checked={this.state.termsAccept} onChange={this.termsClick} type="checkbox" id="accept2" />
                                                                    <label htmlFor="accept2">{translate("IAcceptingThe")}</label>
                                                                </div>
                                                                <div className="col-md-6 formSectionBtn">
                                                                    <button onClick={this.epay} type="submit">{translate('PayNow')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <h3>{translate("TermsConditions")}</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper listPoint">
                                <h4>شروط (حجز مبدئي لمسكن جاهز)</h4>
                                <ul>
                                    <li>صلاحية الحجز لمدة شهر من تاريخه.</li>
                                    <li>يعتبر حجز المسكن ملغي في حال عدم استكمال المستندات المطلوبة ، أو عدم مطابقته للشروط.</li>
                                    <li>يتم دفع مبلغ على حسب رسالة الموافقة الصادرة من المؤسسة كحجز مبدئي للمسكن </li>
                                </ul>
                                <h4>شروط (حجز مبدئي لمسكن قيد الانشاء)</h4>
                                <ul>
                                    <li>يعتبر حجز المسكن ملغي في حال عدم استكمال المستندات المطلوبة أو عدم مطابقته للشروط.</li>
                                    <li>المؤسسة غير مسؤولة عن تعويض المتعامل في حال التأخير في تسليم المسكن..</li>
                                    <li>لا يحق للمتعامل معاينة المسكن قيد الانشاء لحين الانتهاء من المشروع.</li>
                                    <li>لا يحق للمتعامل المطالبة بأية تعديلات على المسكن قيد الإنشاء.</li>
                                    <li>يتم دفع مبلغ على حسب رسالة الموافقة الصادرة من المؤسسة كحجز مبدئي للمسكن </li>
                                </ul>
                                <h4>شروط عامة</h4>
                                <ul>
                                    <li>لا يتم تسليم المسكن إلا بعد تحصيل قيمته بالكامل.</li>
                                    <li>يتم سداد المبلغ المتبقي  أو فرقية المبلغ من حصة المتعامل خلال شهر من ابلاغ المتعامل بذلك، وبخلافه يتم إلغاء الحجز مباشرة و إحالة طلبكم للمشروع القادم.</li>
                                    <li>يسمح للمتعامل بتمديد حجز المسكن مرتين فقط على ان لا تتعدى مدة الحجز شهرين من تاريخه مع توضيح سبب التأجيل, وفي حال عدم رغبتكم في المساكن المتاحة بالمشروع يتم إحالة طلبكم للمشروع القادم.</li>
                                    <li>لا يسمح بتغيير المسكن المحجوز إلا مره واحدة فقط خلال فترة تخصيصه المبدئي</li>
                                    <li>في حال وجود أحد أفراد الأسرة من أصحاب الهمم يرجى توفير (بطاقة سند/ بطاقة أصحاب الهمم)</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="bookingLocationWrapper">
                                <input defaultChecked="checked" id="tab1" type="radio" name="pct" />
                                <input id="tab2" type="radio" name="pct" />
                                <nav>
                                    <ul>
                                        <li className="tab1">
                                            <label htmlFor="tab1">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/3dview.svg'} alt="3dview" /></i>
                                                <span>{translate('3DView')}</span>
                                            </label>
                                        </li>
                                        <li className="tab2">
                                            <label htmlFor="tab2">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/locationMap.svg'} alt="Location" /></i>
                                                <span>{translate('Locationonthemap')}</span>
                                            </label>
                                        </li>
                                    </ul>
                                </nav>
                                <section>
                                    <div className="tab1">
                                        <div className="villa3dimage">
                                            <iframe src={this.state.payload.threeDimensionalHouseURL} />
                                        </div>
                                    </div>
                                    <div className="tab2">
                                        <div className="villaLocationMap">
                                            <div id="map" style={{ height: '100%', width: '100%', position: 'relative' }}>
                                                {this.state.payload ? <GoogleMapReact
                                                    bootstrapURLKeys={{ key: CONSTANTS.googleMapKey }}
                                                    defaultCenter={{ lat: this.state.payload.latitude ? parseFloat(this.state.payload.latitude) : 0, lng: this.state.payload.longitude ? parseFloat(this.state.payload.longitude) : 0 }}
                                                    defaultZoom={16}>
                                                    <HouseBookingMapPin key={0} payload={this.state.payload} callback={this.refreshPins} showPopUp={false} />
                                                </GoogleMapReact> : null}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AuthenticatedComponent(MyBooking)