import React from 'react'
import DateFormater from 'dateformat'
import { ScrollToTop } from '../../utils/Scroll'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
// import 'owl.carousel/dist/assets/owl.carousel.min.css'
// import 'owl.carousel/dist/assets/owl.theme.default.css'
import TypicalVillasUtils from './typical_villas_utils'
import CONSTANTS from '../constants/dashboard-constants'
import { FacebookIcon, EmailIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
import { FacebookShareButton, EmailShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'

class TypicalVillasReviewComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            payload: '',
            faqPopup: false,
            videoPopup: false,
            imgsArray: [],
            payloadr: {},
            requestId: localStorage.requestId ? localStorage.getItem("requestId") : '',
            selectedStyleId: localStorage.styleID ? localStorage.getItem('styleID') : 0,
            lookupsPayload: localStorage.lookupsPayload ? JSON.parse(localStorage.getItem('lookupsPayload')) : '',
        }
        this.getConsultantInfo = this.getConsultantInfo.bind(this)
        this.faqPopClick = this.faqPopClick.bind(this)
        this.videoPopClick = this.videoPopClick.bind(this)
    }

    faqPopClick() {
        this.setState({ faqPopup: !this.state.faqPopup })
    }
    videoPopClick() {
        this.setState({ videoPopup: !this.state.videoPopup })
    }

    componentDidMount() {
        ScrollToTop()
        this.setState({ payload: TypicalVillasUtils.getVilla(Number(localStorage.getItem('villaID'))) }, () => {
            if (localStorage.getItem('villaResponse')) {
                this.setState({ payloadr: JSON.parse(localStorage.getItem('villaResponse')) })
            } else {
                this.setState({ payloadr: JSON.parse(localStorage.getItem("oldTypicalVillaBooking")) })
            }
            this.getConsultantInfo()
        })
    }

    getConsultantInfo() {
        this.setState({ consultant: TypicalVillasUtils.getConsultant(this.state.payload.consultant) })
    }

    printPdf(url) {
        var iframe = this._printIframe;
        if (!this._printIframe) {
            iframe = this._printIframe = document.createElement('iframe');
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
            };
        }

        iframe.src = url;
    }

    render() {
        return (
            <div className="container-fluid newStyleWrapper">
                {this.state.faqPopup ? <div className="PopupBk"></div> : null}
                {this.state.faqPopup ? <div className="uploadPopup">
                    <button className="popupClose" onClick={this.faqPopClick}>X</button>
                    <div className="popupShowFile">
                        <iframe src={this.context.state.siteLang === 'en' ? 'https://mbrhe-cdn.azureedge.net/eservices-portal/unifiedvillas/FAQ_EN.pdf' : 'https://mbrhe-cdn.azureedge.net/eservices-portal/unifiedvillas/FAQ_Ar.pdf'}></iframe>
                    </div>
                </div> : null}
                {this.state.videoPopup ? <div className="PopupBk"></div> : null}
                {this.state.videoPopup ? <div className="uploadPopup">
                    <button className="popupClose" onClick={this.videoPopClick}>X</button>
                    <div className="popupShowFile">
                        <iframe width="560" height="315" src={"https://youtube.com/embed/" + TypicalVillasUtils.getStyleVideo(this.state.payload, this.state.selectedStyleId)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div> : null}
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    {/* <li className="breadcrumb-item"><a href="/typicalVillas">{translate('typicalvillas')}</a></li> */}
                                    <li className="breadcrumb-item active" aria-current="page">{translate('confirmation')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <span>{translate('typicalvillas')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection typicalVillasDetailWrapper">
                                <div className="service_title_wrapper">
                                    <h3>{translate('servicesteps')}</h3>
                                </div>
                                <div className='stagesWrapper'>
                                    <div className='stagesBox stagesBg1'>
                                        <div className='boxIco'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stagesBoxIco1.svg'} alt="Apply for request" /></div>
                                        <p>{translate('applyforrequest')}</p>
                                    </div>
                                    <div className='stagesBox stagesBg2'>
                                        <div className='boxIco'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stagesBoxIco2.svg'} alt="Request under process" /></div>
                                        <p>{translate('requestunderprocess')}</p>
                                    </div>
                                    <div className='stagesBox stagesBg3'>
                                        <div className='boxIco'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stagesBoxIco3.svg'} alt="Amount payment" /></div>
                                        <p>{translate('amountpayment')}</p>
                                    </div>
                                    <div className='stagesBox stagesBg4'>
                                        <div className='boxIco'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stagesBoxIco4.svg'} alt="Establishment step" /></div>
                                        <p>{translate('establishmentstep')}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="service_title_wrapper">
                                <span>{translate('yourBookingDetails')}</span>
                            </div>

                            <div className="service_contant_wrapper formSection typicalVillasDetailWrapper">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='row typicalVillasReviewDetails'>
                                            <div className='col-md-4'>
                                                <strong>{translate('requestNo')}</strong>
                                                <span> : {this.state.requestId ? this.state.requestId : this.state.payloadr.id}</span>
                                            </div>
                                            <div className='col-md-4'>
                                                <strong>{translate('requestStatus')}</strong>
                                                <span> : {this.state.payloadr.requestStatusDesc}</span>
                                            </div>
                                            <div className='col-md-4'>
                                                <strong>{translate('requestDate')}</strong>
                                                <span> : {DateFormater(this.state.payloadr.createdAt, 'dd/mm/yyyy')}</span>
                                            </div>
                                        </div>
                                        <div className='CarouselWrapper villaReview'>
                                            {this.state.payload ? TypicalVillasUtils.getImageURLArray(this.state.payload, this.state.lookupsPayload, this.context.state.siteLang) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='row iconsWrapper'>
                                    <div className='col'>
                                        <ul>
                                            <li>
                                                <button onClick={this.faqPopClick}>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/aqs_icon.svg'} alt="FAQs" /></i>
                                                    <span>{translate("faq")}</span>
                                                </button>
                                            </li>
                                            <li>
                                                <button onClick={this.videoPopClick}>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/video_icon.svg'} alt="video" /></i>
                                                    <span>{translate("ModelVideo")}</span>
                                                </button>
                                            </li>
                                            <li>
                                                <a href="#!">
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/share_icon.svg'} alt="share" /></i>
                                                    <span>{translate("share")}</span>
                                                </a>
                                                <ul>
                                                    <li>
                                                        <FacebookShareButton url={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf} >
                                                            <FacebookIcon size={30} round={true} />
                                                        </FacebookShareButton >

                                                        <TwitterShareButton url={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf}>
                                                            <TwitterIcon size={30} round={true} />
                                                        </TwitterShareButton>

                                                        <EmailShareButton url={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf}>
                                                            <EmailIcon size={30} round={true} />
                                                        </EmailShareButton>

                                                        <WhatsappShareButton url={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf}>
                                                            <WhatsappIcon size={30} round={true} />
                                                        </WhatsappShareButton>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <button onClick={() => window.open(CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf, '_blank', 'noopener,noreferrer', "PRINT", "height=400,width=600")}>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/b_print_icon.svg'} alt="Print" /></i>
                                                    <span>{translate("Print")}</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='row houseDetails'>
                                    <div className="col-md-6 largFonts">
                                        <h2>{TypicalVillasUtils.getVillaTitle(this.state.lookupsPayload, this.context.state.siteLang)} {this.state.payload.title}</h2>
                                        <ul>
                                            <li>
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/house_new.svg'} alt="img" /></i>
                                                <span>{translate('floors')} : {this.state.payload.floor}</span>
                                            </li>
                                            <li>
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} alt="img" /></i>
                                                <span>{translate('bedrooms')} : {this.state.payload.bedroom}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6 largFonts">
                                        <h2>{this.state.payload ? TypicalVillasUtils.getStyleLabel(this.state.lookupsPayload, this.state.payload, this.state.selectedStyleId, this.context.state.siteLang) : ''}</h2>
                                        <ul>
                                            <li>
                                                <span>{translate('area')} : {this.state.payload ? TypicalVillasUtils.getStyleArea(this.state.payload, this.state.selectedStyleId) : 0} {translate('sqft')}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-12 largFonts">
                                        <ul>
                                            <li>
                                                <span>{translate("startingprice")} : {this.state.payload.startingPrice} {translate("dirham")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-md-12 typicalTitle'>
                                        <h2>{translate("consultantInformation")}</h2>
                                    </div>
                                    <div className='col-md-12 typicalInfoWrapper'>
                                        <hr></hr>
                                        <ul>
                                            <li>
                                                <span>{translate("consultantcompany")}</span>
                                                {this.state.consultant ? <span> {this.state.consultant ? this.state.consultant.name : ''}</span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                            <li>
                                                <span>{translate("engineername")}</span>
                                                {this.state.consultant ? <span> {this.state.consultant ? this.state.consultant.eng_name : ''}</span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                            <li>
                                                <span>{translate("email")}</span>
                                                {this.state.consultant ? <span> {this.state.consultant ? this.state.consultant.email : ''}</span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                            <li>
                                                <span>{translate("mobileNo")}</span>
                                                {this.state.consultant ? <span>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/call_icon.svg'} alt="call" /></i>
                                                    <a href='tel:0501000000'>{this.state.consultant ? this.state.consultant.contactNo : ''}</a>
                                                </span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                            <li>
                                                <span>{translate("Locationonthemap")}</span>
                                                {this.state.consultant ? <span>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/location_icon.svg'} alt="location" /></i>
                                                    <a href={this.state.consultant ? this.state.consultant.location : ''} target="_blank" rel="noopener noreferrer">{translate("clickhere")}</a>
                                                </span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="houseBtns center">
                                        {/* <button className="primary_button button_50" onClick={() => { this.props.history.push('/typicalVillas') }}>{translate('backToHome')}</button> */}
                                        <button className="primary_button button_50" onClick={() => { this.props.history.push('/customerDashboard') }}>{translate('backToHome')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default AuthenticatedComponent(TypicalVillasReviewComponent)