
import React from 'react'
import qs from 'qs'
import NoData from '../utils/nodata'
import Loader from '../utils/LoaderBars'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import PieChart from '../utils/piechart'
import CONSTANTS from '../constants/dashboard-constants'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'

class ContractorPaymentRequest extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            item: null,
            props: '',
            response: '',
            showLoading: false,
            showComplementaryServices: false,
            token: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token
        }
        this.failCallback = this.failCallback.bind(this)
        this.beforeUnload = this.beforeUnload.bind(this)
        this.validateRequest = this.validateRequest.bind(this)
        this.validationResponse = this.validationResponse.bind(this)
        this.acceptRejectPayment = this.acceptRejectPayment.bind(this)
        this.processPaymentResponse = this.processPaymentResponse.bind(this)
    }

    componentDidMount() {
        localStorage.setItem("isPendingPayment", "true")
        this.validateRequest(this.state.token)
        window.addEventListener('beforeunload', this.beforeUnload);
    }

    beforeUnload() {
        localStorage.removeItem("isPendingPayment")
        window.removeEventListener('beforeunload', this.beforeUnload);
    }

    validateRequest(token) {
        this.setState({ showLoading: true })
        fetch(CONSTANTS.validatePendingPaymentPublicRequest + "?token=" + token + "&local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => response.json()).then(this.validationResponse).catch(this.failCallback);
    }

    validationResponse(response) {
        this.setState({ showLoading: false })
        if (response.isValid) {
            this.setState({ item: this.paymentItem(response.payload) })
        } else {
            this.setState({ responseMessage: response.message })
        }
    }

    processPaymentRequest(token, decission) {
        this.setState({ showLoading: true })
        fetch(CONSTANTS.processPendingPaymentPublicRequest + "?token=" + token + "&isApproved=" + decission + "&local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(response => response.json()).then(this.processPaymentResponse).catch(this.failCallback);
    }

    processPaymentResponse(response) {
        this.setState({ showLoading: false })
        if (response.success) {
            this.setState({ item: null, responseMessage: response.message })
        } else {
            toast.error(response.message)
        }
    }

    failCallback() {
        this.setState({ showLoading: false })
        toast.error(translate("nodata"))
    }

    acceptRejectPayment(e) {
        this.processPaymentRequest(this.state.token, e.target.id)
    }

    paymentItem(item) {
        return (
            <div>
                <div className='contractorPaymentRequestsSection' style={{ display: '' }}>
                    <ToastContainer autoClose={5000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                    <h4>{translate('ContractorPaymentSubmittedForReviewAndApprovalHeading')}:</h4>
                    <h6>{translate('ContractorPaymentSubmittedForReviewAndApproval')}</h6>
                    <div className="contractorPaymentRequestsBox">
                        <div className='sectionBlock'>
                            <PieChart progressColor="#408d45" percentTextColor="#3c4551" data={[{ value: item.completedPercentage }, { value: (100 - item.completedPercentage) }]} />
                        </div>
                        <div className="sectionBlock">
                            <div className='sectionCol'>
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/clipboardUser.svg'} alt="User" /></i>
                                <div>
                                    <span>{translate('contractorName')}</span>
                                    <span>{item.contractorName}</span>
                                </div>
                            </div>
                            <div className='sectionCol'>
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/moneyWave.svg'} alt="AED" /></i>
                                <div>
                                    <span>{translate('totalpayment')}</span>
                                    <span>{item.totalAmount}</span>
                                </div>
                            </div>
                        </div>
                        <div className="sectionBlock">
                            <button onClick={this.acceptRejectPayment} id={"true"}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/odone.svg'} alt="approve" /> {translate('approve')} </button>
                            <button onClick={this.acceptRejectPayment} id={"false"}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/cancel.svg'} alt="Reject" /> {translate('Reject')} </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={15000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.showLoading ? <Loader /> : null}
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('contractor-payments-requests-widget-heading')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><span>{translate('contractor-payments-requests-widget-heading')}</span></div>
                        </div>
                        {this.state.item != null ? <div className="col-md-12">
                            <div className="service_contant_wrapper contractorPayment">
                                {this.state.item}
                            </div>
                        </div> :
                            <div className='contractorPaymentRejectionBox' style={{ display: '' }}>
                                <div className='paymentRejectionBox'>
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/payment_rejection_ico.svg'} alt="rejection" />
                                </div>
                                <div className='paymentRejectionBox'>
                                    <p>{this.state.responseMessage ? this.state.responseMessage : translate('nodata')}</p>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        )
    }
}
export default ContractorPaymentRequest