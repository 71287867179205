import React from 'react'
import ErrorComponent from '../utils/errorComponent'
import Loader from '../utils/LoaderBars'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../constants/dashboard-constants'

class Captcha extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            errors: [],
            error: null,
            showLoadinng: false,
            captchaInput: '',
            audioPayload: null,
            isVisualCaptcha: true,
            captchaSelectedIndex: -1,
        }
        this.captcha = this.captcha.bind(this)
        this.setCaptcha = this.setCaptcha.bind(this)
        this.captchaClick = this.captchaClick.bind(this)
        this.captchaInput = this.captchaInput.bind(this)
        this.failCallback = this.failCallback.bind(this)
        this.generateCaptcha = this.generateCaptcha.bind(this)
        this.validateCaptcha = this.validateCaptcha.bind(this)
        this.getCaptchaAudioUrl = this.getCaptchaAudioUrl.bind(this)
        this.captchaSetAudioUrl = this.captchaSetAudioUrl.bind(this)
        this.toggleVisualCaptcha = this.toggleVisualCaptcha.bind(this)
        this.validateAudioCaptcha = this.validateAudioCaptcha.bind(this)
        this.captchaValidateSuccess = this.captchaValidateSuccess.bind(this)
    }

    componentDidMount() {
        this.generateCaptcha()
    }

    componentDidUpdate() {

    }

    getSnapshotBeforeUpdate() {
        this.props.refresh && this.props.refresh ? this.generateCaptcha() : null
        return null
    }

    componentWillDidUpdate() {
        console.log("componentWillDidUpdate")
    }

    validateAudioCaptcha() {
        var url = CONSTANTS.validateAudioCaptchaEndpoint + "?local=" + this.context.state.siteLang + "&Id=" + this.state.audioPayload.audioId + "&answer=" + this.state.captchaInput
        fetch(url, {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then(this.captchaValidateSuccess).catch(this.failCallback)
    }

    generateCaptcha() {
        this.setState({ captchaSelectedIndex: -1, showLoadinng: true })
        this.props.toggleRefresh && this.props.toggleRefresh()
        var url = CONSTANTS.generateCaptchaEndpoint + "?local=" + this.context.state.siteLang + "&size=5"
        fetch(url, {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then(this.setCaptcha).catch(this.failCallback)
    }

    validateCaptcha() {
        var url = CONSTANTS.validateCaptchaEndpoint + "?local=" + this.context.state.siteLang + "&questionId=" + this.state.payload.question.id + "&imageKey=" + this.state.payload.images[this.state.captchaSelectedIndex].key
        fetch(url, {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then(this.captchaValidateSuccess).catch(this.failCallback)
    }

    captchaValidateSuccess(response) {
        if (response.success) {
            this.props.captchaValidationCallback(true, response.message)
        } else {
            this.props.captchaValidationCallback(false, response.message)
        }
    }

    setCaptcha(response) {
        if (response.success) {
            this.getCaptchaAudioUrl(response.payload.audioId)
            this.setState({ payload: response.payload, showLoadinng: false })
        }
    }

    getCaptchaAudioUrl(audioId) {
        var url = CONSTANTS.generateAudioCaptchaEndpoint + "/" + audioId + "?local=" + this.context.state.siteLang
        fetch(url, {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then(this.captchaSetAudioUrl).catch(this.failCallback)
    }

    captchaSetAudioUrl(response) {
        this.setState({ audioPayload: response.payload })
    }

    captchaClick(e) {
        this.setState({ captchaSelectedIndex: parseInt(e.target.id) }, () => {
            this.validateCaptcha()
        })
    }

    captcha(payload) {
        var captchaImages = []
        var selectedIndex = this.state.captchaSelectedIndex
        payload.images.map((image, index) => {
            captchaImages.push(
                <div key={index} className={selectedIndex !== -1 && selectedIndex === index ? "img captchaSelect" : "img"}>
                    <img id={index} name={image.key} src={image.url} alt={image.key} onClick={this.captchaClick} />
                </div>
            )
        })
        return captchaImages
    }

    failCallback(response) {
        if (response.status === 400) {
            var responseJson = JSON.parse(response.response)
            toast.error(responseJson.error_description)
        } else {
            toast.error(response.message)
        }
    }

    loadFailed(response) {
        this.setState({ error: <ErrorComponent message={response.message} closeCalback={this.closeCallback} /> })
    }

    toggleVisualCaptcha() {
        this.setState({ error: null, isVisualCaptcha: !this.state.isVisualCaptcha })
    }

    captchaInput(e) {
        const value = e.target.value
        this.setState({ captchaInput: value }, () => {
            this.validateAudioCaptcha()
        })
    }

    render() {
        return (
            <div className="container-fluid toWhomWrapper">
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.showLoadinng ? <Loader /> : null}
                {this.state.payload && this.state.payload.question ?
                    <div className="col-md-12">
                        <div className="captchaWrapper">
                            {this.state.isVisualCaptcha ?
                                <div className="imageWrapper">
                                    <div className="captchaImages">
                                        <p className="captchaQuestion">{translate("click_captcha")} <strong>{this.state.payload.question && this.state.payload.question.value}</strong></p>
                                        {this.state.payload && this.captcha(this.state.payload)}
                                    </div>
                                </div> :
                                <div className="voiceWrapper">
                                    <audio src={this.state.audioPayload && this.state.audioPayload.audioUrl} autoPlay />
                                    <label>{translate("enter_captcha")}</label>
                                    <input type="text" onChange={this.captchaInput} />
                                </div>}
                            <div className="buttonWrapper">
                                <button type="reset" onClick={this.generateCaptcha}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/captcha_refresh.svg'} alt="refresh" /></button>
                                {this.state.isVisualCaptcha ?
                                    <button type="reset" onClick={this.toggleVisualCaptcha}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/captcha_voice.svg'} alt="voice" /></button> :
                                    <button type="reset" onClick={this.toggleVisualCaptcha}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/captcha_img.svg'} alt="image" /></button>}
                            </div>
                        </div>
                    </div> : null}
            </div>
        )
    }
}
export default Captcha