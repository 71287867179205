import PdfViewConstant from './PdfViewConstant';
import BaseStore from '../../../BaseStore';

class PdfViewStore extends BaseStore {

  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._success = false;
    this._response = null;
    this._isUpdateMetaSuccess = false;
    this._isUpdateMetaResponse = '';
    this._uaePassAccessTokenResponse = '';
    this._uaePassProcees = '';
    this._uaePassFetchedDocument = '';
    this._uploadSignedDocumentResponse = '';
    this._uploadSignedDocumentMessage = '';
    this._uploadSignedDocumentSuccess = false;
    this._deleteSignedDocument = '';
    this._documentInformationSuccess = false;
    this._documentInformationResponsePayload = '';
    this._documentInformationResponse = '';
    this._documentInformationMessage = '';
    this._deleteSignedError = '';
    this._Mbrheresponse = '';
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case PdfViewConstant.VIEW_ATTACHMENT:
        this._success = action.response.success;
        this._response = action.response.payload;
        this.emitChange();
        break;

      case PdfViewConstant.DOWNLOAD_MBRHE_ATTACHMENT:
        this._Mbrheresponse = action.response;
        this.emitChange();
        break;

      case PdfViewConstant.UPDATE_META_DATA:
        this._isUpdateMetaSuccess = action.response.success;
        this._isUpdateMetaResponse = action.response.message;
        this.emitChange();
        break;

      case PdfViewConstant.UARPASS_ACCESS_TOKEN:
        this._uaePassAccessTokenResponse = action.response;
        this.emitChange();
        break;

      case PdfViewConstant.UARPASS_PROCESS:
        this._uaePassProcees = action.response;
        this.emitChange();
        break;

      case PdfViewConstant.FETCHED_SIGNED_DOCUMENT:
        this._uaePassFetchedDocument = action.response;
        this.emitChange();
        break;

      case PdfViewConstant.UPLOAD_SIGNED_DOCUMENT:
        this._uploadSignedDocumentResponse = action.response.payload;
        this._uploadSignedDocumentMessage = action.response.message;
        this._uploadSignedDocumentSuccess = action.response.success;
        this.emitChange();
        break;

      case PdfViewConstant.DELETE_SIGNED_DOCUMENT:
        this._deleteSignedDocument = action.response.status;
        this._deleteSignedError = action.response.error;
        this.emitChange();
        break;

      case PdfViewConstant.DOCUMENT_INFORMATION:
        this._documentInformationSuccess = action.response.success;
        this._documentInformationResponse = action.response;
        this._documentInformationResponsePayload = action.response.payload;
        this._documentInformationMessage = action.response.message;
        this.emitChange();
        break;

      default:
        break;
    };
  }
  isSuccess() {
    return this._success;
  }

  get response() {
    return this._response;
  }

  get mbrheresponse() {
    return this._Mbrheresponse;
  }

  isUpdateMetaSuccess() {
    return this._isUpdateMetaSuccess;
  }

  get updateMetaResponse() {
    return this._isUpdateMetaResponse;
  }

  get uaePassAccessTokenResponse() {
    return this._uaePassAccessTokenResponse;
  }

  get uaePassProcessResponse() {
    return this._uaePassProcees;
  }

  get uaePassFetchedDocumentResponse() {
    return this._uaePassFetchedDocument;
  }

  isUploadSignedDocSuccess() {
    return this._uploadSignedDocumentSuccess;
  }

  get uploadSignedDocMessage() {
    return this._uploadSignedDocumentMessage;
  }

  get uploadSignedDocResponse() {
    return this._uploadSignedDocumentResponse;
  }
  get deleteSignedDocument() {
    return this._deleteSignedDocument;
  }

  get deleteSignedError() {
    return this._deleteSignedError;
  }


  isDocumentInformationSuccess() {
    return this._documentInformationSuccess;
  }


  get documentInformationResponse() {
    return this._documentInformationResponse;
  }

  get documentInformationMessage() {
    return this._documentInformationMessage;
  }

}

export default new PdfViewStore();