import React from 'react'
import translate from '../../../Providers/i18n/Translate'
function FinancialStatusWidgetItem(props) {
    return (
        <div className="financialStatusWrapper">
            <div className="row">
                <div className="col-md-12 ">
                    <div className="financialStatusHead">
                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/FinancialStatus.svg'} alt="maintenance insurance" /></i>
                        <span>{translate('ApplicationNumber')} : </span>
                        <strong>{props.payload.applicationNo}</strong>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="financialStatusBody">
                        <div className="row">
                            <div className="col-md-12">
                                <span> {translate('ApplicationName')} : </span>
                                <span> {props.payload.applicationName} </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <span> {translate('startingBalance')} : </span>
                                <span> {props.payload.startingBalance} </span>
                            </div>
                            <div className="col-md-6">
                                <span> {translate('DateofLastBill')} : </span>
                                <span> {props.payload.dateOfLastBill} </span>
                            </div>
                            <div className="col-md-6">
                                <span> {translate('RemainingLoan')} : </span>
                                <span> {props.payload.remainingLoan} </span>
                            </div>
                            <div className="col-md-6">
                                <span> {translate('MaturityDate')} : </span>
                                <span> {props.payload.maturityDate} </span>
                            </div>
                            <div className="col-md-6">
                                <span> {translate('MonthlyPayment')} : </span>
                                <span> {props.payload.monthlyPayment} </span>
                            </div>
                            <div className="col-md-6">
                                <span> {translate('NextPaymentDate')} : </span>
                                <span> {props.payload.nextPaymentDate} </span>
                            </div>
                            <div className="col-md-6">
                                <span> {translate('OverdueAmount')} : </span>
                                <span> {props.payload.overdueAmount} </span>
                            </div>
                            <div className="col-md-6">
                                <span> {translate('EarlySettlement')} : </span>
                                <span> {props.payload.earlySettlement} </span>
                            </div>
                            <div className="col-md-6">
                                <span> {translate('Discount')} : </span>
                                <span> {props.payload.discount} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FinancialStatusWidgetItem