var BASE_URL = process.env.API_BASE_URL
var UAEPASS_BASE_URL = process.env.UAEPASS_BASE_URL
export default {
  BASE_URL: BASE_URL,
  VIEW_ATTACHMENT_URL: BASE_URL + 'UaePass/v1/DocumentUnSigned/attachment/view?documentId=',
  VIEW_ATTACHMENT: 'VIEW_ATTACHMENT',
  DOWNLOAD_MBRHE_ATTACHMENT_URL: BASE_URL + 'UaePass/v1/DocumentUnSigned/attachment/Download?documentId=',
  DOWNLOAD_MBRHE_ATTACHMENT_NEW_URL: BASE_URL + 'iskan/v1/smartApp/constructionLoan/attachments/download/',
  DOWNLOAD_MBRHE_ATTACHMENT: 'DOWNLOAD_MBRHE_ATTACHMENT',
  UPDATE_META_DATA_URL: BASE_URL + 'UaePass/v1/Document/UpdateMetaData?local=',
  UPDATE_META_DATA: 'UPDATE_META_DATA',
  UPLOAD_SIGNED_DOCUMENT_URL: BASE_URL + 'UaePass/v1/DocumentSigned/attach?local=',
  UPLOAD_SIGNED_DOCUMENT_URL_NEW: BASE_URL + 'iskan/v1/smartApp/constructionLoan/attachments/signattachment?local=',
  UPLOAD_SIGNED_DOCUMENT: 'UPLOAD_SIGNED_DOCUMENT',


  //UAEPASS Api's
  UARPASS_ACCESS_TOKEN_URL: UAEPASS_BASE_URL + 'uaepass-signature/api/v1.0/accessToken',
  UARPASS_ACCESS_TOKEN: 'UARPASS_ACCESS_TOKEN',

  UARPASS_PROCESS_URL: UAEPASS_BASE_URL + 'uaepass-signature/api/v1.0/signProcess',
  UARPASS_PROCESS: 'UARPASS_PROCESS',

  FETCHED_SIGNED_DOCUMENT_URL: UAEPASS_BASE_URL + 'uaepass-signature/api/v1.0/documentId/',
  FETCHED_SIGNED_DOCUMENT: 'FETCHED_SIGNED_DOCUMENT',

  DELETE_SIGNED_DOCUMENT_URL: UAEPASS_BASE_URL + 'uaepass-signature/api/v1.0/processId/',
  DELETE_SIGNED_DOCUMENT: 'DELETE_SIGNED_DOCUMENT',

  DOCUMENT_INFORMATION_URL: UAEPASS_BASE_URL + 'uaepass-signature/api/v1.0/verifyDocument',
  DOCUMENT_INFORMATION: 'DOCUMENT_INFORMATION',

  UPLOAD_SIGN_PROCESS_DETAILS_ENDPOINT: UAEPASS_BASE_URL + 'UaePass/v1/DocumentUnSigned/UpdateProcess'
}