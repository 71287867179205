import React from 'react'
import translate from '../../../Providers/i18n/Translate'

function MaintenanceInsuranceItem(props) {
    return (
        <div className="maintenanceInsuranceWrapper">
            <h4><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/maintenanc.svg'} alt="no data" /></i>{translate('requestNo')}: {props.payload && props.payload.id}</h4>
            <div className="row">
                <div className="col-sm-6">
                    <div className="maintenanceInsuranceItem">{translate('ContactPersonName')}: <span>{props.payload && props.payload.contactPerson}</span></div>
                    <div className="maintenanceInsuranceItem">{translate('mobileNo')}: <span>{props.payload && props.payload.mobileNo}</span></div>
                    <div className="maintenanceInsuranceItem">{translate('granteePeriod')}: <span>{props.payload && props.payload.granteePeriod}</span></div>
                </div>
                <div className="col-sm-6">
                    <div className="maintenanceInsuranceItem">{translate('OfficePhoneNo')}: <span>{props.payload && props.payload.officeNo}</span></div>
                    <div className="maintenanceInsuranceItem">{translate('supplier')}: <span>{props.payload && props.payload.supplier}</span></div>
                </div>
            </div>
        </div>
    )
}
export default MaintenanceInsuranceItem

// <div className="maintenanceInsuranceItem">Description: <span>{props.payload.description}</span></div>