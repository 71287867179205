import React from "react"
import NumericInput from 'react-numeric-input'
import { AppContext } from '../../Providers/context'
import translate from "../../Providers/i18n/Translate"

class SocialInformation extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.next = this.next.bind(this)
        this.validate = this.validate.bind(this)
        this.onChange = this.onChange.bind(this)
        this.SingleFemaleChildrenInHouseChange = this.SingleFemaleChildrenInHouseChange.bind(this)
        this.SingleMaleChildrenInHouseChange = this.SingleMaleChildrenInHouseChange.bind(this)
        this.MarriedFemaleChildrenInHouseChange = this.MarriedFemaleChildrenInHouseChange.bind(this)
        this.MarriedMaleChildrenInHouseChange = this.MarriedMaleChildrenInHouseChange.bind(this)
        this.SeniorFemaleChildrenInHouseChange = this.SeniorFemaleChildrenInHouseChange.bind(this)
        this.SeniorMaleChildrenInHouseChange = this.SeniorMaleChildrenInHouseChange.bind(this)
        this.HandicapFemaleChildrenInHouseChange = this.HandicapFemaleChildrenInHouseChange.bind(this)
        this.HandicapMaleChildrenInHouseChange = this.HandicapMaleChildrenInHouseChange.bind(this)
    }

    SingleFemaleChildrenInHouseChange(value) {
        this.props.reference.reset()
        this.props.reference.updateSocialStudyPayload("singleFemaleChildrenInHouse", value, 2)
    }

    SingleMaleChildrenInHouseChange(value) {
        this.props.reference.reset()
        this.props.reference.updateSocialStudyPayload("singleMaleChildrenInHouse", value, 2)
    }

    MarriedFemaleChildrenInHouseChange(value) {
        this.props.reference.reset()
        this.props.reference.updateSocialStudyPayload("marriedFemaleChildrenInHouse", value, 2)
    }

    MarriedMaleChildrenInHouseChange(value) {
        this.props.reference.reset()
        this.props.reference.updateSocialStudyPayload("marriedMaleChildrenInHouse", value, 2)
    }

    SeniorFemaleChildrenInHouseChange(value) {
        this.props.reference.reset()
        this.props.reference.updateSocialStudyPayload("seniorFemaleChildrenInHouse", value, 2)
    }

    SeniorMaleChildrenInHouseChange(value) {
        this.props.reference.reset()
        this.props.reference.updateSocialStudyPayload("seniorMaleChildrenInHouse", value, 2)
    }

    HandicapFemaleChildrenInHouseChange(value) {
        this.props.reference.reset()
        this.props.reference.updateSocialStudyPayload("handicapFemaleChildrenInHouse", value, 2)
    }

    HandicapMaleChildrenInHouseChange(value) {
        this.props.reference.reset()
        this.props.reference.updateSocialStudyPayload("handicapMaleChildrenInHouse", value, 2)
    }

    onChange(e) {
        this.props.reference.updateSocialStudyPayload("isHousePreparedForHandicap", e.target.value.toString().toLowerCase().trim() === 'true' ? 1 : 0, 2)
    }

    validate() {
        return this.props.reference.state.socialStudyPayload.singleFemaleChildrenInHouse >= 0 &&
            this.props.reference.state.socialStudyPayload.singleMaleChildrenInHouse >= 0 &&
            this.props.reference.state.socialStudyPayload.marriedFemaleChildrenInHouse >= 0 &&
            this.props.reference.state.socialStudyPayload.marriedMaleChildrenInHouse >= 0 &&
            this.props.reference.state.socialStudyPayload.seniorFemaleChildrenInHouse >= 0 &&
            this.props.reference.state.socialStudyPayload.seniorMaleChildrenInHouse >= 0 &&
            this.props.reference.state.socialStudyPayload.handicapFemaleChildrenInHouse >= 0 &&
            this.props.reference.state.socialStudyPayload.handicapMaleChildrenInHouse >= 0 &&
            this.props.reference.state.socialStudyPayload.isHousePreparedForHandicap !== null ? true : false
    }

    next() {
        if (this.validate()) {
            this.props.reference.updateSocialStudyPayload("ticketNo", localStorage.getItem("ticket"))
            this.props.reference.postSocialStudy(1)
        } else {
            this.props.reference.showError(translate('requiredErrorMessage'))
        }
    }

    render() {
        return (
            <div className="col">
                <div className="formSection row">
                    <div className="numericInputWrapper">
                        <div className="row">
                            <div className="col-lg-6 numericInputSet">
                                <label>{translate("NumberOfSingleFamilyMembersInHouse")}<span className="redStar">*</span></label>
                                <div className="NumericBox">
                                    <label>
                                        <span>{translate('females')}</span>
                                        <NumericInput min={0} mobile value={this.props.reference.state.socialStudyPayload.singleFemaleChildrenInHouse} onChange={this.SingleFemaleChildrenInHouseChange} />
                                    </label>
                                </div>
                                <div className="NumericBox">
                                    <label>
                                        <span>{translate('males')}</span>
                                        <NumericInput min={0} mobile value={this.props.reference.state.socialStudyPayload.singleMaleChildrenInHouse} onChange={this.SingleMaleChildrenInHouseChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-6 numericInputSet">
                                <label>{translate("NumbeFfMarriedFamilyMembersInHouse")}<span className="redStar">*</span></label>
                                <div className="NumericBox">
                                    <label>
                                        <span>{translate('females')}</span>
                                        <NumericInput min={0} mobile value={this.props.reference.state.socialStudyPayload.marriedFemaleChildrenInHouse} onChange={this.MarriedFemaleChildrenInHouseChange} />
                                    </label>
                                </div>
                                <div className="NumericBox">
                                    <label>
                                        <span>{translate('males')}</span>
                                        <NumericInput min={0} mobile value={this.props.reference.state.socialStudyPayload.marriedMaleChildrenInHouse} onChange={this.MarriedMaleChildrenInHouseChange} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 numericInputSet">
                                <label>{translate("NumberOfElderyFamilyMembers60Plus")}<span className="redStar">*</span></label>
                                <div className="NumericBox">
                                    <label>
                                        <span>{translate('females')}</span>
                                        <NumericInput min={0} mobile value={this.props.reference.state.socialStudyPayload.seniorFemaleChildrenInHouse} onChange={this.SeniorFemaleChildrenInHouseChange} />
                                    </label>
                                </div>
                                <div className="NumericBox">
                                    <label>
                                        <span>{translate('males')}</span>
                                        <NumericInput min={0} mobile value={this.props.reference.state.socialStudyPayload.seniorMaleChildrenInHouse} onChange={this.SeniorMaleChildrenInHouseChange} />
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-6 numericInputSet">
                                <label>{translate("NumberOfPeopleDeterminationInFamily")}<span className="redStar">*</span></label>
                                <div className="NumericBox">
                                    <label>
                                        <span>{translate('females')}</span>
                                        <NumericInput min={0} mobile value={this.props.reference.state.socialStudyPayload.handicapFemaleChildrenInHouse} onChange={this.HandicapFemaleChildrenInHouseChange} />
                                    </label>
                                </div>
                                <div className="NumericBox">
                                    <label>
                                        <span>{translate('males')}</span>
                                        <NumericInput min={0} mobile value={this.props.reference.state.socialStudyPayload.handicapMaleChildrenInHouse} onChange={this.HandicapMaleChildrenInHouseChange} />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 customRadio">
                                <p>{translate('IsHouseWellequippedForPeopleOfDeterminationNeed')}<span className="redStar">*</span></p>
                                <input type="radio" id="PeopleDeterminationyes" value={true} checked={this.props.reference.state.socialStudyPayload.isHousePreparedForHandicap === 1} onChange={this.onChange} />
                                <label htmlFor="PeopleDeterminationyes">{translate("yes")}</label>
                                <input type="radio" id="PeopleDeterminationno" value={false} checked={this.props.reference.state.socialStudyPayload.isHousePreparedForHandicap === 0} onChange={this.onChange} />
                                <label htmlFor="PeopleDeterminationno">{translate("no")}</label>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="col-md-12 formSectionBtn ">
                            <button className="stepNextBtn" onClick={this.next}>{translate('next')}</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default SocialInformation