import React from 'react'
import history from '../../../History'
import NoData from '../../utils/nodata'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'

class ComplementaryServicesWidget extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            services: []
        }
        this.serviceClick = this.serviceClick.bind(this)
        this.getServices = this.getServices.bind(this)
        this.setServices = this.setServices.bind(this)
        this.prepareTypicalVillas = this.prepareTypicalVillas.bind(this)
        this.setApplications = this.setApplications.bind(this)
        this.getTypicalVillasApplications = this.getTypicalVillasApplications.bind(this)
    }

    componentDidMount() {
        this.getServices()
    }

    getServices() {
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.complementaryServicesEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setServices).catch();
        }
    }

    setServices(response) {
        var servicesArray = []
        if (response.payload && response.payload.length > 0) {
            response.payload.map((service) => {
                servicesArray.push(<ServiceItem key={service.id} payload={service} serviceClick={this.serviceClick} />)
            })
        }
        this.setState({ services: servicesArray })
    }

    serviceClick(e) {
        let id = e.currentTarget.id
        let code = e.currentTarget.getAttribute("code")
        var applicationStatus = 0
        if (id === "82d04156-878c-461b-ba6e-3796a3642ecb") {
            history.push("/changeServiceTicketsListing")
        } else if (id === "980fd1e9-2ce9-484d-9855-5d1cdcdc258b" || id === "c086c45d-118f-4a54-a1f5-5d0ca221739c") {
            localStorage.setItem("disbursementTypeID", code)
            history.push({ pathname: '/constructionLoanDisbursementTickets', state: { serviceID: id } })
        } else if (id === "3efef2a4-be61-46e7-b3a5-6cf9fd24cb8a") {
            this.prepareTypicalVillas()
        } else {
            if (id === "0bcc7958-3e82-444b-ba7f-643bec2ba3e5") {
                applicationStatus = 15
            } else if (id === "22d5e68a-2a03-4fca-82c8-52dab2e0a8a6") {
                applicationStatus = 5
            }
            history.push({ pathname: '/allowedToCancelApplicationsListing', state: { applicationStatus: applicationStatus } })
        }
    }

    prepareTypicalVillas(){
        this.getTypicalVillasApplications()
    }

    getTypicalVillasApplications() {
        if (CONSTANTS.authToken) {
            fetch(CONSTANTS.typicalVillasApplicationsEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplications).catch(() => { this.setState({ isLoading: false }) });
        }
    }

    setApplications(response) {
        this.setState({ isLoading: false })
        var items = response.payload
        if (items.length > 0) {
            if (items.length > 1) {
                localStorage.setItem("applicationsPayload", JSON.stringify(response))
                localStorage.removeItem("applicationPayload")
                history.push({ pathname: '/villaApplications' })
            } else {
                localStorage.setItem("applicationPayload", JSON.stringify(response.payload[0]))
                history.push({ pathname: '/typicalVillas' })
            }
        }
    }

    render() {
        return (
            <div className="col-md-12 complementaryServicesWidget">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/complementary_services_icon.svg'} alt="Housing Request" /></span>
                        <span>{translate('complementryServices')}</span>
                    </div>
                    <div className="widgetContant">
                        <div className="widgetContentIn">
                            {this.state.services.length > 0 ? this.state.services : <NoData message={translate('nodata')} />}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

function ServiceItem(props) {
    return (
        <div id={props.payload.id} code={props.payload.code} onClick={props.serviceClick} className="complementaryServicesBox">
            <div className="widgetServiceBox">
                <span>
                    <div>
                        <div className="widgetService_icon">
                            {props.payload.id === '82d04156-878c-461b-ba6e-3796a3642ecb' ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/change_request_icon.svg" alt={props.payload.name} /> : null}
                            {props.payload.id === '22d5e68a-2a03-4fca-82c8-52dab2e0a8a6' ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/cancel_progress_application.svg" alt={props.payload.name} /> : null}
                            {props.payload.id === '0bcc7958-3e82-444b-ba7f-643bec2ba3e5' ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/approved_cancel.svg" alt={props.payload.name} /> : null}
                            {props.payload.id === '980fd1e9-2ce9-484d-9855-5d1cdcdc258b' ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/construction_disbursement.svg" alt={props.payload.name} /> : null}
                            {props.payload.id === '3efef2a4-be61-46e7-b3a5-6cf9fd24cb8a' ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/typicalvillas.svg" alt={props.payload.name} /> : null}
                            {props.payload.id === 'c086c45d-118f-4a54-a1f5-5d0ca221739c' ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/maintenance_loan_disbursement_icon.svg" alt={props.payload.name} /> : null}
                        </div>
                        <div className="widgetServiceName">
                            <span>{props.payload.name}</span>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    )
}
export default ComplementaryServicesWidget