import React from 'react'
import Loader from '../utils/LoaderBars'
import { LOCALES } from '../../Providers/i18n'
import { ScrollToTop } from '../../utils/Scroll'
import { AppContext } from '../../Providers/context'
import InputComponent from '../utils/inputComponent'
import ErrorComponent from '../utils/errorComponent'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import TypicalVillasUtils from './typical_villas_utils'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class TypicalVillasSubmitComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            errors: [],
            error: null,
            termsAccept: false,
            acceptClick: false,
            showLoading: false,
            villaID: localStorage.getItem('villaID'),
            styleID: localStorage.getItem('styleID'),
            villaPayload: {},
            applicationPayload: JSON.parse(localStorage.getItem('applicationPayload')),
            requestPayload: JSON.parse(localStorage.getItem('villaResponse')),
            startingPrice: localStorage.getItem('startingPrice'),
            email: JSON.parse(localStorage.getItem('dashboard')).payload.email,
            mobileNo: JSON.parse(localStorage.getItem('dashboard')).payload.primaryMobileNo,
            maritalStatus: JSON.parse(localStorage.getItem('dashboard')).payload.maritalStatus,
        }
        this.reset = this.reset.bind(this)
        this.addError = this.addError.bind(this)
        this.showError = this.showError.bind(this)
        this.preLoadData = this.preLoadData.bind(this)
        this.acceptClick = this.acceptClick.bind(this)
        this.removeError = this.removeError.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.submitRequest = this.submitRequest.bind(this)
        this.validateErrors = this.validateErrors.bind(this)
        this.generateTicket = this.generateTicket.bind(this)
        this.setTicketResults = this.setTicketResults.bind(this)
        this.prepareRequestBody = this.prepareRequestBody.bind(this)
        // this.setApplicationStatus = this.setApplicationStatus.bind(this)
        // this.fetchApplicationStatus = this.fetchApplicationStatus.bind(this)
    }

    componentDidMount() {
        ScrollToTop()
        this.setState({ villaPayload: TypicalVillasUtils.getVilla(Number(localStorage.getItem('villaID'))) })
        this.preLoadData()
    }

    preLoadData() {
        if (this.state.applicationPayload.request[0]) {
            var request = this.state.applicationPayload.request[0];
            this.setState({ email: request.email, mobileNo: request.mobileNo, DeterminationStatus: request.determinationStatus, determination: request.determinationStatus ? 'true' : 'false', areyousick: request.typeOfSickness ? 'true' : 'false', typeofsickness: request.typeOfSickness, landNumber: request.plotNo, amountPaidBy: request.paymentMethod })
        } else if (this.state.requestPayload) {
            var request = this.state.applicationPayload.request[0];
            this.setState({ email: request.email, mobileNo: request.mobileNo, DeterminationStatus: request.determinationStatus, determination: request.determinationStatus ? 'true' : 'false', areyousick: request.typeOfSickness ? 'true' : 'false', typeofsickness: request.typeOfSickness, landNumber: request.plotNo, amountPaidBy: request.paymentMethod })
        }
    }

    closeCallback() {
        this.setState({ error: null })
    }

    acceptClick(e) {
        this.closeCallback()
        if (e.target.id === "accept") {
            this.setState({ errorMessage: "", acceptClick: !this.state.acceptClick, termsAccept: true }, () => { })
        }
        else {
            if (e.target.id === "acceptCB") {
                if (e.target.checked) {
                    this.setState({ acceptClick: !this.state.acceptClick, termsAccept: false }, () => { })
                } else {
                    this.setState({ termsAccept: false })
                }
            } else {
                this.setState({ acceptClick: !this.state.acceptClick, termsAccept: false }, () => { })
            }
        }
    }

    handleChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (isValid) { this.removeError(name) } else if (e.target.required) { this.addError(name) }
        if (name === 'determination') {
            if (value === 'true') {
                this.setState({ 'DeterminationStatus': 1 })
            } else {
                this.setState({ 'DeterminationStatus': 0 })
            }
        } else if (name === 'amountPaidBy') {
            if (value === 'true') {
                this.setState({ 'amountPaidBy': 1 })
            } else {
                this.setState({ 'amountPaidBy': 2 })
            }
        } else if (name === 'areyousick') {
            if (value === 'true') {
                this.addError("typeofsickness")
            } else {
                this.setState({ typeofsickness: null })
                this.removeError("typeofsickness")
            }
        }
    }

    reset() {
        this.setState({ error: null, errorMessage: '' })
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    validateErrors() {
        if (this.state.errors.length > 0) {
            return false
        } else {
            if (this.state.email && this.state.mobileNo && this.state.determination && this.state.amountPaidBy && this.state.areyousick && this.state.landNumber) {
                return this.state.areyousick === 'true' ? (this.state.typeofsickness !== undefined && this.state.typeofsickness.length > 0) : true
            } else {
                return false
            }
        }
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    submitRequest() {
        if (this.validateErrors()) {
            if (!this.state.termsAccept) {
                this.setState({ error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
            } else {
                this.generateTicket()
            }
        } else {
            this.showError(translate("requiredErrorMessage"))
        }
    }

    generateTicket() {
        this.reset()
        if (this.validateErrors()) {
            this.setState({ showLoading: true }, () => {
                fetch(CONSTANTS.typicalVillasSubmitApiEndpoint + "?local=" + this.context.state.siteLang, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'Authorization': CONSTANTS.authToken
                    },
                    body: this.prepareRequestBody()
                }).then(response => response.json()).then(this.setTicketResults).catch(this.loadFailed)
            })
        }
    }

    prepareRequestBody() {
        var params = new URLSearchParams()
        params.set('styleId', this.state.styleID > 0 ? this.state.styleID : this.state.villaPayload.styles[0].id);
        params.set('DiffPrice', 2);
        params.set('landNumber', this.state.landNumber);
        params.set('MartialStatus', this.state.maritalStatus);
        params.set('startingPrice', this.state.villaPayload.startingPrice.replace(' (AED)', '').replaceAll(',', ''));
        params.set('PaymentMethod', this.state.amountPaidBy);
        params.set('EmailAddress', this.state.email);
        params.set('MobileNo', this.state.mobileNo);
        params.set('villaId', this.state.villaID);
        params.set('applicationNo', this.state.applicationPayload.applicationNo);
        params.set('DeterminationStatus', this.state.DeterminationStatus);
        params.set('IsHumatAlWatn', this.state.IsHumatAlWatn ? this.state.IsHumatAlWatn : false);
        params.set('TypeOfSickness', this.state.typeofsickness ? this.state.typeofsickness : '');
        return params
    }

    setTicketResults(response) {
        this.setState({ showLoading: false })
        if (response.success) {
            toast.success(response.message)
            this.setState({ response: response })
            localStorage.setItem('villaResponse', JSON.stringify(response.payload))
            localStorage.setItem('requestId', response.payload.requestId)
        } else {
            toast.error(response.message)
        }
    }

    // fetchApplicationStatus() {
    //     if (CONSTANTS.authToken.length > 15) {
    //         this.setState({ showLoading: true })
    //         fetch(CONSTANTS.typicalVillasApplicationsEndpoint + "?local=" + this.context.state.siteLang, {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': CONSTANTS.authToken
    //             }
    //         }).then(response => response.json()).then(this.setApplicationStatus).catch(this.loadFailed);
    //     }
    // }

    // setApplicationStatus(response) {
    //     if (response.success) {
    //         var items = response.payload
    //         var applicationPayload = items[0]
    //         this.setState({ applicationPayload: applicationPayload }, () => {
    //             this.setState({ showLoading: false })
    //         })
    //     } else {
    //         this.setState({ showLoading: false })
    //         toast.error(response.message)
    //     }
    // }

    render() {
        var request = this.state.applicationPayload.request[0];
        return (
            <div className="container-fluid newStyleWrapper">
                {this.state.showLoading ? <Loader /> : null}
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.response && this.state.response.success ? <div className="PopupBk"></div> : null}
                {this.state.response && this.state.response.success ? <div className='success_message_wrapper textcenter'>
                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/submittedSuccessfully.svg'} alt="img" />
                    <h4>{translate('requestSuccess')}</h4>
                    <div className='buttonWrapper'>
                        <button className='primary_button' onClick={() => { this.props.history.replace('/typicalVillasReview') }}>{translate('viewRequest')}</button>
                        {/* <button className='secondary_button' onClick={() => { this.props.history.push('/typicalVillas') }}>{translate('backToHome')}</button> */}
                        <button className='secondary_button' onClick={() => { this.props.history.push('/customerDashboard') }}>{translate('backToHome')}</button>
                    </div>
                </div> : null}
                {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                {this.state.acceptClick ? <div className="acceptPop">
                    <button className="popupClose" onClick={this.acceptClick}>X</button>
                    <h2>{translate('TermsAndConditions')}</h2>
                    <p>{translate('eligibleterms')}</p>
                    <div className="col-md-12 acceptPopBtn">
                        <div className="row">
                            <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
                        </div>
                    </div>
                </div> : null}
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item"><a href="/typicalVillas">{translate('typicalvillas')}</a></li>
                                    <li className="breadcrumb-item"><a href="/typicalVillasDetail">{translate('villadetails')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('serviceform')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <span>{translate('typicalvillas')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection typicalVillasSubmitFormWrapper">
                                <div className="row">
                                    <div className="col-md-6">
                                        <strong> {translate('applicationNo')} : </strong>
                                        <span> {this.state.applicationPayload && this.state.applicationPayload.applicationNo} </span>
                                    </div>
                                    <div className="col-md-6">
                                        <strong> {translate('applicationDate')} : </strong>
                                        <span> {this.state.applicationPayload && this.state.applicationPayload.createdDate} </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <strong> {translate('applucationStatus_ServiceType')} : </strong>
                                        <span> {this.state.applicationPayload && this.state.applicationPayload.serviceName} </span>
                                    </div>
                                    <div className="col-md-6">
                                        <strong> {translate('ApplicationStatus')} : </strong>
                                        <span> {this.state.applicationPayload && this.state.applicationPayload.status} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row"></div>
                                <div className="row">
                                    <InputComponent inputProps={{ lable: translate('email'), name: 'email', isRequired: true, disabled: false, value: this.state.email, type: 'email', placeHolder: '', changeListener: this.handleChange }} />
                                    <InputComponent inputProps={{ lable: translate('PrimaryMobileNo'), name: 'mobileNo', isRequired: true, disabled: false, value: this.state.mobileNo, type: 'phone', placeHolder: '', changeListener: this.handleChange }} />
                                    <div className="col-md-6 customRadio fixedLabel">
                                        {/* <div className="emptySpace"></div> */}
                                        <p>{translate('IsFromSpecialNeeds')}<span className="redStar">*</span></p>
                                        <input type="radio" id="specialneedyes" name="determination" value={true} defaultChecked={request ? (request.determinationStatus ? true : false) : false} onChange={this.handleChange} />
                                        <label htmlFor="specialneedyes">{translate("yes")}</label>
                                        <input type="radio" id="specialneedno" name="determination" value={false} defaultChecked={request ? (request.determinationStatus ? false : true) : false} onChange={this.handleChange} />
                                        <label htmlFor="specialneedno">{translate("no")}</label>
                                    </div>
                                    {this.state.determination === 'true' ? <div className='col-md-6'>
                                        {this.context.state.siteLang === LOCALES.ENGLISH ?
                                            <select className='inputBottomMargin' name="DeterminationStatus" value={this.state.DeterminationStatus} onChange={this.handleChange}>
                                                <option value="1">Mental</option>
                                                <option value="2">Physical</option>
                                            </select> :
                                            <select name="DeterminationStatus" value={this.state.DeterminationStatus} onChange={this.handleChange}>
                                                <option value="1">ذهني</option>
                                                <option value="2">جسدي</option>
                                            </select>
                                        }</div>
                                        : null}
                                    <div className="col-md-12 customRadio fixedLabel">
                                        <div className="emptySpace"></div>
                                        <p>{translate('homatalwatn')}<span className="redStar">*</span></p>
                                        <input type="radio" id="homatalwatnyes" name="IsHumatAlWatn" value={true} defaultChecked={request ? request.isHumatAlWatn : false} onChange={this.handleChange} />
                                        <label htmlFor="homatalwatnyes">{translate("yes")}</label>
                                        <input type="radio" id="homatalwatnno" name="IsHumatAlWatn" value={false} defaultChecked={request ? !request.isHumatAlWatn : false} onChange={this.handleChange} />
                                        <label htmlFor="homatalwatnno">{translate("no")}</label>
                                    </div>
                                    <div className="col-md-6 customRadio fixedLabel">
                                        <div className="emptySpace"></div>
                                        <p>{translate('areyousick')}<span className="redStar">*</span></p>
                                        <input type="radio" id="areyousickyes" name="areyousick" value={true} defaultChecked={request ? (request.typeOfSickness ? true : false) : false} onChange={this.handleChange} />
                                        <label htmlFor="areyousickyes">{translate("yes")}</label>
                                        <input type="radio" id="areyousickno" name="areyousick" value={false} defaultChecked={request ? (request.typeOfSickness ? false : true) : false} onChange={this.handleChange} />
                                        <label htmlFor="areyousickno">{translate("no")}</label>
                                    </div>
                                    {this.state.areyousick === 'true' ?
                                        <InputComponent inputProps={{ class: 'col-md-6 inputBottomMargin', lable: translate('typeofsickness'), name: 'typeofsickness', isRequired: true, disabled: false, value: this.state.typeofsickness, type: 'text', placeHolder: '', changeListener: this.handleChange }} />
                                        : null}
                                    <div className="col-md-12 customRadio fixedLabel">
                                        <div className="emptySpace"></div>
                                        <p>{translate('amountpaidby')}<span className="redStar">*</span></p>
                                        <input type="radio" id="amountpaidbycash" name="amountPaidBy" value={true} defaultChecked={request ? (request.paymentMethod ? (request.paymentMethod === 1 ? true : false) : false) : false} onChange={this.handleChange} />
                                        <label htmlFor="amountpaidbycash">{translate("cash")}</label>
                                        <input type="radio" id="amountpaidbybank" name="amountPaidBy" value={false} defaultChecked={request ? (request.paymentMethod ? (request.paymentMethod === 2 ? true : false) : false) : false} onChange={this.handleChange} />
                                        <label htmlFor="amountpaidbybank">{translate("bank")}</label>
                                    </div>
                                    <InputComponent inputProps={{ maxLength: 20, lable: translate('LandNo'), name: 'landNumber', isRequired: true, disabled: false, value: this.state.landNumber, type: 'text', placeHolder: '', changeListener: this.handleChange }} />
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="listNote">
                                            <li>{translate('typicalvillasnoteone')}</li>
                                            <li>{translate('typicalvillasnotetwo')}</li>
                                            <li>{translate('typicalvillasnotethree')}</li>
                                            <li>{translate('typicalvillasnotefour')}</li>
                                            <li>{translate('typicalvillasnotefive')}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 temsAcceptWrapper">
                                        <hr></hr>
                                        <div className="customCheckBox">
                                            <input name='termsAccept' checked={this.state.termsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
                                            <label htmlFor="acceptCB">{translate('IAcceptingThe')}</label>
                                        </div>
                                        <button className="primary_button" onClick={this.submitRequest}>{translate('submitrequest')}</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.error}
                            {this.state.errorMessage}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AuthenticatedComponent(TypicalVillasSubmitComponent);