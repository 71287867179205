import React from 'react'
import '../../reactToastify.css'
import InputComponent from '../utils/inputComponent'
import ErrorComponent from '../utils/errorComponent'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class OverdueComponent extends React.Component {
    static contextType = AppContext
    constructor() {
        super()
        this.state = {
            error: '',
            applicationnumber: '',
            mpayaccount: '',
            mobileNo: '',
            amount: '',
            errors: ['applicationNo', 'mPayAccount', 'mobileNo', 'amount']
        }
        this.showError = this.showError.bind(this)
        this.reset = this.reset.bind(this)
        this.removeError = this.removeError.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleMobileNoChange = this.handleMobileNoChange.bind(this)
        this.paymentRequest = this.paymentRequest.bind(this)
        this.requestOverduePayment = this.requestOverduePayment.bind(this)
        this.overduePaymentRequestSuccess = this.overduePaymentRequestSuccess.bind(this)
        this.failCallback = this.failCallback.bind(this)
    }

    componentDidMount() {
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleMobileNoChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (isValid) {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    reset() {
        this.setState({ error: null })
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.reset} /> })
    }

    paymentRequest(e) {
        if (this.state.errors.length > 0) {
            this.showError(translate('requiredErrorMessage'))
        } else {
            this.requestOverduePayment()
            this.showError("Form is valid")
        }
    }

    requestOverduePayment() {
        var token = localStorage.getItem('accessToken')
        if (token) {
            var url = CONSTANTS.overduePaymentsRegisterEndpoint + "?local=" + this.context.state.siteLang
            fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.overduePaymentRequestSuccess).catch(this.failCallback)
        }
    }

    overduePaymentRequestSuccess(response) {
        // this.showError(response)
        console.log(response)
    }

    failCallback(response) {
        console.log(response)
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={2000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('Overduepayment')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/FinancialStatus.svg'} alt="Overdue payment" /></i><span>{translate('Overduepayment')}</span></div>
                        </div>
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <form>
                                    <div className="row">
                                        <InputComponent inputProps={{ lable: translate('applicationNo'), name: 'applicationNo', defaultValue: this.state.emiratesId, type: 'number', changeListener: this.handleMobileNoChange, isRequired: true }} />
                                        <InputComponent inputProps={{ lable: translate('mPayAccount'), name: 'mPayAccount', defaultValue: this.state.name, type: 'text', changeListener: this.handleMobileNoChange, isRequired: true }} />
                                    </div>
                                    <div className="row">
                                        <InputComponent inputProps={{ lable: translate('mobileNo'), name: 'mobileNo', defaultValue: this.state.idbarahnumber, placeHolder: '', type: 'phone', changeListener: this.handleMobileNoChange, isRequired: true }} />
                                        <InputComponent inputProps={{ lable: translate('Amount'), name: 'amount', defaultValue: this.state.email, type: 'number', changeListener: this.handleMobileNoChange, isRequired: true }} />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="ePaymentBox">
                                <h2>{translate("Epayment")}<i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/pay.svg'} alt="Pay" /></i></h2>
                                <p>{translate("Epayment_message")}</p>
                                <button onClick={this.paymentRequest}>{translate("Continue")}</button>
                            </div>
                        </div>
                    </div>
                    {this.state.error}
                </div>
            </div>
        )
    }
}

export default AuthenticatedComponent(OverdueComponent)