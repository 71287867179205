import React from "react";
import queryString from 'query-string';
import LoadingBar from 'react-top-loading-bar'
import DocumentVerificationStore from './DocumentVerificationStore';
import DocumentVerificationService from './DocumentVerificationService';


class QRDocumentVerificationComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            response: "",
            isSuccess: false,
            referenceNo: ''
        }
    }
    componentDidMount() {
        this.getDocumentviaQueryString();
        this._onChange = this._onChange.bind(this);
        DocumentVerificationStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        DocumentVerificationStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getVerifyResponse());
        this.LoadingBar && this.LoadingBar.complete()
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        // console.log("Error logging in : ", errorDescription);
    }

    getVerifyResponse() {   
        return {
            response: DocumentVerificationStore.response,
            isSuccess: DocumentVerificationStore.isSuccess(),
            message: DocumentVerificationStore.message,
        };
    }

    getDocumentviaQueryString() {
        let url = this.props.location.search;
        if (url !== "") {
            let value = queryString.parse(url);
            if (value.referenceNo !== "") {
                this.setState({ referenceNo: value.referenceNo })
                this.LoadingBar && this.LoadingBar.continuousStart()
                DocumentVerificationService.verifyDocumentById(value.referenceNo)
                .catch(function (error) {
                    this.catchBody(error);
                }.bind(this));
            }
        }
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        // console.log("Error logging in : ", errorDescription);
    }

    render() {
        const {response, isSuccess, message} = this.state
        return (
            <div className="container-fluid">
              <LoadingBar className='loagingbar' height={0} color='#0091ff' onRef={ref => (this.LoadingBar = ref)} /> 
                <div className="container-fluid">
                    <div className="container mainSection">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="docVerifiResultWrapper">
                                    {!isSuccess &&  message != "" ? <div className="noDatadocVerifiResult" style={{display:''}}>
                                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/No_data_icon.svg'} alt="No data"/>
                                    {message ? <span>{message}</span>: <span>No Available Documents…</span>} 
                                    </div> :   
                                        null}
                                    {response ?  
                                    <object data={response.pdfUrl} type="application/pdf" scrolling="no" allowfullscreen=""><p></p>
                                    <iframe src={response.pdfUrl} scrolling="no" allowfullscreen="">
                                        <p>Your browser does not support PDFs.
                                        <a href={response.pdfUrl}>Download the PDF</a>.</p>
                                    </iframe>
                                    </object>
                                    : null}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default QRDocumentVerificationComponent;