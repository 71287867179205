import React from 'react'
import translate from '../Providers/i18n/Translate'

function LeavingWeb(props) {
    return (
        <div>
            {console.log(props)}
            <div className="PopupBk">
                <div className="viewPopup">
                    <div className="popupContant leaveWeb">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>{translate('ThisExternalLink')}</h2>
                                <p>{translate('doYouWantToContinue')}</p>
                            </div>
                            <div className="col-md-12 leaveWebBtns">
                                <a onClick={props.hide} href="#!" title="">{translate('cancle')}</a>
                                <a onClick={props.hide} href={props.url} title="" target="_blank" rel="noopener noreferrer">{translate('Continue')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LeavingWeb