import React from 'react'
import translate from '../../Providers/i18n/Translate'
import CircularProgressChart from '../utils/piechart'

function ApplicationStatusItem(props) {
    var secondProgress = 100 - props.payload.completedPercentage
    return (
        <div className="col-md-6">
            <div className="widgetApplicationStatusWrapper housingApplicationStatusBoxWrapper">
                <div className="widgetApplicationStatusInWrapper">
                    <div className="col">
                        {props.payload ?
                            <div className="row housingAccordion">
                                {/* <div className="col-sm-4">
                                    <CircularProgressChart progressColor="#408d45" percentTextColor="#3c4551" data={[{ value: props.payload.completedPercentage }, { value: secondProgress }]} />
                                </div> */}
                                <div className="col-sm-8">
                                    <span className="selectedcolor-90">{props.payload.serviceName}</span>
                                    <p><span>{props.payload.fileNo} </span>{props.payload.status}</p>
                                    <p>{props.payload.since} | {props.payload.date}</p>
                                </div>
                                {props.showComplementaryServices ?
                                    <div className="housingAccordionContent">
                                        {props.complementaryServicesArray}</div> : null}
                                {props.complementaryServicesArray.length > 0 ?
                                    <button id={props.index} name={props.showComplementaryServices.toString()} onClick={props.onArrowClick}>
                                        {translate("complemantryRequests")}
                                        <img className='btnmargin' id={props.index} name={props.showComplementaryServices.toString()} onClick={props.onArrowClick} src={props.showComplementaryServices ? 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/more.svg' : 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/down.svg'} alt="Expand" />
                                    </button> : null}
                            </div> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApplicationStatusItem