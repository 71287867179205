import React from 'react'
import '../../../reactToastify.css'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
import translate from '../../../Providers/i18n/Translate'
import { AppContext } from '../../../Providers/context'
import PdfListStore from './PdfListStore';
import PdfListService from './PdfListService';
import { LOCALES } from '../../../Providers/i18n'
import CommonUtils from '../../../utils/CommonUtils'
import { ToastContainer, toast } from 'react-toastify';
import HISTORY from '../../../History';

class PdfListComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            uploadDoc: false,
            uploadBg: false,
            userType: localStorage.getItem('userType'),
            name: '',
            companyName: '',
            email: '',
            isShow: false,
            response: '',
            isSuccess: false,
            isLoading: false,
            categoryListResponse: '',
            isCategoryListSuccess: false,
            selectValue: '',
            documentName: '',
            documentDescription: '',
            uploadUnsingedAttachResponse: '',
            isUploadAUnsingedAttachSuccess: false,
            uploadUnsingedAttachMessage: '',
            isSendApiCall: false,
            file: '',
            isShowAddDocView: false,
            isDeleteAttachmentSuccess: false,
            deleteAttachmentMessage: '',
            convertedFile: '',
            deleteId: '',
            fileName: '',
            deleteDoc: false,
            signItLater: false

        }
        this._onChange = this._onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    componentDidMount() {
        this.getCategoryList();
        this.getProjectsInboxList();
        this.setState({ isLoading: true })
        PdfListStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        PdfListStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState({ isLoading: false })
        this.setState(this.projectsInboxListState());
        this.setState(this.categoryListState());
        this.setState(this.uploadUnsignedAttachmentState());
        this.setState(this.deleteAttachmentState);
        if (!this.state.isUploadAUnsingedAttachSuccess && this.state.uploadUnsingedAttachMessage) {
            toast.error(this.state.uploadUnsingedAttachMessage)
        }
        if (this.state.isSendApiCall && this.state.isDeleteAttachmentSuccess && this.state.deleteAttachmentMessage) {
            this.getProjectsInboxList();
            toast.success(this.state.deleteAttachmentMessage)
            this.setState({
                isSendApiCall: false,
            })
        }
    }

    getCategoryList() {
        PdfListService.projectCategoryList(this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    categoryListState() {
        return {
            categoryListResponse: PdfListStore.categoryListResponse,
            isCategoryListSuccess: PdfListStore.isCategoryListSuccess(),
        };
    }

    getProjectsInboxList() {
        PdfListService.customerInboxList(this.context.state.siteLang, '')
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    projectsInboxListState() {
        return {
            response: PdfListStore.response,
            isSuccess: PdfListStore.isSuccess(),
        };
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    uploadDocClick() {
        this.setState({
            uploadDoc: !this.state.uploadDoc,
            uploadBg: !this.state.uploadBg,
            signItLater: false,
            selectValue: '',
            documentName: '',
            documentDescription: '',
            file: ''
        })
    }

    deleteDocClick(value) {
        this.setState({
            deleteDoc: !this.state.deleteDoc,
            deleteId: value.documentId,
            fileName: value.documentName
        })
    }

    signItLaterClick() {
        this.setState({
            uploadBg: false,
            signItLater: false,
            isUploadAUnsingedAttachSuccess: false
        })

        this.getProjectsInboxList();
    }

    signNow(payload) {
        this.setState({
            uploadBg: false,
            signItLater: false,
            isUploadAUnsingedAttachSuccess: false
        })
        let payLoadObject = {
            documentId: payload.documentId,
            documentName: this.state.documentName
        }
        this.redirect(payLoadObject, '/signPdf');
    }

    deleteDocYesClick() {
        this.setState({
            deleteDoc: !this.state.deleteDoc,
            isSendApiCall: true,
        })
        PdfListService.deleteAttachment(this.state.deleteId, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    deleteAttachmentState() {
        return {
            isDeleteAttachmentSuccess: PdfListStore.isDeleteAttachmentSuccess(),
            deleteAttachmentMessage: PdfListStore.deleteAttachmentMessage,
        };
    }

    handleChange(event) {
        var data = event.target.value;
        if (data !== 'يرجى اختيار نوع المستند' || data !== 'Please select document type') {
            this.setState({
                selectValue: JSON.parse(data)
            });
        } else {
            this.setState({
                selectValue: ''
            });
        }
    }

    onChangeDocName = (event) => {
        this.setState({
            documentName: event.target.value.replace(/[^\w\s]/gi, "")
        });
    }

    onChangeListener = () => {
        this.setState({
            isShowAddDocView: true
        });
    }

    onChangeListener1 = () => {
        this.setState({
            isShowAddDocView: false
        });
    }

    onChangeDocDesc = (event) => {
        this.setState({
            documentDescription: event.target.value
        });
    }

    uploadFile(e) {
        const files = e.target.files[0]
        if (CommonUtils.isValidPDFFileType(files) && CommonUtils.isValidFileSize(files)) {
            this.setState({
                file: files,
            })
        } else {
            toast.error(translate('fileTypePdf'))
        }
    }

    uploadSingleDocClick() {
        if (this.state.selectValue === '') {
            toast.error(translate('pleaseSelectDocType'))
            return;
        }
        if (this.state.documentName === '') {
            toast.error(translate('pleaseEnterDocName'))
            return;
        }
        if (this.state.documentDescription === '') {
            toast.error(translate('pleaseEnterDocDesc'))
            return;
        }
        if (this.state.file === '') {
            toast.error(translate('pleaseselectFile'))
            return;
        }
        this.setState({
            uploadDoc: !this.state.uploadDoc,
            signItLater: true
        })
        PdfListService.uploadUnsignedAttachment(this.state.selectValue.id, this.state.documentDescription, this.state.documentName,
            this.state.file, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    uploadUnsignedAttachmentState() {
        return {
            uploadUnsingedAttachResponse: PdfListStore.uploadUnsingedAttachResponse,
            isUploadAUnsingedAttachSuccess: PdfListStore.isUploadAUnsingedAttachSuccess(),
            uploadUnsingedAttachMessage: PdfListStore.uploadUnsingedAttachMessage,
        };
    }

    redirect = (object, page) => {
        localStorage.setItem('pdfObject', JSON.stringify(object));
        HISTORY.push({
            pathname: page
            // state: { detail: itemObject }
        })
    };

    render() {
        const { isShowAddDocView, response, isSuccess, categoryListResponse, isCategoryListSuccess, isLoading, selectValue, file,
            uploadUnsingedAttachResponse, isUploadAUnsingedAttachSuccess, uploadUnsingedAttachMessage,
            isDeleteAttachmentSuccess, deleteAttachmentMessage } = this.state;

        var resultsList = [];
        if (isSuccess) {
            response.sort((a, b) => a.categoryId - b.categoryId);
        }
        if (isCategoryListSuccess) {
            categoryListResponse.sort((a, b) => a.categoryId - b.categoryId);
        }

        //compare two array and get common objects
        if (isSuccess && response && categoryListResponse) {
            resultsList = categoryListResponse.filter((id1) => response.some((id2) => (id2.enableEdit && id2.categoryId) === id1.id));
        }
        var resultsList2 = [];
        let content = [];
        if (isSuccess && response && categoryListResponse) {
            for (let idx in response) {
                const item = response[idx];
                if (!item.enableEdit) {
                    content.push(item);
                    resultsList2 = categoryListResponse.filter((id1) => content.some((id2) => id2.categoryId === id1.id));
                }
            }
        }

        return (
            <div className="container-fluid">
                <ToastContainer autoClose={5000} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    {/* breadcrumb */}
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        {this.state.userType == "1" ? <a href="/customerDashboard">{translate('dashboard')}</a> : <a href="/contractorDashboard">{translate('dashboard')}</a>}
                                    </li>
                                    {this.state.userType === "1" ? <li className="breadcrumb-item"><a href="/userProfile">{translate('profile')}</a></li> : <li className="breadcrumb-item"><a href="/contractorProfile">{translate('profile')}</a></li>}
                                    <li className="breadcrumb-item active" aria-current="page">{translate('documentsList')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* End breadcrumb */}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/profile.svg'} alt="profile" /></i><span>{translate('myInbox')}</span>
                                {isShowAddDocView ? <button className="myInboxAddBtn" onClick={this.uploadDocClick.bind(this)}>
                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/add_c.svg'} alt="Add" /></i>
                                    <span>{translate('addDoc')}</span>
                                </button> : null}
                            </div>
                        </div>
                        {!isLoading && isSuccess && response.length > 0 ?
                            <div className="subTitle">
                                <h3>{translate('uploadedDoc')}</h3>
                            </div> : null}
                    </div>
                    <div className="row uploadDocTab">
                        <div className="col-md-12">
                            <div className="tabs">
                                {/* mrhe Documents */}
                                <input type="radio" name="tabs" id="tabone" onClick={this.onChangeListener1.bind(this)} defaultChecked />
                                <label htmlFor="tabone">{translate('DocumentsUploadedMBRHE')}</label>
                                <div className="col-md-12 tab">
                                    {!isLoading && isSuccess && resultsList2.length > 0 ? resultsList2.map((list, index) => {
                                        return (
                                            <div key={index} className="row service_contant_wrapper">
                                                <div className="subTitle">
                                                    <h2>{list.value}</h2>
                                                </div>
                                                {/* child items */}
                                                {response.some(object => object.categoryId == list.id) ? response.filter(object => object.categoryId == list.id).map((item, index) => {
                                                    if (!item.enableEdit) {
                                                        return (
                                                            <div key={index} className="col-md-12 myInboxUprow">
                                                                <div className="row no-gutters">
                                                                    <div className="col-lg-7 myInboxPdfName">
                                                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/pdf_ico.svg'} alt="pdf" /></i>
                                                                        <span>{item.documentName}</span>
                                                                    </div>
                                                                    <div className="col-lg-5 myInboxUprowBtns">
                                                                        {!item.isSigned ? <button className="myInboxSignBtn" onClick={this.redirect.bind(this, item, '/signPdf')}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign.svg'} alt="pdf" /></i> <span>{translate('signDoc')}</span></button> : null}
                                                                        <button className="myInboxViewBtn" onClick={this.redirect.bind(this, item, '/viewPdf')}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/eye_icon_white.svg'} alt="pdf" /></i> <span>{translate('signView')}</span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                }) : null}
                                            </div>
                                        );
                                    }) : null}
                                </div>


                                {/* customer Uploaded Documents */}
                                <input type="radio" name="tabs" id="tabtwo" onChange={this.onChangeListener.bind(this)} />
                                <label htmlFor="tabtwo">{translate('DocumentsUploadedCustomer')}</label>
                                <div className="col-md-12 tab">
                                    {!isLoading && isSuccess && resultsList.length > 0 ? resultsList.map((list, index) => {
                                        return (
                                            <div key={index} className="row service_contant_wrapper">
                                                <div className="subTitle">
                                                    <h2>{list.value}</h2>
                                                </div>
                                                {/* child items */}
                                                {response.some(object => object.categoryId == list.id) ? response.filter(object => object.categoryId == list.id).map((item, index) => {
                                                    if (item.enableEdit) {
                                                        return (
                                                            <div key={index} className="col-md-12 myInboxUprow">
                                                                <div className="row no-gutters">
                                                                    <div className="col-lg-7 myInboxPdfName">
                                                                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/pdf_ico.svg'} alt="pdf" /></i>
                                                                        <span>{item.documentName}</span>
                                                                    </div>
                                                                    <div className="col-lg-5 myInboxUprowBtns">
                                                                        {!item.isSigned ? <button className="myInboxSignBtn" onClick={this.redirect.bind(this, item, '/signPdf')}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign.svg'} alt="pdf" /></i> <span>{translate('signDoc')}</span></button> : null}
                                                                        <button className="myInboxViewBtn" onClick={this.redirect.bind(this, item, '/viewPdf')}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/eye_icon_white.svg'} alt="pdf" /></i> <span>{translate('signView')}</span></button>
                                                                        <button className="myInboxDeleteBtn" onClick={this.deleteDocClick.bind(this, item)}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete_boq.svg'} alt="pdf" /></i> <span>{translate('delete')}</span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                }) : null}
                                            </div>
                                        );

                                        /////

                                    }) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* don't have any Documents */}
                    {isShowAddDocView ? <div>
                        {!isLoading && !isSuccess || response.length === 0 ?
                            <div className="row service_contant_wrapper">
                                <div className="col-md-12">
                                    <div className="NoDocWrapper">
                                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/nodoc.svg'} alt="pdf" />
                                        <p>{translate('noDoc')}</p>
                                        <button onClick={this.uploadDocClick.bind(this)}>{translate('addDoc')}</button>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div> : null}
                    {/* Upload Document POPUP*/}
                    {this.state.uploadBg ? <div className="PopupBk" ></div> : null}
                    {this.state.uploadDoc ? <div className="uploadPopup uploadDocWrapperPopup">
                        <div className="row formSection">
                            <div className="col-md-12 subTitle">
                                <h3>{translate('docDetail')}</h3>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="documenttype">{translate('docType')}</label>
                                                <select id="documenttype"
                                                    onChange={this.handleChange}>
                                                    {this.context.state.siteLang === LOCALES.ENGLISH ? <option>Please select document type</option> : <option>يرجى اختيار نوع المستند</option>}
                                                    {isCategoryListSuccess && categoryListResponse.map((option, key) =>
                                                        <option key={key} value={JSON.stringify(option)} >
                                                            {option.value}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="documentname">{translate('docName')}</label>
                                                <input maxLength="25" value={this.state.documentName} type="text" id="documentname" name="documentName" placeholder={this.context.state.siteLang === LOCALES.ENGLISH ? 'Please enter document name' : 'أدخل اسم المستند'}
                                                    onChange={this.onChangeDocName.bind(this)}
                                                />
                                                <div className="infoInputText">
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="pdf" /></i>
                                                    <span>{translate('validationLimit')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="documentdescription">{translate('docDesc')}</label>
                                                <textarea value={this.state.documentDescription} id="documentdescription" placeholder={this.context.state.siteLang === LOCALES.ENGLISH ? 'Please enter document description' : 'أدخل تفاصيل المستند'}
                                                    onChange={this.onChangeDocDesc.bind(this)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12 uploadFile">
                                                <h2>{translate('choseFile')}</h2>
                                                <input type="file" id="chooseyourfile" onChange={this.uploadFile}></input>
                                                <label htmlFor="chooseyourfile">{translate('choseFile')}</label>
                                                {file ? <span className="uploadFileName">{file.name}</span> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 uploadDocumentBtn">
                                        <button onClick={this.uploadSingleDocClick.bind(this)}>{translate('uploadDocument')}</button>
                                        <button onClick={this.uploadDocClick.bind(this)}>{translate('cancle')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}

                    {/* File upload Successfull */}
                    {this.state.signItLater && isUploadAUnsingedAttachSuccess ?
                        <div className="uploadPopup inboxSuccessfullUploadBox">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/succuss_file_upload.svg'} alt="pdf" />
                                    <h2>{this.state.documentName} {translate('docSavedSuccess')} </h2>
                                </div>
                                <div className="col-md-12 uploadDocumentBtn">
                                    <button onClick={this.signNow.bind(this, uploadUnsingedAttachResponse)}>{translate('signNow')}</button>
                                    <button onClick={this.signItLaterClick.bind(this)}>{translate('signLater')}</button>
                                </div>
                            </div>
                        </div> : null}
                    {/* END Upload Document POPUP*/}

                    {/* DELETE Document POPUP*/}
                    {this.state.deleteDoc ? <div className="PopupBk" ></div> : null}
                    {this.state.deleteDoc ? <div className="uploadPopup deletePopup">
                        <div className="row formSection">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <p>{translate('deletePopUp')} {this.state.fileName} {this.context.state.siteLang === LOCALES.ENGLISH ? '?' : '؟'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 uploadDocumentBtn">
                                        <button onClick={this.deleteDocYesClick.bind(this)}>{translate('yes')}</button>
                                        <button onClick={this.deleteDocClick.bind(this)}>{translate('cancle')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}

                </div>
            </div >
        )
    }
}
export default AuthenticatedComponent(PdfListComponent)
