import React from 'react'
import History from '../History'
import Loader from '../Customer/utils/LoaderBars'
import { AppContext } from '../Providers/context'
import translate from '../Providers/i18n/Translate'
import ErrorComponent from '../Customer/utils/errorComponent'
import InputComponent from '../Customer/utils/inputComponent'
import CONSTANTS from '../Customer/constants/dashboard-constants'
import HappinessMeter from '../HappinessMeter/HappinessMeter'
import GetHousingApplicationStatusStore from './InformationServiceStore';
import GetHousingApplicationStatusService from './InformationServiceService';

var errorsArray = []
class GetHousingApplicationStatusComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            error: null,
            response: '',
            fileNumber: '',
            captchaInput: '',
            showLoader: false,
            isSuccess: false,
            audioPayload: null,
            isVisualCaptcha: true,
            captchaSelectedIndex: -1,
            showHappinessMeter: false
        }
        this.captcha = this.captcha.bind(this)
        this._onChange = this._onChange.bind(this);
        this.setCaptcha = this.setCaptcha.bind(this)
        this.goBackPage = this.goBackPage.bind(this)
        this.captchaClick = this.captchaClick.bind(this)
        this.captchaInput = this.captchaInput.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.isContractor = this.isContractor.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.generateCaptcha = this.generateCaptcha.bind(this)
        this.validateCaptcha = this.validateCaptcha.bind(this)
        this.getCaptchaAudioUrl = this.getCaptchaAudioUrl.bind(this)
        this.captchaSetAudioUrl = this.captchaSetAudioUrl.bind(this)
        this.toggleVisualCaptcha = this.toggleVisualCaptcha.bind(this)
        this.validateAudioCaptcha = this.validateAudioCaptcha.bind(this)
        this.captchaValidateSuccess = this.captchaValidateSuccess.bind(this)
        this.submitApplicationNumber = this.submitApplicationNumber.bind(this)
    }

    componentDidMount() {
        GetHousingApplicationStatusStore.addChangeListener(this._onChange);
        errorsArray.push("fileNumber")
        this.setState({ errors: errorsArray })
        this.generateCaptcha()
        // if (!this.isLoggedIn()) {
        //     require('../ApplicationBase/Login/style_24.css')
        // }
    }

    isLoggedIn() {
        return localStorage.getItem('accessToken')
    }

    componentWillUnmount() {
        GetHousingApplicationStatusStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getHousingProjectStatusState());
    }

    getHousingProjectStatus() {
        this.setState({ showLoader: true })
        GetHousingApplicationStatusService.housingAppStatus(this.state.fileNumber, this.context.state.siteLang)
            .catch(function (error) {
                this.setState({ showLoader: false })
                this.catchBody(error);
            }.bind(this));
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    getHousingProjectStatusState() {
        this.setState({ showHappinessMeter: true, captchaSelectedIndex: -1, captchaInput: '', showLoader: false })
        return {
            response: GetHousingApplicationStatusStore.response,
            isSuccess: GetHousingApplicationStatusStore.isSuccess(),
        };
    }

    handleChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (isValid) {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    reset() {
        this.setState({ error: null })
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    submitApplicationNumber() {
        // e.preventDefault();
        this.reset()
        if (this.state.errors.length <= 0) {
            this.getHousingProjectStatus();
        } else {
            this.showError(translate('requiredErrorMessage'))
        }
        this.generateCaptcha()
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    closeCallback() {
        this.setState({ error: null })
    }

    generateCaptcha() {
        this.setState({ captchaSelectedIndex: -1, captchaInput: '' })
        var url = CONSTANTS.generateCaptchaEndpoint + "?local=" + this.context.state.siteLang + "&size=5"
        fetch(url, {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then(this.setCaptcha).catch(this.failCallback)
    }

    validateCaptcha() {
        this.setState({ isSuccess: false, response: null })
        if (this.state.isVisualCaptcha) {
            if (this.state.captchaSelectedIndex !== -1) {
                var url = CONSTANTS.validateCaptchaEndpoint + "?local=" + this.context.state.siteLang + "&questionId=" + this.state.payload.question.id + "&imageKey=" + this.state.payload.images[this.state.captchaSelectedIndex].key
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'consumer-key': process.env.CLIENT_ID,
                        'consumer-secret': process.env.CLIENT_SECRET
                    }
                }).then(response => response.json()).then(this.captchaValidateSuccess).catch(this.failCallback)
            } else {
                this.showError(translate("invalid_picture"))
            }
        } else {
            if (this.state.captchaInput === '') {
                this.showError(translate("invalid_picture"))
            } else {
                this.validateAudioCaptcha()
            }
        }
    }

    captchaValidateSuccess(response) {
        if (response.success) {
            this.submitApplicationNumber()
        } else {
            this.showError(response.message)
        }
    }

    setCaptcha(response) {
        if (response.success) {
            this.getCaptchaAudioUrl(response.payload.audioId)
            this.setState({ payload: response.payload })
        }
    }

    getCaptchaAudioUrl(audioId) {
        var url = CONSTANTS.generateAudioCaptchaEndpoint + "/" + audioId + "?local=" + this.context.state.siteLang
        fetch(url, {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then(this.captchaSetAudioUrl).catch(this.failCallback)
    }

    captchaSetAudioUrl(response) {
        this.setState({ audioPayload: response.payload })
    }

    captchaClick(e) {
        this.setState({ captchaSelectedIndex: parseInt(e.target.id) })
    }

    captcha(payload) {
        var captchaImages = []
        var selectedIndex = this.state.captchaSelectedIndex
        payload.images.map((image, index) => {
            captchaImages.push(
                <div key={index} className={selectedIndex !== -1 && selectedIndex === index ? "img captchaSelect" : "img"}>
                    <img id={index} name={image.key} src={image.url} alt={image.key} onClick={this.captchaClick} />
                </div>
            )
        })
        return captchaImages
    }

    captchaInput(e) {
        const value = e.target.value
        this.setState({ captchaInput: value })
    }

    toggleVisualCaptcha() {
        this.setState({ error: null, isVisualCaptcha: !this.state.isVisualCaptcha })
    }

    validateAudioCaptcha() {
        var url = CONSTANTS.validateAudioCaptchaEndpoint + "?local=" + this.context.state.siteLang + "&Id=" + this.state.audioPayload.audioId + "&answer=" + this.state.captchaInput
        fetch(url, {
            method: 'GET',
            headers: {
                'consumer-key': process.env.CLIENT_ID,
                'consumer-secret': process.env.CLIENT_SECRET
            }
        }).then(response => response.json()).then(this.captchaValidateSuccess).catch(this.failCallback)
    }

    goBackPage() {
        if (this.isContractor()) {
            History.replace("/contractorDashboard")
        } else {
            History.replace("/customerDashboard")
        }
    }

    isContractor() {
        if (localStorage.getItem('accessToken') && localStorage.dashboard) {
            return localStorage.getItem('accessToken') && JSON.parse(localStorage.dashboard).payload.userType === 2
        } else {
            return false
        }
    }

    render() {
        const { response, isSuccess } = this.state
        return (
            <div className="container-fluid">
                {this.state.showLoader ? <Loader /> : null}
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#!" onClick={this.goBackPage}>{translate('back')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('GetHousingApplicationStatus')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ApplicationStatus.svg'} alt="Get Housing Application Status" /></i><span>{translate('GetHousingApplicationStatus')}</span></div>
                        </div>
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    <InputComponent inputProps={{ lable: translate('applicationNo'), name: 'fileNumber', isRequired: true, defaultValue: this.state.fileNumber, type: 'number', changeListener: this.handleChange }} />
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="captchaWrapper">
                                            {this.state.isVisualCaptcha ?
                                                <div className="imageWrapper">
                                                    <div className="captchaImages">
                                                        <p className="captchaQuestion">{translate("click_captcha")} <strong>{this.state.payload && this.state.payload.question.value}</strong></p>
                                                        {this.state.payload && this.captcha(this.state.payload)}
                                                    </div>
                                                </div> :
                                                <div className="voiceWrapper">
                                                    <audio src={this.state.audioPayload && this.state.audioPayload.audioUrl} autoPlay />
                                                    <label>{translate("enter_captcha")}</label>
                                                    <input type="text" value={this.state.captchaInput} onChange={this.captchaInput} />
                                                </div>}
                                            <div className="buttonWrapper">
                                                <button type="reset" onClick={this.generateCaptcha}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/captcha_refresh.svg'} alt="refresh" /></button>
                                                {this.state.isVisualCaptcha ?
                                                    <button type="reset" onClick={this.toggleVisualCaptcha}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/captcha_voice.svg'} alt="voice" /></button> :
                                                    <button type="reset" onClick={this.toggleVisualCaptcha}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/captcha_img.svg'} alt="image" /></button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 formSectionBtn">
                                        <button type="reset" onClick={this.goBackPage}>{translate('cancle')}</button>
                                        <button onClick={this.validateCaptcha}>{translate('submit')}</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.error}
                            {isSuccess ? <HappinessMeter serviceCode="2669" transactionID={this.state.fileNumber} /> : null}
                            {isSuccess && response ?
                                <div className="service_contant_wrapper">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="servicesTable getApplicationTable">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th className="sticky">{translate('applucationStatus_FileNo')}</th>
                                                            <th>{translate('applucationStatus_ServiceType')}</th>
                                                            <th>{translate('applucationStatus_Status')}</th>
                                                        </tr>
                                                        {isSuccess && response ?
                                                            <tr>
                                                                <td className="sticky">{response.fileNo}</td>
                                                                <td>{response.serviceName}</td>
                                                                <td>{response.status}</td>
                                                            </tr>
                                                            : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}

                            {isSuccess && response == null ?
                                <div className="service_contant_wrapper">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="noDataLabel resultwrapper">
                                                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/No_data_icon.svg" alt="No Data" />
                                                <span>{translate('nodata')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
//from Omar It will refresh only when user will click refresh button
export default GetHousingApplicationStatusComponent;