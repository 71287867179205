import React from 'react'
import '../../reactToastify.css'
import History from '../../History'
import { AppContext } from '../../Providers/context'
import { ToastContainer, toast } from 'react-toastify'
import translate from '../../Providers/i18n/Translate'
import EarlySettlementStore from './earlysettlement-store'
import EarlySettlementService from './earlysettlement-service'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
import CONSTANTS from '../constants/dashboard-constants'

class EarlySettlement extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super()
        this.state = {
            images: [],
            files: [],
            payload: '',
            success: false
        }
        this.onChange = this.onChange.bind(this)
        this.applyEarleSettlement = this.applyEarleSettlement.bind(this)
        this.getFile = this.getFile.bind(this)
        this.removeClick = this.removeClick.bind(this)
        this.setFinancialStatus = this.setFinancialStatus.bind(this)
    }

    componentDidMount() {
        EarlySettlementStore.addChangeListener(this.onChange)
        EarlySettlementService.getFinancialStatusRequest("?local=" + this.context.state.siteLang)
    }

    onChange() {
        this.setFinancialStatus(EarlySettlementStore.financialStatusResponse)
        this.earlySettlementResponseHandler(EarlySettlementStore.response)
    }

    setFinancialStatus(response) {
        if (response.success && response.payload.length > 0)
            this.setState({ payload: response.payload[0] })
    }

    earlySettlementResponseHandler(response) {
        if (response.success) {
            this.setState({ success: true })
            toast.success(response.message)
        } else {
            toast.error(response.message)
        }
    }

    applyEarleSettlement() {
        EarlySettlementService.earlySettlementRequest(this.state.payload.applicationNo, "?local=" + this.context.state.siteLang, this.state.images)
    }

    getFile(e) {
        let filesArr = e.target.files;
        var files = [...this.state.images]
        for (var i = 0; i < filesArr.length; i++) {
            files.push(filesArr[i])
        }
        e.target.value = null
        this.setState({ images: files }, () => { this.setFiles(this.state.images) })
    }

    removeClick(e) {
        var array = [...this.state.images];
        array.splice(e.target.name, 1);
        this.setState({ images: array }, () => { this.setFiles(this.state.images) });
    }

    setFiles(files) {
        var filesArray = []
        files.map((file, index) => { filesArray.push(<FileComponent removeClick={this.removeClick} key={index} index={index} file={file} />) })
        this.setState({ files: filesArray })
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={2000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('EarlySettlementHeading')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        {this.state.success ? <HappinessMeter serviceCode={CONSTANTS.serviceCodes.EarlySettlement} transactionID="" /> : null}
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/EarlyPaymentDiscount.svg'} alt="Early Payment Discount" /></i><span>{translate('EarlySettlementHeading')}</span></div>
                        </div>
                        <div className="col-md-12 earlySettlementWrapper">
                            <div className="service_contant_wrapper formSection">
                                <h3>{translate('EarlySettlementHeading')}</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('Loanfilenumber')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.applicationNo} - {this.state.payload.applicationName}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('Discountpercentage')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.discountPercentage}%</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('Discountedamount')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.discount}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('Earlypaymentamount')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.amountToPay}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('applicationNo')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.applicationNo}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('DateofLastBill')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.dateOfLastBill}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('MonthlyPayment')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.monthlyPayment}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('OverdueAmount')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.overdueAmount}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('RemainingLoan')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.remainingLoan}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('MaturityDate')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.maturityDate}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('startingBalance')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.startingBalance}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('NextPaymentDate')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.nextPaymentDate}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('Remainingmonths')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.remainingMonths}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <span> {translate('Amounttopay')}: </span>
                                            </div>
                                            <div className="col-8">
                                                <strong>{this.state.payload.amountToPay}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="service_contant_wrapper formSection">
                                        <div className="row">
                                            <div className="col-md-12 uploadInput">
                                                <h3> {translate('requireddocs')}</h3>
                                                <p> {translate('fileSize')}. </p>
                                                <label> {translate('Completioncertificate')} <span className="redStar">*</span></label>
                                                <div className="uploadInputCustum">
                                                    <span> {translate('Selectfiles')} </span>
                                                    <input type="file" accept="image/*, application/pdf" multiple onChange={this.getFile}></input>
                                                </div>
                                                <ul>
                                                    {this.state.files}
                                                </ul>
                                            </div>
                                            <div className="col-md-12 formSectionBtn">
                                                <button type="reset" onClick={History.goBack}> {translate('cancle')} </button>
                                                <button type="submit" onClick={this.applyEarleSettlement}> {translate('submit')} </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="service_contant_wrapper formSection">
                                        <h3> {translate('ApplicationsStatus')}</h3>
                                        <strong> {translate('requireddocs')} </strong>
                                        <ul className="listDot">
                                            <li> {translate('doc1')} </li>
                                            <li> {translate('doc2')} </li>
                                        </ul>
                                        <br></br>
                                        <strong> {translate('conditions')} </strong>
                                        <ul className="listDot">
                                            <li> {translate('settlement_term1')} </li>
                                            <li> {translate('settlement_term2')} </li>
                                            <li> {translate('settlement_term3')} </li>
                                            <li> {translate('settlement_term4')} </li>
                                            <li> {translate('settlement_term5')} </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function FileComponent(props) {
    return (<li>
        <span> {props.file.name} </span>
        <button name={props.index} onClick={props.removeClick}>X</button>
    </li>)
}

export default AuthenticatedComponent(EarlySettlement)