import HousingRequestsConstants from './housing-requests-constants'
import BaseStore from '../../../BaseStore'

class HousingRequestsStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this.registerToActions.bind(this))
        this.housingRequestsResponse = null
        this.deleteHousingRequestsResponse = null
    }

    registerToActions(action) {
        switch (action.actionType) {
            case HousingRequestsConstants.HOUSINGREQUESTS:
                this.housingRequestsResponse = action.response
                this.emitChange()
                break
            case HousingRequestsConstants.DELETEHOUSINGREQUESTS:
                this.deleteHousingRequestsResponse = action.response
                this.emitChange()
                break
            default:
                break
        }
    }

    get response() {
        return this.housingRequestsResponse
    }

    get deleteHousingRequestResponse() {
        return this.deleteHousingRequestsResponse
    }

    clearDeleteResponse() {
        this.deleteHousingRequestsResponse = null
    }
}

export default new HousingRequestsStore()