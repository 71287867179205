import React from 'react'
import PieChart from '../../utils/piechart'
import translate from '../../../Providers/i18n/Translate'

function ContractorPaymentRequestsWidgetItem(props) {
    var secondPeogress = 100 - props.item.completedPercentage
    return (
        <div className="widgetApplicationStatusWrapper">
            
            <div className="widgetContractorPaymentRequestsWrapper">
                <div className="widgetBlock">
                    <PieChart progressColor="#408d45" percentTextColor="#3c4551" data={[{ value: props.item.completedPercentage }, { value: secondPeogress }]} />
                </div>
                <div className="widgetBlock">
                    {/* <div className='widgetSection'>
                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/clockFive.svg'} alt="Time" /></i>
                        <span>{props.item.relativeDate}</span>
                    </div> */}
                    <div className='widgetSection'>
                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/clipboardUser.svg'} alt="User" /></i>
                        <div>
                            <span>{translate('contractorName')}</span>
                            <span>{props.item.contractorName}</span>
                        </div>
                    </div>
                    <div className='widgetSection'>
                        <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/moneyWave.svg'} alt="AED" /></i>
                        <div>
                            <span>{translate('totalpayment')}</span>
                            <span>{props.item.totalAmount}</span>
                        </div>
                    </div>
                </div>
                <div className="widgetBlock">
                <button name={props.item.id} onClick={props.rejectPayment}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/cancel.svg'} alt="Reject" /> {translate('Reject')} </button>
                <button name={props.item.id} onClick={props.acceptPayment}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/odone.svg'} alt="Accept" /> {translate('Accept')} </button>
                </div>
            </div>
        </div>
    )
}
export default ContractorPaymentRequestsWidgetItem