import React from "react";
import {Pie} from 'react-chartjs-2';


class ProjectsSummaryPieChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
        this.state = {
            summaryResponse : this.props.pieChart
        }
    }

    getStatus(key) {
        var dataList = [];
        dataList = this.state.summaryResponse.map(value => {
            if (key === 'status') {
                return value.status;
            } else if (key === 'color') {
                return value.color;
            } else if (key === 'count') {
                return value.count;
            }
        });
        return dataList;
    }
    // Contractor Project Summary
    setProjectsSummaryResponse() {
        var contractorProjects = {
            labels: this.getStatus('status'),
            datasets: [
                {
                    label: 'Contractor Projects',
                    fill: true,
                    lineTension: 0.1,
                    backgroundColor: ['#55D8FE','#FF8373','#FFDA83','#338AE6','#A3A0FB','#003454','#F6303F', '#0296CE', '#C224B9', '#C1CF32', '#0068AD', '#F29400'],
                    data: this.getStatus('count')
                }
            ]
        };
        return contractorProjects;
    }

    render() {
        return (
            <div className="widgetContant">
                <Pie ref="pieChart" data={this.setProjectsSummaryResponse()} legend={{ "position": "left", }} width={300} />
            </div>
        );
    }

}

export default ProjectsSummaryPieChartComponent;