import AuthConstants from './AuthConstants';
import BaseStore from '../../BaseStore';

class LoginStore extends BaseStore {

  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._success = false;
    this._response = null;
    this._jwt = null;
    this._userType = null;
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case AuthConstants.LOGIN_USER_PASSWORD_OR_OTP:
        this._success = action.response.success;
        this._jwt = action.response.access_token;
        this._userType = action.response;
        this.emitChange();
        break;
      case AuthConstants.LOGIN_USER_OTP:
        this._response = action.response.message;
        this._success = action.response.success;
        this.emitChange();
        break;
      default:
        break;
    };
  }

  isLoggedIn() {
    return this._jwt != null;
  }

  isSuccess() {
    return this._success;
  }


  get response() {
    return this._response;
  }

  get userTypeResponse() {
    return this._userType;
  }

  get jwt() {
    return this._jwt;
  }
}

export default new LoginStore();