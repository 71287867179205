import ContractorProjectPaymentsAction from './ContractorProjectPaymentsAction';
import ContractorProjectPaymentsConstant from './ContractorProjectPaymentsConstant';
import request from 'reqwest';
import when from 'when';
import axios from 'axios';

class ContractorProjectPaymentsService {
  /** contractor project payments */
  projectPayments(statusId, page, pagesize, local) {
    return this.projectPaymentsHandleResponse(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_PROJECT_PAYMENTS_URL + statusId + "&page=" + page + '&pagesize=' + pagesize + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }));
  }
  projectPaymentsHandleResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.projectPayments(response);
        return true;
      });
  }

  /** contractor entry status*/
  contractorEntrySatus(contractId, transactionType, local) {
    return this.contractorEntrySatusHandleResponse(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_ENTRY_STATUS_URL + contractId + "&transactionType=" + transactionType + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }));
  }

  contractorEntrySatusHandleResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.contractorEntrySatus(response);
        return true;
      });
  }

  /** contractor project payments break down*/
  projectPaymentsBreakDownInfo(contractId, transactionType, local) {
    return this.projectPaymentsBreakDownInfoResponse(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_PROJECT_PAYMENTS_BREAK_DOWN_URL + contractId + "&transactionType=" + transactionType + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }));
  }

  projectPaymentsBreakDownInfoResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.projectPaymentsBreakDown(response);
        return true;
      });
  }

  /** contractor add payment header owner amount */
  contractorAddHeaderOwnerAmount(paymentId, paymentOwner, local) {
    return this.contractorAddHeaderOwnerAmountResponse(when(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_ADD_PAYMENT_HEADER_OWNER_URL + paymentId + '&paymentOwner=' + paymentOwner + '&local=' + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    })));
  }

  contractorAddHeaderOwnerAmountResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.projectPaymentsOwnerAmount(response);
        return true;
      });
  }


  /** contractor add payment header details */
  contractorAddPaymentHeaderDetail(id, paymentId, contractId, workId, paymentPercentage, note, local) {
    return this.contractorAddPaymentHeaderResponse(when(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_ADD_PAYMENT_HEADER_DETAILS_URL + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      },
      data: {
        id: id,
        paymentId: paymentId,
        contractId: contractId,
        workId: workId,
        paymentPercentage: paymentPercentage,
        note: note
      }
    })));
  }

  contractorAddPaymentHeaderResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.projectPaymentsHeaderDetails(response);
        return true;
      });
  }

  /** contractor get all attachments */
  contractorGetAllDocumemnts(requestNo, local) {
    return this.contractorGetAllDocumemntsResponse(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_GET_ALL_DOCUMENTS_URL + requestNo + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }));
  }

  contractorGetAllDocumemntsResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.getAllDocuments(response);
        return true;
      });
  }

  /** contractor upload attachments */
  contractorUploadDocumemnts(requestNo, files, type, local) {
    const url = ContractorProjectPaymentsConstant.CONTRACTOR_UPLOAD_DOCUMENTS_URL + requestNo + '&local=' + local;
    const formData = new FormData();
    formData.append('files', files);
    formData.append('type', type);
    const config = {
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }
    return this.contractorUploadDocumemntsResponse(axios.post(url, formData, config))
  }

  contractorUploadDocumemntsResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.uploadDocument(response.data);
        return true;
      });
  }

  /** contractor view attachment */
  contractorViewDocumemnt(requestNo, attachmentId, local) {
    return this.contractorViewDocumemntResponse(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_VIEW_DOCUMENTS_URL + requestNo + '&Id=' + attachmentId + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }));
  }

  contractorViewDocumemntResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.viewDocument(response);
        return true;
      });
  }
  /** contractor delete attachment */
  contractorDeleteDocumemnt(requestNo, attachmentId, local) {
    return this.contractorDeleteDocumemntResponse(when(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_DELETE_DOCUMENTS_URL + requestNo + '&Id=' + attachmentId + '&local=' + local,
      method: 'DELETE',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    })));
  }

  contractorDeleteDocumemntResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.deleteDocument(response);
        return true;
      });
  }
  /** contractor submit status */
  contractorSubmitStatus(id, contractorId, contractId, statusId, local) {
    return this.contractorSubmitStatusResponse(when(request({
      url: ContractorProjectPaymentsConstant.CONTRACTOR_SUBMIT_STATUS_URL + local,
      method: 'POST',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      },
      data: {
        id: id,
        contractorId: contractorId,
        contractId: contractId,
        statusId: statusId
      }
    })));
  }

  contractorSubmitStatusResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorProjectPaymentsAction.submitStatus(response);
        return true;
      });
  }
}



export default new ContractorProjectPaymentsService();