import React from "react";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent';
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import OpenNewProjectFileStore from '../OpenNewProjectFile/OpenNewProjectFileStore';
import OpenNewProjectFileService from '../OpenNewProjectFile/OpenNewProjectFileService';
import CommonUtils from '../../utils/CommonUtils'
import { ToastContainer, toast } from 'react-toastify';
import '../../reactToastify.css';
import CurrencyInput from 'react-currency-input-field';
import { Redirect } from 'react-router-dom';

var ownerScopeList = [];
class OwnerScopeBreakdownComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            showBg: false,
            alert: false,
            object: this.props.object,
            isOwnerScopeSuccess: false,
            ownerScopeResponse: '',
            ownerScopeMessage: '',
            popUpId: '',
            popUpWorkId: 0,
            popUpOwnerWorkAmount: '',
            popUpWorkDesc: '',
            isSendApiCall: false,
            isClearDeleteApiCall: false,
            isClearOrDeleteOwnerScopeSuccess: false,
            clearOrDeleteOwnerScopeResponse: '',
            clearOrDeleteOwnerScopeMessage: '',
            isSaveModifyOwnerScopeSuccess: false,
            saveModifyOwnerScopeResponse: '',
            saveModifyOwnerScopeMessage: '',
            isShowWorkDesc: false,
            deletePopUpId: 0,
            isGetListLoaded: false,
            isRedirect: false,
        };
        this.props.handlerPercentageLable(false);
        this._onChange = this._onChange.bind(this);
    }

    componentDidMount() {
        OpenNewProjectFileStore.addChangeListener(this._onChange);
        this.getOwnerScopeList();
    }

    componentWillUnmount() {
        OpenNewProjectFileStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getOwnerScopeListState());
        this.setState(this.saveModifyOwnerScopeState());
        this.setState(this.getClearOrDeleteState());
        if (this.state.isSendApiCall && this.state.isSaveModifyOwnerScopeSuccess) {
            toast.success(translate('savedSucc'), { autoClose: 2000 });
            this.setState({
                isSendApiCall: false,
                isOwnerScopeSuccess: false,
                isClearOrDeleteOwnerScopeSuccess: false
            })
        }
        if (this.state.isClearDeleteApiCall && this.state.isClearOrDeleteOwnerScopeSuccess) {
             toast.success(translate('savedSucc'), { autoClose: 2000 });
            this.setState({
                isClearDeleteApiCall: false,
                isOwnerScopeSuccess: false,
                isSaveModifyOwnerScopeSuccess: false
            })
        }
        if (this.state.isGetListLoaded && this.state.ownerScopeMessage) {
            toast.error(this.state.ownerScopeMessage, { autoClose: 2000 });
            this.setState({
                isGetListLoaded: false
            })
        }

        if (this.state.saveModifyOwnerScopeMessage) {
            toast.error(this.state.saveModifyOwnerScopeMessage, { autoClose: 2000 });
        }

        if (this.state.clearOrDeleteOwnerScopeMessage) {
            toast.error(this.state.clearOrDeleteOwnerScopeMessage, { autoClose: 2000 });
        }
    }

    getOwnerScopeList() {
        this.setState({
            isGetListLoaded: true
        })
        OpenNewProjectFileService.getOwnerScope(this.state.object.id, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getOwnerScopeListState() {
        return {
            isOwnerScopeSuccess: OpenNewProjectFileStore.isOwnerScopeSuccess(),
            ownerScopeResponse: OpenNewProjectFileStore.ownerScopeResponse,
            ownerScopeMessage: OpenNewProjectFileStore.ownerScopeMessage,

        };
    }

    modifyClick(workId) {
        var boqObject = CommonUtils.getBOQIndex(ownerScopeList, workId);
        var workDesc = '';
        if (workId === 514 || workId === 515 || workId === 516 ||
            workId === 517) {
            this.setState({
                isShowWorkDesc: true
            })
            workDesc = CommonUtils.getOwnerScopeWorkDesc(ownerScopeList, workId)
        } else {
            this.setState({
                isShowWorkDesc: false
            })
            workDesc = CommonUtils.getOwnerScopeWorkDesc([], workId)
        }
        if (boqObject !== -1) {
            if (boqObject.workDesc) {
                workDesc = boqObject.workDesc
            }
            this.setState({
                popUpId: boqObject.id,
                popUpWorkId: workId,
                popUpOwnerWorkAmount: boqObject.ownerWorkAmount,
                popUpWorkDesc: workDesc
            })
        }
        if (boqObject === -1) {
            this.setState({
                popUpId: '',
                popUpWorkId: workId,
                popUpOwnerWorkAmount: '',
                popUpWorkDesc: CommonUtils.getOwnerScopeWorkDesc([], workId)
            })
        }

        if (!workId) {
            this.setState({
                popUpOwnerWorkAmount: '',
                popUpWorkId: workId
            })
        }
        this.setState({
            showBg: !this.state.showBg
        })
    }

    addNewItem() {
        this.setState({
            showBg: !this.state.showBg,
            isShowWorkDesc: true,
            popUpOwnerWorkAmount: '',
            popUpWorkDesc: '',
            popUpId: '',
            popUpWorkId: ''
        });
    }

    clearClick(id) {
        this.setState({
            isClearDeleteApiCall: true
        })
        OpenNewProjectFileService.clearOrDeleteOwnerScope(id, this.state.object.id, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }


    getClearOrDeleteState() {
        return {
            isClearOrDeleteOwnerScopeSuccess: OpenNewProjectFileStore.isClearOrDeleteOwnerScopeSuccess(),
            clearOrDeleteOwnerScopeResponse: OpenNewProjectFileStore.clearOrDeleteOwnerScopeResponse,
            clearOrDeleteOwnerScopeMessage: OpenNewProjectFileStore.clearOrDeleteOwnerScopeMessage,
        };
    }

    handleChange = (event) => {
        if (event === undefined) {
            this.setState({
                popUpOwnerWorkAmount: ''
            });
            return;
        }
        if (event.includes('.')) {
            var value = String(Math.trunc(event))
            if (value.length > 8) {
                toast.error(translate('maxlength'));
                return;
            } else {
                this.setState({
                    popUpOwnerWorkAmount: event
                });
                return;
            }
        }
        if (event.length > 9) {
            toast.error(translate('maxlength'));
            return;
        }
        this.setState({
            popUpOwnerWorkAmount: event
        });
    }

    handleNoteChange = (event) => {
        this.setState({
            popUpWorkDesc: event.target.value
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.onClick();
        }
    }

    onClick = () => {
        this.sendApiCall(this.state.popUpId, this.state.popUpWorkId, this.state.popUpOwnerWorkAmount, this.state.popUpWorkDesc);
        this.setState({
            showBg: !this.state.showBg,
        })
    }

    sendApiCall = (id, workId, ownerWorkAmount, workDesc) => {
        this.setState({
            isSendApiCall: true
        })
        OpenNewProjectFileService.modifyAndSaveOwnerScope(id, this.state.object.id, workId, ownerWorkAmount, workDesc, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    saveModifyOwnerScopeState() {
        return {
            isSaveModifyOwnerScopeSuccess: OpenNewProjectFileStore.isSaveModifyOwnerScopeSuccess(),
            saveModifyOwnerScopeResponse: OpenNewProjectFileStore.saveModifyOwnerScopeResponse,
            saveModifyOwnerScopeMessage: OpenNewProjectFileStore.saveModifyOwnerScopeMessage,
        };
    }

    deleteClick(value) {
        this.setState({
            deleteConfirmation: !this.state.deleteConfirmation,
            deletePopUpId: value,
        })
    }
    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorMessage = parseError.message;
        toast.error(errorMessage, { autoClose: 2000 });
        console.log("Error logging in : ", errorMessage);
    }

    deleteClickYes() {
        this.setState({
            deleteConfirmation: !this.state.deleteConfirmation,
            isClearDeleteApiCall: true
        })
        OpenNewProjectFileService.clearOrDeleteOwnerScope(this.state.deletePopUpId, this.state.object.id, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    handlePDF = () => {
        localStorage.setItem('ownerScope', true)
        this.setState({
            isRedirect: true
        })
    }

    redirect() {
        return (
            <div>
                {this.state.isRedirect ? <Redirect
                    to={{
                        pathname: '/viewBOQSummary',
                        state: { item: this.state.object }
                    }}
                /> : null}
            </div>
        )
    }


    render() {
        const { isOwnerScopeSuccess, ownerScopeResponse, isSaveModifyOwnerScopeSuccess, saveModifyOwnerScopeResponse,
            isClearOrDeleteOwnerScopeSuccess, clearOrDeleteOwnerScopeResponse } = this.state

        var results = [];
        if (isOwnerScopeSuccess && ownerScopeResponse.length > 0) {
            ownerScopeResponse.sort((a, b) => a.workId - b.workId);
            ownerScopeList = ownerScopeResponse.slice();
        }
        if (isSaveModifyOwnerScopeSuccess && saveModifyOwnerScopeResponse.length > 0) {
            saveModifyOwnerScopeResponse.sort((a, b) => a.workId - b.workId);
            ownerScopeList = saveModifyOwnerScopeResponse.slice();
        }
        if (isClearOrDeleteOwnerScopeSuccess) {
            if (clearOrDeleteOwnerScopeResponse.length > 0) {
                clearOrDeleteOwnerScopeResponse.sort((a, b) => a.workId - b.workId);
                ownerScopeList = clearOrDeleteOwnerScopeResponse.slice();
            } else {
                ownerScopeList = [];
            }
        }
        // compare two lists and get unmatched items in list 
        results = ownerScopeList.filter((id1) => !CommonUtils.ownerShareDummyList().some((id2) => id2.workId === id1.workId));

        return (
            <div className="col-md-12">
                <ToastContainer autoClose={5000} position={toast.POSITION.TOP_CENTER} />

                {/* EditPopup File */}
                {this.state.showBg ? <div className="PopupBk"></div> : null}
                {this.state.showBg ? <div className="uploadPopup modifyNewProjectPopup">
                    <div className="row formSection">
                        <div className="col-12"><label>{translate('estimatedValue')} : </label>
                            <CurrencyInput
                                placeholder=""
                                value={this.state.popUpOwnerWorkAmount}
                                allowDecimals={true}
                                decimalsLimit={2}
                                onKeyDown={this.handleKeyPress}
                                onChange={this.handleChange.bind(this)} /></div>
                        {!CommonUtils.isOwnerScopeIDExists(this.state.popUpWorkId) || this.state.isShowWorkDesc ?
                            <div className="col-12"><label>{translate('descriptionOfwork')} : </label><input onKeyDown={this.handleKeyPress} value={this.state.popUpWorkDesc} type="text" onChange={this.handleNoteChange.bind(this)} /></div> : null}
                        <div className="col modifyFormSectionBtn">
                            <div className="modifyFormSectionBtnSpace"></div>
                            <div className="modifyFormSectionBtnWrapper">
                                <button onClick={this.onClick} >{translate('saveAndClose')}</button>
                                <button onClick={this.modifyClick.bind(this, '')}>{translate('cancle')}</button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {/* End EditPopup File */}

                {/* Delete File */}
                {this.state.deleteConfirmation ? <div className="PopupBk"></div> : null}
                {this.state.deleteConfirmation ? <div className="deleteConfirmationPopup">
                    <div className="row formSection">
                        <div className="col-12">
                            <p>{translate('deleteconfirmation')}</p>
                        </div>
                        <div className="col button text-center">
                            <button onClick={(this.deleteClickYes.bind(this))}>{translate('yes')}</button>
                            <button onClick={this.deleteClick.bind(this)}>{translate('no')}</button>
                        </div>
                    </div>
                </div> : null}
                {/* End Delete File */}
                <div className="row">
                         {/* <div className="col-md-12 project_step_top_Btns">
                             <div>
                                 <button className="printBtn" onClick={this.handlePDF}>{translate('printOwnerScope')}</button>
                                 {this.redirect()}
                             </div>
                         </div> */}
                    <div className="openNewProjectOwnerScopeTable project_step_table_header">
                        <table>
                            <tbody>
                                <tr>
                                    <th>{translate('srNo')}</th>
                                    <th>{translate('descriptionOfwork')}</th>
                                    <th>{translate('estimatedValue')}</th>
                                    <th>{translate('remarks')}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="project_step_wrapper openNewProjectOwnerScopeTable">
                        <div className="project_step_table_contantSection">
                            <Accordion allowZeroExpanded={true} allowMultipleExpanded={true} preExpanded={['1', '2', '3', '4', '5', '6', '7', '8',
                                '9', '10', '11', '12', '13', '14', '15', '16', '17']}>
                                {/* set1 */}
                                <AccordionItem className="widgetWrapper" uuid="1">
                                    <AccordionItemPanel className="widgetContant">
                                        <div className="project_step_table_contant">
                                            <table>
                                                <tbody>
                                                    {/* static rows with descriptions */}
                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 501) ? ownerScopeList.filter(object => object.workId == 501).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('airConditioning'), value)
                                                    )) : this.tableColumns(501, 501, translate('airConditioning'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 502) ? ownerScopeList.filter(object => object.workId == 502).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('lightFitiing'), value)
                                                    )) : this.tableColumns(502, 502, translate('lightFitiing'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 503) ? ownerScopeList.filter(object => object.workId == 503).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('kitchenCab'), value)
                                                    )) : this.tableColumns(503, 503, translate('kitchenCab'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 504) ? ownerScopeList.filter(object => object.workId == 504).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('wardrobes'), value)
                                                    )) : this.tableColumns(504, 504, translate('wardrobes'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 505) ? ownerScopeList.filter(object => object.workId == 505).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('internalWooden'), value)
                                                    )) : this.tableColumns(505, 505, translate('internalWooden'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 506) ? ownerScopeList.filter(object => object.workId == 506).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('antenna'), value)
                                                    )) : this.tableColumns(506, 506, translate('antenna'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 507) ? ownerScopeList.filter(object => object.workId == 507).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('interncom'), value)
                                                    )) : this.tableColumns(507, 507, translate('interncom'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 508) ? ownerScopeList.filter(object => object.workId == 508).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('falseCelling'), value)
                                                    )) : this.tableColumns(508, 508, translate('falseCelling'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 509) ? ownerScopeList.filter(object => object.workId == 509).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('carShed'), value)
                                                    )) : this.tableColumns(509, 509, translate('carShed'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 510) ? ownerScopeList.filter(object => object.workId == 510).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('interlock'), value)
                                                    )) : this.tableColumns(510, 510, translate('interlock'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 511) ? ownerScopeList.filter(object => object.workId == 511).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('sweetSoil'), value)
                                                    )) : this.tableColumns(511, 511, translate('sweetSoil'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 512) ? ownerScopeList.filter(object => object.workId == 512).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('leveling'), value)
                                                    )) : this.tableColumns(512, 512, translate('leveling'), '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 513) ? ownerScopeList.filter(object => object.workId == 513).map((value, index) => (
                                                        this.tableColumns(index, value.workId, translate('water&Electricity'), value)
                                                    )) : this.tableColumns(513, 513, translate('water&Electricity'), '')}


                                                    {/* static rows without descriptions */}
                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 514) ? ownerScopeList.filter(object => object.workId == 514).map((value, index) => (
                                                        this.tableColumns(index, value.workId, value.workDesc, value)
                                                    )) : this.tableColumns(514, 514, '', '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 515) ? ownerScopeList.filter(object => object.workId == 515).map((value, index) => (
                                                        this.tableColumns(index, value.workId, value.workDesc, value)
                                                    )) : this.tableColumns(515, 515, '', '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 516) ? ownerScopeList.filter(object => object.workId == 516).map((value, index) => (
                                                        this.tableColumns(index, value.workId, value.workDesc, value)
                                                    )) : this.tableColumns(516, 516, '', '')}

                                                    {ownerScopeList.length > 0 && ownerScopeList.some(object => object.workId == 517) ? ownerScopeList.filter(object => object.workId == 517).map((value, index) => (
                                                        this.tableColumns(index, value.workId, value.workDesc, value)
                                                    )) : this.tableColumns(517, 517, '', '')}


                                                    {/* dynamic rows */}
                                                    {results.length > 0 ? results.map((value, index) => (
                                                        this.tableColumns(index, value.workId, value.workDesc, value)
                                                    )) : null}
                                                </tbody>
                                            </table>

                                            <div className="stepAddBtn">
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    tableColumns(index, workId, name, boq) {
        var remarks = '';
        var id = '';
        var isDisabled = true;
        if (boq) {
            remarks = boq.remarks;
            id = boq.id;
            if (id) {
                isDisabled = false;
            }
        }
        return (
            <tr key={index}>
                <td>{workId}</td>
                <td>{name}</td>
                <td><CurrencyInput disabled={true} value={boq.ownerWorkAmount} /></td>
                <td className="stepCommentsWrapper">
                    {remarks == null ? <input type="text" defaultValue='' /> : <input disabled type="text" value={remarks} />}
                    <button>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        {remarks ? <span className="tooltiptext">{remarks}</span> : null}
                    </button>
                </td>
                <td className="text-center">
                    <button className="modifiBtn modify" onClick={this.modifyClick.bind(this, workId)}><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/modify_boq.svg" alt="edit" /></i>{translate('modify')}</button>
                    {!CommonUtils.isOwnerScopeIDExists(workId) ?
                        <button className="modifiBtn delete" onClick={this.deleteClick.bind(this, boq.id)}><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete_boq.svg" alt="delete" /></i>{translate('delete')}</button>
                        : null}
                    {CommonUtils.isOwnerScopeIDExists(workId) ?
                        <button disabled={isDisabled} className="modifiBtn clear" onClick={this.clearClick.bind(this, boq.id)}><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/clear_boq.svg" alt="clear" /></i>{translate('clear')}</button>
                        : null}
                </td>
            </tr>
        );
    }
}
export default AuthenticatedComponent(OwnerScopeBreakdownComponent);
