import React from 'react'
import History from '../../History'
import Loader from '../utils/LoaderBars'
import StepDocuments from './StepDocuments'
import HouseAddition from './HouseAddition'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { LOCALES } from '../../Providers/i18n'
import SocialInformation from './SocialInformation'
import { AppContext } from '../../Providers/context'
import InputComponent from '../utils/inputComponent'
import ErrorComponent from '../utils/errorComponent'
import Utils from '../utils/customerModuleUtilities'
import { ToastContainer, toast } from 'react-toastify'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'
import ExtensionAdditionComponents from './ExtensionAdditionComponents'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class NewHousingRequestComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            stepNo: 1,
            gender: '',
            errors: [],
            history: '',
            response: '',
            error: null,
            isSuccess: false,
            temsAccept: false,
            maritalStatus: '',
            successMessage: '',
            showServies: false,
            showLoading: false,
            ticketResponse: {},
            serviceChanged: false,
            socialStudyPayload: {
                "ticketNo": "",
                "singleMaleChildrenInHouse": 0,
                "singleFemaleChildrenInHouse": 0,
                "marriedMaleChildrenInHouse": 0,
                "marriedFemaleChildrenInHouse": 0,
                "seniorMaleChildrenInHouse": 0,
                "seniorFemaleChildrenInHouse": 0,
                "handicapMaleChildrenInHouse": 0,
                "handicapFemaleChildrenInHouse": 0,
                "isHousePreparedForHandicap": null,
                "requireServiceSection": null,
                "requireFirstFloor": null,
                "requireHall": null,
                "requireHallWithBath": null,
                "requirePreparationKitchen": null,
                "requireRoomWithBath": null,
                "requireExternalKitchen": null,
                "requireStore": null,
                "requireWashingRoom": null,
                "requireServiceRoomBath": null,
                "requireNurseRoomBath": null,
                "requireDriverRoomBath": null,
                "requireParking": null
            },
            socialStudyEmptyPayload: {
                "ticketNo": "",
                "singleMaleChildrenInHouse": 0,
                "singleFemaleChildrenInHouse": 0,
                "marriedMaleChildrenInHouse": 0,
                "marriedFemaleChildrenInHouse": 0,
                "seniorMaleChildrenInHouse": 0,
                "seniorFemaleChildrenInHouse": 0,
                "handicapMaleChildrenInHouse": 0,
                "handicapFemaleChildrenInHouse": 0,
                "isHousePreparedForHandicap": null,
                "requireServiceSection": null,
                "requireFirstFloor": null,
                "requireHall": null,
                "requireHallWithBath": null,
                "requirePreparationKitchen": null,
                "requireRoomWithBath": null,
                "requireExternalKitchen": null,
                "requireStore": null,
                "requireWashingRoom": null,
                "requireServiceRoomBath": null,
                "requireNurseRoomBath": null,
                "requireDriverRoomBath": null,
                "requireParking": null
            },
            financialErrors: [],
            servicesPayload: [],
            eligibleServices: [],
            govtSupportTypes: [],
            submitSuccess: false,
            selectedServiceID: '',
            personalInfoErrors: [],
            serviceApplyingFor: '',
            selectedServiceName: '',
            selectedServiceIDold: '',
            showHappinessMeter: false,
            requiredCreationDate: false,
            // employment: StepEmployment,
            requireSocialTechnicalStudy: localStorage.getItem('requireSocialTechnicalStudy') === 'true',
            isModifying: props.location.state && props.location.state.isModifying,
            ticket: localStorage.getItem("ticket") ? localStorage.getItem("ticket") : props.location.state && props.location.state.ticketNo,
        }
        this.reset = this.reset.bind(this)
        this.finish = this.finish.bind(this)
        this.goNext = this.goNext.bind(this)
        this.ddmmyy = this.ddmmyy.bind(this)
        this.mmddyy = this.mmddyy.bind(this)
        this.setDates = this.setDates.bind(this)
        this.addError = this.addError.bind(this)
        this.verifyOTP = this.verifyOTP.bind(this)
        this.getTicket = this.getTicket.bind(this)
        this.validEmail = this.validEmail.bind(this)
        this.setProfile = this.setProfile.bind(this)
        this.setResults = this.setResults.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
        this.requestOTP = this.requestOTP.bind(this)
        this.removeError = this.removeError.bind(this)
        this.acceptClick = this.acceptClick.bind(this)
        this.validMobile = this.validMobile.bind(this)
        this.yearsBetween = this.yearsBetween.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.submitRequest = this.submitRequest.bind(this)
        this.isDateEligible = this.isDateEligible.bind(this)
        this.handleCheckbox = this.handleCheckbox.bind(this)
        this.generateTicket = this.generateTicket.bind(this)
        this.changeListener = this.changeListener.bind(this)
        this.validateErrors = this.validateErrors.bind(this)
        this.getSocialStudy = this.getSocialStudy.bind(this)
        this.setDocsResults = this.setDocsResults.bind(this)
        this.postSocialStudy = this.postSocialStudy.bind(this)
        this.otpVerifyFailed = this.otpVerifyFailed.bind(this)
        this.validLandNumber = this.validLandNumber.bind(this)
        this.servicesPopClick = this.servicesPopClick.bind(this)
        this.otpVerifySuccess = this.otpVerifySuccess.bind(this)
        this.updateStepNumber = this.updateStepNumber.bind(this)
        this.otpRequestFailed = this.otpRequestFailed.bind(this)
        this.setTicketResults = this.setTicketResults.bind(this)
        this.eligibleInfoClick = this.eligibleInfoClick.bind(this)
        this.otpRequestSuccess = this.otpRequestSuccess.bind(this)
        this.isMandatoryService = this.isMandatoryService.bind(this)
        this.setServicesResults = this.setServicesResults.bind(this)
        this.setEligibleServices = this.setEligibleServices.bind(this)
        this.getEligibleServices = this.getEligibleServices.bind(this)
        this.updateTicketPayload = this.updateTicketPayload.bind(this)
        this.requiredCreationDate = this.requiredCreationDate.bind(this)
        this.setViewTicketResults = this.setViewTicketResults.bind(this)
        this.showSecondWifeOption = this.showSecondWifeOption.bind(this)
        this.validSecondWifeOption = this.validSecondWifeOption.bind(this)
        this.fetchRequiredDocuments = this.fetchRequiredDocuments.bind(this)
        this.handleRadioButtonClick = this.handleRadioButtonClick.bind(this)
        this.getIsSocialStudyRequired = this.getIsSocialStudyRequired.bind(this)
        this.updateSocialStudyPayload = this.updateSocialStudyPayload.bind(this)
        this.validHouseCompletionDate = this.validHouseCompletionDate.bind(this)
        this.getTicketsForServiceCode = this.getTicketsForServiceCode.bind(this)
        this.changeListenerNoValidation = this.changeListenerNoValidation.bind(this)
        this.getApplicationsWithTickets = this.getApplicationsWithTickets.bind(this)
        this.setApplicationsWithTickets = this.setApplicationsWithTickets.bind(this)
        this.validateGenderForSecondWife = this.validateGenderForSecondWife.bind(this)
        this.getIsTotalDeductionRequired = this.getIsTotalDeductionRequired.bind(this)
        this.getApplicationsForServiceCode = this.getApplicationsForServiceCode.bind(this)
        this.contractDateDateDateChangeListener = this.contractDateDateDateChangeListener.bind(this)
    }

    componentDidMount() {
        this.getApplicationsWithTickets()
        var payload = JSON.parse(localStorage.dashboard).payload
        this.setDates()
        this.setState({ maritalStatus: payload.maritalStatus, gender: payload.gender }, () => {
            this.getEligibleServices(this.state.maritalStatus)
        })
        if (this.state.ticket && this.state.ticket.length > 0) {
            localStorage.setItem("ticket", this.state.ticket)
            this.getTicket(this.state.ticket)
            if (this.state.requireSocialTechnicalStudy) {
                this.getSocialStudy()
            }
        } else if (localStorage.serviceID) {
            this.setProfile(payload, this.props.history)
            this.setState({ selectedServiceID: localStorage.serviceID }, () => { this.updateTicketPayload('serviceTypId', localStorage.serviceID) })
        } else {
            this.setProfile(payload, this.props.history)
            this.setState({ error: true, errorMessageService: <div className="alert alertWarning">{translate('selectservice')}</div> })
        }

        setTimeout(() => { this.requiredCreationDate() }, 100);
    }

    setDates() {
        var payload = this.state.ticketResponse
        if (this.requiredCreationDate() && payload) {
            var dateString = payload.housecompletionDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
            this.setState({ HousecompletionDate: payload.housecompletionDate ? new Date(dateString) : '' })
            setTimeout(() => { this.updateTicketPayload('housecompletionDate', this.mmddyy(new Date(dateString))) }, 300);
        }
    }

    getApplicationsWithTickets() {
        this.setState({ showLoading: true }, () => {
            fetch(CONSTANTS.applicationsWithTicketsEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplicationsWithTickets).catch(this.loadFailed);
        })
    }

    setApplicationsWithTickets(response) {
        this.setState({ showLoading: false, applicationsWithTickets: response.payload }, () => {
            this.getOldTicket(this.state.applicationsWithTickets, this.state.selectedServiceID)
        })
        if (!this.state.ticket || this.state.ticket.length <= 0) {
            this.showSecondWifeOption()
        }
    }

    getOldTicket(applications, serviceCode) {
        var tickets = this.getTicketsForServiceCode(applications, serviceCode)
        tickets.length > 0 && tickets[0].status === 0 && this.getTicket(tickets[0].referanceNo)
    }

    getSocialStudy() {
        this.setState({ showLoading: true }, () => {
            fetch(CONSTANTS.socialTechnicalStudyGetEndpoint + this.state.ticket + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then((response) => { this.setState({ socialStudyPayload: response.payload }) }).catch(this.loadFailed);
        })
    }

    postSocialStudy(stepNo) {
        this.setState({ showLoading: true }, () => {
            fetch(CONSTANTS.socialTechnicalStudyPostEndpoint + stepNo + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': CONSTANTS.authToken
                },
                body: new URLSearchParams(this.state.socialStudyPayload),
            }).then(response => response.json()).then((response) => { response.success ? this.setState({ showLoading: false, stepNo: stepNo + 2 }) : this.loadFailed() }).catch(this.loadFailed);
        })
    }

    updateTicketPayload(name, value) {
        this.reset()
        this.setState({ ticketResponse: { ...this.state.ticketResponse, [name]: value } })
    }

    updateSocialStudyPayload(name, value, stepNumber) {
        this.setState({ stepNo: stepNumber, socialStudyPayload: { ...this.state.socialStudyPayload, [name]: value } })
    }

    setProfile(props, history) {
        if (props) {
            if (props.email === null || props.email.length === 0) {
                this.updateTicketPayload('email', '')
                this.addError('email')
            } else {
                this.updateTicketPayload('email', props.email)
                this.removeError('email')
            }
            setTimeout(function () {
                if (props.primaryMobileNo === null || props.primaryMobileNo.length === 0) {
                    this.updateTicketPayload('mobileNo', '')
                    this.addError('mobileNo')
                } else {
                    this.updateTicketPayload('mobileNo', props.primaryMobileNo)
                    this.removeError('mobileNo')
                }
            }.bind(this), 100)
            this.setState({
                history: history,
                message: '',
                services: ''
            })
        }
    }

    handleChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        if (name === 'otp') {
            this.setState({ otp: value })
            // this.updateStepNumber(2)
        } else {
            this.updateStepNumber(1)
            if (isValid) { this.removeError(name) } else if (e.target.required) { this.addError(name) }
            // if (name === 'isFromSpecialNeeds') {
            //     this.updateTicketPayload(name, e.target.value)
            //     if (e.target.value === 'true') {
            //         setTimeout(function () { this.updateTicketPayload("specialNeedTypeId", 1) }.bind(this), 100)
            //     } else {
            //         setTimeout(function () { this.updateTicketPayload("specialNeedTypeId", 0) }.bind(this), 100)
            //     }
            // } else if (name === 'specialNeedTypeId') {
            //     this.updateTicketPayload(name, parseInt(value, 10))
            // }
            // else if (name === 'secondWifeEmiratesId') {
            //     this.updateTicketPayload(name, parseInt(value, 10))
            // }
            if (name === 'isApplyingForSecondWife') {
                setTimeout(function () { this.updateTicketPayload(name, value) }.bind(this), 100)
                setTimeout(function () { this.updateTicketPayload("secondWifeEmiratesId", 0) }.bind(this), 100)
                if (e.target.value === 'true') {
                    setTimeout(function () { this.addError("secondWifeEmiratesId") }.bind(this), 100)
                } else {
                    setTimeout(function () { this.removeError("secondWifeEmiratesId") }.bind(this), 100)
                }
            }
            else {
                this.updateTicketPayload(name, value)
            }
        }
    }

    reset() {
        this.setState({ error: null, errorMessage: '' })
    }

    showError(message) {
        this.setState({ showLoading: false, error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> }, () => {
            this.scrollToBottom()
        })
    }

    scrollToBottom() {
        // document.getElementById("errorDiv").scrollIntoView();
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    closeCallback() {
        this.setState({ error: null })
    }

    goNext(stepNo) {
        if (this.state.errors.length === 0) {
            if (this.validateErrors()) {
                this.generateTicket()
            } else {
                this.setState({ stepNo: 1 })
                this.showError(translate("requiredErrorMessage"))
            }
        } else {
            this.setState({ stepNo: 1 })
            this.showError(translate("requiredErrorMessage"))
        }
    }

    generateTicket() {
        this.reset()
        if (this.validateErrors()) {
            this.setState({ showLoading: true }, () => {
                fetch(CONSTANTS.generateHousingRequestEndPoint + "?step=1&local=" + this.context.state.siteLang, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        'Authorization': CONSTANTS.authToken
                    },
                    body: new URLSearchParams(this.state.ticketResponse)
                }).then(response => response.json()).then(this.setTicketResults).catch(this.loadFailed)
            })
        }
    }

    setTicketResults(response) {
        console.log(this.state.canApplyForSecondWife)
        if (response.success) {
            this.setState({ showLoading: false, 'ticket': response.payload.ticket, ticketResponse: response.payload }, () => {
                localStorage.setItem("ticket", this.state.ticket)
                this.updateTicketPayload('ticket', response.payload.ticket)
                this.fetchRequiredDocuments()
                setTimeout(() => { this.state.requireSocialTechnicalStudy ? this.setState({ stepNo: 2 }) : this.setState({ stepNo: 5 }) }, 300);
                setTimeout(() => { this.setDates() }, 300);
            })
        } else {
            this.setState({ showLoading: false }, () => {
                this.showError(response.message)
            })
        }
    }

    loadFailed(response) {
        this.setState({ showLoading: false }, () => {
            this.showError(response.message)
        })
    }

    servicesPopClick() {
        this.setState({ stepNo: 1, showServies: !this.state.showServies })
    }

    eligibleInfoClick(e) {
        var name = e.target.name
        const radiobtns = []
        var previousid = ""
        this.state.servicesPayload.map((service) => {
            if (name === service.id) {
                previousid = name
                if (this.state.previousId === name && this.state.isInfoShown) {
                    this.setState({ isInfoShown: false })
                    radiobtns.push(this.radioButton(service, false))
                } else {
                    this.setState({ isInfoShown: true })
                    radiobtns.push(this.radioButton(service, true))
                }
            } else {
                radiobtns.push(this.radioButton(service, name === service.id))
            }
        })
        this.setState({ eligibleServices: radiobtns, previousId: previousid })
    }

    acceptClick(e) {
        if (e.target.id === "accept") {
            this.setState({ error: false, errorMessage: "", acceptClick: !this.state.acceptClick, temsAccept: true }, () => {

            })
        }
        else {
            this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false, error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> }, () => {
                // this.validateErrors()
            })
        }
    }

    /** Validations */
    validateErrors() {
        this.setState({ error: false, errorMessage: '', errorMessageService: '' })
        let isValid = true
        if (this.state.selectedServiceID === '') {
            isValid = false
            window.scrollTo(0, 200)
            this.setState({ error: true, errorMessageService: <div className="alert alertWarning">{translate('selectservice')}</div> })
        } else if (!this.validSecondWifeOption() || !this.validHouseCompletionDate() || !this.validLandNumber() || !this.validEmail() || !this.validMobile()) {
            isValid = false
        }
        return isValid
    }

    validSecondWifeOption() {
        return this.state.canApplyForSecondWife ? !(this.state.ticketResponse.secondWifeEmiratesId == undefined || this.state.ticketResponse.secondWifeEmiratesId == '' || this.state.ticketResponse.secondWifeEmiratesId == 'null') : true
    }

    getValidApplications(applications) {
        var validApps = []
        applications && applications.map((application) => {
            var status = application.status.toString()
            if ((application.source === 2) && status === '5' || status === '15' || status === '50' || status === '70')
                validApps.add(application)
        })
        return validApps
    }

    hasTicketWithStatus60(applications) {
        var validTicketFound = false
        applications && applications.map((application) => {
            // application.cancelTickets && application.cancelTickets.some((ticket) => {
            var status = application.status.toString()
            if (application.source === 1 && status === '60')
                validTicketFound = true
            // })
        })
        return validTicketFound
    }

    validateApplications(applications) {
        var validAppFound = false
        applications && applications.some((application) => {
            var status = application.status.toString()
            if (application.source === 2 && (status === '5' || status === '15' || status === '50' || status === '70'))
                validAppFound = true
        })
        return validAppFound
    }

    validateTickets(applications) {
        var applicationsWithValidTickets = []
        applications && applications.map((application) => {
            // application.cancelTickets && application.cancelTickets.map((ticket) => {
            var status = application.status.toString()
            if (application.source === 1 && status !== '30' && status !== '50' && status !== '60')
                applicationsWithValidTickets.push(application)
            // })
        })
        return applicationsWithValidTickets.length > 0
    }

    getApplicationsForServiceCode(allApplications, serviceCode) {
        var applications = []
        if (serviceCode) {
            allApplications.map((application) => {
                if (application.serviceTypeId.toString() === serviceCode.toString() && application.source === 2)
                    applications.push(application)
            })
        }
        return applications
    }

    getTicketsForServiceCode(allApplications, serviceCode) {
        var tickets = []
        if (serviceCode && allApplications) {
            allApplications.map((application) => {
                if (application.serviceTypeId.toString() === serviceCode.toString() && application.source === 1)
                    tickets.push(application)
            })
        }
        return tickets
    }

    validHouseCompletionDate() {
        return this.requiredCreationDate() ? this.state.ticketResponse.housecompletionDate != undefined : true
    }

    validLandNumber() {
        return this.isMandatoryService(this.state.selectedServiceID) ? (this.state.ticketResponse.landNumber != undefined && this.state.ticketResponse.landNumber.length > 0) : true
    }

    validEmail() {
        return this.state.ticketResponse.email != undefined && this.state.ticketResponse.email.length > 0
    }

    validMobile() {
        return this.state.ticketResponse.mobileNo != undefined && this.state.ticketResponse.mobileNo.length > 0
    }

    validHouseCompletionDate() {
        return this.requiredCreationDate() ? (this.state.ticketResponse.housecompletionDate != undefined && this.state.ticketResponse.housecompletionDate.length > 0) : true
    }

    validMonthlyIncome() {
        return this.state.ticketResponse.salary != undefined && this.state.ticketResponse.salary.length > 0
    }
    /** Validations */

    getEligibleServices(maritalStatus) {
        fetch(CONSTANTS.getEligibleServicesEndPoint + "?local=" + this.context.state.siteLang + '&status=' + maritalStatus, {
            method: 'GET',
            headers: { 'Authorization': CONSTANTS.authToken }
        }).then(response => response.json()).then(this.setServicesResults).catch(this.loadFailed);
    }

    setServicesResults(response) {
        if (response.success) {
            this.setState({ servicesPayload: response.payload }, () => {
                this.setEligibleServices(this.state.servicesPayload)
            })
        } else {
            this.showError(response.message)
        }
    }

    setEligibleServices(services) {
        const radiobtns = []
        var selectedServiceName = ''
        var isTotalDeductionRequired = false
        services.map((service) => {
            if (service.id === localStorage.serviceID) {
                selectedServiceName = service.name
                isTotalDeductionRequired = service.isTotalDeductionsRequired
            }
            radiobtns.push(this.radioButton(service))
        })
        localStorage.setItem("isTotalDeductionRequired", isTotalDeductionRequired)
        this.setState({ eligibleServices: radiobtns, selectedServiceName: selectedServiceName })
    }

    handleRadioButtonClick(e) {
        this.setState({ errorMessageService: '' }, () => {
            // setTimeout(() => { this.updateTicketPayload('landNumber', '') }, 200);
            // setTimeout(() => { this.updateTicketPayload('financeAmount', 0) }, 210);
        })
        var name = e.target.name
        var id = e.target.id
        localStorage.setItem('serviceID', id)
        this.setState({ selectedServiceID: id, selectedServiceName: name, requireSocialTechnicalStudy: this.getIsSocialStudyRequired(id), socialStudyPayload: this.state.socialStudyEmptyPayload }, () => {
            this.getOldTicket(this.state.applicationsWithTickets, this.state.selectedServiceID)
            this.servicesPopClick()
            this.updateTicketPayload('serviceTypId', id)
            localStorage.setItem("requireSocialTechnicalStudy", this.getIsSocialStudyRequired(id))
            localStorage.setItem("isTotalDeductionRequired", this.getIsTotalDeductionRequired(id))
        })
        setTimeout(() => { this.requiredCreationDate() }, 100);
        setTimeout(() => { this.showSecondWifeOption() }, 100);
    }

    getIsTotalDeductionRequired(serviceID) {
        var isTotalDeductionRequired = false
        this.state.servicesPayload.some((service) => {
            isTotalDeductionRequired = service.isTotalDeductionsRequired
            return serviceID === service.id
        })
        return isTotalDeductionRequired
    }

    getIsSocialStudyRequired(serviceID) {
        var isSocialStudyRequired = false
        this.state.servicesPayload.some((service) => {
            isSocialStudyRequired = service.requireSocialTechnicalStudy
            return serviceID === service.id
        })
        return isSocialStudyRequired
    }

    changeListener(e, isValid) {
        this.reset()
        this.updateStepNumber(1)
        const { name, value } = e.target
        this.setState({ [name]: value })
        if (isValid || name === "TownNo") {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    radioButton(props, showMessage) {
        let addClassZIndex = ["eligibleServicesInfoWrapper"];
        if (showMessage) {
            addClassZIndex.push("infoZindex");
        }
        return (
            <div className="eligibleServicesRadioBox" key={props.id}>
                <div className="eligibleServicesBox">
                    <div className={addClassZIndex.join(' ')}>
                        <i><img name={props.id} src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} onClick={this.eligibleInfoClick} alt="eligibleInfo" /></i>
                        {showMessage ? <div className="eligibleInfo">{translate("eligibleInfoIconMsg")}<a href={props.serviceInfoLink}>{translate("clickhere")}</a></div> : null}
                    </div>
                    <input type="radio" id={props.id} name={props.name} onChange={this.handleRadioButtonClick} />
                    <label htmlFor={props.id}>{props.name}</label>
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/' + props.id + '.svg'} onError={(e) => { e.target.onerror = null; e.target.src = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/' + 0 + '.svg' }} alt={props.name} /></i>
                </div>
            </div>
        )
    }

    changeListenerNoValidation(e) {
        this.reset()
        this.updateStepNumber(1)
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    postData() {
        this.setState({ showLoading: true }, () => {
            fetch(CONSTANTS.generateHousingRequestEndPoint + "?step=2&local=" + this.context.state.siteLang, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': CONSTANTS.authToken
                },
                body: new URLSearchParams(this.state.ticketResponse),
            }).then(response => response.json()).then(this.setResults).catch(this.loadFailed);
        })
    }

    setResults(response) {
        if (response.success) {
            this.setState({ successMessage: response.message, showLoading: false, submitSuccess: false }, () => {
                this.finish()
            })
        } else {
            this.setState({ showLoading: false }, () => {
                this.showError(response.message)
            })
        }
    }

    finish() {
        History.push({ pathname: "/housingRequests", state: { ticketNo: this.state.ticketResponse.ticket, serviceCode: Utils.getHappinessMeterServiceCode(this.state.selectedServiceID) } })
    }

    updateStepNumber(stepNo) {
        this.setState({ stepNo: stepNo })
    }

    getChecked(stepNo) {
        var isChecked = false
        if (this.state.errors.length === 0) {// && !this.state.error
            isChecked = true
        } else {
            this.setState({ stepNo: stepNo - 1 })
        }
        return isChecked
    }

    submitRequest() {
        if (!this.state.temsAccept) {
            this.setState({ error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
        } else {
            var payload = this.state.ticketResponse
            if (payload.isObligationOtpRequired) {
                this.requestOTP()
            } else {
                this.postData()
            }
        }
    }

    requestOTP() {
        this.reset()
        this.setState({ showLoading: true }, () => {
            fetch(CONSTANTS.obligationOTPEndPoint + '/' + this.state.ticketResponse.ticket + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.otpRequestSuccess).catch(this.otpRequestFailed);
        })
    }

    otpRequestSuccess(response) {
        if (response.success) {
            toast.success(response.message)
            this.setState({ showLoading: false, showObligation: true, otp: '' })
        } else {
            this.setState({ showLoading: false, showObligation: false, otp: '' }, () => {
                this.showError(response.message)
            })
        }
    }

    otpRequestFailed(response) {
        this.setState({ showLoading: false, showObligation: false, otp: '' }, () => {
            this.showError(response.message)
        })
    }

    verifyOTP() {
        this.reset()
        if (this.state.otp.length > 0) {
            this.setState({ showLoading: true }, () => {
                fetch(CONSTANTS.obligationVerifyEndPoint + '/' + this.state.ticketResponse.ticket + "?local=" + this.context.state.siteLang + "&otp=" + this.state.otp, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': CONSTANTS.authToken
                    }
                }).then(response => response.json()).then(this.otpVerifySuccess).catch(this.otpVerifyFailed);
            })
        } else {
            this.showError(translate("requiredErrorMessage"))
        }
    }

    otpVerifySuccess(response) {
        this.setState({ showLoading: false, showObligation: false })
        if (response.success) {
            toast.success(response.message)
            this.updateTicketPayload("isObligationOtpRequired", false)
        } else {
            this.showError(response.message)
        }
    }

    otpVerifyFailed(response) {
        this.setState({ showLoading: false, showObligation: false })
        this.showError(response.message)
    }

    getTicket(ticket) {
        this.setState({ showLoading: true }, () => {
            fetch(CONSTANTS.viewHousingRequestEndPoint + '?ticket=' + ticket + "&local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setViewTicketResults).catch(this.loadFailed);
        })
    }

    setViewTicketResults(response) {
        if (response.success) {
            localStorage.setItem('serviceID', response.payload.serviceTypId)
            this.setState({ ticketResponse: response.payload, isRefresh: false, selectedServiceIDold: response.payload.serviceTypId.toString(), selectedServiceID: response.payload.serviceTypId.toString() })
            this.getEligibleServices(JSON.parse(localStorage.dashboard).payload.maritalStatus)
            setTimeout(() => { this.requiredCreationDate() }, 100);
            setTimeout(() => { this.setDates() }, 100);
            setTimeout(() => { this.setState({ showLoading: false }) }, 100);
            setTimeout(() => { this.setState({ canApplyForSecondWife: response.payload.isApplyingForSecondWife }) }, 100);
        } else {
            this.showError(response.message)
        }
    }

    showSecondWifeOption() {
        var showSecondWifeOption = false
        if (this.state.applicationsWithTickets) {
            var applicationsForServiceCode = this.getApplicationsForServiceCode(this.state.applicationsWithTickets, this.state.selectedServiceID)
            var ticketsForServiceCode = this.getTicketsForServiceCode(this.state.applicationsWithTickets, this.state.selectedServiceID)
            var hasValidTicket = this.validateTickets(ticketsForServiceCode)
            var hasValidApplication = this.validateApplications(applicationsForServiceCode)
            if (this.validateGenderForSecondWife()) {
                if ((hasValidApplication && !hasValidTicket)) {
                    showSecondWifeOption = true
                }
                if ((!hasValidApplication && hasValidTicket)) {
                    showSecondWifeOption = ticketsForServiceCode.some((ticket) => {
                        return ticket.status !== 0 ? true : false
                    })
                }
                else if (hasValidApplication && this.hasTicketWithStatus60(this.getValidApplications())) {
                    showSecondWifeOption = true
                } else if (this.state.ticketResponse.secondWifeEmiratesId && this.state.selectedServiceIDold === this.state.selectedServiceID) {
                    showSecondWifeOption = true
                }
            }
        }
        console.log(showSecondWifeOption)
        setTimeout(() => { this.setState({ canApplyForSecondWife: showSecondWifeOption }) }, 100);

        // return showSecondWifeOption
    }

    validateGenderForSecondWife() {
        if (this.state.gender === 0 || this.state.gender === 1) {
            if (this.state.maritalStatus === 0 || this.state.maritalStatus === 2 || this.state.maritalStatus === 5) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    getBoolean(stringVal) {
        if (stringVal === undefined || stringVal === null) {
            return undefined
        } else {
            return stringVal.toString().toLowerCase().trim() === 'true'
        }
    }

    handleCheckbox(e) {
        this.reset()
        this.updateStepNumber(1)
        var checked = e.target.value
        if (!checked === 'true') {
            setTimeout(function () { this.updateTicketPayload("supportingSupportTypeId", "") }.bind(this), 200)
        }
        this.updateTicketPayload("hasSupport", checked)
    }

    fetchRequiredDocuments() {
        this.setState({ response: null })
        // fetch(CONSTANTS.housingTicketRequiredDocsEndPoint + "/" + this.state.ticketResponse.ticket + "?local=" + this.context.state.siteLang, {
        fetch(CONSTANTS.housingTicketRequiredDocsEndPoint + "?local=" + this.context.state.siteLang + "&ticket=" + this.state.ticketResponse.ticket, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            },
        }).then(response => response.json()).then(this.setDocsResults).catch(this.loadFailed);
    }

    setDocsResults(response) {
        this.setState({ response: response })
    }

    isMandatoryService(serviceID) {
        return serviceID === "120" || serviceID === "125" || serviceID === "140" || serviceID === "210" || serviceID === "230" || serviceID === "235"
    }

    requiredCreationDate() {
        var serviceID = this.state.ticketResponse.serviceTypId ? this.state.ticketResponse.serviceTypId.toString() : ''
        var isRequired = serviceID === '120' || serviceID === '125' || serviceID === '230' || serviceID === '235'
        this.setState({ requiredCreationDate: isRequired })
        return isRequired
    }

    contractDateDateDateChangeListener(date) {
        var serviceID = this.state.selectedServiceID.toString()
        setTimeout(() => { this.updateStepNumber(1) }, 50)
        if (date != null) {
            setTimeout(() => { this.removeError('HousecompletionDate') }, 150)
            this.setState({ 'HousecompletionDate': date }, () => {
                this.updateTicketPayload('housecompletionDate', this.mmddyy(date))
            })
            // if (this.isDateEligible(this.state.selectedServiceID, date)) {
            //     setTimeout(() => { this.removeError('inValidDate') }, 150)
            // } else if (serviceID === '120' || serviceID === '230') {
            //     setTimeout(() => { this.addError('inValidDate') }, 150)
            //     setTimeout(() => { this.showError(translate("msg10years")) }, 100)
            // } else if (serviceID === '125' || serviceID === '235') {
            //     setTimeout(() => { this.addError('inValidDate') }, 150)
            //     setTimeout(() => { this.showError(translate("msg30years")) }, 100)
            // }
        } else {
            this.addError('HousecompletionDate')
        }
    }

    ddmmyy(date) {
        var d = new Date(date);
        var dd = (d.getDate()).toString().padStart(2, "0");
        var mm = (d.getMonth() + 1).toString().padStart(2, "0");
        var yy = d.getFullYear();
        return dd + "/" + mm + "/" + yy;
    }

    mmddyy(date) {
        var d = new Date(date);
        var dd = (d.getDate()).toString().padStart(2, "0");
        var mm = (d.getMonth() + 1).toString().padStart(2, "0");
        var yy = d.getFullYear();
        return mm + "/" + dd + "/" + yy;
    }

    isDateEligible(serviceID, houseCompletionDate) {
        if (serviceID.toString() === '120' || serviceID.toString() === '230') {
            return this.yearsBetween(new Date(), houseCompletionDate) > 10
        } else if (serviceID.toString() === '125' || serviceID.toString() === '235') {
            return this.yearsBetween(new Date(), houseCompletionDate) > 30
        }
    }

    yearsBetween(currentDate, houseCompletionDate) {
        var diff = (currentDate.getTime() - houseCompletionDate.getTime()) / 1000
        diff /= (60 * 60 * 24)
        return Math.abs(Math.round(diff / 365.25))
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.submitSuccess ? <HappinessMeter serviceCode={this.state.serviceCode} transactionID={this.state.ticketNo} /> : null}
                {this.state.showLoading ? <Loader /> : null}
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.showObligation && <div className="PopupBk"></div>}
                {this.state.showObligation && <div className="obligationPop">
                    <h2>{translate('Obligationtobuild')}</h2>
                    <p>{translate('ObligationtobuildBody')}</p>
                    <p className="note"><strong>{translate('note')}: </strong>{translate('documentobligationnote')}</p>
                    <div className="row">
                        <div className="col-md-12 popupOTP otpResendWrapper">
                            <label><strong>{translate('PleaseenteryourOTP')}</strong></label>
                            {/* <input type="number" maxLength="6" name="otp" onChange={this.handleChange}></input> */}
                            <div className='otpResendInputBox'>
                                <InputComponent inputProps={{ lable: "", name: 'otp', type: 'number', changeListener: this.handleChange, maxLength: 6 }} />
                                <button className='otpResendBtn' onClick={this.requestOTP}>{translate('ResendPinCode')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 obligationPopBtn">
                            <button type="submit" onClick={this.verifyOTP}>{translate('submit')}</button>
                        </div>
                    </div>
                </div>}
                {/* All Services Popup */}
                {this.state.showServies ? <div className="PopupBk" onClick={this.servicesPopClick}></div> : null}
                {this.state.showServies ? <div className="viewPopup allServicesOpoup">
                    <button className="popupClose" onClick={this.servicesPopClick}>X</button>
                    <div className="row">
                        <div className="col-md-12"><h3><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Services_icon.svg'} alt="services" /> {translate('selectedService')}</h3></div>
                    </div>
                    <div className="row viewPopupContant">
                        {this.state.eligibleServices && this.state.eligibleServices.length > 0 ? this.state.eligibleServices : null}
                    </div>
                </div> : null}
                {/* End All Services Popup */}
                {!this.state.submitSuccess ? <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item"><a href="/housingRequests">{translate('housing-requests-widget-heading')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('newHousingRequest')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                        {this.state.acceptClick ? <div className="acceptPop">
                            <button className="popupClose" onClick={this.acceptClick}>X</button>
                            <h2>{translate('TermsOfUse')}</h2>
                            <p>{translate('eligibleterms')}</p>
                            <div className="col-md-12 acceptPopBtn">
                                <div className="row">
                                    <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
                                </div>
                            </div>
                        </div> : null}
                        {this.state.ticket ? <div className='col-md-12'>
                            <div className="service_contant_wrapper formSection">
                                <div className="row ticketNumberWrapper">
                                    <div className="col-sm-6">
                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ticket.svg" alt="ticket" />
                                        <span>{translate('ticket-number')}  {this.state.ticket} </span>
                                    </div>
                                    <div className="col-sm-6 buttons">
                                        <button onClick={() => { History.push("/housingRequests") }}>{translate("back")}</button>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        <div className="col-md-12">
                            <div className="service_contant_wrapper">

                                <div className="alert alert-info" role="alert">
                                    <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="information" /></span>
                                    <span>{translate('eligiblenote')}</span>
                                </div>
                                <div className="row">
                                    <div className="col-md-12"><h3><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Services_icon.svg'} alt="services" /> {translate('selectedService')}</h3></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 selectServicesWrapper">
                                        {this.state.selectedServiceID !== '' ?
                                            <div className="selectedServicesInfo">
                                                <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/' + this.state.selectedServiceID + '.svg'} onError={(e) => { e.target.onerror = null; e.target.src = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/' + 0 + '.svg' }} alt="Service" /></span>
                                                <span>{this.state.selectedServiceName}</span>
                                            </div> : null}
                                        {this.state.selectedServiceID !== '' ?
                                            <button onClick={this.servicesPopClick}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/change.svg'} alt="Change" /></i> {translate('changeServiceType')} </button> :
                                            <button onClick={this.servicesPopClick}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/change.svg'} alt="Change" /></i> {translate('selecteService')} </button>}
                                    </div>
                                </div>
                                <br></br>
                                {this.state.errorMessageService}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="accordionHousing formSection">
                                <div className="AccordionWrapper newHousingRequest">
                                    <div className="AccordionItem">
                                        <input name="houserequest" type="radio" id="13" checked={this.state.stepNo >= 1} onChange={e => { }} />
                                        <label className="tab-label" htmlFor="13">
                                            <h3><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/finance.svg'} alt="finance" /> {translate('employmentFinanceInformation')} </h3>
                                            <span>{translate('ShowAll')}</span>
                                        </label>
                                        <div className="AccordionContent" >
                                            <div className="row">
                                                <InputComponent inputProps={{ lable: translate('PrimaryMobileNo'), name: 'mobileNo', isRequired: true, disabled: false, defaultValue: '', value: this.state.ticketResponse.mobileNo && this.state.ticketResponse.mobileNo, type: 'phone', placeHolder: this.context.state.siteLang === LOCALES.ENGLISH ? "Enter Mobile No(e.g. 00971-5xxxxxxxx)" : "(e.g. 00971-5xxxxxxxx)ادخل رقم الهاتف المتحرك", changeListener: this.handleChange }} />
                                                <InputComponent inputProps={{ lable: translate('email'), name: 'email', isRequired: true, disabled: false, defaultValue: '', value: this.state.ticketResponse.email && this.state.ticketResponse.email, type: 'email', placeHolder: this.context.state.siteLang === LOCALES.ENGLISH ? "Enter Email(example@mbrhe.ae)" : "ادخل البريد الالكتروني (example@mbrhe.ae)", changeListener: this.handleChange }} />
                                                <InputComponent inputProps={{ lable: translate('monthlyIncome'), name: 'salary', isRequired: true, disabled: false, defaultValue: '', value: this.state.ticketResponse.salary && this.state.ticketResponse.salary, type: 'number', placeHolder: this.context.state.siteLang === LOCALES.ENGLISH ? "Enter Monthly Income" : "ادخل البريد الالكتروني", changeListener: this.handleChange }} />
                                                {this.state.canApplyForSecondWife ?// && this.state.ticketResponse.isApplyingForSecondWife && this.getBoolean(this.state.ticketResponse.isApplyingForSecondWife)
                                                    <InputComponent inputProps={{ lable: translate('secondwifeEID'), name: 'secondWifeEmiratesId', isRequired: true, disabled: false, defaultValue: '', value: this.state.ticketResponse.secondWifeEmiratesId && this.state.ticketResponse.secondWifeEmiratesId, type: 'eid', placeHolder: this.context.state.siteLang === LOCALES.ENGLISH ? "Second wife Emirates ID" : "الهوية الاماراتية للزوجة الثانية", changeListener: this.handleChange }} />
                                                    : null}
                                                {this.state.requiredCreationDate ?
                                                    <div className="inputFormRow col-md-6">
                                                        <label>{translate('housecompletiondate')}<span className="redStar">*</span></label>
                                                        <DatePicker endDate={new Date()} readOnly={this.state.ticketResponse && this.state.ticketResponse.requestStatusId === 10} dateFormat="dd/MM/yyyy" selected={this.state.HousecompletionDate && this.state.HousecompletionDate} onChange={date => this.contractDateDateDateChangeListener(date)} maxDate={new Date()} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" />
                                                    </div> : null}
                                                {this.state.selectedServiceID !== '' && this.isMandatoryService(this.state.selectedServiceID) ? <div className="col-md-12">
                                                    <div className='row'>
                                                        <InputComponent isError={false} inputProps={{ maxLength: 20, lable: translate("LandNo"), name: 'landNumber', isRequired: true, defaultValue: '', value: this.state.ticketResponse && this.state.ticketResponse.landNumber !== undefined && this.state.ticketResponse.landNumber !== "null" ? this.state.ticketResponse.landNumber : '', type: 'text', placeHolder: this.context.state.siteLang === LOCALES.ENGLISH ? "Enter the Land Number" : "ادخل رقم قطعة الأرض ", changeListener: this.handleChange }} />
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-12">
                                                            <div className="alert alert-info landNumberInfoNote" role="alert">
                                                                <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="information" /></span>
                                                                <span>{translate("landNumberMessage")}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null}
                                            </div>
                                            <div className="col-md-12 formSectionBtn ">
                                                <button onClick={this.goNext} className="stepNextBtn">{translate('next')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.requireSocialTechnicalStudy ? <div className="AccordionWrapper newHousingRequest">
                                    <div className="AccordionItem">
                                        <input name="houserequest" type="radio" id="14" checked={this.state.stepNo >= 2 && this.getChecked()} disabled={this.state.stepNo < 2 || this.state.errors.length > 0} onChange={e => { }} />
                                        <label className="tab-label" htmlFor="14">
                                            <h3>
                                                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/documents.svg'} alt="Social Information" /> {translate('SocialInformation')} </h3>
                                        </label>
                                        <div className="AccordionContent" >
                                            <SocialInformation reference={this} />
                                        </div>
                                    </div>
                                </div> : null}
                                {this.state.requireSocialTechnicalStudy ? <div className="AccordionWrapper newHousingRequest">
                                    <div className="AccordionItem">
                                        <input name="houserequest" type="radio" id="15" checked={this.state.stepNo >= 3 && this.getChecked()} disabled={this.state.stepNo < 3 || this.state.errors.length > 0} onChange={e => { }} />
                                        <label className="tab-label" htmlFor="15">
                                            <h3>
                                                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/documents.svg'} alt="Additional Components for Housing" /> {translate('HouseAdditionComponents')} </h3>
                                        </label>
                                        <div className="AccordionContent" >
                                            <HouseAddition reference={this} />
                                        </div>
                                    </div>
                                </div> : null}
                                {this.state.requireSocialTechnicalStudy ? <div className="AccordionWrapper newHousingRequest">
                                    <div className="AccordionItem">
                                        <input name="houserequest" type="radio" id="16" checked={this.state.stepNo >= 4 && this.getChecked()} disabled={this.state.stepNo < 4 || this.state.errors.length > 0} onChange={e => { }} />
                                        <label className="tab-label" htmlFor="16">
                                            <h3>
                                                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/documents.svg'} alt="Components of the required addition to the supplement" /> {translate('ExtensionAdditionComponents')} </h3>
                                        </label>
                                        <div className="AccordionContent" >
                                            <ExtensionAdditionComponents reference={this} />
                                        </div>
                                    </div>
                                </div> : null}
                                <div className="AccordionWrapper newHousingRequest">
                                    <div className="AccordionItem">
                                        <input name="houserequest" type="radio" id="17" checked={this.state.stepNo >= 5 && this.getChecked()} disabled={this.state.stepNo < 5 || this.state.errors.length > 0 || !this.state.temsAccept} onChange={e => { }} />
                                        <label className="tab-label" htmlFor="17">
                                            <h3><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/documents.svg'} alt="Documents" /> {translate('Documents')}</h3>
                                        </label>
                                        <div className="AccordionContent" >
                                            {this.state.response ? <StepDocuments reference={this} /> : null}
                                        </div>

                                    </div>
                                </div>
                                {this.state.stepNo > 1 && this.state.errors.length <= 0 ? <div className="housingRequestSteps customCheckBox ">
                                    <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
                                    {this.context.state.siteLang === 'en' ?
                                        <label htmlFor="acceptCB">{translate('IAgreeToTheWebsite')} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/terms%20of%20use/", "_blank")} href="">{translate('TermsOfUse')}</a> and <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/privacy%20policy/", "_blank")} href="">{translate('privacypolicy')}</a>.</span></label> :
                                        <label htmlFor="acceptCB">{"أوافق على"} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/terms%20of%20use/", "_blank")} href="">{"شروط الاستخدام"}</a> كما وأوافق على <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/privacy%20policy/", "_blank")} href="">{"سياسة الخصوصية"}</a> للموقع الإلكتروني الخاص بمؤسسة محمد بن راشد للإسكان.</span></label>}
                                </div> : null}
                            </div>
                        </div>
                    </div>
                    {this.state.error}
                    {this.state.errorMessage}
                </div> : <div className="constructionLoanDisbursementStepSuccussWrapper">
                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/submittedSuccessfully.svg'} alt="Submitted Successfully" />
                    <p>{this.state.successMessage}</p>
                    <button className="stepFinishBtn" onClick={this.finish}>{translate('finish')}</button>
                </div>}
            </div >
        );
    }
}

/*/iskan/v1/applications?local=en for tickets listing*/

export default AuthenticatedComponent(NewHousingRequestComponent)