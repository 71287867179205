import AppDispatcher from '../../Dispatcher';
import OpenNewProjectFileConstant from './OpenNewProjectFileConstant';


class OpenNewProjectFileAction {

  projectFileBOQ(response) {
    AppDispatcher.dispatch({
      actionType: OpenNewProjectFileConstant.OPEN_NEW_PROJECT_FILE_BOQ,
      response: response
    })
  }

  projectFileAddHeader(response) {
    AppDispatcher.dispatch({
      actionType: OpenNewProjectFileConstant.OPEN_NEW_PROJECT_FILE_ADD_HEADER,
      response: response
    })
  }

  listOwnerScope(response) {
    AppDispatcher.dispatch({
      actionType: OpenNewProjectFileConstant.LIST_OWNER_SCOPE,
      response: response
    })
  }

  saveAndModifyOwnerScope(response) {
    AppDispatcher.dispatch({
      actionType: OpenNewProjectFileConstant.SAVE_MODIFY_OWNER_SCOPE,
      response: response
    })
  }

  clearOrDeleteOwnerScope(response) {
    AppDispatcher.dispatch({
      actionType: OpenNewProjectFileConstant.CLEAR_OR_DELETE_OWNER_SCOPE,
      response: response
    })
  }
}

export default new OpenNewProjectFileAction();
