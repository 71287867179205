import React from 'react'
import NoData from '../utils/nodata'
import Loader from '../utils/LoaderBars'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../constants/dashboard-constants'
import ApplicationStatusItem from './application-status-item'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
class HousingRequestService extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            props: '',
            response: '',
            showLoading: false,
            showComplementaryServices: false
        }
        this.loadFailed = this.loadFailed.bind(this)
        this.onArrowClick = this.onArrowClick.bind(this)
        this.setApplicationStatus = this.setApplicationStatus.bind(this)
        this.fetchApplicationStatus = this.fetchApplicationStatus.bind(this)
        this.getComplementaryServices = this.getComplementaryServices.bind(this)
        // this.downloadApprovalLetter = this.downloadApprovalLetter.bind(this)
        // this.fetchApprovalLetter = this.fetchApprovalLetter.bind(this)
    }

    componentDidMount() {
        this.fetchApplicationStatus(this.props)
    }

    fetchApplicationStatus(props) {
        this.setState({ showLoading: true, props: props })
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.applicationStatusEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplicationStatus).catch(this.loadFailed);
        }
    }

    setApplicationStatus(response, clickIndex, isShown) {
        var applicationStatusItems = []
        var items = response.payload
        var showComplementaryServices = false
        items.map((application, index) => {
            if (clickIndex != null && clickIndex === index.toString()) {
                if (isShown === 'false') {
                    showComplementaryServices = true
                }
            } else {
                showComplementaryServices = false
            }
            return (applicationStatusItems.push(<ApplicationStatusItem key={index} index={index} onArrowClick={this.onArrowClick} showComplementaryServices={showComplementaryServices} payload={application} complementaryServicesArray={this.getComplementaryServices(application.supportingApplication)} />))
        })
        this.setState({ showLoading: false, response: response, itemsArray: applicationStatusItems })
    }

    loadFailed(response) {
        this.setState({ showLoading: false })
        toast.error(response.message)
    }

    onArrowClick(e) {
        var index = e.target.id
        var isShown = e.target.name
        this.setState({ showComplementaryServices: !this.state.showComplementaryServices }, () => {
            this.setApplicationStatus(this.state.response, index, isShown)
        })
    }

    getComplementaryServices(servicesArray) {
        var itemsArray = []
        servicesArray.map((item, index) => {
            itemsArray.push(
                <div className="housingAccordionContent">
                    <strong>{item.typeName}</strong>
                    <div>
                        <span class="housingRequestsince">{translate('applicationNo')}:</span>
                        <t></t><strong className="selectedcolor-20">&nbsp;{item.supportingAppNo}</strong>
                    </div>
                    <div>
                        <span class="housingRequestsince">{translate('status')}</span>
                        <t></t><strong className="selectedcolor-20">&nbsp;{item.status}</strong>
                    </div>
                    <p class="housingRequestsince"> {item.since} | {item.createdDate} </p>
                </div>
            )
        })
        return (itemsArray)
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.showLoading ? <Loader /> : null}
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('application-status-widget-heading')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ApplicationStatus.svg'} alt="" /></i><span>{translate('application-status-widget-heading')}</span></div>
                        </div>
                        <div className="col-md-12">
                            {this.state.error}
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    {this.state.itemsArray.length > 0 ? this.state.itemsArray : <NoData message={translate('nodata')} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AuthenticatedComponent(HousingRequestService)