import React from 'react'
import History from '../../../History'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import InputComponent from '../../utils/inputComponent'
import ErrorComponent from '../../utils/errorComponent'
import { ScrollToTop } from '../../../utils/Scroll'
import CONSTANTS from '../../constants/dashboard-constants'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'

class CancelProgresHousingServiceComponentComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            ApplicantName: '',
            ApplicationNumber: '',
            ServicesType: '',
            ApplicationsStatus: '',
            mobileNo: '',
            email: '',
            success: false,
            successComponent: null,
            applicationsPayload: '',
            errors: [],
            applicationsArray: [],
            reasonsArray: [],
            reasonId: 0,
            temsAccept: false,
            showTextBox: false,
            ticketNo: '',
            acceptClick: false,
            requestType: 1,
            ReasonDesc: ''
        }
        this.changeListener = this.changeListener.bind(this)
        this.submit = this.submit.bind(this)
        this.cancel = this.cancel.bind(this)
        this.getAvailableApplications = this.getAvailableApplications.bind(this)
        this.setApplicationsList = this.setApplicationsList.bind(this)
        this.getReasonsList = this.getReasonsList.bind(this)
        this.setReasonsList = this.setReasonsList.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
        this.prepareApplicationsArray = this.prepareApplicationsArray.bind(this)
        this.prepareReasonsList = this.prepareReasonsList.bind(this)
        this.changeApplication = this.changeApplication.bind(this)
        this.getApp = this.getApp.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.showError = this.showError.bind(this)
        this.changeReason = this.changeReason.bind(this)
        this.submitCancelRequest = this.submitCancelRequest.bind(this)
        this.acceptClick = this.acceptClick.bind(this)
        this.getTicket = this.getTicket.bind(this)
        this.completeRequest = this.completeRequest.bind(this)
        this.applicationListPageClick = this.applicationListPageClick.bind(this)
    }

    componentDidMount() {
        this.getReasonsList()
        ScrollToTop()
        var ticketNo = this.props.location.state.ticketNo
        var applicationNo = this.props.location.state.applicationNo
        this.setState({ applicationStatus: this.props.location.state.applicationStatus })
        if (ticketNo.length > 0) {
            localStorage.setItem('ticketNo', ticketNo)
            this.setState({ ticketNo: ticketNo }, () => {
                this.getTicket(ticketNo)
            })
        } else {
            this.setState({ ApplicationNumber: applicationNo }, () => {
                this.submitCancelRequest()
            })
        }
        this.setState({ ApplicantName: JSON.parse(localStorage.dashboard).payload.name })
    }

    getTicket(ticketNo) {
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.cancelRequestViewTicketEndpoint + "/" + ticketNo + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then((response) => {
                this.setState({
                    ApplicantName: response.payload.name,
                    ApplicationNumber: response.payload.applicationNo,
                    ServicesType: response.payload.serviceType,
                    ApplicationsStatus: response.payload.applicationStatusDesc,
                    mobileNo: response.payload.mobileNo,
                    email: response.payload.email,
                    reasonId: response.payload.reasonId,
                    ReasonDesc: response.payload.reasonDesc,
                    ticketNo: response.payload.ticket,
                    requestType: response.payload.requestType,
                    showTextBox: response.payload.reasonId === 10
                }, () => {
                    if (response.payload.reasonId === 10 && (response.payload.reasonDesc && (response.payload.reasonDesc.length < 20 || response.payload.reasonDesc > 200))) {
                        this.addError('ReasonDesc')
                    } else {
                        this.removeError('ReasonDesc')
                    }
                })
            }).catch(this.loadFailed);
        }
    }

    changeListener(e, isValid) {
        this.closeCallback()
        const { name, value } = e.target
        if (isValid) {
            this.removeError(name)
        } else if (e.target.required) {
            this.addError(name)
        }
        this.setState({ [name]: value })
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    submit() {
        if (this.state.errors.length > 0) {
            if (this.state.errors[0] === 'ReasonDesc') {
                this.showError(translate("DescrMessage"))
            } else {
                this.showError(translate("requiredErrorMessage"))
            }
        } else {
            if (this.state.temsAccept) {
                this.completeRequest()
            } else {
                this.showError(translate('acceptterms'))
            }
        }
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    cancel() {
        History.push({ pathname: '/CustomerDashboard' })
    }

    /*Applications API*/
    getAvailableApplications() {
        fetch(CONSTANTS.listApplicationsAllowedToCancelEndpoint + "?local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.setApplicationsList).catch(this.loadFailed);
    }

    prepareApplicationsArray(response) {
        var array = []
        response.payload.map((application) => {
            return (array.push(<option value={application.applicationNo} key={application.applicationNo}>{application.service}</option>))
        })
        this.setState({
            applicationsPayload: response.payload,
            applicationsArray: array,
            ApplicationNumber: response.payload[0].applicationNo,
            ServicesType: response.payload[0].service,
            ApplicationsStatus: response.payload[0].statusName,
            // mobileNo: response.payload[0].mobile,
            // email: response.payload[0].email
        })
    }

    setApplicationsList(response) {
        if (response.success) {
            this.prepareApplicationsArray(response)
        }
    }
    /*Applications API*/

    /*Reasons API*/
    getReasonsList() {
        fetch(CONSTANTS.applicationReasonsListEndpoint + "?local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.setReasonsList).catch(this.loadFailed);
    }

    prepareReasonsList(response) {
        var array = []
        response.payload.map((reason) => {
            return (array.push(<option value={reason.id} key={reason.id}>{reason.name}</option>))
        })
        this.setState({ reasonsArray: array })
    }

    setReasonsList(response) {
        if (response.success) {
            this.prepareReasonsList(response)
        }
    }
    /*Reasons API*/

    loadFailed(response) {
        console.log(response)
    }

    changeApplication(e) {
        let app = this.getApp(e.target.value)
        this.setState({
            ApplicationNumber: app.applicationNo,
            ServicesType: app.service,
            ApplicationsStatus: app.statusName,
            // mobileNo: app.mobile,
            // email: app.email
        })
    }

    changeReason(e) {
        this.closeCallback()
        var showBox = false
        if (e.target.value.toString() === '10') {
            showBox = true
            this.addError('ReasonDesc')
        } else {
            this.removeError('ReasonDesc')
        }
        this.setState({ reasonId: e.target.value, showTextBox: showBox }, () => {
            this.submitCancelRequest()
        })
    }

    getApp(appNo) {
        return this.state.applicationsPayload.find((app) => {
            return app.applicationNo.toString() === appNo
        })
    }

    submitCancelRequest() {
        if (this.state.errors.length === 0) {
            var data = new URLSearchParams()
            data.append('ApplicationNo', this.state.ApplicationNumber)
            data.append('ReasonId', this.state.reasonId)
            data.append('TicketNo', this.state.ticketNo)
            data.append('ReasonDesc', this.state.ReasonDesc)
            data.append('mobileNo', this.state.mobileNo.length > 0 ? this.state.mobileNo : JSON.parse(localStorage.dashboard).payload.primaryMobileNo);
            data.append('email', this.state.email.length > 0 ? this.state.email : JSON.parse(localStorage.dashboard).payload.email);
            fetch(CONSTANTS.applicationCancelSubmitEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': CONSTANTS.authToken
                },
            }).then(response => response.json()).then((response) => {
                if (response.success) {
                    this.setState({ ticketNo: response.ticketNo }, () => {
                        localStorage.setItem('ticketNo', response.ticketNo)
                        this.getTicket(this.state.ticketNo)
                    })
                } else {
                    this.getTicket(localStorage.getItem('ticketNo'))
                }
            }).catch(this.loadFailed)
            // }
        } else {
            if (this.state.errors[0] === 'ReasonDesc') {
            } else {
                this.showError(translate("requiredErrorMessage"))
            }
        }
    }

    completeRequest() {
        fetch(CONSTANTS.cancelRequestCompleteEndpoint + "?ticketNo=" + this.state.ticketNo + "&local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': CONSTANTS.authToken
            },
        }).then(response => response.json()).then((response) => {
            if (response.success) {
                this.setState({ success: true, successComponent: <SuccessComponent message={response.message} ticket={this.state.ticketNo} /> })
            } else {
                toast.error(response.message)
            }
        }).catch(this.loadFailed)
    }

    closeCallback() {
        this.setState({ error: null })
    }

    acceptClick(e) {
        this.closeCallback()
        if (e.target.id === "accept") {
            this.setState({ errorMessage: "", acceptClick: !this.state.acceptClick, temsAccept: true }, () => { })
        }
        else {
            if (e.target.id === "acceptCB") {
                if (e.target.checked) {
                    this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false }, () => { })
                } else {
                    this.setState({ temsAccept: false })
                }
            } else {
                this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false }, () => { })
            }
        }
    }

    applicationListPageClick() {
        this.props.history.push({ pathname: "/allowedToCancelApplicationsListing", state: { applicationStatus: this.state.applicationStatus } })
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item" onClick={this.applicationListPageClick}><a href="">{translate('allowedToCancleTitle')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate(this.state.applicationStatus === 5 ? 'cancelRequest' : 'cancelApprovedRequest')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row ticketNumberWrapper">
                                    <div className="col-sm-6">
                                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ticket.svg'} alt="ticket" />
                                        <span>{translate('ticket-number')} {this.state.ticketNo}</span>
                                    </div>
                                </div>
                            </div>
                            {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                            {this.state.acceptClick ? <div className="acceptPop">
                                <button className="popupClose" onClick={this.acceptClick}>X</button>
                                <h2>{translate('TermsAndConditions')}</h2>
                                <p>{translate('eligibleterms')}</p>
                                <div className="col-md-12 acceptPopBtn">
                                    <div className="row">
                                        <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
                                    </div>
                                </div>
                            </div> : null}

                            {this.state.success ? this.state.successComponent : <div className="service_contant_wrapper formSection">
                                <div className="col-md-12">
                                    <h3><img src={this.state.applicationStatus === 5 ? 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/cancel_progress_application.svg' : 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/approved_cancel.svg'} alt="Change Request Icon" /> {translate(this.state.applicationStatus === 5 ? 'cancelRequest' : 'cancelApprovedRequest')}</h3>
                                </div>
                                <div className="col-md-12 cancel_progress_services">
                                    <div className="row">
                                        <InputComponent inputProps={{ lable: translate('applicantName'), name: 'ApplicantName', disabled: true, isRequired: true, defaultValue: this.state.ApplicantName, placeHolder: this.context.state.siteLang === 'en' ? 'Applicant Name' : 'اسم مقدم الطلب', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('ApplicationNumber'), name: 'ApplicationNumber', disabled: true, isRequired: true, defaultValue: this.state.ApplicationNumber, placeHolder: this.context.state.siteLang === 'en' ? 'Application Number' : 'رقم الطلب', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('servicesType'), name: 'ServicesType', disabled: true, isRequired: true, defaultValue: this.state.ServicesType, placeHolder: this.context.state.siteLang === 'en' ? 'Service Type' : 'نوع الخدمة', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('ApplicationsStatus'), name: 'ApplicationsStatus', disabled: true, isRequired: true, defaultValue: this.state.ApplicationsStatus, placeHolder: this.context.state.siteLang === 'en' ? 'Application Status' : 'حالة الطلب', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('mobileNo'), class: 'mobileNo col-md-6', name: 'mobileNo', isRequired: true, defaultValue: this.state.mobileNo, placeHolder: this.context.state.siteLang === 'en' ? 'Mobile Number' : 'رقم الموبايل', type: 'phone', changeListener: this.changeListener, update: this.submitCancelRequest }} />
                                        <InputComponent inputProps={{ lable: translate('email'), name: 'email', isRequired: true, defaultValue: this.state.email, placeHolder: this.context.state.siteLang === 'en' ? "Email" : 'البريد الالكتروني', type: 'email', changeListener: this.changeListener, update: this.submitCancelRequest }} />
                                        <div className="col-md-6">
                                            <label htmlFor="reasons">{translate('reasonsForCancelation')}<span className="redStar"></span></label>
                                            <select name="reasons" value={this.state.reasonId} id="reasons" onChange={this.changeReason}>
                                                {this.state.reasonsArray}
                                            </select>
                                            {this.state.showTextBox ? <InputComponent className="textArea" inputProps={{ minLength: 20, maxLength: 200, lable: translate('Enterthereason'), name: 'ReasonDesc', isRequired: true, defaultValue: this.state.ReasonDesc, placeHolder: this.context.state.siteLang === 'en' ? 'Entered text should be in between (20 to 200) characters.' : 'التوضيح يجب ان يكون ضمن عدد (20 الى 200) حرف.', type: 'textArea', changeListener: this.changeListener, update: this.submitCancelRequest }} /> : null}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 formSectionBtn">
                                            <hr></hr>
                                            <div className="customCheckBox">
                                                <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
                                                <label htmlFor="acceptCB">{translate('IAcceptingThe')}</label>
                                            </div>
                                            <a href="/CustomerDashboard"><button>{translate('cancle')}</button></a>
                                            <button onClick={this.submit}>{translate('submit')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    {this.state.error}
                </div>
            </div >
        );
    }
}

function SuccessComponent(props) {
    return (
        <div className="service_contant_wrapper formSection">
            <div className="col-md-12">
                <h3><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/change_request_icon.svg'} alt="Change Request Icon" />{translate("cancelRequest")}</h3>
            </div>
            <div className="col-md-12">
                <div className="serviceRequestMsgBox">
                    <p>{props.message ? props.message : ''}<span></span></p>
                    <a href="/CustomerDashboard"><button>{translate('back')}</button></a>
                </div>
            </div>
        </div>
    )
}

export default AuthenticatedComponent(CancelProgresHousingServiceComponentComponent)