import React from "react";
import StepZilla from "react-stepzilla";
import RequiredDocumentsComponent from '../Common/RequiredDocumentsComponent';
import ContractorProjectPaymentsStore from '../ContractorProjectPayments/ContractorProjectPaymentsStore';
import ContractorProjectPaymentsService from '../ContractorProjectPayments/ContractorProjectPaymentsService';
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent';
import { ScrollToTop } from '../../utils/Scroll';
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import CommonUtils from '../../utils/CommonUtils';


var handleValue = "";
var valueObject = '';
class RetentionPaymentDetailComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            object: JSON.parse(localStorage.getItem('projectItem')),
            statusId: Number(localStorage.getItem('statusId')),
            isChecked: true,
            message: "",
            response: "",
            inputValue: 0,
            typingTimeout: 0,
            loading: false,
            value: '',
            page: '0',
            pageSize: '250',
            isSuccessValue: false,
            transactionType: 0,
            isSuccess: false
        }
        this.getProjectPaymentsState();
        this.selectInput = this.selectInput.bind(this);
        this.getLatestResponse = this.getLatestResponse.bind(this);
        this._onChange = this._onChange.bind(this);
    }

    toggleChange = () => {
        handleValue = !this.state.isChecked;
        this.setState({
            isChecked: !this.state.isChecked
        });
        if (this.state.isChecked) {
            this.state.inputValue = 0;
            this.state.response.paymentOwner = 0;
            //this.sendApiCall();
        }
    }

    componentDidMount() {
        ScrollToTop();
        if (!this.state.isSuccess) {
            this.setState(this.getBreakDown());
        }
        if (!this.state.value && localStorage.getItem('accessToken') && localStorage.getItem('accessToken').length > 15) {
            this.getAllProjectPayments();
        }
        ContractorProjectPaymentsStore.addChangeListener(this._onChange);
    }

    getAllProjectPayments() {
        ContractorProjectPaymentsService.projectPayments(this.state.statusId, this.state.page, this.state.pageSize, this.context.state.siteLang);
    }

    componentWillUnmount() {
        ContractorProjectPaymentsStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getProjectPaymentsState());
        this.setState(this.getPPBreakDown());
        if (this.state.isSuccess && this.state.response.paymentOwner > 0) {
            this.setState({
                isChecked: true
            });
        }
    }
    getProjectPaymentsState() {
        return {
            value: ContractorProjectPaymentsStore.response,
            isSuccessValue: ContractorProjectPaymentsStore.isSuccess()
        };
    }

    onChangePaymentOwner = (event) => {
        var self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }
        self.setState({
            inputValue: event.target.value,
            typingTimeout: setTimeout(function () {
                self.sendApiCall(self.state.inputValue);
                self.state.response.paymentOwner = self.state.inputValue;
            }, 1000)
        });
    }

    sendApiCall = () => {
        ContractorProjectPaymentsService.contractorAddHeaderOwnerAmount(this.state.object.paymentId, this.state.inputValue, this.context.state.sitLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    getBreakDown() {
        ContractorProjectPaymentsService.projectPaymentsBreakDownInfo(this.state.object.id, this.state.transactionType, this.context.state.sitLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getPPBreakDown() {
        return {
            response: ContractorProjectPaymentsStore.responsePaymentHeader,
            isSuccess: ContractorProjectPaymentsStore.isSuccessPaymentHeader(),
            message: ContractorProjectPaymentsStore.messagePaymentHeader,
        };
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    selectInput() {
        ScrollToTop();
        setTimeout(function () {
            this.textInput.current.focus();
        }.bind(this), 500)
    }

    getLatestResponse() {
        return this.state.response
    }


    setCheckBoxState() {
        if (valueObject.source === "TQL") {
            return false;
        }
        if (handleValue === "" || handleValue === true) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { value, isChecked, message, response, isSuccess, isSuccessValue } = this.state


        if (isSuccessValue) {
            valueObject = CommonUtils.getIndex(value, this.state.object);
        }
        // if (!valueObject) {
        //     return null;
        // }
        var steps = [];
        if (isSuccess) {
            steps =
                [
                    {
                        name: translate('requiredDoc'), component: <RequiredDocumentsComponent dashboardResponse={response}
                            checkCheckBoxState={this.setCheckBoxState}
                            selectInput={this.selectInput}
                            getLatestValue={this.getLatestResponse}
                        />
                    }
                ]
        }

        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/contractorDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item"><a href="/projectsList">{translate('retentionPaymentRequest')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('retentionPayment')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* Loan */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/RetentionPayment.svg" alt="Contractor Payment Request" /></i>
                                {valueObject.source == 'ENG' ? <span>{translate('retentionPaymentconstructionloan')}</span> :
                                    <span>{translate('retentionPaymentmaintenancegrant')}</span>}</div>
                        </div>
                        {message ? <div className="alert alert-danger" role="alert">
                            {message}
                        </div> : null}


                        <div className="col-md-12">
                            <div className="service_contant_wrapper">
                                <div className="row">
                                    <div className="col-md-12 subTitle">
                                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/information.svg" alt="project Information" /><h3>{translate('projectInformation')}</h3>
                                    </div>
                                </div>
                                <div className="row formSection">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="ownerName">{translate('ownerName')}</label>
                                                <input id="ownerName" type="text" disabled defaultValue={valueObject.owner}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="fileNumber">{translate('fileNumber')}</label>
                                                <input id="fileNumber" type="text" disabled defaultValue={valueObject.applicationNo}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="contractAmount">{translate('contractAmount')}</label>
                                                <input id="contractAmount" type="text" disabled defaultValue={valueObject.contractAmount}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="currentPercentCompleted">{translate('currentPercentCompleted')}</label>
                                                <input id="currentPercentCompleted" type="text" disabled defaultValue={valueObject.percentageCompleted} />
                                            </div>
                                            <div className="col-md-4">
                                                {valueObject.source == 'ENG' ? <label htmlFor="loanAmount">{translate('loanAmount')}</label> : <label>{translate('grantAmount')}</label>}
                                                <input id="loanAmount" type="text" disabled defaultValue={valueObject.loanAmountDisplay}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="totalPaidAmount">{translate('totalPaidAmount')}</label>
                                                <input id="totalPaidAmount" type="text" disabled defaultValue={valueObject.totalPayment} />
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="contractStatus">{translate('contractStatus')}</label>
                                                <input id="contractStatus" type="text" disabled defaultValue={valueObject.contractStatus}></input>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="retentionAmount">{translate('retentionAmount')}</label>
                                                <input id="retentionAmount" type="text" disabled defaultValue={valueObject.totalRetentions}></input>
                                            </div>
                                        </div>
                                    </div>
                                    {valueObject.source == "ENG" ? <div className="col-md-12">
                                        <div className="col-md-12 customCheckBox">
                                            <input type="checkbox" checked={isChecked} id="Iaccept" name="isChecked" defaultValue="" onChange={this.toggleChange} />
                                            <label htmlFor="Iaccept">{translate('sendAmountOwner')}</label>
                                            <span className="note">{translate('kindlySpecify')}</span>
                                        </div>
                                        {response && isChecked ? <div className="col-md-4">
                                            <label htmlFor="paymentToOwner">{translate('paymentToOwner')}</label>
                                            <input id="paymentToOwner" type="text" ref={this.textInput} defaultValue={response.paymentOwner} onChange={this.onChangePaymentOwner}></input>
                                        </div> : null}
                                    </div> : null}
                                </div>
                            </div>
                        </div>

                        {isSuccess ? <div className="col-md-12">
                            <div className="service_contant_wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='step-progress'>
                                            <StepZilla showNavigation={false} stepsNavigation={false} steps={steps} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> : null}
                    </div>

                </div>
            </div>
        );
    }
}

export default AuthenticatedComponent(RetentionPaymentDetailComponent);