import React from "react"
import translate from '../Providers/i18n/Translate'
import { LOCALES } from '../Providers/i18n'
import { AppContext } from '../Providers/context'

class WelcomeMessage extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            locale: props.locale,
            siteLang: props.siteLang,
            // divClick: false,
            chatClicked: false
        }
        // this.divClick = this.divClick.bind(this)
        this.chatClicked = this.chatClicked.bind(this)
        this.handleChatBox = this.handleChatBox.bind(this)
        this.handleWelcomeBox = this.handleWelcomeBox.bind(this)

    }

    handleWelcomeBox() {
        this.props.handleWelcomeBox();
    }

    handleFabCheckbox() {
        return true;
    }

    handleChatBox(lang) {
        this.props.handleChatBox(lang);
    }

    // divClick() {
    //     this.setState({ divClick: !this.state.divClick })
    // }

    chatClicked() {
        var userString = localStorage.getItem('dashboard') ? localStorage.getItem('dashboard') : null
        if (userString != null) {
            var userPayload = JSON.parse(userString).payload
            window.$zopim.livechat.setName(userPayload.name)
            window.$zopim.livechat.setEmail(userPayload.email)
            window.$zopim.livechat.setPhone(userPayload.primaryMobileNo)
            window.$zopim.livechat.setLanguage(localStorage.getItem('siteLang'))
        }
        window.$zopim.livechat.window.toggle()
    }
    
    render() {
        const locale = this.state.locale;
        const whatsAppMobile = this.state.siteLang === 'en' ? 'https://api.whatsapp.com/send/?phone=97142029444&text=Hello%20Muftah,%20Could%20I%20know%20the%20Housing%20Application%20Status' : 'https://api.whatsapp.com/send/?phone=97142029444&text=%D9%85%D8%B1%D8%AD%D8%A8%D8%A7%20%D9%85%D9%8F%D9%81%D8%AA%D8%A7%D8%AD,%20%D8%A7%D8%B1%D9%8A%D8%AF%20%D9%85%D8%B9%D8%B1%D9%81%D8%A9%20%D8%AD%D8%A7%D9%84%D8%A9%20%D8%A7%D9%84%D8%B7%D9%84%D8%A8%20%D8%A7%D9%84%D8%A5%D8%B3%D9%83%D8%A7%D9%86%D9%8A';
        const whatsAppWeb = this.state.siteLang === 'en' ? 'https://web.whatsapp.com/send??phone=97142029444&text=Hello%20Muftah,%20Could%20I%20know%20the%20Housing%20Application%20Status' : 'https://web.whatsapp.com/send?phone=97142029444&text=%D9%85%D8%B1%D8%AD%D8%A8%D8%A7%20%D9%85%D9%8F%D9%81%D8%AA%D8%A7%D8%AD,%20%D8%A7%D8%B1%D9%8A%D8%AF%20%D9%85%D8%B9%D8%B1%D9%81%D8%A9%20%D8%AD%D8%A7%D9%84%D8%A9%20%D8%A7%D9%84%D8%B7%D9%84%D8%A8%20%D8%A7%D9%84%D8%A5%D8%B3%D9%83%D8%A7%D9%86%D9%8A';
        let isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        let whatsAppLink = isMobile ? whatsAppMobile : whatsAppWeb;


        return (

            <ul>
                <li>
                    <a data-tip data-for='talkWithMoftah' onClick={() => this.handleChatBox(locale)}>
                        <span>{translate('talk_with_moftah')}</span>
                        <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/openchat.svg" alt="talk With Moftah"/></i>
                    </a>
                </li>
                <li>
                    <a data-tip data-for='livechat' onClick={this.chatClicked}>
                        <span>{translate('livechat')}</span>
                        <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/openchat.svg" alt="livechat"/></i>
                    </a>
                </li>
            </ul>
            // <div className="fab-wrapper">
            //     <input id="fabCheckbox" type="checkbox" onClick={this.divClick} checked={this.state.divClick} onChange={() => this.handleFabCheckbox()} className="fab-checkbox" />
            //     <label className="fab" htmlFor="fabCheckbox">
            //         <img data-tip data-for='Chat' className="msgIco" src={this.state.divClick ? "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/chatbotnew.svg" : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/chatbotnew.svg"} alt="Open" />
            //     </label>
            //     <div className="fab-wheel">
            //         <div className="fab-action closeFloatIco" onClick={this.divClick}><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/chat_close.svg" alt="close" /></div>
            //         <a data-tip data-for='livechat' className="fab-action fab-action-5 livechat" onClick={this.chatClicked}>
            //             <span>{translate('livechat')}</span>
            //         </a>
            //         <a data-tip data-for='talkWithMoftah' className="fab-action fab-action-6 moftah" onClick={() => this.handleChatBox(locale)}>
            //             <span>{translate('talk_with_moftah')}</span>
            //         </a>
            //     </div>
            //     <div className="fab-wheelBack" onClick={this.divClick}></div>
            // </div>
        );
    }
}

export default WelcomeMessage;