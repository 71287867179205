import React from 'react'

class HappinessMeterComponent extends React.Component {
    constructor
        (props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.setState({ params: new URLSearchParams(this.props.location.search) }, () => {
            document.forms[0].submit()
        })
    }

    render() {
        return (
            <div className="happinessMeterWrapper">
                {this.state.params ? <form id="happinessmeter" action={this.state.params.get('path')} method="post">
                    <input type="hidden" name="json_payload" value={this.state.params.get('json_payload')} />
                    <input type="hidden" name="client_id" value={this.state.params.get('client_id')} />
                    <input type="hidden" name="signature" value={this.state.params.get('signature')} />
                    <input type="hidden" name="lang" value={this.state.params.get('lang')} />
                    <input type="hidden" name="timestamp" value={this.state.params.get('timestamp')} />
                    <input type="hidden" name="random" value={this.state.params.get('random')} />
                    <input type="hidden" name="nonce" value={this.state.params.get('nonce')} />
                </form> : null}
            </div>
        )
    }
}

export default HappinessMeterComponent