import React, { useContext } from "react"
import { LOCALES } from '../../Providers/i18n'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'

export default (props) => {
    const { dispatch, state } = useContext(AppContext);
    const globalImage = props.chatbot ? 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/global.svg' : 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/language_globe.svg';
    const locale = state.siteLang === LOCALES.ENGLISH ? LOCALES.ARABIC : LOCALES.ENGLISH;
    const setLanguage = siteLang => {
        dispatch({ type: 'setLang', siteLang });
        localStorage.setItem('siteLang', siteLang);
        window.$zopim.livechat.window.hide()
        window.location.reload();
    };

    return <React.Fragment>
        {props.chatbot ?
            // <img src={globalImage} alt="" />
            // <img src={globalImage} alt="" />
            <button data-tip data-for='language' onClick={() => setLanguage(locale)} >{translate('lang')}<span></span></button>
            : <li><button data-tip data-for='language' onClick={() => setLanguage(locale)} id="ChangeLanguag" ><span></span>{translate('lang')}</button></li>
        }
    </React.Fragment>
}