import React from 'react'
import History from '../../../History'
import NoData from '../../utils/nodata'
import ServiceItem from './services-widget-item'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import { ResponsiveContainer } from 'recharts'

class ServicesWidget extends React.Component {
    static contextType = AppContext

    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            props: '',
            eligibleServices: '',
            previousId: "",
            isInfoShown: false
        }
        this.setServicesWidget = this.setServicesWidget.bind(this)
        this.getPath = this.getPath.bind(this)
        this.getIcon = this.getIcon.bind(this)
        this.getIsTotalDeductionRequired = this.getIsTotalDeductionRequired.bind(this)
        this.setEligibleServices = this.setEligibleServices.bind(this)
        this.setServicesResults = this.setServicesResults.bind(this)
        this.radioButton = this.radioButton.bind(this)
        this.eligibleInfoClick = this.eligibleInfoClick.bind(this)
        this.handleRadioButtonClick = this.handleRadioButtonClick.bind(this)
        this.infoLinkClick = this.infoLinkClick.bind(this)
        this.getServices = this.getServices.bind(this)
        this.getIsSocialStudyRequired = this.getIsSocialStudyRequired.bind(this)
    }

    componentDidMount() {
        this.setState({ props: this.props }, () => {
            this.getServices()
        })
    }

    getServices() {
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.getNewServicesEndPoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: { 'Authorization': CONSTANTS.authToken }
            }).then(response => response.json()).then(this.setServicesResults).catch(this.loadFailed);
        }
    }

    setServicesResults(response) {
        if (response.success) {
            this.setEligibleServices(this.props, response.payload)
        } else {
            this.showError(response.message)
        }
    }

    setEligibleServices(props, services) {
        const radiobtns = []
        services.map((service) => {
            // if (service.id.length < 5)
            radiobtns.push(this.radioButton(service, false, this.infoLinkClick, this))
        })
        this.setState({ props: props, eligibleServices: radiobtns, newRequestPayload: services }, () => {
            // this.setServicesWidget(services)
        })
    }

    loadFailed(response) {
        console.log("no eligible services")
    }

    setServicesWidget(services) {
        // //add new services here
        // var serviceItems = []
        // // if (services) {
        // // var items = [...response.services, ...response.pinnedServices]
        // services.map((service, index) => {
        //     this.getPath(service.id) && serviceItems.push(<ServiceItem key={index} icon={this.getIcon(service.id)} path={this.getPath(service.id)} name={service.name} />)
        //     return (serviceItems)
        // })
        // // }
        // this.setState({ itemsArray: serviceItems })
        // // this.getEligibleServices(response.payload.maritalStatus)
    }

    getPath(id) {
        var path
        switch (id) {
            //Housing Request actionURL=HousingServiceRequest/AddHousingServiceRequest
            // case '76998782-c2a4-4dda-a7dc-437b2f6de1d5':
            //     path = '/housingRequests'
            //     break
            //Loan Calculator actionURL=Calculators/Loan
            case 'fbfcd36b-60d0-4152-b955-50ed865fc014':
                path = '/loancalculator'
                break
            //Construction Cost Calculator actionURL=Calculators/Construction
            case 'dd058258-d1fa-4686-8506-1ad00a37498d':
                path = '/constructionCostCalculator'
                break
            // //House Booking actionURL=HouseBooking/Index
            case 'ceea8a43-f5d9-47fb-bc92-871ecdcf7a80':
                path = '/houseBooking'
                break
            //Towhom
            case 'eef575c0-32e3-42ba-a6e5-e3a9a9b40235':
                path = '/toWhomItMayConcernCertificate'
                break
            //Maintanance Request
            case 'a7274fb4-4999-441a-96cf-75026a3cbf12':
                path = '/maintenanceservice'
                break
            // //Request Liability Certificate
            // case 'd73f675e-4866-46a7-819a-08bf4ac3d1d3':
            //     path = '/toWhoItMayConcern'
            //     break
            //Early Settlement
            case '6f5781ec-385e-423d-a4d8-1438facbbae0':
                path = '/earlysettlement'
                break
            default:
        }
        return path
    }

    getIcon(id) {
        var icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Services_icon.svg'
        switch (id) {
            //Housing Request actionURL=HousingServiceRequest/AddHousingServiceRequest
            // case '76998782-c2a4-4dda-a7dc-437b2f6de1d5':
            //     icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'
            //     break
            //Loan Calculator actionURL=Calculators/Loan
            case 'fbfcd36b-60d0-4152-b955-50ed865fc014':
                icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/loan_calculator_icon.svg'
                break
            //Construction Cost Calculator actionURL=Calculators/Construction
            case 'dd058258-d1fa-4686-8506-1ad00a37498d':
                icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HouseConstructionLoan_icon.svg'
                break
            //House Booking actionURL=HouseBooking/Index
            case 'ceea8a43-f5d9-47fb-bc92-871ecdcf7a80':
                icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ManageYourHouseBooking.svg'
                break
            //Towhom
            case 'eef575c0-32e3-42ba-a6e5-e3a9a9b40235':
                icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg'
                break
            //Early Settlement
            case '6f5781ec-385e-423d-a4d8-1438facbbae0':
                icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/EarlyPaymentDiscount.svg'
                break
            //Request Liability Certificate
            case 'd73f675e-4866-46a7-819a-08bf4ac3d1d3':
                icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg'
                break
            default:
        }
        return icon
    }

    getInfoLink(id) {
        var link = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Services_icon.svg'
        switch (id) {
            //Housing Request actionURL=HousingServiceRequest/AddHousingServiceRequest
            // case '76998782-c2a4-4dda-a7dc-437b2f6de1d5':
            //     icon = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'
            //     break
            //Loan Calculator actionURL=Calculators/Loan
            case 'fbfcd36b-60d0-4152-b955-50ed865fc014':
                link = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/loan_calculator_icon.svg'
                break
            //Construction Cost Calculator actionURL=Calculators/Construction
            case 'dd058258-d1fa-4686-8506-1ad00a37498d':
                link = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HouseConstructionLoan_icon.svg'
                break
            //House Booking actionURL=HouseBooking/Index
            case 'ceea8a43-f5d9-47fb-bc92-871ecdcf7a80':
                link = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ManageYourHouseBooking.svg'
                break
            //Towhom
            case 'eef575c0-32e3-42ba-a6e5-e3a9a9b40235':
                link = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg'
                break
            //Early Settlement
            case '6f5781ec-385e-423d-a4d8-1438facbbae0':
                link = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/EarlyPaymentDiscount.svg'
                break
            //Request Liability Certificate
            case 'd73f675e-4866-46a7-819a-08bf4ac3d1d3':
                link = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg'
                break
            default:
        }
        return link
    }

    moreClick(e) {
        e.preventDefault();
        this.setState({
            moreClick: !this.state.moreClick
        });
    }

    eligibleInfoClick(e) {
        var name = e.target.name
        const radiobtns = []
        var previousid = ""
        this.state.newRequestPayload.map((service) => {
            // if (service.id > 5) {
            if (name === service.id) {
                previousid = name
                if (this.state.previousId === name && this.state.isInfoShown) {
                    this.setState({ isInfoShown: false })
                    radiobtns.push(this.radioButton(service, false, this.infoLinkClick, this))
                } else {
                    this.setState({ isInfoShown: true })
                    radiobtns.push(this.radioButton(service, true, this.infoLinkClick, this))
                }
            } else {
                radiobtns.push(this.radioButton(service, name === service.id, this.infoLinkClick, this))
            }
            // }
        })
        this.setState({ eligibleServices: radiobtns, previousId: previousid })
    }

    handleRadioButtonClick(e) {
        localStorage.setItem('serviceID', e.currentTarget.id)
        localStorage.setItem('requireSocialTechnicalStudy', "")
        localStorage.setItem('requireSocialTechnicalStudy', this.getIsSocialStudyRequired(e.currentTarget.id))
        localStorage.removeItem('ticket')
        localStorage.setItem('isTotalDeductionRequired', this.getIsTotalDeductionRequired(e.currentTarget.id))
        // this.props.history.push('/newhousingRequest')
        // if (e.currentTarget.id === 'eef575c0-32e3-42ba-a6e5-e3a9a9b40235') {
        //     this.loggedInUserRequestNew()
        // } else {
        History.push({
            pathname: e.currentTarget.id.length > 5 ? this.getPath(e.currentTarget.id) : '/newhousingRequest',
            state: { status: this.state.status, }
        })
        // }
    }

    getIsTotalDeductionRequired(serviceID) {
        var isTotalDeductionRequired = false
        this.state.newRequestPayload.some((service) => {
            isTotalDeductionRequired = service.isTotalDeductionsRequired
            return serviceID === service.id
        })
        return isTotalDeductionRequired
    }

    // loggedInUserRequestNew() {
    //     this.props.reference.startLoading()
    //     var data = new URLSearchParams();
    //     data.append('EmiratesID', this.state.emiratesId);
    //     fetch(CONSTANTS.generateToWhomNewCertificateEndPoint + "?local=" + this.context.state.siteLang, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    //             'Authorization': CONSTANTS.authToken
    //         },
    //         body: data
    //     }).then(response => response.json()).then((response) => {
    //         if (response.success) {
    //             History.push({ pathname: '/toWhomItMayConcernCertificate', state: { response: response, } })
    //         } else {
    //             if (response.shouldApplyManual) {
    //                 History.push({
    //                     pathname: '/toWhoItMayConcern', state: { status: this.state.status, }
    //                 })
    //             }
    //         }
    //         this.props.reference.stopLoading()
    //         console.log(response)
    //     }).catch((response) => {
    //         this.props.reference.stopLoading()
    //         console.log(response)
    //     });
    // }

    getIsSocialStudyRequired(serviceID) {
        var isSocialStudyRequired = false
        this.state.newRequestPayload.some((service) => {
            isSocialStudyRequired = service.requireSocialTechnicalStudy
            return serviceID === service.id
        })
        return isSocialStudyRequired === undefined ? false : isSocialStudyRequired
    }

    infoLinkClick(e) {
        var infoLink = e.currentTarget.name
        window.open(infoLink, '_blank', 'noopener,noreferrer')
    }

    render() {
        let addClasMore = ["widgetContant"];
        if (this.state.moreClick) {
            addClasMore.push("showMore");
        }
        return (
            <div className="row">
                <div className="col-md-12 customerServicesWidget">
                    <div className="widgetWrapper">
                        <div className="widgetHead">
                            <span><img src={this.props.widget.icon} alt="MBRHE" /></span>
                            <span>{translate(this.props.widget.name)}</span>
                        </div>
                        <div className={addClasMore.join(' ')}>
                            {this.state.eligibleServices.length > 0 ? this.state.eligibleServices : <NoData message={translate('nodata')} />}
                            {/* {this.state.itemsArray.size !== 0 ? this.state.itemsArray : null} */}
                            <button onClick={this.moreClick.bind(this)}>{translate('mostusedservices')} <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/more.svg'} alt="more" /></i></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    radioButton(props, showMessage, infoLinkClick, ref) {
        let addClassZIndex = ["customerServicesInfoWrapper"];
        if (showMessage) {
            addClassZIndex.push("infoZindex");
        }
        return (
            <div className="widgetServiceBox" key={props.id}>
                <div className={addClassZIndex.join(' ')}>
                    <i><img name={props.id} src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} onClick={this.eligibleInfoClick} alt="eligibleInfo" /></i>
                    {props.serviceInfoLink && props.serviceInfoLink.length > 0 && showMessage ? <div className="eligibleInfo">{translate("eligibleInfoIconMsg")}<a name={props.serviceInfoLink} onClick={infoLinkClick}>{translate("clickhere")}</a></div> : null}
                </div>
                <span id={props.id} name={props.name} onClick={this.handleRadioButtonClick}>
                    <div >
                        <div className="widgetService_icon">
                            {props.id.length > 5 ? <img src={ref.getIcon(props.id)} onError={(e) => { e.target.onerror = null; e.target.src = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/' + 0 + '.svg' }} alt={props.name} /> : <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/' + props.id + '.svg'} onError={(e) => { e.target.onerror = null; e.target.src = 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/' + 0 + '.svg' }} alt={props.name} />}
                        </div>
                        <div className="widgetServiceName">
                            <span>{props.name}</span>
                        </div>
                    </div></span>
            </div>
        )
    }
}

export default ServicesWidget