import React from 'react'
import NoData from '../../utils/nodata'
import GoogleMapReact from 'google-map-react'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import HousingProjectsItem from './housingprojects-widget-item'

class HousingProjectsWidget extends React.Component {
    static defaultProps = {
        center: {
            lat: 25.0840876,
            lng: 55.2373002
        },
        zoom: 10
    }

    constructor(props) {
        super()
        this.state = {
            itemsArray: [],
        }
        this.fetchHousingProjectsData = this.fetchHousingProjectsData.bind(this)
        this.setHousingProjectWidget = this.setHousingProjectWidget.bind(this)
        this.refreshPins = this.refreshPins.bind(this)
        this.setItems = this.setItems.bind(this)
    }

    componentDidMount() {
        this.fetchHousingProjectsData(this.props.url)
    }

    fetchHousingProjectsData(url) {
        if (CONSTANTS.authToken.length > 15) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setHousingProjectWidget).catch(this.loadFailed);
        }
    }

    loadFailed(response) {
        console.log(response)
    }

    setHousingProjectWidget(response) {
        if (response.success) {
            localStorage.setItem('projects', JSON.stringify(response))
            this.setItems(response)
        }
    }

    setItems(response) {
        var housingProjectsItems = []
        var items = response.payload
        items.map((housingPrtoject, index) => {
            return (housingProjectsItems.push(<HousingProjectsItem key={index} lat={housingPrtoject.latitude} lng={housingPrtoject.longitude} housingPrtoject={housingPrtoject} index={index} callback={this.refreshPins} showPopUp={false} />))
        })
        this.setState({ itemsArray: housingProjectsItems })
    }

    refreshPins() {
        // var housingProjectsItems = []
        // this.state.itemsArray.every((item, index) => {
        //     item.props.showPopUp = false
        //     // return (housingProjectsItems.push(item))
        // })
        this.setState({})
    }

    render() {
        return (
            <div className="col-md-12 housingProjectsWidgetWrapper">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Housing_Projects.svg'} alt="Housing Projects" /></span>
                        <span>{translate('housing-projects-widget-heading')}</span>
                    </div>
                    <div className="widgetContant">
                        {this.state.itemsArray.length > 0 ?
                            <div id="map">
                                <div style={{ height: '100%', width: '100%', position: 'relative' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: CONSTANTS.googleMapKey }}
                                        defaultCenter={this.props.center}
                                        defaultZoom={this.props.zoom}>
                                        {this.state.itemsArray[0] ? this.state.itemsArray[0].onPinClick : this.state.itemsArray[0]}
                                        {this.state.itemsArray}
                                    </GoogleMapReact>
                                </div>
                            </div> : <NoData message={translate('nodata')} />}
                    </div>
                </div>
            </div>
        )
    }
}
export default HousingProjectsWidget