import React from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
function PdfViewer(value) {
    function onDocumentLoadSuccess({ numPages }) {
        value.pdfViewRef(numPages)
    }

    return (
        <Document file={value.data.url} onLoadSuccess={onDocumentLoadSuccess} loading="" >
            <Page pageNumber={value.parentRef.state.pageNumber} scale={1} loading="" />
        </Document>
    )
}

export default PdfViewer