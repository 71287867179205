export default {
    HOUSE_BOOKING_FILTERS: 'HOUSE_BOOKING_FILTERS',
    VILLAS: 'VILLAS',
    VILLAS_GROUPS: 'VILLAS_GROUPS',
    MY_BOOKINGS: 'MY_BOOKINGS',
    VILLA_DETAILS: 'VILLA_DETAILS',
    PRINT_BOOKING: 'PRINT_BOOKING',
    BOOK_HOUSE: 'BOOK_HOUSE',
    EPAY: 'EPAY'

}