import React from 'react'
import NoData from '../../utils/nodata'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import MaintenanceRequestsItem from './maintenance-requests-widget-item'

class MaintenanceRequestsWidget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            props: '',
            itemsArray: [],
            payload: ''
        }
        this.fetchMaintanenceRequests = this.fetchMaintanenceRequests.bind(this)
        this.setMaintananceRequestWidget = this.setMaintananceRequestWidget.bind(this)
        this.maintenanceRequests = this.maintenanceRequests.bind(this)
        this.showDetails = this.showDetails.bind(this)
    }

    componentDidMount() {
        this.fetchMaintanenceRequests(this.props)
    }

    fetchMaintanenceRequests(props) {
        this.setState({ props: props })
        if (CONSTANTS.authToken.length > 15){
            fetch(props.url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setMaintananceRequestWidget).catch(this.loadFailed);
        }
    }

    setMaintananceRequestWidget(response) {
        if (response.success) {
            var maintananceRequestItems = []
            var items = response.payload
            if (items) {
                items.map((maintananceRequest, index) => {
                    return (maintananceRequestItems.push(<MaintenanceRequestsItem showDetails={this.showDetails} key={index} index={index} payload={maintananceRequest} />))
                })
            }
            this.setState({ payload: response.payload, itemsArray: maintananceRequestItems })
        }
    }

    showDetails(e) {
        this.props.history.push({
            pathname: '/maintenancerequests',
            state: { payload: this.state.payload[e.target.name] }
        })
    }


    maintenanceRequests() {
        this.props.history.push('/maintenancerequests')
    }

    loadFailed(response) {
        console.log(response)
    }

    render() {
        return (
            <div className="col-md-6 maintenanceRequestWidgetWrapper">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Maintenance_grant_projects.svg'} alt="Maintenance Requests" /></span>
                        <span>{translate('maintenance-request-widget-heading')}</span>
                    </div>
                    <div className="widgetContant MaintenanceRequestsWrapper">
                        <div className="widgetContentIn">
                            {this.state.itemsArray.length > 0 ? this.state.itemsArray : <NoData message={translate('nodata')} />}
                        </div>
                        {this.state.itemsArray.length > 0 ? <div className="widgetsMoreBtn">
                            <hr></hr>
                            <a href="/maintenancerequests">{translate('show-more')}<img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/arrow.svg'} alt="Click" /></a>
                        </div> : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default MaintenanceRequestsWidget