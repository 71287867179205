import AwarenessConstants from './awareness-constants'
import BaseStore from '../../../BaseStore'

class AwarenessStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this.registerToActions.bind(this))
        this.awarenessResponse = null
    }

    registerToActions(action) {
        switch (action.actionType) {
            case AwarenessConstants.AWARENESS:
                this.awarenessResponse = action.response
                this.emitChange()
                break
            default:
                break
        }
    }

    get response() {
        return this.awarenessResponse
    }
}

export default new AwarenessStore()