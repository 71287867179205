import React, { createRef } from 'react'
import '../../reactToastify.css'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import { ToastContainer, toast } from 'react-toastify';
import Draggable from 'react-draggable';
import PdfViewStore from '../UaePassSignature/ViewPdf/PdfViewStore';
import PdfViewService from '../UaePassSignature/ViewPdf/PdfViewService';
import Moment from 'moment';
import CommonUtils from '../../utils/CommonUtils'
import HISTORY from '../../History';
import queryString from 'query-string';
import AppPDF from '../UaePassSignature/ViewPdf/PdfViewer'
import PdfViewConstant from '../UaePassSignature/ViewPdf/PdfViewConstant';

class SignPdfComponent extends React.Component {
    static contextType = AppContext
    pages = 0
    constructor(props) {
        super(props)
        this.linkRef = React.createRef();
        this.divref = React.createRef();
        this.state = {
            object: JSON.parse(localStorage.getItem('pdfObject')) ? JSON.parse(localStorage.getItem('pdfObject')) : null,
            viewAttachmentResponse: '',
            isSetLoading: false,
            numberofSign: 1,
            base64File: '',
            isDocumentInformation: false,
            userType: localStorage.getItem('userType'),
            isChecked: false,
            processStatus: '',
            isAccessToken: false,
            convertedFile: '',
            signBy: '',
            signSuccess: false,
            processStatusCanceled: '',
            processStatusFailed: '',
            processStatusFinished: '',
            downloadFileFromBlob: '',
            uaePassFetchedDocumentResponse: '',
            isShowWholeScreenLoader: false,
            isUploadSignedDocSuccess: false,
            deleteSignedDocument: '',
            deleteSignedError: '',
            isDocumentFetched: false,
            uaepass_status: 'done',
            uploadDocToUMbrhe: false,
            documentInformationResponse: '',
            isLoadAttachment: false,
            // isNotValid: false,
            isDocValid: false,
            formateDate: '',
            activeDrags: 0,
            canvasRef: null,
            deltaXyPos: {
                x: 0,
                y: 0
            },
            pageNumber: 1
        }

        this._onChange = this._onChange.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.setPdfViewRef = this.setPdfViewRef.bind(this);
        this.setPageNumber = this.setPageNumber.bind(this);
        this.uploadProcessID = this.uploadProcessID.bind(this);
    }

    setPageNumber(pageNum) {
        this.setState({ pageNumber: pageNum })
    }

    componentDidMount() {
        this.readProcessStatus();
        this.setState({ isSetLoading: true, isDocumentFetched: true, isLoadAttachment: true })
        this.getViewAttachmnet();
        PdfViewStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        PdfViewStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState({ viewAttachmentResponse: PdfViewStore.mbrheresponse })
        this.setState({ uaePassAccessTokenResponse: PdfViewStore.uaePassAccessTokenResponse })
        this.setState({ uaePassProcessResponse: PdfViewStore.uaePassProcessResponse })
        this.setState({ uaePassFetchedDocumentResponse: PdfViewStore.uaePassFetchedDocumentResponse })
        this.setState({ isUploadSignedDocSuccess: PdfViewStore.isUploadSignedDocSuccess(), uploadSignedDocResponse: PdfViewStore.uploadSignedDocResponse, uploadSignedDocMessage: PdfViewStore.uploadSignedDocMessage });
        this.setState({ deleteSignedDocument: PdfViewStore.deleteSignedDocument, deleteSignedError: PdfViewStore.deleteSignedError });
        if (this.state.isDocumentInformation) {
            this.setState({ isDocumentInformationSuccess: PdfViewStore.isDocumentInformationSuccess(), documentInformationResponse: PdfViewStore.documentInformationResponse, documentInformationMessage: PdfViewStore.documentInformationMessage });
        }

        if (this.state.isLoadAttachment && this.state.viewAttachmentResponse) {
            localStorage.setItem('docFileName', this.state.object.name+".pdf")
            this.downloadFileFromUrl(this.state.viewAttachmentResponse, localStorage.getItem('docFileName'));
            this.setState({ isLoadAttachment: false, isDocumentInformation: true })
        }
        if (this.state.isDocumentInformation && this.state.isDocumentInformationSuccess && this.state.documentInformationResponse.payload) {
            if (this.state.documentInformationResponse.payload.resultMajor.includes('Success')) {
                var name = this.state.documentInformationResponse.payload.signerIdentity;
                if (name != null && name.includes('CN=')) {
                    var string = name.split('+', 2)
                    var the_text = string[0];
                    if(!the_text.includes('CN=')){
                        the_text = string[1];
                    }
                    var string3 = the_text.split(',', 2)
                    var string2 = string3[0].split('=', 2)
                    var signerName = string2[1];
                    this.setState({ signBy: signerName })
                }
                var data = this.state.documentInformationResponse.payload.notAfter
                if (data) {
                    this.setState({ formateDate: Moment(data).format('DD-MM-YYYY') })
                }
                var signedDocNumber = Number(this.state.documentInformationResponse.payload.numberPdfSignatures)
                if (signedDocNumber) {
                    this.setState({ numberofSign: this.state.numberofSign + signedDocNumber })
                }
                this.setState({ isDocValid: true, isDocumentInformation: false, isShowWholeScreenLoader: false })
            }
        }
        if (this.state.isDocumentInformation && !this.state.isDocumentInformationSuccess && this.state.documentInformationResponse) {
            if (this.state.documentInformationResponse.payload === undefined) {
                this.setState({ isDocumentInformation: false, isShowWholeScreenLoader: false }, () => {
                    toast.error(this.state.documentInformationResponse.error);
                })
            } else {
                if (this.state.documentInformationResponse.payload.resultMajor.includes('RequesterError')) {
                    this.setState({ isDocumentInformation: false, isShowWholeScreenLoader: false })
                }
            }
        }
        if (this.state.isDocumentInformation && this.state.documentInformationMessage) {
            this.setState({ isDocumentInformation: false })
            toast.error(this.state.documentInformationMessage);
        }
        if (this.state.isAccessToken && this.state.uaePassAccessTokenResponse) {
            if (this.state.uaePassAccessTokenResponse.error) {
                this.catchBody(this.state.uaePassAccessTokenResponse)
            } else {
                localStorage.setItem('uaePassToken', this.state.uaePassAccessTokenResponse.access_token);
                var processJson = CommonUtils.createProcess(this.state.deltaXyPos.x, this.state.deltaXyPos.y, this.state.numberofSign, process.env.REDIRECT_VIEW_COMPONENT_DISBURSEMENT);
                PdfViewService.uaePassCreateProcess(processJson, this.state.convertedFile)
                    .catch(function (error) {
                        this.catchBody(error);
                    }.bind(this));
            }
        }
        if (this.state.isAccessToken && this.state.uaePassProcessResponse) {
            if (this.state.uaePassProcessResponse.error) {
                this.catchBody(this.state.uaePassProcessResponse)
            } else {
                localStorage.setItem('UaePassDocument', this.state.uaePassProcessResponse.documents[0].id)
                localStorage.setItem('UaePassProcessId', this.state.uaePassProcessResponse.id)
                // this.uploadProcessID()
                var processUrl = this.state.uaePassProcessResponse.tasks.pending[0].url;
                window.location.href = processUrl
                this.setState({
                    isAccessToken: false,
                    isDocumentFetched: true
                })
            }
        }
        if (this.state.isDocumentFetched && this.state.uaePassFetchedDocumentResponse) {
            const blob = window.URL.createObjectURL(this.state.uaePassFetchedDocumentResponse);
            this.setState({
                viewAttachmentResponse: blob,
                downloadFileFromBlob: blob,
                isDocumentFetched: false,
            })
            //upload to mbrhe server
            if (!this.state.isUploadSignedDocSuccess) {
                this.setState({
                    uploadDocToUMbrhe: true
                })
                PdfViewService.uploadSignedDocNew(
                    new File([this.state.uaePassFetchedDocumentResponse], localStorage.getItem('docFileName')),
                    localStorage.getItem('UaePassProcessId'),
                    this.state.uaepass_status,
                    this.state.object.id,
                    localStorage.getItem('UaePassDocument'),
                    this.context.state.siteLang)
                    .catch(function (error) {
                        this.catchBody(error);
                    }.bind(this));
            }
        }
        if (this.state.uploadDocToUMbrhe && this.state.isUploadSignedDocSuccess) {
            //delete document from uae process
            PdfViewService.deleteDocumentFromUAEPass(localStorage.getItem('UaePassProcessId'))
                .catch(function (error) {
                    this.catchBody(error);
                }.bind(this));
            this.setState({
                uploadDocToUMbrhe: false,
                isDeleteSuccessFromUAEPASS: true
            })
        }
        if (this.state.isDeleteSuccessFromUAEPASS && this.state.deleteSignedDocument === 204) {
            this.setState({
                isDeleteSuccessFromUAEPASS: false,
                isShowWholeScreenLoader: false
            })
        }
        if (this.state.isDeleteSuccessFromUAEPASS && this.state.deleteSignedError) {
            toast.error(this.state.deleteSignedError)
            this.setState({
                isDeleteSuccessFromUAEPASS: false,
                isShowWholeScreenLoader: false
            })
        }
    }

    uploadProcessID() {
        fetch(PdfViewConstant.updateSignedDocStatusEndpoint + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'bearer ' + localStorage.getItem('accessToken')
            },
            body: new URLSearchParams({
                'TicketNo': localStorage.getItem('dTicketNo'),
                'Status': this.getStatus(this.state.uaepass_status),
                'ProcessId': localStorage.getItem('UaePassProcessId'),
                'UAEPassStatus': this.state.uaepass_status,
                'DocumentId': this.state.object.id,
                'UaePassDocumentId': localStorage.getItem('UaePassDocument')
            }),
        }).then(response => response.json()).then(response => console.log(response))
            .catch(console.log("Failed to update sign process data"))
    }

    documentInformation(base64) {
        PdfViewService.documentInformation(base64)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    getStatus(status) {
        if (status === "New") {
            return 0
        } else if (status === "Pending") {
            return 2
        } else if (status === "Fail") {
            return 3
        } else if (status === "Finished") {
            return 5
        }
    }

    downloadFileFromUrl(blob, fileName) {
        var convertBlogToFile = new File([blob], fileName+".pdf", { type: "application/pdf" });
        this.setState({
            convertedFile: convertBlogToFile,
        })
        CommonUtils.getBase64(this.state.convertedFile)
            .then((result) => {
                this.documentInformation(result);
                this.setState({ base64File: result, isDocumentInformation: true })
            }).catch(err =>
                console.error("error from fetch-->", err));
    }

    handleUploadFile(event) {
        let selectedFile = event;
        let file = null;
        let fileName = "";
        if (selectedFile) {
            let fileToLoad = selectedFile;
            fileName = fileToLoad.name;
            let fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
            };
            fileReader.readAsDataURL(fileToLoad);
        }

        this.setState({
            fileData: file,
            fileName: fileName
        })
    }

    readProcessStatus() {
        let url = this.props.location.search;
        if (url !== "") {
            this.setState({ isShowWholeScreenLoader: true })
            let value = queryString.parse(url);
            if (value.status && value.status !== '') {
                if (value.status === "finished") {
                    this.setState({
                        processStatusFinished: value.status,
                        signSuccess: true
                    })
                    PdfViewService.fetchSignedDocument(localStorage.getItem('UaePassDocument'))
                        .catch(function (error) {
                            this.catchBody(error);
                        }.bind(this));
                } else if (value.status === "failed") {
                    this.setState({
                        processStatusFailed: value.status,
                        failedSign: true
                    })
                } else if (value.status === "canceled") {
                    this.setState({
                        cancelSign: true,
                        processStatusCanceled: value.status
                    })

                }
            }
        }
    }

    getViewAttachmnet() {
        this.setState({ isShowWholeScreenLoader: true })
        var documentId = 0;
        if (this.state.object) {
            if (this.state.object.hasOwnProperty('id')) {
                documentId = this.state.object.id;
            } else if (this.state.object.id) {
                documentId = this.state.object.id;
            } else {
                documentId = this.state.object;
            }
            PdfViewService.downloadAttachmentFromMbrheNew(documentId)
                .catch(function (error) {
                    this.catchBody(error);
                }.bind(this));
        } else {
            this.setState({ isSetLoading: false })
        }
    }

    catchBody(response) {
        this.setState({ isShowWholeScreenLoader: false, isAccessToken: '' }, () => {
            toast.error(response.error);
        })
    }

    handleDrag = (e, d) => {
        console.log("X=" + d.x + " ,Y=" + d.y)
        var height = this.divref.current.clientHeight - d.y
        this.setState({
            isSetLoading: false,
            deltaXyPos: {
                x: d.x,
                y: height - 38,
            }
        });
    }

    cancelSignClick() {
        this.setState({
            cancelSign: !this.state.cancelSign,
        })
        this.returnToList();
    }

    returnToList() {
        return HISTORY.push('/stepConstructionLoanDisbursement');
    }

    signYes() {
        if (!this.state.isChecked) {
            toast.error(translate('acceptterms'))
            return;
        }
        this.setState({ isAccessToken: true, isShowWholeScreenLoader: true })
        PdfViewService.uaePassAccessToken().catch(function (error) {
            this.catchBody(error)
        }.bind(this));
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked
        });
        if (!this.state.isChecked) {
            this.setState({
                isSetLoading: false
            });
        }
    }

    signSuccessClick() {
        this.setState({ signSuccess: !this.state.signSuccess, })
        this.returnToList();
        this.removeFromStorage();
    }
    
    downloadPdf() {
        CommonUtils.downloadBlob(this.state.downloadFileFromBlob, localStorage.getItem('docFileName'));
        toast.success(translate('downloadDoc'))
        if (toast.isActive) {
            setTimeout(function () {
                HISTORY.push('/stepConstructionLoanDisbursement');
            }, 3000)
        }
        this.removeFromStorage();
    }

    removeFromStorage() {
        localStorage.removeItem('docFileName');
        localStorage.removeItem('uaePassToken');
        localStorage.removeItem('UaePassDocument');
        localStorage.removeItem('UaePassProcessId');
    }

    setPdfViewRef(pages) {
        this.pages = pages
        this.setState({})
    }

    renderPagination = (pages) => {
        localStorage.setItem('setPageNo', this.state.pageNumber)
        if (!pages) {
            return null;
        }
        let previousButton = <li className="previous" onClick={() => this.setPageNumber(this.state.pageNumber - 1)}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/prv.svg'} alt="Previous" /></li>;
        if (this.state.pageNumber === 1) {
            previousButton = <li className="previous disabled"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/prv.svg'} alt="Previous" /></li>;
        }
        let nextButton = <li className="next" onClick={() => this.setPageNumber(this.state.pageNumber + 1)}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/next.svg'} alt="Next" /></li>;
        if (this.state.pageNumber === pages) {
            nextButton = <li className="next disabled"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/next.svg'} alt="Next" /></li>;
        }

        return (
            <div className="pdfNavBtn">
                <nav>
                    <ul className="pager" locale="en-En">
                        {previousButton}
                        <li className="pdfPage">{this.state.pageNumber}</li>
                        <li>-</li>
                        <li className="pdfPages">{pages}</li>
                        {nextButton}
                    </ul>
                </nav>
            </div>
        );
    }

    render() {
        const { object, base64File, isShowWholeScreenLoader } = this.state;
        let props = {};
        if (base64File) {
            props = {
                url: this.state.convertedFile,
                // url: this.state.downloadFileFromBlob ? this.state.downloadFileFromBlob : this.state.convertedFile,
                isLoading: this.state.isSetLoading
            }
        }
        return (
            <div className="container-fluid">
                <a ref={this.linkRef} />
                <ToastContainer autoClose={2000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    {/* breadcrumb */}
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">{this.state.userType === "1" ? <a href="/customerDashboard">{translate('dashboard')}</a> : <a href="/contractorDashboard">{translate('dashboard')}</a>}</li>
                                    <li className="breadcrumb-item active" aria-current="page"><a href="/constructionLoanDisbursementTickets">{localStorage.getItem('disbursementTypeID') === '16' ? translate('maintenanceDisbursementContractRequests') : translate('constructionDisbursementContractRequests')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page"><a href="/stepConstructionLoanDisbursement">{translate('requestform')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('signDocument')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* End breadcrumb */}
                    <div className="row service_contant_wrapper">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/construction_disbursement.svg'} alt="construction Disbursement Contract Requests" /></i>
                                <span>{localStorage.getItem('disbursementTypeID') === '16' ? translate('maintenanceDisbursementContractRequests') : translate('constructionDisbursementContractRequests')}</span></div>
                        </div>

                        <div className="subTitle">
                            <h2>{translate('signADoc')}</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="pdfVewWrapper signComp">
                                    </div>
                                </div>
                                <div className="pdfviewBoxWrapper">
                                    <div className="signPdfBox">
                                        <div className="pdfviewBox" ref={this.divref}>
                                            {this.pages > 0 ? <Draggable bounds="parent" onDrag={this.handleDrag}>
                                                <div className="drag-box">
                                                    <div>{translate('signHere')}</div>
                                                </div>
                                            </Draggable> : null}
                                            {base64File ? <AppPDF parentRef={this} data={props} pdfViewRef={this.setPdfViewRef} /> : null}
                                        </div>
                                        {this.pages > 0 ? this.renderPagination(this.pages) : null}
                                    </div>
                                    <div className="pdfviewDetailsBox">
                                        <div className="row documentViewDetails">
                                            <div className="col-sm-4"><strong>{translate('docName')} : </strong></div>
                                            <div className="col-sm-8"><span>{object.name.replace('.pdf', "")}</span></div>
                                            <div className="col-sm-4"><strong>{translate('docType')} : </strong></div>
                                            <div className="col-sm-8"><span>Pdf</span></div>
                                            {this.state.isDocValid ?
                                                <div className="col-sm-4"><strong>{translate('docValidityStatus')} : </strong></div>
                                                : null}
                                            {this.state.isDocValid ?
                                                <div className="col-sm-8">
                                                    <span className="text-success">{translate('validText')}</span>
                                                </div>
                                                : null}
                                            {this.state.formateDate ? <div className="col-sm-4"><strong>{translate('docValidity')} : </strong></div> : null}
                                            {this.state.formateDate ? <div className="col-sm-8">
                                                <span>{this.state.formateDate}</span>
                                            </div> : null}
                                            {this.state.signBy ? <div className="col-sm-4"><strong>{translate('signedBy')} : </strong></div> : null}
                                            {this.state.signBy ? <div className="col-sm-8">
                                                <span>{this.state.signBy}</span>
                                            </div> : null}
                                            <div className="col-md-12 customCheckBox">
                                                <input type="checkbox" name="isFromSpecialNeeds" checked={this.state.isChecked} onChange={this.toggleChange} id="SpecialNeeds" /><label htmlFor="SpecialNeeds">{translate('responsibleforsign')}</label>
                                            </div>
                                            <div className="col-lg-12 viewFileBtns">
                                                <button className="viewCancelBtn" onClick={this.returnToList.bind(this)}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/cancel_ico.svg'} alt="Cancel" /></i><span>{translate('cancle')}</span></button>
                                                <button className="viewSignBtn" onClick={this.signYes.bind(this)}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign.svg'} alt="Sign" /></i> <span>{translate('sign')}</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* START loader */}
                    {isShowWholeScreenLoader ? <div className="PopupBk"></div> : null}
                    {isShowWholeScreenLoader ? <div className="loader">
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                        <div className="bar4"></div>
                        <div className="bar5"></div>
                        <div className="bar6"></div>
                    </div> : null}
                    {/* END loader */}
                    {/* Sign successfully Document POPUP*/}
                    {this.state.signSuccess && !this.state.isShowWholeScreenLoader && this.state.processStatusFinished ? <div className="PopupBk" ></div> : null}
                    {this.state.signSuccess && !this.state.isShowWholeScreenLoader && this.state.processStatusFinished ? <div className="uploadPopup signSuccessfullyWrapperPopup">
                        <div className="row formSection">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <i className="successfullyimageIco"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign_successfully.svg'} alt="pdf" /></i>
                                            <p className="successfullytext">{translate('docSignSuccessUAE')}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 signSuccessfullyWrapperPopupBtn">
                                        <button onClick={this.downloadPdf.bind(this)} className="downloadBtn"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/dowlload.svg'} alt="Download" /> </i> <span>{translate('download')}</span></button>
                                        <button className="cancelBtn" onClick={this.signSuccessClick.bind(this)}>{translate('cancle')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {/* END Sign successfully Document POPUP*/}


                    {/* cancelled Sign Document POPUP*/}
                    {this.state.cancelSign || this.state.failedSign && this.state.processStatusCanceled || this.state.processStatusFailed ? <div className="PopupBk" ></div> : null}
                    {this.state.cancelSign || this.state.failedSign && this.state.processStatusCanceled || this.state.processStatusFailed ? <div className="uploadPopup cancelSignWrapperPop">
                        <div className="row formSection">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.cancelSign ?
                                            <div className="row">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/cancel_sign.svg'} alt="pdf" /></i>
                                                <p>{translate('cancleSignProcess')}</p>
                                            </div> :
                                            <div className="row">
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/cancel_sign.svg'} alt="pdf" /></i>
                                                <p>{translate('docSignatureFailed')}</p>
                                            </div>}
                                    </div>
                                    <div className="col-md-12 uploadDocumentBtn">
                                        <button onClick={this.cancelSignClick.bind(this)}>{translate('cancle')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {/* END cancelled Sign Document POPUP*/}

                </div>
            </div>
        )
    }
}
export default AuthenticatedComponent(SignPdfComponent)