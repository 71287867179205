import ContractorPaymentConstant from './contractorPaymentConstant';
import BaseStore from '../../../BaseStore';

class ContractorPAymentStore extends BaseStore {

  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._success = false;
    this._response = null;
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case ContractorPaymentConstant.CUSTOMER_PAYMENT_DASHBOARD:
        this._success = action.response.success;
        this._response = action.response.payload;
        this.emitChange();
        break;
      default:
        break;
    };
  }
  isSuccess() {
    return this._success;
  }

  get response() {
    return this._response;
  }
}

export default new ContractorPAymentStore();