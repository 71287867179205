import React from 'react'
import '../../../reactToastify.css'
import NoData from '../../utils/nodata'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../../constants/dashboard-constants'
import CostumerModuleUtilities from '../../utils/customerModuleUtilities'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
import HousingRequestsStore from '../../dashboard/housingrequests-widget/housing-requests-store'
import HousingRequestsService from '../../dashboard/housingrequests-widget/housing-requests-service'
class ChangeServiceTicketsListingComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            redirect: false,
            props: ''
        }
        this.onChange = this.onChange.bind(this)
        this.modifyTicket = this.modifyTicket.bind(this)
        this.deleteSuccess = this.deleteSuccess.bind(this)
        this.setHousingRequests = this.setHousingRequests.bind(this)
        this.deleteTicketClick = this.deleteTicketClick.bind(this)
        this.yes = this.yes.bind(this)
        this.no = this.no.bind(this)
        this.getTickets = this.getTickets.bind(this)
    }

    componentDidMount() {
        this.getTickets()
        this.setState({ props: this.props })
        // HousingRequestsStore.addChangeListener(this.onChange)
        // HousingRequestsService.fetchHousingRequest(100, "?local=" + this.context.state.siteLang)
    }

    onChange() {
        this.setHousingRequests(HousingRequestsStore.response)
        this.deleteSuccess(HousingRequestsStore.deleteHousingRequestResponse)
    }

    getTickets() {
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.changeServiceTicketsListingEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setHousingRequests).catch();
        }
    }

    setHousingRequests(response) {
        var housingRequestItems = []
        if (response.payload.length > 0) {
            if (response.payload[0].canCreateRequest) {
                var items = response.payload
                if (items.length > 0) {
                    items.map((housingRequest, index) => {
                        let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
                        let cls = CostumerModuleUtilities.getStatusLableColor(housingRequest.status)
                        return (housingRequestItems.push(<ChangeServiceTicketsListingItem canCreateTicket={response.payload[0].canCreateRequest} deleteClick={this.deleteTicketClick} modifyTicket={this.modifyTicket} key={index} payload={housingRequest} image={img} className={cls} />))
                    })
                    this.setState({ redirect: false, itemsArray: housingRequestItems })
                }
            } else {
                var ticketItems = response.payload[0].changeServiceTickets
                if (ticketItems.length > 0) {
                    ticketItems.map((housingRequest, index) => {
                        if (housingRequest.status !== 30) {
                            let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
                            let cls = CostumerModuleUtilities.getStatusLableColor(housingRequest.status)
                            return (housingRequestItems.push(<ChangeServiceTicketsListingItem applicationNo={response.payload[0].applicationNo} index={index} canCreateTicket={response.payload[0].canCreateRequest} deleteClick={this.deleteTicketClick} modifyTicket={this.modifyTicket} key={index} payload={housingRequest} image={img} className={cls} />))
                        }
                    })
                    this.setState({ redirect: false, itemsArray: housingRequestItems })
                } else {
                    this.setState({ redirect: true })
                }
            }
        } else {
            this.setState({ redirect: true })
        }
    }

    modifyTicket(e) {
        const index = e.currentTarget.id
        this.state.props.history.push({ pathname: '/changeServiceRequest', state: index ? { ticketIndex: index } : null })
    }

    deleteTicketClick(e) {
        e.persist()
        const ticketNo = e.currentTarget.name
        if (ticketNo) {
            this.setState({ ticketNo: ticketNo, showConfirmation: !this.state.showConfirmation })
        }
    }

    yes() {
        if (this.state.ticketNo) {
            this.setState({ showConfirmation: !this.state.showConfirmation })
            HousingRequestsService.deleteTicket(this.state.ticketNo)
        }
    }

    no() {
        this.setState({ ticketNo: '', showConfirmation: false })
    }

    deleteSuccess(response) {
        if (response) {
            if (response.success) {
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
            HousingRequestsStore.clearDeleteResponse()
            HousingRequestsService.fetchHousingRequest(100, "?local=" + this.context.state.siteLang)
            this.no()
        }
    }
    componentDidUpdate() {
        localStorage.setItem('serviceID', '')
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    {/* delete confirmation popup */}
                    {this.state.showConfirmation ?
                        <div className="PopupBk"></div> : null}
                    {this.state.showConfirmation ?
                        <div className="deleteConfirmationPopup">
                            <p>{translate('deleteconfirmation')}</p>
                            <div className="button">
                                <button onClick={this.yes}>{translate('yes')}</button>
                                <button onClick={this.no}>{translate('no')}</button>
                            </div>
                        </div> : null}

                    {/* delete confirmation popup */}
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('changeService-Request')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" /></i>
                                <span>{translate('changeService-Request')}</span>

                                {/* <div className="add_newrequest">
                                    <a href="/NewhousingRequest"><span> {translate('clicktoadd')} </span><i>+</i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    {this.state.redirect ? <NoData message={translate('nodata')} /> : this.state.itemsArray}
                                    {/* {this.state.redirect ? <Redirect to='/NewhousingRequest' /> : this.state.itemsArray} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function ChangeServiceTicketsListingItem(props) {
    return (
        <div className="col-md-6 housingRequestWrapperAll">
            <div className="houseingRequestInn housingRequestWrapper">
                <div className="row no-gutters">
                    <div className="col-md-2">
                        <i><img src={props.image} alt="MBRHE" /></i>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-12">
                                <strong>{props.payload.service}</strong>
                                <span> {props.canCreateTicket ? translate('applicationNo') : translate('ticket-number')} </span>
                                <span> {props.canCreateTicket ? ': ' + props.payload.applicationNo : props.payload.ticket} </span>
                                {!props.canCreateTicket ?
                                    <div >
                                        <span> {translate('applicationNo')}</span>
                                        <span>: {props.applicationNo} </span>
                                    </div> :
                                    null}
                            </div>
                            <div className="col-12">
                                <span> {translate('status-lbl')} </span>
                                <span className={props.class}> {props.payload.statusName} </span>
                            </div>
                            <div className="col-12">
                                <p className="housingRequestsince">{props.payload.createdAt}</p>
                            </div>
                        </div>
                    </div>
                    {props.canCreateTicket ? <div className="col-md-3 housingRequestBtns">
                        <button name={props.payload.ticket} onClick={props.modifyTicket}> {translate('Apply')} </button>
                    </div> : null}
                    {props.payload.enableEdit ? <div className="col-md-3 housingRequestBtns">
                        <button name={props.payload.ticket} id={props.index} onClick={props.modifyTicket}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg'} alt="Edit" /> {translate('modify')} </button>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}
export default AuthenticatedComponent(ChangeServiceTicketsListingComponent)