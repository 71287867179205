import React from 'react'
import history from '../../../History'
import NoData from '../../utils/nodata'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import ContractorPaymentsNewWidgetItem from './contractorpaymentsnew-widget-item'
import { AppContext } from '../../../Providers/context'
import ContractorPaymentService from './contractorPaymentService'
import ContrcatorPaymnetStore from './contrcatorPaymnetStore'

class ContractorPaymentnewWidget extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            showImagePopup: false,
            showFilesPopup: false,
            response: '',
            contractId: '',
            imageResponseURL: '',
            isSuccess: false,
            paymentsresponse: '',
        }
        // this.fetchContractorPaymentsData = this.fetchContractorPaymentsData.bind(this)
        this.setContractorPaymentWidget = this.setContractorPaymentWidget.bind(this)
        this.setContractorPaymentImages = this.setContractorPaymentImages.bind(this)
        this.setContractorPaymentImagesURL = this.setContractorPaymentImagesURL.bind(this)
        this.more = this.more.bind(this)
        this.showFiles = this.showFiles.bind(this)
        this.toggleFilesPopup = this.toggleFilesPopup.bind(this)
        this.showImage = this.showImage.bind(this)
        this.toggleImagePopup = this.toggleImagePopup.bind(this)
        this._onChange = this._onChange.bind(this);

    }

    componentDidMount() {
        if (!this.state.paymentsresponse) {
            this.getContractorPayments();
        }
        ContrcatorPaymnetStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        ContrcatorPaymnetStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.setProjectsState());
        if (this.state.isSuccess && this.state.paymentsresponse) {
            this.setContractorPaymentWidget(this.state.paymentsresponse);
        }
    }

    getContractorPayments() {
        if (CONSTANTS.authToken.length > 15) {
            ContractorPaymentService.contractorPaymentsList(this.context.state.siteLang)
                .catch(function (error) {
                    this.catchBody(error);
                }.bind(this));
        }
    }
    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    setProjectsState() {
        return {
            paymentsresponse: ContrcatorPaymnetStore.response,
            isSuccess: ContrcatorPaymnetStore.isSuccess(),
        };
    }

    more() {
        history.push("/contractorpayments")
    }

    showFiles(e) {
        const paymentId = e.currentTarget.id;
        const contractId = e.currentTarget.name;
        this.setState({ contractId: contractId })
        this.fetchContractorPaymentImages(CONSTANTS.fetchContractorPaymentImages + '?paymentId=' + paymentId + '&contractId=' + contractId + "&local=" + this.context.state.siteLang);
    }

    fetchContractorPaymentImages(url) {
        if (localStorage.getItem('accessToken'))
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setContractorPaymentImages).catch(this.loadFailed);
    }

    setContractorPaymentImages(response) {
        // if (response.success && response.payload.length > 0) {
        this.setState({ response: response.payload })
        this.toggleFilesPopup()
        // }
    }

    toggleFilesPopup() {
        this.setState({ showFilesPopup: !this.state.showFilesPopup })
    }

    showImage(e) {
        const docId = e.currentTarget.id;
        this.fetchContractorPaymentImagesURL(CONSTANTS.fetchContractorPaymentImagesUrl + '?contractId=' + this.state.contractId + '&docId=' + docId + '&local=' + this.context.state.siteLang);
    }

    fetchContractorPaymentImagesURL(url) {
        if (localStorage.getItem('accessToken'))
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setContractorPaymentImagesURL).catch(this.loadFailed);
    }

    setContractorPaymentImagesURL(response) {
        if (response.success) {
            this.setState({ imageResponseURL: response.payload })
            this.toggleImagePopup()
        }
    }

    toggleImagePopup() {
        this.setState({ showImagePopup: !this.state.showImagePopup })
    }

    setContractorPaymentWidget(response) {
        if (this.state.isSuccess) {
            var contractorPaymentRequestsItems = []
            var items = response
            if (items.length > 0) {
                contractorPaymentRequestsItems.push(<ContractorPaymentsNewWidgetItem key={0} showFiles={this.showFiles} item={items} />)
            }
            this.setState({ itemsArray: contractorPaymentRequestsItems })
        }
    }

    render() {
        const { response, imageResponseURL } = this.state
        return (
            <div className="col-md-12 stagesProjectCompletionWidgetWrapper">
                {this.state.isSuccess && this.state.paymentsresponse ?
                    <div className="stagesProjectCompletionWidgetWrapper">
                        {/* Image popup */}
                        {this.state.showFilesPopup ? <div className="PopupBk"></div> : null}
                        {this.state.showFilesPopup ? <div className="viewPopup">
                            <h2><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/image_search_icon.svg'} alt="search" /></i>{translate('attachmentImage')}</h2>
                            {response && response.length > 0 ? response.map((valueItem, valueIndex) => {
                                return (
                                    <FileItem key={valueIndex} item={valueItem} showImage={this.showImage} />
                                );
                            }) : <div className="col contractorPaymentImg">
                                <div className="row viewPopupContant">
                                    <NoData message={translate('nodata')} />
                                </div>
                            </div>}
                            <button className="popupClose" onClick={this.toggleFilesPopup}>X</button>
                        </div> : null}
                        {/* Image popup */}

                        {/* Image popup view */}
                        {this.state.showImagePopup ? <div className="PopupBk"></div> : null}
                        {this.state.showImagePopup ? <div className="uploadPopup">
                            <button className="popupClose" onClick={this.toggleImagePopup}>X</button>
                            <div className="popupShowFile">
                                {imageResponseURL ? <img src={imageResponseURL} alt="search" /> : null}
                            </div>
                        </div> : null}
                        {/* Image popup view */}

                        <div className="widgetWrapper">
                            <div className="widgetHead">
                                <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ContractorPayment.svg'} alt="Contractor Payment" /></span>
                                <span>{translate('theSatgesOfProject')}</span>
                            </div>
                            <div className="widgetContant">
                                {this.state.itemsArray.length > 0 ?
                                    <div className="widgetContentIn">
                                        {this.state.itemsArray}
                                    </div> : <NoData message={translate('nodata')} />}
                            </div>
                        </div>
                    </div>
                    : null}</div>
        )
    }
}

function FileItem(props) {
    return (
        <div className="col contractorPaymentImg">
            <div className="row viewPopupContant">
                <div className="col-md-7">{props.item.name}</div>
                <div className="col-md-5 project_step_upload_btns">
                    <button id={props.item.documentId} onClick={props.showImage}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/icon_eye.svg'} alt="view" /> {translate('viewImage')}</button>
                </div>
            </div>
        </div>
    )
}

export default ContractorPaymentnewWidget