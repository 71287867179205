import React from 'react'
function ServiceItem(props) {
    return (
        <div className="widgetServiceBox" >
            <a href={props.path}>
                <div href={props.path}>
                    <div className="widgetService_icon">
                        <img src={props.icon} alt="MBRHE" />
                    </div>
                    <div className="widgetServiceName">
                        <span>{props.name}</span>
                    </div>
                </div>
            </a>
        </div>)
}
export default ServiceItem