import AppDispatcher from '../../../Dispatcher';
import PdfViewConstant from './PdfViewConstant';


class PdfViewAction {

    viewAttachment(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.VIEW_ATTACHMENT,
            response: response
        })
    }

    downloadFromMbrheApi(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.DOWNLOAD_MBRHE_ATTACHMENT,
            response: response
        })
    }

    updateMeta(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.UPDATE_META_DATA,
            response: response
        })
    }

    getUaePassToken(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.UARPASS_ACCESS_TOKEN,
            response: response
        })
    }

    uaePassCreateProcess(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.UARPASS_PROCESS,
            response: response
        })
    }

    getSignedDocumentFromUaePass(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.FETCHED_SIGNED_DOCUMENT,
            response: response
        })
    }

    uploadSignedDocument(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.UPLOAD_SIGNED_DOCUMENT,
            response: response
        })
    }

    deleteDocumentFromUAEPass(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.DELETE_SIGNED_DOCUMENT,
            response: response
        })
    }

    documentInformation(response) {
        AppDispatcher.dispatch({
            actionType: PdfViewConstant.DOCUMENT_INFORMATION,
            response: response
        })
    }
}
export default new PdfViewAction();