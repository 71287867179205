import React from 'react'
function ErrorComponent(props) {
    return (
        <div id='errorDiv' className="alert alert-danger alert-dismissible fade show" role="alert">
            {props.message}
            <button  onClick={props.closeCalback} type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            {/* <button onClick={props.closeCalback} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> */}
        </div>
    )
}
export default ErrorComponent