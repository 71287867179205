import React from 'react'
import NoData from '../../utils/nodata'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import MaintenanceInsuranceItem from './maintenance-insurance-widget-item'

class MaintenanceInsuranceWidget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            props: '',
            itemsArray: []
        }
        this.fetchMaintanenceInsuranceRequests = this.fetchMaintanenceInsuranceRequests.bind(this)
        this.setMaintananceInsuranceWidget = this.setMaintananceInsuranceWidget.bind(this)
        this.insuranceDetails = this.insuranceDetails.bind(this)
    }

    componentDidMount() {
        this.fetchMaintanenceInsuranceRequests(this.props)
    }

    fetchMaintanenceInsuranceRequests(props) {
        this.setState({ props: props })
        if (CONSTANTS.authToken.length > 15) {
            fetch(props.url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setMaintananceInsuranceWidget).catch(this.loadFailed);
        }
    }

    setMaintananceInsuranceWidget(response) {
        var maintananceRequestItems = []
        var items = response.payload
        if (items && items.length > 0) {
            // items.map((maintananceRequest, index) => {
            //     return (maintananceRequestItems.push(<MaintenanceInsuranceItem key={index} payload={maintananceRequest} />))
            // })
            maintananceRequestItems.push(<MaintenanceInsuranceItem key={0} payload={items[0]} />)
            maintananceRequestItems.push(<MaintenanceInsuranceItem key={1} payload={items[1]} />)
            this.setState({ itemsArray: maintananceRequestItems })
        }
    }

    loadFailed(response) {
        console.log(response)
    }

    insuranceDetails() {
        this.props.history.push('/maintenanceInsurance')
    }

    render() {
        return (
            <div className="col-md-6 maintenanceInsuranceWidgetWrapper">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/maintenanc.svg'} alt="maintenance insurance" /></span>
                        <span>{translate('maintenance-insurance-widget-heading')}</span>
                    </div>
                    <div className="widgetContant MaintenanceInsuranceWrapper">
                        <div className="widgetContentIn">
                            {this.state.itemsArray.length > 0 ? this.state.itemsArray : <NoData message={translate('nodata')} />}
                        </div>
                        {this.state.itemsArray.length > 0 ? <div className="widgetsMoreBtn">
                            <hr></hr>
                            <a href="/maintenanceInsurance">{translate('show-more')}<img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/arrow.svg'} alt="Click" /></a>
                        </div> : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default MaintenanceInsuranceWidget