import React from 'react'
import DateFormater from 'dateformat'
import translate from '../../../Providers/i18n/Translate'

function ManageBookingsItem(props) {
    return (
        <div className="manageYourBookingWrapper">
            
            <div className="widgetContentIn">
            <div className="col-md-12">
            <div className="row yourBookingHead">
                <div className="col-sm-5"><strong> {translate('BookingReferenceNumber')} : </strong></div>
                <div className="col-sm-7"> {props.payload.referenceNo} </div>
                <div className="col-sm-5"><strong> {translate('BookedDate')} : </strong></div>
                <div className="col-sm-7"> {DateFormater(props.payload.bookingDate, 'dd/mm/yyyy')} </div>
            </div>
            <div className="row">
                <div className="col-sm-5"><strong> {translate('projectName')} : </strong></div>
                <div className="col-sm-7"> {props.payload.projectDescription} </div>
            </div>
            <div className="row">
                <div className="col-sm-5"><strong> {translate('Style')} : </strong></div>
                <div className="col-sm-7"> {props.payload.villaStyle} </div>
            </div>
            <div className="row no-gutters yourBookingDetails">
                <div className="col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Housing_Projects.svg'} alt="Zone" /></i>
                    <span><strong> {translate('Zone')} : </strong> {props.payload.landLocation} </span>
                </div>
                <div className="col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Villa number" /></i>
                    <span><strong> {translate('VillaNo')} : </strong> {props.payload.villaNo} </span>
                </div>
                <div className="col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Floor" /></i>
                    <span><strong> {translate('NumberofFloors')} : </strong> {props.payload.floor} </span>
                </div>
                <div className="col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Land number" /></i>
                    <span><strong> {translate('LandNo')} : </strong> {props.payload.plotNumber} </span>
                </div>
                <div className="col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Building area" /></i>
                    <span><strong> {translate('BuildingArea')} : </strong> {props.payload.basedArea} </span>
                </div>
                <div className="col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/area.svg'} alt="Land area" /></i>
                    <span><strong> {translate('Landarea')} : </strong> {props.payload.plotArea} </span>
                </div>
                <div className="col-sm-6 housebookDetailsbox">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} alt="Number of bedroom" /></i>
                    <span><strong> {translate('NumberofBedrooms')} : </strong> {props.payload.bedroom} </span>
                </div>
            </div>
            </div>
            </div>
            <div className="widgetsMoreBtn">
                    <hr></hr>
                    <button onClick={props.myBookingDetails}> {translate('show-more')} <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/arrow.svg'} alt="Click" /></button>
                </div>
            </div>
    )
}
export default ManageBookingsItem