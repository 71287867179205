import React from 'react'
import history from '../../../History'
import NoData from '../../utils/nodata'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'

class ConstructionDisbursementServicesWidget extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            applications: []
        }

        this.setApplications = this.setApplications.bind(this)
    }

    componentDidMount() {
        this.getAllowedApplications(this.props.url)
    }

    getAllowedApplications() {
        if (CONSTANTS.authToken.length > 15){
            // fetch(CONSTANTS.getApplicationEndpoint + "?local=" + this.context.state.siteLang, {
            //     method: 'GET',
            //     headers: {
            //         'Authorization': CONSTANTS.authToken
            //     }
            // }).then(response => response.json()).then(this.setApplications).catch(this.loadFailed);
        }
    }

    setApplications(response) {
        var apps = []
        if (response.success) {
            this.setState({ applications: apps })
        } else {
            apps.push(<NoData message={response.message} />)
            this.setState({ applications: apps })
        }
    }

    loadFailed(response) {
        console.log(response)
    }

    SignDocList = event => {
        history.push("/ConstructionDisbursementList");
    };
    DocPayment = event => {
        history.push("/ConstructionDisbursementPayment");
    };
    DocDetails = event => {
        history.push("/ConstructionDisbursementDetails");
    };

    getSignDocView() {
        return (
            <div className="constructionDisbursementWrapper">
                <div className="row">
                    <div className="col-md-5">
                        <div>
                            <span>{translate('HousingStatus_ReqNo')}:</span>
                            <strong>11111</strong>
                        </div>
                        <div>
                            <span>{translate('applicantName')}</span>
                            <strong>Sample Name</strong>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                            <span>{translate('requestStatus')}: </span>
                            <strong>DOCUMENTS COMPLETE</strong>
                        </div>
                        <div>
                            <span>{translate('HousingStatus_ReqDate')}: </span>
                            <strong>01/06/2021</strong>
                        </div>
                    </div>
                    <div className="col-md-3 buttonColSide">
                        <button className="signBtnWidget" onClick={this.SignDocList}>
                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign.svg" alt="sign" /></i>
                            <span>{translate('signDocument')}</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    getDocPaymentView() {
        return (<div className="constructionDisbursementWrapper">
            <div className="row">
                <div className="col-md-5">
                    <div>
                        <span>{translate('HousingStatus_ReqNo')}:</span>
                        <strong>11111</strong>
                    </div>
                    <div>
                        <span>{translate('applicantName')}</span>
                        <strong>Sample Name</strong>
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <span>{translate('requestStatus')}: </span>
                        <strong>DOCUMENTS COMPLETE</strong>
                    </div>
                    <div>
                        <span>{translate('HousingStatus_ReqDate')}: </span>
                        <strong>01/06/2021</strong>
                    </div>
                </div>
                <div className="col-md-3 buttonColSide">
                    <button className="signBtnWidget" onClick={this.DocPayment}>
                        <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/credit-card.svg" alt="payment" /></i>
                        <span>{translate('InsurancePayment')}</span>
                    </button>
                </div>
            </div>
        </div>)
    }

    getDocDetailsView() {
        return (<div className="constructionDisbursementWrapper">
            <div className="row">
                <div className="col-md-5">
                    <div>
                        <span>{translate('HousingStatus_ReqNo')}:</span>
                        <strong>11111</strong>
                    </div>
                    <div>
                        <span>{translate('applicantName')}: </span>
                        <strong>Sample Name</strong>
                    </div>
                </div>
                <div className="col-md-4">
                    <div>
                        <span>{translate('requestStatus')}: </span>
                        <strong>DOCUMENTS COMPLETE</strong>
                    </div>
                    <div>
                        <span>{translate('HousingStatus_ReqDate')}: </span>
                        <strong>01/06/2021</strong>
                    </div>
                </div>
                <div className="col-md-3 buttonColSide">
                    <button className="infoBlack" onClick={this.DocDetails}>
                        <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info_white.svg" alt="sign" /></i>
                        <span>{translate('moreDetails')}</span>
                    </button>
                </div>
            </div>
        </div>)
    }

    render() {
        return (
            <div className="col-md-12 constructionDisbursementServicesWidget">

            </div >
        )
    }
}

export default ConstructionDisbursementServicesWidget

{/* <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/construction_disbursement.svg'} alt="Construction Disbursement Contract Requests" /></span>
                        <span>{translate('constructionDisbursementContractRequests')}</span>
                    </div>
                    <br></br>
                    <div className="widgetContant">
                        <div className="widgetContentIn">
                            {this.state.applications}
                            {this.getSignDocView()}
                            {this.getDocPaymentView()}
                            {this.getDocDetailsView()}
                        </div>
                    </div>
                </div> */}