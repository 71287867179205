import PdfListConstant from './PdfListConstant';
import BaseStore from '../../../BaseStore';

class PdfListStore extends BaseStore {

  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._success = false;
    this._response = null;
    this._categorySuccess = false;
    this._categoryResponse = null;
    this._uploadUnsingedAttachSuccess = false;
    this._uploadUnsingedAttachResponse = null;
    this._uploadUnsingedAttachMessage = '';
    this._deleteAttachmentSuccess = false;
    this._deleteAttachmentMessage = '';
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case PdfListConstant.CUSTOMER_INBOX_LIST:
        this._success = action.response.success;
        this._response = action.response.payload;
        this.emitChange();
        break;

      case PdfListConstant.CATEGORY_LIST:
        this._categorySuccess = action.response.success;
        this._categoryResponse = action.response.payload;
        this.emitChange();
        break;

      case PdfListConstant.UPLAOD_UNSIGNED_ATTACHMENT:
        this._uploadUnsingedAttachSuccess = action.response.success;
        this._uploadUnsingedAttachResponse = action.response.payload;
        this._uploadUnsingedAttachMessage = action.response.message;
        this.emitChange();
        break;

      case PdfListConstant.DELETE_DOCUMENT:
        this._deleteAttachmentSuccess = action.response.success;
        this._deleteAttachmentMessage = action.response.message;
        this.emitChange();
        break;

      default:
        break;
    };
  }
  isSuccess() {
    return this._success;
  }

  get response() {
    return this._response;
  }

  isCategoryListSuccess() {
    return this._categorySuccess;
  }

  get categoryListResponse() {
    return this._categoryResponse;
  }

  isUploadAUnsingedAttachSuccess() {
    return this._uploadUnsingedAttachSuccess;
  }

  get uploadUnsingedAttachResponse() {
    return this._uploadUnsingedAttachResponse;
  }

  get uploadUnsingedAttachMessage() {
    return this._uploadUnsingedAttachMessage;
  }

  isDeleteAttachmentSuccess() {
    return this._deleteAttachmentSuccess;
  }

  get deleteAttachmentMessage() {
    return this._deleteAttachmentMessage;
  }

}

export default new PdfListStore();