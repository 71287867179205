import PrintOpenNewProjectFile from './PrintOpenNewProjectFileComponent';
import React from "react";
import translate from '../../Providers/i18n/Translate'
import ContractorProjectEnum from "../../constants/ContractorProjectEnum";

class ViewBOQSummaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      object: this.props.location.state.item,
      statusId: Number(localStorage.getItem('statusId')),
      ownerScope: JSON.parse(localStorage.getItem('ownerScope'))
    };
  }
  print() {
    window.print();
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="container mainSection">
          <div className="row">
            {/* breadcrumb */}
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">

                  <li className="breadcrumb-item"><a href="/contractorDashboard">{translate('dashboard')}</a></li>

                  {this.state.statusId == ContractorProjectEnum.OpenNewProjectFile ?
                    <li className="breadcrumb-item"><a href="/openNewProjectFileDetail">{translate('openNewProjectFile')}</a></li>
                    : <li className="breadcrumb-item"><a href="/projectPaymentsDetail">{translate('projectPaymentRequests')}</a></li>}
                {this.state.ownerScope ? <li className="breadcrumb-item active" aria-current="page">{translate('ownerScopeSummary')}</li>
                : <li className="breadcrumb-item active" aria-current="page">{translate('boqSummary')}</li>}
                </ol>
              </nav>
            </div>

            <div className="col-md-12 project_step_top_Btns">
              <button className="printBtn" onClick={this.print}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/print_icon.svg'} alt="" /></i>{translate('Print')}</button>
            </div>
            <PrintOpenNewProjectFile projectListItem={this.state.object} />
          </div>
        </div>
      </div>
    );
  }
}

export default ViewBOQSummaryComponent;