export default {
    All: '0',
    Completed: '1',
    TVDone: '2',
    GL: '3',
    Maintenance: '4',
    GL2: '5',
    IP: '6',
    Cancelled: '7',
    Closed: '8',
    Settlement: '9',
    FileOpened: '10',
    Suspended: '11',
    Awad: '13',
    OpenNewProjectFile: 3,
    ContractorProjectPayments: 6,
    RetentionPayment: 1,
    EntryStatusTransactionType: 10,
    SubmitTransactionType: 1,
    TransactionType: 0
}