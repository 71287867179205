import React from "react"
import '../../../reactToastify.css'
import { Redirect } from 'react-router-dom';
import History from '../../../History'
import Loader from '../../utils/LoaderBars'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../../Providers/context'
import CommonUtils from '../../../utils/CommonUtils'
import CONSTANTS from '../../constants/dashboard-constants'
import HeaderStore from "../../../ApplicationBase/Header/HeaderStore"
import AwarnessesWidget from '../awarness-widget/awarnesses-widget-component'
import LinkAccountWidget from '../linking-widget/linkaccount-widget-component'
import ServicesWidgetComponent from '../service-widget/services-widget-component'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
import HousingRequestWidget from '../housingrequests-widget/housingrequest-widget-component'
import HousingProjectsWidget from '../housingprojects-widget/housingprojects-widget-component'
import FinancialStatusWidget from '../financialstatus-widget/financialstatus-widget-component'
import MaintenanceRequestsWidget from '../maintenance-requests/maintenance-requests-component'
import ManageBookingsWidget from '../managehousebooking-widget/managebookings-widget-component'
import MaintenanceInsuranceWidget from '../maintenance-insurance/maintenance-insurance-component'
import ApplicationStatusWidget from '../applicationstatus-widget/applicationstatus-widget-component'
import ContractorPaymentRequestsWidget from '../contractorpaymentrequests-widget/contractorpaymentrequests-widget-component'
import ContractorPaymentnewWidget from '../contractorpaymentnew/contractorpaymentsnew-widget-component'
import FinancialStatusWidgetGraph from '../financialstatus-widgetgraph/financialstatusgraph-widget-component'
import DashboardLoader from '../../../Contractor/Common/ContentLoader'
import ComplementaryServicesWidget from '../complementaryservices-widget/complementary-widget-component'
import ConstructionDisbursementServicesWidget from '../constructionloandisbursement-widget/constructionloandisbursement-widget-component'

var local
class Dashboard extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            serviceEditClick: false,
            widgetClick: false,
            widgetContant: false,
            linkAccountClick: false,
            linkAccountBg: false,
            linkAccount: false,
            widgetsArray: [],
            preExpanded: [],
            props: '',
            responsePayload: '',
            shouldUpdateHeader: true,
            isTourOpen: false,
            isShowingMore: false,
            isLoading: false,
            showLoading: false
        }
        this.addWidgets = this.addWidgets.bind(this)
        this.dashboardRequest = this.dashboardRequest.bind(this)
        this.onChange = this.onChange.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.startLoading = this.startLoading.bind(this)
    }

    componentWillUnmount() {
        HeaderStore.removeChangeListener(this.onChange)
    }

    startLoading() {
        this.setState({ showLoading: true })
    }

    stopLoading() {
        this.setState({ showLoading: false })
    }

    onChange() {
        var response = HeaderStore.response
        if (response.success) {
            this.setState({ responsePayload: response.payload }, () => {
                if (this.props.props.location.search) {
                    History.push({ pathname: CommonUtils.getRedirection(this.props.props.location.search, false).props.to, })
                }
            })
        }
    }

    componentDidMount() {
        local = "?local=" + this.context.state.siteLang
        HeaderStore.addChangeListener(this.onChange)
        this.addWidgets()
        this.setState({ isLoading: true, props: this.props })
    }

    dashboardRequest() {
        window.location.reload();
    }

    addWidgets() {
        this.pushServicesWidget()
    }

    /** index 0 */
    pushServicesWidget() {
        var widget = {
            id: '1',
            name: 'service-widget-heading',
            icon: 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Services_icon.svg',
            description: '',
            actionUrl: ''
        }
        var array = [...this.state.widgetsArray]
        var servicesWidgetComponent = array.find(({ widget }) => widget ? widget.type.name === 'ServicesWidgetComponent' : undefined)
        if (!servicesWidgetComponent)
            array.push(<ServicesWidgetComponent reference={this} widget={widget} />)
        this.setState({ widgetsArray: array }, () => {
            this.ConstructionDisbursementServicesWidget()
        })
    }

    setLoading() {
        this.setState({ isLoading: false })
    }

    /** index 1 */
    ConstructionDisbursementServicesWidget() {
        var array = [...this.state.widgetsArray]
        var disbursement = array.find(({ widget }) => widget ? widget.type.name === 'ConstructionDisbursementServicesWidget' : undefined)
        if (!disbursement)
            array.push(<ConstructionDisbursementServicesWidget />)
        this.setState({ widgetsArray: array }, () => {
            this.ComplementaryServicesWidget()
        })
    }


    /** index 1 */
    ComplementaryServicesWidget() {
        var array = [...this.state.widgetsArray]
        var housingRequestWidget = array.find(({ widget }) => widget ? widget.type.name === 'HousingRequestWidget' : undefined)
        if (!housingRequestWidget)
            // array.push(<HousingRequestWidget history={this.state.props.props.history} />)
            array.push(<ComplementaryServicesWidget />)
        this.setState({ widgetsArray: array }, () => {
            this.pushHousingRequests()
        })
    }

    /** index 2 */
    pushHousingRequests() {
        var array = [...this.state.widgetsArray]
        var housingRequestWidget = array.find(({ widget }) => widget ? widget.type.name === 'HousingRequestWidget' : undefined)
        if (!housingRequestWidget)
            array.push(<HousingRequestWidget history={this.state.props.props.history} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushApplicationStatusWidget()
        })
    }


    /** index 3 */
    pushApplicationStatusWidget() {
        var array = [...this.state.widgetsArray]
        var url = CONSTANTS.applicationStatusEndpoint + local + '&page=0&pageSize=2'
        var applicationStatusWidget = array.find(({ widget }) => widget ? widget.type.name === 'ApplicationStatusWidget' : undefined)
        if (!applicationStatusWidget)
            array.push(<ApplicationStatusWidget history={this.state.props.props.history} url={url} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushFinancialStatus()
        })
    }

    /** index 4 */
    pushFinancialStatus() {
        var array = [...this.state.widgetsArray]
        var url = CONSTANTS.financialStatusEndpoint + local
        var financialStatusWidget = array.find(({ widget }) => widget ? widget.type.name === 'FinancialStatusWidget' : undefined)
        if (!financialStatusWidget)
            array.push(<FinancialStatusWidget history={this.state.props.props.history} url={url} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushContractorPaymentRequestsWidget()
        })
    }

    /** index 5 */
    pushContractorPaymentRequestsWidget() {
        var array = [...this.state.widgetsArray]
        var url = CONSTANTS.contractorPaymentsRequestsEndpoint + local
        var contractorPaymentRequestsWidget = array.find(({ widget }) => widget ? widget.type.name === 'ContractorPaymentRequestsWidget' : undefined)
        if (!contractorPaymentRequestsWidget)
            array.push(<ContractorPaymentRequestsWidget history={this.state.props.props.history} url={url} />)
        this.setState({ widgetsArray: array }, () => {
            this.financialStatusGraph()
        })
    }

    /** index 6 */
    financialStatusGraph() {
        var array = [...this.state.widgetsArray]
        var financialStatusWidget = array.find(({ widget }) => widget ? widget.type.name === 'FinancialStatusWidget' : undefined)
        if (!financialStatusWidget)
            array.push(<FinancialStatusWidgetGraph history={this.state.props.props.history} />)
        this.setState({ widgetsArray: array }, () => {
            this.contractorPaymentsNew()
        })
    }

    /** index 7 */
    contractorPaymentsNew() {
        var array = [...this.state.widgetsArray]
        var contractorPaymentsWidget = array.find(({ widget }) => widget ? widget.type.name === 'ContractorPaymentsWidget' : undefined)
        if (!contractorPaymentsWidget)
            array.push(<ContractorPaymentnewWidget history={this.state.props.props.history} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushMaintenanceWidgets()
        })
    }

    /** index 8 */
    pushMaintenanceWidgets() {
        var array = [...this.state.widgetsArray]
        var url = CONSTANTS.maintananceRequestsEndPoint + local + '&page=0&pageSize=3'
        var maintenanceInsuranceWidget = array.find(({ widget }) => widget ? widget.type.name === 'MaintenanceInsuranceWidget' : undefined)
        if (!maintenanceInsuranceWidget)
            array.push(<MaintenanceRequestsWidget history={this.state.props.props.history} url={url} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushMaintenanceInsuranceWidget()
        })
    }

    /** index 9 */
    pushMaintenanceInsuranceWidget() {
        var array = [...this.state.widgetsArray]
        var url = CONSTANTS.maintenanceInsuranceEndpoint + local + '&page=0&pageSize=2'
        var maintenanceRequestsWidget = array.find(({ widget }) => widget ? widget.type.name === 'MaintenanceRequestsWidget' : undefined)
        if (!maintenanceRequestsWidget)
            array.push(<MaintenanceInsuranceWidget history={this.state.props.props.history} url={url} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushHousingProjects()
        })
    }

    /** index 10 */
    pushHousingProjects() {
        var array = [...this.state.widgetsArray]
        var url = CONSTANTS.housingprojectEndpoint + local
        var housingProjectsWidget = array.find(({ widget }) => widget ? widget.type.name === 'HousingProjectsWidget' : undefined)
        if (!housingProjectsWidget)
            array.push(<HousingProjectsWidget history={this.state.props.props.history} url={url} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushHouseBooking()
        })
    }

    // pushContractorPayments() {
    //     var array = [...this.state.widgetsArray]
    //     var url = CONSTANTS.contractorPaymentsEndpoint + local + '&page=0&pageSize=4'
    //     var contractorPaymentsWidget = array.find(({ widget }) => widget ? widget.type.name === 'ContractorPaymentsWidget' : undefined)
    //     if (!contractorPaymentsWidget)
    //         array.push(<ContractorPaymentsWidget history={this.state.props.props.history} url={url} />)
    //     this.setState({ widgetsArray: array }, () => {
    //         this.pushContractorPaymentRequestsWidget()
    //     })
    // }

    /** index 11 */
    pushHouseBooking() {
        var array = [...this.state.widgetsArray]
        var url = CONSTANTS.houseBookingMyBookingsEndPoint + local
        var manageBookingsWidget = array.find(({ widget }) => widget ? widget.type.name === 'ManageBookingsWidget' : undefined)
        if (!manageBookingsWidget)
            array.push(<ManageBookingsWidget history={this.state.props.props.history} url={url} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushAwareness()
        })
    }

    //  /** index 12 */
    //  pushChangeServiceRequestWidget() {
    //     var array = [...this.state.widgetsArray]
    //     var changeServiceRequestWidget = array.find(({ widget }) => widget ? widget.type.name === 'changeServiceRequestWidget' : undefined)
    //     if (!changeServiceRequestWidget)
    //         // array.push(<ChangeServiceRequestWidget history={this.state.props.props.history} />)
    //         array.push(<ChangeServiceRequestWidget />)
    //     this.setState({ widgetsArray: array }, () => {
    //         this.pushAwareness()
    //     })
    // }

    /** index 12 */
    pushAwareness() {
        var array = [...this.state.widgetsArray]
        var awarnessesWidget = array.find(({ widget }) => widget ? widget.type.name === 'AwarnessesWidget' : undefined)
        if (!awarnessesWidget)
            array.push(<AwarnessesWidget history={this.state.props.props.history} />)
        this.setState({ widgetsArray: array }, () => {
            this.pushLinkWidget(true)
        })
    }

    /** index 13 */
    pushLinkWidget(mustLink) {
        var array = [...this.state.widgetsArray]
        var linkWidget = array.find(({ widget }) => widget ? widget.type.name === 'LinkAccountWidget' : undefined)
        if (!linkWidget) {
            array.push(<LinkAccountWidget isMustLink={mustLink} refresh={this.dashboardRequest} />)
        }
        this.setState({ widgetsArray: array })
        setTimeout(function () { this.setState({ isLoading: false }) }.bind(this), 1000)
    }

    render() {
        if (localStorage.getItem("userType") === '1') {
            return (
                <div className="container-fluid">
                    {this.state.showLoading ? <Loader /> : null}
                    <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                    {this.state.isLoading ? <DashboardLoader /> :
                        <div className="container mainSection">
                            {this.state.widgetsArray[0]}
                            {/* NEW */}
                            <div className="row">
                                {this.state.widgetsArray[1]}
                            </div>
                            <div className="row">
                                {this.state.widgetsArray[2]}
                            </div>
                            <div className="row">
                                {/** Link Account this.state.responsePayload.mustLink && */}
                                {this.state.responsePayload && this.state.responsePayload.mustLink ? this.state.widgetsArray[14] : null}
                                {/* {this.state.widgetsArray[12]} */}
                            </div>
                            <div className="row">
                                {this.state.widgetsArray[6]}
                                {this.state.widgetsArray[4]}
                            </div>
                            <div className="row">
                                {this.state.widgetsArray[3]}
                            </div>
                            <div className="row">
                                {this.state.widgetsArray[7]}
                                {this.state.widgetsArray[8]}
                            </div>
                            <div className="row">
                                {this.state.widgetsArray[12]}
                                {this.state.widgetsArray[5]}
                            </div>
                            <div className="row">
                                {this.state.widgetsArray[11]}
                            </div>
                            <div className="row">
                                {this.state.widgetsArray[9]}
                                {this.state.widgetsArray[10]}
                            </div>
                            {/* <div className="row">
                                {this.state.widgetsArray[12]}
                            </div> */}
                            <div className="row">
                                {this.state.widgetsArray[13]}
                            </div>
                        </div>}
                    {/* <div className="helpBut"></div> */}
                </div>
            );
        } else {
            return <Redirect to='/contractorDashboard' />
        }
    }
}

export default AuthenticatedComponent(Dashboard)