import CONSTANTS from '../constants/dashboard-constants'
const RequestBuilder = {
    buildUrl: function (url, parameters) {
        let qs = ''
        for (const key in parameters) {
            if (parameters.hasOwnProperty(key)) {
                const value = parameters[key];
                qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&'
            }
        }
        if (qs.length > 0) {
            qs = qs.substring(0, qs.length - 1);
            url = url + '&' + qs;
        }

        return url;
    },
    serverGETRequest: function (url, params, successCallback, errorCallback) {
        fetch(this.buildUrl(url, params), {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(successCallback).catch(errorCallback);
    }
}
export default RequestBuilder