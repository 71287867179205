import React from 'react'
import NoData from '../utils/nodata'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import MaintenanceRequestsStore from './maintenance-requests-store'
import MaintenanceRequestsService from './maintenance-requests-service'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class MaintenanceRequests extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super()
        this.state = {
            itemsArray: []
        }
        this.onChange = this.onChange.bind(this)
        this.setMaintenanceRequests = this.setMaintenanceRequests.bind(this)
        this.showSingleRequest = this.showSingleRequest.bind(this)
        this.refresh = this.refresh.bind(this)
        this.deleteRequest = this.deleteRequest.bind(this)
        this.yes = this.yes.bind(this)
        this.no = this.no.bind(this)
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.payload) {
            this.showSingleRequest(this.props.location.state.payload)
        } else {
            MaintenanceRequestsStore.addChangeListener(this.onChange)
            MaintenanceRequestsService.fetchMaintenanceRequest(100, "?local=" + this.context.state.siteLang)
        }
    }

    onChange() {
        this.setMaintenanceRequests(MaintenanceRequestsStore.response)
        this.refresh(MaintenanceRequestsStore.deleteResponse)
    }

    deleteRequest(e) {
        e.persist()
        const requestNumber = e.currentTarget.name
        if (requestNumber) {
            this.setState({ requestNumber: requestNumber, showConfirmation: !this.state.showConfirmation })
        }
    }

    refresh(response) {
        if (response) {
            if (response.success) {
                if (this.state.itemsArray.length > 0) {
                    MaintenanceRequestsService.fetchMaintenanceRequest(100, "?local=" + this.context.state.siteLang)
                } else {
                    this.showSingleRequest('')
                }
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
            MaintenanceRequestsStore.clearDeleteResponse()
        }
    }

    showSingleRequest(payload) {
        var items = []
        items.push(<MaintenanceRequestItemView key={0} index={0} payload={payload} deleteRequest={this.deleteRequest} />)
        this.setState({ itemsArray: items })
    }

    setMaintenanceRequests(response) {
        if (response.success && response.payload.length > 0) {
            var items = []
            response.payload.map((item, index) => {
                items.push(<MaintenanceRequestItemView key={index} index={index} payload={item} deleteRequest={this.deleteRequest} />)
            })
            this.setState({ itemsArray: items })
        } else {
            // console.log(response)
        }
    }

    yes() {
        if (this.state.requestNumber) {
            this.no()
            MaintenanceRequestsService.deleteMaintenanceRequest(this.state.requestNumber, "?local=" + this.context.state.siteLang)
        }
    }

    no() {
        this.setState({ requestNumber: '', showConfirmation: !this.state.showConfirmation })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <ToastContainer refs={this} autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                    {/* delete confirmation popup */}
                    {this.state.showConfirmation ?
                        <div className="PopupBk"></div> : null}
                    {this.state.showConfirmation ?
                        <div className="deleteConfirmationPopup">
                            <p>{translate('deleteconfirmation')}</p>
                            <div className="button">
                                <button onClick={this.yes}>{translate('yes')}</button>
                                <button onClick={this.no}>{translate('no')}</button>
                            </div>
                        </div> : null}

                    {/* delete confirmation popup */}
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('MaintenanceRequest')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ContractorPayment.svg'} alt="Maintenance Request" /></i><span>{translate('MaintenanceRequest')}</span></div>
                        </div>
                        {this.state.itemsArray.length > 0 ? this.state.itemsArray : <NoData message={translate('nodata')}/>}
                    </div>
                </div>
            </div >
        )
    }
}

function MaintenanceRequestItemView(props) {
    return (
        <div className="col-md-12">

            <div className="service_contant_wrapper maintenanceRequestsWrapperBox">
                <div className="row">
                    <div className="col-md-12">
                        <div className="maintenanceRequestsTitle">
                            <span>{translate('requestNo')}:</span>
                            <strong>{props.payload.id}</strong>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('requestNo')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.id}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('status')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.status}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('requestDate')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.requestDate}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('AppointmentDate')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.appointmentDate}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('problemType')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.problemTypeId}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('problemRemarks')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.problemRemarks}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('problemDescription')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.problemTypeDescription}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('endDate')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.endDate}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('contractorName')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.contractor.name}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('email')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.contractor.email}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('ContactPersonName')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.contractor.contactPerson}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('mobileNo')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.contractor.contactMobile}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('officePhone')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.contractor.officeNo}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span></span>
                            </div>
                            <div className="col-md-8">
                                <strong></strong>
                            </div>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('contractorRemarks')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.contractorRemarks}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-4">
                                <span>{translate('requestRelativeDate')}:</span>
                            </div>
                            <div className="col-md-8">
                                <strong>{props.payload.appointmentDescriptionLongStringDate}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 formSectionBtn">
                        <hr></hr>
                        <button name={props.payload.id} onClick={props.deleteRequest}>{translate('delete')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AuthenticatedComponent(MaintenanceRequests)