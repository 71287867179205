import AppDispatcher from '../../Dispatcher';
import ContractorProjectPaymentsConstant from './ContractorProjectPaymentsConstant';


class ContractorProjectPaymentsAction {

  projectPayments(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_PROJECT_PAYMENTS,
      response: response
    })
  }

  contractorEntrySatus(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_ENTRY_STATUS,
      response: response
    })
  }


  projectPaymentsBreakDown(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_PROJECT_PAYMENTS_BREAK_DOWN,
      response: response
    })
  }

  projectPaymentsOwnerAmount(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_ADD_PAYMENT_HEADER_OWNER_A,
      response: response
    })
  }

  projectPaymentsHeaderDetails(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_ADD_PAYMENT_HEADER_DETAILS,
      response: response
    })
  }
  getAllDocuments(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_GET_ALL_DOCUMENTS,
      response: response
    })
  }

  uploadDocument(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_UPLOAD_DOCUMENTS,
      response: response
    })
  }

  viewDocument(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_VIEW_DOCUMENTS,
      response: response
    })
  }

  deleteDocument(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_DELETE_DOCUMENTS,
      response: response
    })
  }

  submitStatus(response) {
    AppDispatcher.dispatch({
      actionType: ContractorProjectPaymentsConstant.CONTRACTOR_SUBMIT_STATUS,
      response: response
    })
  }
}

export default new ContractorProjectPaymentsAction();
