import InformationServiceConstant from './InformationServiceConstant';
import BaseStore from '../BaseStore';

class GetHousingApplicationStatusStore extends BaseStore {
  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._success = false;
    this._response = null;
    this._isSuccessMaintenence = false;
    this._responseMaintenence = null;
    this._isSuccessPayment = false;
    this._responsePayment = null;
    this._messagePayment = null;
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case InformationServiceConstant.HOUSING_APPLICATION_STATUS:
        this._success = action.response.success;
        this._response = action.response.payload;
        this.emitChange();
        break;
      case InformationServiceConstant.MAINTENANCE_STATUS:
        this._isSuccessMaintenence = action.response.success;
        this._responseMaintenence = action.response.payload;
        this.emitChange();
        break;
      case InformationServiceConstant.PAYMENT_STATUS:
        this._isSuccessPayment = action.response.success;
        this._responsePayment = action.response.payload;
        this._messagePayment = action.response.message;
        this.emitChange();
        break;
      default:
        break;
    };
  }
  isSuccess() {
    return this._success;
  }

  get response() {
    return this._response;
  }
  isSuccessMaintenence() {
    return this._isSuccessMaintenence;
  }

  get responseMaintenence() {
    return this._responseMaintenence;
  }

  isSuccessPayment() {
    return this._isSuccessPayment;
  }

  get responsePayment() {
    return this._responsePayment;
  }

  get messagePayment() {
    return this._messagePayment;
  }
}

export default new GetHousingApplicationStatusStore();