import React from "react";
import ContractorProjectPaymentsStore from '../ContractorProjectPayments/ContractorProjectPaymentsStore';
import ContractorProjectPaymentsService from '../ContractorProjectPayments/ContractorProjectPaymentsService';
import { Redirect } from 'react-router-dom';
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent';
import ContractorProjectEnum from '../../constants/ContractorProjectEnum'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'


class ProjectsListComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = {
            statusId: Number(localStorage.getItem('statusId')),
            serviceCard: localStorage.getItem('serviceCard'),
            page: '0',
            page: '0',
            pageSize: '250',
            response: '',
            isSuccess: false,
            isShow: false,
            item: '',
            alert: true,
        }
        this.getProjectPaymentsState();
        this._onChange = this._onChange.bind(this);
    }

    componentDidMount() {
        if (!this.state.response) {
            this.getAllProjectPayments();
        }
        ContractorProjectPaymentsStore.addChangeListener(this._onChange);
        console.log("service info link = " + this.state.serviceCard)
    }

    componentWillUnmount() {
        ContractorProjectPaymentsStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.getProjectPaymentsState());
    }

    getProjectPaymentsState() {
        return {
            response: ContractorProjectPaymentsStore.response,
            isSuccess: ContractorProjectPaymentsStore.isSuccess()
        };
    }

    getAllProjectPayments() {
        ContractorProjectPaymentsService.projectPayments(this.state.statusId, this.state.page, this.state.pageSize, this.context.state.siteLang);
    }

    modifyClick = (object) => {
        const itemObject = object
        this.setState(state => ({
            isShow: !state.isShow,
            item: itemObject
        }));
    };

    alertClose() {
        this.setState({
            alert: !this.state.alert
        })
    }


    render() {
        const { response, isSuccess, item, statusId, alert } = this.state;
        let noDataChangeState = '';
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/contractorDashboard">{translate('dashboard')}</a></li>
                                    {statusId == ContractorProjectEnum.OpenNewProjectFile ? <li className="breadcrumb-item active" aria-current="page">{translate('projectFileRequests')}</li> : null}
                                    {statusId == ContractorProjectEnum.ContractorProjectPayments ? <li className="breadcrumb-item active" aria-current="page">{translate('projectPaymentRequests')}</li> : null}
                                    {statusId == ContractorProjectEnum.RetentionPayment ? <li className="breadcrumb-item active" aria-current="page">{translate('retentionPaymentRequest')}</li> : null}
                                </ol>
                            </nav>
                        </div>
                    </div>

                    {/* Loan */}
                    <div className="row">
                        <div className="col-md-12">
                        <a href={this.state.serviceCard} target="_blank"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg" alt="serInfo" /></a>
                            {statusId == ContractorProjectEnum.OpenNewProjectFile ? <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/opennewfile_icon.svg" alt="open" /></i>
                                <span>{translate('openNewProjectFileConstructionloan')}</span>  </div> : null}
                            {statusId == ContractorProjectEnum.ContractorProjectPayments ? <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractorProjectPayment.svg" alt="Contractor Payment" /></i>
                                <span>{translate('contractorProjectPaymentConstructionloan')}</span>   </div> : null}
                            {statusId == ContractorProjectEnum.RetentionPayment ? <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/RetentionPayment.svg" alt="Contractor Payment Request" /></i>
                                <span>{translate('retentionPaymentconstructionloan')}</span>   </div> : null}
                        </div>
                        {/* <div className="contractorSerInfoWrapper"> */}
                            
                        {/* </div> */}

                        <div className="col-md-12">
                            {isSuccess && response ? response.map((value, index) => {
                                if (value.source == 'ENG') {
                                    noDataChangeState = value.source;
                                    return (
                                        <div key={index}>
                                            <div className="service_contant_wrapper">
                                                <div className="row">
                                                    <div className="col-sm-1 projectStatusIco">
                                                        {statusId == ContractorProjectEnum.OpenNewProjectFile ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg" alt="newfile" /> : null}
                                                        {statusId == ContractorProjectEnum.ContractorProjectPayments ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractor_Project_Payment_Construction_Loan.svg" alt="MBRHE" /> : null}
                                                        {statusId == ContractorProjectEnum.RetentionPayment ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Retention_Payment_Construction_Loan.svg" alt="retention" /> : null}
                                                    </div>
                                                    <div className="col-sm-11 housingRequestHead">
                                                        <h3>{translate('applicationNo')}:  {value.applicationNo}</h3>
                                                        <h4>{value.owner}</h4>
                                                    </div>
                                                </div>
                                                <div className="row topLine">
                                                    <div className="col-sm-1"></div>
                                                    <div className="col-sm-11">
                                                        <div className="row">
                                                            {this.setStatusWithColor(value)}
                                                            <div className="col-sm-6">
                                                                <span>{translate('applicationDate')} :</span> <span>{value.transactionDate}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row topLine">
                                                    <div className="col-md-12">
                                                        {value.remarks && alert ? <div className="alert alert-primary" role="alert">
                                                            <button onClick={this.alertClose.bind(this)} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                            <span>{translate('note')}</span>{value.remarks}
                                                        </div> : null}
                                                    </div>
                                                    <div className="col-md-12 right_align" >
                                                        {statusId == ContractorProjectEnum.OpenNewProjectFile ? this.redirect(item, '/openNewProjectFileDetail') : null}
                                                        {statusId == ContractorProjectEnum.ContractorProjectPayments ? this.redirect(item, '/projectPaymentsDetail') : null}
                                                        {statusId == ContractorProjectEnum.RetentionPayment ? this.redirect(item, '/retentionPaymentDetail') : null}
                                                        {value.enableEdit ? <button className="modifiBtn" onClick={this.modifyClick.bind(this, value)} >
                                                            <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg" alt="edit Contrast" /> {translate('modify')}
                                                        </button>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }) : null}
                            {!isSuccess && response !== "" ? <div>{this.noData()}</div> : null}
                            {noDataChangeState !== "ENG" ? <div>{this.noData()}</div> : null}
                        </div>

                        {/* Grant */}
                        <div className="col-md-12">
                            {statusId == ContractorProjectEnum.OpenNewProjectFile ? <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/opennewfile_icon.svg" alt="open new file" /></i>
                                <span>{translate('openNewProjectFileMaintenanceGrant')}</span>  </div> : null}
                            {statusId == ContractorProjectEnum.ContractorProjectPayments ? <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractorProjectPayment.svg" alt="Contractor Payment" /></i>
                                <span>{translate('contractorProjectPaymentMaintenanceGrant')}</span>   </div> : null}
                            {statusId == ContractorProjectEnum.RetentionPayment ? <div className="service_title_wrapper"><i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/RetentionPayment.svg" alt="Contractor Payment Request" /></i>
                                <span>{translate('retentionPaymentmaintenancegrant')}</span>   </div> : null}
                        </div>
                        <div className="col-md-12">
                            {isSuccess && response ? response.map((value, index) => {
                                if (value.source == 'TQL') {
                                    noDataChangeState = value.source;
                                    return (
                                        <div key={index}>
                                            <div className="service_contant_wrapper">
                                                <div className="row">
                                                    <div className="col-sm-1 projectStatusIco">
                                                        {statusId == ContractorProjectEnum.OpenNewProjectFile ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg" alt="newfile" /> : null}
                                                        {statusId == ContractorProjectEnum.ContractorProjectPayments ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/contractor_Project_Payment_Construction_Loan.svg" alt="MBRHE" /> : null}
                                                        {statusId == ContractorProjectEnum.RetentionPayment ? <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Retention_Payment_Construction_Loan.svg" alt="retention" /> : null}
                                                    </div>
                                                    <div className="col-sm-11 housingRequestHead">
                                                        <h3>{translate('applicationNo')}:  {value.applicationNo}</h3>
                                                        <h4>{value.owner}</h4>
                                                    </div>
                                                </div>
                                                <div className="row topLine">
                                                    <div className="col-sm-1"></div>
                                                    <div className="col-sm-11">
                                                        <div className="row">
                                                            {this.setStatusWithColor(value)}
                                                            <div className="col-sm-6">
                                                                <span>{translate('applicationDate')} :</span> <span>{value.transactionDate}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row topLine">
                                                    <div className="col-md-12">
                                                        {value.remarks && alert ? <div className="alert alert-primary" role="alert">
                                                            <button onClick={this.alertClose.bind(this)} type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                            <span>{translate('note')}</span>{value.remarks}
                                                        </div> : null}
                                                    </div>
                                                    <div className="col-md-12 right_align" >
                                                        {statusId == ContractorProjectEnum.OpenNewProjectFile ? this.redirect(item, '/openNewProjectFileDetail') : null}
                                                        {statusId == ContractorProjectEnum.ContractorProjectPayments ? this.redirect(item, '/projectPaymentsDetail') : null}
                                                        {statusId == ContractorProjectEnum.RetentionPayment ? this.redirect(item, '/retentionPaymentDetail') : null}
                                                        {value.enableEdit ? <button className="modifiBtn" onClick={this.modifyClick.bind(this, value)}>
                                                            <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg" alt="edit Contrast" /> {translate('modify')}</button>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            }) : null}
                            {!isSuccess && response !== "" ? <div>{this.noData()}</div> : null}
                            {noDataChangeState !== "TQL" ? <div>{this.noData()}</div> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setStatusWithColor(value) {
        return (
            <div className="col-sm-6">
                <span>{translate('status')} : </span>

                {value.status == 'Not Complete' || value.status == 'غير مكتمل' ?
                    <span className="notComplet">{value.status}</span> : null}

                {value.status == 'Completed - Waiting Owner Approval' || value.status == 'مكتملة - لاعتماد المالك' ?
                    <span className="completedWaiting">{value.status}</span> : null}

                {value.status == 'Completed - Documents Checking' || value.status == 'مكتملة - تدقيق على المستندات' ?
                    <span className="completedDocumentsChecking">{value.status}</span> : null}

                {value.status == 'Expired - Owner Approval Not Received' || value.status == 'انتهت الصلاحية - لم يتم استلام موافقة المالك' ?
                    <span className="expiredNotReceived">{value.status}</span> : null}

                {value.status == 'Documents Received' || value.status == 'تم استلام المستندات' ?
                    <span className="documentsReceived">{value.status}</span> : null}

                {value.status == 'New' || value.status == 'طلب جديد' ?
                    <span className="new">{value.status}</span> : null}

                {value.status == 'Approved' || value.status == 'موافقة نهائية' ?
                    <span className="approved">{value.status}</span> : null}

                {value.status == 'On Hold' || value.status == 'في الانتظار' ?
                    <span className="onHold">{value.status}</span> : null}

                {value.status == 'In Progress' || value.status == 'قيد الاجراء' ?
                    <span className="inProgress">{value.status}</span> : null}

                {value.status == 'Pre Approval' || value.status == 'مواقفة مبدئية' ?
                    <span className="preApproval">{value.status}</span> : null}

                {value.status == 'Received' || value.status == 'جديد' ?
                    <span className="received">{value.status}</span> : null}

                {value.status == 'Rejected' || value.status == 'مرفوض' ?
                    <span className="rejected">{value.status}</span> : null}

                {value.status == 'Suspended' || value.status == 'معلق' ?
                    <span className="suspended">{value.status}</span> : null}

            </div>
        )
    }

    redirect(item, page) {
        if (item) {
            localStorage.setItem('projectItem', JSON.stringify(item))
        }
        return (
            <div>
                {this.state.isShow ? <Redirect
                    to={{
                        pathname: page
                        // state: { item }
                    }}
                /> : null}
            </div>
        )
    }
    noData() {
        return (
            <div className="service_contant_wrapper  service_contant_no_data">
                <div className="row">
                    <div className="col-sm-12">
                        <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/nodata.svg" alt="no data" /></span>
                        <h3>{translate('noData')}</h3>
                    </div>
                </div>
            </div>
        );
    }
}
export default AuthenticatedComponent(ProjectsListComponent);