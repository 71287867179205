import React from 'react';
import { Redirect } from 'react-router-dom';

export default class AuthenticatedApp extends React.Component {

    render() {
        return (
            <div className="container">
                {this.redirectComponent}
            </div>
        );
    }

    get redirectComponent() {
        var savedJwt = localStorage.getItem('accessToken');
        var userType = localStorage.getItem('userType');
        if (savedJwt) {
            if (userType) {
                return (
                    <Redirect to='/customerDashboard' />
                )
            } else {
                return (
                    <Redirect to='/contractorDashboard' />
                )
            }
        } else {
            return (
                <Redirect to='/eservices' />
            )
        }
    }

}