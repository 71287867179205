import DocumentVerificationAction from './DocumentVerificationAction';
import DocumentVerificationConstant from './DocumentVerificationConstant';
import request from 'reqwest';

class ContractorProjectPaymentsService {

  /** verify Document */
  verifyDocumentById(referenceNo) {
    return this.handleResponse(request({
      url: DocumentVerificationConstant.VERIFY_DOCUMENT_URL + referenceNo,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: { 'consumer-key': process.env.CLIENT_ID, 'consumer-secret': process.env.CLIENT_SECRET }
    }));
  }

  handleResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        DocumentVerificationAction.verifyDocument(response);
        return true;
      });
  }
}

export default new ContractorProjectPaymentsService();