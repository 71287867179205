import React from 'react'
import Loader from '../utils/LoaderBars'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import AwarenessStore from '../dashboard/awarness-widget/awareness-store'
import AwarenessService from '../dashboard/awarness-widget/awareness-service'
import AwarnessesItem from './awarnesses-item'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
class AwarnessesComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            showLoading: true
        }
        this.onChange = this.onChange.bind(this)
        this.setItems = this.setItems.bind(this)
    }

    componentWillUnmount() {
        AwarenessStore.removeChangeListener(this.onChange)
    }

    componentDidMount() {
        AwarenessStore.addChangeListener(this.onChange)
        AwarenessService.awarenessRequest("?local=" + this.context.state.siteLang)
    }

    onChange() {
        this.setItems(AwarenessStore.response)
    }

    setItems(response) {
        var awarnessesItems = []
        var items = response.payload
        items.map((awarnesse, index) => {
            return (awarnessesItems.push(<AwarnessesItem key={index} id={awarnesse.id} type={awarnesse.type} description={awarnesse.description} image={awarnesse.image} />))
        })
        this.setState({ showLoading: false, itemsArray: awarnessesItems })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('awareness-widget-heading')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Awareness.svg'} alt="" /></i><span>{translate('awareness-widget-heading')}</span></div>
                        </div>
                        <div className="col-md-12">
                            {this.state.showLoading ? <Loader /> :
                                <div className="service_contant_wrapper formSection">
                                    <div className="row">
                                        {this.state.itemsArray.size !== 0 ? this.state.itemsArray : null}
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AuthenticatedComponent(AwarnessesComponent)