import ChatbotConstant from './ChatbotConstant';
import BaseStore from '../BaseStore';
import { format } from 'date-fns';

class ChatbotStore extends BaseStore {

  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._ConversationId = null;
    this._FaqId = null;
    this._Locale = null;
    this._BotType = null;
    this._response = null;
  }

  _registerToActions(action) {
    switch (action.actionType) {
        
      case ChatbotConstant.CHATBOT_DIRECTLINE:
        this._ConversationId = action.response.conversationId; 
        ///this._FaqId = action.response.FaqId; 
        this._Locale = action.response.locale; 
        this._BotType = action.response.action; 
        this._response = action.response.activities.length ? action.response.activities : [{ Success: "Error", text: action.response.message}]; 
        this.emitChange();
        break;    
        
      default:
        break;
    };
  }
  
  ConversationId() {
    return this._ConversationId;
  }
  
  isSuccess() {
    return this._ConversationId !== '' ? true : false;
  }
  
  FaqId() {
    return this._FaqId;
  }

  
  BotType() {
    return this._BotType;
  }
  
  Locale() {
    return this._Locale;
  }

  response(state_response) {
        var response = new Array();
        if(state_response.length){
            for(var i=0; i<state_response.length; i++){
                response.push(state_response[i]);
            }
        }
        if(this._response.length){
            this._response[0].time = format(new Date(), 'HH:m:i');
            this._response[0].ServiceType =  this._BotType === 'FAQ' ?  '' : this._response[0].serviceType;
            response.push(this._response);
        }
        return response;
  }
}

export default new ChatbotStore();