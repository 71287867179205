import React from 'react'
import history from '../../History'
import { toast } from 'react-toastify'
import { ScrollToTop } from '../../utils/Scroll'
import InputComponent from '../utils/inputComponent'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class StepFour extends React.Component {

    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            ticketPayload: '',
            dashboardPayload: '',
            isPaid: false,
            error: false,
            errorMessage: '',
            errors: [],
        }
        this.goBack = this.goBack.bind(this)
        this.showError = this.showError.bind(this)
        this.addError = this.addError.bind(this)
        this.reset = this.reset.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
        this.removeError = this.removeError.bind(this)
        this.requestPayment = this.requestPayment.bind(this)
        this.paymentRequestResponse = this.paymentRequestResponse.bind(this)
        this.submitRequest = this.submitRequest.bind(this)
        this.acceptClick = this.acceptClick.bind(this)
        this.completePaymentSuccess = this.completePaymentSuccess.bind(this)
        this.completePayment = this.completePayment.bind(this)
        this.completeRequest = this.completeRequest.bind(this)
        this.completeSuccess = this.completeSuccess.bind(this)
        this.changeListener = this.changeListener.bind(this)
        this.getPaymentInformation = this.getPaymentInformation.bind(this)
        this.getTicket = this.getTicket.bind(this)
        this.getTicketSuccess = this.getTicketSuccess.bind(this)
    }

    componentDidMount() {
        ScrollToTop()
        var dashboardPayload = JSON.parse(localStorage.getItem('dashboard')).payload
        this.setState({ trnNo: this.props.reference.getPaymentValues(), email: localStorage.emailaddress ? localStorage.getItem('emailaddress') : dashboardPayload.email, mobileNo: localStorage.mobileno ? localStorage.getItem('mobileno') : dashboardPayload.primaryMobileNo }, () => {
            this.completePayment()
        })
        this.setState({ ticketPayload: localStorage.getItem('ticketPayload') ? JSON.parse(localStorage.getItem('ticketPayload')) : '' }, () => {
            this.getPaymentInformation(this.state.ticketPayload.ticketNo)
            if (this.state.ticketPayload.ticketNo) {
                this.getTicket(this.state.ticketPayload.ticketNo)
            }
            // this.setState({ paymentResponse: JSON.parse(localStorage.getItem('paymentResponse')) })
        })
    }

    getTicket(ticketNo) {
        localStorage.setItem("dTicketNo", ticketNo)
        this.setState({ showLoading: true }, () => {
            fetch(CONSTANTS.getDisbursementTicketDataEndpoint + '/' + ticketNo + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.getTicketSuccess).catch(this.loadFailed);
        })
    }

    getTicketSuccess(response) {
        this.setState({ showLoading: false }, () => {
            if (response.success) {
                this.setState({ ticketPayload: response.payload }, () => { localStorage.setItem("ticketPayload", JSON.stringify(response.payload)) })
            } else {
                this.loadFailed(response)
            }
        })
    }

    completePayment() {
        if (this.state.trnNo && this.state.trnNo.sptrn) {
            setTimeout(this.props.reference.startLoading(), 300)
            var token = localStorage.getItem('accessToken')
            if (token) {
                fetch(CONSTANTS.completeRequestLoanDisbursementPaymentURL + "?local=" + this.context.state.siteLang + "&spTrn=" + this.state.trnNo.sptrn + "&ticketNo=" + this.state.ticketPayload.ticketNo, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'bearer ' + token
                    }
                }).then(response => response.json()).then(this.completePaymentSuccess).catch(this.loadFailed)
            }
        }
    }

    getPaymentInformation(ticketNo) {
        fetch(CONSTANTS.getPaymentInformationEndpoint + '?ticketNo=' + ticketNo + "&local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then((response) => {
            this.props.reference.stopLoading()
            localStorage.setItem('paymentResponse', JSON.stringify(response))
            response.success ? this.setState({ paymentResponse: response.payload }) : console.log(response.message)
        }).catch(this.loadFailed)
    }

    completePaymentSuccess(response) {
        this.props.reference.stopLoading()
        if (response.success) {
            localStorage.setItem('paymentResponse', JSON.stringify(response))
            var ticketNo = localStorage.getItem("dTicketNo") ? localStorage.getItem("dTicketNo") : JSON.parse(localStorage.getItem('ticketPayload')).ticketNo
            this.props.reference.startLoading()
            fetch(CONSTANTS.getDisbursementTicketDataEndpoint + '/' + ticketNo + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then((response) => {
                this.props.reference.stopLoading()
                response.success ? this.setState({ ticketPayload: response.payload }) : this.loadFailed()
            }).catch(this.loadFailed);
        } else {
            toast.error(response.message)
        }
    }

    goBack() {
        if (this.state.ticketPayload.requestStatusId === 10) {
            history.push("/constructionLoanDisbursementTickets")
        } else {
            localStorage.setItem('startAtStep', 2)
            this.props.jumpToStep(2)
        }

    }

    changeListener(e, isValid) {
        var name = e.target.name
        var value = e.target.value
        if (isValid) {
            this.removeError(name)
            this.setState({ [name]: value })
        } else {
            this.setState({ [name]: value })
            this.addError(name)
        }
    }

    acceptClick(e) {
        if (e.target.id === "accept") {
            this.setState({ error: false, errorMessage: "", acceptClick: !this.state.acceptClick, temsAccept: true })
        }
        else {
            this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false, error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
        }
    }

    submitRequest() {
        this.reset()
        if (!this.state.temsAccept && this.state.ticketPayload.requestStatusId !== 10) {
            this.setState({ error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
        } else {
            if (this.state.errors.length > 0) {
                this.showError()
            } else {
                this.requestPayment()
            }
        }
    }

    componentWillUnmount() {
        // localStorage.removeItem('emailaddress')
        // localStorage.removeItem('mobileno')
    }

    requestPayment() {
        setTimeout(this.props.reference.startLoading(), 300)
        localStorage.setItem('emailaddress', this.state.email)
        localStorage.setItem('mobileno', this.state.mobileNo)
        var token = localStorage.getItem('accessToken')
        var data = new URLSearchParams()
        data.append('ticketno', this.state.ticketPayload.ticketNo)
        data.append('emailaddress', this.state.email)
        data.append('mobileno', this.state.mobileNo)
        data.append('amount', this.state.ticketPayload.loanInsurance)
        if (token) {
            fetch(CONSTANTS.requestLoanDisbursementPaymentURL + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                headers: {
                    'Authorization': 'bearer ' + token
                },
                body: data
            }).then(response => response.json()).then(this.paymentRequestResponse).catch(this.loadFailed)
        }
    }

    paymentRequestResponse(response) {
        this.props.reference.stopLoading()
        if (response.success) {
            window.open(response.payload.redirectionUrl, "_self")
            toast.success(response.message)
        } else {
            toast.error(response.message)
        }
    }

    completeRequest() {
        this.reset()
        if (!this.state.temsAccept) {
            window.scrollTo({ top: 100, left: 0, behavior: 'smooth' })
            this.setState({ error: true, errorMessage: <div className="alert alertWarning">{translate('acceptterms')}</div> })
        } else {
            setTimeout(this.props.reference.startLoading(), 300)
            var token = localStorage.getItem('accessToken')
            if (token) {
                fetch(CONSTANTS.finishLoanDisbursementEndpoint + "?local=" + this.context.state.siteLang + "&ticketNo=" + this.state.ticketPayload.ticketNo, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'bearer ' + token
                    }
                }).then(response => response.json()).then(this.completeSuccess).catch(this.loadFailed)
            }
        }
    }

    completeSuccess(response) {
        this.props.reference.stopLoading()
        if (response.success) {
            toast.success(response.message)
            history.push("/constructionLoanDisbursementTickets")
        } else {
            toast.error(response.message)
        }
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    showError() {
        this.setState({ error: true, errorMessage: <div className="alert alertWarning">{translate('requiredErrorMessage')}</div> })
        window.scrollTo({ top: 100, left: 0, behavior: 'smooth' })
    }

    reset() {
        this.setState({ error: null })
    }

    loadFailed(response) {
        this.props.reference.stopLoading()
        this.setState({ showLoading: false }, () => { this.showError(response.message) })
    }

    render() {
        return (
            <div className="formSection constructionDisbursementSigninWrapper">
                {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                {this.state.acceptClick ? <div className="acceptPop">
                    <button className="popupClose" onClick={this.acceptClick}>X</button>
                    <h2>{translate('TermsOfUse')}</h2>
                    {translate('eligibleterms')}
                    <br></br>
                    <hr></hr>
                    <div className="col-md-12 acceptPopBtn">
                        <div className="row">
                            <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
                        </div>
                    </div>
                </div> : null}
                <div className="col-md-12">
                    <div className='constructionDisbursementStepInfoWrapper'>
                        <div className="row">
                            <div className="col-md-6">
                                <span>{translate('loanAmount')} :</span>
                                <strong> {this.state.ticketPayload.allowedLoanValue} </strong>
                            </div>
                            <div className="col-md-6">
                                <span>{translate('installmentAmount')} :</span>
                                <strong> {this.state.ticketPayload.loanInstallmentMonthly} </strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <span>{translate('numberInstallments')} :</span>
                                <strong> {this.state.ticketPayload.paymentDurationMonths} </strong>
                            </div>
                            <div className="col-md-6">
                                <span>{translate('repaymentInstallmentdurationYears')} :</span>
                                <strong> {this.state.ticketPayload.paymentDurationYears} </strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <span>{translate('repaymentInstallmentDurationMonths')} :</span>
                                <strong> {this.state.ticketPayload.paymentDurationMonths} </strong>
                            </div>
                            <div className="col-md-6">
                                <span>{translate('advancedInsuranceAmount')} :</span>
                                <strong> {this.state.ticketPayload.loanInsurance} </strong>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className='insurancePaymentOuterFormWrapperH3'>{translate('InsurancePayment')}</h3>
                <div className="formSection insurancePaymentOuter">
                    <div className='insurancePaymentOuterFormWrapper'>
                        <div className="row insurancePaymentForminput">
                            <InputComponent inputProps={{ disabled: this.state.ticketPayload.requestStatusId === 10, lable: translate('mobileNumber'), name: 'mobileNo', isRequired: true, value: this.state.mobileNo, type: 'phone', changeListener: this.changeListener }} />
                            <InputComponent inputProps={{ disabled: this.state.ticketPayload.requestStatusId === 10, lable: translate('email'), name: 'email', isRequired: true, value: this.state.email, type: 'email', changeListener: this.changeListener }} />
                        </div>
                        <div className='row insurancePaymentForminputBtn'>
                            <div className='col-sm-8'><span>{translate('insuranceAmount')}:</span> <strong> {this.state.ticketPayload.loanInsurance} {translate("dirham")} </strong></div>
                            {/* {this.state.ticketPayload.requestStatusId !== 0 || this.state.paymentResponse !== undefined ? null : <div className='col-sm-4'><button className='primary_button' onClick={this.submitRequest}>{translate('PayNow')}</button></div>} */}
                            {this.state.ticketPayload.requestStatusId === 0 ? <div className='col-sm-4'><button className='primary_button' onClick={this.submitRequest}>{translate('PayNow')}</button></div> : null}
                        </div>
                        {this.state.paymentResponse ? <div className="row">
                            <div className="col-md-12">
                                <div className="successfullyWrapper">
                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/successfully.svg'} alt="Successfully" />
                                    <h2>{translate('YourPaymentSuccessfully')}</h2>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                    {this.state.paymentResponse ? <div className='row'>
                        <div className='col-md-12'>
                            <div className='constructionDisbursementStepSuccessInfoWrapper'>
                                <div className='row no-gutters'>
                                    <div className='col-md-6'>
                                        <span>{translate('transactionNumber')}</span>
                                        <strong>{this.state.paymentResponse.transactionNumber}</strong>
                                    </div>
                                    <div className='col-md-6'>
                                        <span>{translate('totalPaidAmount')}</span>
                                        <strong>{this.state.paymentResponse.amountPaid}</strong>
                                    </div>
                                    <div className='col-md-6'>
                                        <span>{translate('applicantName')}</span>
                                        <strong>{this.state.paymentResponse.applicantName}</strong>
                                    </div>
                                    <div className='col-md-6'>
                                        <span>{translate('serviceType')}</span>
                                        <strong>{this.state.paymentResponse.serviceName}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    <div className="row">
                        <hr></hr>
                        <br></br>
                        <div className="col-md-12">
                            <div className="row">
                                {this.state.ticketPayload.requestStatusId !== 10 ? <div className="col-md-8 customCheckBox">
                                    <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
                                    {this.context.state.siteLang === 'en' ?
                                        <label htmlFor="acceptCB">{translate('IAgreeToTheWebsite')} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/terms%20of%20use/", "_blank")} href="">{translate('TermsOfUse')}</a> and <a onClick={() => window.open("https://www.mbrhe.gov.ae/en/privacy%20policy/", "_blank")} href="">{translate('privacypolicy')}</a>.</span></label> :
                                        <label htmlFor="acceptCB">{"أوافق على"} <span> <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/terms%20of%20use/", "_blank")} href="">{"شروط الاستخدام"}</a> كما وأوافق على <a onClick={() => window.open("https://www.mbrhe.gov.ae/ar/privacy%20policy/", "_blank")} href="">{"سياسة الخصوصية"}</a> للموقع الإلكتروني الخاص بمؤسسة محمد بن راشد للإسكان.</span></label>}
                                </div> : null}
                                <div className="col-md-12 constructionLoanDisbursementStepBtn">
                                    <button className="stepBacktBtn" onClick={this.goBack}>{translate('back')}</button>
                                    {this.state.ticketPayload.requestStatusId === 12 ? <button className="stepFinishBtn" onClick={this.completeRequest}>{translate('complete')}</button> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    {this.state.error ? this.state.errorMessage : null}
                </div>
            </div>
        )
    }
}

export default AuthenticatedComponent(StepFour)