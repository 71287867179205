import React from 'react'
import history from '../../History'
import Loader from '../utils/LoaderBars'
import CommonUtils from '../../utils/CommonUtils'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'

class viewDoc extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            docUrl: '',
            viewdocs: false,
            showLoading: true,
            ticketPayload: '',
            document: props.location.state.doc,
            ticketNo: props.location.state.ticketNo
        }
        this.goBack = this.goBack.bind(this)
        this.signDoc = this.signDoc.bind(this)
        this.doneLoading = this.doneLoading.bind(this)
        this.getDocument = this.getDocument.bind(this)
        this.setDocument = this.setDocument.bind(this)
    }

    signDoc() {
        localStorage.setItem('pdfObject', JSON.stringify(this.state.document))
        history.push({ pathname: "/signDocument", state: { ticketNo: this.state.ticketNo, doc: this.state.document } });
    }

    viewdocsClick() {
        this.setState({
            viewdocs: !this.state.viewdocs
        })
    }

    componentDidMount() {
        localStorage.removeItem('pdfObject')
        this.getDocument()
        this.setState({ ticketPayload: localStorage.getItem('ticketPayload') ? JSON.parse(localStorage.getItem('ticketPayload')) : '' })
    }

    getDocument() {
        if (CONSTANTS.authToken) {
            fetch(CONSTANTS.viewDisbursementDocumentEndpoint + "/" + this.state.ticketNo + "?id=" + this.state.document.id + "&local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setDocument).catch();
        }
    }

    setDocument(response) {
        if (response.success) {
            this.setState({ docUrl: response.payload })
        }
    }

    doneLoading() {
        this.setState({ showLoading: false })
    }

    goBack() {
        history.push('/stepConstructionLoanDisbursement');
    }

    render() {
        // const canvasEl = useRef(null)
        const docURL = this.state.docUrl
        return (
            <div className="container mainSection">
                {/* {this.state.showLoading ? <Loader/> : null} */}
                {this.state.showLoading ? <Loader /> : null}
                <div className="row">
                    {/* breadcrumb */}
                    <div className="col-md-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                <li className="breadcrumb-item active" aria-current="page"><a href="/constructionLoanDisbursementTickets">{localStorage.getItem('disbursementTypeID') === '16' ? translate('maintenanceDisbursementContractRequests') : translate('constructionDisbursementContractRequests')}</a></li>
                                <li className="breadcrumb-item active" aria-current="page"><a href="/stepConstructionLoanDisbursement">{translate('requestform')}</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{translate('viewdocument')}</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                {/* ******************** POPUP ******************** */}

                {/* view Document POPUP*/}
                {this.state.viewdocs ? <div className="PopupBk" ></div> : null}
                {this.state.viewdocs ? <div className="uploadPopup">
                    <div className="row formSection">
                        <div className="col-md-12 subTitle">
                            <h3>{this.state.document.name.replace('.pdf', "")}</h3>
                            <button className="close" onClick={this.viewdocsClick.bind(this)}>X</button>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12 signInPopupPdfWrapper pdfViewWrapperBox">
                                            <iframe id="o" width="100%" height="560px" onLoad={this.doneLoading} src={docURL} type="application/pdf"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <div className="row">
                    <div className="col-md-12">
                        <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/construction_disbursement.svg'} alt="construction Disbursement Contract Requests" /></i>
                            <span>{localStorage.getItem('disbursementTypeID') === '16' ? translate('maintenanceDisbursementContractRequests') : translate('constructionDisbursementContractRequests')}</span></div>
                    </div>
                    <div className="col-md-12">
                        <div className="service_contant_wrapper formSection">
                            <div className="row">
                                <div className=" col-md-12 subTitle">
                                    <h2>{translate('docDetail')}</h2>
                                </div>
                                <div className="col-lg-5">
                                    <div className="pdfVewWrapper">
                                        <button onClick={this.viewdocsClick.bind(this)} className="pdfZoomBtn"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/zoom.svg'} alt="zoom" /></button>
                                        <iframe id="o" width="100%" height="560px" onLoad={this.doneLoading} src={docURL} type="application/pdf"></iframe>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="row documentViewDetails">
                                        <div className="col-sm-4"><strong>{translate('docName')}</strong></div>
                                        <div className="col-sm-8"><span>{this.state.document.name}</span></div>
                                        <div className="col-sm-4"><strong>{translate('date')}</strong></div>
                                        <div className="col-sm-8"><span>{this.state.document.createdAt}</span></div>
                                        <div className="col-sm-4"><strong>{translate('DocumentStatus')}</strong></div>
                                        <div className="col-sm-8"><span>{this.state.document.isSignedMessage}</span></div>
                                        <div className="col-sm-4"><strong>{translate('docSize')}</strong></div>
                                        <div className="col-sm-8"><span>{CommonUtils.formatBytes(this.state.document.size)}</span></div>
                                        <div className="col-lg-12 viewFileBtns">
                                            <button className="viewBackBtn" onClick={this.goBack}><i><img src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/back_icon.svg"} alt="back" /></i><span>{translate('back')}</span></button>
                                            <button className="downloadBtn" onClick={this.goBack}><i><img src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/dowlload.svg"} alt="Download" /></i><span>{translate('download')}</span></button>
                                            <button className="viewSignBtn"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/tick_ico.svg'} alt="Sign" /></i> <span>{translate('signed')}</span></button>
                                            
                                            {this.state.ticketPayload.requestStatusId !== 10 ? <span>
                                                {this.state.document.isSigned > 0
                                                    ? <button className="viewSignBtn"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/tick_ico.svg'} alt="Sign" /></i> <span>{translate('signed')}</span></button>
                                                    : <button className="viewSignBtn" onClick={this.signDoc}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign.svg'} alt="Sign" /></i> <span>{translate('sign')}</span></button>
                                                }</span> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default viewDoc