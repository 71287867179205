import React from 'react'
import NoData from '../../utils/nodata'
import AwarenessStore from './awareness-store'
import AwarenessService from './awareness-service'
import AwarnessesItem from './awarenesses-widget-item'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'

class AwarnessesWidget extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super()
        this.state = {
            itemsArray: []
        }
        this.onChange = this.onChange.bind(this)
        this.setAwarnessesWidget = this.setAwarnessesWidget.bind(this)
        this.setItems = this.setItems.bind(this)
    }

    componentDidMount() {
        AwarenessStore.addChangeListener(this.onChange)
        AwarenessService.awarenessRequest("?local=" + this.context.state.siteLang)
    }

    componentWillUnmount() {
        AwarenessStore.removeChangeListener(this.onChange)
    }

    onChange() {
        this.setAwarnessesWidget(AwarenessStore.response)
    }

    loadFailed(response) {
        console.log(response)
    }

    setAwarnessesWidget(response) {
        if (response.success) {
            localStorage.setItem('awarnesses', JSON.stringify(response))
            this.setItems(response)
        }
    }

    setItems(response) {
        var awarnessesItems = []
        var items = response.payload
        if (items) {
            awarnessesItems.push(<AwarnessesItem key={0} id={items[0].id} type={items[0].type} description={items[0].description} image={items[0].image} />)
            awarnessesItems.push(<AwarnessesItem key={1} id={items[1].id} type={items[1].type} description={items[1].description} image={items[1].image} />)
            // awarnessesItems.push(<AwarnessesItem key={2} id={items[2].id} type={items[2].type} description={items[2].description} image={items[2].image} />)
            awarnessesItems.push(<AwarnessesItem key={3} id={items[3].id} type={items[3].type} description={items[3].description} image={items[3].image} />)
            awarnessesItems.push(<AwarnessesItem key={4} id={items[4].id} type={items[4].type} description={items[4].description} image={items[4].image} />)
            // awarnessesItems.push(<AwarnessesItem key={5} id={items[5].id} type={items[5].type} description={items[5].description} image={items[4].image} />)
        }
        this.setState({ itemsArray: awarnessesItems })
    }

    render() {
        return (
            <div className="col-md-12 awarenessWidgetWrapper">
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Awareness.svg'} alt="Awareness" /></span>
                        <span>{translate('awareness-widget-heading')}</span>
                    </div>
                    <div className="widgetContant">
                        {this.state.itemsArray.length > 0 ?
                            <div className="widgetContentIn">
                                <div className="row">
                                    {this.state.itemsArray}
                                </div>
                            </div>
                            : <NoData message={translate('nodata')} />}
                    </div>
                    {this.state.itemsArray.length > 0 ? <div className="widgetsMoreBtn">
                        <a href={this.state.itemsArray.length > 0 ? "/awarenesses" : null}>{translate('show-more')}<img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/arrow.svg'} alt="" /></a>
                    </div> : null}
                </div>
            </div>
        )
    }
}

export default AwarnessesWidget