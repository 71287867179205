import React from 'react'
import History from '../../History'
import ErrorComponent from '../utils/errorComponent'
import RequestBuilder from '../utils/requestBuilder'
import InputComponent from '../utils/inputComponent'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class LoanCalculatorService extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super()
        this.state = {
            formFields: {
                salary: '',
                // Others: '',
                // Guarantor: '',
                PersonalLoan: '',
                // EmployerLoan: '',
                // Rent: '',
                // CarLoan: '',
                // LoanAmount: '',
            },
            payload: {},
            showResults: false,
            error: null,
            errors: ["salary"]
        }
        this.calculateLoan = this.calculateLoan.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.newRequest = this.newRequest.bind(this)
        this.successCalback = this.successCalback.bind(this)
        this.failCallback = this.failCallback.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.showError = this.showError.bind(this)
        this.addError = this.addError.bind(this)
        this.removeError = this.removeError.bind(this)
    }

    handleChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        this.setState({ formFields: { ...this.state.formFields, [name]: value } })
        if (isValid) {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    calculateLoan(e) {
        e.preventDefault();
        this.reset()
        if (this.state.errors.length <= 0) {
            RequestBuilder.serverGETRequest(CONSTANTS.calculateLoanEndpoint + "?local=" + this.context.state.siteLang, this.state.formFields, this.successCalback, this.failCallback)
        } else {
            this.showError(translate('requiredErrorMessage'))
        }
    }

    successCalback(response) {
        if (response.success) {
            if (response.payload.hasDebitError) {
                this.showError(this.context.state.siteLang === 'en' ? response.payload.messageEn : response.payload.messageAr)
            }
            if (response.payload.showcalculations) {
                this.setState({ showResults: true, payload: response.payload })
            }
        } else {
            this.showError(response.message)
        }
    }

    failCallback(response) {
        this.showError(response.message)
    }

    closeCallback() {
        this.setState({ error: null })
    }

    newRequest() {
        this.setState({ showResults: false, formFields: {}, errors: ["salary"] })//formFields:{} only if you want to empty form
    }

    reset() {
        this.setState({ error: null })
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('loancalculator-heading')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/loan_calculator_icon.svg'} alt="loan calculator" /></i><span>{translate('loancalculator-heading')}</span></div>
                        </div>
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                {!this.state.showResults ?
                                    <form>
                                        <div className="row">
                                            <InputComponent inputProps={{ lable: translate('totalIncome'), name: 'salary', isRequired: true, defaultValue: this.state.salary, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                            <InputComponent inputProps={{ lable: translate('totalDeductions'), name: 'PersonalLoan', isRequired: false, defaultValue: this.state.PersonalLoan, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                        </div>
                                        {/* <div className="row">
                                            <InputComponent inputProps={{ lable: translate('gauranter'), name: 'Guarantor', isRequired: false, defaultValue: this.state.Guarantor, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                            <InputComponent inputProps={{ lable: translate('personalloan'), name: 'PersonalLoan', isRequired: false, defaultValue: this.state.PersonalLoan, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                        </div>
                                        <div className="row">
                                            <InputComponent inputProps={{ lable: translate('employerloan'), name: 'EmployerLoan', isRequired: false, defaultValue: this.state.EmployerLoan, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                            <InputComponent inputProps={{ lable: translate('rent'), name: 'Rent', isRequired: false, defaultValue: this.state.Rent, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                        </div>
                                        <div className="row">
                                            <InputComponent inputProps={{ lable: translate('carloan'), name: 'CarLoan', isRequired: false, defaultValue: this.state.CarLoan, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                            <InputComponent inputProps={{ lable: translate('loanAmount'), name: 'GuarantorLoan', isRequired: false, defaultValue: this.state.LoanAmount, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-12 formSectionBtn">
                                                <button type="reset" onClick={History.goBack}>{translate('cancle')}</button>
                                                <button disabled={false} onClick={this.calculateLoan}>{translate('calculate')}</button>
                                            </div>
                                        </div>
                                    </form> :
                                    < div className="calculationResult">
                                        <HappinessMeter serviceCode='' transactionID="" />
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <strong>{translate('totalSalary')} :</strong>
                                                <span>{this.state.payload.totalSalary}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <strong>{translate('totalIncome')} :</strong>
                                                <span>{this.state.payload.totalIncome}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <strong>{translate('totalDeductions')} :</strong>
                                                <span>{this.state.payload.totalDeductions}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <strong>{translate('netIncome')} :</strong>
                                                <span>{this.state.payload.netIncome}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <strong>{translate('debtPercentage')} :</strong>
                                                <span>{this.state.payload.debtPercentage}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <strong>{translate('repaymentPeriod')} :</strong>
                                                <span>{this.state.payload.repaymentPeriod}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <strong>{translate('repaymentInMonths')} :</strong>
                                                <span>{this.state.payload.repaymentInMonths}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <strong>{translate('loanAmount')} :</strong>
                                                <span>{this.state.payload.loanAmount}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <strong>{translate('monthlyInstallments')} :</strong>
                                                <span>{this.state.payload.monthlyInstallments}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <strong>{translate('lastInstallmentAmount')} :</strong>
                                                <span>{this.state.payload.lastInstallmentAmount}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <strong>{translate('debitPerctAfterInstallment')} :</strong>
                                                <span>{this.state.payload.debitPerctAfterInstallment}</span>
                                            </div>
                                            <div className="col-sm-6">
                                                <strong>{translate('twoYearsInsurance')} :</strong>
                                                <span>{this.state.payload.twoYearsInsurance}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <strong>{translate('monthlyPremium')} :</strong>
                                                <span>{this.state.payload.monthlyPremium}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* <h3>{translate('MonthlyInstalment')}</h3> */}
                                                <strong>{translate('Disclimer')}:</strong>
                                                <p>{translate('loanmessage')}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 formSectionBtn">
                                                <button onClick={this.newRequest}>{translate('make-new-request')}</button>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                            {this.state.error}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default AuthenticatedComponent(LoanCalculatorService)