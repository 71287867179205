import React from 'react'
import Loader from '../../utils/LoaderBars'
import history from '../../../History'
import HeaderService from "../../../ApplicationBase/Header/HeaderService"
import NotificationItem from './notifications-item'
import { Scrollbars } from 'react-custom-scrollbars'
import { AppContext } from '../../../Providers/context'
import CONSTANTS from '../../constants/dashboard-constants'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
class NotificationsComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            showLoading: false,
            apiURL: ''
        }
        this.setNotifications = this.setNotifications.bind(this)
        this.deleteNotificationSuccess = this.deleteNotificationSuccess.bind(this)
        this.review = this.review.bind(this)
        this.delete = this.delete.bind(this)
    }

    componentDidMount() {
        this.setState({ apiURL: this.props.url })
        this.fetchNotificationsData(this.props.url)
    }

    fetchNotificationsData(url) {
        if (CONSTANTS.authToken.length > 15) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setNotifications).catch(this.loadFailed);
        }
    }

    loadFailed(response) {
        this.setState({ showLoading: false })
        console.log(response)
    }

    review() {
        history.push({ pathname: '/housingRequests' })
        this.props.close()
    }

    delete(e) {
        this.setState({ showLoading: true })
        var notificationID = e.target.id
        var url = CONSTANTS.deleteNotificationsEndpoint + notificationID + "?local=" + this.context.state.siteLang
        if (CONSTANTS.authToken.length > 15) {
            fetch(url, {
                method: 'Delete',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(() => {
                this.deleteNotificationSuccess()
            }
            ).catch(this.loadFailed)
        }
    }

    deleteNotificationSuccess(response){
        this.setState({ showLoading: false })
        HeaderService.dashboardRequest("?local=" + this.context.state.siteLang)
        this.fetchNotificationsData(this.state.apiURL)
    }

    setNotifications(response) {
        var notificationsItems = []
        var items = response.payload
        items.map((notification, index) => {
            return (notificationsItems.push(<NotificationItem key={index} delete={this.delete} review={this.review} notification={notification} />))
        })
        this.setState({ itemsArray: notificationsItems })
    }

    render() {
        return (
            <Scrollbars className="scrollbars" style={{ width: 270 }} universal>
                {this.state.showLoading ? <Loader /> : null}
                {this.state.itemsArray.size !== 0 ? this.state.itemsArray : null}
            </Scrollbars>
        )
    }
}
export default AuthenticatedComponent(NotificationsComponent)