import React from 'react'
import CONSTANTS from '../../constants/dashboard-constants'
import '../../../reactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
import translate from '../../../Providers/i18n/Translate'
import { AppContext } from '../../../Providers/context'
import HISTORY from '../../../History';


class UserProfile extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super()
        this.state = {
            name: '',
            email: '',
            address: '',
            pobox: '',
            primaryMobileNo: '',
            message: ""
        }
        this.updateProfile = this.updateProfile.bind(this)
        this.setProfile = this.setProfile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.success = this.success.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
    }

    componentDidMount() {
        var user = this.props.location.getPayload ? this.props.location.getPayload() : null
        if (user == null) {
            user = localStorage.dashboard && JSON.parse(localStorage.getItem('dashboard')).payload
        }
        this.setProfile(user)
    }

    handleChange(e) {
        const { name, value } = e.target;
        // this.updateLocalStorage(name, value)
        this.setState({ [name]: value });
    }

    updateProfile(e) {
        e.preventDefault();
        var data = new URLSearchParams();
        data.append('mobileNo', this.state.primaryMobileNo);
        data.append('emailAddress', this.state.email);
        data.append('pobox', this.state.pobox);
        data.append('address', this.state.address);
        fetch(CONSTANTS.updateProfileEndpoint + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': CONSTANTS.authToken
            },
            body: data
        }).then(response => response.json()).then(this.success).catch(this.loadFailed);
    }

    loadFailed(response) {
        toast.error(response.message)
    }

    success(response) {
        toast.success(response.message)
    }

    setProfile(user) {
        if (user) {
            this.setState({
                userType: localStorage.getItem('userType'),
                name: user.name,
                email: user.email,
                address: user.address,
                pobox: user.pobox,
                primaryMobileNo: user.primaryMobileNo,
                photo: user.photo
            })
        }
    }

    // isLogedIn() {
    //     return localStorage.getItem('accessToken')
    // }

    // componentDidUpdate() {
    //     if (this.state.isLogout) {
    //         if (this.state.ISUAEPASS) {
    //             window.location.href = process.env.UAEPASS_LOGOUT
    //         } else {
    //             this.redirectToHome();
    //         }
    //     }
    // }

    // logout = () => {
    //     var local = localStorage.getItem('siteLang');
    //     var username = localStorage.getItem('USERNAME');
    //     var toggle = localStorage.getItem('TOGGLEME');
    //     this.setState({ ISUAEPASS: localStorage.getItem('isUAEPASS') })
    //     localStorage.clear();
    //     if (local !== null) {
    //         localStorage.setItem('siteLang', local);
    //     }
    //     if (username != null && toggle != null) {
    //         localStorage.setItem('USERNAME', username);
    //         localStorage.setItem('TOGGLEME', toggle);
    //     }
    //     cookie.remove('chatbotInfoName', { path: '/' });
    //     cookie.remove('chatbotInfoMobile', { path: '/' });
    //     cookie.remove('chatbotInfoEmail', { path: '/' });
    //     cookie.remove('chatbotInfoPhoto', { path: '/' });

    //     this.setState({ isLogout: true })
    // }

    // redirectToHome() {
    //     HISTORY.push('/eservices')
    // }

    myInbox() {
        HISTORY.push("/pdfList");
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={2000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    {/* breadcrumb */}
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        {this.state.userType === "1" ? <a href="/customerDashboard">{translate('dashboard')}</a> : <a href="/contractorDashboard">{translate('dashboard')}</a>}
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('profile')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* End breadcrumb */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/profile.svg'} alt="profile" /></i><span>{translate('profile')}</span>
                                <button className="myInboxBtn" onClick={this.myInbox}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/inbox.svg'} alt="inbox" /></i> <span>{translate('myInbox')}</span></button>
                            </div>
                        </div>
                    </div>
                    <form >
                        <div className="col-md-12">
                            <div className="row service_contant_wrapper formSection">
                                <div className="col-md-2">
                                    <div className="profileImage">
                                        <img src={this.state.photo ? `data:image/png;base64,${this.state.photo}` : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/user_default.svg"} height="31" width="31" alt='profile' />
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="name">{translate('name')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="name" name="name" defaultValue={this.state.name} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="email">{translate('email')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="email" name="email" defaultValue={this.state.email} onChange={this.handleChange} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="primaryMobileNo">{translate('mobileNo')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="primaryMobileNo" name="primaryMobileNo" defaultValue={this.state.primaryMobileNo} onChange={this.handleChange} disabled /></div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="address">{translate('address')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="address" name="address" defaultValue={this.state.address} onChange={this.handleChange} /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="pobox">{translate('POBox')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="pobox" name="pobox" defaultValue={this.state.pobox} onChange={this.handleChange} /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-8 profileSave"><button onClick={this.updateProfile}>{translate('save')}</button></div>
                                    </div>
                                    {/* {!this.isEservicesRoute() && this.isLogedIn() ? <li><button data-tip data-for='logout' className="logoutBtn" onClick={this.logout}><span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/logout.svg'} alt='logout' /></span></button></li> : null} */}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default AuthenticatedComponent(UserProfile)