import React from 'react'
import '../../../reactToastify.css'
import NoData from '../../utils/nodata'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../../constants/dashboard-constants'
import CostumerModuleUtilities from '../../utils/customerModuleUtilities'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
class CancelServiceTicketsListingComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            redirect: false,
            props: ''
        }
        this.modifyTicket = this.modifyTicket.bind(this)
        this.setHousingRequests = this.setHousingRequests.bind(this)
        this.getTickets = this.getTickets.bind(this)
        this.applicationListPageClick = this.applicationListPageClick.bind(this)
    }

    componentDidMount() {
        this.getTickets()
        this.setState({ props: this.props, applicationIndex: this.props.location.state ? this.props.location.state.applicationIndex : '', applicationStatus: this.props.location.state.applicationStatus })
    }

    getTickets() {
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.listApplicationsAllowedToCancelEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setHousingRequests).catch();
        }
    }

    setHousingRequests(response) {
        if (response.success) {
            var applicationTicketItems = []
            var items = response.payload[parseInt(this.state.applicationIndex)].cancelServiceTickets
            if (items.length > 0) {
                items.map((applicationTicket, index) => {
                    if (applicationTicket.status !== 30) {
                        let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
                        let cls = CostumerModuleUtilities.getStatusLableColor(applicationTicket.status)
                        return (applicationTicketItems.push(<CancelServiceTicketsListingItem modifyTicket={this.modifyTicket} key={index} payload={applicationTicket} image={img} className={cls} />))
                    }
                })
                this.setState({ application: response.payload[parseInt(this.state.applicationIndex)], redirect: false, itemsArray: applicationTicketItems })
            } else {
                this.setState({ redirect: true })
            }
        }
    }

    modifyTicket(e) {
        const ticketNo = e.currentTarget.name
        this.state.props.history.push({ pathname: '/cancelProgressServiceRequest', state: { ticketNo: ticketNo, applicationNo: this.state.application.applicationNo, applicationStatus: this.state.applicationStatus } })
    }

    applicationListPageClick() {
        this.props.history.push({ pathname: "/allowedToCancelApplicationsListing", state: { applicationStatus: this.state.applicationStatus } })// push({ pathname: "/allowedToCancelApplicationsListing", state: { applicationStatus: this.state.applicationStatus } })
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item" onClick={this.applicationListPageClick}><a href="">{translate('allowedToCancleTitle')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('allowedToCancleTicketsTitle')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" /></i>
                                <span>{translate('allowedToCancleTicketsTitle')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    {this.state.redirect ? <NoData message={translate('nodata')} /> : this.state.itemsArray}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function CancelServiceTicketsListingItem(props) {
    return (
        <div className="col-md-6 housingRequestWrapperAll">
            <div className="houseingRequestInn housingRequestWrapper">
                <div className="row no-gutters">
                    <div className="col-md-2">
                        <i><img src={props.image} alt="MBRHE" /></i>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-12">
                                <strong>{props.payload.service}</strong>
                                <span>{translate('ticket-number')}</span>
                                <span>{props.payload.ticket}</span>
                            </div>
                            <div className="col-12">
                                <span>{translate('status')}: </span>
                                <span className={props.class}>{props.payload.statusName}</span>
                            </div>
                            <div className="col-12">
                                <p className="housingRequestsince">{props.payload.createdAt} | {props.payload.since}</p>
                            </div>
                        </div>
                    </div>
                    {props.payload.enableEdit ? <div className="col-md-3 housingRequestBtns">
                        <button name={props.payload.ticket} onClick={props.modifyTicket}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg'} alt="Edit" /> {translate('modify')} </button>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}
export default AuthenticatedComponent(CancelServiceTicketsListingComponent)