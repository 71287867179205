import AppDispatcher from '../../../Dispatcher';
import ContractorPaymentConstant from './contractorPaymentConstant';


class ContractorPaymentAction {

  contractorPayments(response) {
    AppDispatcher.dispatch({
      actionType: ContractorPaymentConstant.CUSTOMER_PAYMENT_DASHBOARD,
      response: response
    })
  }
}
export default new ContractorPaymentAction();