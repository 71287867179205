import AppDispatcher from '../Dispatcher';
import InformationServiceConstant from './InformationServiceConstant';


class GetHousingApplicationStatusComponentAction {

    housingApplicationSatus(response) {
        AppDispatcher.dispatch({
            actionType: InformationServiceConstant.HOUSING_APPLICATION_STATUS,
            response: response
        })
    }

    maintenanceApplicationSatus(response) {
        AppDispatcher.dispatch({
            actionType: InformationServiceConstant.MAINTENANCE_STATUS,
            response: response
        })
    }

    paymentSatus(response) {
        AppDispatcher.dispatch({
            actionType: InformationServiceConstant.PAYMENT_STATUS,
            response: response
        })
    }
}

export default new GetHousingApplicationStatusComponentAction();
