import AppDispatcher from '../../../Dispatcher';
import PdfListConstant from './PdfListConstant';


class PdfListAction {

    cutsomerInboxList(response) {
        AppDispatcher.dispatch({
            actionType: PdfListConstant.CUSTOMER_INBOX_LIST,
            response: response
        })
    }

    projectCategoryList(response) {
        AppDispatcher.dispatch({
            actionType: PdfListConstant.CATEGORY_LIST,
            response: response
        })
    }

    uploadUnsignedAttachment(response) {
        AppDispatcher.dispatch({
            actionType: PdfListConstant.UPLAOD_UNSIGNED_ATTACHMENT,
            response: response
        })
    }

    deleteAttachment(response) {
        AppDispatcher.dispatch({
            actionType: PdfListConstant.DELETE_DOCUMENT,
            response: response
        })
    }
}
export default new PdfListAction();