import React from 'react'

function AwarenessItem(props) {
    return (
        <div className="col-md-6">
            <div className="awarnessesBox">
                <div className="awarnessesimages">
                    <img src={props.image.length > 0 ? props.image : 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/no_data.svg'} alt="awarnesses" />
                </div>
                <div className="awarnessesContant">
                    <strong>{props.type}</strong>
                    <p>{props.description} </p>
                </div>
            </div>
        </div>
    )
}
export default AwarenessItem