import DocumentVerificationConstant from './DocumentVerificationConstant';
import BaseStore from '../BaseStore';

class DocumentVerificationStore extends BaseStore {

  constructor() {
    super();
    this.subscribe(() => this._registerToActions.bind(this))
    this._response = '';
    this._success = false;
    this._message = '';
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case DocumentVerificationConstant.VERIFY_DOCUMENT:
        this._success = action.response.success;
        this._response = action.response.payload;
        this._message = action.response.message;
        this.emitChange();
        break;

      default:
        break;
    };
  }

  isSuccess() {
    return this._success;
  }

  get response() {
    return this._response;
  }

  get message() {
    return this._message;
  }
}

export default new DocumentVerificationStore();