import React from "react";
import translate from '../Providers/i18n/Translate';
import { LOCALES } from '../Providers/i18n';
import ChatbotStore from './ChatbotStore';
import ChatbotService from './ChatbotService';
import { format } from 'date-fns';
import SpeechRecognition from './speechSdk';
import { AppContext } from '../Providers/context';
import AuthService from '../ApplicationBase/Login/AuthService';
import LoginStore from "../ApplicationBase/Login/LoginStore";
import HeaderService from "../ApplicationBase/Header/HeaderService";
import HeaderStore from "../ApplicationBase/Header/HeaderStore";

class UserChat extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.state = props.state;
        this.response = [];
        this.handleChatBox = this.handleChatBox.bind(this);
        this._onChange = this._onChange.bind(this);
        this._onChangeUAEPass = this._onChangeUAEPass.bind(this);
        this.onChangeHeaderStore = this.onChangeHeaderStore.bind(this);
        this.uaepassPopClick = this.uaepassPopClick.bind(this);
        this.handleUserRequest = this.handleUserRequest.bind(this);
        this.handleNewUserRequest = this.handleNewUserRequest.bind(this);
        this.handleNewUserRequestKeyPress = this.handleNewUserRequestKeyPress.bind(this);
        this.modifyChatResponse = this.modifyChatResponse.bind(this);
        this.miniLoader = this.miniLoader.bind(this);
        this.handleTopBottom = this.handleTopBottom.bind(this);
        this.handleContactInformation = this.handleContactInformation.bind(this);
        this.handleChabotLoginTHC = this.handleChabotLoginTHC.bind(this);
        this.openChat = this.openChat.bind(this);
        this.locale = props.locale;
        this.nonLuisOptions = [];
        this.ChatbotButtons = [];

    }

    handleTopBottom() {
        this.setState(state => ({
            goTop: !state.goTop,
            goBottom: true,
        }));
        if (this.state.goTop) {
            const chat = document.getElementById("chatContainer");
            chat.scrollTop = 0;
        } else {
            this.scrollToBottom();
        }
    }

    handleChatBox(lang) {
        if (!localStorage.getItem('dashboard') && localStorage.getItem('accessToken')) {
            window.location.href = '/';
        }
        this.props.handleChatBox(lang);
    }

    handleUserRequest(text, btnType, value=null) {
        console.log('handleUserRequest');
        if (this.state.isSuccess) {
            let chatContainerUl = document.querySelector('#chatContainerUl');
            let loader = this.miniLoader();
            chatContainerUl.append(loader);
            this.scrollToBottom();
            if (text === 'restart' || text === 'مرحبا') {
                this.state.response = [];
                this.state.conversationId = '';
                this.state.faqId = '';
            } else {
               var txt = value ? value : text;
                this.state.response = this.modifyChatResponse(txt);
            }
            this.state.reqText = text;
            this.state.botType = btnType ? btnType : 'Luis';
            this.state.isSuccess = false;
            this.directline(this.state.locale);
        }
    }

    handleNewUserRequestKeyPress(event) {
        const textBox = document.getElementById("newReqText");
        if (event.key === 'Enter') {
            this.validateUserRequest(textBox.value);
        }
    }

    handleNewUserRequest() {
        const textBox = document.getElementById("newReqText");
        this.validateUserRequest(textBox.value);
    }

    validateUserRequest(text) {
        console.log('validateUserRequest');
        if (text.length >= 2) {
            var map_buttons = this.map_buttons(text);
            text = map_buttons !== '' ? map_buttons : text;
            if (!this.state.response[this.state.response.length - 1][0].quickSuggestions && !map_buttons && this.ChatbotButtons.length > 0) {
                this.modifyChatResponse(text);
                this.loadWarningSelectFromOptions(text, this.ChatbotButtons);
            } else {
                var BotType = '';
                if (map_buttons) {
                    BotType = this.state.botType;
                } else {
                    BotType = (this.nonLuisOptions.includes(text) || (!this.nonLuisOptions.length && this.state.botType === 'Web')) ? 'Web' : "Luis";
                }
                this.handleUserRequest(text, BotType);
            }
        }

    }

    map_buttons(text) {
        var found = false;
        var value = '';
        const lowerChatbotButtons = this.ChatbotButtons;
        for (var i = 0; i < lowerChatbotButtons.length && !found; i++) {
            var words = lowerChatbotButtons[i].split(" ");
            for (var j = 0; j < words.length; j++) {
                if (words[j].toLowerCase() === text.toLowerCase()) {
                    found = true;
                    value = lowerChatbotButtons[i];
                    break;
                }
            }
        }
        return value;
    }

    componentDidMount() {
        ChatbotStore.addChangeListener(this._onChange);
        LoginStore.addChangeListener(this._onChangeUAEPass);
        HeaderStore.addChangeListener(this.onChangeHeaderStore);
        this.state.locale = this.context.state.siteLang === LOCALES.ENGLISH ? LOCALES.CHATBOT_EN : LOCALES.CHATBOT_AR;
        this.state.response = [];
        this.loginWithUAEPass();
        this.getProfileInfo();
        const CuurentDate = new Date();
        //if (cookie.load('chatbotInfoName') && cookie.load('chatbotInfoName') != 'null' && cookie.load('chatbotInfoName') !== null) {
        if (localStorage.getItem("chatbotInfoName") && localStorage.getItem("chatbotInfoName") != 'null' && localStorage.getItem("chatbotInfoName") !== null && localStorage.getItem("chatbotInfoLoginTime") === CuurentDate.getDate().toString() ) {
        
            this.directline(this.state.locale);
        } else if (this.state.token === '') {
//            cookie.remove('chatbotInfoName', { path: '/' });
//            cookie.remove('chatbotInfoMobile', { path: '/' });
//            cookie.remove('chatbotInfoEmail', { path: '/' });
//            cookie.remove('chatbotInfoPhoto', { path: '/' });
//            cookie.remove('chatbotInfoType', { path: '/' });
              localStorage.removeItem("chatbotInfoLoginTime");
              localStorage.removeItem("chatbotInfoName");
              localStorage.removeItem("chatbotInfoMobile");
              localStorage.removeItem("chatbotInfoEmail");
              localStorage.removeItem("chatbotInfoPhoto");
              localStorage.removeItem("chatbotInfoType");
            this.loadUserContactInfo();
        }
    }

    loadWarningSelectFromOptions(text, ChatbotButtons) {
        var activity = {
            Success: "Normal",
            serviceType: "WarningSelectFromOptions",
            text: text,
            time: format(new Date(), 'HH:m:i'),
            botType: this.state.botType,
            ChatbotButtons: ChatbotButtons
        };
        this.setState(state => ({
            response: this.modifyChatResponse("", activity, false),
            isSuccess: true,
        }));
    }

    loadUserContactInfo() {
        var activity = {
            Success: "Normal",
            serviceType: "ContactInfo",
            ServiceType: "ContactInfo",
            text: "ContactInfo",
            time: format(new Date(), 'HH:m:i'),
        };
        this.setState(state => ({
            response: this.modifyChatResponse("", activity),
            isSuccess: true,
        }));
    }

    onChangeHeaderStore() {
        let response = HeaderStore.response;
        if (response && response.success && !localStorage.getItem("chatbotInfoName")) {
        //if (response && response.success && !cookie.load('chatbotInfoName')) {
            localStorage.setItem("dashboard", JSON.stringify(response));
            this.getProfileInfo();
            window.location.href = 'chatbotWeb?uaePass=1';
        }
    }

    uaepassPopClick() {
        this.setState((state) => ({
            is_error: !state.is_error
        }));
    }

    loginWithUAEPass() {
        if (this.state.token && !localStorage.getItem("chatbotInfoName")) {
        //if (this.state.token && !cookie.load('chatbotInfoName')) {
            AuthService.customerLoginUsingPassword(this.state.username, this.state.password, this.state.token, this.context.state.siteLang)
                .catch(function (error) {
                    this.catchErrorBody(error);
                    this.loadUserContactInfo();
                }.bind(this));
        }
    }

    catchErrorBody(error) {
        window.location.href = process.env.CHATBOT_LOGOUT_UAEPASS_URL + "?error=" + this.getError(error);
    }

    getError(errorObject) {
        var parseError = JSON.parse(errorObject.response);
        var errorDescription = parseError.error_description;
        var error = parseError.error + ",  " + errorDescription;
        return error
    }

    getProfileInfo() {
        if (localStorage.getItem('dashboard')) {
            let profile = JSON.parse(localStorage.getItem('dashboard')).payload;
            if (profile) {
                this.saveContactCookies(profile.name, profile.primaryMobileNo, profile.email, profile.photo, profile.userType);
            }
        }
    }

    componentWillUnmount() {
        ChatbotStore.removeChangeListener(this._onChange);
        LoginStore.removeChangeListener(this._onChangeUAEPass);
        HeaderStore.removeChangeListener(this.onChangeHeaderStore)
    }

    componentDidUpdate() {
        if (!this.state.goBottom) {
            this.scrollToBottom();
        }
        var newReqText = document.getElementById("newReqText");
        if (newReqText) {
            var loader = document.getElementById("miniLoader");
            if (loader) {
                loader.remove();
            }
            this.state.goBottom = false;
            newReqText.value = '';
        }
    }

    modifyChatResponse(text, activity, user) {
        if (!activity) {
            var activity = {
                Success: "Normal",
                text: text,
                time: format(new Date(), 'HH:m:i'),
                user: !user ? true : user
            };
        }
        var response = new Array();
        response[0] = activity;
        this.state.response.push(response);
        return this.state.response;
    }

    _onChange() {
        this.setState(this.setChatbotState());
    }

    _onChangeUAEPass() {
        if (LoginStore.isLoggedIn() || LoginStore.isSuccess()) {
            localStorage.setItem('accessToken', LoginStore.jwt);
            localStorage.setItem('isUAEPASS', true);
            let local = '?local=' + this.context.state.siteLang;
            HeaderService.dashboardRequest(local);
        }

    }

    setChatbotState() {
        return {
            response: ChatbotStore.response(this.state.response),
            conversationId: ChatbotStore.ConversationId(),
            faqId: ChatbotStore.FaqId(),
            //locale: ChatbotStore.Locale(),
            botType: ChatbotStore.BotType(),
            isSuccess: ChatbotStore.isSuccess()
        };
    }

    scrollToBottom() {
        const chat = document.getElementById("chatContainer");
        if (chat)
            chat.scrollTop = chat.scrollHeight;
    }

    directline(chabot_lang) {
        let eid = '';
        if (localStorage.getItem('dashboard')) {
            let profile = JSON.parse(localStorage.getItem('dashboard')).payload;
            if (profile) {
                eid = profile.emiratesId;
            }
        }
        var from = {
//            Id: cookie.load('chatbotInfoName') ? cookie.load('chatbotInfoName') : '',
//            Name: cookie.load('chatbotInfoName') ? cookie.load('chatbotInfoName') : '',
//            Mobile: cookie.load('chatbotInfoMobile') ? cookie.load('chatbotInfoMobile') : '',
//            Email: cookie.load('chatbotInfoEmail') ? cookie.load('chatbotInfoEmail') : '',
                Id: localStorage.getItem("chatbotInfoName") ? localStorage.getItem("chatbotInfoName") : '',
                Name: localStorage.getItem("chatbotInfoName") ? localStorage.getItem("chatbotInfoName") : '',
                Mobile: localStorage.getItem("chatbotInfoMobile") ? localStorage.getItem("chatbotInfoMobile") : '',
                Email: localStorage.getItem("chatbotInfoEmail") ? localStorage.getItem("chatbotInfoEmail") : '',
            Properties: {
                
                UserType : localStorage.getItem("chatbotInfoType") ? localStorage.getItem("chatbotInfoType") : '',
                //UserType: cookie.load('chatbotInfoType') ? cookie.load('chatbotInfoType') : '',
                EmiratesId: eid,
                EmiratesToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : ''
            }
        };
        ChatbotService.directline(this.state.conversationId, this.state.faqId, this.state.reqText, this.state.botType, this.state.value, chabot_lang, from)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    catchBody(error) {
        if(error.response){
            var parseError = JSON.parse(error.response);
            var errorDescription = parseError.error_description;
            console.log("Error logging in : ", errorDescription);
        }
    }

    miniLoader() {
        var li = document.createElement("li");
        li.className = "send";
        li.id = "miniLoader";
        var profilePicBox = document.createElement("div");
        profilePicBox.className = "profilePicBox";
        var profilePic = document.createElement("img");
        profilePic.className = "profilePic";
        profilePic.src = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/avatar.svg";
        profilePicBox.append(profilePic);
        li.append(profilePicBox);
        var chatBox = document.createElement("div");
        chatBox.className = "chatBox";
        var chatBoxContentWrapper = document.createElement("div");
        chatBoxContentWrapper.className = "chatBoxContentWrapper";
        var chatBoxContent = document.createElement("div");
        chatBoxContent.className = "chatBoxContent";
        var chatBoxDetails = document.createElement("div");
        chatBoxDetails.className = "chatBoxDetails";
        var chatbotName = document.createElement("span");
        chatbotName.innerHTML = document.getElementById("cahtbot_name").innerHTML;
        var time = document.createElement("span");
        time.innerHTML = format(new Date(), 'HH:m:i');
        chatBoxDetails.append(chatbotName);
        chatBoxDetails.append(time);
        var loader = document.createElement("div");
        loader.className = "loader";
        loader.id = "miniLoader";
        for (var i = 0; i <= 5; i++) {
            let bar = document.createElement('div');
            bar.className = "bar" + i;
            loader.append(bar);
        }
        chatBoxContent.append(chatBoxDetails);
        chatBoxContent.append(loader);
        chatBoxContentWrapper.append(chatBoxContent);
        chatBox.append(chatBoxContentWrapper);
        li.append(chatBox);
        return li;
    }

    chatBotReply(obj) {
        
        this.nonLuisOptions = [];
        this.ChatbotButtons = [];
        {
            
            return (
                obj.map((value, index) => {
                    ///console.log(value);
                    value.Buttons = value.buttons;
                    const cookieImg = localStorage.getItem("chatbotInfoPhoto");//cookie.load('chatbotInfoPhoto');
                    var className = value.user ? 'replies' : 'send';
                    var user_img = cookieImg ? "data:image/png;base64, " + cookieImg : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/user_chatbot.svg";
                    var img = value.user ? user_img : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/avatar.svg";
                    var textColor = value.success === 'Error' ? 'red' : '';
                    const cookieName = localStorage.getItem("chatbotInfoName");//cookie.load('chatbotInfoName');
                    return (
                        <li className={className} key={index}>
                            {value.text ?
                            <div className="profilePicBox">
                                <img className="profilePic" src={img} alt="" />
                            </div>
                            : null}
                             <div className="chatBox">
                                {value.ServiceType === 'ContactInfo' ? this.ContactInfo(value) : value.ServiceType === 'WarningSelectFromOptions' ? this.WarningSelectFromOptions(value)
                                    :
                                    <React.Fragment>
                                       {value.text ?
                                        <div className="chatBoxContentWrapper">
                                            <div className="chatBoxContent">
                                                <div className="chatBoxDetails">
                                                    <span id="cahtbot_name">{value.user ? cookieName ? cookieName : translate('cahtbot_customer_name') : translate('cahtbot_name')}</span>
                                                    <span>{value.time}</span>
                                                </div>
                                                {value.ServiceType !== 'ToWhomItMayConcern' ? <p style={{ color: textColor }} dangerouslySetInnerHTML={{ __html: this.replaceNewLine(value.text) }} /> : null}
                                                {this.loadTemplate(value)}
                                            </div>
                                        </div>
                                       : null}
                                        
                                        {value.Buttons && value.serviceType != 'Confirmation'  && value.serviceType != 'ToWhomItMayConcernButton' ?
                                            value.ServiceType == 'HomePage' ? this.handleButtons(value, 1) : this.handleButtons(value, 0)
                                            : null}
                                    </React.Fragment>
                                }
                            </div>
                        </li>
                    )
                })
            );
        }
    }

    loadTemplate(value) {
        switch (value.serviceType) {
            case "Confirmation":
                return this.confirmationBtnTemplate(value.Buttons);
            case "ToWhomItMayConcernButton":
                return this.confirmationBtnTemplate(value.Buttons);
            case "byApplicantId":
                return this.multiStatusTemplate(value.data);
            case "PaymentByApplicationNo":
                return this.paymentByApplicationNo(value.data);
            case "WarningSelectFromOptions":
                return this.WarningSelectFromOptions(value);
            case "HousingMaintenanceStatus":
                return this.HousingMaintenanceStatusTemplate(value.data);
            case "ToWhomItMayConcern":
                return this.ToWhomItMayConcernTemplate(value.text.split('\n'));
            default:
                return null;
        }

    }

    WarningSelectFromOptions(data) { 
        return (
            <React.Fragment>
                <div className="chatBoxContentWrapper">
                    <div className="chatBoxContent">
                        <div className="chatBoxDetails">
                            <span id="cahtbot_name">{translate('cahtbot_name')}</span>
                            <span>{data.time}</span>
                        </div>
                        <p> {translate('cahtbot_invlaid_selection')}</p>
                    </div>
                </div>
                <div className="sendChips arabicChip">
                    {data.ChatbotButtons.map((button, index) => {
                        return (
                            <React.Fragment key={index}>
                                {button === 'Back to main menu' || button === 'قائمة الخدمات الرئيسية' ?
                                    <button className="back_icon" onClick={() => this.handleUserRequest(button, data.botType)}>
                                        <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/back.svg" alt="Back" /></i>
                                        <span>{button}</span>
                                    </button>
                                    :
                                    <button onClick={() => this.handleUserRequest(button, data.botType)}>{button} </button>}
                            </React.Fragment>
                        );
                    })
                    }
                </div>
                <div className="chatBoxContentWrapper">
                    <div className="chatBoxContent">
                        <div className="chatBoxDetails"></div>
                        <div>{translate('cahtbot_continue_search')}</div>
                    </div>
                </div>
                <div className="sendChips arabicChip">
                    <button onClick={() => this.handleUserRequest(data.text, 'Luis')}> {data.text} </button>
                </div>
            </React.Fragment>
        );
    }
    
    handleChabotLoginTHC(token){
        localStorage.setItem("accessToken", token)
    }

    handleContactInformation(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        const name = data.get('ContactInfoName');
        const mobile = data.get('ContactInfoMobile');
        const email = data.get('ContactInfoEmail');
        if (name && mobile) {
            if (!this.mobileValidation(mobile)) {
                this.setState(state => ({
                    error: this.context.state.siteLang === LOCALES.ENGLISH ? "Enter a valid mobile number" : "الرجاء ادخال رقم الموبايل الصحيح",
                }));
                return false;
            }
            if (email) {
                var re = /\S+@\S+\.\S+/;
                if (!re.test(email)) {
                    this.setState(state => ({
                        error: this.context.state.siteLang === LOCALES.ENGLISH ? "Enter a valid email" : "الرجاء ادخال البريد الالكتروني الصحيح",
                    }));
                    return false;
                }
            }
            this.state.isSuccess = true;
            this.saveContactCookies(name, mobile, email);
            this.handleUserRequest('restart');
        } else {
            data.set('ContactInfoName', name);
            data.set('ContactInfoMobile', mobile);
            data.set('ContactInfoEmail', email);
            this.setState(state => ({
                error: this.context.state.siteLang === LOCALES.ENGLISH ? "Fill all required fields" : "الرجاء ادخال جميع الحقول المطلوبة",
            }));
        }
    }

    mobileValidation(mobile) {
        let valid = false;
        let allowedFirstPart = ['0', '00971', '+971', '0971', '971'];
        let operators = ['56', '54', '50', '52', '55', '58'];
        if (mobile.length >= 10 && mobile.length <= 14) {
            var secondPart = mobile.substr(mobile.length - 9);
            var firstPart = mobile.split(secondPart)[0];
            if (allowedFirstPart.includes(firstPart)) {
                let opt = secondPart.substr(0, 2);
                if (operators.includes(opt)) {
                    valid = true;
                }
            }
        }
        return valid;
    }

    saveContactCookies(name, mobile, email, photo = '', type = '1') {
        const expires = new Date(); 
        //expires.setDate(Date.now() + 1000 * 60 * 60);
        localStorage.setItem("chatbotInfoLoginTime", expires.getDate());
        localStorage.setItem("chatbotInfoName", name === '' ? "." : name);
        localStorage.setItem("chatbotInfoMobile", mobile);
        localStorage.setItem("chatbotInfoEmail", email);
        localStorage.setItem("chatbotInfoPhoto", photo);
        localStorage.setItem("chatbotInfoType", type);
        //cookie.save('chatbotInfoName', name === '' ? "." : name, { path: '/', expires });
        //cookie.save('chatbotInfoMobile', mobile, { path: '/', expires });
        //cookie.save('chatbotInfoEmail', email, { path: '/', expires });
        //cookie.save('chatbotInfoPhoto', photo, { path: '/', expires });
        //cookie.save('chatbotInfoType', type, { path: '/', expires });
    }

    ContactInfo(value) {
        this.state.isSuccess = false;
        return (
            <React.Fragment>
                <div className="chatBoxContentWrapper">
                    <div className="chatBoxContent">
                        <div className="chatBoxDetails">
                            <span id="cahtbot_name">{value.user ? translate('cahtbot_customer_name') : translate('cahtbot_name')}</span>
                            <span>{value.time}</span>
                        </div>
                        <p>{translate('ContactInfoText1')}</p>
                        <p>{translate('ContactInfoText2')}</p>
                    </div>
                </div>
                <div className="chatBoxContentWrapper">
                    <div className="chatBoxContent">
                        <form onSubmit={this.handleContactInformation}>
                            <div className="userForm">
                                <h2>{translate('contactInformation')}</h2>
                                {this.state.error ? <div style={{ color: 'red' }}>{this.state.error}<br /><br /></div> : null}
                                <label htmlFor="ContactInfoName">{translate('name')}<span className="redStar">*</span></label>
                                <input name='ContactInfoName' type="text" id="ContactInfoName" placeholder={this.locale == 'en' ? "Muftah" : "مفتاح"} />
                                <label htmlFor="ContactInfoMobile">{translate('mobileNumber')}<span className="redStar">*</span></label>
                                <input name='ContactInfoMobile' type="number" id="ContactInfoMobile" placeholder="050xxxxxxx, 0097150xxxxxxx" />
                                <label htmlFor="ContactInfoEmail">{translate('ContactInfoEmail')}</label>
                                <input name='ContactInfoEmail' type="email" id="ContactInfoEmail" placeholder="example@domain.com" />
                            </div>
                            <div className="userFormBtns">
                                <button >{translate('submit')}</button>
                                <button onClick={() => this.handleChatBox('')}>{translate('cancle')}</button>
                            </div>
                           { window.location.pathname !== '/muftah' ? <div className="chatloginOr"><span>{translate('or')}</span></div>:null}

                            <div className="formSection signincus loginWhiteBg uaePassBtnWrap">
                                {/* <a onClick={this.clickUAEPass} href={DOMPurify(this.getUaepassUrl(process.env.UAEPASS_URL) + this.context.state.siteLang)} className="uaePassBtn"><span>{translate('loginWith')}</span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/uaePass.png'} alt="uaePass" /><span>{translate('uaePass')}</span></a> */}
                                 { 
                                 window.location.pathname !== '/muftah' ?
                                 <a href={process.env.CHATBOT_UAEPASS_URL + this.context.state.siteLang} className="uaePassBtn"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/uaepass.svg'} alt="uaePass" /><span>{translate('loginWith')}</span></a>
                                 :null}
                                {/* <span>{translate('uaePass')}</span> */}
                                {/* <a onClick={this.clickUAEPass} href={""} className="uaePassBtn"><span>{translate('loginWith')}</span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/uaePass.png'} /><span>{translate('uaePass')}</span></a> */}
                            </div>

                            {/* <div className="formSection signincus loginWhiteBg uaePassBtnWrap">
                                <a href={process.env.CHATBOT_UAEPASS_URL + this.context.state.siteLang} className="uaePassBtn">
                                    <span>{translate('loginWith')}</span>
                                    <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/uaePass.png" alt="" />
                                    <span>{translate('uaePass')}</span>
                                </a>
                            </div> */}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    openChat() {
        var userString = localStorage.getItem('dashboard') ? localStorage.getItem('dashboard') : null
        if (userString != null) {
            var userPayload = JSON.parse(userString).payload
            window.$zopim.livechat.setName(userPayload.name)
            window.$zopim.livechat.setEmail(userPayload.email)
            window.$zopim.livechat.setPhone(userPayload.primaryMobileNo)
            window.$zopim.livechat.setLanguage(localStorage.getItem('siteLang'))
        }
        window.$zopim.livechat.window.toggle()
        this.handleChatBox('')
    }

    AgentTemplate(data) {
        return (
            <div className="callAgent">
                {/* <a href={data} target="_blank"> */}
                <a onClick={this.openChat} href="#!">
                    <div className="imgBox"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/call2.svg" alt="" /></div>
                    <div className="text">{translate('agent')}</div>
                </a>
            </div>
        );
    }

    multiStatusTemplate(data) 
    {
        return (
            <>
                { data.length > 0 ?
            <div className="servicesTable">
                <table>
                    <tbody>
                        <tr>
                            <th className="sticky">{translate('applucationStatus_FileNo')}</th>
                            <th>{translate('applucationStatus_ServiceType')}</th>
                            <th>{translate('applucationStatus_Status')}</th>
                        </tr>

                        {data.map((application, index) => {
                            return (
                                <tr key={index}>
                                    <td className="sticky">{application.fileNo}</td>
                                    <td>{application.serviceName}</td>
                                    <td>{application.status}</td>
                                </tr>
                            );
                        })
                        }

                    </tbody>
                </table>
            </div>
             :null}
           </>
            
        );
    }

    paymentByApplicationNo(data) {
        return (
            <div className="servicesTable">
                <table>
                    <tbody>
                        <tr>
                            <th className="sticky">{translate('payment_id')}</th>
                            <th>{translate('payment_current_percent_completed')}</th>
                            <th>{translate('payment_total_bill_amount')}</th>
                            <th>{translate('payment_status')}</th>
                        </tr>
                        {data.map((application, index) => {
                            return (
                                this.getApplcationPayments(application.payments, index)
                            );
                        })
                        }


                    </tbody>
                </table>
            </div>
        );
    }

    getApplcationPayments(payments, index) {
        return (
            <React.Fragment key={index}>
                {payments.map((payment, index2) => {
                    return (
                        <tr key={index2}>
                            <td className="sticky">{payment.paymentId}</td>
                            <td>{payment.percentageCompleted}</td>
                            <td>{payment.totalBillPaymentDisplay}</td>
                            <td>{payment.status}</td>
                        </tr>
                    );
                })
                }
            </React.Fragment>
        );
    }

       HousingMaintenanceStatusTemplate(data) {
       
        
        return (

                <>
                { data.length > 0 ? 
                <div className="servicesTable">
                    <table>
                        <tbody>
                            <tr>
                                <th className="sticky">{translate('HousingStatus_ReqNo')}</th>
                                <th >{translate('HousingStatus_ReqDate')}</th>
                                <th >{translate('HousingStatus_AppointmentDate')}</th>
                                <th>{translate('applucationStatus_Status')}</th>
                                <th>{translate('problemDescription')}</th>
                            </tr>

                            { data.map((application, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="sticky">{application.requestId}</td>
                                        <td>{application.requestDate}</td>
                                        <td>{application.appointmentDate}</td>
                                        <td>{application.status}</td>
                                        <td>{application.problemDescription}</td>
                                    </tr>
                                );
                            })
                            }
                        </tbody>
                    </table>
                </div>
                :null}
                </>
        );
    }

    ToWhomItMayConcernTemplate(data) {
        var link = '';
        var text = '';
        var download = '';
        if (data.length) {
            text = data[0];
            link = data[data.length - 1];
            download = 'fileUrl=' + link;
        }
        return (
            <React.Fragment>
                <div className="pdfWrapper">
                    <iframe id="fred" src={link} frameBorder="0" scrolling="auto" allowFullScreen=""></iframe>
                </div>
                <div className="pdfWrapperFooter">
                    <a href={link} target="_blank">
                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/fullScreen_icon.svg" alt="" />
                        <span>{translate('PDF_ViewFull')}</span>
                    </a>
                    <a href={link} target="_blank" >
                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/downloadPDF_icon.svg" alt="" />
                        <span>{translate('PDF_Download')}</span>
                    </a>
                </div>
            </React.Fragment>
        );
    }

     replaceNewLine(myString) {
        if(myString){
            var regX = /\r?\n/g;
            var replaceString = '<br />';
            myString = myString.replace(regX, replaceString);
            if (myString.indexOf("](") != -1) {
                myString = this.FaqPdfUrl(myString);
            }else{
                if(new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(myString)) {
                   var split =  myString.split("http");
                   if(split[0] && split[1]){
                        var url = "http"+split[1];
                        var txt =split[0].length > 0 ? split[0] :split[0] + '<br> '; 
                        myString = txt + '<a className="FaqUrl" target="_blank" href="' + url+ '">' + url + '</a> '; 
                    }
                }
            }
            
        }
        return myString;
    }

    FaqPdfUrl(myString) {
        var linksWithText = myString.split('[');
        myString = '';
        for (var i = 0; i < linksWithText.length; i++) {
            var item = linksWithText[i];
            if (item.indexOf("]") != -1) {
                var splitText = item.split("](");
                var splitUrl = splitText[1].split(")");
                myString += ' <a className="FaqUrl" target="_blank" href="' + splitUrl[0] + '">' + splitText[0] + '</a> ';
                if (splitUrl.length > 1) {
                    for (var j = 1; j < splitUrl.length; j++) {
                        myString += splitUrl[j].replace("(", "")
                    }
                } else {
                    myString += splitUrl.length > 1 ? splitUrl[1] : '';
                }
            } else {
                myString += item;
            }
        }
        return myString;
    }

    confirmationBtnTemplate(buttons) {
        return (
            <div className="btns">
                {buttons.map((button, index) => {
                    return (
                        <button key={index} onClick={() => this.handleUserRequest(button.value, button.buttonType)}><img src={index === 0 ? "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Yes.svg" : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/No.svg"} alt="" /> {button.title}</button>
                    );
                })
                }
            </div>
        );
    }

    handleButtons(value, isHome) {
        return (
            <React.Fragment>
                {isHome ?
                    <React.Fragment>
                        {this.loadButtons(value, value.quickSuggestions, 0, 3, 1)}
                        {this.loadButtons(value, value.restartConversation, 3, 100)}

                    </React.Fragment>
                    : this.loadButtons(value, null, 0, 100)
                }
            </React.Fragment>
        );
    }

    loadButtons(value, text, start, end, isLuis) {
        return (
            <React.Fragment>
                <div className="sendChips arabicChip">
                    {value.buttons.map((button, index) => {
                        if (index >= start && index < end) {
                            return (
                                <React.Fragment key={index}>
                                    {this.CreateButton(button)}
                                </React.Fragment>
                            );
                        }
                    })
                    }
                </div>
                {value.ServiceType === 'Agent' && !isLuis ? this.AgentTemplate(value.data) : null}
                {text ?
                    <div className="chatBoxContentWrapper">
                        <div className="chatBoxContent">
                            <div className="chatBoxDetails"></div>
                            <div dangerouslySetInnerHTML={{ __html: this.replaceNewLine(text) }} />
                        </div>
                    </div>
                    : null}
            </React.Fragment>
        );
    }

    CreateButton(button) {
        var title = button.title;
        this.ChatbotButtons.push(button.value);
        if (button.buttonType === 'Web') {
            this.nonLuisOptions.push(title);
        }
        return (
            <React.Fragment>
                {title === 'Back to main menu' || title === 'قائمة الخدمات الرئيسية' ?
                    <button className="back_icon" onClick={() => this.handleUserRequest(button.value, button.buttonType)}>
                        <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/back.svg" alt="Back" /></i>
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                    </button>
                    : <button onClick={() => this.handleUserRequest(button.value, button.buttonType, title)} dangerouslySetInnerHTML={{ __html: title }} />}
            </React.Fragment>
        );
    }

    render() {
        var { response, date, isSuccess, goTop, is_error, apiError, errorDescription } = this.state;
        
        //if (cookie.load('chatbotInfoName') && response.length > 0 && response[0][0].ServiceType == 'ContactInfo') {
        if (localStorage.getItem("chatbotInfoName") && response.length > 0 && response[0][0].ServiceType == 'ContactInfo') {
            response = [];
        }
        var refresh = "مرحبا";
        var back = "عودة";
        var placeholder = 'كتابة الرد...';
        var arClass = "chatContainer chatAr";
        if (this.locale == 'en') {
            refresh = "restart";
            back = "back";
            arClass = "chatContainer";
            placeholder = 'Send a message...';
        }
        return (
            <div className={arClass}>
                {is_error && !localStorage.getItem('isUAEPASS') ?
                    <React.Fragment>
                        <div className="PopupBk"></div>
                        <div className="uaepassErrorPopup">
                            <p>{translate(apiError)}</p>
                            <div className="button">
                                <button onClick={() => this.uaepassPopClick()}>{translate('ok')}</button>
                            </div>
                        </div>
                    </React.Fragment> : null}
                <div className="chatframe">
                    <div className="chatHead">
                        <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/avatar.svg" alt="chatbot" />
                        <span>{translate('chatbot_heading')}</span>
                        <span className="chat_close_icon"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/close.svg" alt="close" onClick={() => this.handleChatBox('')} /></span>
                    </div>
                    <div className="content">
                        <div className="headertop">
                            {/* <span className="date"><strong>Today</strong> {date}</span>  */}
                            <div className="chatHeadContact">
                                <div className="headerContactWrapper">
                                    <strong>{translate("email")}</strong>
                                    <span>: <a href="mailto:info@mbrhe.ae">info@mbrhe.ae</a></span>
                                    |<strong>{translate("tele")}</strong>
                                    <span>: <a href="Tel:80030" className="ext_link">80030</a></span>
                                </div>
                                <div className="headerContactWrapper">
                                    <span>{translate("monFri")}</span>
                                    |<strong>{translate("OfficialWorkingHours")}</strong>
                                    <span>: {translate("MonThu")}</span>
                                    |<span>{translate("Friday")}</span>
                                </div>
                                {/* <strong>{translate("email")}</strong>
                                            <span>: <a href="mailto:info@mbrhe.ae">info@mbrhe.ae</a></span>
                                            <strong>{translate("tele")}</strong>
                                            <span>: <a href="Tel:80030" className="ext_link">80030</a></span>
                                            <strong>{translate("OfficialWorkingHours")}</strong>
                                            <span>: {translate("SundayToThursday")}</span>
                                            <strong>{translate("CustomerService")}</strong>
                                            <span>: {translate("CustomerServiceTime")}</span> */}
                            </div>

                            <div className="headerTopBtns">
                                <button onClick={() => this.handleUserRequest(refresh)}><span>{translate('cahtbot_refresh')}</span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Startover_icon.svg" alt="Start over" /></button>
                                {/* <button onClick={() => this.handleTopBottom()} className="top_icon"><span> {goTop ? translate('cahtbot_top') : translate('cahtbot_bottom')} </span><img src={goTop ? "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/top_chatbot.svg" : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bottom.svg" } alt="" /></button> */}
                                <button onClick={() => this.handleTopBottom()} className="top_icon"><img src={goTop ? "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/top_chatbot.svg" : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bottom.svg"} alt="top" /></button>
                            </div>
                        </div>
                        {response.length > 0 ?
                            <span>
                                <div id="chatContainer" className="messages">
                                    <ul id="chatContainerUl">
                                        {
                                            response ?
                                                response.map((value, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {this.chatBotReply(value)}
                                                        </React.Fragment>
                                                    )
                                                })
                                                : null}
                                    </ul>
                                </div>
                                <div className="message-input">
                               
                                    <div className="wrap">
                                        <label htmlFor="newReqText" className="hide"></label>
                                        <input id="newReqText" type="text" placeholder={placeholder} onKeyPress={this.handleNewUserRequestKeyPress} />
                                        <button id="newReqBtn" onClick={() => this.handleNewUserRequest()} className="submit">
                                            <i aria-hidden="true"><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/send_icon.svg" alt="send" /></i>
                                        </button>
                                        <SpeechRecognition validateUserRequest={this.validateUserRequest.bind(this)} />
                                    </div>
                                </div>
                            </span>
                            :
                            <div className="loader">
                                <div className="bar1"></div>
                                <div className="bar2"></div>
                                <div className="bar3"></div>
                                <div className="bar4"></div>
                                <div className="bar5"></div>
                                <div className="bar6"></div>
                            </div>
                        }
                    </div>
                </div>
                <div className="chatBg"></div>
                <div id="miniLoader"></div>
            </div>
        );
    }
}

export default UserChat;