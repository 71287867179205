import React from 'react'
import '../../../reactToastify.css'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'
import translate from '../../../Providers/i18n/Translate'
import { AppContext } from '../../../Providers/context'
import { LOCALES } from '../../../Providers/i18n'
import CommonUtils from '../../../utils/CommonUtils'
import { ToastContainer, toast } from 'react-toastify';
import PdfViewStore from './PdfViewStore';
import PdfViewService from './PdfViewService';
import PdfListStore from '../PdfList/PdfListStore';
import PdfListService from '../PdfList/PdfListService';
import Moment from 'moment';
import HISTORY from '../../../History';

class PdfViewComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.linkRef = React.createRef();
        this.state = {
            editDoc: false,
            deleteDoc: false,
            cancelSign: false,
            failedSign: false,
            selectValue: '',
            documentName: '',
            documentDescription: '',
            signSuccess: false,
            signIndocs: false,
            viewdocs: false,
            name: '',
            isSetLoading: true,
            companyName: '',
            viewAttachmentResponse: '',
            processStatusCanceled: '',
            processStatusFailed: '',
            processStatusFinished: '',
            email: '',
            object: JSON.parse(localStorage.getItem('pdfObject')) ? JSON.parse(localStorage.getItem('pdfObject')) : null,
            isUpdateMetaSuccess: false,
            updateMetaResponse: '',
            isDeleteAttachmentSuccess: false,
            deleteAttachmentMessage: '',
            isSendApiCall: false,
            convertedFile: '',
            isChecked: false,
            processStatus: '',
            isAccessToken: false,
            newSignedFile: '',
            downloadFileFromBlob: '',
            userType: localStorage.getItem('userType'),
            deltaXyPos: {
                x: 0,
                y: 0
            }
        }
        this._onChange = this._onChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getCategoryList();
        this.getViewAttachmnet();
        PdfViewStore.addChangeListener(this._onChange);
        PdfListStore.addChangeListener(this._onChange);
    }



    componentWillUnmount() {
        PdfViewStore.removeChangeListener(this._onChange);
        PdfListStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.viewAttachmnetState());
        this.setState(this.categoryListState());
        this.setState(this.updateMetaState());
        this.setState(this.deleteAttachmentState);
        if (!this.state.isUpdateMetaSuccess) {
            this.setState({
                selectValue: this.state.object.categoryId,
            })
        }
        if (this.state.isUpdateMetaSuccess) {
            toast.success(this.state.updateMetaResponse)
            this.setState({
                isUpdateMetaSuccess: false
            })
        }
        if (this.state.isSendApiCall && this.state.isDeleteAttachmentSuccess && this.state.deleteAttachmentMessage) {
            toast.success(this.state.deleteAttachmentMessage)
            setTimeout(function () { //Start the timer
                HISTORY.push({
                    pathname: '/pdfList'
                })
            }, 2000)
        }
        if (this.state.viewAttachmentResponse) {
            this.downloadFileFromUrl(this.state.viewAttachmentResponse, this.state.object.documentName);
        }
    }

    getViewAttachmnet() {
        if (this.state.object) {
            // PdfViewService.viewAttachment(this.state.object.documentId)
            PdfViewService.downloadAttachmentFromMbrhe(this.state.object.documentId)
                .catch(function (error) {
                    this.catchBody(error);
                }.bind(this));
        } else {
            this.setState({
                isSetLoading: false
            })
        }
    }

    viewAttachmnetState() {
        return {
            viewAttachmentResponse: PdfViewStore.mbrheresponse,
            // isViewAttachmentSuccess: PdfViewStore.isSuccess(),
        };
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        console.log("Error logging in : ", errorDescription);
    }

    editDocClick() {
        this.setState({
            editDoc: !this.state.editDoc,
            documentName: this.state.object.documentName,
            documentDescription: this.state.object.description,
            isSetLoading: false

        })
    }

    onChangeDocName = (event) => {
        this.setState({
            documentName: event.target.value.replace(/[^\w\s]/gi, "")
        });
    }

    onChangeDocDesc = (event) => {
        this.setState({
            documentDescription: event.target.value
        });
    }

    updateClick() {

        if (this.state.documentName === '') {
            toast.error(translate('pleaseEnterDocName'))
            return;
        }

        if (this.state.selectValue === '') {
            toast.error(translate('pleaseSelectDocType'))
            return;
        }

        if (this.state.documentDescription === '') {
            toast.error(translate('pleaseEnterDocDesc'))
            return;
        }

        this.setState({
            editDoc: !this.state.editDoc,
        })
        PdfViewService.updateMetaData(this.state.documentDescription, this.state.documentName, this.state.object.documentId,
            this.state.selectValue, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    updateMetaState() {
        return {
            isUpdateMetaSuccess: PdfViewStore.isUpdateMetaSuccess(),
            updateMetaResponse: PdfViewStore.updateMetaResponse,
        };
    }


    deleteDocClick() {
        this.setState({
            deleteDoc: !this.state.deleteDoc,
            isSetLoading: false
        })
    }

    cancelSignClick() {
        this.setState({
            cancelSign: !this.state.cancelSign,
        })
    }

    failedSignClick() {
        this.setState({
            failedSign: !this.state.failedSign,
        })
    }


    cancleSign() {
        this.setState({
            signIndocs: !this.state.signIndocs,
            isSetLoading: false
        })
    }


    viewdocsClick() {
        this.setState({
            viewdocs: !this.state.viewdocs,
            isSetLoading: true
        })
    }

    closeDoc() {
        this.setState({
            viewdocs: !this.state.viewdocs,
            isSetLoading: false
        })
    }

    getCategoryList() {
        PdfListService.projectCategoryList(this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    categoryListState() {
        return {
            categoryListResponse: PdfListStore.categoryListResponse,
            isCategoryListSuccess: PdfListStore.isCategoryListSuccess(),
        };
    }

    handleChange(event) {
        var data = event.target.value;
        if (data !== 'يرجى اختيار نوع المستند' || data !== 'Please select a document type') {
            this.setState({
                selectValue: JSON.parse(data)
            });
        } else {
            this.setState({
                selectValue: ''
            });
        }
    }

    deleteDocYesClick() {
        this.setState({
            deleteDoc: !this.state.deleteDoc,
            isSendApiCall: true
        })
        PdfListService.deleteAttachment(this.state.object.documentId, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
    }

    deleteAttachmentState() {
        return {
            isDeleteAttachmentSuccess: PdfListStore.isDeleteAttachmentSuccess(),
            deleteAttachmentMessage: PdfListStore.deleteAttachmentMessage,
        };
    }

    // download
    downloadPdf(httpUrl) {
        fetch(httpUrl)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = this.state.object.fileName;
                    a.click(); 
                    toast.success(translate('downloadDoc'))
                });
            });
    }

    //convert url to file
    downloadFileFromUrl(blob, fileName) {
        var convertBlogToFile = new File([blob], fileName, { type: "application/pdf" });
        var url = window.URL.createObjectURL(convertBlogToFile)
        this.setState({
            convertedFile: url
        })
    }

    redirect = (object, page) => {
        localStorage.setItem('pdfObject', JSON.stringify(object));
        HISTORY.push({
            pathname: page
            // state: { detail: itemObject }
        })
    };

    render() {
        const {categoryListResponse, isCategoryListSuccess, selectValue,
            isUpdateMetaSuccess, object, convertedFile } = this.state;

        if (isCategoryListSuccess) {
            categoryListResponse.sort((a, b) => a.categoryId - b.categoryId);
        }
        var formateDate;
        if (isUpdateMetaSuccess) {
            object.documentName = this.state.documentName
            object.categoryName = categoryListResponse.find(obj => obj.id === this.state.selectValue).value;
            object.description = this.state.documentDescription
        }
        if (object) {
            formateDate = Moment(object.createdDate).format('DD-MM-YYYY');
            var fileSize = CommonUtils.formatBytes(object.fileSize);
        }
        return (
            <div className="container-fluid">
                <a ref={this.linkRef} />

                {/* <PdfViewer data={pdfurl}/> */}
                <ToastContainer autoClose={5000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    {/* breadcrumb */}
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        {this.state.userType === "1" ? <a href="/customerDashboard">{translate('dashboard')}</a> : <a href="/contractorDashboard">{translate('dashboard')}</a>}
                                    </li>
                                    <li className="breadcrumb-item"><a href="/pdfList">{translate('documentsList')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('viewDocumemnt')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* End breadcrumb */}

                    {/* ******************** POPUP ******************** */}

                    {/* EDIT Document POPUP*/}
                    {this.state.editDoc ? <div className="PopupBk" ></div> : null}
                    {this.state.editDoc ? <div className="uploadPopup">
                        <div className="row formSection">
                            <div className="col-md-12 subTitle">
                                <h3>{translate('editDocDetail')} </h3>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="documentname">{translate('docName')}</label>
                                                <input maxLength="25" value={this.state.documentName} type="text" id="documentname" name="documentName" placeholder={this.context.state.siteLang === LOCALES.ENGLISH ? 'Please enter document name' : 'أدخل اسم المستند'}
                                                    onChange={this.onChangeDocName.bind(this)}
                                                />
                                                <div className="infoInputText">
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info.svg'} alt="pdf" /></i>
                                                    <span>{translate('validationLimit')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="documenttype">{translate('docType')}</label>
                                                <select value={selectValue} onChange={this.handleChange}>
                                                    {this.context.state.siteLang === LOCALES.ENGLISH ? <option>Please select document type</option> : <option>يرجى اختيار نوع المستند</option>}
                                                    {isCategoryListSuccess && categoryListResponse.map((option) => (
                                                        <option key={option.id} value={option.id}>{option.value}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="documentdescription">{translate('docDesc')}</label>
                                                <textarea value={this.state.documentDescription} id="documentdescription" placeholder={this.context.state.siteLang === LOCALES.ENGLISH ? 'Please enter document description' : 'أدخل تفاصيل المستند'}
                                                    onChange={this.onChangeDocDesc.bind(this)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 uploadDocumentBtn">
                                        <button onClick={this.updateClick.bind(this)}>{translate('update')}</button>
                                        <button onClick={this.editDocClick.bind(this)}>{translate('cancle')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {/* END EDIT Document POPUP*/}

                    {/* DELETE Document POPUP*/}
                    {this.state.deleteDoc ? <div className="PopupBk" ></div> : null}
                    {this.state.deleteDoc ? <div className="uploadPopup deletePopup">
                        <div className="row formSection">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <p>{translate('deletePopUp')} ({object.documentName}) {this.context.state.siteLang === LOCALES.ENGLISH ? '?' : '؟'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 uploadDocumentBtn">
                                        <button onClick={this.deleteDocYesClick.bind(this)}>{translate('yes')}</button>
                                        <button onClick={this.deleteDocClick.bind(this)}>{translate('cancle')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {/* END DELETE Document POPUP*/}

                    {/* view Document POPUP*/}
                    {this.state.viewdocs ? <div className="PopupBk" ></div> : null}
                    {this.state.viewdocs ? <div className="uploadPopup">
                        <div className="row formSection">
                            <div className="col-md-12 subTitle">
                                <h3>{object.documentName}</h3>
                                <button className="close" onClick={this.closeDoc.bind(this)}>X</button>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 signInPopupPdfWrapper pdfViewWrapperBox">
                                                {/* <PdfViewer data={props} /> */}
                                                {convertedFile ? <embed src={convertedFile + '#toolbar=0'} type="application/pdf" width="100%" height="560px" /> : <embed src='' type="application/pdf" width="100%" height="560px" />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {/* END view Document POPUP*/}

                    {/* ******************** END POPUP ******************** */}

                    <div className="row service_contant_wrapper">
                        <div className="subTitle">
                            <h2>{translate('docDetail')}</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="pdfVewWrapper">
                                        {convertedFile ? <button onClick={this.viewdocsClick.bind(this)} className="pdfZoomBtn"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/zoom.svg'} alt="zoom" /></button> : null}
                                        {convertedFile ? <embed src={convertedFile + '#toolbar=0'} type="application/pdf" width="100%" height="560px" /> : <embed src='' type="application/pdf" width="100%" height="560px" />}
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    {object ? <div className="row documentViewDetails">
                                        <div className="col-sm-4"><strong>{translate('docName')}: </strong></div>
                                        <div className="col-sm-8"><span>{object.documentName}</span></div>
                                        <div className="col-sm-4"><strong>{translate('docType')}: </strong></div>
                                        <div className="col-sm-8"><span>{object.categoryName}</span></div>
                                        <div className="col-sm-4"><strong>{translate('date')}: </strong></div>
                                        <div className="col-sm-8"><span>{formateDate}</span></div>
                                        {!object ? <div className="col-sm-4"><strong>{translate('docValidityUntill')}: </strong></div> : null}
                                        {!object ? <div className="col-sm-8"><span>--</span></div> : null}
                                        <div className="col-sm-4"><strong>{translate('docSize')}: </strong></div>
                                        <div className="col-sm-8"><span>{fileSize}</span></div>
                                        <div className="col-sm-4"><strong>{translate('docDetail')}: </strong></div>
                                        <div className="col-sm-8"><span>{object.description}</span></div>
                                        <div className="col-lg-12 viewFileBtns">
                                            {object.isSigned ? <button className="viewSignBtn" ><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/tick_ico.svg'} alt="Sign" /></i> <span>{translate('signed')}</span></button> :
                                                <button className="viewSignBtn" onClick={this.redirect.bind(this, object, '/signPdf')}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign.svg'} alt="Sign" /></i> <span>{translate('sign')}</span></button>}
                                            {object.enableEdit ? <button className="viewEditBtn" onClick={this.editDocClick.bind(this)}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit_ico.svg'} alt="Edit" /></i> <span>{translate('edit')}</span></button> : null}
                                            <button onClick={this.downloadPdf.bind(this, convertedFile)} className="viewDownloadBtn"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/dowlload.svg'} alt="Download" /></i> <span>{translate('download')}</span></button>
                                            {object.enableEdit ? <button className="viewDeleteBtn" onClick={this.deleteDocClick.bind(this)}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete_boq.svg'} alt="Delete" /></i> <span>{translate('delete')}</span></button> : null}
                                        </div>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default AuthenticatedComponent(PdfViewComponent)