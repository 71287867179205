import React from 'react'
import history from '../../History'
import { toast } from 'react-toastify'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class StepThree extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            documentsArray: [],
            ticketPayload: '',
            ticketNo: localStorage.getItem("dTicketNo"),
            response: localStorage.ticketPayload ? JSON.parse(localStorage.getItem("ticketPayload")) : ""
        }
        this.goBack = this.goBack.bind(this)
        this.goStepFour = this.goStepFour.bind(this)
        this.getDocuments = this.getDocuments.bind(this)
        this.setDocuments = this.setDocuments.bind(this)
        this.hasUnSignedDocuments = this.hasUnSignedDocuments.bind(this)
        this.validateSignedDocuments = this.validateSignedDocuments.bind(this)

    }

    componentDidMount() {
        this.getDocuments()
        this.setState({ ticketPayload: localStorage.getItem('ticketPayload') ? JSON.parse(localStorage.getItem('ticketPayload')) : '' })
    }

    viewSignDoc = event => {
        var docObject = this.getDocumentObjectToPassNext(this.state.response, Number(event.currentTarget.name), Number(event.currentTarget.id))
        history.push({ pathname: "/viewDocument", state: { ticketNo: this.state.ticketNo, doc: docObject } });
    }

    SignDoc = event => {
        var docObject = this.getDocumentObjectToPassNext(this.state.response, Number(event.currentTarget.name), Number(event.currentTarget.id))
        localStorage.setItem('pdfObject', JSON.stringify(docObject));
        history.push({ pathname: "/signDocument", state: { ticketNo: this.state.ticketNo, doc: docObject[0] } });
    }

    getDocumentObjectToPassNext(response, categoryId, documentId) {
        var docObject
        response.attachments.map((category) => {
            if (categoryId === category.id) {
                category.attachments.map((document) => {
                    if (documentId === document.id) {
                        docObject = document
                    }
                })
            }
        })
        return docObject
    }

    getDocuments() {
        this.props.reference.startLoading()
        // setTimeout(() => {
        fetch(CONSTANTS.getDocumentsToSignListEndpoint + "/" + this.state.ticketNo + "?local=" + this.context.state.siteLang + "&ticketStatusId=" + this.props.reference.state.ticketPayload.requestStatusId, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.setDocuments).catch();
        // }, 5000)
    }

    setDocuments(response) {
        if (response.success) {
            var docsArray = []
            if (response.payload.attachments.length < 6) {
                setTimeout(() => {
                    this.getDocuments()
                }, 3000)
            } else {
                this.props.reference.stopLoading()
                response.payload.attachments.map((category, index) => {
                    return docsArray.push(<Category ticketPayload={this.state.ticketPayload} key={index} payload={category} viewSignDoc={this.viewSignDoc} SignDoc={this.SignDoc} />)
                })
                this.setState({ response: response.payload, documentsArray: docsArray })
            }
        } else {
            this.props.reference.stopLoading()
        }
    }

    goStepFour() {
        localStorage.setItem('startAtStep', 3)
        this.props.jumpToStep(3)
    }

    goBack() {
        localStorage.setItem('startAtStep', 1)
        this.props.jumpToStep(1)
    }

    hasUnSignedDocuments(response) {
        if (response.success) {
            this.goStepFour()
        } else {
            toast.error(response.message)
        }
    }

    validateSignedDocuments() {
        if (this.state.ticketPayload.requestStatusId === 10) {
            this.goStepFour()
        } else {
            fetch(CONSTANTS.validateDocumentsSignatureEndpoint + "/" + localStorage.getItem("dTicketNo") + "?local=" + this.context.state.siteLang, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    'Authorization': CONSTANTS.authToken
                },
            }).then(response => response.json()).then(this.hasUnSignedDocuments).catch((response) => { console.log(response.message) })
        }
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="formSection constructionDisbursementContractRequestsHead">
                    <div className='constructionDisbursementStepInfoWrapper'>
                        <div className="row">
                            <div className="col-md-6">
                                <span>{translate('loanAmount')}: </span>
                                <strong> {this.state.ticketPayload.allowedLoanValue} </strong>
                            </div>
                            <div className="col-md-6">
                                <span>{translate('installmentAmount')}: </span>
                                <strong> {this.state.ticketPayload.loanInstallmentMonthly} </strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <span>{translate('numberInstallments')}:  </span>
                                <strong> {this.state.ticketPayload.paymentDurationMonths} </strong>
                            </div>
                            <div className="col-md-6">
                                <span>{translate('repaymentInstallmentdurationYears')}: </span>
                                <strong> {this.state.ticketPayload.paymentDurationYears} </strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <span>{translate('repaymentInstallmentDurationMonths')}: </span>
                                <strong> {this.state.ticketPayload.paymentDurationMonths} </strong>
                            </div>
                            <div className="col-md-6">
                                <span>{translate('advancedInsuranceAmount')}: </span>
                                <strong> {this.state.ticketPayload.loanInsurance} </strong>
                            </div>
                        </div>
                    </div>
                    <h3>{translate('Documents')}</h3>
                    <hr></hr>
                    {this.state.documentsArray}
                    <br />
                    <div className="col-md-12 constructionLoanDisbursementStepBtn">
                        <button className="stepBacktBtn" onClick={this.goBack}>{translate('back')}</button>
                        <button className="stepFinishBtn" onClick={this.validateSignedDocuments}>{translate('next')}</button>
                    </div>
                </div>
            </div>
        )
    }
}

function Document(props) {
    return (
        <div className="col-12">
            <div className="constructionUploadFileSection">
                <div className="row">
                    <div className="col-lg-5 col-md-8">
                        <div className="iconDiv">
                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/pdf_ico.svg" alt="pdf" /></i>
                        </div>
                        <div className="textDiv">
                            <span>{props.document.name.replace('.pdf', "")}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                        <span>{translate('status')}</span> <strong>{props.document.isSigned > 0 ? translate('signed') : translate('needYourSignature')}</strong>
                    </div>
                    <div className="col-lg-4 col-md-12 constructionUploadFileSectionBtns">
                        <button id={props.document.id} name={props.categoryId} className="viewBtn" onClick={props.viewSignDoc}>
                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/eye_icon_white.svg" alt="pdf" /></i>
                            <span>{translate('view')}</span>
                        </button>
                        {props.ticketPayload.requestStatusId !== 10 ? <button id={props.document.id} name={props.categoryId} className="signBtn" onClick={props.SignDoc}>
                            <i><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/sign.svg" alt="pdf" /></i>
                            <span>{props.document.isSigned > 0 ? translate('reSignature') : translate('sign')}</span>
                        </button> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

function Category(props) {
    return (
        <div className="row">
            <Documents ticketPayload={props.ticketPayload} categoryId={props.payload.id} attachments={props.payload.attachments} viewSignDoc={props.viewSignDoc} SignDoc={props.SignDoc} ></Documents>
        </div>
    )
}

function Documents(props) {
    var docsArray = []
    props.attachments.map((document, index) => {
        return docsArray.push(<Document ticketPayload={props.ticketPayload} key={index} categoryId={props.categoryId} document={document} viewSignDoc={props.viewSignDoc} SignDoc={props.SignDoc} />)
    })
    return docsArray
}

export default AuthenticatedComponent(StepThree)