import PdfListAction from './PdfListAction';
import PdfListConstant from './PdfListConstant';
import request from 'reqwest';
import when from 'when';
import axios from 'axios';

class PdfListService {
    /** customer inbox projects list */
    customerInboxList(local, documentId) {
        return this.handleInboxListResponse(request({
            url: PdfListConstant.CUSTOMER_INBOX_LIST_URL + local + '&documentId=' + documentId,
            method: 'GET',
            crossOrigin: true,
            type: 'json',
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem('accessToken')
            }
        }));
    }

    handleInboxListResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfListAction.cutsomerInboxList(response);
                return true;
            });
    }

    /** projects category list */
    projectCategoryList(local) {
        return this.handleProjectCategoryResponse(request({
            url: PdfListConstant.CATEGORY_LIST_URL + local,
            method: 'GET',
            crossOrigin: true,
            type: 'json',
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem('accessToken')
            }
        }));
    }

    handleProjectCategoryResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfListAction.projectCategoryList(response);
                return true;
            });
    }

    /** upload unsigned attachment */
    uploadUnsignedAttachment(id, documentDescription, documentName, file, local) {
        const url = PdfListConstant.UPLAOD_UNSIGNED_ATTACHMENT_URL + local;
        const formData = new FormData();
        formData.append('categoryId', id);
        formData.append('description', documentDescription);
        formData.append('fileName', documentName);
        formData.append('file', file);
        const config = {
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem("accessToken")
            }
        }
        return this.handleUploadUnsignedAttachmentResponse(axios.post(url, formData, config))
    }

    handleUploadUnsignedAttachmentResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfListAction.uploadUnsignedAttachment(response.data);
                return true;
            });
    }

    /** delete attachment */
    deleteAttachment(documentId, local) {
        return this.handleDeleteDocumentResponse(when(request({
            url: PdfListConstant.DELETE_DOCUMENT_URL + local + '&documentId='+ documentId,
            method: 'POST',
            crossOrigin: true,
            type: 'json',
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem("accessToken")
            }
        })));
    }

    handleDeleteDocumentResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfListAction.deleteAttachment(response);
                return true;
            });
    }
}
export default new PdfListService();