import React from "react"
import { AppContext } from '../../Providers/context'
import translate from "../../Providers/i18n/Translate"

class HouseAddition extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.next = this.next.bind(this)
        this.onChange = this.onChange.bind(this)
        this.validate = this.validate.bind(this)
    }

    onChange(e) {
        this.props.reference.updateSocialStudyPayload(e.target.name, e.target.value.toString().toLowerCase().trim() === 'true' ? 1 : 0,3)
    }

    validate() {
        this.props.reference.reset()
        return this.props.reference.state.socialStudyPayload.requireServiceSection !== null && 
        this.props.reference.state.socialStudyPayload.requireFirstFloor !== null && 
        this.props.reference.state.socialStudyPayload.requireHall !== null && 
        this.props.reference.state.socialStudyPayload.requireHallWithBath !== null && 
        this.props.reference.state.socialStudyPayload.requirePreparationKitchen !== null && 
        this.props.reference.state.socialStudyPayload.requireRoomWithBath !== null ? true : false
    }

    next() {
        if (this.validate()) {
            this.props.reference.postSocialStudy(2)
        } else {
            this.props.reference.showError(translate('requiredErrorMessage'))
        }
    }

    render() {
        return (
            <div className="col">
                <div className="formSection row">
                    <div className="col">
                        <div className="row">
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('ServiceExtension')}<span className="redStar">*</span></p>
                                <input type="radio" id="ServiceExtensionyes" name="requireServiceSection" value={true} checked={this.props.reference.state.socialStudyPayload.requireServiceSection===1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="ServiceExtensionyes">{translate("yes")}</label>
                                <input type="radio" id="ServiceExtensionno" name="requireServiceSection" value={false} checked={this.props.reference.state.socialStudyPayload.requireServiceSection===0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="ServiceExtensionno">{translate("no")}</label>
                            </div>
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('FirstFloor')}<span className="redStar">*</span></p>
                                <input type="radio" id="FirstFlooryes" name="requireFirstFloor" value={true} checked={this.props.reference.state.socialStudyPayload.requireFirstFloor===1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="FirstFlooryes">{translate("yes")}</label>
                                <input type="radio" id="FirstFloorno" name="requireFirstFloor" value={false} checked={this.props.reference.state.socialStudyPayload.requireFirstFloor===0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="FirstFloorno">{translate("no")}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('MasterBedroom')}<span className="redStar">*</span></p>
                                <input type="radio" id="MasterBedroomyes" name="requireRoomWithBath" value={true} checked={this.props.reference.state.socialStudyPayload.requireRoomWithBath===1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="MasterBedroomyes">{translate("yes")}</label>
                                <input type="radio" id="MasterBedroomno" name="requireRoomWithBath" value={false} checked={this.props.reference.state.socialStudyPayload.requireRoomWithBath===0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="MasterBedroomno">{translate("no")}</label>
                            </div>
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('MasterMajilis')}<span className="redStar">*</span></p>
                                <input type="radio" id="MasterMajilisyes" name="requireHallWithBath" value={true} checked={this.props.reference.state.socialStudyPayload.requireHallWithBath===1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="MasterMajilisyes">{translate("yes")}</label>
                                <input type="radio" id="MasterMajilisno" name="requireHallWithBath" value={false} checked={this.props.reference.state.socialStudyPayload.requireHallWithBath===0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="MasterMajilisno">{translate("no")}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('PreperationalKitchen')}<span className="redStar">*</span></p>
                                <input type="radio" id="PreperationalKitchenyes" name="requirePreparationKitchen" value={true} checked={this.props.reference.state.socialStudyPayload.requirePreparationKitchen===1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="PreperationalKitchenyes">{translate("yes")}</label>
                                <input type="radio" id="PreperationalKitchenno" name="requirePreparationKitchen" value={false} checked={this.props.reference.state.socialStudyPayload.requirePreparationKitchen===0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="PreperationalKitchenno">{translate("no")}</label>
                            </div>
                            <div className="col-md-6 customRadio fixedLabel">
                                <p>{translate('Salle')}<span className="redStar">*</span></p>
                                <input type="radio" id="Salleyes" name="requireHall" value={true} checked={this.props.reference.state.socialStudyPayload.requireHall===1} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="Salleyes">{translate("yes")}</label>
                                <input type="radio" id="Salleno" name="requireHall" value={false} checked={this.props.reference.state.socialStudyPayload.requireHall===0} defaultChecked={false} onChange={this.onChange} />
                                <label htmlFor="Salleno">{translate("no")}</label>
                            </div>
                        </div>
                        <hr></hr>
                    </div>
                    <div className="col-md-12 formSectionBtn ">
                        <button className="stepNextBtn" onClick={this.next}>{translate('next')}</button>
                    </div>
                </div>
            </div >
        )
    }
}

export default HouseAddition