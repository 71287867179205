var BASE_URL = process.env.API_BASE_URL
export default {
  OPEN_NEW_PROJECT_FILE_BOQ_URL: BASE_URL + 'contractor/v1/service-request/billofquantity?contractId=',
  OPEN_NEW_PROJECT_FILE_BOQ: 'OPEN_NEW_PROJECT_FILE_BOQ',

  OPEN_NEW_PROJECT_FILE_ADD_HEADER_URL: BASE_URL + 'contractor/v1/service-request/addbillofquantity?local=',
  OPEN_NEW_PROJECT_FILE_ADD_HEADER: 'OPEN_NEW_PROJECT_FILE_ADD_HEADER',

  LIST_OWNER_SCOPE_URL: BASE_URL + 'contractor/v1/service-request/EngOwnerScopeWorkList?contractId=',
  LIST_OWNER_SCOPE: 'LIST_OWNER_SCOPE',

  SAVE_MODIFY_OWNER_SCOPE_URL: BASE_URL + 'contractor/v1/service-request/SaveOrupdateEngOwnerScopeWork?local=',
  SAVE_MODIFY_OWNER_SCOPE: 'SAVE_MODIFY_OWNER_SCOPE',

  CLEAR_OR_DELETE_OWNER_SCOPE_URL: BASE_URL + 'contractor/v1/service-request/DeleteEngOwnerScopeWork?local=',
  CLEAR_OR_DELETE_OWNER_SCOPE: 'CLEAR_OR_DELETE_OWNER_SCOPE'
}