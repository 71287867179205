import PdfViewAction from './PdfViewAction';
import PdfViewConstant from './PdfViewConstant';
import request from 'reqwest';
import when from 'when';
import axios from 'axios';

class PdfViewService {
    /** view attachment */
    viewAttachment(documentId) {
        if (localStorage.getItem('accessToken')) {
            return this.viewAttachmentResponse(request({
                url: PdfViewConstant.VIEW_ATTACHMENT_URL + documentId,
                method: 'GET',
                crossOrigin: true,
                type: 'json',
                headers: {
                    'Authorization': 'bearer ' + localStorage.getItem('accessToken'),
                    'Accept': 'application/pdf'
                }
            }));
        }
    }

    viewAttachmentResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.viewAttachment(response);
                return true;
            });
    }

    /** download Attachment From Mbrhe */
    downloadAttachmentFromMbrhe(documentId) {
        const url = PdfViewConstant.DOWNLOAD_MBRHE_ATTACHMENT_URL + documentId;
        if (localStorage.getItem('accessToken')) {
            return this.downloadAttahmentResponse(axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Authorization': 'bearer ' + localStorage.getItem('accessToken'),
                    'Accept': 'application/pdf'
                }
            }));
        }
    }

    downloadAttahmentResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.downloadFromMbrheApi(response.data);
                return true;
            });
    }

    /** download Attachment From Mbrhe */
    downloadAttachmentFromMbrheNew(documentId) {
        const url = PdfViewConstant.DOWNLOAD_MBRHE_ATTACHMENT_NEW_URL + documentId;
        if (localStorage.getItem('accessToken')) {
            return this.downloadAttahmentResponseNew(axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Authorization': 'bearer ' + localStorage.getItem('accessToken'),
                    'Accept': 'application/pdf'
                }
            }));
        }
    }

    downloadAttahmentResponseNew(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.downloadFromMbrheApi(response.data);
                return true;
            });
    }

    /** update metadata */
    updateMetaData(description, fileName, documentId, categoryId, local) {
        return this.updateMetaDataResponse(when(request({
            url: PdfViewConstant.UPDATE_META_DATA_URL + local,
            method: 'POST',
            crossOrigin: true,
            type: 'json',
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem("accessToken")
            },
            data: {
                description: description,
                fileName: fileName,
                documentId: documentId,
                categoryId: categoryId
            }
        })));
    }

    updateMetaDataResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.updateMeta(response);
                return true;
            });
    }

    /** uaepass get access token */
    uaePassAccessToken() {
        const tokenUrl = PdfViewConstant.UARPASS_ACCESS_TOKEN_URL;
        return this.uaePassAccessTokenResponse(when(request({
            url: tokenUrl,
            method: 'POST',
            crossOrigin: true,
            type: 'json',
        })));
    }

    uaePassAccessTokenResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.getUaePassToken(response);
                return true;
            });
    }

    // uaePassCreateProcess
    uaePassCreateProcess(process, document) {
        const url = PdfViewConstant.UARPASS_PROCESS_URL;
        const formData = new FormData();
        formData.append('process', JSON.stringify(process));
        formData.append('document', document);
        const config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('uaePassToken')
            }
        }
        return this.uaePassCreateProcessResponse(axios.post(url, formData, config))
    }

    uaePassCreateProcessResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.uaePassCreateProcess(response.data);
                return true;
            });
    }

    /** get signed document from UAEPAss */
    fetchSignedDocument(documentId) {
        const url = PdfViewConstant.FETCHED_SIGNED_DOCUMENT_URL + documentId + '/document';
        return this.getSignedDocument(axios.get(url, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('uaePassToken'),
                'Accept': 'application/pdf'
            }
        }));
    }

    getSignedDocument(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.getSignedDocumentFromUaePass(response.data);
                return true;
            });
    }

    uploadSignedDocNew(file, process_id, uaepass_status, document_id, uaePass_document_id, local) {
        const url = PdfViewConstant.UPLOAD_SIGNED_DOCUMENT_URL_NEW + local;
        const formData = new FormData();
        formData.append('file', file)
        formData.append('process_id', process_id);
        formData.append('uaepass_status', uaepass_status);
        formData.append('document_id', document_id);
        formData.append('uaePass_document_id', uaePass_document_id);
        const config = {
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem("accessToken")
            }
        }
        return this.uploadSignedDocResponseNew(axios.post(url, formData, config))
    }

    uploadSignedDocResponseNew(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.uploadSignedDocument(response.data);
                return true;
            });
    }

    /** upload Signed Document to mbrhe server */
    uploadSignedDoc(file, process_id, uaepass_status, document_id, uaePass_document_id, local) {
        const url = PdfViewConstant.UPLOAD_SIGNED_DOCUMENT_URL + local;
        const formData = new FormData();
        formData.append('file', file)
        formData.append('process_id', process_id);
        formData.append('uaepass_status', uaepass_status);
        formData.append('document_id', document_id);
        formData.append('uaePass_document_id', uaePass_document_id);
        const config = {
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem("accessToken")
            }
        }
        return this.uploadSignedDocResponse(axios.post(url, formData, config))
    }

    uploadSignedDocResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.uploadSignedDocument(response.data);
                return true;
            });
    }

    // delete sign document from uaepass
    deleteDocumentFromUAEPass(processId) {
        const url = PdfViewConstant.DELETE_SIGNED_DOCUMENT_URL + processId;
        const config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('uaePassToken')
            }
        }
        return this.deleteDocumentFromUAEPassResponse(axios.delete(url, config))
    }

    deleteDocumentFromUAEPassResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.deleteDocumentFromUAEPass(response);
                return true;
            });
    }

    /** document info */
    documentInformation(base64) {
        var data = JSON.stringify({
            base64: base64
        })
        var config = {
            method: 'post',
            url: PdfViewConstant.DOCUMENT_INFORMATION_URL,
            headers: {
                // 'Authorization': 'bearer ' + localStorage.getItem('accessToken'),
                'Content-Type': 'application/json'
            },
            data: data
        };

        return this.documentInformationResponse(axios(config));
    }


    documentInformationResponse(responsePromise) {
        return responsePromise
            .then(function (response) {
                PdfViewAction.documentInformation(response.data);
                return true;
            });
    }

}
export default new PdfViewService();