import React from 'react'
import History from '../../History'
import { AppContext } from '../../Providers/context'
import InputComponent from '../utils/inputComponent'
import ErrorComponent from '../utils/errorComponent'
import RequestBuilder from '../utils/requestBuilder'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class ConstructionCostCalculatorService extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super()
        this.state = {
            formFields: {
                budget: '',
                area: '',
                averageCost: ''
            },
            errors: ["budget", "area", "averageCost"],
            payload: {},
            showResults: false,
            error: null,
        }
        this.calculateCost = this.calculateCost.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.newRequest = this.newRequest.bind(this)
        this.successCalback = this.successCalback.bind(this)
        this.failCallback = this.failCallback.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.showError = this.showError.bind(this)
        this.addError = this.addError.bind(this)
        this.removeError = this.removeError.bind(this)
    }

    handleChange(e, isValid) {
        this.reset()
        const { name, value } = e.target
        this.setState({ formFields: { ...this.state.formFields, [name]: value } })
        if (isValid) {
            this.removeError(name)
        } else {
            this.addError(name)
        }
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    calculateCost(e) {
        e.preventDefault();
        this.reset()
        if (this.state.errors.length === 0) {
            RequestBuilder.serverGETRequest(CONSTANTS.calculateConstructionCostEndpoint + "?local=" + this.context.state.siteLang, this.state.formFields, this.successCalback, this.failCallback)
        } else {
            this.showError(translate('requiredErrorMessage'))
        }
    }

    successCalback(response) {
        if (response.success) {
            this.setState({ showResults: true, payload: response })
        } else {
            this.showError(response.message)
        }
    }

    failCallback(response) {
        this.showError(response.message)
    }

    closeCallback() {
        this.setState({ error: null })
    }

    newRequest() {
        this.setState({ showResults: false, formFields: {}, errors: ["budget", "area", "averageCost"] })//formFields:{} only if you want to empty form
    }

    reset() {
        this.setState({ error: null })
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('ConstructionCalculator')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HouseConstructionLoan_icon.svg'} alt="" /></i><span>{translate('ConstructionCalculator')}</span></div>
                        </div>

                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                {this.state.showResults ? null :
                                    <form>
                                        <div className="row">
                                            <InputComponent inputProps={{ lable: translate('Budget'), name: 'budget', isRequired: true, defaultValue: this.state.formFields.budget, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                            <InputComponent inputProps={{ lable: translate('Area'), name: 'area', isRequired: true, defaultValue: this.state.formFields.area, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                        </div>
                                        <div className="row">
                                            <InputComponent inputProps={{ lable: translate('AverageCost'), name: 'averageCost', isRequired: true, defaultValue: this.state.formFields.averageCost, placeHolder: 'AED', type: 'number', changeListener: this.handleChange }} />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 formSectionBtn">
                                                <button type="reset" onClick={History.goBack}>{translate('cancle')}</button>
                                                <button disabled={false} onClick={this.calculateCost}>{translate('calculate')}</button>
                                            </div>
                                        </div>
                                    </form>}

                                {/* Result */}
                                {this.state.showResults ?
                                    <div className="calculationResult">
                                        <HappinessMeter serviceCode='2602' transactionID="" />
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <span>{translate('OutDoorConstructionCost')} : </span>
                                                <span><strong>{this.state.payload.outDoorConstructionCost}</strong></span>
                                            </div>
                                            <div className="col-sm-6">
                                                <span>{translate('EstimatedBuildupArea')} : </span>
                                                <span><strong>{this.state.payload.estimatedBuildupArea}</strong></span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 formSectionBtn">
                                                <button onClick={this.newRequest} type="submit">{translate('make-new-request')}</button>
                                            </div>
                                        </div>
                                    </div> : null}
                            </div>
                            {this.state.error}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AuthenticatedComponent(ConstructionCostCalculatorService)