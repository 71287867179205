import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import translate from '../../../Providers/i18n/Translate'
import { LOCALES } from '../../../Providers/i18n';
import { AppContext } from '../../../Providers/context'
import NoData from '../../utils/nodata';


let financialStatusCopy = [];
var noData = '';
class FinancialStatusGraphWidgetItem extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
        this.state = {
            financialStatusResponse: this.props.payload,
            selectValue: '',
            top: 0,
            left: 0,
            showTooltip: false,
            apiData: '',
        }

        this.handleChange = this.handleChange.bind(this);
    }

    //Financial Status(Doughnut chart)
    getData(key) {
        let dataObject = 0;
        if (key === 'totalRetentions') {
            dataObject = financialStatusCopy[0].totalRetentions;
        } else if (key === 'totalPayment') {
            dataObject = financialStatusCopy[0].totalPayment;
        } else if (key === 'remainingPayment') {
            dataObject = financialStatusCopy[0].remainingPayment;
        } else if (key === 'ownerShare') {
            dataObject = financialStatusCopy[0].ownerShare;
        } else if (key === 'loanAmount') {
            dataObject = financialStatusCopy[0].loanAmount;
        } else if (key === 'contractAmount') {
            dataObject = financialStatusCopy[0].contractAmount;
        }
        return dataObject;
    }

    //Financial Status(Doughnut chart)
    setFinancialStatusResponse() {
        const financialStatus = {
            labels: [financialStatusCopy[0].totalRetentionsLabel,
            financialStatusCopy[0].totalPaymentLabel,
            financialStatusCopy[0].remainingPaymentLabel,
            financialStatusCopy[0].ownerShareLabel,
            financialStatusCopy[0].loanAmountLabel,
            financialStatusCopy[0].contractAmountLabel],
            datasets: [
                {
                    label: 'Financial status',
                    fill: true,
                    lineTension: 0.1,
                    backgroundColor: ['#55D8FE', '#FF8373', '#FFDA83', '#338AE6', '#A3A0FB', '#003454', '#F6303F', '#0296CE', '#C224B9', '#C1CF32', '#0068AD', '#F29400'],
                    data: [this.getData('totalRetentions'),
                    this.getData('totalPayment'),
                    this.getData('remainingPayment'),
                    this.getData('ownerShare'),
                    this.getData('loanAmount'),
                    this.getData('contractAmount')]
                }
            ]
        };
        return financialStatus;
    }

    //Financial Status(Doughnut chart)
    handleChange(event) {
        var data = event.target.value;
        this.setState({
            selectValue: JSON.parse(data)
        });
    }

    //Financial Status(Bar chart)
    setProjectSummaryResponse() {
        const projectSummary = {
            labels: this.getStatus('paymentDate'),
            datasets: [
                {
                    label: '',
                    barThickness: 28,
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#A3A1FB',
                    data: this.getStatus('percentageCompleted'),
                }
            ]
        }
        return projectSummary;
    }

    //Financial Status(Bar chart)
    getStatus(key) {
        var dataList = [];
        noData = "";
        if (financialStatusCopy[0].payments.length > 0) {
            dataList = financialStatusCopy[0].payments.map(value => {
                if (key === 'paymentDate') {
                    return value.paymentDate;
                } else if (key === 'color') {
                    return value.color;
                } else if (key === 'percentageCompleted') {
                    return value.percentageCompleted;
                }
            });
        } else {
            if (this.context.state.siteLang === LOCALES.ENGLISH) {
                noData = 'Payments Not Available';
            } else {
                noData = 'المدفوعات غير متوفرة';
            }
        }
        return dataList;
    }
    setPositionAndData = (payload) => {
        this.setState({
            ...this.state,
            top: payload.top,
            left: payload.left,
            apiData: payload.data,
            showTooltip: true,
        });
    };

    hide = () => {
        this.setState({
            ...this.state,
            showTooltip: false,
        });
    };

    setOptions = () => {
        const options = {
            responsive: true,
            maintainAspectRatio: true,
            legend: {
                display: false
            },
            title: {
                display: true,
                fontColor: "#0364a2",
                fontSize: 13,
                text: noData,
            },
            "scales": {
                "xAxes": [
                    {
                        "gridLines": {
                            "color": "#222",
                            "offsetGridLines": true,
                            "borderDash": [
                                0,
                                2
                            ]
                        },
                    }
                ],
                "yAxes": [
                    {
                        "gridLines": {
                            "display": true,
                            "categorySpacing": 90,
                            "drawBorder": false,
                            "color": "rgba(0, 0, 0, 0.1)"
                        },
                        "ticks": {
                            "beginAtZero": true,
                            "min": 0,
                            "max": 100,
                            "stepSize": 10,
                            callback: function (value) {
                                // convert it to percentage
                                return (value / this.max * 100).toFixed(0) + '%';
                            },
                        }
                    }
                ]
            },
            "hover": {
                mode: "x",
                intersect: true
            },
            events: ["mousemove", "mouseout", "click", "touchstart", "touchmove", "touchend"],
            onHover: (evt, item) => {
                if (evt.type == "mouseout") {
                    this.hide();
                    return;
                }
            },
            tooltips: {
                enabled: false,
                mode: 'x',
                intersect: true,
                "custom": (tooltipModel) => {
                    const position = this.refs.chart.chartInstance.chart.canvas.getBoundingClientRect();
                    // set position of tooltip
                    const left = position.left + tooltipModel.caretX - 96;
                    const top = position.top + tooltipModel.caretY - 113;
                    //get bar index
                    var index = tooltipModel.dataPoints[0].index;
                    // set values
                    const data = financialStatusCopy[0].payments[index];
                    this.setPositionAndData({ top, left, data });
                }
            }
        }
        return options;
    }

    render() {
        const { financialStatusResponse, selectValue, apiData } = this.state;
        financialStatusCopy = [];
        if (selectValue) {
            financialStatusCopy.push(selectValue);
        } else {
            financialStatusCopy = [...this.state.financialStatusResponse];
        }
        const left = this.state.left + 5;
        const top = this.state.top - 15;
        return (
            <div className="row">
                {/* Doughnut chart */}
                <div className="col-lg-6">
                    <div className="col-md-12 chartTitle">
                        <span>
                            {/* <label htmlFor="financialDropdown">{translate('financial-status-widget-heading')}</label> */}
                            <select id="financialDropdown"
                                onChange={this.handleChange} >
                                {/* {this.context.state.siteLang === 'ar' ? <option>الرجاء الاختيار</option> : <option>Please Select</option>} */}
                                {financialStatusResponse.map((value, index) =>
                                    <option key={index} value={JSON.stringify(value)}>
                                        {value.owner + ' - ' + value.applicationNo}
                                    </option>
                                )
                                }
                            </select>
                        </span>
                    </div>
                    <Doughnut ref="doughnut" legend={{ "position": "right", }} data={this.setFinancialStatusResponse()} width={380} />
                </div>
                {/* Bar chart */}
                <div className="col-lg-6">
                   {financialStatusCopy[0].payments.length > 0? 
                   <Bar ref="chart" data={this.setProjectSummaryResponse()} options={this.setOptions()} width={300} />
                   : <NoData message={translate('nodata')} />}
                    {apiData && this.state.showTooltip ? this.customTooltipOnYourLine(top, left, apiData) : null}
                </div>
            </div>
        );
    }
    customTooltipOnYourLine(top, left, payments) {
        return (
            <div className="grapPopupBox"
                style={{
                    top,
                    left,
                }} >
                <div>{translate('totalBillAmount')}<span>: {payments.totalBillPaymentDisplay}</span></div>
                <div>{translate('paymentToOwner')}<span>: {payments.paymentOwner}</span></div>
                <div>{translate('paymentToContractor')}<span>: {payments.paymentContractor}</span></div>
                <div>{translate('billRetention')}<span>: {payments.billRetention}</span></div>
                <div>{translate('status')}<span>: {payments.status}</span></div>

            </div>
        )
    }
}
export default FinancialStatusGraphWidgetItem