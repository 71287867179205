import React from 'react'
import History from '../../../History'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import InputComponent from '../../utils/inputComponent'
import CONSTANTS from '../../constants/dashboard-constants'
import CommonUtils from '../../../utils/CommonUtils'
import ErrorComponent from '../../utils/errorComponent'
import { ScrollToTop } from '../../../utils/Scroll'
import AuthenticatedComponent from '../../../ApplicationBase/AuthenticatedComponent'

class ChangeServiceRequestComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            ApplicantName: '',
            ApplicationNumber: '',
            ServicesType: '',
            ApplicationsStatus: '',
            mobileNo: '',
            showFileFrame: false,
            fileFrame: null,
            email: '',
            successComponent: null,
            success: false,
            errors: [],
            temsAccept: false,
            ticketNo: '',
            acceptClick: false,
            requiredDocumentsArray: []
        }
        this.changeListener = this.changeListener.bind(this)
        this.submit = this.submit.bind(this)
        this.cancle = this.cancle.bind(this)
        this.handleFiles = this.handleFiles.bind(this)
        this.viewFile = this.viewFile.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
        this.prepareApplicationsArray = this.prepareApplicationsArray.bind(this)
        this.setApplicationsList = this.setApplicationsList.bind(this)
        this.getAvailableApplications = this.getAvailableApplications.bind(this)
        this.prepareRequiredDocumentsArray = this.prepareRequiredDocumentsArray.bind(this)
        this.setRequiredDocumentsList = this.setRequiredDocumentsList.bind(this)
        this.getRequiredDocuments = this.getRequiredDocuments.bind(this)
        this.acceptClick = this.acceptClick.bind(this)
        this.uploadDocument = this.uploadDocument.bind(this)
        this.deleteSuccess = this.deleteSuccess.bind(this)
        this.viewSuccess = this.viewSuccess.bind(this)
        this.closeShowFilePopup = this.closeShowFilePopup.bind(this)
        this.validateDocumentsCount = this.validateDocumentsCount.bind(this)
        this.viewTicket = this.viewTicket.bind(this)
        this.requestChangeService = this.requestChangeService.bind(this)
        this.requestCompleteService = this.requestCompleteService.bind(this)
    }

    componentDidMount() {
        ScrollToTop()
        this.getAvailableApplications()
        var ticketIndex = this.props.location.state && this.props.location.state.ticketIndex ? parseInt(this.props.location.state.ticketIndex) : 0
        this.setState({ ticketIndex: ticketIndex, ApplicantName: JSON.parse(localStorage.dashboard).payload.name, mobileNo: JSON.parse(localStorage.dashboard).payload.primaryMobileNo, email: JSON.parse(localStorage.dashboard).payload.email })
    }

    // /*Applications API*/
    getAvailableApplications() {
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.changeServiceTicketsListingEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplicationsList).catch(this.loadFailed)
        }
    }

    prepareApplicationsArray(response) {
        if (response.success && response.payload[0]) {
            this.setState({
                applicationPayload: response.payload[0],
                applicationsPayload: response.payload,
                ApplicationNumber: response.payload[0].applicationNo,
                ServicesType: response.payload[0].service,
                ApplicationsStatus: response.payload[0].statusName,
            })
            if (response.payload[0].canCreateRequest) {
                this.requestChangeService()
            } else {
                this.getRequiredDocuments(response.payload[0].changeServiceTickets[this.state.ticketIndex].ticket)
            }
        }
    }

    setApplicationsList(response) {
        if (response.success) {
            this.prepareApplicationsArray(response)
        }
    }
    /*Applications API*/

    /*Required Documents API*/
    getRequiredDocuments(ticket) {
        this.setState({ ticketNo: ticket })
        fetch(CONSTANTS.changeServiceRequiredDocumentListEndpoint + "/" + ticket + "?local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.setRequiredDocumentsList).catch(this.loadFailed)
    }

    prepareRequiredDocumentsArray(response) {
        var documentsList = []
        response.payload.map((documentList, index) => {
            documentsList.push(<RequiredFile key={index} documentList={documentList} handleFiles={this.handleFiles} viewFile={this.viewFile} deleteFile={this.deleteFile} />)
        })
        this.setState({ documentsResponse: response, requiredDocumentsArray: documentsList })
    }

    setRequiredDocumentsList(response) {
        if (response.success) {
            this.prepareRequiredDocumentsArray(response)
        }
    }
    /*Required Documents API*/

    loadFailed(response) {
        console.log(response)
    }

    changeListener(e, isValid) {
        const { name, value } = e.target;
        if (isValid) { this.removeError(name) } else if (e.target.required) { this.addError(name) }
        this.setState({ [name]: value })
    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    submit() {
        if (this.state.temsAccept) {
            if (this.validateDocumentsCount()) {
                this.requestCompleteService()
            } else {
                this.showError(translate("requiredErrorMessage"))
            }
        } else {
            this.showError(translate('acceptterms'))
        }
    }

    viewTicket(ticket) {
        fetch(CONSTANTS.changeServiceViewTicketEndpoint + "/" + ticket + "?local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then((response) => {
            this.getRequiredDocuments(response.payload.ticket)
            this.setState({
                applicationPayload: response,
                ApplicationNumber: response.payload.applicationNo,
                ServicesType: response.payload.serviceType,
                Ticket: response.payload.ticket,
                ApplicationsStatus: response.payload.applicationStatus,
            })
        }).catch();
    }

    requestChangeService() {
        var data = new URLSearchParams()
        data.append('service', this.state.applicationPayload.serviceTypeId)
        data.append('ApplicationNo', this.state.applicationPayload.applicationNo)
        data.append('ReasonId', 1)
        data.append('email', this.state.applicationPayload.email)
        data.append('mobileNo', this.state.applicationPayload.mobile)
        fetch(CONSTANTS.changeServiceSubmitEndpoint + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': CONSTANTS.authToken
            },
        }).then(response => response.json()).then((response) => {
            if (response.success) {
                this.getRequiredDocuments(response.ticketNo)
                // toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        }).catch(this.loadFailed)
    }

    requestCompleteService() {
        fetch(CONSTANTS.changeServiceCompleteEndpoint + "?local=" + this.context.state.siteLang + "&ticketNo=" + this.state.ticketNo, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': CONSTANTS.authToken
            },
        }).then(response => response.json()).then((response) => {
            if (response.success) {
                this.setState({ success: true, successComponent: <SuccessComponent message={response.message} ticket={this.state.ticketNo} /> })
            } else {
                toast.error(response.message)
            }
        }).catch(this.loadFailed)
    }

    validateDocumentsCount() {
        var isValid = true
        this.state.documentsResponse.payload.map((document) => {
            if (document.addedCount === 0) {
                isValid = false
            }
        })
        return isValid
    }

    cancle() {
        History.push('/customerDashboard')
    }

    handleFiles(e) {
        this.setState({ error: null })
        let file = e.currentTarget.files[0];
        let id = e.currentTarget.id
        if (CommonUtils.isValidFileType(file) && CommonUtils.isValidFileSize(file)) {
            this.uploadDocument(id, file)
        } else {
            toast.error(translate('fileSize'))
        }
        e.currentTarget.value = null
    }

    viewFile(e) {
        fetch(CONSTANTS.changeServiceViewDocumentEndpoint + "/" + this.state.ticketNo + "?id=" + e.currentTarget.id + "&local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.viewSuccess).catch(this.loadFailed);
    }

    viewSuccess(response) {
        if (response.success) {
            this.setState({ showFileFrame: !this.state.showFileFrame }, () => {
                this.setState({
                    fileFrame:
                        this.state.showFileFrame ? <div className="uploadPopup">
                            <button className="popupClose" onClick={this.closeShowFilePopup}>X</button>
                            <div className="popupShowFile">
                                {CommonUtils.getFileExtension(response.payload).toUpperCase() === "PNG"
                                    || CommonUtils.getFileExtension(response.payload).toUpperCase() === "JPG"
                                    || CommonUtils.getFileExtension(response.payload).toUpperCase() === "JPEG"
                                    ? <img src={response.payload} alt="View doc" />
                                    : <iframe src={response.payload} alt="" title="View doc" />}
                            </div>
                        </div> : null
                })
            })
        } else {
            toast.error(response.message)
        }
    }

    closeShowFilePopup() {
        this.setState({ showFileFrame: false, fileFrame: null })
    }

    deleteFile(e) {
        fetch(CONSTANTS.changeServiceDeleteDocumentEndpoint + "/" + this.state.ticketNo + "?attachmentId=" + e.currentTarget.id + "&local=" + this.context.state.siteLang, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.deleteSuccess).catch(this.loadFailed)
    }

    deleteSuccess(response) {
        if (response.success) {
            toast.success(response.message)
            this.getRequiredDocuments(this.state.ticketNo)
        } else {
            toast.error(response.message)
        }
    }

    closeCallback() {
        this.setState({ error: null })
    }

    acceptClick(e) {
        this.closeCallback()
        if (e.target.id === "accept") {
            this.setState({ errorMessage: "", acceptClick: !this.state.acceptClick, temsAccept: true }, () => { })
        }
        else {
            if (e.target.id === "acceptCB") {
                if (e.target.checked) {
                    this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false }, () => { })
                } else {
                    this.setState({ temsAccept: false })
                }
            } else {
                this.setState({ acceptClick: !this.state.acceptClick, temsAccept: false }, () => { })
            }
        }
    }

    uploadDocument(typeId, file) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('type', typeId)
        formData.append('local', this.context.state.siteLang)
        fetch(CONSTANTS.changeServiceUploadDocumentEndpoint + "/" + this.state.ticketNo + "?local=" + this.context.state.siteLang, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': CONSTANTS.authToken
            },
        }).then(response => response.json()).then((response) => {
            if (response.success) {
                toast.success(response.message)
                this.getRequiredDocuments(this.state.ticketNo)
            } else {
                toast.error(response.message)
            }
        }).catch(this.loadFailed);
    }

    showError(message) {
        this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.showFileFrame ? <div className="PopupBk"></div> : null}
                {this.state.fileFrame}
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item"><a href="/changeServiceTicketsListing">{translate('changeService-Request')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate("changeServiceRequest")}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row ticketNumberWrapper">
                                    <div className="col-sm-6">
                                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ticket.svg'} alt="ticket" />
                                        <span>{translate('ticket-number')} {this.state.ticketNo}</span>
                                    </div>
                                </div>
                            </div>
                            {this.state.acceptClick ? <div className="PopupBk"></div> : null}
                            {this.state.acceptClick ? <div className="acceptPop">
                                <button className="popupClose" onClick={this.acceptClick}>X</button>
                                <h2>{translate('TermsAndConditions')}</h2>
                                <p>{translate('eligibleterms')}</p>
                                <div className="col-md-12 acceptPopBtn">
                                    <div className="row">
                                        <button id="accept" onClick={this.acceptClick}>{translate('Agree')}</button>
                                    </div>
                                </div>
                            </div> : null}

                            {this.state.success ? this.state.successComponent : <div className="service_contant_wrapper formSection">
                                <div className="col-md-12">
                                    <h3><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/change_request_icon.svg'} alt="Change Request Icon" />{translate("changeServiceRequest")}</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <InputComponent inputProps={{ lable: translate('applicantName'), name: 'ApplicantName', disabled: true, isRequired: true, defaultValue: this.state.ApplicantName, placeHolder: this.context.state.siteLang === 'en' ? 'Applicant Name' : 'اسم مقدم الطلب', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('ApplicationNumber'), name: 'ApplicationNumber', disabled: true, isRequired: true, defaultValue: this.state.ApplicationNumber, placeHolder: this.context.state.siteLang === 'en' ? 'Application Number' : 'رقم الطلب', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('servicesType'), name: 'ServicesType', disabled: true, isRequired: true, defaultValue: this.state.ServicesType, placeHolder: this.context.state.siteLang === 'en' ? 'Service Type' : 'نوع الخدمة', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('ApplicationStatus'), name: 'ApplicationsStatus', disabled: true, isRequired: true, defaultValue: this.state.ApplicationsStatus, placeHolder: this.context.state.siteLang === 'en' ? 'Application Status' : 'حالة الطلب', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('mobileNo'), name: 'mobileNo', disabled: true, isRequired: true, defaultValue: this.state.mobileNo, placeHolder: this.context.state.siteLang === 'en' ? 'Mobile Number' : 'رقم الموبايل', type: 'text', changeListener: this.changeListener }} />
                                        <InputComponent inputProps={{ lable: translate('email'), name: 'email', disabled: true, isRequired: true, defaultValue: this.state.email, placeHolder: this.context.state.siteLang === 'en' ? "Email" : 'البريد الالكتروني', type: 'email', changeListener: this.changeListener }} />
                                        {this.state.requiredDocumentsArray}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 formSectionBtn">
                                            <hr></hr>
                                            <div className="customCheckBox">
                                                <input name='temsAccept' checked={this.state.temsAccept} onChange={this.acceptClick} type="checkbox" id="acceptCB" />
                                                <label htmlFor="acceptCB">{translate('IAcceptingThe')}</label>
                                            </div>
                                            <a href="/CustomerDashboard"><button>{translate('cancle')}</button></a>
                                            <button onClick={this.submit}>{translate('submit')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    {this.state.error}
                </div>
            </div >
        );
    }
}

function SuccessComponent(props) {
    return (
        <div className="service_contant_wrapper formSection">
            <div className="col-md-12">
                <h3><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/change_request_icon.svg'} alt="Change Request Icon" />{translate("changeServiceRequest")}</h3>
            </div>
            <div className="col-md-12">
                <div className="serviceRequestMsgBox">
                    <p>{props.message ? props.message : ''}<span></span></p>
                    <a href="/CustomerDashboard"><button>{translate('back')}</button></a>
                </div>
            </div>
        </div>
    )
}

function attachments(attachments, viewFile, deleteFile) {
    var attachmentsArray = []
    attachments.map((attachment, index) => {
        attachmentsArray.push(<FileComponent key={index} attachment={attachment} viewFile={viewFile} deleteFile={deleteFile} />)
    })
    return attachmentsArray
}

function RequiredFile(props) {
    return (
        <div className="col-md-12 uploadFile uploadFW">
            <h2>{props.documentList.name}<i className="redStar">*</i></h2>
            <div className="row">
                <div className="col-md-5">
                    <input type="file" id={props.documentList.id} onChange={props.handleFiles}></input>
                    <label htmlFor="chooseyourfile">{translate('choseFile')}</label>
                </div>
                <div className="col-md-7">
                    {attachments(props.documentList.attachments, props.viewFile, props.deleteFile)}
                </div>
            </div>
        </div>)
}

function FileComponent(props) {
    return (
        < div >
            <span>{props.attachment && props.attachment.name}</span>
            <span className="uploadFWB">
                <button id={props.attachment.id} onClick={props.viewFile}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/icon_eye.svg'} alt="edit" /> {translate('view')}</button>
                <button id={props.attachment.id} onClick={props.deleteFile}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete.svg'} alt="add" /> {translate('delete')}</button>
            </span>
        </div >
    )
}
export default AuthenticatedComponent(ChangeServiceRequestComponent)