// import React from "react";
// import './login.css';
// import { Redirect } from 'react-router-dom';
// import queryString from 'query-string';
// import translate from '../../Providers/i18n/Translate'
// import { AppContext, AppContextProvider } from '../../Providers/context'
// import CommonUtils from "../../utils/CommonUtils";
// import { AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

// class AdminDashboardComponent extends React.Component {
//     static contextType = AppContext
//     componentDidMount() {
//         CommonUtils.getLocalParameter("/eservices", this.context.state.siteLang, this.props.location.search);
//     }

//     render() {
//             return (
//                 <> 
//                         <div className="container-fluid appWrapper">
//                             <div className="container">

//                                 {/* QUICK SERVICEC */}
//                                 <div className="row">
//                                     <div className="col-md-12 loginQuickServicesWrapper customerServicesWidget">
//                                         <div className="widgetWrapper">
//                                             <div className="widgetHead">
//                                                 <span><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Services_icon.svg" alt="customer" /></span>
//                                                 <span>{translate('QuickServices')}</span>
//                                             </div>
//                                             <div className="widgetContant">
//                                                 <div className="widgetServiceBox">
//                                                     <a href="/housingApplicationStatus">
//                                                         <div className="widgetService_icon">
//                                                             <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ApplicationStatus.svg" alt="customer" />
//                                                         </div>
//                                                         <div className="widgetServiceName">
//                                                             {translate('GetHousingApplicationStatus')}
//                                                         </div>
//                                                     </a>
//                                                 </div>
//                                                 <div className="widgetServiceBox">
//                                                     <a href="/maintenanceRequestStatusForReadyHouse">
//                                                         <div className="widgetService_icon">
//                                                             <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/maintenance_grant.svg" alt="customer" />
//                                                         </div>
//                                                         <div className="widgetServiceName">
//                                                             {translate('GetMaintenanceRequestStatusForReadyHouse')}
//                                                         </div>
//                                                     </a>
//                                                 </div>
//                                                 <div className="widgetServiceBox">
//                                                     <a href="/paymentsStatus">
//                                                         <div className="widgetService_icon">
//                                                             <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/FinancialStatus.svg" alt="customer" />
//                                                         </div>
//                                                         <div className="widgetServiceName">
//                                                             {translate('GetPaymentsStatus')}
//                                                         </div>
//                                                     </a>
//                                                 </div>
//                                                 <div className="widgetServiceBox">
//                                                     <a href="/toWhoItMayConcern">
//                                                         <div className="widgetService_icon">
//                                                             <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/to_who_it_may_concern_icon.svg" alt="customer" />
//                                                         </div>
//                                                         <div className="widgetServiceName">
//                                                             {translate('to-whom-lbl')}
//                                                         </div>
//                                                     </a>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>
//             </>
//             )
//     }
// }
// export default AdminDashboardComponent;