import React from 'react';
import { Beforeunload } from 'react-beforeunload';
import History from './History';
import { Router, Switch, Route } from 'react-router-dom';
import Header from './ApplicationBase/Header/Header';
import Nav from './ApplicationBase/Header/Nav';
import Utils from './Customer/utils/customerModuleUtilities';
import Footer from "./ApplicationBase/Footer/FooterComponent";
import ContractorLogin from './ApplicationBase/Login/ContractorLoginComponent';
import LoginHomeComponent from './ApplicationBase/Login/LoginHomeComponent';
import AdminLoginComponent from './ApplicationBase/Login/AdminLoginComponent';
import AdminDashboard from './ApplicationBase/Login/AdminDashboardComponent';
import CustomerLogin from './ApplicationBase/Login/CustomerLoginComponent';
import AuthenticatedApp from './ApplicationBase/AuthenticatedApp';
import Contractor from './Contractor/ContractorDashboard/ContractorDashboardComponent';
import ProjectsList from './Contractor/Common/ProjectsListComponent';
import ProjectPaymentsDetail from './Contractor/ContractorProjectPayments/ProjectPaymentsDetailComponent';
import ContractorProfile from './Contractor/ContractorProfile/ContractorProfileComponent';
import OpenNewProjectFileDetail from './Contractor/OpenNewProjectFile/OpenNewProjectFileDetailComponent';
import RetentionPaymentDetail from './Contractor/RetentionPayment/RetentionPaymentDetailComponent';
import DocumentVerificationComponent from './DocumentVerification/DocumentVerificationComponent';
import QRDocumentVerificationComponent from './DocumentVerification/QRDocumentVerificationComponent';
// sign pdf using uaepass
import PdfListComponent from './Customer/UaePassSignature/PdfList/PdfListComponent';
import PdfViewComponent from './Customer/UaePassSignature/ViewPdf/PdfViewComponent';
import SignPdfComponent from './Customer/UaePassSignature/SignPdf/SignPdfComponent';
/**Customer Components */
import Loancalculator from "./Customer/loancalculator/loancalculator-service-component";
import ConstructionCostCalculatorService from "./Customer/constructioncostcalculator/constructioncostcalculator-service-component";
import ApplicationStatusComponent from "./Customer/applicationstatus/application-status-component";
// import ApplicationStatusFinancialDetails from "./Customer/applicationstatus/application-status-financial-details";
// import ApplicationStatusFinancialMessage from "./Customer/applicationstatus/application-status-financial-message";
// import ApplicationStatusPaymentSuccess from "./Customer/applicationstatus/application-status-payment-success";
import AwarenessComponent from "./Customer/awareness/awarnesses-component";
import UserProfile from "./Customer/dashboard/userprofile/user-profile-component";
import HouseBookingServiceComponent from "./Customer/housebookings/housebooking-service-component";
import TypicalVillasServiceComponent from "./Customer/typicalvillas/typicalvillas_service_component";
import TypicalVillasSubmitComponent from "./Customer/typicalvillas/typicalvillas_submit";
import TypicalVillasDetailComponent from "./Customer/typicalvillas/typicalvillas_detail";
import TypicalVillasReviewComponent from "./Customer/typicalvillas/typicalvillas_review";
import HouseDetails from "./Customer/housebookings/house-details";
import GroupDetails from "./Customer/housebookings/group-details";
import ConfirmPayment from "./Customer/housebookings/confirm-payment";
import MyBooking from "./Customer/housebookings/my-booking";
import Search from "./Customer/search/search-component";
import HousingRequestServiceComponent from "./Customer/housingrequests/housingrequest-service-component";
import NewHousingRequestComponent from "./Customer/housingsteps/NewHousingRequestComponent";
import TowhomServicesComponent from "./Customer/towhomitmayconcern/towhom-service-component";
import TowhomServicesComponentNew from "./Customer/towhomitmayconcern/toWhomComponentNew";
import Dashboard from "./Customer/dashboard/dashboard-home/dashboard-component";
import HousingRequestSteps from "./Customer/housingsteps/NewHousingRequestComponent";
import MaintenanceRequests from "./Customer/maintenancerequests/maintenance-requests-component";
import MaintenanceService from "./Customer/maintenanceservice/maintenance-service-component";
import EarlySettlement from "./Customer/earlysettlement/early-settlement-component";
import MaintenanceInsuranceComponent from "./Customer/maintenanceinsurance/maintenance-insurance-details-component";
import OverdueComponent from "./Customer/overduepayments/overduepayment-component";
import PrintOpenNewProjectFile from './Contractor/Common/PrintOpenNewProjectFileComponent';
import ViewBOQSummaryComponent from './Contractor/Common/ViewBOQSummaryComponent';

import ChangeServiceRequestComponent from "./Customer/complementaryservices/changeservicerequest/changeservicerequest-component";
import CancelProgresHousingServiceComponentComponent from "./Customer/complementaryservices/cancelprogresshousingservice/cancelprogresshousingservice-component";
import ChangeServiceTicketsListingComponent from "./Customer/complementaryservices/changeservicerequest/change-service-tickets-listing-component";
import CancelServiceTicketsListingComponent from "./Customer/complementaryservices/cancelprogresshousingservice/cancel-service-tickets-listing-component";
import AllowedToCancelApplicationsListingComponent from "./Customer/complementaryservices/cancelprogresshousingservice/allowed-to-cancel-applications-list";
import GetHousingApplicationStatusComponent from "./InformationServices/GetHousingApplicationStatusComponent";
import GetMaintenanceRequestStatusForReadyHouse from "./InformationServices/GetMaintenanceRequestStatusForReadyHouseComponent";
import GetPaymentsStatus from "./InformationServices/GetPaymentsStatusComponent";
import HappinessMeterComponent from "./HappinessMeter/HappinessMeterComponent";
import HappinessMeterPublic from "./HappinessMeter/HappinessMeter";

import ComplaintComponent from "./Customer/complementaryservices/complaint/complaint-component";
import MaterialsDiscount from "./Customer/complementaryservices/materialsdiscount/materialsdiscount-component";
import PercentageOfCompletion from "./Customer/complementaryservices/percentageofcompletion/percentagecompletion-component";

// import ConstructionLoanDisbursement from "./Customer/construction_loan_disbursement/construction-loan-disbursement-component";
import ConstructionLoanDisbursementTicketsListing from "./Customer/construction_loan_disbursement/construction-loan-disbursement-listing";
import StepConstructionLoanDisbursement from "./Customer/construction_loan_disbursement/StepsConstructionLoanDisbursement";
// import SigninDocumentComponentList from "./Customer/construction_loan_disbursement/SigninDocumentComponentList";
import StepThree from "./Customer/construction_loan_disbursement/StepThree";
// import ConstructionDisbursementPayment from "./Customer/construction_loan_disbursement/SigninDocumentComponentPayment";
import StepFour from "./Customer/construction_loan_disbursement/StepFour";
// import ConstructionDisbursementDetails from "./Customer/construction_loan_disbursement/SigninDocumentComponentDetails";
// sign pdf using uaepass
import viewDoc from './Customer/construction_loan_disbursement/viewDocComponent';
import signDoc from './Customer/construction_loan_disbursement/signDocComponent';
import LiveChatComponent from './Chatbot/LiveChatComponent'
import TypicalVillasApplicationsListComponent from './Customer/typicalvillas/typical_villas_applications'

/**Chatbot Components */
import ChatbotComponent from "./Chatbot/ChatbotComponent";
import { I18nProvider } from './Providers/i18n';
import { AppContext } from './Providers/context';
import DashboardLoader from './Contractor/Common/ContentLoader';
import ContractorContentLoader from './Contractor/Common/ContractorContentLoader';
import ReactGA from 'react-ga';
// Page Not Found
import PageNotFoundComponent from "./Pagenotfound/PageNotFoundComponent";
import EservicesHealthComponent from "./Customer/health/health";
import UnderMaintenanceComponent from "./ApplicationBase/UnderMaintenance/UnderMaintenanceComponent";
import PendingPaymentsApproval from "./Customer/contractorpaymentrequest/contractor-payment-request-component";
import UploadDocuments from "./InformationServices/PublicPageDocumentsUploadComponent";


class App extends React.Component {
  static contextType = AppContext

  constructor() {
    super()
    this.state = {
      isUnderMaintenance: false,
      appTheme: localStorage.getItem("appTheme") ? localStorage.getItem("appTheme") : "theme-normal"
    }
    this.changeTheme = this.changeTheme.bind(this)
    this.isUnderMaintenance = this.isUnderMaintenance.bind(this)
  }
  changeTheme(theme) {
    localStorage.setItem("appTheme", theme)
    this.setState({ appTheme: localStorage.getItem("appTheme") })    
  }

  componentDidMount() {
    this.isUnderMaintenance()
    Utils.getServiceCodes()
    // console.log("service 210 HM code is " + Utils.getHappinessMeterServiceCode("210"))
    // console.log("service id 1c9b0970-46e5-48f4-a6e8-fd346f359a2e HM code is " + Utils.getHappinessMeterServiceCode("1c9b0970-46e5-48f4-a6e8-fd346f359a2e"))
    // console.log("service id null HM code is " + Utils.getHappinessMeterServiceCode(null))

    let prevScrollpos = window.pageYOffset;

        window.onscroll = function () {
            const currentScrollPos = window.pageYOffset;
            const footer = document.getElementById("footer");
            const subHeader = document.getElementById("header");

            if (prevScrollpos >= currentScrollPos) {
                if (subHeader.classList.contains('headerHidden')) {
                    subHeader.classList.remove('headerHidden');
                }
                if (footer.classList.contains('footerHidden')) {
                    footer.classList.remove('footerHidden');
                }
                
            } else {
                if (!subHeader.classList.contains('headerHidden')) {
                    subHeader.classList.add('headerHidden');
                }
                if (!footer.classList.contains('footerHidden')) {
                    footer.classList.add('footerHidden');
                }
            }
            prevScrollpos = currentScrollPos;

        }
  }

  isUnderMaintenance() {
    fetch("https://mbrhecdnstorageaccount.blob.core.windows.net/eservices-portal/under-maintenance.json", {
    }).then(response => response.json()).then((response) => {
      this.setState({ isUnderMaintenance: response.isUnderMaintenance })
      console.log(response.isUnderMaintenance)
    }).catch((error) => {
      console.log(error)
    })
  }

  render() {
    ReactGA.initialize('G-25PTNY6BQ4');
    History.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname)
    }
    );

    const { state } = this.context
    return (
      <div className={"app " + this.state.appTheme}>
        <Beforeunload onBeforeunload={() => {
          window.$zopim.livechat.window.hide()
        }} />
        <Router history={History}>
          <I18nProvider locale={state.siteLang}>
          {location.pathname==='/muftah' ? <Route path='/muftah' component={ChatbotComponent} /> :<div>
            <Header />
            {this.state.isUnderMaintenance}
            {this.state.isUnderMaintenance ? <Route component={UnderMaintenanceComponent} /> :
              <Nav appRef={this} />}
            {this.state.isUnderMaintenance ? null : <Switch>
              <Route exact path='/' component={AuthenticatedApp} />
              <Route path='/dashboardloader' component={DashboardLoader} />
              <Route path='/happinessMeter' component={HappinessMeterComponent} />
              <Route path='/HappinessMeterQR' component={HappinessMeterPublic} />
              <Route path='/ContractorContentLoader' component={ContractorContentLoader} />
              <Route path='/eservices' component={LoginHomeComponent} />
              <Route path='/Admin-login' component={AdminLoginComponent} />
              <Route path='/Admin-dashboard' component={AdminDashboard} />
              <Route path='/contractorLogin' component={ContractorLogin} />
              <Route path='/customerLogin' component={CustomerLogin} />
              <Route path='/housingrequestnew' component={NewHousingRequestComponent} />
              {/* contractor dashboard*/}
              {/*<Route path='/contractorDashboard' component={Contractor} />*/}
              <Route path="/contractorDashboard" render={props => <Contractor props={props} refresh={this.refresh} />} />
              {/* contractor project Payments*/}
              <Route path='/projectsList' component={ProjectsList} />
              <Route path='/projectPaymentsDetail' component={ProjectPaymentsDetail} />
              {/* open new project file*/}
              <Route path='/openNewProjectFileDetail' component={OpenNewProjectFileDetail} />
              {/* retention payment*/}
              <Route path='/retentionPaymentDetail' component={RetentionPaymentDetail} />
              {/* contractor profile*/}
              <Route path='/contractorProfile' component={ContractorProfile} />
              {/**Customer Routes */}
              <Route path='/awarenesses' component={AwarenessComponent} />
              <Route path='/loancalculator' component={Loancalculator} />
              <Route path='/constructionCostCalculator' component={ConstructionCostCalculatorService} />
              <Route path="/customerDashboard" render={props => <Dashboard props={props} refresh={this.refresh} />} />
              <Route path='/userProfile' component={UserProfile} />
              <Route path='/houseBooking' component={HouseBookingServiceComponent} />
              <Route path='/typicalVillas' component={TypicalVillasServiceComponent} />
              <Route path='/typicalVillasSubmit' component={TypicalVillasSubmitComponent} />
              <Route path='/typicalVillasDetail' component={TypicalVillasDetailComponent} />
              <Route path='/typicalVillasReview' component={TypicalVillasReviewComponent} />
              <Route path='/houseDetails' component={HouseDetails} />
              <Route path='/groupDetails' component={GroupDetails} />
              <Route path='/confirmPayment' component={ConfirmPayment} />
              <Route path='/myBooking' component={MyBooking} />
              <Route path='/housingRequests' component={HousingRequestServiceComponent} />
              {/* <Route path='/applicationFinancialDetails' component={ApplicationStatusFinancialDetails} /> */}
              {/* <Route path='/applicationFinancialMessage' component={ApplicationStatusFinancialMessage} /> */}
              {/* <Route path='/applicationFinancialPayment' component={ApplicationStatusPaymentSuccess} /> */}

              {/* <Route path='/constructionLoanDisbursement' component={ConstructionLoanDisbursement} /> */}
              <Route path='/constructionLoanDisbursementTickets' component={ConstructionLoanDisbursementTicketsListing} />
              <Route path='/stepConstructionLoanDisbursement' component={StepConstructionLoanDisbursement} />
              <Route path='/stepThree' component={StepThree} />
              <Route path='/stepFour' component={StepFour} />
              {/* <Route path='/constructionDisbursementDetails' component={ConstructionDisbursementDetails} /> */}
              {/* ConstructionDisbursement UAE pass signature*/}
              <Route path='/viewDocument' component={viewDoc} />
              <Route path='/signDocument' component={signDoc} />

              {/* <Route path='/ticketsListing' component={TicketsListing} /> */}
              <Route path='/newhousingRequest' component={NewHousingRequestComponent} />
              <Route path='/housingRequestSteps' component={HousingRequestSteps} />
              <Route path='/toWhoItMayConcern' component={TowhomServicesComponentNew} />
              {/* <Route path='/toWhoItMayConcernManual' component={TowhomServicesComponent} /> */}
              <Route path='/toWhomItMayConcernCertificate' component={TowhomServicesComponentNew} />
              <Route path='/applicationStatus' component={ApplicationStatusComponent} />
              <Route path='/maintenancerequests' component={MaintenanceRequests} />
              <Route path='/maintenanceservice' component={MaintenanceService} />
              <Route path='/earlysettlement' component={EarlySettlement} />
              <Route path='/maintenanceInsurance' component={MaintenanceInsuranceComponent} />
              <Route path='/overduePayment' component={OverdueComponent} />
              <Route path='/documentVerification' component={DocumentVerificationComponent} />
              <Route path='/documentVerificationCertificate' component={QRDocumentVerificationComponent} />
              <Route path='/printOpenNewProjectFile' component={PrintOpenNewProjectFile} />
              <Route path='/viewBOQSummary' component={ViewBOQSummaryComponent} />
              <Route path='/changeServiceRequest' component={ChangeServiceRequestComponent} />
              <Route path='/cancelProgresServiceRequest' component={CancelProgresHousingServiceComponentComponent} />
              <Route path='/housingApplicationStatus' component={GetHousingApplicationStatusComponent} />
              <Route path='/maintenanceRequestStatusForReadyHouse' component={GetMaintenanceRequestStatusForReadyHouse} />
              <Route path='/paymentsStatus' component={GetPaymentsStatus} />
              <Route path='/cancelProgressServiceRequest' component={CancelProgresHousingServiceComponentComponent} />
              <Route path='/changeServiceTicketsListing' component={ChangeServiceTicketsListingComponent} />
              <Route path='/cancelServiceTicketsListing' component={CancelServiceTicketsListingComponent} />
              <Route path='/allowedToCancelApplicationsListing' component={AllowedToCancelApplicationsListingComponent} />
              
              <Route path='/complaint' component={ComplaintComponent} />
              <Route path='/materialsDiscount' component={MaterialsDiscount} />
              <Route path='/percentageOfCompletion' component={PercentageOfCompletion} />

              <Route path='/searchResult' component={Search} />
              {/* <Route path='/feedback' component={Feedback} /> */}
              {/* customer UAE pass signature My Inbox*/}
              <Route path='/pdfList' component={PdfListComponent} />
              <Route path='/viewPdf' component={PdfViewComponent} />
              <Route path='/signPdf' component={SignPdfComponent} />
              {/**Chatbot Routes */}
              <Route path='/chatbotWeb' component={ChatbotComponent} />
              <Route path='/liveChat' component={LiveChatComponent} />
              <Route path='/villaApplications' component={TypicalVillasApplicationsListComponent} />
              <Route path='/eservicesHealth' component={EservicesHealthComponent} />
              <Route path='/pendingPaymentsApproval' component={PendingPaymentsApproval} />
              <Route path='/attachDocuments' component={UploadDocuments} />
              {/* <Route path='/underMaintenance' component={UnderMaintenanceComponent} /> */}
              {/* 404 PageNotFound always end of all other Routes (as a fallback if no Route path match)*/}
              <Route component={PageNotFoundComponent} />
            </Switch>
            }
            {/* {this.state.isUnderMaintenance ? null : <Footer />} */}
            <Footer />
            </div>            }
          </I18nProvider>
        </Router>
      </div>
    );
  }
}
export default App;