import React from 'react'
import PieChart from '../../utils/piechart'
import translate from '../../../Providers/i18n/Translate'

function ContractorPaymentRequestsWidgetItem(props) {
    var secondPeogress = 100 - props.item.completedPercentage
    return (
        <div className="widgetApplicationStatusWrapper">
            <div className="widgetApplicationStatusInWrapper">
                <div className="col">
                    <div className="row">
                        <div className="col-sm-4">
                            <PieChart progressColor="#408d45" percentTextColor="#3c4551" data={[{ value: props.item.completedPercentage }, { value: secondPeogress }]} />
                        </div>
                        <div className="col-sm-8">
                            <div>
                                <span>{props.item.relativeDate}</span>
                            </div>
                            <div>
                                <p>{translate('contractorName')} : <span>{props.item.contractorName}</span></p>
                                <p>{translate('amount')} <span>{props.item.totalAmount}</span></p>
                            </div>
                            <button name={props.item.id} onClick={props.rejectPayment}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/cancel.svg'} alt="Reject" /> {translate('Reject')} </button>
                            <button name={props.item.id} onClick={props.acceptPayment}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/odone.svg'} alt="Accept" /> {translate('Accept')} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContractorPaymentRequestsWidgetItem