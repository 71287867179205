import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9'
import 'core-js/es/map';
import 'core-js/es/set';
import 'react-app-polyfill/stable';
import 'raf/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { AppContextProvider } from './Providers/context'

ReactDOM.render(
    <AppContextProvider>
        <App />
    </AppContextProvider>, document.getElementById('app'));
