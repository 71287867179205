class CustomerModuleUtils {
    getStatusLableColor(status) {
        var cls = ''
        if (status === 0) {
            cls = "selectedcolor-0"
        }
        else if (status === 10) {
            cls = "selectedcolor-10"
        } else if (status === 20) {
            cls = "selectedcolor-20"
        } else if (status === 30) {
            cls = "selectedcolor-30"
        } else if (status === 50) {
            cls = "selectedcolor-50"
        } else if (status === 60) {
            cls = "selectedcolor-60"
        } else {
            cls = "selectedcolor-0"
        }
        return cls
    }

    getServiceCodes() {
        fetch("https://mbrhecdnstorageaccount.blob.core.windows.net/eservices-portal/services.json", {
        }).then(response => response.json()).then((jsonData) => {
            localStorage.setItem("HMcodes", JSON.stringify(jsonData))
        }).catch((error) => {
            return null
        })
    }

    getHappinessMeterServiceCode(serviceId) {
        // var serviceCode = serviceId
        try {
            JSON.parse(localStorage.getItem("HMcodes")).services.map((service) => {
                if (serviceId != null && (service.id.toString() === serviceId.toString() || service.code.toString() === serviceId.toString())) {
                    serviceId = service.meter_id
                }
            })
        } catch (e) {
            console.log(e + "exception in services array from CDN")
            // serviceCode = serviceId
        }
        // console.log("For happiness meter service id is---" + serviceId)
        // var serviceCode = '0'
        // switch (serviceId) {
        //     //Apply for housing grant
        //     case '110':
        //         serviceCode = '5463'
        //         break;
        //     //Apply for apartment grant
        //     case '115':
        //         serviceCode = '5458'
        //         break;
        //     //"Apply for housing Maintenance, Addition or Replacement (partial / whole) grant\r\n\r\n"
        //     case '120':
        //         serviceCode = '5462'
        //         break;
        //     //Apply for Housing Replacement Grant
        //     case '125':
        //         serviceCode = '6563'
        //         break;
        //     //Apply for housing Land
        //     case '130':
        //         serviceCode = '5469'
        //         break;
        //     //Apply for Housing construction grant
        //     case '140':
        //         serviceCode = '5461'
        //         break;
        //     //Apply for housing construction loan
        //     case '210':
        //         serviceCode = '5473'
        //         break;
        //     //Apply for housing unit loan from local market"
        //     case '220':
        //         serviceCode = '5474'
        //         break;
        //     //Apply for housing Maintenance, Addition or Replacement (partial / whole) loan
        //     case '230':
        //         serviceCode = '5476'
        //         break;
        //     //Apply for Housing Replacement Loan
        //     case '235':
        //         serviceCode = '6564'
        //         break;
        //     //Apply for Housing loan
        //     case '240':
        //         serviceCode = '2588'
        //         break;
        //     //Apply for housing purchase
        //     case '260':
        //         serviceCode = '2602'
        //         break;
        //     //Apply for housing unit rent
        //     // case '270':
        //     //     serviceCode = '0'
        //     //     break;
        //     default:
        //         serviceCode = serviceId
        // }
        // console.log("For happiness meter service code is---" + serviceCode)
        return serviceId
    }

    // _requestLiabilityCertificate = 2574,
    // _nquireOnRemainingLoanAmount = 2575,
    // _payHousingLoanOverDues = 2576,
    // _requestEarlySettlementDiscount = 2579,
    // _requestToWhomItConcernsLetter = 2584,
    // _openingNewProjectFile = 2585,
    // _requestProjectFirstPayment = 2586,
    // _requestRetentionPayment = 2587,
    // _requestReadymadeHouseLoan = 2588,
    // _requestHouseMaintenanceAdditionLoan = 2589,
    // _requestHouseConstructionLoan = 2590,
    // _calculateHousingLoan = 2595,
    // _requestHousingLandGrant = 2596,
    // _requestGovernmentHouseGrant = 2597,
    // _requestMaintenance_AdditionGrant = 2598,
    // _requestToPurchaseGovernmentHouse = 2602,
    // _updateHousingApplicationRequest = 2603,
    // _requestProjectInterimPayment = 2663,
    // _requestProjectFinalPayment = 2664,
    // _readyHousingAllocationRequest = 2665,
    // _requestMaintenanceOfYearWarranty = 2669,
    // _requestContractSettlement = 2682,
    // _contractorPaymentDisplay = 3244,
    // _houseGranteeeComponents = 3246,
    // _guidenaceAndAwarnessForReadyHouses = 3248,
    // _question_SuggestionAndComplains = 3249,
    // _updatePersonalCustomersData = 3250,
    // _housingGuru = 3252,
    // _onlineNotificationWhenReadyFavoriateHouseAvailable = 3253
}

// //Apply for housing grant
// case '110':
//     serviceCode = '0'
//     break;
// //Apply for apartment grant
// case '115':
//     serviceCode = '0'
//     break;
// //"Apply for housing Maintenance, Addition or Replacement (partial / whole) grant\r\n\r\n"
// case '120':
//     serviceCode = '2598'
//     break;
// //Apply for housing Land
// case '130':
//     serviceCode = '2596'
//     break;
// //Apply for Housing construction grant
// case '140':
//     serviceCode = '0'
//     break;
// //Apply for housing construction loan
// case '210':
//     serviceCode = '2590'
//     break;
// //Apply for housing unit loan from local market"
// case '220':
//     serviceCode = '0'
//     break;
// //Apply for housing Maintenance, Addition or Replacement (partial / whole) loan
// case '230':
//     serviceCode = '2589'
//     break;
// //Apply for Housing loan
// case '240':
//     serviceCode = '2588'
//     break;
// //Apply for housing purchase
// case '260':
//     serviceCode = '2602'
//     break;
// //Apply for housing unit rent
// case '270':
//     serviceCode = '0'
//     break;
// default:
//     serviceCode = '0'

export default new CustomerModuleUtils()