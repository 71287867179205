import React from 'react'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'

class EservicesHealthComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper pageNotFound">
                                <span>Health Check</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default EservicesHealthComponent