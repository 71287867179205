import React from 'react'
import translate from '../../../Providers/i18n/Translate'
function NotificationsItem(props) {
    return (
        <div className="notification_box selectedcolor-70">
            <div className="notiTitle">
                <div className="notificationIcon">
                    <img src={props.notification.type === 70 ? 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg' : 'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bell2.svg'} alt='MBRHE' />
                </div>
                <div><span>{props.notification.title}</span></div>
            </div>
            <p>{props.notification.message}</p>
            <p>{props.notification.createdAt}</p>
            <p>{props.notification.since}</p>
            {props.notification.type === 70 ? <div className="notification_boxBtns">
                <button onClick={props.review}>{translate('Review')}</button>
                <button id={props.notification.id} onClick={props.delete}>{translate('Delete')}</button>
            </div> : null}
        </div>
    )
}

export default NotificationsItem