import React from 'react'
import NoData from '../../utils/nodata'
import translate from '../../../Providers/i18n/Translate'
import FinancialStatusWidgetItem from './financialstatusgraph-widget-item'
import ContrcatorPaymnetStore from '../contractorpaymentnew/contrcatorPaymnetStore'


class FinancialStatusWidgetGraph extends React.Component {
    constructor(props) {
        super()
        this.state = {
            financialStatusItem: null,
            financialSecondItem: '',
            paymentsresponse: ''
        }
        this.setFinancialStatus = this.setFinancialStatus.bind(this)
        this._onChange = this._onChange.bind(this);
    }

    componentDidMount() {
        ContrcatorPaymnetStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        ContrcatorPaymnetStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this.setProjectsState());
        if (this.state.isSuccess && this.state.paymentsresponse) {
            this.setFinancialStatus(this.state.paymentsresponse);
        }
    }

    setProjectsState() {
        return {
            paymentsresponse: ContrcatorPaymnetStore.response,
            isSuccess: ContrcatorPaymnetStore.isSuccess(),
        };
    }

    setFinancialStatus(response) {
        if (response.length > 0) {
            this.setState({ financialStatusItem: <FinancialStatusWidgetItem key={0} payload={response} index={0} /> })
        }
    }

    render() {
        return (
            <div className="col-md-12 financialStatusGraphWidgetWrapper">
                {this.state.financialStatusItem ?
                    <div className="financialStatusGraphWidgetWrapper">
                        <div className="widgetWrapper">
                            <div className="widgetHead">
                                <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/FinancialStatus.svg'} alt="Financial Status" /></span>
                                <span>{translate('theSatgesOfProject')}</span>
                            </div>
                            <div className="widgetContant">
                                <div className="widgetContentIn">
                                    {this.state.financialStatusItem != null ? this.state.financialStatusItem : <NoData message={translate('nodata')} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}</div>
        )
    }
}
export default FinancialStatusWidgetGraph