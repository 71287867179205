var BASE_URL = process.env.API_BASE_URL
export default {
  BASE_URL: BASE_URL,
  CUSTOMER_INBOX_LIST_URL: BASE_URL + 'UaePass/v1/DocumentUnSigned/List?local=',
  CUSTOMER_INBOX_LIST: 'CUSTOMER_INBOX',
  CATEGORY_LIST_URL: BASE_URL + 'UaePass/v1/Category/List?local=',
  CATEGORY_LIST: 'CATEGORY_LIST',
  UPLAOD_UNSIGNED_ATTACHMENT_URL: BASE_URL + 'UaePass/v1/DocumentUnSigned/attach?local=',
  UPLAOD_UNSIGNED_ATTACHMENT: 'UPLAOD_UNSIGNED_ATTACHMENT',
  DELETE_DOCUMENT_URL: BASE_URL + 'UaePass/v1/Document/delete?local=',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT'
}