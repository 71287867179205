var BASE_URL = process.env.API_BASE_URL
export default {
  BASE_URL:   BASE_URL,
  CONTRACTOR_PROJECT_PAYMENTS_URL:   BASE_URL + 'contractor/v1/dashboard/projects?statusId=',
  CONTRACTOR_PROJECT_PAYMENTS: 'CONTRACTOR_PROJECT_PAYMENTS',

  CONTRACTOR_ENTRY_STATUS_URL:   BASE_URL + '/contractor/v1/service-request/contractorentry?contractId=',
  CONTRACTOR_ENTRY_STATUS: 'CONTRACTOR_ENTRY_STATUS',

  CONTRACTOR_PROJECT_PAYMENTS_BREAK_DOWN_URL:   BASE_URL + 'contractor/v1/service-request/paymentheader?contractId=',
  CONTRACTOR_PROJECT_PAYMENTS_BREAK_DOWN: 'CONTRACTOR_PROJECT_PAYMENTS_BREAK_DOWN',

  CONTRACTOR_ADD_PAYMENT_HEADER_OWNER_URL:   BASE_URL +  'contractor/v1/service-request/AddpaymentheaderOwnerAmount?PaymentId=',
  CONTRACTOR_ADD_PAYMENT_HEADER_OWNER_A: 'CONTRACTOR_ADD_PAYMENT_HEADER_OWNER_A',

  CONTRACTOR_ADD_PAYMENT_HEADER_DETAILS_URL:   BASE_URL + 'contractor/v1/service-request/Addpaymentheaderdetails?local=',
  CONTRACTOR_ADD_PAYMENT_HEADER_DETAILS: 'CONTRACTOR_ADD_PAYMENT_HEADER_DETAILS',

  CONTRACTOR_GET_ALL_DOCUMENTS_URL:   BASE_URL + 'contractor/v1/service-request/documents?requestNo=',
  CONTRACTOR_GET_ALL_DOCUMENTS: 'CONTRACTOR_GET_ALL_DOCUMENTS',
  
  CONTRACTOR_UPLOAD_DOCUMENTS_URL:   BASE_URL + 'contractor/v1/service-request/attach?requestNo=',
  CONTRACTOR_UPLOAD_DOCUMENTS: 'CONTRACTOR_UPLOAD_DOCUMENTS',

  CONTRACTOR_VIEW_DOCUMENTS_URL:   BASE_URL + 'contractor/v1/service-request/attachment/view?requestNo=',
  CONTRACTOR_VIEW_DOCUMENTS: 'CONTRACTOR_VIEW_DOCUMENTS',

  CONTRACTOR_DELETE_DOCUMENTS_URL:   BASE_URL + 'contractor/v1/service-request/attachment/delete?requestNo=',
  CONTRACTOR_DELETE_DOCUMENTS: 'CONTRACTOR_DELETE_DOCUMENTS',

  CONTRACTOR_SUBMIT_STATUS_URL:   BASE_URL + 'contractor/v1/service-request/addcontractorentry?local=',
  CONTRACTOR_SUBMIT_STATUS: 'CONTRACTOR_SUBMIT_STATUS',
}