import AppDispatcher from '../../../Dispatcher'
import HousingRequestsConstants from './housing-requests-constants'

class HousingRequestsActions {
    fetchHousingRequest(response) {
        AppDispatcher.dispatch({ actionType: HousingRequestsConstants.HOUSINGREQUESTS, response: response })
    }

    deleteHousingRequest(response) {
        AppDispatcher.dispatch({ actionType: HousingRequestsConstants.DELETEHOUSINGREQUESTS, response: response })
    }
}
export default new HousingRequestsActions()