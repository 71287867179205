import ContractorPaymnetAction from './contractorPaymnetAction';
import ContractorPaymentConstant from './contractorPaymentConstant';
import request from 'reqwest';

class ContractorDashboardService {
  /** contractor payments */
  contractorPaymentsList(local) {
    return this.handleResponse(request({
      url: ContractorPaymentConstant.CUSTOMER_PAYMENT_DASHBOARD_URL + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem("accessToken")
      }
    }));
  }

  handleResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorPaymnetAction.contractorPayments(response);
        return true;
      });
  }
}

export default new ContractorDashboardService();