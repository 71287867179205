import React from "react"
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import StepFour from './StepFour'
import queryString from 'query-string'
import Loader from '../utils/LoaderBars'
import StepZilla from "react-stepzilla"
import 'react-datepicker/dist/react-datepicker.css'
import ErrorComponent from '../utils/errorComponent'
import { AppContext } from '../../Providers/context'
import { ToastContainer, toast } from 'react-toastify'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'

class StepsConstructionLoanDisbursement extends React.Component {
    static contextType = AppContext
    _isMounted = false
    constructor(props) {
        super(props)
        this.state = {
            ticketPayload: {
            },
            props: props,
            steps: [],
            errors: [],
            error: null,
            showLoading: false,
            postCallback: null,
            ticketNo: '',
            startAtStep: localStorage.startAtStep ? localStorage.getItem('startAtStep') : 0
        }
        this.addError = this.addError.bind(this)
        this.showError = this.showError.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
        this.postSuccess = this.postSuccess.bind(this)
        this.stopLoading = this.stopLoading.bind(this)
        this.removeError = this.removeError.bind(this)
        this.validateData = this.validateData.bind(this)
        this.startLoading = this.startLoading.bind(this)
        this.closeCallback = this.closeCallback.bind(this)
        this.getPaymentValues = this.getPaymentValues.bind(this)
        this.getTicketSuccess = this.getTicketSuccess.bind(this)
        this.getEpayConfirmation = this.getEpayConfirmation.bind(this)
    }

    componentDidMount() {
        this.getEpayConfirmation()
        this._isMounted = true
        if (localStorage.getItem("dTicketNo") || localStorage.ticketPayload) {
            var ticketNo = localStorage.getItem("dTicketNo") ? localStorage.getItem("dTicketNo") : JSON.parse(localStorage.getItem('ticketPayload')).ticketNo
            this.getTicket(ticketNo)
        } else {
            this.validateData()
            this.setState({
                steps: [
                    { name: translate('step1'), component: <StepOne reference={this} /> },
                    { name: translate('step2'), component: <StepTwo reference={this} /> },
                    { name: translate('step3'), component: <StepThree reference={this} /> },
                    { name: translate('step4'), component: <StepFour reference={this} /> }
                ]
            })
            this.updateTicketPayload("applicationNo", this.state.props.location.state.tickerPayload.applicationNo)
        }
    }

    getEpayConfirmation() {
        let url = this.props.location.search;
        if (url !== "") {
            let value = queryString.parse(url);
            this.setState({ paymentValues: value });
        }
    }

    getPaymentValues() {
        return this.state.paymentValues
    }

    startLoading() {
        this.setState({ showLoading: true })
    }

    stopLoading() {
        this.setState({ showLoading: false })
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getTicketPayload() {
        return this.state.ticketPayload
    }

    updateTicketPayload(name, value) {
        if (this._isMounted) {
            this.setState({ ticketPayload: { ...this.state.ticketPayload, [name]: value } }, () => {
                this.removeError(name)
            })
        }
    }

    reset() {
        if (this._isMounted) {
            this.setState({ error: null })
        }
    }

    postData(postCallback) {
        this.setState({ postCallback: postCallback.bind(this) })
        if (this.state.ticketPayload.requestStatusId !== 10) {
            this.validateData()
            if (this.state.errors.length <= 0) {
                this.setState({ showLoading: true }, () => {
                    fetch(CONSTANTS.postDisbursementDataEndpoint + "?local=" + this.context.state.siteLang, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                            'Authorization': CONSTANTS.authToken
                        },
                        body: new URLSearchParams(this.state.ticketPayload),
                    }).then(response => response.json()).then(this.postSuccess).catch(this.loadFailed)
                })
            } else {
                this.showError(translate('requiredErrorMessage'))
            }
        } else {
            this.state.postCallback()
        }
    }

    postSuccess(response) {
        this.setState({ showLoading: false }, () => {
            if (response.success) {
                if (response.ticketNo) {
                    localStorage.setItem("dTicketNo", response.ticketNo)
                    this.setState({ ticketNo: response.ticketNo }, () => {
                        this.updateTicketPayload("ticketNo", response.ticketNo)
                    })
                    this.getTicket(response.ticketNo)
                }
                toast.success(response.message)
                this.state.postCallback()
            } else {
                toast.error(response.message)
                this.loadFailed(response)
            }
        })
    }

    loadFailed(response) {
        if (response) {
            this.setState({ showLoading: false }, () => {
                this.showError(response.message)
            })
        }

    }

    addError(name) {
        if (this.state.errors.indexOf(name) === -1)
            this.state.errors.push(name)
    }

    removeError(name) {
        this.setState({ errors: this.state.errors.filter(x => x !== name) })
    }

    showError(message) {
        if (this._isMounted) {
            this.setState({ error: <ErrorComponent message={message} closeCalback={this.closeCallback} /> })
        }
    }

    closeCallback() {
        if (this._isMounted) {
            this.setState({ error: null })
        }
    }

    validateData() {
        if (this.state.ticketPayload) {
            this.state.ticketPayload.fundType && this.state.ticketPayload.fundType > 0 ? this.removeError('fundType') : this.addError('fundType')
            this.state.ticketPayload.mobileNo && this.state.ticketPayload.mobileNo.length > 0 ? this.removeError('mobileNo') : this.addError('mobileNo')
            this.state.ticketPayload.email && this.state.ticketPayload.email.length > 0 ? this.removeError('email') : this.addError('email')
            this.state.ticketPayload.area && this.state.ticketPayload.area > 0 ? this.removeError('area') : this.addError('area')
            this.state.ticketPayload.manucipalityNumber && this.state.ticketPayload.manucipalityNumber > 0 ? this.removeError('manucipalityNumber') : this.addError('manucipalityNumber')
            this.state.ticketPayload.landArea && this.state.ticketPayload.landArea > 0 ? this.removeError('landArea') : this.addError('landArea')
            this.state.ticketPayload.contractor && this.state.ticketPayload.contractor.toString().length > 0 ? this.removeError('contractor') : this.addError('contractor')
            this.state.ticketPayload.consultant && this.state.ticketPayload.consultant.toString().length > 0 ? this.removeError('consultant') : this.addError('consultant')
            this.state.ticketPayload.contractAmount && this.state.ticketPayload.contractAmount > 0 ? this.removeError('contractAmount') : this.addError('contractAmount')
            this.state.ticketPayload.contractDate && this.state.ticketPayload.contractDate.length > 0 ? this.removeError('contractDate') : this.addError('contractDate')
            this.state.ticketPayload.demarcationDate && this.state.ticketPayload.demarcationDate.length > 0 ? this.removeError('demarcationDate') : this.addError('demarcationDate')
            this.state.ticketPayload.contractDurationInMonths && this.state.ticketPayload.contractDurationInMonths > 0 ? this.removeError('contractDurationInMonths') : this.addError('contractDurationInMonths')
            // this.state.ticketPayload.preparationMobilizationDuration && this.state.ticketPayload.preparationMobilizationDuration > 0 ? this.removeError('preparationMobilizationDuration') : this.addError('preparationMobilizationDuration')
        }
    }

    getTicket(ticketNo) {
        localStorage.setItem("dTicketNo", ticketNo)
        this.setState({ showLoading: true }, () => {
            fetch(CONSTANTS.getDisbursementTicketDataEndpoint + '/' + ticketNo + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.getTicketSuccess).catch(this.loadFailed);
        })
    }

    getTicketSuccess(response) {
        this.setState({ showLoading: false }, () => {
            if (response.success) {
                this.setState({
                    ticketNo: response.payload.ticketNo,
                    ticketPayload: response.payload, steps: [
                        { name: translate('step1'), component: <StepOne reference={this} /> },
                        { name: translate('step2'), component: <StepTwo reference={this} /> },
                        { name: translate('step3'), component: <StepThree reference={this} /> },
                        { name: translate('step4'), component: <StepFour reference={this} /> }
                    ]
                }, () => {
                    localStorage.setItem("ticketPayload", JSON.stringify(response.payload))
                    this.validateData()
                })
            } else {
                this.loadFailed(response)
            }
        })
    }

    viewGeneratedTicket(ticketNo, props) {
        if (this._isMounted) {
            this.setState({ props: props, ticketNo: ticketNo }, () => { this.getTicket() })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.showLoading ? <Loader /> : null}
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard')}</a></li>
                                    <li className="breadcrumb-item"><a href="/constructionLoanDisbursementTickets">{localStorage.getItem('disbursementTypeID') === '16' ? translate('maintenanceDisbursementContractRequests') : translate('constructionDisbursementContractRequests')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('requestform')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/construction_disbursement.svg'} alt="construction Disbursement Contract Requests" /></i>
                                <span>{localStorage.getItem('disbursementTypeID') === '16' ? translate('maintenanceDisbursementContractHeading') : translate('constructionDisbursementContractHeading')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.ticketPayload.ticketNo ?
                            <div className="col-md-12">
                                <div className="service_contant_wrapper formSection">
                                    <div className="row">
                                        <div className="col-md-6 constructionDisbursementContractRequestsHead">
                                            <div>
                                                <span>{translate('ticket-number')}</span>
                                                <strong>{this.state.ticketPayload.ticketNo}</strong>
                                            </div>
                                            <div>
                                                <span>{translate('applicantName')}:</span>
                                                <strong>{this.state.ticketPayload.applicantName}</strong>
                                            </div>
                                        </div>
                                        <div className="col-md-6 constructionDisbursementContractRequestsHead">
                                            <div>
                                                <span>{translate('HousingStatus_ReqDate')}:</span>
                                                <strong>{this.state.ticketPayload.requestDate}</strong>
                                            </div>
                                            <div>
                                                <span>{translate('requestStatus')}:</span>
                                                <strong>{this.state.ticketPayload.requestStatus}</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className='step-progress constructionLoanDisbursementStepWrapper'>
                                            {this.state.steps.length > 0 ? <StepZilla startAtStep={parseInt(this.state.startAtStep)} steps={this.state.steps} showNavigation={false} stepsNavigation={false} preventEnterSubmission={true} /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.error}
                    <div ref={el => { this.el = el; }} />
                </div>
                {this.state.showLoading ? <Loader /> : null}
            </div >
        );
    }
}
export default AuthenticatedComponent(StepsConstructionLoanDisbursement)