import ChatbotAction from './ChatbotAction';
import ChatbotConstant from './ChatbotConstant';
import request from 'reqwest';

class ChatbotService {
  /** Directline start chat comment */
  directline(conversationId, faqId, reqText, botType, value, local, from) {
    return this.handleDirectlineResponse(request({
      url: ChatbotConstant.BASE_URL,
      method: 'POST',
      crossOrigin: true,
      //type: 'json',
      headers: {
        'Content-Type': 'application/json '
      },
       data: JSON.stringify({
        ConversationId: conversationId,
        //FaqConversationId: faqId,
        ReqText: reqText,
        Locale: local,
        //Value: value,
        Action: botType,
        Channel: 'Web',
        From: from
      })
    }));
  }
  handleDirectlineResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ChatbotAction.directline(response);
        return true;
      });
  }  
}

export default new ChatbotService();