import React from "react"
import '../../reactToastify.css'
import Loader from '../utils/LoaderBars'
import NoData from '../utils/nodata'
import qs from 'qs'
import CommonUtils from '../../utils/CommonUtils'
import { AppContext } from '../../Providers/context'
import { ToastContainer, toast } from 'react-toastify'
import translate from "../../Providers/i18n/Translate"
import CONSTANTS from '../constants/dashboard-constants'

class StepDocuments extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            otp: '',
            file: '',
            fileName: '',
            response: '',
            documents: [],
            viewfileURL: '',
            fileFrame: null,
            modifyPopup: null,
            showLoading: false,
            addFilePopup: null,
            showFileFrame: false,
            showBackground: false,
            showObligation: false,
            showUploadPopup: false,
            showModifyPopup: false,
            fetchDocumentSuccess: false
        }
        this.getFile = this.getFile.bind(this)
        this.addClick = this.addClick.bind(this)
        this.addClick = this.addClick.bind(this)
        this.backPress = this.backPress.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
        this.setResults = this.setResults.bind(this)
        this.modifyClick = this.modifyClick.bind(this)
        this.deleteClick = this.deleteClick.bind(this)
        this.deleteClick = this.deleteClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.viewFileClick = this.viewFileClick.bind(this)
        this.submitRequest = this.submitRequest.bind(this)
        this.viewImageError = this.viewImageError.bind(this)
        this.uploadDocument = this.uploadDocument.bind(this)
        this.deleteDocument = this.deleteDocument.bind(this)
        this.viewImageSuccess = this.viewImageSuccess.bind(this)
        this.closeShowFilePopup = this.closeShowFilePopup.bind(this)
        this.fetchRequiredDocuments = this.fetchRequiredDocuments.bind(this)
    }

    componentDidMount() {
        this.setResults(this.props.reference.state.response)
    }

    addClick(e) {
        e && e.persist()
        this.setState({ showBackground: !this.state.showBackground, showUploadPopup: !this.state.showUploadPopup }, () => {
            this.setState({
                addFilePopup:
                    this.state.showUploadPopup ?
                        <div>
                            <div className="uploadPopup">
                                <button className="popupClose" onClick={this.addClick}>X</button>
                                <h2>{e.target.name}</h2>
                                <input type="file" id={e.target.id} onChange={this.getFile}></input>
                                <label htmlFor={e.target.id}>{translate('dragAndDrop')}</label>
                                <p>{translate('fileSize')}</p>
                            </div>
                        </div> : null
            })
        })
    }

    modifyClick(e) {
        e && e.persist()
        this.setState({ showBackground: !this.state.showBackground, showModifyPopup: !this.state.showModifyPopup }, () => {
            this.setState({
                modifyPopup: this.state.showModifyPopup && this.state.response ?
                    <div>
                        <div className="viewPopup">
                            <button id={e.target.id} className="popupClose" onClick={this.modifyClick}>X</button>
                            <h2>{e.target.name}</h2>
                            {this.state.response[e.target.id].attachments.map((attachment, index) => {
                                return (<div key={index} className="row viewPopupContant">
                                    <div className="col-md-7">{attachment.name}</div>
                                    <div className="col-md-5 project_step_upload_btns">
                                        <button id={attachment.id} onClick={this.viewFileClick}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/icon_eye.svg'} alt="view" /> {translate('view')} </button>
                                        <button id={attachment.id} onClick={this.deleteClick}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/delete.svg'} alt="delete" /> {translate('delete')} </button>
                                    </div>
                                </div>
                                )
                            })}
                        </div>
                    </div> : null
            })
        })
    }

    viewFileClick(e) {
        e && e.persist()
        if (!this.state.showFileFrame && e.target.id.length > 0) {
            this.setState({ showLoading: true }, () => {
                fetch(CONSTANTS.housingTicketViewDocsEndPoint + "?ticket=" + this.props.reference.state.ticketResponse.ticket + "&id=" + e.target.id + "&local=" + this.context.state.siteLang, {
                    method: 'GET',
                    headers: {
                        'Authorization': CONSTANTS.authToken
                    },
                }).then(response => response.json()).then(this.viewImageSuccess).catch(this.viewImageError);
            })
        } else {
            this.setState({ showFileFrame: !this.state.showFileFrame })
        }
    }

    closeShowFilePopup() {
        this.setState({ showFileFrame: false, fileFrame: null })
    }

    viewImageSuccess(response) {
        if (response.success) {
            this.setState({ showLoading: false, showFileFrame: !this.state.showFileFrame }, () => {
                this.setState({
                    fileFrame:
                        this.state.showFileFrame ? <div className="uploadPopup">
                            <button className="popupClose" onClick={this.closeShowFilePopup}>X</button>
                            {CommonUtils.getFileComponent(response)}
                        </div> : null
                })
            })
        } else {
            toast.error(response.message)
        }
    }

    viewImageError(response) {
        toast.error(response.message)
    }

    deleteClick(e) {
        this.deleteDocument(e)
    }

    getFile(e) {
        let file = e.currentTarget.files[0];
        if (CommonUtils.isValidFileType(file) && CommonUtils.isValidFileSize(file)) {
            this.uploadDocument(e.currentTarget.id, file)
        } else {
            toast.error(translate('fileSize'))
        }
        e.currentTarget.value = null
    }

    deleteDocument(e) {
        if (e) {
            e.persist()
            this.setState({ documentId: e.currentTarget.id }, () => {
                this.setState({ showLoading: true }, () => {
                    // var url = CONSTANTS.housingTicketDeleteDocsEndPoint + "/" + this.props.reference.state.ticketResponse.ticket + "?local=" + this.context.state.siteLang + "&attachmentId=" + this.state.documentId
                    var url = CONSTANTS.housingTicketDeleteDocsEndPoint + "?local=" + this.context.state.siteLang + "&ticket=" + this.props.reference.state.ticketResponse.ticket + "&attachmentId=" + this.state.documentId
                    fetch(url, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': CONSTANTS.authToken
                        },
                    }).then(response => response.json()).then((response) => {
                        this.modifyClick()
                        this.fetchRequiredDocuments()
                        if (response.success) {
                            toast.success(translate('docdeletesuccess'))
                        } else {
                            toast.error(response.message)
                        }
                    }).catch(this.loadFailed);
                })
            })
        }
    }

    fetchRequiredDocuments() {
        // fetch(CONSTANTS.housingTicketRequiredDocsEndPoint + "/" + this.props.reference.state.ticketResponse.ticket + "?local=" + this.context.state.siteLang, {
        fetch(CONSTANTS.housingTicketRequiredDocsEndPoint + "?local=" + this.context.state.siteLang + "&ticket=" + this.props.reference.state.ticketResponse.ticket, {
            method: 'GET',
            headers: {
                'Authorization': CONSTANTS.authToken
            },
        }).then(response => response.json()).then(this.setResults).catch(this.loadFailed);
    }

    backPress() {
        this.props.reference.getTicket()
        this.props.reference.reset()
        this.props.reference.updateStepNo(2)
        this.props.jumpToStep(1)
    }

    uploadDocument(typeId, file) {
        this.setState({ showLoading: true }, () => {
            CommonUtils.getBase64(file)
                .then((resultBase64) => {
                    // fetch(CONSTANTS.housingTicketPostDocsEndPoint + "/" + this.props.reference.state.ticketResponse.ticket + "?local=" + this.context.state.siteLang, {
                    fetch(CONSTANTS.housingTicketPostDocsEndPoint + "?local=" + this.context.state.siteLang + "&ticket=" + this.props.reference.state.ticketResponse.ticket, {
                        method: 'POST',
                        body: qs.stringify({ 'file': resultBase64, 'type': typeId, 'local': this.context.state.siteLang }),
                        headers: {
                            'Authorization': CONSTANTS.authToken,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                    }).then(response => response.json()).then((response) => {
                        if (response.success) {
                            this.addClick()
                            toast.success(response.message)
                            this.fetchRequiredDocuments()
                        } else {
                            this.setState({ showLoading: false }, () => {
                                this.addClick()
                                toast.error(response.message)
                            })
                        }
                    }).catch(this.loadFailed);
                }
                )
        })
    }

    setResults(response) {
        if (response.success) {
            var documents = []
            this.setState({ showLoading: false, docs_message: response.message, response: response.payload, fetchDocumentSuccess: true }, () => {
                documents = response.payload.map((object, index) => {
                    var documentsArray = []
                    documentsArray.push(<FileComponent index={index} key={index} payload={object} addClick={this.addClick} modifyClick={this.modifyClick} />)
                    return documentsArray
                })
                this.setState({ documents: documents, docs_message: response.message })
            })
        }
    }

    loadFailed() {
        this.setState({ showLoading: false, response: {}, documents: [] })
    }

    submitRequest() {
        this.props.reference.submitRequest()
    }

    handleChange(e) {
        const { name, value } = e.currentTarget
        this.setState({ [name]: value })
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={3000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                {this.state.showBackground ? <div className="PopupBk"></div> : null}
                {this.state.addFilePopup}
                {this.state.modifyPopup}
                {this.state.fileFrame}
                {this.state.documents}
                <br />
                {this.state.documents.length <= 0 ? <div className="stepDocNoDocWrapper">
                    <div className="noData" style={{ display: "" }}>
                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/nodocs.svg'} alt="No Data" />
                        <span style={{ fontWeight: "bold", fontSize: 15 }}>{this.state.docs_message}</span>
                    </div>
                </div> : null
                }
                <br></br>
                <div className="infoWrapper">
                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/info_blue.svg'} alt="info" /></i>
                    <span>{translate('uploaddocumentmessage')}</span>
                </div>
                <div className="row">
                    <div className="col-md-12 project_step_nav_Btn">
                        <button className="stepFinishBtn" onClick={this.submitRequest}>{translate('finish')}</button>
                    </div>
                </div>
                {this.state.showLoading ? <Loader /> : null}
            </div >
        )
    }
}


function FileComponent(props) {
    return (
        <div className="row project_step_upload_Section">
            <div className="col-lg-5">
                <span>{props.payload.name}</span>{props.payload.isRequired ? <span className="redStar">*</span> : null}
            </div>
            <div className="col-lg-3">
                <span>{translate('documentCount')} : </span>
                <span>{props.payload.attachments.length}</span>
            </div>
            <div className="col-lg-4 project_step_upload_btns">
                <button name={props.payload.name} id={props.payload.id} onClick={props.addClick}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/add.svg'} alt="" /> {translate('add')} </button>
                {props.payload.attachments.length > 0 ? <button name={props.payload.name} id={props.index} onClick={props.modifyClick}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/edit.svg'} alt="" /> {translate('viewModify')} </button> : null}
            </div>
        </div>)
}

export default StepDocuments