import HeaderConstants from './HeaderConstants'
import BaseStore from '../../BaseStore'

class HeaderStore extends BaseStore {

    constructor() {
        super();
        this.subscribe(() => this.registerToActions.bind(this))
        this.headerResponse = null
    }

    registerToActions(action) {
        switch (action.actionType) {
            case HeaderConstants.HEADER:
                this.headerResponse = action.response
                this.emitChange()
                break
            default:
                break
        }
    }

    get response() {
        return this.headerResponse
    }
}

export default new HeaderStore()