import React from 'react'
import '../../reactToastify.css'
import NoData from '../utils/nodata'
import Loader from '../utils/LoaderBars'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import { ToastContainer, toast } from 'react-toastify'
import HousingRequestItem from './housingrequest-widget-item'
import HappinessMeter from '../../HappinessMeter/HappinessMeter'
import CostumerModuleUtilities from '../utils/customerModuleUtilities'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
import HousingRequestsStore from '../dashboard/housingrequests-widget/housing-requests-store'
import HousingRequestsService from '../dashboard/housingrequests-widget/housing-requests-service'

class HousingRequestService extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            redirect: false,
            props: '',
            showLoading: true
        }
        this.onChange = this.onChange.bind(this)
        this.modifyTicket = this.modifyTicket.bind(this)
        this.deleteSuccess = this.deleteSuccess.bind(this)
        this.setHousingRequests = this.setHousingRequests.bind(this)
        this.deleteTicketClick = this.deleteTicketClick.bind(this)
        this.yes = this.yes.bind(this)
        this.getIsTotalDeductionRequired = this.getIsTotalDeductionRequired.bind(this)
        this.no = this.no.bind(this)
        this.getIsSocialStudyRequired = this.getIsSocialStudyRequired.bind(this)
    }

    componentDidMount() {
        this.setState({ props: this.props })
        HousingRequestsStore.addChangeListener(this.onChange)
        HousingRequestsService.fetchHousingRequest(100, "?local=" + this.context.state.siteLang)
    }

    onChange() {
        this.setHousingRequests(HousingRequestsStore.response)
        this.deleteSuccess(HousingRequestsStore.deleteHousingRequestResponse)
    }

    setHousingRequests(response) {
        var housingRequestItems = []
        var items = response.payload
        if (items.length > 0) {
            this.setState({ requestsPayload: response.payload })
            items.map((housingRequest, index) => {
                let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
                let cls = CostumerModuleUtilities.getStatusLableColor(housingRequest.status)
                return (housingRequestItems.push(<HousingRequestItem deleteClick={this.deleteTicketClick} modifyTicket={this.modifyTicket} key={index} payload={housingRequest} image={img} className={cls} />))
            })
            this.setState({ redirect: false, itemsArray: housingRequestItems })
            setTimeout(function () { this.setState({ showLoading: false }) }.bind(this), 1200)
        } else {
            setTimeout(function () { this.setState({ showLoading: false, redirect: true }) }.bind(this), 800)
        }
    }

    modifyTicket(e) {
        const ticketNo = e.currentTarget.name
        const requestType = e.currentTarget.id
        const applicationNo = e.currentTarget.title
        const applicationStatus = e.currentTarget.value
        localStorage.removeItem('ticket')
        if (ticketNo) {
            if (requestType.toString() === '3') {
                this.state.props.history.push({ pathname: '/changeServiceRequest' })
            } else if (requestType.toString() === '1' || requestType.toString() === '2') {
                this.state.props.history.push({ pathname: '/cancelProgressServiceRequest', state: { ticketNo: ticketNo, applicationNo: applicationNo, applicationStatus: applicationStatus } })
            } else {
                localStorage.setItem('requireSocialTechnicalStudy', this.getIsSocialStudyRequired(ticketNo))
                localStorage.setItem("isTotalDeductionRequired", this.getIsTotalDeductionRequired(ticketNo))
                this.state.props.history.push({
                    pathname: '/housingRequestSteps', state: { ticketNo: ticketNo, isModifying: true }
                })
            }
        } else {
            toast.error(e.target.name)
        }
    }

    getIsSocialStudyRequired(requestID) {
        var isSocialStudyRequired = false
        this.state.requestsPayload.some((request) => {
            isSocialStudyRequired = request.requireSocialTechnicalStudy
            return requestID === request.ticket
        })
        return isSocialStudyRequired
    }

    getIsTotalDeductionRequired(requestID) {
        var isTotalDeductionRequired = false
        this.state.requestsPayload.some((request) => {
            isTotalDeductionRequired = request.isTotalDeductionsRequired
            return requestID === request.ticket
        })
        return isTotalDeductionRequired
    }

    deleteTicketClick(e) {
        e.persist()
        const ticketNo = e.currentTarget.name
        if (ticketNo) {
            this.setState({ ticketNo: ticketNo, showConfirmation: !this.state.showConfirmation })
        }
    }

    yes() {
        if (this.state.ticketNo) {
            this.setState({ showLoading: true, showConfirmation: !this.state.showConfirmation })
            HousingRequestsService.deleteTicket(this.state.ticketNo)
        }
    }

    no() {
        this.setState({ ticketNo: '', showConfirmation: false })
    }

    deleteSuccess(response) {
        if (response) {
            if (response.success) {
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
            HousingRequestsStore.clearDeleteResponse()
            HousingRequestsService.fetchHousingRequest(100, "?local=" + this.context.state.siteLang)
            this.no()
        }
        // this.setState({ showLoading: false })
    }

    componentDidUpdate() {
        localStorage.setItem('serviceID', '')
    }

    render() {
        return (
            <div className="container-fluid">
                {this.props.location.state ? <HappinessMeter serviceCode={this.props.location.state.serviceCode} transactionID={this.props.location.state.ticketNo} /> : null}
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    {/* delete confirmation popup */}
                    {this.state.showConfirmation ?
                        <div className="PopupBk"></div> : null}
                    {this.state.showConfirmation ?
                        <div className="deleteConfirmationPopup">
                            <p>{translate('deleteconfirmation')}</p>
                            <div className="button">
                                <button onClick={this.yes}>{translate('yes')}</button>
                                <button onClick={this.no}>{translate('no')}</button>
                            </div>
                        </div> : null}

                    {/* delete confirmation popup */}
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('housing-requests-widget-heading')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="HousingRequest" /></i>
                                <span>{translate('housing-requests-widget-heading')}</span>

                                <div className="add_newrequest">
                                    <a href="/NewhousingRequest" onClick={() => { localStorage.removeItem("ticket") }}><span> {translate('clicktoadd')} </span><i>+</i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.showLoading ? <Loader /> :
                        <div className="row">
                            <div className="col-md-12">
                                <div className="service_contant_wrapper formSection">
                                    <div className="row">
                                        {this.state.redirect ? <NoData message={translate('nodata')} /> : this.state.itemsArray}
                                        {/* {this.state.redirect ? <Redirect to='/NewhousingRequest' /> : this.state.itemsArray} */}
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
        )
    }
}
export default AuthenticatedComponent(HousingRequestService)