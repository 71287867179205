import React from 'react'
import NoData from '../../utils/nodata'
import { AppContext } from '../../../Providers/context'
import { ToastContainer, toast } from 'react-toastify'
import translate from '../../../Providers/i18n/Translate'
import CONSTANTS from '../../constants/dashboard-constants'
import ContractorPaymentRequestsWidgetItem from './contractorpaymentrequests-widget-item'

class ContractorPaymentRequestsWidget extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super()
        this.state = {
            itemsArray: [],
            url: ''
        }
        this.fetchContractorPaymentsData = this.fetchContractorPaymentsData.bind(this)
        this.setContractorPaymentWidget = this.setContractorPaymentWidget.bind(this)
        this.acceptPayment = this.acceptPayment.bind(this)
        this.rejectPayment = this.rejectPayment.bind(this)
        this.processSuccess = this.processSuccess.bind(this)
    }

    componentDidMount() {
        this.fetchContractorPaymentsData(this.props.url)
        this.setState({ url: this.props.url })
    }

    fetchContractorPaymentsData(url) {
        if (CONSTANTS.authToken.length > 10) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setContractorPaymentWidget).catch(this.loadFailed);
        }
    }

    acceptPayment(e) {
        const { name } = e.target
        if (CONSTANTS.authToken.length > 10) {
            fetch(CONSTANTS.pendingPaymentProcessEndpoint + "?local=" + this.context.state.siteLang + '&id=' + name + '&approve=true', {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.processSuccess).catch(this.loadFailed);
        }
    }

    rejectPayment(e) {
        const { name } = e.target
        if (CONSTANTS.authToken.length > 15) {
            fetch(CONSTANTS.pendingPaymentProcessEndpoint + "?local=" + this.context.state.siteLang + '&id=' + name + '&approve=false', {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.processSuccess).catch(this.loadFailed);
        }
    }

    processSuccess(response) {
        this.fetchContractorPaymentsData(this.state.url)
        toast.success(response.message)
    }

    loadFailed(response) {
        toast.error(response)
        console.log(response.message)
    }

    setContractorPaymentWidget(response) {
        if (response.success) {
            var contractorPaymentRequestsItems = []
            var items = response.payload
            if (items.length > 2) {
                contractorPaymentRequestsItems.push(<ContractorPaymentRequestsWidgetItem key={0} item={items[0]} rejectPayment={this.rejectPayment} acceptPayment={this.acceptPayment} />)
                contractorPaymentRequestsItems.push(<ContractorPaymentRequestsWidgetItem key={1} item={items[1]} rejectPayment={this.rejectPayment} acceptPayment={this.acceptPayment} />)
            } else {
                items.map((item) => {
                    return (contractorPaymentRequestsItems.push(<ContractorPaymentRequestsWidgetItem key={0} item={item} rejectPayment={this.rejectPayment} acceptPayment={this.acceptPayment} />))
                })
            }
            this.setState({ itemsArray: contractorPaymentRequestsItems })
        }
    }

    render() {
        return (
            <div className="col-lg-6 contractorPaymentRequestWrapper">
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="widgetWrapper">
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/ContractorPaymentRequest.svg'} alt="Contractor Payment Request" /></span>
                        <span>{translate('contractor-payments-requests-widget-heading')}</span>
                    </div>
                    <div className="widgetContant">
                        <div className="widgetContentIn">
                            {this.state.itemsArray.length > 0 ?
                                <div>{this.state.itemsArray}</div>
                                : <NoData message={translate('nodata')} />}
                        </div>
                        {/* {this.state.itemsArray.length > 0 ? <div className="widgetsMoreBtn">
                            <hr></hr>
                            <a href={this.state.itemsArray.length > 0 ? "/contractorPaymentRequest" : null}>{translate('show-more')}<img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/arrow.svg'} alt="Click" /></a>
                        </div> : null} */}
                    </div>
                </div>
            </div>
        )
    }
}
export default ContractorPaymentRequestsWidget