import React from 'react'
import translate from '../../Providers/i18n/Translate'
class HouseBookingMapPin extends React.Component {
    constructor(props) {
        super()
        this.state = { showPopUp: false }
        this.onPinClick = this.onPinClick.bind(this)
        this.getDirections = this.getDirections.bind(this)
    }

    render() {
        return (
            <div className="popupMapWrapper">
                <img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/location3.png" onClick={this.onPinClick} alt="Location" />
                {this.state.showPopUp ?
                    <div className="popupMapContent">
                        <button onClick={this.onPinClick}>X</button>
                        <div className="popupMapContentDetails">
                            <div>
                                <strong>{translate("Price")} : </strong>
                                <span> {this.props.payload.sellingPrice} </span>
                            </div>
                            <div>
                                <strong>{translate("Landarea")} : </strong>
                                <span> {this.props.payload.basedArea} </span>
                            </div>
                        </div>
                        <div className="popupMapContentBtn">
                            <button onClick={this.getDirections}><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/pin.svg" alt="Location" />{translate("directions")}</button>
                            {this.props.goToDetails ?
                                <button name={this.props.payload.id} onClick={this.props.goToDetails}><img src="https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/direction.svg" alt="direction" />{translate("Viewdetails")}</button>
                                : null}
                        </div>
                    </div> : null}
            </div>
        )
    }
    onPinClick() {
        this.setState({ showPopUp: !this.state.showPopUp })
        // this.props.callback()
    }

    getDirections() {
        window.open("https://www.google.com/maps?q=" + this.props.payload.latitude + "," + this.props.payload.longitude)
    }
}
export default HouseBookingMapPin