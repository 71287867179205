import React from 'react'

function NoData(props) {
    return (
        <div className="noData" style={{ display: "" }}>
            <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/no_data.svg'} alt="No Data" />
            <span>{props.message}</span>
        </div>
    )
}
export default NoData