import AppDispatcher from '../Dispatcher';
import ChatbotConstant from './ChatbotConstant';

class ChatbotAction {
  directline(response) {
    AppDispatcher.dispatch({
      actionType: ChatbotConstant.CHATBOT_DIRECTLINE,
      response: response
    })
  }
}
export default new ChatbotAction();