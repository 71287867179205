$(function () {
    // SIDE MENU
    $('.mobMenuWrapper').on('click', function (e) {
        e.preventDefault();
        $('.sub-menu').hide();
        $('.menu-toggle').removeClass('rotate');
        $('li .sub-menu').removeClass('active');
        $('li .sub-menu').slideUp(400);
        $('#footer').removeClass('footZindex');
    });

    $('#mobMenuCloseBtn').on('click', function (e) {
        e.preventDefault();
        $('.sub-menu').hide();
        $('.menu-toggle').removeClass('rotate');
        $('li .sub-menu').removeClass('active');
        $('li .sub-menu').slideUp(400);
        $('#footer').removeClass('footZindex');
    });

    $('#menuBtn').on('click', function (e) {
        e.preventDefault();
        $('#footer').addClass('footZindex');
    });

    // ACCESSABILITY
    $('#accessabilityBtn').on('click', function (e) {
        e.preventDefault();
        $('#footer').addClass('footZindex');
    });

    $('.accPopup .popupClose').on('click', function (e) {
        e.preventDefault();
        $('#footer').removeClass('footZindex');
    });

});

// window.onload = function (){
    
// };