import ContractorDashboardAction from './ContractorDashboardAction';
import ContractorDashboardConstant from './ContractorDashboardConstant';
import request from 'reqwest';

class ContractorDashboardService {
  /** contractor project payments */
  contractorDashboardProjects(page, pagesize, local) {
    return this.handleResponse(request({
      url: ContractorDashboardConstant.CONTRACTOR_DASHBOARD_URL + page + '&pagesize=' + pagesize + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('accessToken')
      }
    }));
  }
  
  contractorDashboardServices(local) {
    return this.handleServicesResponse(request({
      url: ContractorDashboardConstant.CONTRACTOR_DASHBOARD_SERVICES_URL + '&local=' + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('accessToken')
      }
    }));
  }

  /** contractor projects summary */
  contractorProjectSummary(local) {
    return this.handleContractorSummary(request({
      url: ContractorDashboardConstant.CONTRACTOR_PROJECT_SUMMARY_URL + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('accessToken')
      }
    }));
  }

  /** project summary */
  projectSummary(local) {
    return this.handleProjectSummary(request({
      url: ContractorDashboardConstant.PROJECT_SUMMARY_URL + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('accessToken')
      }
    }));
  }

  /** financial status */
  financialStatus(local) {
    return this.handleFinancialStatus(request({
      url: ContractorDashboardConstant.FINANCIAL_STATUS_URL + local,
      method: 'GET',
      crossOrigin: true,
      type: 'json',
      headers: {
        'Authorization': 'bearer ' + localStorage.getItem('accessToken')
      }
    }));
  }

  handleFinancialStatus(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorDashboardAction.financialStatus(response);
        return true;
      });
  }

  handleProjectSummary(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorDashboardAction.projectSummary(response);
        return true;
      });
  }

  handleResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorDashboardAction.contractorProjects(response);
        return true;
      });
  }
  
  handleServicesResponse(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorDashboardAction.dashboardServices(response);
        return true;
      });
  }

  handleContractorSummary(responsePromise) {
    return responsePromise
      .then(function (response) {
        ContractorDashboardAction.contractorProjectsSummary(response);
        return true;
      });
  }
}

export default new ContractorDashboardService();