import React from 'react'
import '../../reactToastify.css'
import { ToastContainer, toast } from 'react-toastify'
import CONSTANTS from '../../Customer/constants/dashboard-constants'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
import translate from '../../Providers/i18n/Translate'
import { AppContext } from '../../Providers/context'
import { LOCALES } from '../../Providers/i18n'
import HISTORY from '../../History';


class ContractorProfile extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            companyName: '',
            email: '',
            bank: '',
            bankAccount: '',
            emirate: '',
            contactName: '',
            primaryMobileNo: '',
            officeNo: '',
            faxNo: '',
            address: '',
            pobox: '',
            photo: '',
            newPwd: '',
            confPwd: '',
            message: "",
            changePwd: false
        }
        this.updateProfile = this.updateProfile.bind(this)
        this.updatePassword = this.updatePassword.bind(this)
        this.setProfile = this.setProfile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.success = this.success.bind(this)
        this.loadFailed = this.loadFailed.bind(this)
        this.changePasswordClick = this.changePasswordClick.bind(this)
        this.passwordUpdate = this.passwordUpdate.bind(this)
    }

    componentDidMount() {
        var user = this.props.location.getPayload ? this.props.location.getPayload() : null
        if (user == null) {
            user = localStorage.dashboard && JSON.parse(localStorage.getItem('dashboard')).payload
        }
        this.setProfile(user)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    changePasswordClick(e) {
        e.preventDefault()
        this.setState({ changePwd: !this.state.changePwd })
    }

    passwordUpdate(e) {
        e.preventDefault();
        if (this.state.confPwd === this.state.newPwd)
            this.updatePassword()
        else
            toast.error(translate('passwordarenotsame'), { autoClose: 5000 })
    }

    updateProfile(e) {
        e.preventDefault();
        var data = new URLSearchParams()
        data.append('name', this.state.name)
        data.append('companyName', this.state.companyName)
        data.append('email', this.state.email)
        data.append('bank', this.state.bank)
        data.append('bankAccount', this.state.bankAccount)
        data.append('emirate', this.state.emirate)
        data.append('contactName', this.state.contactName)
        data.append('primaryMobileNo', this.state.primaryMobileNo)
        data.append('officeNo', this.state.officeNo)
        data.append('faxNo', this.state.faxNo)
        data.append('address', this.state.address)
        data.append('pobox', this.state.pobox)
        fetch(CONSTANTS.updateContractorProfileEndpoint + this.context.state.siteLang, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': CONSTANTS.authToken
            },
            body: data
        }).then(response => response.json()).then(this.success).catch(this.loadFailed);
    }

    updatePassword() {
        fetch(CONSTANTS.contractorProfilePasswordEndpoint + this.context.state.siteLang + "&userName=" + this.state.name + "&newPassword=" + this.state.newPwd, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': CONSTANTS.authToken
            }
        }).then(response => response.json()).then(this.success).catch(this.loadFailed);
    }

    loadFailed(response) {
        toast.error(response.message)
    }

    success(response) {
        console.log(response)
        if (response.success)
            toast.success(response.message)
        else
            toast.error(response.message)
    }

    setProfile(user) {
        if (user) {
            this.setState({
                userType: localStorage.getItem('userType'),
                name: user.name,
                companyName: user.companyName,
                email: user.email,
                bank: user.bank,
                bankAccount: user.bankAccount,
                emirate: user.emirate,
                contactName: user.contactName,
                primaryMobileNo: user.primaryMobileNo,
                officeNo: user.officeNo,
                faxNo: user.faxNo,
                address: user.address,
                pobox: user.pobox,
                photo: user.photo
            })
        }
    }

    myInbox() {
        HISTORY.push("/pdfList");
    }

    render() {
        return (
            <div className="container-fluid">
                <ToastContainer autoClose={5000} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="container mainSection">
                    {/* breadcrumb */}
                    <div className="row">
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        {this.state.userType === "1" ? <a href="/customerDashboard">{translate('dashboard')}</a> : <a href="/contractorDashboard">{translate('dashboard')}</a>}
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('profile')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/* End breadcrumb */}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper"><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/profile.svg'} alt="profile" /></i><span>{translate('profile')}</span>
                                <button className="myInboxBtn" onClick={this.myInbox}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/inbox.svg'} alt="inbox" /></i> <span>{translate('myInbox')}</span></button>
                            </div>
                        </div>
                    </div>

                    <form >
                        <div className="col-md-12">
                            <div className="row service_contant_wrapper formSection">
                                <div className="col-lg-2">
                                    <div className="profileImage">
                                        <img src={this.state.photo ? `data:image/png;base64,${this.state.photo}` : "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/user_default.svg"} height="31" width="31" alt='profile' />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="name">{translate('name')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="name" name="name" defaultValue={this.state.name} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="companyName">{translate('companyName')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="companyName" name="companyName" defaultValue={this.state.companyName} onChange={this.handleChange} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="email">{translate('email')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="email" name="email" defaultValue={this.state.email} onChange={this.handleChange} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="bank">{translate('bankName')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="bank" name="bank" defaultValue={this.state.bank} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="bankAccount">{translate('accountNo')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="bankAccount" name="bankAccount" defaultValue={this.state.bankAccount} onChange={this.handleChange} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="emirate">{translate('emirate')}</label></div>
                                        <div className="col-sm-8">
                                            {this.context.state.siteLang === LOCALES.ENGLISH ?
                                                <select id="emirate" name="emirate" value={this.state.emirate} onChange={this.handleChange}>
                                                    <option value="1">Abu Dhabi</option>
                                                    <option value="2">Dubai</option>
                                                    <option value="3">Sharjah</option>
                                                    <option value="4">Ajman</option>
                                                    <option value="5">Ras Al Khaimah</option>
                                                    <option value="6">Umm Al Quwain</option>
                                                    <option value="7">Fujairah</option>
                                                    <option value="8">Al Ain</option>
                                                    <option value="9">Hatta</option>
                                                    <option value="90">OutSide the country</option>
                                                    <option value="99">Not Selected</option>
                                                </select> :
                                                <select id="emirate" name="emirate" value={this.state.emirate} onChange={this.handleChange}>
                                                    <option value="1">أبوظبي</option>
                                                    <option value="2">دبي</option>
                                                    <option value="3">الشارقة</option>
                                                    <option value="4">عجمان</option>
                                                    <option value="5">رأس الخيمة</option>
                                                    <option value="6">أم القيوين</option>
                                                    <option value="7">الفجيرة</option>
                                                    <option value="8">العين</option>
                                                    <option value="9">حتا</option>
                                                    <option value="90">خارج البلاد</option>
                                                    <option value="99">لم يتم اختياره</option>
                                                </select>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="contactName">{translate('contactPerson')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="contactName" name="contactName" defaultValue={this.state.contactName} /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="primaryMobileNo">{translate('mobileNumber')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="primaryMobileNo" name="primaryMobileNo" defaultValue={this.state.primaryMobileNo} onChange={this.handleChange} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="officeNo">{translate('officePhone')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="officeNo" name="officeNo" defaultValue={this.state.officeNo} onChange={this.handleChange} /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="faxNo">{translate('faxNo')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="faxNo" name="faxNo" defaultValue={this.state.faxNo} onChange={this.handleChange} disabled /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="address">{translate('address')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="address" name="address" defaultValue={this.state.address} onChange={this.handleChange} /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-4"><label htmlFor="pobox">{translate('pOBox')}</label></div>
                                        <div className="col-sm-8"><input type="text" id="pobox" name="pobox" defaultValue={this.state.pobox} onChange={this.handleChange} /></div>
                                    </div>
                                    <div className="row">
                                        <div className="col profileSave">
                                            <button onClick={this.changePasswordClick}>{translate('changePwd')}</button>
                                            <button onClick={this.updateProfile}>{translate('save')}</button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.changePwd ? <div className="PopupBk" onClick={this.changePasswordClick}></div> : null}
                                {this.state.changePwd ?
                                    <div className="changePasswordPopup">
                                        <div className="row">
                                            <div className="col-md-12 formSection">
                                                <div className="row">
                                                    <div className="col">
                                                        <h2><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/password.svg'} alt="change Password" /> {translate('changePwd')}</h2>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4"><label htmlFor="name">{translate('userName')}</label></div>
                                                    <div className="col-sm-8"><input type="text" id="name" defaultValue={this.state.name} disabled /></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4"><label htmlFor="newPwd">{translate('newPwd')}</label></div>
                                                    <div className="col-sm-8"><input type="password" id="newPwd" name="newPwd" onChange={this.handleChange} /></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4"><label htmlFor="confPwd">{translate('confPwd')}</label></div>
                                                    <div className="col-sm-8"><input type="password" id="confPwd" name="confPwd" onChange={this.handleChange} /></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col changePasswordPopupBtn">
                                                        <button onClick={this.passwordUpdate}>{translate('update')}</button>
                                                        <button onClick={this.changePasswordClick}>{translate('cancle')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.responseErrorMessage}
                                    </div> : null}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default AuthenticatedComponent(ContractorProfile)